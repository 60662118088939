import React, { Component } from 'react';
import { Flex, Button} from 'antd-mobile';
import SadRobot from '../images/icons/iconSadRobot.png'
import {
    isBrowser,
    isMobile,
    isChrome,
    isSafari,
    isMobileSafari,
} from "react-device-detect";
class NotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {  
            loading:false,
            isBrowser:'Not Browser',
            isMobile: 'Not Mobile',
            isChrome: 'Not Chrome',
            isSafari: 'Not Safari',
            isMobileSafari: 'Not MobileSafari'
        }
    }

    componentDidMount() {
        if (isBrowser){
            this.setState({
                isBrowser: 'is Browser'
            })
        }
        if (isMobile) {
            this.setState({
                isMobile: 'is Mobile',
            })
        }
        if (isChrome) {
            this.setState({
                isChrome: 'is Chrome',
            })
        }
        if (isSafari){
            this.setState({
                isSafari: 'is Safari',
            })
        }
        if (isMobileSafari) {
            this.setState({
                isMobileSafari: 'is MobileSafari'
            })
        }

    }

    goToContactUs(){
        this.setState({
            loading:true
        });
        window.location.href = "https://www.ourheartvoice.com/contact-us/"
    }

    componentWillUnmount(){
        this.setState({
            loading: false
        });
    }

    render() { 
        return ( 
            <div className="notfound-page">
                <Flex direction="column" justify="end">
                    {/* {'>> ' +this.state.isBrowser + ' <<'} <br/>
                    {'>> ' + this.state.isMobile + ' <<'}<br />
                    {'>> ' + this.state.isChrome + ' <<'}<br />
                    {'>> ' + this.state.isSafari + ' <<'}<br />
                    {'>> ' + this.state.isMobileSafari + ' <<'}<br />*/}
                    <img src={SadRobot} className="SadRobot" alt="page not found" />
                    <div className="desc1">This session has expired.</div>
                    <div className="desc2">Need help?</div>
                    <Button
                        className="outline-blue-btn"
                        size="large"
                        onClick={this.goToContactUs.bind(this)}
                        loading={this.state.loading}
                        disabled={this.state.loading}
                    >
                        Contact Us
                    </Button>
                </Flex>
            </div>
         );
    }
}
 
export default NotFound;