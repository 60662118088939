import React, { useState } from 'react';
import { styled } from '@material-ui/core/styles';
import { FormControl, Typography } from '@material-ui/core';
import Loading from '../../images/icons/roomConnecting.png';

import Controls from './components/Controls/Controls';
import LocalVideoPreview from './components/LocalVideoPreview/LocalVideoPreview';
import MenuBar from './components/MenuBar/MenuBar';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';

import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import { useAppState } from "./state";
import useVideoContext from "./hooks/useVideoContext/useVideoContext";
import AuthGuard from "../../services/AuthGuard";
import VideoTrack from './components/VideoTrack/VideoTrack';
import ParticipantStrip from './components/ParticipantStrip/ParticipantStrip';
import MainParticipant from './components/MainParticipant/MainParticipant';
import { Button, Modal } from 'antd-mobile';
import SettingsDialog from './components/MenuBar/SettingsDialog/SettingsDialog';
import LocalAudioLevelIndicator from './components/MenuBar/DeviceSelector/LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import { LocalVideoTrack } from 'twilio-video';


const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
});

const Main = styled('main')({
  overflow: 'hidden',
});

export default function App() {
  const roomState = useRoomState();
  const { token, isShowMenu, setIsShowMenu, setError} = useAppState();
  const { connect, isConnecting } = useVideoContext();
  const { localTracks } = useVideoContext();
  const [settingPopUp, setSettingPopUp] = useState(true)
  const [testingAudio, setTestingAudio] = useState(false)

  const localVideoTrack = localTracks.find(track => track.kind === 'video') as LocalVideoTrack;

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  // const height = useHeight();

  //@ts-ignore
  let audio: HTMLAudioElement = document.getElementById("ringtoneMp3");
  const toggleTestAudio = () => {
    setTestingAudio(!testingAudio)

    if (audio !== null) {
      if (!testingAudio) {
        if (audio.paused) {
          audio.play();// 播放
          audio.loop = true;
        }
      } else {
        audio.pause()
      }
    }
  }

  return (
    <div>
      <Main className={"videoCall-block " + roomState }>
        <div className="user1" onClick={() => setTimeout(() => setIsShowMenu(!isShowMenu), 250)}>
          {roomState === 'disconnected' 
            ? <div>
                  <p>Loading...</p>
                  <img src={Loading}></img>
                  <p>Please check your connection if <br/>the call is taking too long to load</p>
              </div> 
            : <MainParticipant />}
          <Controls />
        </div>
        <div className="user2">
          {roomState === 'disconnected' || <ParticipantStrip />}
        </div>
      </Main>
      {/*<ReconnectingNotification />*/}
      <Modal
          visible={settingPopUp}
          transparent
          maskClosable={false}
          className="settings-popup"
      >
          <div className="video-settings">
          <p style={{ fontSize: '1.3em', margin: 0 }}>While you're waiting..</p>
          <p style={{ fontSize: '1.1em', margin: 0 }}>Test your Audio & Video to ensure you have a smooth call.</p>
          <div>
          <div>
            <FormControl fullWidth>
              <Typography variant="h6">Speaker:</Typography>
            </FormControl>
            <Button
              size="small"
              style={{ display: 'inline-block'}}
              className="outline-blue-btn"
              onClick={toggleTestAudio}>
              {testingAudio ? "Stop Test" : "Test Speaker"}
            </Button>
          </div>
          <div>
            <FormControl fullWidth>
              <Typography variant="h6">Microphone:</Typography>
            </FormControl>
            <LocalAudioLevelIndicator />
          </div>
          <div>
            <FormControl fullWidth>
              <Typography variant="h6">Video:</Typography>
            </FormControl>
            <div className="video-preview">
              {localVideoTrack ? (
                <VideoTrack isLocal track={localVideoTrack} />
              ) : <p>Camera not available</p>}
            </div>
          </div>
            <Button onClick={() => { 
              if (testingAudio) {
                toggleTestAudio()
              }
              setSettingPopUp(false); 

              // @ts-ignore
              if (token && AuthGuard.videoCallInfo.canConnect) {
                // @ts-ignore
                AuthGuard.videoCallInfo.canConnect = false
                localTracks.forEach(track => track.restart())
                // setError({code: 123, name: 'name', message: 'msg'})
                connect(token)
              }
            }}>
              Proceed to Call
            </Button>
          </div>
          </div>
      </Modal>
    </div >
  );
}
