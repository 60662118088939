import React, { Component } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { WingBlank, Modal, Carousel, SegmentedControl, Text, Flex, WhiteSpace, InputItem, NavBar, Icon, Picker, Button, Tabs, Toast, Checkbox, SearchBar, List, Drawer } from 'antd-mobile';
import { ROUTES, RespondCode, GAID, GOOGLE_ANALYTICS } from "../commons/Constants";
import AuthGuard from '../services/AuthGuard';
import { addKeyValueForDorpList, isPostalCode, getCache, currencyType, setCache, removeCache } from '../commons/Helpers';
import GoogleMapReact from 'google-map-react';
import { StickyContainer, Sticky } from 'react-sticky';
import { deviceType } from 'react-device-detect';
import { relativeTimeThreshold } from 'moment';
import img from '../images/icons/Omron.png'
import banner_nobg from '../images/icons/banner_nobg.png'
import icon1 from '../images/icons/general-practioner.png'
import icon2 from '../images/icons/doctor1.png'
import icon3 from '../images/icons/stethoscope.png'
import iconStep1 from '../images/icons/icon_step1.png'
import iconStep2 from '../images/icons/icon_step2.png'
import iconStep3 from '../images/icons/icon_step3.png'
import logo from '../images/icons/logo.png'
import menu from '../images/icons/blue-menu.png'
import iphone from '../images/icons/image_s.png'
import localIcon from '../images/icons/location-icon.png'
import telemeddoct from '../images/icons/telemeddoct.png'
import iconIappsOmron from '../images/icons/icon_iapps_omron.png'
import iconDoctorAvatar from '../images/icons/icon_doctor_avatar.png'
import drIcon from '../images/icons/dr_icon.png'
import searchIcon from '../images/icons/search_icon.png'
import consultOurDietician from '../images/icons/consult_our_dietician.png'
import heartIcon from '../images/icons/heart_icon.png'
import Menu from '../component/Menu';
import { Animated } from "react-animated-css";
import {
    isBrowser,
    isMobile
} from "react-device-detect";
import PCHome from './PCHome';
import { WHATSAPP_MOBILENO } from '../commons/Constants';
import LoginModel from '../component/LoginModel';
import { ViewMore } from '../component/ViewMore';
import ReactGA from "react-ga";
import { ReactGAEvent } from '../commons/ReactGAEvent';
import {getDieticianMouble} from '../commons/Constants';


class WebHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userProfileId: '',
            localDoctor: false,
            user_lat: '',
            user_long: '',
            menu_list: [],
            openMenu: false,
            isMobileSize: false,
            popUpDr: false,
            dr_photo: '',
            dr_name: '',
            specialty: '',
            clinic_name: '',
            clinic_address: '',
            clinic_country: '',
            clinic_dialing_code: '',
            clinic_contact_no: '',
            consultation_fee: '',
            teleconsultation_currency_code: '',
            identification: '',
            postal_code: '',
            doctor_id: '',
            requestconsultBtnLoading: false,
            user_drug_allergy: '',
            user_source: '',
            user_is_complete: '',
        }
    }

    componentWillMount() {
        if (GOOGLE_ANALYTICS) {
            ReactGA.set({
                page: window.location.href,
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (GOOGLE_ANALYTICS) {
            ReactGA.set({
                page: window.location.href,
            });
        }
    }

    async componentDidMount() {
        let { token } = this.props.match.params;
        if (token) await AuthGuard.getNewToken(token)
        this.setState({
            menu_list: Menu()
        })
        window.addEventListener('scroll', this.handleScroll.bind(this)) //监听滚动
        window.addEventListener('resize', this.handleResize.bind(this)) //监听窗口大小改变

        let len = window.location.href.split("/");
        let lenLength = len.length;

        if (lenLength == 6) {
            if (len[5]) await AuthGuard.getNewToken(len[5])
            if (len[4] == 'consultAnyGP') {
                this.setState({
                    localDoctor: true
                })
            }
        }

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll.bind(this))
        window.removeEventListener('resize', this.handleResize.bind(this))
    }

    handleScroll = e => {
        // console.log( 
        //     '浏览器滚动事件',
        //     e.srcElement.scrollingElement.scrollTop,
        //     e.srcElement.scrollingElement.scrollHeight
        // )
        //e.srcElement.scrollingElement.scrollTop为距离滚动条顶部高度
        // e.srcElement.scrollingElement.scrollHeight为整个文档高度
    }

    handleResize = e => {
        // console.log('浏览器窗口大小改变事件', e.target.innerWidth)
        this.setState({
            isMobileSize: e.target.innerWidth > 1220 ? false : true
        })

    }

    onClose() {
        console.log('close')
    }
    checkToken(filterKey) {
        console.log(filterKey)
        removeCache('filter_docotor');
        ReactGAEvent("Go TO DoctorList", "onclick " + filterKey ? filterKey : 'Find a Doctor' + " Btn");
        this.props.history.push({ pathname: "/doctorList", state: { filterKey } })
    }
    notAllow() {
        this.loadingToast()
        AuthGuard.notAddress({}, (isOk, res) => {
            if (isOk) {
                let { id, photo, doctorinfo, clinicinfo, consultation_fee, specialty, profile, identification } = res.result;
                let _specialty = '-'
                let _specialtyArr = []
                if (specialty && specialty.length > 0) {
                    specialty.map((item, index) => {
                        _specialtyArr.push(item.display_name)
                    })
                    _specialty = _specialtyArr.join(' / ')
                }
                this.setState({
                    doctor_id: id ? id : '',
                    dr_photo: photo ? photo.url.o : '',
                    dr_name: doctorinfo.name || '--',
                    specialty: _specialty || '--',
                    clinic_name: clinicinfo.clinic_name || '--',
                    clinic_address: clinicinfo.clinic_address || '--',
                    clinic_country: clinicinfo.country || '--',
                    clinic_dialing_code: clinicinfo.clinic_dialing_code || '',
                    identification: identification,
                    clinic_contact_no: clinicinfo.clinic_contact_no || '',
                    teleconsultation_currency_code: profile ? profile.teleconsultation_currency : '',
                    postal_code: clinicinfo.postal_code || '--',
                    consultation_fee: consultation_fee ? currencyType(consultation_fee, clinicinfo.profile && clinicinfo.profile.teleconsultation_currency_code ? clinicinfo.profile.teleconsultation_currency_code : '') : '0.00'
                }, () => {
                    this.popupDr()
                })
            } else {
                Modal.alert('', <div> You can book an appointment with a provider instead!</div>, [

                    { text: "View all providers", style: { color: '#108ee9' }, onPress: () => this.props.history.push({ pathname: "/doctorList" }) },
                    { text: 'Cancel', style: { color: '#108ee9' }, onPress: () => console.log('') },
                    { text: '', style: { display: 'none' }, onPress: () => console.log('') },
                ])
            }
            Toast.hide();
        });
    }
    allow() {
        if (!getCache('is_local_allow')) {
            setCache('is_local_allow', true);
        }
        this.setState({
            localDoctor: false
        }, () => {
            this.geoFindMe()
        })

    }
    getLocal() {
        ReactGAEvent("Consult Any General Practitioner click event", "onclick CGP Btn");
        if (getCache('is_local_allow')) {
            this.allow();
        } else {
            this.setState({
                localDoctor: true
            })
        }
    }
    loadingToast() {
        Toast.loading('Loading...', 30, () => {
            console.log('Load complete !!!');
        });
    }

    requestConsult = async (id) => {
        console.log(id)
        this.loadingToast()
        this.cancelConsult()
        this.setState({
            requestconsultBtnLoading: true
        })

        let _url = window.location.pathname

        if (!AuthGuard.checkLogin()) {
            Toast.hide()
            this.loginModel.alertLogin({
                url: _url,
                query: id,
                alert_title: 'You need a HeartVoice account to consult doctors.',
            })
        } else {
            try {
                let res = await this.checkProfile()
                console.log(res)
                Toast.hide();
                if (res) {
                    if (id !== '') {
                        if (navigator.userAgent.indexOf('heartvoice') !== -1) {
                            this.doMobileInqueue.call(this, id, 'app')
                        } else {
                            this.doMobileInqueue.call(this, id, 'whatsapp')
                        }
                    }
                }
            } catch (error) {
                Toast.hide()
                this.setState({
                  requestconsultBtnLoading: false,
                });
                if (error && error.status_code !== 401) {
                    Modal.alert('Complete Profile', <div className="complete_Profile">Please provide more information about
              yourself to initiate a teleconsultation. </div>, [
                        { text: 'Cancel', style: { color: '#108ee9' }, onPress: () => console.log('第0个按钮被点击了') },
                        { text: 'Proceed', style: { color: '#108ee9' }, onPress: () => this.goMyinfo() },
                        {
                            text: ' ',
                            style: { display: 'none' },
                            onPress: () => {
                                console.log('No')
                            }
                        }
                    ])
                }
            }
        }
    }

    checkProfile = () => {
        this.loadingToast()
        return new Promise((resolve, reject) => {
            AuthGuard.checkProfileComplete({}, (isOk, res) => {
                console.log(isOk, res)
                let { drug_allergy, is_complete, source } = res
                Toast.hide()
                if (isOk) {
                    if (is_complete) {
                        resolve(res)
                    } else {
                        this.setState({
                            user_drug_allergy: drug_allergy,
                            user_source: source,
                            user_is_complete: is_complete
                        }, () => {
                            reject(res)
                        })
                    }

                } else {
                    reject(res)
                }
            })
        })
    }

    goMyinfo = () => {
        let hasUrl = window.location.pathname;
        setCache('redirectUrl', hasUrl)
        if (this.state.user_source === "manually") {
            // manually 手动注册的用户
            this.props.history.push("/improve");
        } else {
            // myinfo myinfo注册的用户
            if (!this.state.user_is_complete && this.state.user_drug_allergy) {
                this.props.history.push("/improve");
            } else {
                this.props.history.push("/drugAllergy");
            }
        }

    }

    doMobileInqueue(id, channel) {
        this.loadingToast()
        this.setState({
            requestconsultBtnLoading: false
        })
        AuthGuard.mobileInqueue({
            doctor_id: id,
            channel: channel
        }, (isOk, res) => {
            console.log(isOk, res)
            this.setState({
                popUpDr: false
            })
            if (isOk) {
                if (res.message !== null) {
                    this.setState({
                        popUpDr: false
                    })
                    if (isBrowser) {
                        Modal.alert('Oops', <div>We are getting you a doctor. check your whatsapp for updates!</div>, [
                            { text: 'Okay', onPress: () => console.log('第0个按钮被点击了') }
                        ])
                    } else {
                        if (channel === 'app') {
                            window.location.href = 'heartvoice://heartvoice/home?action=home'
                        } else {
                            // this.consultAnyDoctor(code)
                            window.location.href = 'https://wa.me/' + WHATSAPP_MOBILENO
                        }
                    }
                }
            } else {
                Toast.hide();
                if (res.message !== null) {
                    if (res.status_code !== 401) {
                        Modal.alert('Oops', <div>{res.message}</div>, [
                            { text: 'Okay', onPress: () => console.log('第0个按钮被点击了') }
                        ])
                    }
                }
            }
        });
    }

    cancelConsult = () => {
        this.setState({
            popUpDr: false,
            localDoctor: false
        })
    }
    //获取经纬度
    geoFindMe() {
        if (!navigator.geolocation) {
            // Toast.info('Your browser does not support geographic location !!!', 1);
            Modal.alert('Oops', <div>Your browser does not support geographic location. You can try finding a doctor instead. </div>, [
                { text: 'Okay', onPress: () => console.log('') },
            ])
            return;
        }
        let success = (position) => {
            this.setState({
                user_lat: position.coords.latitude,
                user_long: position.coords.longitude
            }, () => {
                setCache('userLatLong', {
                    user_lat: position.coords.latitude,
                    user_long: position.coords.longitude
                })
                this.serchLocalDoctor()
            })

        };

        let error = (err) => {
            // Modal.alert('Oops', <div>we could not get your location! You can try finding a doctor instead. </div>, [
            //     { text: 'Okay', onPress: () => console.log('') },
            // ])
            this.checkToken('consult_general')
        };
        navigator.geolocation.getCurrentPosition(success, error);
    }

    onRef = (ref) => {
        this.loginModel = ref
    }

    serchLocalDoctor = () => {
        let self = this;

        let _url = "/telemedicine/"
        if (!AuthGuard.checkLogin()) {
            this.loginModel.alertLogin({
                url: _url,
                alert_title: 'You need a HeartVoice account to consult doctors.',
            })
            return false
        } else {

            this.loadingToast()

            AuthGuard.serchLocalDoctor({
                user_lat: this.state.user_lat,
                user_long: this.state.user_long
                // user_lat: 1.3519296,
                // user_long: 103.8183009,
            }, (isOk, res) => {
                if (isOk) {
                    let { id, photo, doctorinfo, clinicinfo, consultation_fee, specialty, profile, identification } = res
                    let _specialty = '-'
                    let _specialtyArr = []
                    if (specialty && specialty.length > 0) {
                        specialty.map((item, index) => {
                            _specialtyArr.push(item.display_name)
                        })
                        _specialty = _specialtyArr.join(' / ')
                    }
                    this.setState({
                        doctor_id: id ? id : '',
                        dr_photo: photo ? photo.url.o : '',
                        dr_name: doctorinfo.name || '--',
                        specialty: _specialty || '--',
                        clinic_name: clinicinfo.clinic_name || '--',
                        clinic_address: clinicinfo.clinic_address || '--',
                        clinic_country: clinicinfo.country || '--',
                        clinic_dialing_code: clinicinfo.clinic_dialing_code || '',
                        identification: identification,
                        clinic_contact_no: clinicinfo.clinic_contact_no || '',
                        teleconsultation_currency_code: profile ? profile.teleconsultation_currency : '',
                        postal_code: clinicinfo.postal_code || '--',
                        consultation_fee: consultation_fee ? currencyType(consultation_fee, clinicinfo.profile && clinicinfo.profile.teleconsultation_currency_code ? clinicinfo.profile.teleconsultation_currency_code : '') : '0.00'
                    }, () => {
                        this.popupDr()
                    })
                } else {
                    Modal.alert(
                        "No Providers Available",
                        <div>
                            We are sorry but our providers are not available at the
                            moment. You can check their available hours and book an
                            appointment.
                      </div>,
                        [
                            {
                                text: "Browse Doctors",
                                onPress: () =>
                                    self.checkToken('consult_general'),
                            },
                            {
                                text: "I'll try again later",
                                onPress: () => console.log("cancal"),
                            },
                            { text: " ", onPress: () => console.log("") },
                        ]
                    );
                }
                Toast.hide();
            });
        }



    }

    popupDr = () => {
        this.setState({
            popUpDr: true,
            requestconsultBtnLoading: false,
        })
    }

    openMenu = () => {
        this.setState({
            openMenu: !this.state.openMenu
        })
    }

    onWrapTouchStart = (e) => {
        // fix touch to scroll background page on iOS
        if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
            return;
        }
        // const pNode = closest(e.target, '.am-modal-content');
        // if (!pNode) {
        //     e.preventDefault();
        // }
    }

    dieticianMoudule = () => {
        let getDieticianUrl = getDieticianMouble();
        this.props.history.push(getDieticianUrl)
    }

    renderMobile = () => {
        return (
            <div className="home">
                <div className="home-header">
                    <WingBlank size="lg">
                        <a href="https://www.ourheartvoice.com/">
                            <img className="fl-left img-left" src={logo} alt=""></img>
                        </a>
                        <img className="fl-right img-menu" onClick={this.openMenu} src={menu} alt=""></img>
                    </WingBlank>
                </div>
                <div id="menu-list" className={this.state.openMenu ? 'menu-open' : ''}>
                    <ul className="menu-ul">
                        {this.state.menu_list.map((item, index) => {
                            return (
                                <li className="menu-ul-li" key={`menu-ul-li${index}`}>
                                    <div className={`menu-name ${item.children ? 'menu-parent' : ''}`}>
                                        {item.href ? <a href={item.href}> {item.name} </a> : item.name}
                                    </div>
                                    {item.children ? (
                                        <ul className="menu-ul-li-ul">
                                            {item.children.map((iitem, iindex) => {
                                                return (
                                                    <li className={`menu-ul-li-ul-li ${iitem.code === 'heartvoice_telemedicine' ? 'active-sub-meue' : ''}`} key={`menu-ul-li-ul-li-${iitem.code}`}>
                                                        <div className="menu-sub-name ">
                                                            <a href={iitem.href}>{iitem.name}</a>
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    ) : null}
                                </li>
                            )
                        })}
                    </ul>
                </div>

                <div className="banner">
                    <div className="content">
                        <p className="content-title1">Better health at your fingertips</p>
                        <p className="content-title2">Monitor your health vitals and see a Doctor online with HeartVoice</p>
                    </div>
                    <div className="iphoneImg">
                        <img src={iphone} alt=""></img>
                    </div>
                    <div className="greenLine">
                        <div className="line"></div>
                    </div>
                </div>
                <div className="btns">
                    <a className="outline-pink-btn" onClick={() => this.getLocal()}>
                        <img className="fl-left" src={drIcon} alt="" />
                        <span className="fl-right">Consult Any General Practitioner</span>
                    </a>
                    <a className="outline-pink-btn " onClick={() => this.checkToken('find_doctor')}>
                        <img className="fl-left" src={searchIcon} alt="" />
                        <span className="fl-right">Find a Doctor</span>
                    </a>
                    <a className="outline-pink-btn " onClick={() => this.dieticianMoudule()}>
                        <img className="fl-left" src={consultOurDietician} alt="" />
                        <span className="fl-right">Consult our Dietitian</span>
                    </a>
                    <a className="outline-pink-btn " onClick={() => this.checkToken('my_doctors')}>
                        <img className="fl-left" src={heartIcon} alt="" />
                        <span className="fl-right">My Doctors</span>
                    </a>
                    {/* <a className="fill-pink-btn " onClick={() => this.checkToken('active_sg')}>
                        <span>ActiveSG Promotion</span>
                    </a> */}
                    <a className="fill-pink-btn " onClick={() => this.checkToken('monitoring')} >
                        <span>Explore Monitoring Program</span>
                    </a>
                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />
                </div>
                <div className="steps">
                    <div className="steps-info">
                        <div className="info">
                            With our secured integration, you can now see a doctor simply via WhatsApp!
                        </div>
                    </div>
                    <div className="steps-items">
                        <div className="steps-item">
                            <div className="steps-item-icon">
                                <img src={iconStep1} alt="" />
                            </div>
                            <div className="steps-item-name">
                                Step 1:
                            </div>
                            <div className="steps-item-desc">
                                Select your preferred medical professional
                            </div>
                        </div>
                        <div className="steps-item">
                            <div className="steps-item-icon">
                                <img src={iconStep2} alt="" />
                            </div>
                            <div className="steps-item-name">
                                Step 2:
                            </div>
                            <div className="steps-item-desc">
                                See your doctor now or book an appointment via WhatsApp
                            </div>
                        </div>
                        <div className="steps-item">
                            <div className="steps-item-icon">
                                <img src={iconStep3} alt="" />
                            </div>
                            <div className="steps-item-name">
                                Step 3:
                            </div>
                            <div className="steps-item-desc">
                                You will be directed to WhatsApp to start a video consultation
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text">
                    <div className="fr-left">
                        <img src={telemeddoct} />
                    </div>
                    <div className="fl-right">
                        <p>
                            At HeartVoice, our mission is to make sure that you can receive convenient healthcare whenever you need it. With our busy day-to-day lives and limited time for self-care, we often neglect our health.
                        </p>
                        <p>
                            Hence, HeartVoice now allows you to connect with our SG-registered medical professionals easily through WhatsApp!
                        </p>
                        <p>
                            HeartVoice promises to be here for you no matter where you are on your health journey – be it a simple phone call to a GP or long-term chronic care and weight management support.
                        </p>
                    </div>
                </div>
                <div className="footer">
                    <img src={iconIappsOmron} alt="" />
                </div>
            </div>
        )
    }

    render() {
        return (
            <>
                {isMobile || this.state.isMobileSize ? this.renderMobile() : <PCHome getLocal={this.getLocal.bind(this)} checkToken={this.checkToken.bind(this)} dieticianMoudule={this.dieticianMoudule.bind(this)} />}
                <WingBlank size="lg">
                    <Modal
                        visible={this.state.localDoctor}
                        transparent
                        maskClosable={false}
                        title={<img src={localIcon} style={{ 'height': '40px' }} alt=""></img>}
                        footer={
                            [
                                { text: "Don't Allow", onPress: () => this.notAllow() },
                                { text: "Allow", onPress: () => this.allow() },
                            ]
                        }>
                        <div className="getAddModal">
                            Allow “HeartVoice” to access your location?
                    </div>
                    </Modal>
                </WingBlank>
                <WingBlank size="lg">
                    <Modal
                        visible={this.state.popUpDr}
                        transparent
                        maskClosable={false}
                        className="dr-pop-modal"
                    >
                        <div className="dr-pop-box">
                            <div className="dr-avatar">
                                <img src={this.state.dr_photo ? this.state.dr_photo : iconDoctorAvatar} alt="" />
                            </div>
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="sm" />
                            <div className="dr-name">
                                {this.state.dr_name}
                            </div>
                            <div className="dr-spec">
                                {/* {this.state.specialty} */}

                                {
                                    this.state.specialty && this.state.specialty.length >= 150 ?
                                        <ViewMore
                                            length={150}
                                            text={this.state.specialty}
                                        /> : this.state.specialty
                                }

                            </div>
                            <WhiteSpace size="lg" />
                            <div className="dr-desc">
                                <p>{this.state.clinic_name}</p>
                                <p>{this.state.clinic_address}</p>
                                <p>{this.state.clinic_country} {this.state.postal_code}</p>
                                <div>
                                    Consultation fee: {this.state.consultation_fee}
                                </div>
                            </div>
                            <WhiteSpace size="lg" />
                            <div className="dr-ftr-btn">
                                <Button className="fill-pink-btn" loading={this.state.requestconsultBtnLoading}  onClick={() => this.requestConsult(this.state.doctor_id)}>Request Consult</Button>
                                <Button className="outline-pink-btn" onClick={this.cancelConsult}>Cancel</Button>
                            </div>
                        </div>
                    </Modal>
                </WingBlank>
                <WingBlank size="lg">
                    <LoginModel
                        onRef={this.onRef}
                        history={this.props.history}
                    />
                </WingBlank>
            </>
        )

    }
}

export default WebHome
