import React, { Component } from 'react';
import { Flex, WhiteSpace, WingBlank,NavBar ,Icon,Toast, Modal, Button, Accordion, List } from 'antd-mobile';
import AuthGuard from '../services/AuthGuard'
import { setCache, removeCache, getCache, currencyType, isInvalid } from '../commons/Helpers';
import { SumTotalAmount,FLN,MulTotalAmount} from '../commons/ComputerBill';
import banner_nobg from '../images/icons/banner_nobg.png'
import LoginModel from '../component/LoginModel';
import { isBrowser } from 'react-device-detect';

class GECheckOutPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            packages_id: '',
            name: '',
            price: '',
            photo: '',
            discount_rate: 0,
            campaign_id: '',
            couponId: getCache('selectPaymentCouponId'),
            selectedCoupon: false,
            loading: false,
            disabled: false,
            is_share_personal_data:'N',
            is_contacted_by_ge: 'N'
        }
    }

    componentWillMount() {
        
    }

    componentDidMount() {
        let {package_id,is_share_personal_data,is_contacted_by_ge} = this.props.match.params;
        if(is_share_personal_data && is_contacted_by_ge){
            this.setState({
                is_share_personal_data: is_share_personal_data,
                is_contacted_by_ge: is_contacted_by_ge,
            })
            if(package_id){
                this.loadingToast();
                AuthGuard.packagesCheckDetail({
                    packages_id: package_id,
                }, (isOk,res) => {
                    Toast.hide()
                    if(isOk){
                        this.setState({
                            packages_id: package_id,
                            name: res.name,
                            price: res.price,
                            photo: res.photo.url.o,
                            user_profile_id: res.user_profile_id,
                        })
                        this.updateData();
                        this.getSelectedCouponDetail();
                    }else{
                        console.log(res)
                    }
                    this.setState({
                        loadding: false,
                    })
                })
            }else{
                console.log('无效id')
            }
            removeCache('geInfo');
        }else{
            this.props.history.push('/marketPlaceDetail/' + package_id);
            console.log('无效id');
            removeCache('geInfo');
        }
    }
    onRef = (ref) => {
        this.loginModel = ref
    }

    updateData = async () => {
        let { couponId } = this.state
        let _totalAmount = 0.00
        let _displayCounponAmount = 0.00
        let _subTotalAmount = this.state.price
        if (couponId){
            try {
                let res =  await this.getCouponPrice()
                if (!isInvalid(res.coupon_price)){
                    _displayCounponAmount = res.coupon_price 
                }
            } catch (error) {
                _displayCounponAmount = 0.00
            }
        }else{
            _displayCounponAmount = null
        }
        _totalAmount = SumTotalAmount(this.state.price,0, _displayCounponAmount);
        if(_totalAmount <= 0){
            _totalAmount = parseFloat(0).toFixed(2);
        }
        this.setState({
            displayCounponAmount: _displayCounponAmount,
            totalAmount: _totalAmount,
            subTotalAmount: _subTotalAmount,
        })
    }

    getCouponPrice (){
        let selectedCoupon = this.state.selectedCoupon;
        if(selectedCoupon){
            if(selectedCoupon.promo_type.code == 'freemium'){
                return {coupon_price: this.state.price}
            }else if(selectedCoupon.promo_type.code == 'fixed_price'){
                if(!isInvalid(this.state.price) && !isInvalid(selectedCoupon.amount)){
                    let new_coupon_price = SumTotalAmount(this.state.price,0,selectedCoupon.amount);
                    return {coupon_price: new_coupon_price}
                }
                return {coupon_price: 0.00}
            }else if(selectedCoupon.promo_type.code == 'minimum_spending'){
                let new_coupon_price =  0.00;
                if(selectedCoupon.discount_type.code == 'amount_off'){
                    new_coupon_price = selectedCoupon.discount_amount;
                }else if(selectedCoupon.discount_type.code == 'percentage_off'){
                    if(!isInvalid(this.state.price) && !isInvalid(selectedCoupon.discount_amount)){
                        new_coupon_price = MulTotalAmount(this.state.price,selectedCoupon.discount_amount,100);
                    }
                }else{
                    new_coupon_price =  0.00;
                }
                return {coupon_price: new_coupon_price}
            }else{
                return {coupon_price: 0.00}
            }
        }else{
            return {coupon_price: 0.00}
        }
    }

    getSelectedCouponDetail = () =>{
        let _this = this;
        if(this.state.couponId){
            AuthGuard.getPromoCodeDetailV2({
                user_promo_reward_id: this.state.couponId,
                package_id: this.state.packages_id
    
            }, (isOk, res) => {
                if(isOk){
                    if(res.status_code == 'available'){
                        this.setState({
                            selectedCoupon: res
                        }, ()=>{
                            this.updateData();
                        });
                    }
                }
            });
        }else{
            console.log('not coupon')
        }
    }

    coupon = () => {
        let data = {packages_id: this.state.packages_id,coupon_type: 'GE',user_profile_id: this.state.user_profile_id}
        this.props.history.push({ pathname: "/paymentCoupon", query: data});
    }

    updateCoupon = ()=>{
        removeCache('selectPaymentCouponId')
        this.setState({
            selectedCoupon: null
        })
    }

    ProceedToPayment = () => {
        if(!isBrowser){
            Modal.alert('Proceed to App', <div>If you have the HeartVoice app, you can continue on the app with your existing HeartVoice account! </div>, [
                {
                    text: "Continue on Web", style: { color: '#50B2C2', fontSize: '16px' }, onPress: () => {
                        this.updateCoupon();
                        this.Pay();
                    }
                },
                {
                    text: 'Go to App', style: { color: 'red', fontSize: '16px' }, onPress: () => {
                        Modal.alert('Proceed to App', <div>You can proceed to open the HeartVoice app if you have installed it on your device!</div>, [
                            {
                                text: "Download App", style: {color: '#50B2C2', fontSize: '16px' }, onPress: () => {
                                    window.location.href = 'http://onelink.to/myazfx';
                                }
                            },
                            {
                                text: 'Open App', style: {color: '#50B2C2', fontSize: '16px' }, onPress: () => new Promise((resolve, reject) => {
                                    window.location.href = 'heartvoice://heartvoice/home?action=GEcheckout&packages_id=' + this.state.packages_id + '&is_share_personal_data=' + this.state.is_share_personal_data + '&is_contacted_by_ge=' + this.state.is_contacted_by_ge
                                    reject('')
                                })
                            },
                        ])
                    }
                },
            ])
        }else{
            this.updateCoupon();
            this.Pay();
        }
    }

    loadingToast() {
        Toast.loading('Loading...', 30, () => {
        })
    }

    Pay = () =>{
        if (!AuthGuard.checkLogin()) {
            let _url = window.location.pathname
            this.loginModel.alertLogin({
                url: _url,
                alert_title: 'You need a HeartVoice account to consult doctors.'
            })
        }else{
            if(this.state.totalAmount == 0.00){
                this.loadingToast();
                AuthGuard.creatGE({
                    packages_id: this.state.packages_id,
                    total_money: this.state.totalAmount,
                    coupon_id:this.state.couponId,
                }, (isOk, res) => {
                    this.setState({
                        loading: false
                    });
                    removeCache('proInfo')
                    if (isOk) {
                        let transaction_id = res.transaction.id;
                        removeCache('ge_transaction');
                        setCache('ge_transaction', {transaction_id: transaction_id});
                        setCache('geInfo', {totalAmount: this.state.totalAmount,ge_id:this.state.packages_id });
                        this.props.history.push({ pathname: "/gESuccess/"});
                    }else{
                        setCache('geInfo', {ge_id:this.state.packages_id,is_share_personal_data: this.state.is_share_personal_data,is_contacted_by_ge: this.state.is_contacted_by_ge });
                        this.props.history.push({ pathname: "/gEFail/"});
                    }
                    Toast.hide()
                })
            }else{
                setCache('proInfo', {
                    totalAmount: this.state.totalAmount,
                    couponId: this.state.couponId,
                    is_share_personal_data: this.state.is_share_personal_data,
                    is_contacted_by_ge: this.state.is_contacted_by_ge,
                })
                this.props.history.push({ pathname: "/programsPayment/" + this.state.packages_id + "/" + 'GE'});
            }
        }
    }

    goBack = ()=>{
        this.props.history.push('/marketPlaceDetail/' + this.state.packages_id)
    }

    render() {
        return (
            <div className="Paymet-Page checkout-page background-white">
                <LoginModel
                    onRef={this.onRef}
                    history={this.props.history}
                    />
                <div className="content py-1">
                <Flex >
                    <Flex.Item className="content-contain px-5 pb-5">
                        <NavBar
                        style={{marginLeft:'-0.5rem'}}
                            mode="light"
                            icon={<Icon type="left" color="#EB5DA6" size="lg"/>}
                            onLeftClick={this.goBack}
                        ></NavBar>
                        <WingBlank size="sm">  
                            <Flex className="pb-5">
                                <p className="checkout-title-font">Checkout</p>
                            </Flex>
                        </WingBlank>
                    </Flex.Item>
                </Flex>
                <Flex>
                    <Flex.Item className="content-contain px-5 pb-5">
                    <WhiteSpace size="lg" />
                    <WingBlank size="sm">  
                        <Flex className="pb-5">
                            <Flex.Item>
                                <div className="check-img-dev div-img">
                                    <img src={this.state.photo} alt="" className="check-img ge-img"/>
                                </div>
                            </Flex.Item>
                            <Flex.Item className="flex-grow-15 check-info">
                                <div className="f-uppercase check-info-name">{this.state.name}</div>
                                <div className="text-right">Qty: 1</div>
                                <div className="now-price">${this.state.price}</div>
                            </Flex.Item>
                        </Flex>
                        </WingBlank>
                    </Flex.Item>
                </Flex>
                <Flex className="bar pb-5"></Flex>
                <Flex>
                    <Flex.Item>
                        <div className="coupon-selection">
                            <WhiteSpace size="md" />
                            {!this.state.selectedCoupon ? 
                            (
                                <List.Item
                                    className="CouponDiv"
                                    arrow="horizontal"
                                    onClick={this.coupon.bind(this)}
                                    style={{border:'0px'}}
                                    // data={}
                                >
                                    Coupon 
                                </List.Item>
                                    ) : (
                                    <div className="selected-coupon">
                                        <div className="selected-coupon-title">Coupon</div>
                                        <div className="selected-coupon-detail" onClick={this.coupon.bind(this)}>
                                            <div className="selected-coupon-code">{this.state.selectedCoupon.code}</div>
                                            <div className="selected-coupon-name">{this.state.selectedCoupon.name}</div>
                                        </div>
                                    </div>
                                    )
                            }
                               
                            <WhiteSpace size="md" />
                        </div>
                    </Flex.Item>
                </Flex>
                <Flex className="bar pb-5"></Flex>
                <Flex>
                    <Flex.Item>
                        <WingBlank>
                            <WhiteSpace size="md" />
                            <Flex>
                                <Flex.Item>
                                    <div className="page-title py-5"> Summary </div>
                                    <ul className="billList">
                                        <li className="billitem">
                                            <Flex justify="between">
                                                <div className="item-title text-b">
                                                    Subtotal
                                                </div>
                                                <div className="item-amount">
                                                    ${this.state.subTotalAmount}
                                                </div>
                                            </Flex>
                                        </li>
                                        {this.state.displayCounponAmount ? 
                                        <li className="billitem">
                                            <Flex justify="between">
                                                <div className="item-title text-b">
                                                Coupon Discount
                                                </div>
                                                <div className="item-amount">
                                                    -${this.state.displayCounponAmount}
                                                </div>
                                            </Flex>
                                        </li>
                                        : ''}
                                        <li className="billitem text-big">
                                            <Flex justify="between">
                                                <div className="total-title">
                                                    <big>Total</big>
                                                </div>
                                                <div className="total-amount">
                                                    <big>${this.state.totalAmount}</big>
                                                </div>
                                            </Flex>
                                        </li>
                                    </ul>
                                           
                                </Flex.Item>
                            </Flex>
                            <WhiteSpace size="md" />
                        </WingBlank>
                    </Flex.Item>
                </Flex>
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WingBlank>
                    <div className="btn proceed-btn pb-5" style={{marginTop:'40%'}}>
                        <Flex style={{border:'0px'}}>
                            <Flex.Item>
                                <Button
                                    className="fill-pink-btn"
                                    activeClassName="fill-pink-active-btn"
                                    loading={this.state.loading}
                                    onClick={this.ProceedToPayment.bind(this)}
                                    disabled={this.state.disabled}
                                >
                                    Proceed to Payment
                                </Button>
                            </Flex.Item>
                        </Flex>
                    </div>
                </WingBlank >
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                </div>
            </div>
        );
    }
}
export default GECheckOutPage;