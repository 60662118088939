import React, { Component } from 'react';
import DatePicker from 'react-mobile-datepicker';

const monthMap = {
    '1': 'Jan',
    '2': 'Feb',
    '3': 'Mar',
    '4': 'Apr',
    '5': 'May',
    '6': 'Jun',
    '7': 'Jul',
    '8': 'Aug',
    '9': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec',
};

const dateConfig = {
    'date': {
        format: 'DD',
        caption: 'Day',
        step: 1,
    },
    'month': {
        format: value => monthMap[value.getMonth() + 1],
        caption: 'Mon',
        step: 1,
    },
    'year': {
        format: 'YYYY',
        caption: 'Year',
        step: 1,
    },
};

const dateTimeConfig = {
    'date': {
        format: 'DD',
        caption: 'Day',
        step: 1,
    },
    'month': {
        format: value => monthMap[value.getMonth() + 1],
        caption: 'Mon',
        step: 1,
    },
    'year': {
        format: 'YYYY',
        caption: 'Year',
        step: 1,
    },
    'hour': {
        format: 'hh',
        caption: 'Hour',
        step: 1,
    },
    'minute': {
        format: 'mm',
        caption: 'Min',
        step: 1,
    },
}

export const preventBackgroundScroll = (e /*: React.WheelEvent<HTMLDivElement>*/) => {
    const target = e.currentTarget
    if (
        (e.deltaY < 0 && target.scrollTop <= 0) ||
        (e.deltaY > 0 && target.scrollHeight - target.clientHeight - target.scrollTop <= 0)
    ) {
        e.stopPropagation()
        e.preventDefault()
    }
}


class UDatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // isDatePickerOpen: true,
            // birth:'1906-02-01'
        }
    }

    render() {
        return (
            <>
                <DatePicker
                    onWheel={preventBackgroundScroll}
                    theme="ios"
                    dateConfig={this.props.mode === "date" ? dateConfig : dateTimeConfig}
                    showHeader={this.props.showHeader}
                    mode={this.props.mode}
                    max={this.props.max}
                    min={this.props.min}
                    value={this.props.value}
                    isOpen={this.props.isOpen}
                    onSelect={this.props.onSelect}
                    onCancel={this.props.onCancel}
                    confirmText="OK"
                    cancelText="Cancel"
                >
                </DatePicker>
            </>
        );
    }
}

export default UDatePicker;