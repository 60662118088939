export default () => {
    return [
        {
            name: 'Home',
            code: 'home',
            href: 'https://www.ourheartvoice.com/'
        },
        {
            name: 'About Us',
            code: 'about_us',
            href: 'https://www.ourheartvoice.com/about-us/'
        },
        {
            name: 'For Patients',
            code: 'for_patients',
            children: [
                {
                    name: 'Heartvoice Telemedicine',
                    code: 'heartvoice_telemedicine',
                    // href: '#'
                },
                {
                    name: 'Activesg Telemedicine',
                    code: 'activesg_telemedicine',
                    href: ' https://www.ourheartvoice.com/doctors/'
                },
            ]
        },
        {
            name: 'For Doctors',
            code: 'for_doctors',
            children: [
                {
                    name: 'Join Doctors Panel',
                    code: 'join_doctors_panel',
                    href: 'https://www.ourheartvoice.com/telehealth-platform-signup/'
                }
            ]
        },
        {
            name: 'For Corporates',
            code: 'for_corporates',
            children: [
                {
                    name: 'Wellness Solution',
                    code: 'wellness_solution',
                    href: 'https://www.ourheartvoice.com/how-it-works/'
                },
                {
                    name: 'Corporate Benefits',
                    code: 'corporate_benefits',
                    href: 'https://www.ourheartvoice.com/corporate-benefits/'
                },
                {
                    name: 'OUR PARTNERS',
                    code: 'our_partners',
                    href: 'https://www.ourheartvoice.com/our-partners/'
                },
                {
                    name: 'MEWC',
                    code: 'mewc',
                    href: 'https://www.ourheartvoice.com/weight-control-program/'
                },
            ]
        },
        {
            name: 'Blog',
            code: 'blog',
            href: 'https://www.ourheartvoice.com/news/'
        },
        {
            name: 'FAQs',
            code: 'faqs',
            href: 'https://www.ourheartvoice.com/faqs/'
        },
        {
            name: 'Contact',
            code: 'contact',
            href: 'https://www.ourheartvoice.com/contact-us/'
        },
    ]
}