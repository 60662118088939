import React, { Component } from 'react';
import { Icon } from 'antd-mobile';
import IconArrow from "../images/icons/arrow_down_filling.svg"
class CustomChildren extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        let props = this.props;
        return (  
            <div
                onClick={props.onClick}
                className="picker-select2"
            >
                <div className="picker-value">
                    <span className={props.sClas}>{props.extra}</span>
                </div>
                <div className='picker-icon'>
                    <img src={IconArrow} />
                </div>
            </div>

        );
    }
}
 
export default CustomChildren;