import React from 'react';
import routes from './routes'
import AuthGuard from "./services/AuthGuard";
import {
  HashRouter as Router,
  BrowserRouter,
  Switch,
  Route,
  Redirect  
} from "react-router-dom";

//挂载 Mock
// if (process.env.REACT_APP_MOCK_API === "Y")  require("./mock");

function App() {

  (async () => {
    let hostName = window.location.protocol + "//" + window.location.host; //
    let canAccess = await AuthGuard.checkIpWhiteList();
    if (!canAccess) {
      window.location.href = hostName +  "/downtime.html";
      return;
    }
  })()
  return (
    <BrowserRouter>
      <Switch>
         {routes.map((route , i)=>{
           if(route.exact){
             return (
               <Route
                 key={i}
                 exact
                 path={route.path}
                 render={(props) =>
                   <route.component {...props} routes={route.routes} />
                 }
               />
             )
           }else{
             return (
               <Route
                 key={i}
                 path={route.path}
                 render={(props) =>
                   <route.component {...props} routes={route.routes} />
                 }
               />
             )
           }
         })}
      </Switch>
    </BrowserRouter>
  );
}


export default App;
