import React, { Component } from 'react';
import { Flex, WhiteSpace, WingBlank,NavBar ,Icon, Button, PickerView } from 'antd-mobile';
import male_selected from '../images/icons/male_selected.png';
import AuthGuard from '../services/AuthGuard';

class ProfileResultHeight extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            heightList: [],
            user_profile_id: '',
            height:['165.0'],
            loaddingHeight: false,
            disabledHeight:false,
        }
    }

    componentDidMount() {
        let _heightList_v1 = [];
        let _heightList_v2 = [];
        let _heightList = [];
        let min = 100;
        let max = 220;
        _heightList_v1 = Array.from({length: (max - min) / 0.5 + 1}, (x, i) => {
            let v = parseFloat(min + i * 0.5).toFixed(1);
            return {label: v, value: v};
        })
        _heightList_v2.push({label:'cm',value:'cm'});
        _heightList.push(_heightList_v1);
        _heightList.push(_heightList_v2);
        this.setState({
            heightList: _heightList,
        })
        let {id} = this.props.match.params;
        if(id){
            this.setState({
                user_profile_id: id,
            })
            console.log('user_profile_id',id);
        }else{
            console.log('无效id')
        }
    }

    goBack = ()=>{
        this.props.history.push('/myinfo')
    }

    confirm = ()=>{
        this.setState({
            loaddingHeight: true,
            disabledHeight: true,
        })
        let _height = this.state.height[0];
        AuthGuard.updateHeight({
            height: _height,
            userProfileId: this.state.user_profile_id,
        }, (isOk, res) => {
            if(isOk){
                this.props.history.push('/referralCode/' + this.state.user_profile_id)
            }else{
                console.log(res)
            }
        })
        this.setState({
            loaddingHeight: false,
            disabledHeight: false,
        })
    }

    render() {
        return (
            <div className="referral-code-title">
                <NavBar
                    mode="light"
                    icon={<Icon type="left" color="#EB5DA6" size="lg"/>}
                    onLeftClick={this.goBack}
                ></NavBar>
                <div className="">
                    <WingBlank>
                        <Flex>
                            <div className="page-title py-1">What's your height?</div>
                        </Flex>
                    </WingBlank>
                    <WhiteSpace />
                    <WhiteSpace />
                    <WhiteSpace />
                    <WhiteSpace />
                    <Flex.Item>
                    <WhiteSpace />
                        <div className="referral-img">
                        <img src={male_selected} alt="height_logo" className="img-style"/>
                        </div>
                        <WhiteSpace />
                    </Flex.Item>
                    <Flex.Item>
                        <div className="referral-pick">
                        <PickerView
                            data={ this.state.heightList }
                            cascade={false}
                            value={this.state.height}
                            onChange={v => {
                                console.log(v);
                                this.setState({
                                    height: v
                                })
                            }}
                        >
                        </PickerView>
                        </div>
                    </Flex.Item>

                    <WingBlank size="lg">
                        <Button
                            disabled={this.state.disabledHeight}
                            className="outline-pink-btn pb-5"
                            activeClassName="fill-pink-active-btn"
                            loading={this.state.loaddingHeight}
                            onClick={this.confirm.bind(this)}
                        >
                            Let's Go
                        </Button>
                    </WingBlank>
                </div>
            </div>
        );
    };
}
export default ProfileResultHeight;