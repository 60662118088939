import React, { Component } from 'react';
import { WhiteSpace, NavBar, Icon, Toast, Button } from 'antd-mobile';
import AuthGuard from '../services/AuthGuard';
import { showDate, setCache } from '../commons/Helpers';

class CouponDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            delivery_id: '',
            promo_row_id: '',
            user_profile_id: '',
            loading: false,
            msgError: "",
            msgSeccess: "",
            promocodeDetail: {},
            usage:[]
        }
    }

    componentDidMount() {
        let id = this.props.location.query!==undefined ? this.props.location.query.id : '';
        let delivery_id = this.props.location.query!==undefined ? this.props.location.query.delivery_id : '';
        let promo_row_id = this.props.location.query!==undefined ? this.props.location.query.promo_row_id : '';
        if(id === '' || promo_row_id === ''){
            this.props.history.goBack()
        }else{
            this.setState({
                id: id,
                delivery_id: delivery_id,
                promo_row_id: promo_row_id
            }, () => {
                this.getBillInfo();
            });
        }
    }

    loadingToast() {
        Toast.loading('Loading...', 30, () => {
            console.log('Load complete !!!');
        });
    }

    getBillInfo() {
        this.loadingToast();
        let self = this;
        AuthGuard.userCheckBill({
            userQueueId: self.state.id
        }, (isOk, res) => {
            if (isOk) {
                console.log(res)
                self.setState({
                    user_profile_id: res.user_profile_id
                },()=>{
                    this.getPromoCodeDetail();
                })
            }
            Toast.hide();
        });
    }

    getPromoCodeDetail() {
        let self = this;
        console.log('get')
        AuthGuard.getPromoCodeDetail({
            user_profile_id: self.state.user_profile_id,
            user_promo_reward_id: self.state.promo_row_id,
            user_queue_id: self.state.id,
            delivery_id: self.state.delivery_id
        }, (isOk, res) => {
            if (isOk) {
                console.log('detail===>'+res)
                console.log(res.usage)
                self.setState({
                    promocodeDetail: res,
                    usage:res.usage || []
                    
                })
            }
            Toast.hide();
        });
    }

    useCoupon() {
        setCache('selectCouponId', this.state.promo_row_id)
        this.props.history.goBack(2)
    }

    coupon = () => {
        let data = {id: this.state.id, delivery_id: this.state.delivery_id}
        this.props.history.push({ pathname: "/coupon", query: data});
    }

    render() {
        let { status, status_code} = this.state.promocodeDetail
        return (
            <div className="coupon-detail">
                <NavBar
                    mode="light"
                    icon={<Icon type="left" size="lg" color="#EB5DA6" size="lg"/>}
                    onLeftClick={this.coupon}
                    className="backBtn"
                ></NavBar>
                <div className="coupon-detail-content">
                    <div className="coupon-detail-title">Coupon Details</div>
                    <WhiteSpace />
                    <div className="coupon-detail-title-lg">{this.state.promocodeDetail.code}</div>
                    <WhiteSpace />
                    <div className="coupon-detail-name">{this.state.promocodeDetail.name}</div>
                    <div className="coupon-detail-gray">Expires on: {showDate(this.state.promocodeDetail.expiry_date, 8)}</div>
                    <WhiteSpace /><WhiteSpace />
                    <div className="coupon-detail-title-b mb-1">Description</div>
                    <div className="coupon-detail-text">{this.state.promocodeDetail.description}</div>
                    <WhiteSpace /><WhiteSpace />
                    <div className="coupon-detail-title-b mb-1">Terms & Conditions</div>
                    <div className="coupon-detail-text">{this.state.promocodeDetail.tc}</div>
                    <WhiteSpace /><WhiteSpace />
                    <div className="coupon-detail-title-b mb-1">Usage Restrictions</div>
                    <div className="coupon-detail-text">
                        <div>Applicable for the following</div>
                        {this.state.usage.map((item, index)=>(
                            <div key={index}>-{item}</div>
                        ))}
                    </div>
                </div>
                <Button
                    disabled={status_code !== 'available'}
                    className="use-coupon-btn fill-pink-btn"
                    onClick={this.useCoupon.bind(this)}
                >
                    {status_code === 'available' ?  'Use Coupon' : status}
                </Button>
            </div>

        );
    }
}


export default CouponDetail;