import React, { Component } from 'react';
import { Flex, WhiteSpace, WingBlank, TabBar, Icon,NavBar, Grid, Toast, Modal, ListView, Button } from 'antd-mobile';
import success from '../images/icons/success.png'
import { getCache ,currencyType} from '../commons/Helpers';

class PaymentResultSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalAmount: 0.00,
            currency_code:'',
        }
    }

    okay = ()=>{
        this.props.history.push({ pathname: "/programsSuccess/"});
    }

    componentDidMount() {
        if (getCache('pro_info')) {
            let {totalAmount,currency_code} = JSON.parse(getCache('pro_info'));
            this.setState({
                totalAmount: totalAmount,
                currency_code: currency_code,
            })
        }else{
            this.props.history.push("/404");
        }
    }

    goBack = ()=>{
        this.props.history.push('/doctorList/')
    }

    render() {
        return (
            <div className="Paymet-Page result-success-fail-page">
                <NavBar
                    mode="light"
                    icon={<Icon type="left" color="#EB5DA6" size="lg"/>}
                    onLeftClick={this.goBack}
                ></NavBar>
                <Flex className="content">
                    <Flex.Item>
                        <WingBlank>
                            <WhiteSpace size="md" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <Flex direction="column">
                                <img src={success} className="icon-payment-fail" alt="Result - Fail" />
                                <WhiteSpace size="lg" />
                                <WhiteSpace size="lg" />
                                <WhiteSpace size="lg" />
                                <WhiteSpace size="lg" />
                                <div className="result-title">Payment Success</div>
                                <WhiteSpace size="md" />
                                <div className="result-desc">Your payment for ${this.state.totalAmount}</div>
                                <div className="result-desc">was successfully completed.</div>
                            </Flex>
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WingBlank size="lg">
                                <WingBlank size="lg">
                                    <WingBlank size="lg">
                                        <WingBlank size="lg">
                                            <WingBlank size="lg">
                                                <Button
                                                    className="fill-pink-btn"
                                                    activeClassName="fill-pink-active-btn"
                                                    onClick={this.okay.bind()}
                                                >
                                                    Okay
                                                </Button>
                                                <WhiteSpace size="lg" />
                                                <WhiteSpace size="lg" />
                                                <WhiteSpace size="lg" />
                                            </WingBlank>
                                        </WingBlank>
                                    </WingBlank>
                                </WingBlank>
                            </WingBlank>
                        </WingBlank>
                    </Flex.Item>
                </Flex>
            </div>
        );
    }
}

export default PaymentResultSuccess;