import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

import CallEnd from '@material-ui/icons/CallEnd';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '../Button'

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import {useAppState} from '../../../state';
import useRoomState from "../../../hooks/useRoomState/useRoomState";
import {VideoContext} from "../../VideoProvider";
import AuthGuard from "../../../../../services/AuthGuard";
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import { Modal } from 'antd-mobile';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fab: {
            margin: theme.spacing(1),
        },
    })
);

export default function EndCallButton(endCall: {endCall: any}) {
    const classes = useStyles();
    const {room, localTracks} = useVideoContext();
    const roomState = useRoomState();
    const {uploadLog} = useAppState();
    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
    const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();

    document.addEventListener("drEndCall", (event) => {
        uploadLog()
        if (room.state && room.state !== 'disconnected') {
            localTracks.forEach(track => track.stop())
            room.disconnect();
        }
        endCall.endCall("IconEndCall")
    });

    const tryEndCall = async () => {
        Modal.alert('Are you sure you want to end this consultation?', '', [
            { text: 'Yes', style: { color: 'red' }, onPress: () => {document.dispatchEvent(new Event('drEndCall'))} },
            { text: "No", style: { color: 'blue' }, onPress: () => {}},
        ])
    }

    return (
        <Button
            className="IconCall"
            onClick={tryEndCall}
        />
    );
}
