import React, { Component, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Flex,
  Icon,
  InputItem,
  List,
  NavBar,
  Toast,
  WhiteSpace,
  Modal,
} from "antd-mobile";
import { GOOGLE_ANALYTICS } from "../commons/Constants";
import ReactGA from "react-ga";
import AuthGuard from "../services/AuthGuard";
import { isBrowser } from "react-device-detect";
import ScheduleCalendar from "../component/ScheduleCalendar";
import { currencyType, showDate, getGST, setCache } from "../commons/Helpers";
//icons
import Search from "../images/icons/ic_search_gray.png";
import calendar from "../images/icons/ic_status_calendar_pink.png";
import Dropdown from "../images/icons/dropdown.png";
import _, { cloneDeep } from "lodash";

const Item = List.Item;
const AgreeItem = Checkbox.AgreeItem;

const LoactionItem = ({
  isItem,
  currentLocation,
  locationListOpen,
  selectedLocationItem,
  onClick,
  isLocationDisable,
}) => {
  if (!currentLocation)
    return (
      <div
        className="location-list-box"
        onClick={onClick}
        style={{ height: 55 }}
      >
        <div className="location-top-box">
          <div className="input-title">Location</div>
          <div className="location-merchant-name"></div>
          <div className="select-module">{"Select Location"}</div>
        </div>
        <img
          src={Dropdown}
          className={`dropdown_location ${locationListOpen && "active"}`}
          alt="dropdown-location"
        />
      </div>
    );
  const { location, merchant_name, location_id } = currentLocation;
  return isItem ? (
    <div
      className={`location-list-box ${
        selectedLocationItem.location_id === location_id ? "active" : ""
      } isItem`}
      onClick={() => onClick(currentLocation)}
    >
      <div className="location-top-box">
        <div className="location-merchant-name">{merchant_name}</div>
        <div className="select-module">{location}</div>
      </div>
    </div>
  ) : (
    <div className="location-list-box" onClick={onClick}>
      <div className="location-top-box">
        {location ? <div className="input-title">Location</div> : null}
        <div className="location-merchant-name">{merchant_name}</div>
        <div className="select-module">
          {location ? location : "Select Location"}
        </div>
      </div>
      {isLocationDisable ? null : (
        <img
          src={Dropdown}
          className={`dropdown_location ${locationListOpen && "active"}`}
          alt="dropdown-location"
        />
      )}
    </div>
  );
};

const TestItem = ({
  isItem,
  testListOpen,
  onClick,
  testItem,
  test_selection_num,
  selectedTest,
  test_list,
}) => {
  function getNameById(ids) {
    let str = "";
    ids.map((ite) => {
      str += `${test_list.find((x) => x.qms_test_id == ite).name},`;
    });
    return str.slice(0, str.length - 1);
  }
  if (isItem) {
    const { code, name, qms_test_id } = testItem;
    return (
      <div
        className={`location-list-box ${
          selectedTest.includes(qms_test_id) ? "active" : ""
        } isItem`}
        style={{ height: 50 }}
        onClick={() => onClick(qms_test_id)}
      >
        <div className="location-top-box">
          <div className="location-merchant-name">{name}</div>
        </div>
      </div>
    );
  }
  return !selectedTest?.length ? (
    <div className="location-list-box" onClick={onClick} style={{ height: 55 }}>
      <div className="location-top-box">
        <div className="select-module1">
          {`Select ${test_selection_num} Test(s)`}
        </div>
      </div>
      <img
        src={Dropdown}
        className={`dropdown_location ${testListOpen && "active"}`}
        alt="dropdown-location"
      />
    </div>
  ) : (
    <div className="location-list-box" onClick={onClick} style={{ height: 55 }}>
      <div className="select-module1" style={{ color: "#000" }}>
        {getNameById(selectedTest)}
      </div>
      <img
        src={Dropdown}
        className={`dropdown_location ${testListOpen && "active"}`}
        alt="dropdown-location"
      />
    </div>
  );
};

const DisplayTotalAmount = ({ total, gst_rate, gst_calculation }) => {
  const [totalNum, setTotalNum] = useState(total);
  useEffect(() => {
    setTotalNum(
      _.add(
        _.toNumber(total),
        getGST(_.toNumber(total), gst_rate, gst_calculation)
      )
    );
  }, [total, gst_rate, gst_calculation]);
  return <>{totalNum && "$" + currencyType(totalNum, "SG")}</>;
};

class BookHSWithClinic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      package_id: props.match.params.id,
      screening_name: "",
      merchant_name: "",
      price: "",
      searchList: [],
      locationList: [],
      searchListOpen: false,
      locationListOpen: false,
      addOnItem: [],
      errorMsg: {},
      selectedCoupon: false,
      totalAmount: 0.0,
      loading: false,
      disabled: true,
      agreeCheck: false,
      location_id: "",
      location_shot: {},
      addOnIDs: [],
      health_screening_ID: "",
      hsTime: null,
      hsTimeError: null,
      selectedLocationItem: null,
      filter_key: "",
      isLocationDisable: false,
      clinic_id: "",
      GST_reg_no: "",
      GST_rate: 0,
      testListOpen: false,
      test_list: [],
      test_selection_num: 0,
      selectedTest: [],
      GST_calculation: "1",
      GST_inclusive: 0,
    };
    // this.callAjax = _.debounce(this.searchAddon, 300);
  }
  selectedTestItem = (val) => {
    let arr = cloneDeep(this.state.selectedTest);
    if (arr.includes(val)) {
      arr.splice(
        arr.findIndex((x) => x == val),
        1
      );
    } else {
      if (arr.length == this.state.test_selection_num) {
        Modal.alert(
          "",
          `${this.state.test_selection_num} test(s) has already been selected, please undo the selection before choosing a new one.`,
          [{ text: "OK", onPress: () => console.log("ok") }]
        );
        return;
      }
      arr.push(val);
    }
    this.setState({
      selectedTest: arr,
    });
  };
  getLocationAndMerchantName = (item) => {
    let result = {};
    let location = "";
    let _location_type = item?.location_type || "";
    if (_location_type === "master") {
      const {
        unit_no,
        clinic_address,
        country,
        country_code,
        province,
        city,
        postal_code,
      } = item;

      if (country_code === "SG") {
        location = _.map([clinic_address, unit_no, country, postal_code]).join(
          " "
        );
      } else if (country_code) {
        location = _.map([
          clinic_address,
          unit_no,
          country,
          province,
          city,
          postal_code,
        ]).join(" ");
      }
      // console.log(location);
      result = {
        type: "master",
        location_shot: item,
        location_id: item.id,
        location,
        merchant_name: item.merchant_name,
      };
    }

    if (_location_type === "onsite") {
      const {
        street,
        unit_number,
        country_name,
        country_code,
        province,
        city,
        postal_code,
      } = item;

      if (country_code === "SG") {
        location = _.map([street, unit_number, country_name, postal_code]).join(
          " "
        );
      } else if (country_code) {
        location = _.map([
          street,
          unit_number,
          country_name,
          province,
          city,
          postal_code,
        ]).join(" ");
      }
      result = {
        type: "onsite",
        location_shot: item,
        location_id: item.id,
        merchant_name: item.name,
        location,
      };
    }

    if (_location_type === "offsite") {
      const {
        clinic_address,
        unit_no,
        country,
        country_code,
        province,
        city,
        postal_code,
      } = item;

      if (country_code === "SG") {
        location = _.map([clinic_address, unit_no, country, postal_code]).join(
          " "
        );
      } else if (country_code) {
        location = _.map([
          clinic_address,
          unit_no,
          country,
          province,
          city,
          postal_code,
        ]).join(" ");
      }

      result = {
        type: "offsite",
        location_shot: item,
        location_id: item.id,
        merchant_name: item.name,
        location,
      };
    }
    return result;
  };

  getScreeningDetails = (id, callback) => {
    let addon_id = [];
    console.log(this.state.addOnItem);
    this.state.addOnItem.map((ite) => {
      addon_id.push(ite.id);
    });
    AuthGuard.searchLocation(
      {
        package_id: id,
        addon_id: addon_id.length ? JSON.stringify(addon_id) : "",
      },
      (isOk, res) => {
        Toast.hide();
        if (isOk) {
          this.setState({
            locationList: res?.location_list || [],
            selectedLocationItem:
              res?.location_list && res?.location_list.length > 0
                ? this.getLocationAndMerchantName(res?.location_list[0])
                : "",
          });

          // if (res.masterLocation) {
          //     if (!res.offsiteLocation && !res.onsiteLocation) {
          if (!(res?.location_list && res?.location_list.length > 0)) {
            this.setState(
              {
                isLocationDisable: true,
              },
              () => {
                console.log(this.state.isLocationDisable);
              }
            );
          }

          // }
          return callback(res);
        }
      }
    );
  };

  initState(id) {
    let _this = this;
    AuthGuard.getPackageInfo({ id }, (isOk, res) => {
      if (isOk) {
        _this.setState(
          {
            ...res,
            totalAmount: res?.unit_price || 0.0,
            screening_name: res?.name,
          },
          () => {
            this.searchAddon();
          }
        );
      }
    });
    AuthGuard.findBillForHealthScreening(
      { health_screening_package_id: id },
      (isOk, res) => {
        if (isOk) {
          _this.setState({
            GST_reg_no: res?.GST_reg_no,
            GST_rate: res?.GST_rate * 1,
            test_selection_num: res?.test_selection_num,
            test_list: res?.test_list,
            GST_calculation: res?.GST_calculation,
            GST_inclusive: res?.GST_inclusive,
          });
        }
      }
    );
  }

  async componentDidMount() {
    if (GOOGLE_ANALYTICS) {
      ReactGA.set({
        page: window.location.href,
      });
    }
    let { id } = this.props.match.params;
    this.getScreeningDetails(id);
    this.initState(id);
    // this.searchAddon()
  }

  goBack = () => {
    this.props.history.push("/DoctorScreeningDetails/" + this.state.package_id);
  };

  loadingToast() {
    Toast.loading("Loading...", 30, () => {});
  }

  searchAddon(name) {
    let _this = this;
    const { package_id, clinic_id } = this.state;

    AuthGuard.searchAddOns(
      { package_id, limit: 999, name, clinic_id },
      (isOk, res) => {
        if (isOk && res.total) {
          _this.setState({
            searchList: res.result,
            searchListOpen: name && name !== "" ? true : false,
          });
        } else {
          _this.setState({
            searchListOpen: false,
          });
        }
      }
    );
  }

  keyPressSearch = (e) => {
    e.persist();
    // this.callAjax(e.target.value);
    this.setState({
      filter_key: e.target.value,
    });
    this.searchAddon(e.target.value);
    this.onClearErrorMsg();
  };

  sum = (arr) => {
    var s = 0;
    for (var i = arr.length - 1; i >= 0; i--) {
      s += arr[i];
    }
    return s;
  };

  searchResult = (item) => {
    const { id } = item;
    const { addOnItem } = this.state;

    let hasExist = _.find(addOnItem, (i) => i.id === id);

    console.log(item.health_screening_type);

    // if (!hasExist) {
    addOnItem.push(item);

    this.setState({ addOnItem, searchListOpen: false, filter_key: "" }, () => {
      if (item.health_screening_type === "corporate") {
        this.setState(
          {
            selectedLocationItem: null,
          },
          () => {
            this.computeTotalAmount();
          }
        );
      } else {
        this.computeTotalAmount();
      }
    });

    // }
  };

  deleteAddOn = (item, index) => {
    let _addOnItem = this.state.addOnItem;
    _addOnItem.splice(index, 1);
    this.setState(
      {
        locationListOpen: false,
        addOnItem: [..._addOnItem],
      },
      () => {
        this.computeTotalAmount();
      }
    );

    this.onClearErrorMsg();
  };

  computeTotalAmount = () => {
    const { addOnItem, unit_price } = this.state;
    let totalUnitPrice = [_.toNumber(unit_price)];
    addOnItem.map(({ unit_price }) => {
      totalUnitPrice.push(_.toNumber(unit_price));
    });

    this.setState({
      totalAmount: _.sum(totalUnitPrice),
    });
  };

  locationResult = (item) => {
    this.setState({
      selectedLocationItem: item,
      locationListOpen: false,
    });
  };

  openDatePicker = (e) => {
    if (!this.state.selectedLocationItem) {
      return;
    }
    e.stopPropagation();
    const {
      package_id,
      selectedLocationItem: {
        location_id,
        location_shot: { location_type },
      },
    } = this.state;
    this.scModel.openScModel({
      health_screening_id: package_id,
      location_id,
      location_type,
    });
    this.onClearErrorMsg();
  };

  coupon = () => {
    // let data = {
    //   packages_id: this.state.package_id,
    //   user_profile_id: this.state.user_profile_id,
    //   coupon_type: "HS",
    // };
    // this.props.history.push({ pathname: "/paymentCoupon", query: data });
  };

  openSelect = () => {
    if (!this.state.isLocationDisable) {
      if (!this.state.locationListOpen) {
        this.loadingToast();
        let { id } = this.props.match.params;
        this.getScreeningDetails(id, (params) => {
          this.setState({
            locationListOpen: !this.state.locationListOpen,
          });
        });
      } else {
        this.setState({
          locationListOpen: !this.state.locationListOpen,
        });
      }
    }

    this.onClearErrorMsg();
  };

  onClearErrorMsg = () => {
    this.setState({
      errorMsg: {
        locationMsg: null,
        hsTimeMsg: null,
        addOnMsg: null,
        packageMsg: null,
        testMsg: null,
      },
    });
  };

  focusSearch = () => {
    const { addOnItem } = this.state;
    // if (addOnItem.length)
    this.setState({
      searchListOpen: true,
      locationListOpen: false,
    });
    this.onClearErrorMsg();
  };

  Pay = () => {
    const {
      totalAmount,
      addOnItem,
      selectedLocationItem,
      hsTime,
      package_id,
      loading,
      selectedTest,
      GST_calculation,
      GST_inclusive,
      GST_rate,
    } = this.state;
    if (loading) return;
    let _totalAmount = _.toNumber(totalAmount);
    // console.log(_totalAmount);
    const [date, time] = hsTime.split(",");
    let item_ids = _.map(addOnItem, "id");

    let _addOn_ids = JSON.stringify(item_ids); //没有package_id的数组
    let test_list = JSON.stringify(selectedTest);
    item_ids.push(package_id);

    let state = {
      item_ids: JSON.stringify(item_ids),
      location_id: selectedLocationItem.location_id,
      location_shot: JSON.stringify(selectedLocationItem.location_shot),
      appointment_date_shot: JSON.stringify({
        date: showDate(date, 2),
        time,
      }),
      total: _totalAmount.toFixed(2),
      totalAmount: _totalAmount.toFixed(2),
      is_agree: "Y",
      type: "HS",
      location_type: selectedLocationItem.location_shot.location_type,
      test_list: selectedTest,
      GST_calculation,
      GST_inclusive,
      GST_rate,
    };

    this.setState({
      loading: true,
    });
    AuthGuard.checkAbleBook(
      {
        health_screening_id: this.state.package_id,
        location_id: selectedLocationItem.location_id,
        location_type: selectedLocationItem.location_shot.location_type,
        date: showDate(date, 2),
        time: time,
        addon_ids: _addOn_ids,
        test_list,
      },
      (isOk, res) => {
        this.setState({
          loading: false,
        });
        if (isOk && res) {
          if (_totalAmount > 0) {
            this.props.history.push({
              pathname: "/programsPayment/" + this.state.package_id,
              state: { ...state },
            });
          } else {
            this.loadingToast();
            this.payFree(state);
          }
        } else {
          if (res.data.status_code === 19992) {
            this.setState({
              errorMsg: {
                hsTimeMsg: res.data.message,
              },
            });
          } else if (res.data.status_code === 19991) {
            this.setState({
              errorMsg: {
                locationMsg: res.data.message,
              },
            });
          } else if (res.data.status_code === 19993) {
            this.setState({
              errorMsg: {
                packageMsg: res.data.message,
              },
            });
          } else if (res.data.status_code === 19994) {
            this.setState({
              errorMsg: {
                addOnMsg: res.data.message,
              },
            });
          } else {
            Modal.alert("Notice", res.data.message, [
              { text: "OK", onPress: () => console.log("ok") },
            ]);
          }
        }
      }
    );
  };

  payFree = (state) => {
    const { location_type, ...params } = state;
    AuthGuard.requestForHealthScreening(
      { ...params, test_list: JSON.stringify(params.test_list) },
      (isOk) => {
        Toast.hide();
        if (isOk) {
          this.props.history.push({
            pathname: "/programsSuccess/",
            state: { type: "HS" },
          });
        } else {
          this.props.history.push({
            pathname: "/paymentFail",
            state: { type: "HS", id: this.state.package_id },
          });
        }
      }
    );
  };

  ProceedToPayment = () => {
    this.loadingToast();
    AuthGuard.getProfileInfo({}, (isOk, res) => {
      Toast.hide();
      if (isOk) {
        if (
          !res.email ||
          !res.mobile ||
          !res.dialingCode ||
          !res.fullName ||
          !res.birthday ||
          !res.nric ||
          !res?.identification_type?.code ||
          !res?.gender ||
          !res?.race ||
          !res?.user_nationality ||
          !res?.country_code ||
          !res?.postal_code ||
          !res?.address
        ) {
          setCache("miss_info", {
            email: res.email,
            dialing_code: res.dialingCode,
            mobile_number: res.mobile,
            name: res.fullName,
            birth: res.birthday,
            nric: res.nric,
            identification_type: res?.identification_type?.code,
            gender: res?.gender,
            race: res?.race,
            nationality: res?.user_nationality,
            country_code: res?.country_code,
            province: res?.province,
            city: res?.city,
            postal_code: res?.postal_code,
            unit_no: res?.unit_no,
            address: res?.address,
          });
          let url = window.location.pathname;
          setCache("redirectUrl", url);
          this.props.history.push("/missProfileInfo");
        } else {
          const {
            agreeCheck,
            selectedLocationItem,
            hsTime,
            selectedTest,
            test_selection_num,
          } = this.state;
          if (
            test_selection_num &&
            !selectedTest.length &&
            this.state.selectedLocationItem?.location_shot?.location_type ==
              "master"
          ) {
            this.setState({
              errorMsg: {
                testMsg: `Please fill in the required field. If you are unsure of what test to select, you can change it later or during your appointment`,
              },
            });
            return false;
          } else if (
            test_selection_num &&
            selectedTest.length < test_selection_num &&
            this.state.selectedLocationItem?.location_shot?.location_type ==
              "master"
          ) {
            this.setState({
              errorMsg: {
                testMsg: `Please select ${test_selection_num} tests`,
              },
            });
            return false;
          } else {
            this.setState({
              errorMsg: {
                testMsg: null,
              },
            });
          }

          if (!selectedLocationItem?.location_id) {
            this.setState({
              errorMsg: {
                locationMsg: "Please fill in the required field.",
              },
            });
            return false;
          } else {
            this.setState({
              errorMsg: {
                locationMsg: null,
              },
            });
          }

          let [date, time] = _.split(hsTime, ",", 2);

          if (!hsTime || !time) {
            let _hsTimeMsg = "";
            if (!time) {
              _hsTimeMsg = "Please fill in the required field.";
            }

            this.setState({
              errorMsg: {
                hsTimeMsg: _hsTimeMsg,
              },
            });
            return false;
          } else {
            this.setState({
              errorMsg: {
                hsTimeMsg: null,
              },
            });
          }

          if (!agreeCheck) {
            Modal.alert(
              "Error",
              "Please read and agree to the Terms & Conditions before proceeding.",
              [{ text: "OK", onPress: () => console.log("ok") }]
            );
            return false;
          }

          // this.updateCoupon();
          this.Pay();
        }
      } else {
        Modal.alert("warning", res.message, [
          { text: "OK", onPress: () => console.log("ok") },
        ]);
      }
    });
  };

  onSCRef = (ref) => {
    this.scModel = ref;
  };

  render() {
    const {
      // locationList: { masterLocation, offsiteLocation, onsiteLocation },
      locationList,
      selectedLocationItem,
      unit_price,
      totalAmount,
    } = this.state;
    return (
      <div className="Health-Screening">
        <ScheduleCalendar
          onRef={this.onSCRef}
          onSetHSTime={(hsTime) => {
            this.setState({
              hsTime,
              errorMsg: {
                hsTimeMsg: "",
              },
            });
          }}
        />
        {!isBrowser ? (
          <NavBar
            mode="light"
            icon={<Icon type="left" color="#EB5DA6" size="lg" />}
            onLeftClick={() => this.goBack()}
          >
            Health Screening
          </NavBar>
        ) : (
          ""
        )}
        <Flex className="padding">
          <Flex.Item>
            <h2>Items</h2>
            <WhiteSpace size="lg" />
          </Flex.Item>
        </Flex>
        <div className="item-box">
          <div className="health-screening-box">
            <div className="health_screening_left">
              <h2>{this.state.screening_name}</h2>
              <p>{this.state.merchant_name}</p>
            </div>
            <span className="health_screening_right">
              {unit_price ? "$" + currencyType(unit_price, "SG") : "-"}
            </span>
          </div>

          {this.state.errorMsg.packageMsg ? (
            <Flex.Item className="errorMsg">
              <WhiteSpace size="md" />
              <p>{this.state.errorMsg.packageMsg}</p>
            </Flex.Item>
          ) : (
            ""
          )}

          <WhiteSpace size="lg" />
          {!!this.state.test_selection_num &&
            this.state.selectedLocationItem?.location_shot?.location_type ==
              "master" && (
              <div className="date-location-box">
                <div className="date_test_content">
                  <div
                    className="location-wrap"
                    style={{
                      backgroundColor: this.state.isLocationDisable
                        ? "ghostwhite"
                        : "white",
                    }}
                  >
                    <TestItem
                      testListOpen={this.state.testListOpen}
                      onClick={() =>
                        this.setState({
                          testListOpen: !this.state.testListOpen,
                          errorMsg: {
                            testMsg: null,
                          },
                        })
                      }
                      test_selection_num={this.state.test_selection_num}
                      selectedTest={this.state.selectedTest}
                      test_list={this.state.test_list}
                    />
                    {this.state.testListOpen && (
                      <div className="location-item-wrap">
                        {!!this.state?.test_list?.length &&
                          this.state.test_list.map((item) => (
                            <TestItem
                              key={item.qms_test_id}
                              testItem={item}
                              isItem
                              selectedTest={this.state.selectedTest}
                              onClick={this.selectedTestItem}
                            />
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          {this.state.errorMsg.testMsg ? (
            <Flex.Item className="errorMsg">
              <WhiteSpace size="md" />
              <p>{this.state.errorMsg.testMsg}</p>
            </Flex.Item>
          ) : (
            ""
          )}
          <WhiteSpace size="lg" />
          <div>
            {this.state.addOnItem && this.state.addOnItem.length > 0
              ? this.state.addOnItem.map((item, index) => {
                  return (
                    <Flex className="add-on-item" key={index + "addOnItem"}>
                      <List className="add-ons-res-list health-screening-box">
                        <Item
                          className="add-on-items"
                          extra={"$" + item.unit_price}
                        >
                          {item.name}
                        </Item>
                      </List>
                      <Icon
                        type="cross-circle-o"
                        className="cancel-search-item"
                        color="#E02381"
                        onClick={() => this.deleteAddOn(item, index)}
                      />
                    </Flex>
                  );
                })
              : null}

            <Flex className="health-screening-box">
              <div className="search-module search-add-ons">
                <input
                  className="search-input"
                  type="text"
                  value={this.state.filter_key}
                  onChange={this.keyPressSearch}
                  onFocus={this.focusSearch}
                  onBlur={() => {
                    let _this = this;
                    setTimeout(() => {
                      _this.setState({
                        searchListOpen: false,
                      });
                    }, 500);
                  }}
                  placeholder="Search for Add Ons"
                />
                <img src={Search} className="Search" alt="Search" />
              </div>
            </Flex>

            {this.state.errorMsg.addOnMsg ? (
              <Flex.Item className="errorMsg">
                <WhiteSpace size="md" />
                <p>{this.state.errorMsg.addOnMsg}</p>
              </Flex.Item>
            ) : (
              ""
            )}

            {this.state.searchListOpen ? (
              <div className="add-ons-list">
                {this.state.searchList.map((item, index) => {
                  return (
                    <List
                      className="add-ons-res-list"
                      key={item.id}
                      onClick={() => this.searchResult(item)}
                    >
                      <Item
                        className="add-on-items"
                        extra={`$${item.unit_price}`}
                      >
                        {item.name}
                      </Item>
                    </List>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>

        <Flex className="padding">
          <Flex.Item>
            <h2>Date & Location</h2>
            <WhiteSpace size="lg" />
          </Flex.Item>
        </Flex>

        <div className="date-location-box">
          <div className="date_location_content">
            <div
              className="location-wrap"
              style={{
                backgroundColor: this.state.isLocationDisable
                  ? "ghostwhite"
                  : "white",
              }}
            >
              <LoactionItem
                onClick={this.openSelect}
                locationListOpen={this.state.locationListOpen}
                currentLocation={selectedLocationItem || null}
                selectedLocationItem={selectedLocationItem}
                isLocationDisable={this.state.isLocationDisable}
              />

              {this.state.locationListOpen && (
                <div className="location-item-wrap">
                  {locationList &&
                    locationList.map((item) => (
                      <LoactionItem
                        key={item.id}
                        isItem
                        currentLocation={this.getLocationAndMerchantName(item)}
                        selectedLocationItem={selectedLocationItem}
                        onClick={this.locationResult}
                      />
                    ))}
                  {/* {masterLocation && (
                                        <LoactionItem
                                            isItem
                                            selectedLocationItem={selectedLocationItem}
                                            onClick={this.locationResult}
                                            currentLocation={this.getLocationAndMerchantName(
                                                "master",
                                                masterLocation
                                            )}
                                        />
                                    )}

                                    {onsiteLocation &&
                                        onsiteLocation.map((item) => (
                                            <LoactionItem
                                                key={item.id}
                                                isItem
                                                currentLocation={this.getLocationAndMerchantName(
                                                    "onsite",
                                                    item
                                                )}
                                                selectedLocationItem={selectedLocationItem}
                                                onClick={this.locationResult}
                                            />
                                        ))}

                                    {offsiteLocation &&
                                        offsiteLocation.map((item) => (
                                            <div key={item.id}>
                                                {item.status && item.status == "active" ?
                                                    <LoactionItem
                                                        // key={item.id}
                                                        isItem
                                                        selectedLocationItem={selectedLocationItem}
                                                        onClick={this.locationResult}
                                                        currentLocation={this.getLocationAndMerchantName(
                                                            "offsite",
                                                            item
                                                        )}
                                                    /> : null}
                                            </div>
                                        ))} */}
                </div>
              )}
            </div>

            {this.state.errorMsg.locationMsg ? (
              <Flex.Item className="errorMsg">
                <WhiteSpace size="md" />
                <p>{this.state.errorMsg.locationMsg}</p>
              </Flex.Item>
            ) : (
              ""
            )}
            <WhiteSpace size="lg" />
            <div className="date_content">
              <div
                className="input_item"
                style={{ lineHeight: this.state.hsTime ? "5px" : "55px" }}
              >
                {this.state.hsTime ? (
                  <div className="input-title">Date & Time</div>
                ) : (
                  ""
                )}
                <Flex onClick={this.openDatePicker}>
                  <Flex.Item>
                    <div>
                      <InputItem
                        name="hsTime"
                        placeholder="Date & Time"
                        editable={false}
                        error={this.state.hsTimeError}
                        value={this.state.hsTime}
                      />
                    </div>
                  </Flex.Item>
                  <span>
                    <img
                      src={calendar}
                      style={{ width: "1em", marginRight: "1em" }}
                      alt=""
                    />
                  </span>
                </Flex>
              </div>
            </div>
            {this.state.errorMsg.hsTimeMsg ? (
              <Flex.Item className="errorMsg">
                <WhiteSpace size="md" />
                <p>{this.state.errorMsg.hsTimeMsg}</p>
              </Flex.Item>
            ) : (
              ""
            )}
          </div>
        </div>

        {/* <WhiteSpace size="lg" /> */}
        <WhiteSpace size="md" />
        <Flex>
          <Flex.Item>
            <div className="coupon-selection">
              {!this.state.selectedCoupon ? (
                <List.Item
                  className="CouponDiv"
                  arrow="horizontal"
                  onClick={this.coupon.bind(this)}
                  style={{ border: "0px" }}
                >
                  Coupon
                </List.Item>
              ) : (
                <div className="selected-coupon">
                  <div className="selected-coupon-title">Coupon</div>
                  <div
                    className="selected-coupon-detail"
                    onClick={this.coupon.bind(this)}
                  >
                    <div className="selected-coupon-code">
                      {this.state.selectedCoupon.code}
                    </div>
                    <div className="selected-coupon-name">
                      {this.state.selectedCoupon.name}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Flex.Item>
        </Flex>

        {/* <WhiteSpace size="lg" /> */}
        <WhiteSpace size="md" />
        <div className="bill-box">
          <li className="billitem">
            <Flex justify="between">
              <div className="total-title">
                <b>Subtotal</b>
              </div>
              <div className="total-amount">
                <b>
                  {totalAmount ? "$" + currencyType(totalAmount, "SG") : "-"}
                </b>
              </div>
            </Flex>
          </li>

          {this.state.GST_reg_no && (
            <li className="billitem">
              <Flex justify="between">
                <div className="total-title">
                  <b>
                    {this.state.GST_rate.toFixed(2)}% GST{" "}
                    {this.state.GST_calculation === "1" && "Inclusive"}
                  </b>
                </div>
                <div className="total-amount">
                  <b>
                    {totalAmount
                      ? "$" +
                        currencyType(
                          getGST(
                            totalAmount,
                            this.state.GST_rate,
                            this.state.GST_calculation
                          ),
                          "SG"
                        )
                      : "-"}
                  </b>
                </div>
              </Flex>
            </li>
          )}

          <li className="billitem">
            <Flex justify="between">
              <div className="total-title">
                <b>Total</b>
              </div>
              <div className="total-amount">
                <b>
                  {totalAmount ? (
                    <DisplayTotalAmount
                      total={totalAmount}
                      gst_rate={this.state.GST_rate}
                      gst_calculation={this.state.GST_calculation}
                    />
                  ) : (
                    "-"
                  )}
                </b>
              </div>
            </Flex>
          </li>
        </div>
        <WhiteSpace size="md" />
        <AgreeItem
          data-seed="gId"
          className="agree-item"
          onChange={(e) =>
            this.setState({
              agreeCheck: e.target.checked,
              disabled: !Boolean(e.target.checked),
            })
          }
        >
          <span>
            I agree to the
            <a
              href="https://www.ourheartvoice.com/health-screening-terms-and-condition/"
              target="_blank"
              style={{ color: "#EB5DA6" }}
            >
              {" "}
              Clinic’s Terms & Conditions
            </a>
          </span>
        </AgreeItem>
        <WhiteSpace size="md" />
        <div className="btn proceed-btn pb-5">
          <Flex style={{ border: "0px" }}>
            <Flex.Item>
              <Button
                className="fill-pink-btn"
                activeClassName="fill-pink-active-btn"
                loading={this.state.loading}
                onClick={this.ProceedToPayment.bind(this)}
                // disabled={this.state.disabled}
              >
                Checkout
              </Button>
            </Flex.Item>
          </Flex>
        </div>
      </div>
    );
  }
}

export default BookHSWithClinic;
