import React, { Component } from 'react';
import AuthGuard from '../services/AuthGuard';
import { ReactGAEvent } from '../commons/ReactGAEvent';

class GeDrLink extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentWillMount() {

    }

    async componentDidMount() {
        let { token } = this.props.match.params;
        if (token) await AuthGuard.getNewToken(token)
        this.checkToken('ge')
    }

    checkToken(filterKey) {
        console.log(filterKey)
        ReactGAEvent("Go TO DoctorList", "onclick " + filterKey ? filterKey : 'Find a Doctor' + " Btn");
        this.props.history.push('/doctorList?filter=ge')
    }

    render() {
        return ( <> </>);
        };
    }
    export default GeDrLink;