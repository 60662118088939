import {
  apiBaseUrl,
  ApiUrl,
  XAPP,
  ShowErrorMsgCode,
} from "../commons/Constants";
import axios from "axios";
import qs from "qs";
import AuthGuard from "../services/AuthGuard";
import { Modal } from "antd-mobile";
import { urlEncode, specialStrDecode, closeWindow } from "../commons/Helpers";

let fetch = axios.create({
  baseURL: apiBaseUrl,
  // timeout: 7000 // request timeout,
  // timeout: 7000 // request timeout    ,
  timeout: 360 * 1000,
});

const ErrAPIList = [
  ApiUrl.getAvaliableDate,
  ApiUrl.appointmentSchedule,
  ApiUrl.mobileInqueue,
  ApiUrl.queueAssign,
  ApiUrl.savedr,
  ApiUrl.getProgramsInfo,
  ApiUrl.checkProfileComplete,
  ApiUrl.packagesCheckDetail,
  ApiUrl.getHSDate,
];

fetch.interceptors.request.use(
  async (config) => {
    let canAccess = await AuthGuard.checkIpWhiteList();
    let hostName = window.location.protocol + "//" + window.location.host; //
    if (!canAccess) {
      window.location.href = hostName + "/downtime.html";
      return;
    }

    AuthGuard.checkAuthenticated((isAuth) => {
      if (isAuth) {
        config.headers["X-authorization"] = AuthGuard.isAuthenticated;
      } else {
        // AuthGuard.setLogout();
        // f7.views.main.router.refreshPage();
      }
    });

    if (config.method === "files") {
      config.method = "post";
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      if (
        config.method === "post" ||
        config.method === "put" ||
        config.method === "delete"
      ) {
        if (
          typeof config.data !== "string" &&
          config.headers["Content-Type"] !== "multipart/form-data"
        ) {
          config.data = qs.stringify(config.data);
        }
      }
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

fetch.interceptors.response.use(
  async (data) => {
    return specialStrDecode(data);
  },
  (error) => {
    // console.log(error.response);
    const { config, code, message } = error;

    if (code === "ECONNABORTED" || message.includes("timeout of")) {
      // 请求超时
      Modal.alert("Warning!", "Oops ,Request timed out, please try again!", [
        // { text: 'Cancel', onPress: () => console.log('cancel') },
        { text: "Ok", onPress: () => console.log("ok") },
      ]);
      return Promise.reject(error.response.data);
    }

    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        if (error.response.data.status_code === 401) {
          AuthGuard.removeCaches();
        }
        if (error.response.status === 403) {
          Modal.alert(
            "Oops",
            "You need a HeartVoice account to consult doctors.",
            [
              { text: "Cancel", onPress: () => console.log("cancel") },
              {
                text: "Login / Sign up",
                onPress: () => {
                  AuthGuard.logout(() => {});
                },
              },
            ]
          );
        }
        let url = error.response.config.url;
        if (ErrAPIList.includes(url) === true) {
          Modal.alert(
            "Oops",
            "You need a HeartVoice account to consult doctors.",
            [
              { text: "Cancel", onPress: () => console.log("cancel") },
              {
                text: "Login / Sign up",
                onPress: () => {
                  AuthGuard.logout(() => {});
                },
              },
            ]
          );
        }
      } else if (error.response.status === 500) {
        // f7.dialog.alert('Opps! Server Error.', 'Alert');
      } else if (
        error.response.status == 404 &&
        error.response.data !== undefined &&
        error.response.data.status_code !== undefined
      ) {
        let existence = ShowErrorMsgCode.find((v) => {
          return v == error.response.data.status_code;
        });
        if (existence) {
          if (existence == 23005) {
            Modal.alert("Notice", error.response.data.message, [
              {
                text: "OK",
                onPress: () => {
                  closeWindow();
                },
              },
            ]);
            return false;
          }
          Modal.alert("Notice", error.response.data.message, [
            { text: "OK", onPress: () => console.log("ok") },
          ]);
        }
      } else {
        // f7.dialog.alert(error.response.data.message, 'Notice');
        Modal.alert("Notice", error.response.data.message, [
          { text: "OK", onPress: () => console.log("ok") },
        ]);
      }

      // console.log(error.response);
      return Promise.reject(error.response.data);
    } else {
      return Promise.reject(error);
    }
  }
);

export default class API {
  static AJAX({ url, data, method, headers, jsonp, statusCode, timeout }) {
    return fetch({
      headers: {
        ...headers,
        "X-app": XAPP,
      },
      url: url,
      method: method,
      data,
    });
  }

  static GET({ url, data, headers = {} }) {
    if (data) {
      url = `${url}?${urlEncode(data)}`;
    }
    return API.AJAX({ url, data, method: "GET", headers });
  }

  static POST({ url, data, headers = {} }) {
    return API.AJAX({ url, data, method: "POST", headers });
  }

  static FILES({ url, data, headers = {} }) {
    return API.AJAX({ url, data, method: "FILES", headers });
  }

  static DELETE({ url, data }) {
    return API.AJAX({ url, data, method: "DELETE" });
  }

  userCheckBill({ data }) {
    return API.POST({
      url: ApiUrl.userCheckBillV2,
      data,
    });
  }

  verifyUser({ data }) {
    return API.POST({
      url: ApiUrl.verifyUser,
      data,
    });
  }

  creatBill({ data }) {
    return API.POST({
      url: ApiUrl.creatBill,
      data,
    });
  }

  getPayBackData({ data }) {
    return API.POST({
      url: ApiUrl.payCheckoutV2,
      data,
    });
  }

  singUpSendOtp({ data }) {
    return API.POST({
      url: ApiUrl.signUpSendOtp,
      data,
    });
  }

  signUpVerifyOtp({ data }) {
    return API.POST({
      url: ApiUrl.signUpverifyOtp,
      data,
    });
  }

  loginSendOtp({ data }) {
    return API.POST({
      url: ApiUrl.loginSendotp,
      data,
    });
  }

  loginVerifyOtp({ data }) {
    return API.POST({
      url: ApiUrl.loginVerifyOtp,
      data,
    });
  }

  nationalityList({ data }) {
    return API.GET({
      url: ApiUrl.nationalityList,
      data,
    });
  }

  getProvinceList({ data }) {
    return API.GET({
      url: ApiUrl.getProvinceList,
      data,
    });
  }

  getCityList({ data }) {
    return API.GET({
      url: ApiUrl.getCityList,
      data,
    });
  }

  getSGRegion(data) {
    return API.GET({
      url: ApiUrl.getSGRegion + "/" + data,
    });
  }

  countryList({ data }) {
    return API.POST({
      url: ApiUrl.countryList,
      data,
    });
  }

  completeProfile({ data }) {
    return API.POST({
      url: ApiUrl.completeProfile,
      data,
    });
  }

  signupNotie({ data }) {
    return API.POST({
      url: ApiUrl.signupNotie,
      data,
    });
  }

  addAddress({ data }) {
    return API.POST({
      url: ApiUrl.addAddressV2,
      data,
    });
  }

  noFeePay({ data }) {
    return API.POST({
      url: ApiUrl.noFeePayV2,
      data,
    });
  }

  getSpecialty({ data }) {
    return API.post({
      url: ApiUrl.getSpecialty,
      data,
    });
  }

  doctorDetails({ data }) {
    return API.POST({
      url: ApiUrl.doctorDetails,
      data,
    });
  }

  getspecialtyList({ data }) {
    return API.POST({
      url: ApiUrl.getspecialtyList,
      data,
    });
  }
  getprogramList({ data }) {
    return API.POST({
      url: ApiUrl.getprogramList,
      data,
    });
  }

  getProgramTokenList({ data }) {
    return API.POST({
      url: ApiUrl.getProgramTokenList,
      data,
    });
  }

  coachSearch({ data }) {
    return API.POST({
      url: ApiUrl.coachSearch,
      data,
    });
  }

  deliveryOption({ data }) {
    return API.POST({
      url: ApiUrl.deliveryOption,
      data,
    });
  }
  applyPromocode({ data }) {
    return API.POST({
      url: ApiUrl.applyPromocodeV2,
      data,
    });
  }

  getPromoCodeList({ data }) {
    return API.POST({
      url: ApiUrl.getPromoCodeList,
      data,
    });
  }

  getPromoCodeDetail({ data }) {
    return API.POST({
      url: ApiUrl.getPromoCodeDetail,
      data,
    });
  }

  serchLocalDoctor({ data }) {
    return API.POST({
      url: ApiUrl.serchLocalDoctor,
      data,
    });
  }

  getSingPassUrl({ data }) {
    return API.POST({
      url: ApiUrl.getSingPassUrl,
      data,
    });
  }

  getSingPassInfo({ data }) {
    return API.POST({
      url: ApiUrl.getSingPassInfo,
      data,
    });
  }

  provinceList({ data }) {
    return API.GET({
      url: ApiUrl.provinceList,
      data,
    });
  }

  cityList({ data }) {
    return API.GET({
      url: ApiUrl.cityList,
      data,
    });
  }

  getServiceCountry({ data }) {
    return API.POST({
      url: ApiUrl.getServiceCountry,
      data,
    });
  }
  savedr({ data }) {
    return API.POST({
      url: ApiUrl.savedr,
      data,
    });
  }
  getAvaliableDate({ data }) {
    return API.POST({
      url: ApiUrl.getAvaliableDate,
      data,
    });
  }
  getHSDate({ data }) {
    return API.POST({
      url: ApiUrl.getHSDate,
      data,
    });
  }
  appointmentSchedule({ data }) {
    return API.POST({
      url: ApiUrl.appointmentSchedule,
      data,
    });
  }
  validateToken({ data }) {
    return API.POST({
      url: ApiUrl.validateToken,
      data,
    });
  }
  getMyinfo({ data }) {
    return API.POST({
      url: ApiUrl.getMyinfo,
      data,
    });
  }
  sendOtp4update({ data }) {
    return API.POST({
      url: ApiUrl.sendOtp4update,
      data,
    });
  }
  checkOtp4updte({ data }) {
    return API.POST({
      url: ApiUrl.checkOtp4updte,
      data,
    });
  }
  sendOtp4Reg({ data }) {
    return API.POST({
      url: ApiUrl.sendOtp4Reg,
      data,
    });
  }
  checkOtp4Reg({ data }) {
    return API.POST({
      url: ApiUrl.checkOtp4Reg,
      data,
    });
  }
  getGeoAdd({ data }) {
    return API.POST({
      url: ApiUrl.getGeoAdd,
      data,
    });
  }
  mobileInqueue({ data }) {
    return API.POST({
      url: ApiUrl.mobileInqueue,
      data,
    });
  }
  queueAssign({ data }) {
    return API.POST({
      url: ApiUrl.queueAssign,
      data,
    });
  }

  queueOneAssign({ data }) {
    return API.POST({
      url: ApiUrl.queueOneAssign,
      data,
    });
  }

  getCouponPrice({ data }) {
    return API.POST({
      url: ApiUrl.getCouponPrice,
      data,
    });
  }
  checkRegAdd({ data }) {
    return API.POST({
      url: ApiUrl.checkRegAdd,
      data,
    });
  }

  updateHeight({ data }) {
    return API.POST({
      url: ApiUrl.updateHeight,
      data,
    });
  }

  addReferral({ data }) {
    return API.POST({
      url: ApiUrl.addReferral,
      data,
    });
  }

  requestCode({ data }) {
    return API.POST({
      url: ApiUrl.requestCode,
      data,
    });
  }
  joinPrograms({ data }) {
    return API.POST({
      url: ApiUrl.joinProgramsV2,
      data,
    });
  }
  getPromoCodeListV2({ data }) {
    return API.POST({
      url: ApiUrl.getPromoCodeListV2,
      data,
    });
  }
  getPromoCodeDetailV2({ data }) {
    return API.POST({
      url: ApiUrl.getPromoCodeDetailV2,
      data,
    });
  }
  getProgramsCouponPrice({ data }) {
    return API.POST({
      url: ApiUrl.getProgramsCouponPrice,
      data,
    });
  }
  creatPro({ data }) {
    return API.POST({
      url: ApiUrl.creatProV2,
      data,
    });
  }
  getPayProBackData({ data }) {
    return API.POST({
      url: ApiUrl.proCheckout,
      data,
    });
  }
  acceptProgramsRequest({ data }) {
    return API.GET({
      url: ApiUrl.acceptProgramsRequest,
      data,
    });
  }
  getProgramsInfo({ data }) {
    return API.POST({
      url: ApiUrl.getProgramsInfo,
      data,
    });
  }
  acceptProgramsInfo({ data }) {
    return API.POST({
      url: ApiUrl.acceptProgramsinfo,
      data,
    });
  }
  checkProfileComplete({ data }) {
    return API.POST({
      url: ApiUrl.checkProfileComplete,
      data,
    });
  }
  packagesFindDetail({ data }) {
    return API.POST({
      url: ApiUrl.packagesFindDetail,
      data,
    });
  }

  calculateForTelemed({ data }) {
    return API.POST({
      url: ApiUrl.calculateForTelemedV2,
      data,
    });
  }

  packagesCheckDetail({ data }) {
    return API.POST({
      url: ApiUrl.packagesCheckDetail,
      data,
    });
  }

  creatGE({ data }) {
    return API.POST({
      url: ApiUrl.creatGE,
      data,
    });
  }

  marketPlaceDetailInfo({ data }) {
    return API.POST({
      url: ApiUrl.marketPlaceDetailInfo,
      data,
    });
  }

  hasBought({ data }) {
    return API.POST({
      url: ApiUrl.hasBought,
      data,
    });
  }

  getIdentificationTypeList({ data }) {
    return API.POST({
      url: ApiUrl.getIdentificationTypeList,
      data,
    });
  }

  addDrugAllergy({ data }) {
    return API.POST({
      url: ApiUrl.addDrugAllergy,
      data,
    });
  }

  uploadTwilioLog({ data }) {
    return API.FILES({
      url: ApiUrl.uploadTwilioLog,
      data,
    });
  }

  SignUpSendOtp({ data }) {
    return API.POST({
      url: ApiUrl.SignUpSendOtp,
      data,
    });
  }

  SignUpOtpSubmit({ data }) {
    return API.POST({
      url: ApiUrl.SignUpOtpSubmit,
      data,
    });
  }

  SignUpCompleteInfo({ data }) {
    return API.POST({
      url: ApiUrl.SignUpCompleteInfo,
      data,
    });
  }

  SignUpMyinfoOtpSubmit({ data }) {
    return API.POST({
      url: ApiUrl.SignUpMyinfoOtpSubmit,
      data,
    });
  }

  loginComOtpSubmit({ data }) {
    return API.POST({
      url: ApiUrl.loginComOtpSubmit,
      data,
    });
  }

  loginComSubmit({ data }) {
    return API.POST({
      url: ApiUrl.loginComSubmit,
      data,
    });
  }

  notAddress({ data }) {
    return API.POST({
      url: ApiUrl.notAddress,
      data,
    });
  }

  checkIsExist({ data }) {
    return API.POST({
      url: ApiUrl.checkIsExist,
      data,
    });
  }

  hsList({ data }) {
    return API.POST({
      url: ApiUrl.hsList,
      data,
    });
  }

  searchAddOns({ data }) {
    return API.POST({
      url: ApiUrl.searchAddOns,
      data,
    });
  }

  searchLocation({ data }) {
    return API.POST({
      url: ApiUrl.searchLocation,
      data,
    });
  }

  requestForHealthScreening({ data }) {
    return API.POST({
      url: ApiUrl.requestForHealthScreening,
      data,
    });
  }

  checkoutScreening({ data }) {
    return API.POST({
      url: ApiUrl.checkoutScreening,
      data,
    });
  }

  checkIDForHS({ data }) {
    return API.POST({
      url: ApiUrl.checkIDForHS,
      data,
    });
  }

  checkIDForCT({ data }) {
    return API.POST({
      url: ApiUrl.checkIDForCT,
      data,
    });
  }

  joinIDForHS({ data }) {
    return API.POST({
      url: ApiUrl.joinIDForHS,
      data,
    });
  }

  getPackageInfo({ data }) {
    return API.POST({
      url: ApiUrl.getPackageInfo,
      data,
    });
  }

  findBillForHealthScreening({ data }) {
    return API.POST({
      url: ApiUrl.findBillForHealthScreening,
      data,
    });
  }

  getHsPayBackData({ data }) {
    return API.POST({
      url: ApiUrl.getHsPayBackData,
      data,
    });
  }

  checkAbleBook({ data }) {
    return API.POST({
      url: ApiUrl.checkAbleBook,
      data,
    });
  }

  getProfileInfo({ data }) {
    return API.POST({
      url: ApiUrl.getProfileInfo,
      data,
    });
  }

  getRaceAndRaceStatusList({ data }) {
    return API.GET({
      url: ApiUrl.getRaceAndRaceStatusList,
      data,
    });
  }

  telDoctorList({ data }) {
    return API.POST({
      url: ApiUrl.telDoctorList,
      data,
    });
  }

  getMerchantProviderUUid({ data }) {
    return API.POST({
      url: ApiUrl.getMerchantProviderUUid,
      data,
    });
  }

  // CP Questions
  getCPQuestions({ data }) {
    return API.GET({
      url: ApiUrl.getCPQuestions,
      data,
    });
  }

  saveCPQuestions({ data }) {
    return API.POST({
      url: ApiUrl.saveCPQuestions,
      data,
    });
  }

  getTransactionStatus({ data }) {
    return API.POST({
      url: ApiUrl.getTransactionStatus,
      data,
    });
  }

  getLastPending({ data }) {
    return API.POST({
      url: ApiUrl.getLastPending,
      data,
    });
  }

  getDeclarationFormDetail({ data }) {
    return API.POST({
      url: ApiUrl.getDeclarationFormDetail,
      data,
    });
  }

  oauthAuthorize({ data }) {
    return API.POST({
      url: ApiUrl.oauthAuthorize,
      data,
    });
  }
  
  getMyReport({ data }) {
    return API.POST({
      url: ApiUrl.getMyReport,
      data,
    });
  }

  getHealthScreenList({ data }) {
    return API.POST({
      url: ApiUrl.getHealthScreenList,
      data,
    });
  }

  getHSBookedEvnet({ data }) {
    return API.POST({
      url: ApiUrl.getHSBookedEvnet,
      data,
    });
  }
  getScanInfo({ data }) {
    return API.POST({
      url: ApiUrl.getScanInfo,
      data,
    });
  }

  ScanUpload({ data }) {
    return API.POST({
      url: ApiUrl.scanUpload,
      data,
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  CheckVaildliUrl({ data }) {
    return API.POST({
      url: ApiUrl.checkVaildliUrl,
      data,
    });
  }
}
