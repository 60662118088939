import React, { Component } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { WingBlank, Flex, WhiteSpace, Tag, InputItem, NavBar, Icon, Picker, Button, Modal, Toast, Checkbox, SearchBar, List, Drawer } from 'antd-mobile';
import { ROUTES, WHATSAPP_MOBILENO, GAID, GOOGLE_ANALYTICS } from '../commons/Constants';
import VideoCall from '../component/videoCall';
import AuthGuard from '../services/AuthGuard';
import { addKeyValueForDorpList, isPostalCode, setCache, getCache, currencyType, isInvalid, removeCache, viewFile } from '../commons/Helpers';
import GoogleMapReact from 'google-map-react';
import Filter from '../images/icons/filter.png'
import Search from '../images/icons/search.png'
import VideoCallPic from '../images/icons/videocall.png'
import iconDoctorAvatar from '../images/icons/icon_doctor_avatar.png'
import {
    isBrowser,
    isMobile
} from "react-device-detect";
import LoginModel from '../component/LoginModel';
import iconSaveDoctor from '../images/icons/icon_saveDr.png'
import iconUnSaveDoctor from '../images/icons/icon_unsaveDr.png'
import logo from '../images/icons/logo.png'
import success_icon from '../images/icons/success_icon.png'
import close_red from '../images/icons/close_red.png'
import { thisExpression } from '@babel/types';
import Menu from '../component/Menu'
import ReactGA from "react-ga";
import { ReactGAEvent } from "../commons/ReactGAEvent";
import URL from "url";

function closest(el, selector) {
    const matchesSelector =
      el.matches ||
      el.webkitMatchesSelector ||
      el.mozMatchesSelector ||
      el.msMatchesSelector;
    while (el) {
      if (matchesSelector.call(el, selector)) {
        return el;
      }
      el = el.parentElement;
    }
    return null;
}

class DoctorList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            specialtySpanList: [],
            limit: 20,
            page: 1,
            loadDataLoading: false,
            pageLoading: false,
            selectedAddress: [],
            tipsAddress: [],
            tipsSelf: [],
            country_code: '',
            province_code: '',
            city_code: '',
            region_code: '',
            country_list: [],
            province_list: [],
            city_list: [],
            specialty: [],
            available_now: '',
            my_doctors: '',
            active_sg: '',
            monitoring: '',
            selected: [],
            doctorList: [],
            programList: [],
            totalDocList: 0,
            tipList: [],
            latitude: '',
            longitude: '',
            filter_key: '',
            ge_doctor: '',
            savedrLoading: false,
            fliterSelfArr: [
                {
                    code: 'available_now',
                    display_name: 'Available Now'
                },
                {
                    code: 'my_doctors',
                    display_name: 'My Doctors'
                },
                // {
                //     code: 'active_sg',
                //     display_name: 'ActiveSG Promo'
                // },
                {
                    code: 'monitoring',
                    display_name: 'Explore Monitoring Program'
                }
            ],
            menu_list: [],
            errorReuqestMsg: [],
            selected_filter: '',
            consult_general: '',
            find_doctor: '',
            program_open: false,
            program_request_id: "",
            program_file: {},
        }
    }

    componentWillMount() {


    }

    async componentDidMount() {
        let httpUrl = this.props.location.search
        let _query = URL.parse(httpUrl, true).query
        console.log(httpUrl)
        console.log(_query)
        
        if (GOOGLE_ANALYTICS) {
            ReactGA.set({
                page: window.location.href,
            });
        }
        let _filter = '';
        let _filterKey = '';

        let len = window.location.href.split("/");
        let lenLength = len.length;

        if (getCache('filter_docotor')) {
            let { filter, filterKey } = JSON.parse(getCache('filter_docotor'));
            if (filter || filterKey) {
                _filter = filter;
                _filterKey = filterKey;
            }
        }
        if (_filter || _filterKey) {
            if (_filter === "asg") {
                this.checkParams("active_sg")
            } else if (_filter === "ge") {
                this.setState({
                    ge_doctor: "Y",
                }, () => {
                    this.coachSearch("inquire_module");
                });
            } else if (_filterKey) {
                if (_filterKey === "find_doctor" || _filterKey === "consult_general") {
                    this.setState({
                        [_filterKey]: "Y",
                    }, () => {
                        this.coachSearch("inquire_module");
                    });
                } else {
                    this.checkParams(_filterKey);
                }
            } else {
                this.coachSearch("inquire_module");
            }
        } else {

            if (!isInvalid(_query) && _query.filter) {
                _filter = _query.filter;
            }

            if (_filter === "asg") {
                this.checkParams("active_sg")
            } else if (_filter === "ge") {
                this.setState({
                    ge_doctor: "Y",
                }, () => {
                    this.coachSearch("inquire_module");
                });
            } else if (
                this.props.location.state &&
                this.props.location.state.filterKey
            ) {
                _filterKey = this.props.location.state.filterKey;
                if (_filterKey === "find_doctor" || _filterKey === "consult_general") {
                    this.setState({
                        [_filterKey]: "Y",
                    }, () => {
                        this.coachSearch("inquire_module");
                    });
                } else {
                    // console.log(this.props.location.state)
                    this.checkParams(this.props.location.state.filterKey);
                }
            } else {

                if (lenLength == 5 || lenLength == 6 || lenLength == 7) {
                    if (len[5]) await AuthGuard.getNewToken(len[5])
                    if (len[4] == 'myDoctor') {

                        this.checkParams("my_doctors")

                    } else if (len[4] == 'findDoctor') {
                        this.setState({
                            find_doctor: 'Y'
                        }, () => {
                            this.coachSearch("inquire_module");
                        })
                    } else if (len[4] == 'activeSG') {

                        this.checkParams("active_sg")

                    } else if (len[4] == 'monitoringProgram') {
                        if (len[6]) {
                            // if (len[5]) await AuthGuard.getNewToken(len[5])
                            let pgName = len[6].replace(/[+]/g, ' ')
                            this.setState({ filter_key: pgName }, () => {
                                this.checkParams('monitoring')
                            })
                        } else {
                            this.checkParams('monitoring')
                        }
                    }
                } else {
                    this.coachSearch("inquire_module");
                }

            }
            removeCache('filter_docotor');
            setCache('filter_docotor', { 'filter': _filter, 'filterKey': _filterKey });
        }
        this.getspecialtyList();
        this.getServiceCountry();
        window.addEventListener("scroll", this.handleScroll.bind(this)); //监听滚动

        this.setState({
            menu_list: Menu(),
        });


    }

    componentWillReceiveProps(nextProps) {
        if (GOOGLE_ANALYTICS) {
            ReactGA.set({
                page: window.location.href,
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll.bind(this))
    }

    handleScroll = (e) => {
        // console.log(
        //     '浏览器滚动事件',
        //     e.srcElement.scrollingElement.scrollTop,
        //     e.srcElement.scrollingElement.scrollHeight
        // )

        let scrollTop = e.srcElement.scrollingElement.scrollTop
        let scrollHeight = e.srcElement.scrollingElement.scrollHeight
        let clientHeight = e.srcElement.scrollingElement.clientHeight

        if (scrollHeight - scrollTop - clientHeight <= 300) {
            // console.log('<= 300')
            // <= 300
            this.loadMore();
        } else {
            // console.log('> 300')
        }
    }
    checkParams = async (key) => {
        let findItem = this.state.fliterSelfArr.find(item => item.code === key)
        if (key === 'my_doctors') {
            console.log(findItem)
            await this.filterSelf(findItem)
            this.coachSearch('inquire_module')
        }

        if (key === 'active_sg') {
            await this.filterSelf(findItem)
            this.coachSearch('inquire_module')
        }

        if (key === 'monitoring') {
            console.log(findItem)
            await this.filterSelf(findItem)
            this.coachSearch('inquire_module')
        }

    }

    loadingToast() {
        Toast.loading('Loading...', 30, () => {
            console.log('Load complete !!!');
        });
    }

    onOpenChange = (e) => {
        this.setState({
            open: !this.state.open
        }, () => {

            // console.log(this.state.open)
            console.log(this.state.city_code, this.state.province_code, this.state.country_code)
            console.log(this.state.available_now)



            if (this.state.open === false) {

                // if (this.state.city_code !== '' && this.state.province_code !== '' && this.state.country_code !== '' && this.state.available_now !== '' && this.state.my_doctors !== '' && this.state.active_sg !== '') {
                this.submitSearch()
                // }

            }

        });

        // console.log(this.state.open)

    }

    searchInputFocus = () => {
        let { open } = this.state
        if (open) {
            this.onOpenChange()
        }
    }

    getspecialtyList() {
        // this.loadingToast()
        AuthGuard.getspecialtyList({}, (isOk, res) => {
            if (isOk) {
                this.setState({
                    specialtySpanList: res
                })
            }
        });
    }

    coachSearch = (inquiry_mode) => {

        if (this.state.my_doctors) {
            // console.log(this.state.my_doctors)

            let _url = "/doctorList"
            if (!AuthGuard.checkLogin()) {
                console.log(this.state.open)
                this.loginModel.alertLogin({
                    url: _url,
                    alert_title: 'You need a HeartVoice account to save doctors'
                })
                // console.log(this.open)

                return false
            }
        }
        //如果正在加载 就不在继续执行下去
        if (this.state.loadDataLoading) return

        this.loadingToast()
        this.setState({
            open: false,
            loadDataLoading: true
        })
        let _specialty = []
        if (this.state.tipList.length > 0) {
            this.state.tipList.map((item, index) => {
                let findItem = this.state.specialtySpanList.find(iitem => iitem.display_name === item)
                _specialty.push(findItem.code)
            })
            _specialty = JSON.stringify(_specialty)
        }

        // let _doctorList = this.state.doctorList
        // console.log(_doctorList)
        // return false
        console.log(this.state.monitoring)

        var searchParams = {
            limit: this.state.limit,
            page: this.state.page,
            country_code: this.state.country_code,
            specialty: _specialty,
            available_now: this.state.available_now ? 'online' : '',
            my_doctors: this.state.my_doctors ? 'Y' : '',
            active_sg: this.state.active_sg ? 'Y' : '',
            monitoring: this.state.monitoring ? 'Y' : '',
            ge_doctor: this.state.ge_doctor ? 'Y' : '',
            find_doctor: this.state.find_doctor ? 'Y' : '',
            consult_general: this.state.consult_general ? 'Y' : '',
            filter_key: this.state.filter_key
        };

        if (!searchParams.my_doctors && !searchParams.active_sg && !searchParams.ge_doctor && !searchParams.find_doctor && !searchParams.consult_general && !searchParams.monitoring) {
            searchParams.find_doctor = 'Y'
        }

        if (this.state.country_code !== '') {
            if (this.state.country_code === 'SG') {
                searchParams = {
                    ...searchParams,
                    region_code: this.state.region_code,
                }
            }
            else {
                searchParams = {
                    ...searchParams,
                    province_code: this.state.province_code,
                    city_code: this.state.city_code,
                }
            }
        }

        if (getCache('userLatLong')) {
            searchParams = {
                ...searchParams,
                ...JSON.parse(getCache('userLatLong'))
            }
        }


        console.log(searchParams)
        if (searchParams.monitoring === 'Y') {
            if (AuthGuard.checkLogin()) {
                AuthGuard.getProgramTokenList({
                    clinicInfoId: '',
                    status: 'active',
                    programName: this.state.filter_key
                }, (isOk, res) => {
                    Toast.hide();
                    if (isOk) {
                        this.setState({
                            doctorList: [],
                            programList: res,
                            totalDocList: res.length ? parseInt(res.length) : 0,
                            loadDataLoading: false,
                            pageLoading: false,
                        })
                    } else {
                        this.setState({
                            doctorList: [],
                            programList: [],
                            totalDocList: 0,
                            loadDataLoading: false,
                            pageLoading: false,
                        })
                    }
                });
            } else {
                AuthGuard.getprogramList({
                    clinicInfoId: '',
                    status: 'active',
                    programName: this.state.filter_key
                }, (isOk, res) => {
                    Toast.hide();
                    if (isOk) {
                        this.setState({
                            doctorList: [],
                            programList: res,
                            totalDocList: res.length ? parseInt(res.length) : 0,
                            loadDataLoading: false,
                            pageLoading: false,
                        })
                    } else {
                        this.setState({
                            doctorList: [],
                            programList: [],
                            totalDocList: 0,
                            loadDataLoading: false,
                            pageLoading: false,
                        })
                    }
                });
            }
        } else {
            AuthGuard.coachSearch(searchParams, (isOk, res) => {
                // console.log(isOk, res)
                if (isOk) {
                    let newList = [];
                    if (inquiry_mode === 'slide_module') {
                        let hash = {};
                        newList = this.state.doctorList.concat(res.result);
                        newList = newList.reduce((item, next) => {
                            (hash[next.id] ? '' : hash[next.id] = true) && item.push(next);
                            return item
                        }, [])
                    } else if (inquiry_mode === 'inquire_module') {
                        newList = res.result;
                    }
                    this.setState({
                        programList: [],
                        doctorList: newList,
                        totalDocList: res.total ? parseInt(res.total) : 0,
                        loadDataLoading: false,
                        pageLoading: false,
                    })

                } else {
                    this.setState({
                        programList: [],
                        doctorList: [],
                        totalDocList: 0,
                        loadDataLoading: false,
                        pageLoading: false,
                    })
                }

                Toast.hide();
            });
        }
    }

    loadMore = () => {

        if (this.state.pageLoading) {
            return
        }

        // console.log('loadMore');

        let limit = this.state.limit;
        let page = this.state.page;
        let total = this.state.totalDocList;

        page = parseInt(page) + parseInt(1);
        if (page * limit - total > limit) {

            return;
        }
        this.setState({
            page: page,
            pageLoading: true,
        }, () => {

            // if (AuthGuard.checkLogin()) {
            this.coachSearch('slide_module')
            // }

        })
    }

    getServiceCountry() {
        AuthGuard.getServiceCountry({}, (isOk, res) => {
            if (isOk) {
                this.setState({
                    country_list: res
                })
            }
        });
    }

    getProvince = (cuntryCode) => {
        console.log(cuntryCode)
        if (cuntryCode === this.state.country_code) {
            this.setState({
                country_code: '',
                province_list: [],
                province_code: '',
                city_list: [],
                region_code: '',
                city_code: '',
                tipsAddress: []
            })
            return false
        }

        this.delAllRCPC()
        this.setState({
            country_code: cuntryCode,
            province_list: [],
            city_list: [],
            province_code: '',
            region_code: ''
        }, () => {
            this.addRCPC()
        })

        if (cuntryCode === 'SG') {
            AuthGuard.getSGRegion('SG', (isOk, res) => {
                if (isOk) {
                    let _res = []
                    if (res.length > 0) {
                        res.map((item, index) => {
                            _res.push({
                                code: item.code,
                                name: item.display_name
                            })
                        })
                    }

                    this.setState({
                        province_list: _res
                    })

                }
            })
        } else {
            AuthGuard.getProvinceList({
                code: cuntryCode
            }, (isOk, res) => {
                if (isOk) {
                    console.log(res)
                    let _res = []
                    if (res.length > 0) {
                        let province_list = res[0].province_list
                        if (province_list.length > 0) {
                            province_list.map((item, index) => {
                                _res.push({
                                    code: item.code,
                                    name: item.name,
                                })
                            })
                        }
                    }
                    this.setState({
                        province_list: _res
                    })
                }
            })
        }
    }

    getCity = (provinceCode) => {
        this.delAllRCPC()
        let { country_code } = this.state
        if (country_code === 'SG') {
            if (provinceCode === this.state.region_code) {
                this.setState({
                    region_code: '',
                    province_code: ''
                })

                return false
            }

            this.setState({
                region_code: provinceCode,
                province_code: ''
            }, () => {
                this.addRCPC()
            })

        } else {

            if (provinceCode === this.state.province_code) {
                this.setState({
                    region_code: '',
                    province_code: '',
                    city_list: [],
                    city_code: ''
                })

                return false
            }

            this.setState({
                province_code: provinceCode,
                city_list: [],
                city_code: '',
                region_code: ''
            }, () => {
                this.addRCPC()
            })

            AuthGuard.getCityList({
                province_code: provinceCode
            }, (isOk, res) => {
                if (isOk) {
                    let _res = res.length > 0 ? res.map((item, index) => {
                        return {
                            code: item.code,
                            name: item.name
                        }
                    }) : []

                    this.setState({
                        city_list: _res
                    })
                }
            })
        }
    }

    addItemToFilter = (cityCode) => {
        this.delAllRCPC()
        if (cityCode === this.state.city_code) {
            this.setState({
                city_code: ''
            })
            return false
        }

        this.setState({
            city_code: cityCode
        }, () => {
            this.addRCPC()
        })

        // console.log(cityCode)
        // console.log(this.state.city_code)

    }

    delAllRCPC = () => {
        this.setState({
            tipsAddress: []
        })
    }

    delRCPC = (tipsName) => {
        let { country_name, province_name, city_name, region_name } = this.filtterCPC()
        console.table(country_name, province_name, city_name, region_name, tipsName)
        // this.setState({
        //     region_code: '',
        //     city_code: '',
        //     province_code: '',
        //     country_code: '',
        //     city_list: [],
        //     province_list: [],
        //     tipsAddress: []
        // })
        // this.delAllRCPC()
        if (tipsName === country_name) {
            this.setState({
                region_code: '',
                city_code: '',
                country_code: '',
                province_code: '',
                city_list: [],
                province_list: [],
                // country_list:[],
                tipsAddress: []
            }, () => {
                this.delTipList(country_name)
                this.delTipList(province_name)
                this.delTipList(city_name)
            })
        } else if (tipsName === province_name) {
            this.setState({
                region_code: '',
                city_code: '',
                province_code: '',
                city_list: [],
                // province_list: []
            }, () => {
                this.delTipList(province_name)
                this.delTipList(city_name)
            })
        } else if (tipsName === region_name) {
            this.setState({
                region_code: '',
                city_code: '',
                province_code: '',
                city_list: [],
                // province_list: [],
            }, () => {
                this.delTipList(region_name)
            })
        } else if (tipsName === city_name) {
            this.setState({
                region_code: '',
                city_code: '',
            }, () => {
                this.delTipList(city_name)
            })
        }
        // console.log(item)
        // this.delTipList(country_name)
        // this.delTipList(province_name)
        // this.delTipList(city_name)
        // this.delTipList(region_name)
    }

    addRCPC = () => {
        let { country_name, province_name, city_name, region_name } = this.filtterCPC()
        this.addTipList(country_name)
        this.addTipList(province_name)
        this.addTipList(city_name)
        this.addTipList(region_name)

    }

    filtterCPC = () => {
        let {
            country_code,
            province_code,
            city_code,
            region_code,
            country_list,
            province_list,
            city_list
        } = this.state
        let country_name, province_name, city_name, region_name
        let findCountry = country_list.find(item => item.country_code === country_code)
        country_name = country_code && country_list && findCountry ? findCountry['country'] : ''

        if (country_code === 'SG') {
            let finregion = province_list.find(item => item.code === region_code)
            region_name = region_code && province_list && finregion ? finregion['name'] : ''
        } else {
            let findProvince = province_list.find(item => item.code === province_code)
            let findCity = city_list.find(item => item.code === city_code)

            province_name = province_code && province_list && findProvince ? findProvince['name'] : ''
            city_name = city_code && city_list && findCity ? findCity['name'] : ''
        }

        console.table({ country_name, province_name, city_name, region_name })

        return { country_name, province_name, city_name, region_name }

    }

    saveDr(id, status, e) {
        e.stopPropagation();
        if (this.state.open) return;
        let _url = "/doctorList"
        if (!AuthGuard.checkLogin()) {
            this.loginModel.alertLogin({
                url: _url
            })
            return false
        }

        if (this.state.savedrLoading) return

        this.setState({
            savedrLoading: true
        })
        // this.loadingToast()
        AuthGuard.savedr({
            coach_id: id,
            status: status === 'saved' ? 'unsaved' : 'saved'
        }, (isok, res) => {
            if (isok) {
                let { coach_id, status } = res
                let _doctorList = this.state.doctorList
                let index = _doctorList.findIndex(item => item.id === coach_id)
                _doctorList[index].saved = status
                this.setState({
                    doctorList: _doctorList
                })
            }
            this.setState({
                savedrLoading: false
            })

            // Toast.hide();
        })
    }

    addTipList = (name) => {
        if (!name) return
        let spanList = this.state.tipsAddress
        spanList.push(name)
        this.setState({
            tipsAddress: spanList
        })
    }

    delTipList = (name) => {
        if (!name) return
        let spanList = this.state.tipsAddress
        var index = spanList.indexOf(name);
        if (index > -1) {
            spanList.splice(index, 1);
        }

        this.setState({
            tipsAddress: spanList
        })
    }

    add(a) {
        let spanList = this.state.tipList
        var index = spanList.indexOf(a.display_name);
        if (index > -1) {//大于0 代表存在，
            spanList.splice(index, 1);
        } else {
            spanList.push(a.display_name)
        }
        this.setState({
            tipList: spanList
        })
    }

    del(index, e) {
        //展开数组
        let list = [...this.state.tipList]
        //删除元素
        list.splice(index, 1)
        this.setState({
            tipList: list,
        })
    }

    filterSelf(a) {
        console.log(a)
        let spanList = this.state.tipsSelf
        var index = spanList.indexOf(a.display_name);
        if (index > -1) {//大于0 代表存在，
            spanList = []
            this.setState({
                available_now: '',
                my_doctors: '',
                active_sg: '',
                monitoring: ''
            })
        } else {
            spanList = [a.display_name]
            this.setState({
                available_now: a.code === 'available_now',
                my_doctors: a.code === 'my_doctors',
                active_sg: a.code === 'active_sg',
                monitoring: a.code === 'monitoring'
            })
        }
        this.setState({
            selected_filter: this.state.selected_filter === a.code ? '' : a.code,
            region_code: '',
            city_code: '',
            country_code: '',
            province_code: '',
            ge_doctor: '',
            city_list: [],
            province_list: [],
            tipsAddress: [],
            tipList: [],

        })
        this.setState({
            tipsSelf: spanList
        })

    }

    shouldDisableFilter(that, item) {
        if (that.state.selected_filter === '') {
            return false
        }

        if (that.state.selected_filter === 'monitoring') {
            return item.code !== 'monitoring'
        }
        return item.code === 'monitoring'
    }


    delTipsSelf = (item) => {
        console.log(item)
        let spanList = this.state.tipsSelf
        spanList = []
        this.setState({
            available_now: '',
            my_doctors: '',
            tipsSelf: spanList,
            selected_filter: '',
            monitoring: '',
            active_sg: ''
        })
    }

    goDoctorDetail(dID, cID) {
        // let _url_check = "/doctorList/";
        // if (!AuthGuard.checkLogin()) {
        //     this.loginModel.alertLogin({
        //         url: _url_check
        //     })
        //     return false
        // }
        //当蒙版打开的时候 不相应点击事件
        if (this.state.open) return;
        if (this.state.loadDataLoading) return
        setCache("clinic_id",cID)
        let _url = "/doctorDetails/" + cID + '/' + dID
        this.props.history.push(_url);

        // if (!AuthGuard.checkLogin()){
        //     this.loginModel.alertLogin({
        //         url: _url,
        //         query: val
        //     })
        // }else{
        //     this.props.history.push(_url);
        // }
    }

    goClinicSelectedDr = async (cid, uid, identification, e) => {
        e.stopPropagation();
        // e.stopPropagation();
        // if (this.state.open) return;
        // let _url = "/doctorDetails/" + cid + '/' + uid
        // this.props.history.push(_url);

        // if (isBrowser) {
        //     Modal.alert('Tips', <div>We are getting you a doctor. check your whatsapp for updates!</div>, [
        //         { text: 'OK', onPress: () => console.log('ok') }
        //     ])
        // } else {
        //     // window.location.href = 'https://wa.me/' + WHATSAPP_MOBILENO + '?text=hello' + identification;
        //     window.location.href = 'https://wa.me/' + WHATSAPP_MOBILENO
        // }
        let _url = window.location.pathname
        if (!AuthGuard.checkLogin()) {
            this.loginModel.alertLogin({
                url: _url,
                alert_title: 'You need a HeartVoice account to consult doctors.',
            })
        } else {
            try {
                let res = await this.checkProfile()
                if (navigator.userAgent.indexOf('heartvoice') !== -1) {
                    this.doMobileInqueue.call(this, uid, 'app')
                } else {
                    this.doMobileInqueue.call(this, uid, 'whatsapp')
                }
            } catch (error) {
                if (error && error.status_code !== 401) {
                    Modal.alert('Complete Profile', <div className="complete_Profile">Please provide more information about
                        yourself to initiate a teleconsultation. </div>, [
                        { text: 'Cancel', style: { color: '#108ee9' }, onPress: () => console.log('第0个按钮被点击了') },
                        { text: 'Proceed', style: { color: '#108ee9' }, onPress: () => this.goMyinfo() },
                        {
                            text: ' ',
                            style: { display: 'none' },
                            onPress: () => {
                                console.log('No')
                            }
                        }
                    ])
                }
            }
        }
    }

    checkProfile = () => {
        this.loadingToast()
        return new Promise((resolve, reject) => {
            AuthGuard.checkProfileComplete({}, (isOk, res) => {
                console.log(isOk, res)
                let { drug_allergy, is_complete, source } = res
                Toast.hide()
                if (isOk) {
                    if (is_complete) {
                        resolve(res)
                    } else {
                        this.setState({
                            user_drug_allergy: drug_allergy,
                            user_source: source,
                            user_is_complete: is_complete
                        }, () => {
                            reject(res)
                        })
                    }

                } else {
                    reject(res)
                }
            })
        })
    }
    isShowTele = (item) => {
        if (!item?.doctor_opening_time?.length || item?.doctor_opening_time[0]?.data[0]?.lead_time||item?.doctor_opening_time[0]?.lead_time) {
            return false
        }
        if (!(item.lead_time === undefined || item.lead_time === null || item.lead_time === 0 || item.lead_time === "" || item.lead_time === "0")){
            return false
        }
        return true
    }
    goMyinfo = () => {
        let hasUrl = window.location.pathname
        console.log(hasUrl)
        setCache('redirectUrl', hasUrl)
        if (this.state.user_source === "manually") {
            // manually 手动注册的用户
            this.props.history.push("/improve");
        } else {
            // myinfo myinfo注册的用户
            if (!this.state.user_is_complete && this.state.user_drug_allergy) {
                this.props.history.push("/improve");
            } else {
                this.props.history.push("/drugAllergy");
            }
        }
    }

    doMobileInqueue(id, channel) {
        this.loadingToast()
        this.setState({
            consultBtnLoading: true
        })
        AuthGuard.mobileInqueue({
            doctor_id: id,
            channel: channel
        }, (isOk, res) => {
            Toast.hide();
            console.log(isOk, res)
            this.setState({
                consultBtnLoading: false
            })
            if (isOk) {
                if (res.message !== null) {
                    if (isBrowser) {
                        Modal.alert('Oops', <div>We are getting you a doctor. check your whatsapp for updates!</div>, [
                            { text: 'Okay', onPress: () => console.log('第0个按钮被点击了') }
                        ])
                    } else {
                        if (channel == 'app') {
                            window.location.href = 'heartvoice://heartvoice/home?action=home'
                        } else {
                            Modal.alert('Appointment Requested', <div>You will receive a whatsapp message when the doctor confirms your appointment request.</div>, [
                                { text: 'Okay', onPress: () => { window.location.href = 'https://wa.me/' + WHATSAPP_MOBILENO } }
                            ])
                        }
                    }
                }
            } else {
                if (res.status_code !== 401) {
                    Modal.alert('Oops', <div>{res.message}</div>, [
                        { text: 'Okay', onPress: () => console.log('第0个按钮被点击了') }
                    ])
                }
            }
        });
    }

    goBack = () => {
        // this.props.history.goBack()
        this.props.history.push("/telemedicine");
    }

    onRef = (ref) => {
        this.loginModel = ref
    }

    keyPressSearch = (e) => {
        if (e.which !== 13) return
        this.setState({
            page: 1,
            doctorList: []
        }, () => {
            this.coachSearch('inquire_module')
        });
    }
    submitSearch = (e) => {
        ReactGAEvent("drList search event", "onclick search fillter");

        if (AuthGuard.checkLogin()) {
            this.setState({
                page: 1,
                doctorList: []
            }, () => {
                this.coachSearch('inquire_module')
            });
        } else {
            this.setState({
                page: 1,
                // doctorList: []
            }, () => {
                this.coachSearch('inquire_module')
            });
        }


    }

    onWrapTouchStart = (e) => {
        // fix touch to scroll background page on iOS
        if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
            return;
        }
        const pNode = closest(e.target, ".am-modal-content");
        if (!pNode) {
            e.preventDefault();
        }
    };

    requestCode(program_id, programFile = {}) {
        let _url = '/doctorList/';
        if (!AuthGuard.checkLogin()) {
            this.loginModel.alertLogin({
                url: _url,
                alert_title: 'Please Login in.',
            })
            return false
        } else {
            if(Object.keys(programFile).length){
                this.setState({
                    program_open: true,
                    program_request_id: program_id,
                    program_file: programFile
                })
                return;
            }
            this.setState({
                errorReuqestMsg: {}
            })
            let _programList = this.state.programList
            let findItemIndex = _programList.findIndex(item => item.id == program_id);
            this.loadingToast();
            AuthGuard.requestCode({
                programId: program_id,
            }, (isOk, res) => {
                Toast.hide();
                if (isOk) {
                    _programList[findItemIndex].purchase_type = 'Requested';
                    this.setState({
                        programList: _programList,
                        program_request_id: "",
                        program_open: false,
                        program_file: {},
                    })
                } else {
                    this.setState({
                        errorReuqestMsg: {
                            id: program_id,
                            message: res
                        },
                        program_request_id: "",
                        program_open: false,
                        program_file: {},
                    })
                }
            })
        }
    }

    consultationFee({currency_code,max_fee,min_fee}) {
        let str = '--'
        if(max_fee && min_fee){
            str = currencyType(min_fee,currency_code) +'-' + currencyType(max_fee,currency_code)
            return str;
        }

        if(min_fee){
            str = currencyType(min_fee,currency_code)
            return str;
        }

        if (max_fee) {
            str = currencyType(max_fee, currency_code);
            return str;
        }

        return str
    }

    render() {
        let getDoctorList = [];
        let getProgramList = [];
        if (this.state.programList.length > 0) {
            getProgramList = this.state.programList.length > 0 ? this.state.programList.map((item, index) => {
                return (
                    <li className="programs-list" key={item.id}>
                        {/* <div className="main"> */}
                        <div className="Health-program-main">
                            <div className="Health-program">
                                <WingBlank size="lg">
                                    <div className="scroll-box" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <div className="scroll-main">
                                            <div className="health-program-card" style={{ boxShadow: '0 0 5px 0.5px #c0c0c0' }}>
                                                <div className="img-bg">
                                                    <WhiteSpace size="lg" /><WhiteSpace size="lg" />
                                                    <WingBlank size="lg"><WingBlank size="lg">
                                                        <img className="banner-nobg" src={item.image.url.o} alt="" />
                                                    </WingBlank></WingBlank>
                                                    <WhiteSpace size="lg" />
                                                </div>
                                                <WhiteSpace size="lg" />
                                                <h2 style={{ textAlign: 'left', marginLeft: '1rem' }}>{item.name}</h2>
                                                <WhiteSpace size="lg" />
                                                <WingBlank size="lg">
                                                    <Flex>
                                                        <img className="close-red" src={close_red} alt="close_red" />
                                                        <Flex.Item>
                                                            <h2>Chat</h2>
                                                            <p className="flGrey">In-App messaging using</p>
                                                            <p className="flGrey">HeartVoice Application</p>
                                                        </Flex.Item>
                                                    </Flex>
                                                </WingBlank>
                                                <div className="background-ccc" style={{ backgroundColor: '#f8f8f8' }}>
                                                    <WhiteSpace size="lg" />
                                                    <WingBlank size="lg">
                                                        <WingBlank size="lg">
                                                            <h2 style={{ textAlign: 'left' }}>Whats It Includes</h2>
                                                            <WhiteSpace size="lg" />
                                                            <p className="flGrey">-High Blood Pressure(Hypertension), High Cholesterol(Hyperlipidemia), Blood Sugar(Diabetes) </p>
                                                            <p className="flGrey">-Regular review of chronic disease parameters</p>
                                                            <p className="flGrey">-Customised weight /diet plans</p>
                                                            <p className="flGrey">-Teleconsultation/In-Person consultation</p>
                                                            <p className="flGrey">-Medication Delivery to your door step</p>
                                                            <p className="flGrey">-purchase of connected devices</p>
                                                        </WingBlank>
                                                    </WingBlank>
                                                    <WhiteSpace size="lg" />

                                                </div>
                                                <WhiteSpace size="lg" /><WhiteSpace size="lg" />
                                                <WingBlank size="lg">
                                                    <WingBlank size="lg" >
                                                        {item.purchase_type == 'Request' ?
                                                            <Button className="request-img fill-pink-btn" onClick={() => this.requestCode(item.id,{url: item?.program_file_url || "",title: item?.program_file || ""})}>Request</Button> :
                                                            (item.purchase_type == 'Requested' ?
                                                                <Button className="request-img fill-gray-btn"><img src={success_icon} />  Requested!</Button> :
                                                                (item.purchase_type == 'Joined' ?
                                                                    <Button className="request-img fill-gray-btn"><img src={success_icon} />  Joined</Button> : ''))}
                                                        <p className="program_message">{this.state.errorReuqestMsg.id == item.id ? this.state.errorReuqestMsg.message : ''}</p>
                                                    </WingBlank>
                                                </WingBlank>
                                                <WhiteSpace size="lg" /><WhiteSpace size="lg" />
                                                <WingBlank size="lg"><WingBlank size="lg">
                                                    {item.purchase_type == 'Request' ?
                                                        <p className="flGrey bottomP">Click request to find out more information on the specifics of clinic offering and pricing. The clinic will be in touch shortly.</p> :
                                                        (item.purchase_type == 'Requested' ?
                                                            <p className="flGrey bottomP">You have successfully requested for monitoring program. The clinic will be in touch shortly. You will receive a confirmation email when you can start the program.</p> :
                                                            (item.purchase_type == 'Joined' ?
                                                                <p className="flGrey bottomP">You have successfully requested for monitoring program. The clinic will be in touch shortly. You will receive a confirmation email when you can start the program.</p> : ''))}
                                                </WingBlank></WingBlank>
                                                <WhiteSpace size="lg" /><WhiteSpace size="lg" />
                                            </div>
                                        </div>
                                    </div>
                                    <WhiteSpace size="lg" /><WhiteSpace size="lg" />
                                    <WhiteSpace size="lg" /><WhiteSpace size="lg" />
                                </WingBlank>
                            </div>
                        </div>
                        {/* </div> */}
                    </li>)
            }) : []
        } else {
            //搜索获取医生列表
            getDoctorList = this.state.doctorList.length > 0 ? this.state.doctorList.map((item, index) => {
                let _specialty = '-'
                let _specialtyArr = []
                if (item.specialty && item.specialty.length > 0) {
                    item.specialty.map((item, index) => {
                        _specialtyArr.push(item.display_name)
                    })
                    _specialty = _specialtyArr.join(' / ')
                }
                return (
                    <li className="doctor-info" key={item.id} onClick={() => this.goDoctorDetail(item.id, item.clinic_info_id)}>
                        <div className="doctor-info-left">

                            <h2 className="doctor-name doctor-major">{item.doctorinfo !== 'undefined' ? item.doctorinfo.name : '-'}</h2>
                            <span className="doctor-major flGrey">{_specialty}</span>
                            <div className="doctor-introduce" style={{ display: 'inline-block' }}>
                                <span>{item.clinicinfo ? item.clinicinfo.clinic_name : ''}</span><br />
                                <span>{item.clinicinfo ? item.clinicinfo.clinic_address + ' ' + item.clinicinfo.unit_no : ''}</span><br />
                                <span>{item.clinicinfo.country} {item.clinicinfo ? item.clinicinfo.postal_code : ''}</span><br />
                                <span>{item.clinicinfo.clinic_contact_no ? "+" + item.clinicinfo.clinic_dialing_code + ' ' : ''}{item.clinicinfo.clinic_contact_no} </span><br />
                                <span>Consultation fee:{this.consultationFee({
                                    currency_code:item.clinicinfo.profile.teleconsultation_currency_code,
                                    max_fee:item.clinicinfo.teleconsultation_consultation_max_fee,
                                    min_fee:item.clinicinfo.teleconsultation_consultation_min_fee
                                })}
                                </span>
                            </div>
                        </div>
                        <div className="doctor-right">
                            <img className="doctor-head-pic" src={item.photo.url == undefined ? iconDoctorAvatar : item.photo.url.o} alt={item.photo.name} />
                            <div className="iconSaveDr" onClick={this.saveDr.bind(this, item.id, item.saved)}>
                                <img src={item.saved === 'saved' ? iconUnSaveDoctor : iconSaveDoctor} alt="" />
                                <p className="flPink">{item.saved === 'saved' ? 'Unsave' : 'Save'}</p>
                            </div>
                            {(item.doctor_opening_time_to && this.isShowTele(item)) ? <img src={VideoCallPic} className="VideoCallPic" alt="VideoCall" onClick={this.goClinicSelectedDr.bind(this, item.clinic_info_id, item.id, item.identification)} /> : !!item.lead_time}
                        </div>
                    </li>
                )
            }
            ) : []
        }        

        let specialtySpan = this.state.specialtySpanList.map((item, index) => {
            return (
                <Tag className="flGrey" key={index} selected={this.state.tipList.includes(item.display_name)} onChange={() => { this.add(item) }} disabled={this.state.selected_filter === 'monitoring'}>{item.display_name}</Tag>
            )
        });

        let addtips = this.state.tipList.map((item, index) => {
            return (
                <Tag key={index} onChange={this.del.bind(this, index)}> {item} X </Tag>
            )
        });

        let addtipsAddress = this.state.tipsAddress.map((item, index) => {
            return (
                <Tag key={index} onChange={this.delRCPC.bind(this, item)}> {item} X </Tag>
            )
        })

        let addtipsSelf = this.state.tipsSelf.map((item, index) => {
            return (
                <Tag key={index} onChange={this.delTipsSelf.bind(this, item)}> {item} X </Tag>
            )
        })

        return (
            <>
                <div className="Doctor-list pd-0">
                    <LoginModel
                        onRef={this.onRef}
                        history={this.props.history}
                    />
                    {!isBrowser ? <NavBar
                        mode="light"
                        icon={<Icon type="left" color="#EB5DA6" size="lg" />}
                        onLeftClick={() => this.goBack()}
                    ></NavBar> :

                        <div className="pc-home">
                            <div className="header">
                                <a href="https://www.ourheartvoice.com/">
                                    <div className="logo">
                                        <img src={logo}></img>
                                    </div>
                                </a>
                                <div className='menu-list'>
                                    <ul className="menu-ul">
                                        {this.state.menu_list.map((item, index) => {
                                            return (
                                                <li className="menu-ul-li" key={`menu-ul-li${index}`}>
                                                    <div className={`menu-name ${item.code === 'for_patients' ? 'active-sub-meue' : ''} ${item.code === 'contact' ? 'block-div' : ''}`}>
                                                        {item.href ? <a href={item.href}> {item.name} </a> : item.name}
                                                    </div>
                                                    {item.children ? (
                                                        <ul className="menu-sub">
                                                            {item.children.map((iitem, iindex) => {
                                                                return (
                                                                    <li className="menu-ul-li-li" key={`menu-ul-li-ul-li-${iitem.code}`}>
                                                                        <div className={`menu-sub-name ${iitem.code === 'heartvoice_telemedicine' ? 'active-sub-meue' : ''}`}>
                                                                            <a href={iitem.href}>{iitem.name}</a>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    ) : null}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <NavBar
                                mode="light"
                                icon={<Icon type="left" color="#EB5DA6" size="lg" />}
                                onLeftClick={() => this.goBack()}
                            ></NavBar>
                        </div>
                    }

                    <div className="white-panel" style={{ backgroundColor: this.state.open ? "white" : '' }}>
                        <Flex className="doctor-search">
                            <Flex.Item>
                                <Flex>
                                    <div className="search-module">
                                        <img src={Search} className="Search" alt="Search" />
                                        <input
                                            className="search-input"
                                            type="search"
                                            value={this.state.filter_key}
                                            onKeyPress={this.keyPressSearch}
                                            // onFocus={this.searchInputFocus} 
                                            onChange={e => {
                                                this.setState({
                                                    filter_key: e.target.value
                                                })
                                            }}
                                            placeholder="SEARCH" />
                                    </div>
                                </Flex>
                            </Flex.Item>
                        </Flex>
                        <Flex className="filters-module">
                            <Flex className="filter-top">
                                <div className="filter">
                                    <div className="filter">
                                        <div className="filter-box" >
                                            <img src={Filter} className="Filter" alt="Filter" onClick={this.onOpenChange} />
                                            <a style={{ marginTop: '0.175rem', marginBottom: '0.175rem', color: '#9b9b9b', fontSize: '0.35rem', fontFamily: 'Lato-Light' }} onClick={this.onOpenChange}>Filters</a>
                                            <div className="addtips">{addtips}{addtipsAddress}{addtipsSelf}</div>
                                        </div>
                                    </div>
                                </div>
                            </Flex>
                        </Flex>
                    </div>
                    <div className="model-filter">
                        <div className={this.state.open ? 'menuList' : 'menuClose menuList'}>

                            <div className="filters-scroll">
                                <div className="line"></div>
                                <div className="btn-box">
                                    <WingBlank size="lg">
                                        {this.state.fliterSelfArr.map((item, index) => {
                                            return <Tag key={item.code} className="flGrey" selected={this.state.tipsSelf.includes(item.display_name)} onChange={() => { this.filterSelf(item) }} disabled={this.shouldDisableFilter(this, item)}> {item.display_name} </Tag>
                                        })}
                                    </WingBlank>
                                </div>
                                <WingBlank size="lg">
                                    <Flex className="menu-title">
                                        <Flex.Item>Country</Flex.Item>
                                        <Flex.Item>Province</Flex.Item>
                                        <Flex.Item>City</Flex.Item>
                                    </Flex>
                                </WingBlank>
                                <div className="line"></div>
                                <WingBlank size="lg">
                                    <Flex>
                                        <Flex.Item direction="column" className="startFlex">
                                            {this.state.country_list.map((item, index) => {
                                                return (
                                                    <Flex.Item key={item.country_code}><Tag className="flGrey" selected={this.state.country_code === item.country_code} onChange={() => { this.getProvince(item.country_code) }} disabled={this.state.selected_filter === 'monitoring'}>{item.country}</Tag></Flex.Item>
                                                )
                                            })}
                                        </Flex.Item>
                                        <Flex.Item direction="column" className="startFlex">
                                            {this.state.province_list.map((item, index) => {
                                                if (this.state.country_code === 'SG') {
                                                    return (
                                                        <Flex.Item key={item.code}><Tag className="flGrey" selected={this.state.region_code === item.code} style={{ whiteSpace: isBrowser ? 'nowrap' : 'normal' }} onChange={() => { this.getCity(item.code) }} disabled={this.state.selected_filter === 'monitoring'}>{item.name}</Tag></Flex.Item>
                                                    )
                                                } else {
                                                    return (
                                                        <Flex.Item key={item.code}><Tag className="flGrey" selected={this.state.province_code === item.code} style={{ whiteSpace: isBrowser ? 'nowrap' : 'normal' }} onChange={() => { this.getCity(item.code) }} disabled={this.state.selected_filter === 'monitoring'}>{item.name}</Tag></Flex.Item>
                                                    )
                                                }

                                            })}
                                        </Flex.Item>
                                        <Flex.Item direction="column" className="startFlex">
                                            {this.state.city_list.map((item, index) => {
                                                return (
                                                    <Flex.Item key={item.code}><Tag className="flGrey" selected={this.state.city_code === item.code} style={{ whiteSpace: isBrowser ? 'nowrap' : 'normal' }} onChange={() => { this.addItemToFilter(item.code) }} disabled={this.state.selected_filter === 'monitoring'}>{item.name}</Tag></Flex.Item>
                                                )
                                            })}
                                        </Flex.Item>
                                    </Flex>

                                </WingBlank>
                                <div className="line"></div>
                                <WingBlank size="lg">
                                    <Flex className="menu-title">
                                        <Flex.Item>Specialty</Flex.Item>
                                    </Flex>
                                </WingBlank>
                                <div className="line"></div>
                                <WingBlank size="lg">{specialtySpan}</WingBlank>

                            </div>
                            <div className="line"></div>
                            {/* <div className="search-btn-box">
                                <WingBlank size="lg">
                                    <WhiteSpace size="lg" />
                                    <Button className="search-btn fill-pink-btn" onClick={this.submitSearch}>Search</Button>
                                    <WhiteSpace size="lg" />
                                </WingBlank>
                            </div> */}
                        </div>
                    </div>
                    <div className={`${this.state.open ? "doctor-list-box" : ''}`}>
                        <Flex style={{ paddingLeft: '1.5em', }} className="doctor-list">
                            <div className="total_res" style={{ paddingBottom: '1em' }}>
                                {this.state.totalDocList > 0 ? this.state.totalDocList + ' Result(s) found' : 'No Result found'}
                            </div>
                        </Flex>
                        <Flex className="doctor-list">
                            <Flex.Item>
                                <WingBlank size="lg">
                                    <Flex>
                                        {getDoctorList.length > 0 ? getDoctorList : ''}
                                        {getProgramList.length > 0 ? getProgramList : ''}
                                    </Flex>
                                </WingBlank>
                                {this.state.doctorList.length > 0 && this.state.doctorList.length >= this.state.totalDocList ?
                                    <WingBlank size="lg" style={{ textAlign: 'center', paddingBottom: '15px', }}>
                                        <span className="fbGrey fb" style={{ color: '#9b9b9b' }}>No more results.</span>
                                    </WingBlank>
                                    : ''}
                            </Flex.Item>
                        </Flex>
                    </div>
                </div>
                <Modal
                    className="program-tc-modal"
                    visible={this.state.program_open}
                    transparent
                    onClose={()=>{this.setState({ program_open: false, program_request_id: "", program_file: {}, })}}
                    wrapProps={{ onTouchStart: this.onWrapTouchStart }}
                    >
                    <div style={{padding: '20px'}}>
                        <div style={{fontSize: 22, color: 'black',fontWeight: 'bolder'}}>Terms & Conditions</div>
                        <WhiteSpace />
                        <div>To view the full document, please tap <span style={{color: '#c5427e',textDecoration: 'underline'}} onClick={()=>{viewFile(this.state.program_file,"native_open_web")}}>here</span>.</div>
                        <WhiteSpace />
                        <div className="footerBtns">
                            <Button
                                className="outline-pink-btn"
                                style={{
                                fontFamily: "Montserrat-Regular",
                                fontSize: 17,
                                fontWeight: 600,
                                width: '45%'
                                }}
                                onClick={()=>{this.setState({program_open: false, program_request_id: "", program_file: {},})}}
                            >
                                Go Back
                            </Button>
                            <Button
                                style={{
                                    background:
                                    "linear-gradient(135deg, #E02381 0%, #C23579 100%) ",
                                    color: "#fff",
                                    fontFamily: "Montserrat-Regular",
                                    fontSize: 17,
                                    fontWeight: 600,
                                    width: '45%'
                                }}
                                onClick={() => this.requestCode(this.state.program_request_id)}
                                >
                                I Agree
                            </Button>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}


export default DoctorList;