import { StyleSheet } from '@react-pdf/renderer';
// Create styles
export default StyleSheet.create({
    root: {
        width: "100%",
        height: '100vh'
    },
    page: {
        flexDirection: "column",
        backgroundColor: "#E4E4E4",
        fontSize: 10,
    },
    wrap: {
        margin: 30,        
    },
    title: {
        textAlign: 'center',
        fontSize: 10,
        fontWeight:500
    },
    redF:{
        color:'red'
    },
    section: {        
        
        // margin: 10,
        // padding: 10,
        // flexGrow: 1,
    },
    borderLeft:{
        borderLeft: '1px solid #525253',
    },
    borderRight: {
        borderRight: '1px solid #525253'
    },
    borderTop: {
        borderTop: '1px solid #525253'
    },
    borderBottom: {
        borderBottom: '1px solid #525253'
    },  
    border:{
        border: '1px solid #525253'
    },  
    row1:{
        flexDirection:'row',
        padding:2,
        backgroundColor:'#DCE3F5',
        justifyContent:'space-between'
    },
    row1Right: {
        flexDirection: 'row'
    },
    row2:{
        flexDirection: 'row',
        // height:40,
    },
    row3:{
        flexDirection: 'row',
    },
    textBox:{
        marginTop:2,
        height: 30,
        width:17,
        flexDirection: 'row',
        alignItems:'center',
        borderTop: '1px solid #525253',
        borderBottom: '1px solid #525253',
        justifyContent:'center'
    },
    textBolder:{
        fontWeight:600
    },
    textBox2:{
        marginTop: 2,
        height: 20,
        width: 15,
        flexDirection: 'row',
        alignItems: 'center',
        borderTop: '1px solid #525253',
        borderBottom: '1px solid #525253',
        justifyContent: 'center'
    },
    flex1:{
        flex:1
    },
    paddingHorizontal2:{
        paddingHorizontal:2
    },
    paddingVertical2: {
        paddingVertical: 2
    },
    paddingTop2: {
        paddingTop: 2
    },
    paddingBottom2: {
        paddingBottom: 2
    },
    padding:{
        padding: 2
    },
    flexRow:{
        flexDirection: 'row'
    },
    justifyContentCenter: {
        justifyContent: 'space-between'
    },
    alignContentEnd: {
        alignItems:'flex-end'
    },
    pTitle:{
        fontSize: 11,
    },
    checkbox:{
        height:10,
        width:10,
        marginRight:2,
        border:'1px solid #525253'
    }
});