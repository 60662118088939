import React, { Component } from 'react';
import { Flex, WhiteSpace, WingBlank, TabBar, Icon, Grid, Toast, Modal, ListView, Button } from 'antd-mobile';
import HVLogo from '../images/icons/IconHeartVoiceLong.svg'
import { isAndroid, isIOS, isMacOs, isWindows, fullBrowserVersion, isSafari } from "react-device-detect";
import compareVersions from 'compare-versions';
import ChromeLogo from '../images/browser/chrome.svg';
import FireFoxLogo from '../images/browser/firefox.png';
import EdgeLogo from '../images/browser/edge.jpeg';
import SafariLogo from '../images/browser/safari.jpeg';
import Video from 'twilio-video';

class PlatformCheck extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = '#FFF';
    }

    goTochrome = () => {
        window.location.href = 'googlechrome://';
    }

    goToAafari = () => {
        window.location.href = 'safari://';
    }

    render() {
        let chrome = { name: 'Chrome', icon: ChromeLogo, link: 'https://www.google.com/chrome' }
        let firefox = { name: 'Firefox', icon: FireFoxLogo, link: 'https://www.mozilla.org/en-US/firefox/' }
        let safari = { name: 'Safari', icon: SafariLogo, link: 'https://www.apple.com/hk/en/safari/' }
        let edge = { name: 'Edge', icon: EdgeLogo, link: 'https://www.microsoft.com/en-us/edge' }
        let supported = {
            android: [chrome, firefox],
            ios: [safari],
            linux: [chrome, firefox],
            macos: [chrome, firefox, safari, edge],
            windows: [chrome, firefox, edge],
        }

        let supports = supported.linux;
        if (isWindows) {
            supports = supported.windows
        } else if (isMacOs) {
            supports = supported.macos
        } else if (isIOS) {
            supports = supported.ios
        } else if (isAndroid) {
            supports = supported.android
        }
        if ((isSafari && compareVersions(fullBrowserVersion, '12.1') === -1)) {
            return (
                <div className="twilio-unsupported">
                    <div>
                        <p>
                            Sorry, your browser version {fullBrowserVersion} is incompatible with our service.
                        </p>
                        <p>
                            Please open in Safari 12.1 or greater.
                        </p>
                    </div>
                </div>
            )
        }
        return (
            <div className="twilio-unsupported">
                <div>
                    <p>
                        Sorry, your browser is incompatible with our service.
                        </p>
                    <p>
                        Please open in the following browsers:
                        </p>
                    <div className="browsers">
                        {
                            supports.map(i => <div>
                                <div>
                                    <img src={i.icon} onClick={() => window.location.href = i.link} />
                                    <span>{i.name}</span>
                                </div>
                                <span onClick={() => window.location.href = i.link}>Download {i.name}</span>
                            </div>)
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default PlatformCheck;