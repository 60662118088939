import React, { Component, Fragment } from "react";
import {
  Button,
  Modal,
  Tag,
  Toast,
  WhiteSpace,
  Flex,
  Picker,
} from "antd-mobile";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  getSlotTimes,
  getSWeek,
  showDate,
  showGlobalDate,
  isIntersect,
  isSame,
} from "../commons/Helpers";
import iconLeft from "../images/icons/icon-left.png";
import iconRight from "../images/icons/icon-right.png";
import AuthGuard from "../services/AuthGuard";
import moment from "moment";
import { WeekEveryDay, WHATSAPP_MOBILENO } from "../commons/Constants";
import _ from "lodash";
import {
  DropdownIndicator,
  selectStylesForDownList2,
} from "../page/SignUpCompleteInfo";
import Select, { components } from "react-select";
import { isBrowser, isIOS, isAndroid } from "react-device-detect";
import enUs from "antd-mobile/lib/date-picker/locale/en_US";
import CustomChildren from "../commons/CustomChildrenDocList";
import "react-datepicker/dist/react-datepicker.css";

function closest(el, selector) {
  const matchesSelector =
    el.matches ||
    el.webkitMatchesSelector ||
    el.mozMatchesSelector ||
    el.msMatchesSelector;
  while (el) {
    if (matchesSelector.call(el, selector)) {
      return el;
    }
    el = el.parentElement;
  }
  return null;
}

class NextIcon extends Component {
  render() {
    return (
      <div className="nextIcon">
        <img src={iconRight} style={{ width: "0.3rem" }} alt="" />
      </div>
    );
  }
}

class PreviousIcon extends Component {
  render() {
    return (
      <div className="previousIcon">
        <img src={iconLeft} style={{ width: "0.3rem" }} alt="" />
      </div>
    );
  }
}

class ScheduleCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarModal: false,
      dateValue: new Date(),
      weekDayKey: Number(moment().day()),
      timeslotDays: [],
      booked_timeslot: [],
      unavaliable_day: [],
      exception_slots: [],
      dayEveryTime: [],
      rescheduleDate: moment().startOf("day").toString(),
      selected_time: "",
      clinic_id: "",
      doctor_id: "",
      msgSuccess: "Submit appointment success!",
      scheduleSuccess: false,
      scheduleChannel: "whatsapp",
      confirmBtnLoading: false,
      location_type: null,
      hasSlotsDate: [],
      offsiteWeekList: [],
      start_date: "",
      end_date: "",
      purposeModal: false,
      type: "",
      purpose: [],
      purpose_id: "",
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  onWrapTouchStart = (e) => {
    // fix touch to scroll background page on iOS
    if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
      return;
    }
    const pNode = closest(e.target, ".am-modal-content");
    if (!pNode) {
      e.preventDefault();
    }
  };

  openScModel = (params) => {
    console.log("params===>", params);
    if (params.health_screening_id) {
      this.getHSDate(params);
    } else {
      this.getAvaliableDate(params);
    }
    this.setState({
      ...params,
    });
  };

  onConfirm = (key) => () => {
    let { selected_time, dateValue, health_screening_id } = this.state;
    if (health_screening_id) {
      if (selected_time) {
        let _hs_time = showGlobalDate(dateValue) + "," + selected_time;
        this.props.onSetHSTime(_hs_time);
      }
      this.setState({
        calendarModal: false,
      });
    } else {
      if (selected_time && dateValue) {
        this.setState({
          confirmBtnLoading: true,
        });

        if (navigator.userAgent.indexOf("heartvoice") !== -1) {
          this.doAppointmentSchedule.call(this, "app");
        } else if (
          navigator.userAgent.indexOf("1docAndroid") !== -1 ||
          navigator.userAgent.indexOf("1docIos") !== -1
        ) {
          this.doAppointmentSchedule.call(this, "1docApp");
        } else {
          this.doAppointmentSchedule.call(this, "whatsapp");
        }
      } else {
        Toast.fail(
          "Oops! Invalid Date and Time slots ,Please select again!!!",
          3
        );
      }
    }
  };

  doAppointmentSchedule(channel) {
    let { selected_time, dateValue, doctor_id, type, purpose_id } = this.state;
    this.loadingToast();
    AuthGuard.appointmentSchedule(
      {
        doctor_id: doctor_id,
        date: showDate(dateValue, 2),
        time: selected_time,
        channel: channel,
        type,
        purpose_id: type == "visit" ? purpose_id : "",
      },
      (isOk, msg) => {
        this.setState({
          confirmBtnLoading: false,
        });
        if (isOk) {
          this.setState(
            {
              calendarModal: false,
              msgSuccess: msg,
              // scheduleSuccess: true,
              scheduleChannel: channel,
            },
            this.onCloseScheduleModel.bind(this)
          );
        } else {
          Modal.alert("Fail", <div>{msg}</div>, [
            { text: "OK", onPress: () => console.log("") },
          ]);
        }
        Toast.hide();
      }
    );
  }

  onClose = (key) => () => {
    console.log(key);
    this.setState({
      [key]: false,
    });
  };

  onCloseScheduleModel = () => {
    this.setState({
      scheduleSuccess: false,
    });

    if (isBrowser) {
      Modal.alert(
        "Success",
        <div>
          {this.state.type == "visit"
            ? "Your appointment has been successfully made."
            : "Please check your whatsapp for updates!"}
        </div>,
        [{ text: "OK", onPress: () => console.log("第0个按钮被点击了") }]
      );
    } else {
      if (this.state.scheduleChannel === "app") {
        window.location.href = "heartvoice://heartvoice/home?action=home";
      }
      if (this.state.scheduleChannel === "1docApp") {
        isIOS && window.webkit.messageHandlers.back_to_app_home.postMessage("");
        if (isAndroid) {
          window.location.href = "heartvoice://heartvoice/home?action=goback";
          window.JsToAndroid.back_to_app_home();
        }
      } else {
        Modal.alert(
          "Success",
          <div>
            {this.state.type == "visit"
              ? "Your appointment has been successfully made."
              : "Please check your whatsapp for updates!"}
          </div>,
          [
            {
              text: "OK",
              onPress: () => {
                if (this.state.type != "visit") {
                  window.location.href = "https://wa.me/" + WHATSAPP_MOBILENO;
                }
              },
            },
          ]
        );
      }
    }
  };

  openRequestSuccess = () => {
    this.setState({
      scheduleSuccess: false,
    });
    window.location.href = "heartvoice://heartvoice/home?action=home";
  };

  openJoinProgramsSuccess = (name) => {
    this.setState({
      scheduleSuccess: false,
    });
    let _dr_name =
      "You have joined " +
      name +
      "'s monitoring program! Start tracking your vitals.";
    window.location.href =
      "heartvoice://heartvoice/home?action=popup&title=Congratulations!&desc=" +
      encodeURI(_dr_name);
  };

  onChange = (date) => {
    console.log(date);
    let _weekDayKey = WeekEveryDay.findIndex((item, index) => {
      return showDate(date, 4).toUpperCase() === item;
    });
    this.setState(
      {
        rescheduleDate: date,
        weekDayKey: parseInt(_weekDayKey) + 1,
        selected_time: "",
      },
      () => {
        if (this.state.location_type === "onsite") {
          this.onsiteDayEveryTime(this.getFistSelectedDate(date));
        } else {
          this.reCreatDayEveryTime(this.getFistSelectedDate(date));
        }
      }
    );
  };

  onClickDay = (val, e) => {
    this.setState({
      dateValue: val,
    });
  };

  loadingToast() {
    Toast.loading("Loading...", 30, () => { });
  }

  getAvaliableDate = (params) => {
    const { type, ..._params } = params || {};
    this.loadingToast();
    AuthGuard.getAvaliableDate(
      {
        ..._params,
        booking_type: type || "",
      },
      (isOk, res) => {
        if (isOk) {
          let slotDays = getSlotTimes(
            res.timeslot.slots,
            res.timeslot.duration,
            res.timeslot.lead_time
          );
          if (slotDays.length == 0) {
            Modal.alert("Oops", <div>No time slots available! </div>, [
              { text: "OK", onPress: () => console.log("") },
            ]);

            Toast.hide();

            return false;
          }
          let pdata = [];
          if (res?.purpose?.length) {
            res.purpose.map((ite) => {
              pdata.push({
                label: ite.name,
                value: ite.id,
                description: ite.description,
              });
            });
          }
          this.setState(
            {
              timeslotDays: slotDays,
              booked_timeslot: res.booked_timeslot,
              unavaliable_day: res.unavaliable_day,
              exception_slots: res.exception_slots,
              start_date: res.start_date ?? "",
              end_date: res.end_date ?? "",
              dateValue: new Date(moment(res.start_date).format()),
              rescheduleDate: new Date(moment(res.start_date).format()),
              purpose: pdata,
              purpose_id: pdata.length ? pdata[0].value : "",
            },
            () => {
              this.reCreatDayEveryTime(
                moment(this.getFistSelectedDate(this.state.start_date)).format()
              );
            }
          );
        } else {
          Modal.alert("Oops", <div>{res.message}</div>, [
            { text: "Okay", onPress: () => console.log("第0个按钮被点击了") },
          ]);
        }
        Toast.hide();
      }
    );
  };
  handleConfirmVisit = () => {
    if (this.state.purpose_id) {
      console.log(this);
      this.setState({
        purposeModal: false,
      });
    }
  };
  getTimetoMM = (params) => {
    let arr = params.split(":");
    return arr[0] * 3600000 + arr[1] * 60000;
  };
  getHSDate = (params) => {
    this.loadingToast();
    const { location_type } = params;
    AuthGuard.getHSDate(params, (isOk, res) => {
      if (isOk) {
        let slotDays = getSlotTimes(
          res.timeslot.slots,
          res.timeslot.duration,
          res.timeslot.lead_time
        );
        let dateValue = this.getFistSelectedDate(new Date(res.start_date));
        // let currentDateTimeFormat = moment(showDate(dateValue, 11)).format()
        // console.log('currentDateTimeFormat', currentDateTimeFormat)
        this.setState(
          {
            timeslotDays: slotDays,
            booked_timeslot: res.booked_timeslot,
            unavaliable_day: res.unavaliable_day,
            exception_slots: res.exception_slots,
            start_date: res.start_date ?? "",
            end_date: res.end_date ?? "",
            rescheduleDate: showDate(dateValue, 2),
            dateValue,
          },
          () => {
            if (location_type === "onsite") {
              this.onsiteDayEveryTime(
                moment(this.getFistSelectedDate(this.state.start_date)).format()
              );
            } else {
              this.reCreatDayEveryTime(
                moment(this.getFistSelectedDate(this.state.start_date)).format()
              );
            }
            if (res.start_date) {
              this.onChange(moment(this.state.rescheduleDate).format());
            }
            // console.log(moment(showDate(dateValue, 11)).format())

            // console.log(res.timeslot.slots);
            // console.log(slotDays);
          }
        );
      } else {
        // console.log(res);
        Modal.alert("Notice", <div>{res.message}</div>, [
          { text: "OK", onPress: () => console.log("") },
        ]);
      }
      Toast.hide();
    });
  };

  onsiteDayEveryTime = (day) => {
    const { timeslotDays, booked_timeslot, rescheduleDate } = this.state;
    if (!timeslotDays.length) return false;
    let dayEveryTime = timeslotDays.find((item, index) => {
      return item.weekday === showDate(rescheduleDate, 2);
    });

    let unTimeslot_day = !booked_timeslot.length
      ? ""
      : booked_timeslot.filter((item, index) => {
        return item.date === showDate(rescheduleDate, 2);
      });
    // 转时间格式↑

    this.handleSorts(dayEveryTime, unTimeslot_day, day);
  };

  reCreatDayEveryTime = (day) => {
    let _weekDayKey =
      Number(moment(day).day()) === 0 ? 7 : Number(moment(day).day());
    if (this.state.timeslotDays.length === 0) return false;
    let dayEveryTime =
      this.state.timeslotDays.length > 0
        ? this.state.timeslotDays.find((item, index) => {
          return item.weekday === _weekDayKey;
        })
        : "";

    let unTimeslot_day =
      this.state.booked_timeslot && this.state.booked_timeslot.length > 0
        ? this.state.booked_timeslot.filter((item, index) => {
          let _rescheduleDate = showDate(this.state.rescheduleDate, 2);
          return item.date === _rescheduleDate;
        })
        : "";
    this.handleSorts(dayEveryTime, unTimeslot_day, day);
  };

  handleSorts = (dayEveryTime, unTimeslot_day, day) => {
    const { exception_slots } = this.state;
    let avaliableTime = [];
    let _time = showDate(day, 1); //当前时间
    let _day = showDate(day, 2); //当前日期
    // rescheduleDate 选择的日期
    // let disableAll = moment(this.state.rescheduleDate).isBefore(_day);
    let enableAll = moment(showDate(this.state.rescheduleDate, 2)).isAfter(
      _day
    );
    if (dayEveryTime && dayEveryTime.slorts) {
      dayEveryTime.slorts.map((item, index) => {
        let obj = {
          slot: _.padStart(item.slot, 5, "0"),
          duration: item.duration,
          lead_time: item.lead_time,
          disabled: false,
        };
        if (
          moment(new Date()).valueOf() + item.lead_time >
          moment(this.state.rescheduleDate).valueOf() +
          this.getTimetoMM(item.slot)
        ) {
          obj.disabled = true;
        } else if (enableAll) {
          let haveTime =
            unTimeslot_day &&
            unTimeslot_day.find((iitem, iindex) => {
              return iitem.time == item.slot;
            });
          obj.disabled = !!haveTime;
        } else {
          _time = _time.replace(":", "");
          let _time2 = item.slot.replace(":", "");
          if (parseInt(_time2) <= parseInt(_time)) {
            obj.disabled = true;
          } else {
            let haveTime =
              unTimeslot_day &&
              unTimeslot_day.find((iitem, iindex) => {
                return iitem.time == item.slot;
              });
            obj.disabled = !!haveTime;
          }
        }
        this.state.booked_timeslot.map((ite) => {
          if (
            moment(ite.date).valueOf() ==
            moment(this.state.rescheduleDate).valueOf()
          ) {
            if (
              isIntersect(
                [this.getTimetoMM(ite.time), this.getTimetoMM(ite.time_to)],
                [
                  this.getTimetoMM(item.slot),
                  this.getTimetoMM(item.slot) + obj.duration * 60000,
                ]
              )
            ) {
              obj.disabled = true;
            }
          }
        });
        this.state.exception_slots.map((exite) => {
          if (exite.day == showDate(this.state.rescheduleDate, 2)) {
            exite.data.map((exites) => {
              if (
                isIntersect(
                  [
                    this.getTimetoMM(exites.start),
                    this.getTimetoMM(exites.end),
                  ],
                  [
                    this.getTimetoMM(obj.slot),
                    this.getTimetoMM(obj.slot) + obj.duration * 60000,
                  ]
                )
              ) {
                obj.disabled = true;
              }
            });
          }
        });
        !obj.disabled && avaliableTime.push(obj);
      });
    }
    let arr = [];
    let offsiteAllWeekDay = [];
    this.state.timeslotDays.map((ite) => {
      let canChoseTimeNum = 0;
      offsiteAllWeekDay.push(ite.weekday);
      ite.slorts.map((item) => {
        if (
          moment(showDate(new Date(), 2)).valueOf() + item.lead_time <
          moment(ite.weekday).valueOf() + this.getTimetoMM(item.slot)
        ) {
          canChoseTimeNum += 1;
        }
      });
      if (canChoseTimeNum > 0) {
        arr.push(ite.weekday);
      }
    });
    if (
      this.state.unavaliable_day.filter(
        (x) => x.date == showDate(this.state.rescheduleDate, 2)
      ).length
    ) {
      avaliableTime = [];
    }
    this.setState(
      {
        dayEveryTime: avaliableTime,
        hasSlotsDate: arr,
        offsiteWeekList: offsiteAllWeekDay,
      },
      () => {
        this.setState({
          calendarModal: true,
          purposeModal: this.state.type == "visit",
        });
      }
    );
  };

  slotOnClick = (slot) => {
    this.setState({
      selected_time: this.state.selected_time === slot ? "" : slot,
    });
  };

  formatDate = (date, type) => {
    return getSWeek(date);
  };

  getFistSelectedDate = (start_date, end_date) => {
    if (start_date) {
      let currentDateTimeStamp = new Date().getTime();
      let startDateTimeStamp = moment(start_date).valueOf();
      //如果开始日期比今天还要晚,用开始日期，否则用今天日期
      let tempTimeStamp =
        startDateTimeStamp > currentDateTimeStamp
          ? startDateTimeStamp
          : currentDateTimeStamp;
      return showDate(tempTimeStamp, 10);
    }

    return this.state.dateValue;
  };

  canSelectDate = (date) => {
    const { start_date, end_date } = this.state;
    if (start_date && end_date) {
      let calendarDateTimeStamp = new Date(date).getTime();
      let currentDateTimeStamp = new Date().getTime();
      let startDateTimeStamp = new Date(showDate(start_date, 9)).getTime();
      let endDateTimeStamp = new Date(end_date).getTime();
      //如果开始日期比今天还要晚,用开始日期，否则用今天日期
      let tempTimeStamp =
        startDateTimeStamp > currentDateTimeStamp
          ? startDateTimeStamp
          : currentDateTimeStamp;
      if (
        (calendarDateTimeStamp >= tempTimeStamp &&
          calendarDateTimeStamp < endDateTimeStamp) ||
        isSame(calendarDateTimeStamp, tempTimeStamp)
      ) {
        return "canCheck";
      } else {
        return null;
      }
    }

    if (
      new Date(date).getTime() > new Date().getTime() ||
      isSame(new Date(date), new Date())
    ) {
      return "canCheck";
    }
    return null;
  };

  render() {
    return (
      <div className="schedule-box">
        <Modal
          className="schedule-modal"
          visible={this.state.calendarModal}
          transparent
          onClose={this.onClose("calendarModal")}
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
        >
          <div className="schedule-con">
            <div className="calendarCon">
              <Calendar
                locale="en-US"
                nextLabel={<NextIcon />}
                prevLabel={<PreviousIcon />}
                prev2Label=""
                next2Label=""
                className="calendar"
                formatShortWeekday={(locale, date) =>
                  this.formatDate(date, "dd")
                }
                tileClassName={({ activeStartDate, date, view }) => {
                  // return this.canSelectDate(date)
                  if (
                    this.state.location_type == "offsite" ||
                    this.state.location_type == "master" ||
                    !this.state.location_type
                  ) {
                    let canChose = true;
                    if (
                      !this.state.offsiteWeekList.includes(
                        date.getDay() ? date.getDay() : 7
                      ) ||
                      this.state.unavaliable_day.filter(
                        (x) => x == showDate(date, 2)
                      ).length ||
                      moment(date).valueOf() <
                      moment(this.state.start_date).valueOf() ||
                      (this.state.end_date &&
                        moment(date).valueOf() >
                        moment(this.state.end_date).valueOf())
                    ) {
                      // if (!this.state.offsiteWeekList.includes(date.getDay() ? date.getDay() : 7)) {
                      canChose = false;
                    } else {
                      let canUseSlotNum = 0;
                      this.state.timeslotDays.map((ite) => {
                        ite.slorts.map((item) => {
                          if (
                            (date.getDay() ? date.getDay() : 7) == ite.weekday
                          ) {
                            if (
                              moment().valueOf() + item.lead_time <
                              moment(date).valueOf() +
                              this.getTimetoMM(item.slot)
                            ) {
                              canUseSlotNum += 1;
                            }
                          }
                        });
                      });
                      if (canUseSlotNum < 1) {
                        canChose = false;
                      }
                    }
                    if (canChose) {
                      return "canCheck";
                    }
                  }
                  if (
                    this.state.hasSlotsDate.includes(showDate(date, 2)) ||
                    !this.state.unavaliable_day.filter(
                      (x) => x == showDate(date, 2)
                    ).length
                  ) {
                    // if (this.state.hasSlotsDate.includes(showDate(date, 2))) {
                    return "canCheck";
                  }
                  return null;
                }}
                tileDisabled={({ activeStartDate, date, view }) => {
                  // return !this.canSelectDate(date)
                  if (
                    this.state.location_type == "offsite" ||
                    this.state.location_type == "master" ||
                    !this.state.location_type
                  ) {
                    let canChose = true;
                    if (
                      !this.state.offsiteWeekList.includes(
                        date.getDay() ? date.getDay() : 7
                      ) ||
                      this.state.unavaliable_day.filter(
                        (x) => x == showDate(date, 2)
                      ).length ||
                      moment(date).valueOf() <
                      moment(this.state.start_date).valueOf() ||
                      (this.state.end_date &&
                        moment(date).valueOf() >
                        moment(this.state.end_date).valueOf())
                    ) {
                      // if (!this.state.offsiteWeekList.includes(date.getDay() ? date.getDay() : 7)) {
                      canChose = false;
                    } else {
                      let canUseSlotNum = 0;
                      this.state.timeslotDays.map((ite) => {
                        ite.slorts.map((item) => {
                          if (
                            (date.getDay() ? date.getDay() : 7) == ite.weekday
                          ) {
                            if (
                              moment().valueOf() + item.lead_time <
                              moment(date).valueOf() +
                              this.getTimetoMM(item.slot)
                            ) {
                              canUseSlotNum += 1;
                            }
                          }
                        });
                      });
                      if (canUseSlotNum < 1) {
                        canChose = false;
                      }
                    }
                    return !canChose;
                  } else {
                    return (
                      !this.state.hasSlotsDate.includes(showDate(date, 2)) ||
                      this.state.unavaliable_day.filter(
                        (x) => x == showDate(date, 2)
                      ).length
                    );
                    // return !this.state.hasSlotsDate.includes(showDate(date, 2))
                  }
                }}
                navigationLabel={({ date, label, locale, view }) => {
                  let arr = label.split(" ");
                  return (
                    <div className="calendarTitle">
                      <div className="titleLeft">{arr[0]}</div>
                      <div className="titleRight">{arr[1]}</div>
                    </div>
                  );
                }}
                onChange={this.onChange}
                onClickDay={this.onClickDay}
                value={this.state.dateValue}
              />
            </div>
            <div className="timeSlots">
              {this.state.dayEveryTime.length > 0 &&
                (!this.state.end_date ||
                  (this.state.end_date &&
                    showDate(this.state.start_date, 12) <=
                    showDate(this.state.end_date, 12)))
                ? this.state.dayEveryTime.map((item, index) => {
                  let slot = item.slot;
                  if (!item.disabled) {
                    return (
                      <Tag
                        key={index}
                        selected={this.state.selected_time === slot}
                        onChange={() => this.slotOnClick(slot)}
                      >
                        {slot}
                      </Tag>
                    );
                  }
                })
                : "There are no appointment slots for this date. Please choose another date!"}
              <div className="dayeveryAfter"></div>
              <div className="dayeveryAfter"></div>
              <div className="dayeveryAfter"></div>
            </div>
            <WhiteSpace />
            <div className="footerBtns">
              <WhiteSpace />
              {this.state.selected_time && (
                <Button
                  // className="fill-blue-btn"
                  style={{
                    background:
                      "linear-gradient(135deg, #E02381 0%, #C23579 100%) ",
                    color: "#fff",
                    fontFamily: "Montserrat-Regular",
                    fontSize: 17,
                    fontWeight: 600,
                  }}
                  loading={this.state.confirmBtnLoading}
                  disabled={this.state.confirmBtnLoading}
                  onClick={this.onConfirm.bind(this)("calendarModal")}
                >
                  Request
                </Button>
              )}
              <WhiteSpace />
              <Button
                className="outline-blue-btn"
                style={{
                  fontFamily: "Montserrat-Regular",
                  fontSize: 17,
                  fontWeight: 600,
                }}
                onClick={this.onClose("calendarModal")}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          className="purpose-modal"
          visible={this.state.purposeModal}
          transparent
          onClose={this.onClose("purposeModal")}
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
          maskClosable={false}
          style={{ border: "2px solid #00838A", borderRadius: 8 }}
        >
          <div className="purpose-title">
            Please select the purpose of your visit:
          </div>
          <Flex>
            <Flex.Item>
              {isBrowser ? (
                <Select
                  components={{ DropdownIndicator }}
                  className="purpose_select"
                  value={
                    this.state.purpose.find(
                      (x) => x.value == this.state.purpose_id
                    ) || null
                  }
                  onChange={(e) => {
                    this.setState({
                      purpose_id: e.value,
                    });
                  }}
                  onMenuOpen={() => {
                    setTimeout(() => {
                      var a = document.querySelector("#root");
                      a.children[1].style.zIndex = 9999;
                    }, 1);
                  }}
                  styles={selectStylesForDownList2}
                  menuPortalTarget={document.querySelector("#root")}
                  isSearchable={false}
                  options={this.state.purpose}
                  placeholder={"please select"}
                />
              ) : (
                <div className="purpose_picker">
                  <Picker
                    clear
                    data={this.state.purpose}
                    locale={enUs}
                    value={[this.state.purpose_id]}
                    style={{ fontFamily: "Montserrat-Regular" }}
                    placeholder="please select"
                    itemStyle={{
                      WhiteSpace: "normal",
                    }}
                    cols={1}
                    onChange={(v) => {
                      this.setState({
                        purpose_id: v[0],
                      });
                    }}
                  >
                    <CustomChildren
                      sClas={this.state.identification_type ? "sel" : "noSel"}
                    ></CustomChildren>
                  </Picker>
                </div>
              )}
            </Flex.Item>
          </Flex>
          <div className="purpose-content" style={{ wordBreak: "break-all" }}>
            {this.state.purpose_id &&
              this.state.purpose.find((x) => x.value == this.state.purpose_id)
                .description}
          </div>
          <Button
            className="fill-blue-btn"
            onClick={this.handleConfirmVisit.bind(this)}
          >
            Confirm
          </Button>
        </Modal>
        <Modal
          className="appointment-modal"
          visible={this.state.scheduleSuccess}
          transparent
          maskClosable={false}
          footer={[
            { text: "Okay", onPress: () => this.onCloseScheduleModel() },
          ]}
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
        >
          <div>
            {/* {this.state.msgSuccess} */}
            <span>Appointment Requested!</span>
            <br />
            <span>
              Let me check if that’s available. You will receive a whatsapp
              message when the doctor confirms your appointment request.
            </span>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ScheduleCalendar;
