import React, { Component } from 'react';
import { Flex, WhiteSpace, WingBlank,InputItem,NavBar ,Icon, Button,} from 'antd-mobile';
import AuthGuard from '../services/AuthGuard';
import { getCache, removeCache ,setCache} from '../commons/Helpers';

class ReferralCode extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            referral: '',
            user_profile_id: '',
            loadding: false,
            error:'',
        }
    }

    goBack = ()=>{
        this.props.history.push('/login/')
    }

    componentDidMount() {
        if(getCache('referral_id')){
            let {id} = JSON.parse(getCache('referral_id'));
            if(id){
                this.setState({
                    user_profile_id: id,
                })
            }else{
                console.log('无效id')
            }
        }
    }

    confirm = ()=>{
        this.setState({
            error: '',
        })
        let _referral = this.state.referral;
        if(_referral === ''){
            this.setState({
                error: 'Invalid referral code. Please try again.',
            })
        }else{
            this.setState({
                loadding: true,
            })
            AuthGuard.addReferral({
                promo_code: _referral,
                user_profile_id: this.state.user_profile_id,
            }, (isOk, res) => {
                this.setState({
                    loadding: false,
                })
                if(isOk){
                    this.skip()
                }else{
                    this.setState({
                        error: res.message,
                    })
                }
            })
        }
    }

    skip = ()=>{
        removeCache('referral_id');
        if (getCache('redirectUrl')) {
            let url = getCache('redirectUrl')
            url = url.replace('#', '')
            this.props.history.push(url)
        } else {
            this.props.history.push("/submitResult");
        }
    }

    render() {
        return (
            <div className="Register-Page-V2 h5-Page login-Page">
                <NavBar
                    mode="light"
                    icon={<Icon type="left" color="#EB5DA6" size="lg"/>}
                    onLeftClick={this.goBack}
                ></NavBar>
                <WingBlank>
                    <div className="content">
                        <WhiteSpace size="lg" />
                        <Flex>
                            <Flex.Item>
                                <p className="sign-up-title">Did someone tell you about HeartVoice?</p>
                            </Flex.Item>
                        </Flex>
                        <WhiteSpace size="lg" />
                        <WhiteSpace size="lg" />
                        <Flex>
                            <Flex.Item>
                                <div className="progress-bar-left-right"></div>
                                <div className="progress-bar"></div>
                                <div className="progress-bar"></div>
                                <div className="progress-bar-left-right" style={{backgroundColor:'#EB5DA6'}}></div>
                            </Flex.Item>
                        </Flex>
                        <WhiteSpace size="lg" />
                        <WhiteSpace size="lg" />

                        <div>Enter a referral code to enjoy discounts</div>
                        <WhiteSpace size="lg" />
                        <WhiteSpace size="lg" />

                        <div className="input_item">
                            <div className="input-title">{this.state.referral ? 'Enter Referral Code' : ''}</div>
                        <Flex>
                         
                            <Flex.Item>
                                <InputItem
                                clear
                                className="inputField"
                                style={{fontFamily:'Montserrat-Regular'}}
                                name="mobile_email"
                                placeholder="Enter Referral Code"
                                onChange={(v) => {
                                    this.setState({ referral: v });
                                }}
                                ></InputItem>
                            </Flex.Item>
                        </Flex>
                        </div>
                        {   this.state.error ? 
                            <Flex.Item className="errorMsg">
                                <WhiteSpace size="md" />
                                <p>{this.state.error}</p>
                            </Flex.Item> : ''
                        }
                        <WhiteSpace size="lg" />
                        <WhiteSpace size="lg" />
                        <Flex>
                            <Flex.Item>
                            <Button
                                className="fill-pink-btn"
                                style={{width:'100%'}}
                                activeClassName="fill-pink-active-btn"
                                loading={this.state.loadding}
                                disabled={this.state.loadding}
                                onClick={this.confirm.bind(this)}
                            >
                                Confirm
                            </Button>
                            </Flex.Item>
                        </Flex>
                        <WhiteSpace size="lg" />
                        <Flex>
                            <Flex.Item>
                            <Button
                                className="outline-pink-btn"
                                style={{width:'100%'}}
                                activeClassName="fill-pink-active-btn"
                                disabled={this.state.loadding}
                                onClick={this.skip.bind(this)}
                            >
                                Skip this step
                            </Button>
                            </Flex.Item>
                        </Flex>
                    </div>
                </WingBlank>
            </div>
        );
    };
}
export default ReferralCode;