import React, { Component } from "react";
import { Flex, WhiteSpace, WingBlank, Button } from "antd-mobile";
import HVLogo from "../images/icons/IconHeartVoiceLong.svg";
import IconFail from "../images/icons/IconPaymentFail.svg";
import { getCache } from "../commons/Helpers";
import { WHATSAPP_MOBILENO } from "../commons/Constants";

class HSFail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backToChatBtnLoading: false,
      tryAgainBtnLoading: false,
    };
    this.isInIOS = navigator.userAgent.indexOf("1docIos") !== -1;
    this.isInAndroid = navigator.userAgent.indexOf("1docAndroid") !== -1;
  }

  goToWA = () => {
    if (this.isInIOS || this.isInAndroid) {
      this.isInIOS &&
        window.webkit.messageHandlers.back_to_app_home.postMessage("");
      this.isInAndroid && window.JsToAndroid.back_to_app_home();
    } else {
      window.location.href = "https://wa.me/" + WHATSAPP_MOBILENO;
    }
  };

  tryAgain = () => {
    if (getCache("hs_payInfoState")) {
      let { package_id } = JSON.parse(getCache("hs_payInfoState"));
      this.props.history.push({ pathname: "/programsPayment/" + package_id });
    } else if (getCache("hs_payInfo")) {
      let { packageId } = JSON.parse(getCache("hs_payInfo"));
      this.props.history.push({
        pathname: "/DoctorScreeningDetails/" + packageId,
      });
    } else {
      if (getCache("clinic_id")) {
        this.props.history.push(
          "/DoctorDetails/" + getCache("clinic_id") + "?tab=3"
        );
        return;
      }
      this.props.history.push("/404");
    }
  };

  render() {
    return (
      <div className="Paymet-Page result-success-fail-page">
        <Flex className="content">
          <Flex.Item>
            <WingBlank size="sm">
              <WhiteSpace size="md" />
              <Flex>
                <Flex.Item>
                  <img
                    src={HVLogo}
                    className="HVLogo"
                    alt="HeartVoice - Logo"
                  />
                </Flex.Item>
              </Flex>
              <WhiteSpace size="md" />
            </WingBlank>
            <WingBlank>
              <WhiteSpace size="md" />
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <Flex direction="column">
                <div className="result-title">Sorry</div>
                <WhiteSpace size="md" />
                <div className="result-desc">
                  Something seems to have gone wrong.
                </div>
                <div className="result-desc">Would you like to try again?</div>
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <img
                  src={IconFail}
                  className="icon-payment-fail"
                  alt="Result - Fail"
                />
              </Flex>
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <WingBlank size="lg">
                <WingBlank size="lg">
                  <WingBlank size="lg">
                    <WingBlank size="lg">
                      <WingBlank size="lg">
                        <Button
                          className="fill-pink-btn"
                          activeClassName="fill-pink-active-btn"
                          loading={this.state.tryAgainBtnLoading}
                          onClick={this.tryAgain}
                        >
                          Try Again
                        </Button>
                        <WhiteSpace size="lg" />
                        <Button
                          className="outline-pink-btn"
                          activeClassName="outline-pink-active-btn"
                          loading={this.state.backToChatBtnLoading}
                          onClick={this.goToWA}
                        >
                          {this.isInIOS || this.isInAndroid
                            ? "Back to Homepage"
                            : "Back to Chat"}
                        </Button>
                        <WhiteSpace size="lg" />
                        <WhiteSpace size="lg" />
                      </WingBlank>
                    </WingBlank>
                  </WingBlank>
                </WingBlank>
              </WingBlank>
            </WingBlank>
          </Flex.Item>
        </Flex>
      </div>
    );
  }
}

export default HSFail;
