import React, { useState, Component } from "react";
import enUs from "antd-mobile/lib/date-picker/locale/en_US";
import DatePicker from "../component/DatePicker";
import {
  LocaleProvider,
  Flex,
  WhiteSpace,
  Picker,
  WingBlank,
  InputItem,
  TextareaItem,
  Toast,
  Button,
  Modal,
  Checkbox,
} from "antd-mobile";
import myInfo from "../images/icons/myinfo.jpeg";
import AuthGuard from "../services/AuthGuard";
import CustomChildren from "../commons/CustomChildren";
import { TCLink, DIALING_CODE } from "../commons/Constants";
import GoogleSearchInput from "../component/GoogleSearchInput";
import { addKeyValueForDorpList, isInvalid, getCache, isPostalCode, removeCache, setCache, showGender } from "../commons/Helpers";
import { DropdownIndicator } from "./SignUpCompleteInfo";
import { isBrowser } from "react-device-detect";
import Select, { components } from 'react-select';

const AgreeItem = Checkbox.AgreeItem;
function formatDate(date) {
  /* eslint no-confusing-arrow: 0 */
  const dateString = date.toDateString().split(" ");
  var d = dateString[2];
  var m = dateString[1];
  var y = dateString[3];
  // const dateStr = `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()}`;
  const dateStr = `${d}/${m}/${y}`;
  // const timeStr = `${pad(date.getHours())}:${pad(date.getMinutes())}`;
  return `${dateStr}`;
}

const selectStylesForDownList = {
  control: (base) => ({
    ...base,
    border: 'unset',
    boxShadow: 'none',
    fontSize: '0.375rem',
  }),
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  indicatorsContainer: (base) => ({
    ...base,
    padding: 0,
    alignItems: 'flex-start',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: 0,
    alignItems: 'flex-start',
  }),
  menu: styles => ({
    ...styles,
    fontSize: '0.3rem'
  }),
  valueContainer: styles => ({
    ...styles,
    display: 'flex',
    margin: 0,
    padding: 0
  }),
}

class PerfectInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      genderList: [
        {
          label: "Male",
          value: "male",
        },
        {
          label: "Female",
          value: "female",
        },
        {
          label: "I do not wish to specify",
          value: "other",
        },
      ],
      identificationTypeList: [],
      identification_type: 'nric',
      identification: '',
      country_code: '',
      nationalityList: [],
      countryList: [],
      provinceList: [],
      countryListArr: [],
      isDatePickerOpen: false,
      gmapsLoaded: false,
      singPassCode: false,
      myInfoId: '',
      gender: "",
      drug_allergy: '',
      errorMsg: {},
    };
  }

  initMap = () => {
    this.setState({
      gmapsLoaded: true,
    });
  };

  componentDidMount() {
    window.initMap = this.initMap;
    const gmapScriptEl = document.createElement(`script`);
    gmapScriptEl.src =
      `https://maps.googleapis.com/maps/api/js?key=` +
      process.env.REACT_APP_GOOGLE_API_KEY +
      `&libraries=places&callback=initMap`;
    document
      .querySelector(`body`)
      .insertAdjacentElement(`beforeend`, gmapScriptEl);
    this.checkUserInfo()
    this.getIdentificationTypeList()
  }

  getIdentificationTypeList() {
    AuthGuard.getIdentificationTypeList({
      group: "identification_type"
    }, (isOk, res) => {
      let _identificationTypeList = []
      if (isOk) {
        res.map((item, index) => {
          _identificationTypeList.push({
            value: item.code,
            label: item.display_name,
          })
        })
        this.setState({
          identificationTypeList: _identificationTypeList
        })
      }
    })
  }

  checkUserInfo = async () => {
    let { token } = this.props.match.params;
    if (token) await AuthGuard.getNewToken(token)

    if (AuthGuard.checkLogin()) {
      try {
        let res = await this.getUserProfileData()
        this.setUserInfoData(res)
        this.getCountryList();
      } catch (error) {
        console.log(error)
      }
    }

    let singPassCode = getCache('state');
    if (!isInvalid(singPassCode) && singPassCode !== null && singPassCode) {
      this.setState({
        singPassCode: singPassCode
      })
      this.getSingPassInfo();
    } else {
      this.setState({
        singPassCode: ''
      })
      if (!AuthGuard.checkLogin()) {
        Modal.alert('Oops', 'You need a HeartVoice account to consult doctors.', [
          {
            text: 'Login', onPress: () => {
              AuthGuard.logout(() => { });
            }
          },
          {
            text: 'Sign up', onPress: () => {
              AuthGuard.signUp(() => { })
            }
          },
        ]);
      }
    }
  }

  getSingPassInfo = () => {
    let singPassCode = getCache('state');
    AuthGuard.getSingPassInfo({
      state: singPassCode
    }, (isOk, res) => {
      console.log(res.country_code)
      if (isOk) {
        this.setState({
          myInfoId: res.myInfoId,
          nric: res.nric,
          // _nric: res.nric ? true : false,
          postal_code: res.postal_code,
          // _postal_code: res.postal_code,
          country_code: res.country_code ? res.country_code : "",
          // _country_code: res.country_code,
          unit_no: res.unit_no,
          // _unit_no: res.unit_no,
          address: res.address,
          // _address: res.address,
          birth: new Date(res.birth) || new Date(1980, 0, 1),
          // _birth: res.birth ? true : false,
          gender: res.gender ? showGender(res.gender) : "",
          // _gender: res.gender ? true : false,
          nationality: res.nationality
            ? res.nationality === "SINGAPORE CITIZEN"
              ? "Singaporean"
              : res.nationality
            : "",
          // _nationality: res.nationality ? true : false,
          identification_type: "nric",
          // _identification_type: true,
        });
        if (res.country_code == 'SG') {
          this.setState({
            isDisplay: false,
          })
        }
      } else {
        this.setState({
          singpassModal: true
        })
        removeCache('state')
      }
      // Toast.hide()
    })
    // Toast.hide()
  }

  setUserInfoData = (res) => {
    let isSingPassUser = !isInvalid(res.singpassInfo)
    // if(isSingPassUser){
    //   this.setState({
    //     myInfoId: !isInvalid(res.singpassInfo.myInfoId) ? res.singpassInfo.myInfoId : "",
    //     address: !isInvalid(res.singpassInfo.address) ? res.singpassInfo.address : "",
    //     // _address: isSingPassUser && res.singpassInfo.address ? true : false,
    //     birth: !isInvalid(res.singpassInfo.birth) ? res.singpassInfo.birth : "",
    //     // _birth: isSingPassUser && res.singpassInfo.birth ? true : false,
    //     country_code: !isInvalid(res.singpassInfo.country_code) ? res.singpassInfo.country_code : "",
    //     // _country_code: isSingPassUser && res.singpassInfo.country_code ? true : false,
    //     country_name: !isInvalid(res.singpassInfo.country_name) ? res.singpassInfo.country_name : "",
    //     dialingCode: !isInvalid(res.singpassInfo.dialingCode) ? res.singpassInfo.dialingCode : "",
    //     email: !isInvalid(res.singpassInfo.email) ? res.singpassInfo.email : "",
    //     fullName: !isInvalid(res.singpassInfo.fullName) ? res.singpassInfo.fullName : "",
    //     gender: !isInvalid(res.singpassInfo.gender) ? showGender(res.singpassInfo.gender) : "",
    //     // _gender: isSingPassUser && res.singpassInfo.gender ? true : false,
    //     marital_status: !isInvalid(res.singpassInfo.marital_status) ? res.singpassInfo.marital_status : "",
    //     // _marital_status: isSingPassUser && res.singpassInfo.marital_status ? true : false,
    //     mobile: !isInvalid(res.singpassInfo.mobile) ? res.singpassInfo.mobile : "",
    //     nationality: !isInvalid(res.singpassInfo.nationality) ? res.singpassInfo.nationality : "",
    //     // _nationality: isSingPassUser && res.singpassInfo.nationality ? true : false,
    //     nric: !isInvalid(res.singpassInfo.nric) ? res.singpassInfo.nric : "",
    //     // _nric: isSingPassUser && res.singpassInfo.nric ? true : false,
    //     postal_code: !isInvalid(res.singpassInfo.postal_code) ? res.singpassInfo.postal_code : "",
    //     // _postal_code: isSingPassUser && res.singpassInfo.postal_code ? true : false,
    //     unit_no: !isInvalid(res.singpassInfo.unit_no) ? res.singpassInfo.unit_no : "",
    //     // _unit_no: isSingPassUser && res.singpassInfo.unit_no ? true : false,
    //   })
    // }else{
    this.setState({
      address: !isInvalid(res.address) ? res.address : "",
      birth: !isInvalid(res.birth) ? res.birth : "",
      country_code: !isInvalid(res.country_code) ? res.country_code : "",
      country_name: !isInvalid(res.country_name) ? res.country_name : "",
      dialingCode: !isInvalid(res.dialing_code) ? res.dialing_code : DIALING_CODE,
      email: !isInvalid(res.email) ? res.email : "",
      fullName: !isInvalid(res.name) ? res.name : "",
      gender: !isInvalid(res.gender) ? showGender(res.gender) : "",
      marital_status: !isInvalid(res.marital_status) ? res.marital_status : "",
      mobile: !isInvalid(res.mobile) ? res.mobile : "",
      nationality: !isInvalid(res.nationality) ? res.nationality : "",
      nric: !isInvalid(res.nric) ? res.nric : "",
      postal_code: !isInvalid(res.postal_code) ? res.postal_code : "",
      unit_no: !isInvalid(res.unit_no) ? res.unit_no : ""
    });
    // }

    this.setState(
      {
        // email: res.email || "",
        // dialingCode: res.dialing_code || DIALING_CODE,
        // mobile: res.mobile || "",
        old_dialingCode: res.dialing_code || DIALING_CODE,
        old_mobile: res.mobile || "",
        firstName: res.first_name || "",
        lastName: res.last_name || "",
        drug_allergy: res.drug_allergy || '',
        // country_code: !isInvalid(res.country_code) ? res.country_code : "SG",
        province_code: !isInvalid(res.province) ? res.province : "",
        city_code: !isInvalid(res.city) ? res.city : "",
        patient_id: res.user_profile_id || "",
        identification_type: !isInvalid(res.identification_type) && !isInvalid(res.identification_type.code) ? res.identification_type.code : "nric",
        // _identification_type: isSingPassUser && res.identification_type && res.identification_type.code ? true : false,
      },
      () => {
        if (this.state.country_code && this.state.country_code !== "SG") {
          this.setState({
            isDisplay: true,
          });
          this.getProvinceList();
        } else {
        }
      }
    );
  }

  getUserProfileData = () => {
    return new Promise((resolve, reject) => {
      AuthGuard.getMyinfo({}, (isOk, res) => {
        if (isOk) {
          resolve(res)
        } else {
          reject(false)
        }
      })
    })
  }

  loadingToast() {
    Toast.loading("Loading...", 30, () => {
      console.log("Load complete !!!");
    });
  }

  getAddress = (val) => {
    let _address = "";
    if (val) {
      AuthGuard.getGeoAdd(
        {
          address: val,
        },
        (isOk, res) => {
          // console.log(isOk, res)
          if (isOk) {
            _address = res.formatted_address;
            this.setState(
              {
                ...res,
                address: res.formatted_address,
                isDisplay: res.country_code !== "SG",
              },
              () => {
                this.getCountryList();
              }
            );
          }
        }
      );
    }
    this.setState({
      address: _address,
    });
  };

  getCountryList = () => {
    this.loadingToast();
    AuthGuard.getCountry({}, (isOk) => {
      if (isOk) {
        this.setState(
          {
            dialingCodeList: addKeyValueForDorpList(AuthGuard.listCountrys, "dialing_code"),
            countryList: addKeyValueForDorpList(AuthGuard.listCountrys, "country_list"),
            countryListArr: AuthGuard.listCountrys,
          },
          () => {
            if (this.state.country_code !== "") {
              if (this.state.country_code === "SG") {
                this.setState(
                  {
                    province_code: "",
                    city_code: "",
                  },
                  () => {
                    this.getProvinceList();
                  }
                );
              } else {
                console.log(this.state.country_code);
                this.getProvinceList();
              }
            }
            // if (this.state.countryCode != '' && this.state.countryCode != 'SG') {
            //     this.getProvinceList()
            // }
          }
        );
      }
      Toast.hide();
    }
    );
  };
  getProvinceList = () => {
    if (!this.state.country_code) return;
    AuthGuard.getProvinceList(
      {
        code: this.state.country_code,
      },
      (isOk, res) => {
        if (isOk) {
          let { province_code } = this.state;
          this.setState(
            {
              provinceList: addKeyValueForDorpList(
                res[0].province_list,
                "country_list"
              ),
              // province_code: province_code
              //   ? province_code
              //   : res &&
              //     res.length > 0 &&
              //     res[0].province_list &&
              //     res[0].province_list.length > 0
              //   ? res[0].province_list[0].code
              //   : "",
            },
            () => {
              if (this.state.province_code !== "") {
                if (this.state.country_code === "SG") {
                  this.setState(
                    {
                      province_code: "",
                      city_code: "",
                    },
                    () => {
                      this.getCityList();
                    }
                  );
                } else {
                  this.getCityList();
                }
              }
            }
          );
        }
      }
    );
  };

  getCityList = () => {
    if (!this.state.province_code) return;
    AuthGuard.getCityList(
      {
        province_code: this.state.province_code,
      },
      (isOk, res) => {
        if (isOk) {
          // console.log(res)
          let { city_code } = this.state;
          this.setState({
            cityList: addKeyValueForDorpList(res, "country_list"),
            // city_code: city_code
            //   ? city_code
            //   : res && res.length > 0
            //   ? res[0].code
            //   : "",
          });
        }
      }
    );
  }

  confirm = async (e) => {
    e.preventDefault();
    let self = this;
    let noError = true;

    let params = {
      myInfoId: this.state.myInfoId,
      identification_type: this.state.identification_type,
      identification: this.state.nric,
      gender: this.state.gender,
      drug_allergy: this.state.drug_allergy || '-',
      postal_code: this.state.postal_code,
      country_code: this.state.country_code,
      city_code: this.state.country_code === 'SG' ? '' : this.state.city_code,
      province_code: this.state.country_code === 'SG' ? '' : this.state.province_code,
      country: this.state.country_code,
      province: this.state.country_code === 'SG' ? '' : this.state.province_code,
      city: this.state.country_code === 'SG' ? '' : this.state.city_code,
      address: this.state.address,
      unit_no: this.state.unit_no,
      country_name: this.getCountryName(this.state.country_code),
      province_name: this.getProvinceName(this.state.province_code),
      city_name: this.getCityName(this.state.city_code)
    }

    let checkRequest = {
      data: params,
      dataName: {
        gender: 'Gender',
        postal_code: 'Postal ',
        identification_type: 'Identification Type ',
        identification: 'Identification Number ',
        province_code: 'Province ',
        country_code: 'Country ',
        city_code: 'City ',
        address: 'Address',
        unit_no: 'Unit No',
      }
    }

    if (!self.getFieldError(checkRequest)) {
      noError = false;
    }

    if (noError) {
      this.setState({
        errorMsg: {}
      })
    }

    let data = null
    if (params.country_code === 'SG') {
      let { province_code, city, province, province_name, city_code, city_name, ...$params } = params
      data = $params
    } else {
      data = params
    }

    if (!noError) {
      // Toast.fail('Complete necessary information !!!', 2);
      return false
    }

    // let res = false
    // try {
    //   res = await this.checkAddress()
    // } catch (error) {
    //   res = false
    // }


    // if (!res) {
    //   return false
    // }

    this.setState({
      confirmLoadding: true
    })
    removeCache('state')
    setCache('patientInfo', data)
    // 更新信息
    AuthGuard.completeProfile(data, (isOk, res) => {
      if (isOk) {
        AuthGuard.signupNotie({
          dialing_code: self.state.dialingCode,
          mobile_number: self.state.mobile,
        }, () => {

        })
        removeCache('patientInfo')
        if (getCache('redirectUrl')) {
          let url = getCache('redirectUrl')
          url = url.replace('#', '')
          self.props.history.push(url)
        } else {
          self.props.history.push("/submitResult");
        }
      } else {
        if (res.status_code == 11030) {
          this.setState({
            errorMsg: { identification: res.message }
          })
        } else {
          let { message } = res
          message = message ? message : "Update Fail"
          Modal.alert('Alert', message, [{ text: 'OK', onPress: () => console.log('ok') }]);
        }
      }
      this.setState({
        confirmLoadding: false
      })
    })
  }

  checkAddress = () => {
    return new Promise((resolve, reject) => {
      AuthGuard.checkRegAdd({
        param: this.state.address
      }, (isOk, res) => {
        if (isOk) {
          resolve(res)
        } else {
          Modal.alert('Notice', res.message, [{ text: 'OK', onPress: () => console.log('ok') }]);
          reject(false)
        }
      })
    })
  }

  getFieldError = (datas) => {

    let data = datas.data;
    let requiredArr = [
      "gender",
      "identification_type",
      "identification",
      "country_code",
      "postal_code",
      "address",
    ];
    // if (data.country_code !== '' && data.country_code !== null && data.country_code !== 'SG') {
    //   requiredArr.push('province_code', 'city_code');
    // }
    let err = true;
    let msg = {};

    Object.keys(data).forEach((k) => {
      if (requiredArr.includes(k)) {
        if (!data[k]) {
          err = false;
          // msg[k] = name[k] + ' is required!';
          msg[k] = 'Please fill in the required field.';//name[k] + ' is required!';
        }

        // if (k === 'postal_code') {
        //   let res = this.state.countryListArr.find((item) => {
        //     return item.code === this.state.country_code;
        //   })
        //   if (res) {
        //     let resP = !isInvalid(res.postal_code_format) ? res.postal_code_format : '';
        //     if (!isPostalCode(data[k], this.state.country_code, resP)) {
        //       err = false;
        //       msg[k] = 'Invalid Postal Code Format!';
        //     }
        //   }

        // }
      }
    })
    if (!err) {
      this.setState({
        errorMsg: msg
      });
    }
    return err;

  }

  getCountryName(code) {
    let name = ''
    if (code) {
      this.state.countryList.map((item) => {
        if (item.value === code) {
          name = item.label
        }
        return item
      })
    }
    return name

  }
  getProvinceName(code) {
    if (this.state.country_code === 'SG') return ''
    let name = ''
    if (code) {
      this.state.provinceList.map((item) => {
        if (item.value === code) {
          name = item.label
        }
        return item
      })
    }

    return name

  }
  getCityName(code) {
    if (this.state.country_code === 'SG') return ''
    let name = ''
    if (code) {
      this.state.cityList.map((item) => {
        if (item.value === code) {
          name = item.label
        }
        return item
      })
    }

    return name
  }

  getSingPassUrl() {
    // if (!this.state.singPassCode) {
    this.loadingToast();
    AuthGuard.getSingPassUrl({}, (isOk, res) => {
      if (isOk) {
        window.location.href = res.url;
      }
      Toast.hide();
    })
    // }
  }

  renderFormUserInfo = () => {
    return (
      <>
        <WingBlank>
          <Flex>
            <div className="page-title py-1">
              {this.state.patient_id
                ? "Complete your profile"
                : this.state.singPassCode
                  ? "Telemed Registration"
                  : "Registration"}
            </div>
          </Flex>
          <Flex
            className={`page-myinfo completeWithMyinfoBtn`}
            onClick={this.getSingPassUrl.bind(this)}
          >
            <Flex className="size-1">
              {this.state.singPassCode ? "Completed" : "Complete"} with{" "}
              <img className="page-myinfo-img" src={myInfo} alt="" />
            </Flex>
          </Flex>
          <WhiteSpace />
          <WhiteSpace />
          <form id="complete-profile">
            <Flex direction="column" align="start">
              <Flex.Item
                className={`${this.state._gender ? "disabledPicker" : ""}`}
              >
                <div className="new-input-outline">
                  <Flex.Item>
                    <div className="lable-title">
                      {this.state.gender ? "Gender *" : ""}{" "}
                    </div>
                  </Flex.Item>
                  <Flex.Item className={`input-outline  pickerLabel`}>
                    {isBrowser ? (
                      <Select
                        components={{ DropdownIndicator }}
                        name="gender"
                        value={this.state.gender ? this.state.genderList.find(option => option.value == this.state.gender) : null}
                        onChange={(e) => {
                          this.setState({
                            gender: e.value,
                          });
                        }}
                        styles={selectStylesForDownList}
                        menuPortalTarget={document.querySelector('#root')}
                        isSearchable={false}
                        options={this.state.genderList}
                        placeholder={"Gender *"}
                      />
                    ) : (
                      <Picker
                        disabled={this.state._gender}
                        data={this.state.genderList}
                        locale={enUs}
                        value={[this.state.gender]}
                        extra="Gender *"
                        cols={1}
                        onChange={(v) => {
                          this.setState({
                            gender: v[0],
                          });
                        }}
                      >
                        <CustomChildren
                          sClas={this.state.gender ? "sel" : "noSel"}
                        ></CustomChildren>
                      </Picker>
                    )}
                  </Flex.Item>
                </div>
                <span className="errorMsg">
                  {this.state.errorMsg.gender
                    ? this.state.errorMsg.gender
                    : null}{" "}
                </span>
              </Flex.Item>
              <Flex.Item
                className={`${this.state._identification_type ? "disabledPicker" : ""
                  }`}
              >
                <div className="new-input-outline">
                  <Flex.Item>
                    <div className="lable-title">
                      {this.state.identification_type ? "Identification Type *" : ""}
                    </div>
                  </Flex.Item>
                  <Flex.Item className={`input-outline  pickerLabel`}>
                    {isBrowser ? (
                      <Select
                        components={{ DropdownIndicator }}
                        name="identification_type"
                        value={this.state.identification_type ? this.state.identificationTypeList.find(option => option.value == this.state.identification_type) : null}
                        onChange={(e) => {
                          this.setState({
                            identification_type: e.value
                          })
                        }}
                        styles={selectStylesForDownList}
                        menuPortalTarget={document.querySelector('#root')}
                        isSearchable={false}
                        options={this.state.identificationTypeList}
                        placeholder={'Identification Type *'}
                      />
                    ) : (
                      <Picker
                        disabled={this.state._identification_type}
                        data={this.state.identificationTypeList}
                        locale={enUs}
                        value={[this.state.identification_type]}
                        extra="Identification Type *"
                        cols={1}
                        onChange={(v) => {
                          this.setState({
                            identification_type: v[0],
                          });
                        }}
                      >
                        <CustomChildren
                          sClas={this.state.identification_type ? "sel" : "noSel"}
                        ></CustomChildren>
                      </Picker>
                    )}
                  </Flex.Item>
                </div>
                <span className="errorMsg">
                  {this.state.errorMsg.identification_type
                    ? this.state.errorMsg.identification_type
                    : null}{" "}
                </span>
              </Flex.Item>
              <Flex.Item
                className={`${this.state._nric ? "disabledPicker" : ""}`}
              >
                <div className="new-input-outline">
                  <Flex.Item>
                    <div className="lable-title">
                      {this.state.nric ? "Identification Number*" : ""}{" "}
                    </div>
                  </Flex.Item>
                  <Flex.Item className="input-outline">
                    <InputItem
                      value={this.state.nric}
                      disabled={this.state._nric}
                      placeholder="Identification Number *"
                      rows={3}
                      onChange={(v) => this.setState({ nric: v.toUpperCase() })}
                    ></InputItem>
                  </Flex.Item>
                </div>
                <span className="errorMsg">
                  {this.state.errorMsg.identification
                    ? this.state.errorMsg.identification
                    : null}
                </span>
              </Flex.Item>
              <Flex.Item>
                <div className="new-input-outline">
                  <Flex.Item>
                    <div className="lable-title">
                      {this.state.drug_allergy ? "Drug Allergy (if any)" : ""}
                    </div>
                  </Flex.Item>
                  <Flex.Item className="input-outline">
                    <InputItem
                      value={this.state.drug_allergy}
                      placeholder="Drug Allergy (if any)"
                      rows={3}
                      onChange={(v) => this.setState({ drug_allergy: v })}
                    ></InputItem>
                  </Flex.Item>
                </div>
              </Flex.Item>
              <WhiteSpace />
              <WhiteSpace />
              <WhiteSpace />
              <Flex>
                <div className="page-sub-title">Add your address</div>
              </Flex>
              <WhiteSpace />
              <WhiteSpace />
              <Flex.Item>
                {this.state.gmapsLoaded && (
                  <div className="new-input-outline">
                    <GoogleSearchInput
                      getAddress={this.getAddress}
                    ></GoogleSearchInput>
                  </div>
                )}
              </Flex.Item>
              <WhiteSpace />
              <Flex.Item
                className={`${this.state._country_code ? "disabledPicker" : ""
                  }`}
              >
                <div className="new-input-outline">
                  <Flex.Item>
                    <div className="lable-title">
                      {this.state.country_code ? "Country *" : ""}
                    </div>
                  </Flex.Item>
                  {console.log(this.state.country_code)}
                  <Flex.Item className={`input-outline pickerLabel`}>
                    {isBrowser ? (
                      <Select
                        components={{ DropdownIndicator }}
                        name="country"
                        value={this.state.country_code ? this.state.countryList.find(option => option.value == this.state.country_code) : null}
                        onChange={(e) => {
                          this.setState({
                            country_code: e.value
                          },
                            () => {
                              let show;
                              if (this.state.country_code === "SG") {
                                show = false;
                              } else {
                                show = true;
                              }
                              this.getProvinceList();
                              this.setState({
                                isDisplay: show,
                              });
                            })
                        }}
                        styles={selectStylesForDownList}
                        menuPortalTarget={document.querySelector('#root')}
                        isSearchable={false}
                        options={this.state.countryList}
                        placeholder={'Country *'}
                      />
                    ) : (
                      <Picker
                        data={this.state.countryList}
                        extra="Country *"
                        locale={enUs}
                        cols={1}
                        value={[this.state.country_code]}
                        disabled={this.state._country_code}
                        onChange={(v) => {
                          this.setState(
                            {
                              country_code: v[0],
                            },
                            () => {
                              let show;
                              if (this.state.country_code === "SG") {
                                show = false;
                              } else {
                                show = true;
                              }
                              this.getProvinceList();
                              this.setState({
                                isDisplay: show,
                              });
                            }
                          );
                        }}
                      >
                        <CustomChildren
                          sClas={this.state.country_code ? "sel" : "noSel"}
                        ></CustomChildren>
                      </Picker>
                    )}
                  </Flex.Item>
                </div>
                <span className="errorMsg">
                  {this.state.errorMsg.country_code
                    ? this.state.errorMsg.country_code
                    : null}
                </span>
              </Flex.Item>
              <div
                style={{
                  width: "100%",
                  display: this.state.isDisplay ? "block" : "none",
                }}
              >
                <Flex.Item>
                  <div className="new-input-outline">
                    <Flex.Item>
                      <div className="lable-title">
                        {this.state.province_code ? "Province" : ""}
                      </div>
                    </Flex.Item>
                    <Flex.Item className="input-outline pickerLabel">
                      {isBrowser ? (
                        <Flex.Item>
                          <Select
                            components={{ DropdownIndicator }}
                            name="province"
                            value={this.state.province_code ? this.state.provinceList.find(option => option.value == this.state.province_code) : null}
                            onChange={(e) => {
                              this.setState(
                                {
                                  province_code: e.value
                                },
                                () => {
                                  this.getCityList();
                                }
                              );
                            }}
                            styles={selectStylesForDownList}
                            menuPortalTarget={document.querySelector('#root')}
                            isSearchable={false}
                            options={this.state.provinceList}
                            placeholder={'Province'}
                          />
                        </Flex.Item>
                      ) : (
                        <Picker
                          data={this.state.provinceList}
                          extra="Province"
                          locale={enUs}
                          cols={1}
                          value={[this.state.province_code]}
                          onChange={(v) => {
                            this.setState(
                              {
                                province_code: v[0],
                              },
                              () => {
                                console.log("change get c");
                                this.getCityList();
                              }
                            );
                          }}
                        >
                          <CustomChildren
                            sClas={this.state.province_code ? "sel" : "noSel"}
                          ></CustomChildren>
                        </Picker>
                      )}
                    </Flex.Item>
                  </div>
                  <span className="errorMsg">
                    {this.state.errorMsg.province_code
                      ? this.state.errorMsg.province_code
                      : null}
                  </span>
                </Flex.Item>
                <Flex.Item>
                  <div className="new-input-outline">
                    <Flex.Item>
                      <div className="lable-title">
                        {this.state.city_code ? "City" : ""}
                      </div>
                    </Flex.Item>
                    <Flex.Item className="input-outline pickerLabel">
                      {isBrowser ? (
                        <Select
                          components={{ DropdownIndicator }}
                          name="city_code"
                          value={this.state.city_code ? this.state.cityList.find(option => option.value == this.state.city_code) : null}
                          onChange={(e) => {
                            this.setState({
                              city_code: e.value
                            })
                          }}
                          styles={selectStylesForDownList}
                          menuPortalTarget={document.querySelector('#root')}
                          isSearchable={false}
                          options={this.state.cityList}
                          placeholder={'City'}
                        />
                      ) : (
                        <Picker
                          data={this.state.cityList}
                          extra="City"
                          cols={1}
                          locale={enUs}
                          value={[this.state.city_code]}
                          onChange={(v) => {
                            this.setState({
                              city_code: v[0],
                            });
                          }}
                        >
                          <CustomChildren
                            sClas={this.state.city_code ? "sel" : "noSel"}
                          ></CustomChildren>
                        </Picker>
                      )}
                    </Flex.Item>
                  </div>
                  <span className="errorMsg">
                    {this.state.errorMsg.city_code
                      ? this.state.errorMsg.city_code
                      : null}
                  </span>
                </Flex.Item>
              </div>

              <Flex.Item
                className={`${this.state._postal_code ? "disabledPicker" : ""
                  }`}
              >
                <div className="new-input-outline">
                  <Flex.Item>
                    <div className="lable-title">
                      {this.state.postal_code ? "Postal Code *" : ""}{" "}
                    </div>
                  </Flex.Item>
                  <Flex.Item className="input-outline">
                    <InputItem
                      clear
                      value={this.state.postal_code}
                      error={this.state.postal_codeError}
                      disabled={this.state._postal_code}
                      placeholder="Postal Code *"
                      onChange={(v) => this.setState({ postal_code: v })}
                    ></InputItem>
                  </Flex.Item>
                </div>
                <span className="errorMsg">
                  {this.state.errorMsg.postal_code
                    ? this.state.errorMsg.postal_code
                    : null}
                </span>
              </Flex.Item>
              <Flex.Item
                className={`${this.state._address ? "disabledPicker" : ""}`}
              >
                <div className="new-input-outline">
                  <Flex.Item>
                    <div className="lable-title">
                      {this.state.address ? "Street *" : ""}
                    </div>
                  </Flex.Item>
                  <Flex.Item className="input-outline">
                    <InputItem
                      clear
                      error={this.state.addressError}
                      value={this.state.address}
                      disabled={this.state._address}
                      placeholder="Street *"
                      onChange={(v) =>
                        this.setState({
                          address: v,
                        })
                      }
                    ></InputItem>
                  </Flex.Item>
                </div>
                <span className="errorMsg">
                  {this.state.errorMsg.address
                    ? this.state.errorMsg.address
                    : null}
                </span>
              </Flex.Item>
              <Flex.Item>
                <div className="new-input-outline">
                  <Flex.Item>
                    <div className="lable-title">
                      {this.state.unit_no ? "Unit Number" : ""}
                    </div>
                  </Flex.Item>
                  <Flex.Item className="input-outline">
                    <InputItem
                      clear
                      value={this.state.unit_no}
                      disabled={this.state._unit_no}
                      placeholder="Unit Number"
                      onChange={(v) =>
                        this.setState({
                          unit_no: v,
                        })
                      }
                    ></InputItem>
                  </Flex.Item>
                </div>
                <span className="errorMsg">
                  {this.state.errorMsg.unit_no
                    ? this.state.errorMsg.unit_no
                    : null}
                </span>
              </Flex.Item>
            </Flex>
            <WhiteSpace />
            <WingBlank style={{ width: '100%', margin: '0px auto' }}>
              <Button
                className="fill-pink-btn pb-5"
                activeClassName="fill-pink-active-btn"
                loading={this.state.confirmLoadding}
                onClick={this.confirm}
              >
                Complete Profile
              </Button>
            </WingBlank>
            <WhiteSpace />
            <WhiteSpace />
          </form>
        </WingBlank>
      </>
    );
  };

  render() {
    return (
      <LocaleProvider
        locale={{
          value: "English",
          label: "English",
          language: enUs,
        }}
      >
        <div className="CompleteProfile-Page-v2">
          {this.renderFormUserInfo()}

          <br />
          <br />
          <br />
          <br />
        </div>
      </LocaleProvider>
    );
  }
}

export default PerfectInfo;
