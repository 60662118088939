import React, { Component } from 'react';
import { WhiteSpace, NavBar, Icon, Toast, Button } from 'antd-mobile';
import AuthGuard from '../services/AuthGuard';
import { showDate, setCache } from '../commons/Helpers';

class PaymentCouponDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            campaign_id: '',
            delivery_id: '',
            user_profile_id: '',
            promo_row_id: '',
            packages_id: '',
            loading: false,
            msgError: "",
            msgSeccess: "",
            promocodeDetail: {},
            usage:[]
        }
    }

    componentDidMount() {
        let coupon_type = this.props.location.query!==undefined ? this.props.location.query.coupon_type : '';
        let user_profile_id = this.props.location.query!==undefined ? this.props.location.query.user_profile_id : '';
        let promo_row_id = this.props.location.query!==undefined ? this.props.location.query.promo_row_id : '';
        if(coupon_type == 'GE'){
            let packages_id = this.props.location.query!==undefined ? this.props.location.query.packages_id : '';
            if(packages_id === '' || promo_row_id === ''){
                this.props.history.goBack()
            }else{
                this.setState({
                    coupon_type: coupon_type,
                    user_profile_id: user_profile_id,
                    promo_row_id: promo_row_id,
                    packages_id: packages_id,
                }, () => {
                    this.getGEPromoCodeDetail();
                });
            }
        }else{
            let campaign_id = this.props.location.query!==undefined ? this.props.location.query.campaign_id : '';
            if(campaign_id === '' || promo_row_id === ''){
                this.props.history.goBack()
            }else{
                this.setState({
                    campaign_id: campaign_id,
                    user_profile_id: user_profile_id,
                    promo_row_id: promo_row_id
                }, () => {
                    this.getPromoCodeDetail();
                });
            }
        }
    }

    loadingToast() {
        Toast.loading('Loading...', 30, () => {
            console.log('Load complete !!!');
        });
    }

    getPromoCodeDetail() {
        let self = this;
        AuthGuard.getPromoCodeDetailV2({
            user_promo_reward_id: this.state.promo_row_id,
            campaign_id: this.state.campaign_id,
            payment_info: JSON.stringify({"payment_code":"CC1"}),
            qty: 1,

        }, (isOk, res) => {
            if (isOk) {
                self.setState({
                    promocodeDetail: res,
                    usage:res.usage || []
                    
                })
            }
            Toast.hide();
        });
    }

    getGEPromoCodeDetail() {
        let self = this;
        AuthGuard.getPromoCodeDetailV2({
            user_promo_reward_id: this.state.promo_row_id,
            package_id: this.state.packages_id
        }, (isOk, res) => {
            if (isOk) {
                self.setState({
                    promocodeDetail: res,
                    usage:res.usage || []
                    
                })
            }
            Toast.hide();
        });
    }

    useCoupon() {
        setCache('selectPaymentCouponId', this.state.promo_row_id)
        this.props.history.goBack(2)
    }

    coupon = () => {
        let data = {campaign_id: this.state.campaign_id, user_profile_id: this.state.user_profile_id,coupon_type: this.state.coupon_type,
            packages_id: this.state.packages_id,}
        this.props.history.push({ pathname: "/paymentCoupon", query: data});
    }

    render() {
        let { status, status_code} = this.state.promocodeDetail
        return (
            <div className="coupon-detail">
                <NavBar
                    mode="light"
                    icon={<Icon type="left" size="lg" color="#EB5DA6" size="lg"/>}
                    onLeftClick={this.coupon}
                    className="backBtn"
                ></NavBar>
                <div className="coupon-detail-content">
                    <div className="coupon-detail-title">Coupon Details</div>
                    <WhiteSpace />
                    <div className="coupon-detail-title-lg">{this.state.promocodeDetail.code}</div>
                    <WhiteSpace />
                    <div className="coupon-detail-name">{this.state.promocodeDetail.name}</div>
                    <div className="coupon-detail-gray">Expires on: {showDate(this.state.promocodeDetail.expiry_date, 8)}</div>
                    <WhiteSpace /><WhiteSpace />
                    <div className="coupon-detail-title-b mb-1">Description</div>
                    <div className="coupon-detail-text">{this.state.promocodeDetail.description}</div>
                    <WhiteSpace /><WhiteSpace />
                    <div className="coupon-detail-title-b mb-1">Terms & Conditions</div>
                    <div className="coupon-detail-text">{this.state.promocodeDetail.tc}</div>
                    <WhiteSpace /><WhiteSpace />
                    <div className="coupon-detail-title-b mb-1">Usage Restrictions</div>
                    <div className="coupon-detail-text">
                        <div>Applicable for the following</div>
                        {this.state.usage.map((item, index)=>(
                            <div key={index}>-{item}</div>
                        ))}
                    </div>
                </div>
                <Button
                    disabled={status_code !== 'available'}
                    className="use-coupon-btn fill-pink-btn"
                    onClick={this.useCoupon.bind(this)}
                >
                    {status_code === 'available' ?  'Use Coupon' : status}
                </Button>
            </div>

        );
    }
}


export default PaymentCouponDetail;