import React, { Component } from "react";
import {
  Flex,
  WhiteSpace,
  WingBlank,
  TabBar,
  Icon,
  Grid,
  Toast,
  Modal,
  ListView,
  Button,
  InputItem,
} from "antd-mobile";
import Select, { components } from "react-select";
import Failed from "../images/icons/close.svg";
import Search_icon from "../images/icons/edit.svg";
import Dropdown from "../images/icons/down.svg";
import AuthGuard from "../services/AuthGuard";
import { closeWindow } from "../commons/Helpers";

import { ThisMonthInstance } from "twilio/lib/rest/api/v2010/account/usage/record/thisMonth";

function closest(el, selector) {
  const matchesSelector =
    el.matches ||
    el.webkitMatchesSelector ||
    el.mozMatchesSelector ||
    el.msMatchesSelector;
  while (el) {
    if (matchesSelector.call(el, selector)) {
      return el;
    }
    el = el.parentElement;
  }
  return null;
}
class ScanUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scanInfo: null,
      fileList: [],
      fileTypes: [
        { label: "2D Echo", value: "2D Echo" },
        { label: "Bone Mass Density", value: "Bone Mass Density" },
        { label: "Chest X-Ray", value: "Chest X-Ray" },
        {
          label: "COVID-19 Vaccine Certificate",
          value: "COVID-19 Vaccine Certificate",
        },
        { label: "Health Report", value: "Health Report" },
        { label: "Lab Result", value: "Lab Result" },
        { label: "Mammogram", value: "Mammogram" },
        { label: "Metabolic Syndrome", value: "Metabolic Syndrome" },
        { label: "Pap Smear", value: "Pap Smear" },
        { label: "Potassium", value: "Potassium" },
        { label: "Resting ECG", value: "Resting ECG" },
        { label: "Spirometry", value: "Spirometry" },
        { label: "Stool Test", value: "Stool Test" },
        { label: "Stress Echocardiogram", value: "Stress Echocardiogram" },
        { label: "Treadmill", value: "Treadmill" },
        { label: "Trending Report", value: "Trending Report" },
        { label: "Ultrasound Abdomen", value: "Ultrasound Abdomen" },
        { label: "Ultrasound Breast", value: "Ultrasound Breast" },
        {
          label: "Ultrasound Breast Mammogram",
          value: "Ultrasound Breast Mammogram",
        },
        { label: "Ultrasound Carotid", value: "Ultrasound Carotid" },
        { label: "Ultrasound HBS", value: "Ultrasound HBS" },
        { label: "Ultrasound Kidney", value: "Ultrasound Kidney" },
        { label: "Ultrasound KUB", value: "Ultrasound KUB" },
        { label: "Ultrasound Liver", value: "Ultrasound Liver" },
        { label: "Ultrasound Prostate", value: "Ultrasound Prostate" },
        { label: "Ultrasound Pelvis", value: "Ultrasound Pelvis" },
        { label: "Ultrasound Thyroid", value: "Ultrasound Thyroid" },
        { label: "Urine and Stool", value: "Urine and Stool" },
        { label: "Urine Test", value: "Urine Test" },
        { label: "Fundus Image Screening", value: "Fundus Image Screening" },
        { label: "Patient Notes", value: "Patient Notes" },
        { label: "Audiometry", value: "Audiometry" },
        { label: "Others", value: "Others" },
      ],
      fileSizeTotal: 0,
      showDocument: false,
      selectDocuments: [
        {
          label: "Internal Repository",
          value: "INTERNAL_REPORT",
          checked: false,
        },
        {
          label: "Patient Repository",
          value: "PATIENT_REPORT",
          checked: false,
        },
      ],
      uploadFliesShow: false,
      uploadSuccess: [],
      uploadFail: [],
    };
    console.log(props);
  }
  componentDidMount() {
    let { id } = this.props.match.params;
    if (id) {
      this.fetchScanInfo({
        document_qr_id: id,
      });
    }
  }

  fetchScanInfo = (data, callBack) => {
    Toast.loading("loading...", 0, () => {}, true);
    try {
      AuthGuard.getScanInfo(data, (isOk, res) => {
        if (isOk) {
          this.setState({
            scanInfo: res,
          });
          Toast.hide();
          callBack && callBack(isOk);
        } else {
          Toast.hide();
        }
      });
    } catch (e) {
      Toast.hide();
    }
  };

  // checkExpiredTime = () => {
  //   let { expired } = this.props.match.params;
  //   let limit = 60 * 30;
  //   let currentTime = Math.ceil(new Date().getTime() / 1000);
  //   if (!expired || expired - currentTime > limit) {
  //     Toast.fail("Qr code has expired.", 3);
  //     return false;
  //   }
  //   return true;
  // };

  inputChange = (value, i) => {
    this.setFileList("fileName", value, i);
  };

  checkboxChange = (e, i) => {
    e.persist();

    setTimeout(() => {
      this.setFileList("updateFileName", e.target.checked, i);
    }, 100);
  };

  fileTypeChange = (e) => {
    // Prevents React from resetting its properties:
    e.persist();

    setTimeout(() => {
      let { value, dataset } = e.target;
      this.setFileList("fileType", value, dataset.index);
    }, 100);
  };
  removeFileList = (index) => {
    let newArr = this.state.fileList;
    newArr.splice(index, 1);
    this.setState({
      fileList: newArr,
    });
  };

  setFileList = (target, value, i) => {
    let newFileList = this.state.fileList;
    console.log(target, value, i);
    newFileList.map((item, index) => {
      if (Number(i) === index) {
        item[target] = value;
        console.log("item", item);
        if (target === "fileName") {
          let file = item.uploadFile;
          item.uploadFile = new File([file], value + "." + item.fileFormat, {
            type: item.fileFormat,
          });
        }
        if (
          target === "isEditFileName" &&
          !item["fileName"] &&
          !item["isEditFileName"]
        ) {
          item["fileName"] = item.fileType;
        }
      }

      return item;
    });
    console.log(newFileList);
    this.setState({
      fileList: newFileList,
    });
  };

  selectUpload = (e) => {
    // Prevents React from resetting its properties:
    e.persist();

    setTimeout(() => {
      console.log(e);
      let { files } = e.target;
      if (!files.length) {
        return false;
      }
      console.log(files[0]);
      if (
        !["image/jpeg", "image/jpg", "image/png", "application/pdf"].includes(
          files[0].type
        )
      ) {
        Toast.fail("File type not supported!", 3);
        return false;
      }

      let Size = this.computedFileSize(files[0]);
      let { suffix, name } = this.getFileType(files[0]);

      if (Number(this.state.fileSizeTotal) + Number(Size) > 249.9) {
        Toast.fail("The maximum upload is only 250MB!", 3);
        return false;
      }
      if (Size > 49.9) {
        Toast.fail("The maximum limit for a single upload is 50MB!", 3);
        return false;
      }

      let newArr = this.state.fileList;
      newArr.push({
        id: this.uuid(),
        fileName: name,
        fileFormat: suffix,
        isEditFileName: false,
        fileType: "2D Echo",
        uploadFile: files[0],
        uploadStatus: "init",
        updateFileName: false,
      });
      this.setState({
        fileList: newArr,
        fileSizeTotal: (
          Number(this.state.fileSizeTotal) + Number(Size)
        ).toFixed(2),
      });
      e.target.value = "";
      console.log(newArr);
    }, 100);
  };

  computedFileSize = (file) => {
    return (file.size / 1024 / 1024).toFixed(2);
  };

  getFileType = (file) => {
    let startIndex = file?.name.lastIndexOf(".");
    console.log(startIndex, file);
    if (startIndex !== -1) {
      let suffix = file?.name
        .substring(startIndex + 1, file?.name.length)
        .toLowerCase();
      let name = file?.name.substring(0, startIndex);
      return {
        suffix,
        name,
      };
    }
  };

  uploadFiles = async () => {
    let uploadIndex = this.state.fileList.findIndex(
      (item) => item.uploadStatus === "init"
    );
    try {
      if (uploadIndex === -1) {
        Toast.hide();
        this.setState({
          uploadFliesShow: true,
          uploadSuccess: this.state.fileList.filter(
            (e) => e.uploadStatus === "success"
          ),
          uploadFail: this.state.fileList.filter(
            (e) => e.uploadStatus !== "success"
          ),
        });
        return false;
      }

      let uploadItem = this.state.fileList[uploadIndex];

      this.mockUpload(uploadItem, uploadIndex);
    } catch (e) {
      console.log(e);
    }
  };

  mockUpload = async (row, index) => {
    let { id } = this.props.match.params;
    let repository_type = this.state.selectDocuments
      .map((e) => {
        return e.checked && e.value;
      })
      .filter((e) => e);
    try {
      let data = {
        file: row.uploadFile,
        id: this.state.scanInfo.document_qr_request_id,
        repository_type,
        isUpdate: row?.updateFileName ? "Y" : "N",
        fileType: row?.fileType,
      };
      var formdata = new FormData();
      formdata.append("files", data.file);
      formdata.append("document_qr_request_id", data.id);
      formdata.append("repository_type", JSON.stringify(data.repository_type));
      formdata.append("is_update_file_name", data.isUpdate);
      formdata.append("file_type", data.fileType);

      AuthGuard.ScanUpload(formdata, (isOk, res) => {
        if (isOk) {
          this.setFileList("uploadStatus", "success", index);
        } else {
          this.setFileList("uploadStatus", "error", index);
        }
        if (res.status_code == 23006) {
          Toast.hide();
          Modal.alert("Notice", res.message, [
            {
              text: "OK",
              onPress: () => {
                closeWindow();
              },
            },
          ]);
        } else {
          setTimeout(() => {
            this.uploadFiles();
          }, 200);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  keyDown = (e) => {
    e.persist();

    setTimeout(() => {
      if (e.keyCode === 13 && e.key === "Enter") {
        // alert("确认");
        e.target.blur();
      }
    }, 100);
  };

  uuid = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  onWrapTouchStart = (e) => {
    // fix touch to scroll background page on iOS
    if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
      return;
    }
    const pNode = closest(e.target, ".am-modal-content");
    if (!pNode) {
      e.preventDefault();
    }
  };

  checkedDocument = (e, i) => {
    e.persist();

    setTimeout(() => {
      let newArr = this.state.selectDocuments;
      newArr[i].checked = e.target.checked;
      this.setState({
        selectDocuments: newArr,
      });
    }, 100);
  };

  confirmUpload = () => {
    console.log(this.state.selectDocuments);
    let isNext = this.state.selectDocuments.findIndex(
      (e) => e.checked === true
    );
    if (isNext !== -1) {
      this.setState({
        showDocument: false,
      });
      Toast.loading("Uploading...", 0, () => {}, true);

      this.uploadFiles();
    } else {
      Toast.fail("Please select at least one option.", 3);
    }
  };

  resetFileList = () => {
    this.setState({
      fileList: [],
      fileSizeTotal: 0,
      showDocument: false,
      selectDocuments: [
        {
          label: "Internal Repository",
          value: "INTERNAL_REPORT",
          checked: false,
        },
        {
          label: "Patient Repository",
          value: "PATIENT_REPORT",
          checked: false,
        },
      ],
      uploadFliesShow: false,
      uploadSuccess: [],
      uploadFail: [],
    });
  };

  render() {
    return (
      <div className="ScanUpload">
        <div className="header">
          <p className="uploadTit">Upload Documents for</p>
          {this.state?.scanInfo && (
            <p className="userName">
              {this.state.scanInfo?.patient_name || "-"},
              {this.state.scanInfo?.patient_nric || "-"}
            </p>
          )}

          <div className="uploadCon">
            <div className="uploadBtn">
              +&nbsp;&nbsp;Select Files to Upload
              <p>Maximum 50MB</p>
            </div>
            <input
              className="uploadInput"
              type="file"
              // accept="application/pdf,image/png,image/jpeg"
              onChange={(e) => {
                if (this.state?.scanInfo) {
                  this.selectUpload(e);
                }
              }}
            ></input>
          </div>
        </div>
        <div className="uploadFiles">
          {this.state.fileList &&
            this.state.fileList.map((item, index) => {
              return (
                <div className="uploadFile" key={index}>
                  <div className="fileTop">
                    <div className="fileName">
                      {!item?.isEditFileName && (
                        <p>{`${item?.fileName}.${item?.fileFormat}`}</p>
                      )}

                      {item?.isEditFileName && (
                        <InputItem
                          className="inputField"
                          placeholder="File Name"
                          value={item.fileName}
                          type="text"
                          autoFocus
                          onChange={(v) => {
                            this.inputChange(v, index);
                          }}
                          onBlur={() => {
                            this.setFileList("isEditFileName", false, index);
                          }}
                          onKeyDown={(e) => {
                            this.keyDown(e);
                          }}
                          extra={"." + item?.fileFormat}
                        />
                      )}
                    </div>
                    <div className="fileAction">
                      <img
                        className="edit"
                        src={Search_icon}
                        alt=""
                        onClick={() => {
                          this.setFileList("isEditFileName", true, index);
                        }}
                      />
                      <img
                        className="delete"
                        src={Failed}
                        alt=""
                        onClick={() => {
                          this.removeFileList(index);
                        }}
                      />
                    </div>
                  </div>
                  <div className="fileType">
                    <div>
                      <p className="selectType">File Type</p>
                      <p>{item.fileType}</p>
                    </div>
                    <img src={Dropdown} alt="" />
                    <select
                      className="selectFileType"
                      data-index={index}
                      defaultValue={item.fileType}
                      onChange={(e) => {
                        this.fileTypeChange(e);
                      }}
                    >
                      {this.state?.fileTypes?.length &&
                        this.state?.fileTypes.map((lab, num) => {
                          return (
                            <option key={lab.value} value={lab.value}>
                              {lab.label}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="fileCheckBox">
                    <input
                      type="checkbox"
                      id={"updateFileName" + index}
                      value={item?.updateFileName}
                      onChange={(e) => {
                        this.checkboxChange(e, index);
                      }}
                    />
                    <label htmlFor={"updateFileName" + index}>
                      Update file name as file type upon upload
                    </label>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="startUpload">
          <Button
            disabled={!this?.state?.fileList?.length}
            type="primary"
            onClick={() => {
              let { id } = this.props.match.params;
              if (id) {
                this.setState({
                  showDocument: true,
                });
              }
            }}
          >
            Start Uploading
          </Button>
        </div>
        <Modal
          className="uploadPopup"
          visible={this.state.showDocument}
          transparent
          maskClosable={false}
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
        >
          <p className="popupTit">
            Please select where to upload your document(s):
          </p>
          <div className="documents">
            {this.state.selectDocuments &&
              this.state.selectDocuments.map((item, index) => {
                return (
                  <div className="checkBoxItem" key={item.value}>
                    <input
                      id={item.value}
                      type="checkbox"
                      onChange={(e) => {
                        this.checkedDocument(e, index);
                      }}
                    />
                    <label htmlFor={item.value}>{item.label}</label>
                  </div>
                );
              })}
          </div>
          <div className="action">
            <Button
              className="cancel"
              onClick={() => {
                this.setState({
                  showDocument: false,
                });
              }}
            >
              Cancel
            </Button>
            <Button
              className="confirm"
              type="primary"
              onClick={() => this.confirmUpload()}
            >
              Proceed
            </Button>
          </div>
        </Modal>

        <Modal
          className="uploadPopup"
          visible={this.state.uploadFliesShow}
          transparent
          maskClosable={false}
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
        >
          {!!this.state?.uploadSuccess?.length && (
            <>
              <p className="popupTit">Files Uploaded Successfully!</p>
              <div className="fileList">
                {this.state?.uploadSuccess.map((item, i) => {
                  return (
                    <div
                      className="fileItem"
                      key={i}
                    >{`${item.fileName}.${item.fileFormat}`}</div>
                  );
                })}
              </div>
            </>
          )}

          {!!this.state?.uploadFail?.length && (
            <>
              <p className="popupTit">Error Uploading:</p>
              <div className="fileList">
                {this.state?.uploadFail.map((item, i) => {
                  return (
                    <div
                      className="fileItem"
                      key={i}
                    >{`${item.fileName}.${item.fileFormat}`}</div>
                  );
                })}
              </div>
            </>
          )}
          <Button
            className="fileConfirm"
            type="primary"
            onClick={() => {
              this.resetFileList();
            }}
          >
            Proceed
          </Button>
        </Modal>
      </div>
    );
  }
}

export default ScanUpload;
