import React, { Component } from "react";
import {
  Button,
  Flex,
  Icon,
  Modal,
  NavBar,
  Tabs,
  Toast,
  WhiteSpace,
  WingBlank,
} from "antd-mobile";
import {
  dayFile,
  GOOGLE_ANALYTICS,
  WHATSAPP_MOBILENO,
} from "../commons/Constants";
import AuthGuard from "../services/AuthGuard";
import {
  currencyType,
  getWeek,
  setCache,
  removeCache,
  showDate,
} from "../commons/Helpers";
import clinic from "../images/icons/clinic.png";
import letter from "../images/icons/letter.png";
import location from "../images/icons/location.png";
import phone from "../images/icons/phone.png";
import banner_nobg from "../images/icons/banner_nobg.png";
import close_red from "../images/icons/close_red.png";
import { isBrowser, isMobile, isIOS, isAndroid, viewFile } from "react-device-detect";
import LoginModel from "../component/LoginModel";
import iconDoctorAvatar from "../images/icons/icon_doctor_avatar.png";
import iconSaveDoctor from "../images/icons/icon_saveDr.png";
import iconUnSaveDoctor from "../images/icons/icon_unsaveDr.png";
import logo from "../images/icons/logo.png";
import success_icon from "../images/icons/success_icon.png";
import ScheduleCalendar from "../component/ScheduleCalendar";
import { ViewMore } from "../component/ViewMore";
import SwiperCom from "./SwiperCom";
import ReactGA from "react-ga";
import Menu from "../component/Menu";
import _ from "lodash";
import JsFileDownloader from "js-file-downloader";
import url from "url";

function closest(el, selector) {
  const matchesSelector =
    el.matches ||
    el.webkitMatchesSelector ||
    el.mozMatchesSelector ||
    el.msMatchesSelector;
  while (el) {
    if (matchesSelector.call(el, selector)) {
      return el;
    }
    el = el.parentElement;
  }
  return null;
}
class DoctorDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressId: "--",
      unitNo: "",
      mailId: "--",
      country: "",
      country_code: "",
      province: "",
      city: "",
      postal_code: "",
      contactId: "--",
      emergecyId: "",
      patient_id: "",
      programName: "",
      clinicName: "",
      introduction: "",
      bannerSrc: "",
      alt: "",
      programList: [],
      daysList: [],
      openingHoursList: [],
      isShowConsultNow: false,
      doctorList: [],
      teleconsultation_consultation_min_fee: "",
      teleconsultation_consultation_max_fee: "",
      tabs: [
        { title: "About Clinic", id: "AboutClinic", sub: "0" },
        { title: "Providers", id: "DoctorsSection", sub: "1" },
        { title: "Health Program", id: "HealthProgram", sub: "2" },
        { title: "Health Screening", id: "HealthScreening", sub: "3" },
      ],
      hasVerticalScrolled: false,
      initialPage: 0,
      onlineShow: "",
      activeKey: "3",
      // textlength: 150,
      identification: "",
      code_name: "",
      savedrLoading: false,
      AboutClinicOffsetTop: 0,
      DoctorsSectionOffsetTop: 0,
      HealthProgramOffsetTop: 0,
      HealthScreeningOffsetTop: 0,
      clinic_logo: "",
      teleconsultation_currency_code: "SGD",
      consultBtnLoading: false,
      bookAppointmentBtnLoading: false,
      clinic_id: "",
      user_drug_allergy: "",
      user_source: "",
      user_is_complete: "",
      menu_list: [],
      errorReuqestMsg: {},
      screeningList: [],
      tabMarginLeft: "19.5%",
      hasHSPackage: false,
      isShowBookVisit: false,
      program_open: false,
      program_request_id: "",
      program_file: {},
    };
  }

  async componentDidMount() {
    removeCache("source");
    removeCache("source_back");
    if (GOOGLE_ANALYTICS) {
      ReactGA.set({
        page: window.location.href,
      });
    }

    let { id, uid, token } = this.props.match.params;
    if (token) await AuthGuard.getNewToken(token);
    let _httpUrl = this.props.location.search;
    let isShowBookVisit =
      url.parse(_httpUrl, true).query.filter === "show_book_visit";

    if (id) {
      this.setState(
        {
          clinic_info_id: id,
          patient_id: uid,
          isShowBookVisit,
        },
        () => {
          this.getDoctorDetails();
          this.getprogramList();
          this.getScreening();
          this.tabMarginLeftChange();

          window.addEventListener("scroll", this.bindHandleScroll.bind(this));
        }
      );
    } else {
      console.log("无效id");
    }

    this.setState({
      menu_list: Menu(),
    });
  }

  componentWillReceiveProps(nextProps) {
    if (GOOGLE_ANALYTICS) {
      ReactGA.set({
        page: window.location.href,
      });
    }
  }

  //在componentWillUnmount，进行scroll事件的注销
  componentWillUnmount() {
    window.removeEventListener("scroll", this.bindHandleScroll.bind(this));
  }

  onRef = (ref) => {
    this.loginModel = ref;
  };

  onSCRef = (ref) => {
    this.scModel = ref;
  };

  consultAnyDoctor = async (identification, is_any) => {
    console.log("fix", 123);

    let _url = window.location.pathname;
    if (!AuthGuard.checkLogin()) {
      this.loginModel.alertLogin({
        url: _url,
        query: this.props.match.params.id,
        alert_title: "You need a HeartVoice account to consult doctors.",
      });
    } else {
      try {
        let res = await this.checkProfile();
        console.log(res);
        if (res) {
          if (navigator.userAgent.indexOf("heartvoice") !== -1) {
            this.doQueueAssign.call(this, this.state.clinic_id, "app", is_any);
          } else if (
            navigator.userAgent.indexOf("1docAndroid") !== -1 ||
            navigator.userAgent.indexOf("1docIos") !== -1
          ) {
            this.doQueueAssign.call(
              this,
              this.state.clinic_id,
              "1docApp",
              is_any
            );
          } else {
            this.doQueueAssign.call(
              this,
              this.state.clinic_id,
              "whatsapp",
              is_any
            );
          }
        }
      } catch (error) {
        if (error && error.status_code !== 401) {
          Modal.alert(
            "Complete Profile",
            <div className="complete_Profile">
              Please provide more information about yourself to initiate a
              teleconsultation.{" "}
            </div>,
            [
              {
                text: "Cancel",
                style: { color: "#108ee9" },
                onPress: () => console.log("第0个按钮被点击了"),
              },
              {
                text: "Proceed",
                style: { color: "#108ee9" },
                onPress: () => this.goMyinfo(),
              },
              {
                text: " ",
                style: { display: "none" },
                onPress: () => {
                  console.log("No");
                },
              },
            ]
          );
        }
      }
    }
  };

  doQueueAssign(clinic_id, channel, is_any) {
    this.loadingToast();
    if (is_any === "any") {
      AuthGuard.queueAssign(
        {
          clinic_id: clinic_id,
          channel: channel,
        },
        (isOk, res) => {
          Toast.hide();
          if (isOk) {
            if (res.message !== null) {
              if (isBrowser) {
                Modal.alert(
                  "Oops",
                  <div>
                    We are getting you a doctor. check your whatsapp for
                    updates!
                  </div>,
                  [
                    {
                      text: "Okay",
                      onPress: () => console.log("第0个按钮被点击了"),
                    },
                  ]
                );
              } else {
                if (channel == "app") {
                  window.location.href =
                    "heartvoice://heartvoice/home?action=home";
                } else if (channel === "1docApp") {
                  isIOS &&
                    window.webkit.messageHandlers.back_to_app_home.postMessage(
                      ""
                    );
                  isAndroid && window.JsToAndroid.back_to_app_home();
                } else {
                  Modal.alert(
                    "Appointment Requested",
                    <div>
                      You will receive a whatsapp message when the doctor
                      confirms your appointment request.
                    </div>,
                    [
                      {
                        text: "Okay",
                        onPress: () => {
                          window.location.href =
                            "https://wa.me/" + WHATSAPP_MOBILENO;
                        },
                      },
                    ]
                  );
                }
              }
            }
          } else {
            if (res.status_code !== 401) {
              Modal.alert("Oops", <div>{res.message}</div>, [
                {
                  text: "Okay",
                  onPress: () => console.log("第0个按钮被点击了"),
                },
              ]);
            }
          }
        }
      );
    } else {
      // let doctorList = this.state.doctorList;
      // let doctor_one_id = '';
      // if(doctorList && doctorList.length === 1){
      //   if(doctorList[0].id){
      //     doctor_one_id = doctorList[0].id;
      //   }
      // }
      // AuthGuard.queueOneAssign({
      //   doctor_id: doctor_one_id,
      //   channel: channel,
      // }, (isOk, res) => {
      //   Toast.hide();
      //   if (isOk) {
      //     if (res.message !== null) {
      //       if (isBrowser) {
      //         Modal.alert('Oops', <div>We are getting you a doctor. check your whatsapp for updates!</div>, [
      //           { text: 'Okay', onPress: () => console.log('第0个按钮被点击了') }
      //         ])
      //       } else {
      //         if (channel == 'app') {
      //           window.location.href = 'heartvoice://heartvoice/home?action=home'
      //         } else {
      //           Modal.alert('Appointment Requested', <div>You will receive a whatsapp message when the doctor confirms your appointment request.</div>, [
      //             { text: 'Okay', onPress: () => { window.location.href = 'https://wa.me/' + WHATSAPP_MOBILENO } }
      //           ])
      //         }
      //       }
      //     }
      //   } else {
      //     if (res.status_code !== 401) {
      //       Modal.alert('Oops', <div>{res.message}</div>, [
      //         { text: 'Okay', onPress: () => console.log('第0个按钮被点击了') }
      //       ])
      //     }
      //   }
      // });
    }
  }

  onWrapTouchStart = (e) => {
    // fix touch to scroll background page on iOS
    if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
        return;
    }
    const pNode = closest(e.target, ".am-modal-content");
    if (!pNode) {
        e.preventDefault();
    }
  };

  requestCode(programs_id, programFile = {}) {
    // this.props.history.push('/joinProgram/' + programs_id);
    // window.location.href = 'https://forms.gle/9YWb4YhDUE2W3HY39'
    let _url = window.location.pathname;
    if (!AuthGuard.checkLogin()) {
      this.loginModel.alertLogin({
        url: _url,
        alert_title: "Please Login in.",
      });
      return false;
    } else {
      if(Object.keys(programFile).length){
          this.setState({
              program_open: true,
              program_request_id: programs_id,
              program_file: programFile
          })
          return;
      }
      this.setState({
        errorReuqestMsg: {},
      });
      let _programList = this.state.programList;
      let findItemIndex = _programList.findIndex(
        (item) => item.id == programs_id
      );
      this.loadingToast();
      AuthGuard.requestCode(
        {
          programId: programs_id,
        },
        (isOk, res) => {
          Toast.hide();
          if (isOk) {
            _programList[findItemIndex].purchase_type = "Requested";
            this.setState({
              programList: _programList,
              program_request_id: "",
              program_open: false,
              program_file: {},
            });
          } else {
            this.setState({
              errorReuqestMsg: {
                id: programs_id,
                message: res,
              },
              program_request_id: "",
              program_open: false,
              program_file: {},
            });
          }
        }
      );
    }
  }

  loadingToast() {
    Toast.loading("Loading...", 30, () => {});
  }
  //页面跳转锚点
  scrollToAnchor = (anchorName) => {
    console.log(anchorName);
    if (anchorName) {
      // 找到锚点
      let anchorElement = document.getElementById(anchorName);
      // 如果对应id的锚点存在，就跳转到锚点

      if (anchorName === "HealthScreening") {
        window.scrollTo({ top: anchorElement.offsetTop - 120 });
      } else {
        if (anchorElement) {
          anchorElement.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }
    }
  };

  //跳转到指定的医生
  scrollToDr = (drCodeName) => {
    if (drCodeName) {
      // 找到锚点
      let drElement = document.getElementById(drCodeName);
      // 如果对应id的锚点存在，就跳转到锚点
      if (drElement) {
        if (isBrowser) {
          console.log("isBrowser");
          this.scrollToAnchor("DoctorsSection");
          setTimeout(() => {
            drElement.scrollIntoView({ block: "start", behavior: "smooth" });
          }, 400);
        } else {
          setTimeout(() => {
            var headerOffset = 120;
            var elementPosition = drElement.getBoundingClientRect().top;
            var offsetPosition =
              elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
              top: offsetPosition,
              behavior: "smooth",
            });
          }, 600);
        }
      }
    }
  };

  bindHandleScroll = (e) => {
    let {
      AboutClinicOffsetTop,
      DoctorsSectionOffsetTop,
      HealthProgramOffsetTop,
      HealthScreeningOffsetTop,
    } = this.state;
    const scrollTop = e.srcElement.scrollingElement.scrollTop;

    if (scrollTop >= HealthScreeningOffsetTop - 88) {
      this.setState({ activeKey: 3 });
    } else if (scrollTop >= HealthProgramOffsetTop - 88) {
      this.setState({ activeKey: 2 });
    } else if (scrollTop >= DoctorsSectionOffsetTop - 88) {
      this.setState({ activeKey: 1 });
    } else if (scrollTop >= AboutClinicOffsetTop) {
      this.setState({ activeKey: 0 });
    }
  };

  getDoctorDetails() {
    this.loadingToast();

    AuthGuard.doctorDetails(
      {
        clinic_info_id: this.state.clinic_info_id,
      },
      (isOk, res) => {
        Toast.hide();
        if (isOk) {
          var isThereDoctorAvaliable = false;
          for (let index = 0; index < res.doctor_list.length; index++) {
            const doctor = res.doctor_list[index];
            if (doctor.doctor_opening_time_to == true) {
              //判断是否有医生当天营业
              //判断是否不需要提前预约，可当天预约
              if (
                doctor.lead_time === undefined ||
                doctor.lead_time === null ||
                doctor.lead_time === 0 ||
                doctor.lead_time === "" ||
                doctor.lead_time === "0"
              ) {
                isThereDoctorAvaliable = true;
                break;
              }
            }
          }

          let _doctor_list = res.doctor_list ?? [];
          if (_.size(_doctor_list) > 0 && this.state.isShowBookVisit) {
            _doctor_list = [
              ..._.filter(_doctor_list, (o) => o.show_book_visit),
            ];
          }

          this.setState(
            {
              clinic_id: res.id,
              addressId: res.clinic_address,
              unitNo: res.unit_no,
              country: res.country,
              country_code: res.country_code,
              province: res.province,
              city: res.city,
              postal_code: res.postal_code,
              mailId: res.clinic_email,
              clinicName: res.clinic_name,
              contactId: res.clinic_contact_no,
              dialingCode: res.clinic_dialing_code,
              emergecyId: res.clinic_emergency_no,
              openingHoursList: res.clinic_opening_time || [],
              teleconsultation_consultation_min_fee:
                res.teleconsultation_consultation_min_fee || "",
              teleconsultation_consultation_max_fee:
                res.teleconsultation_consultation_max_fee || "",
              doctorList: _doctor_list,
              isShowConsultNow: isThereDoctorAvaliable,
              identification: res.identification,
              introduction: res.introduction,
              clinic_logo:
                res.merchantinfo &&
                res.merchantinfo.display_logo &&
                res.merchantinfo.display_logo.url &&
                res.merchantinfo.display_logo.url.l
                  ? res.merchantinfo.display_logo.url.l
                  : clinic,
              teleconsultation_currency_code:
                res.merchantinfo &&
                res.merchantinfo.teleconsultation_currency_code
                  ? res.merchantinfo.teleconsultation_currency_code
                  : "SGD",
            },
            () => {
              let httpUrl = this.props.location.search;
              let activeKey = url.parse(httpUrl, true).query.tab;
              this.getOffsetTopForTab(activeKey ? activeKey : "");
            }
          );
        }
      }
    );
  }

  getOffsetTopForTab = (activeKey = "") => {
    let self = this;
    this.setState(
      {
        AboutClinicOffsetTop: document.getElementById("AboutClinic")
          ? document.getElementById("AboutClinic").offsetTop
          : 0,
        DoctorsSectionOffsetTop: document.getElementById("DoctorsSection")
          ? document.getElementById("DoctorsSection").offsetTop
          : 0,
        HealthProgramOffsetTop: document.getElementById("HealthProgram")
          ? document.getElementById("HealthProgram").offsetTop
          : 0,
        HealthScreeningOffsetTop: document.getElementById("HealthScreening")
          ? document.getElementById("HealthScreening").offsetTop
          : 0,
      },
      () => {
        let _url = window.location.href;
        if (!!activeKey) {
          setTimeout(() => {
            let tabID = "AboutClinic";
            if (activeKey == 1) {
              tabID = "DoctorsSection";
            } else if (activeKey == 2) {
              tabID = "HealthProgram";
            } else if (activeKey == 3) {
              tabID = "HealthScreening";
            } else {
              activeKey = 0;
            }
            self.scrollToAnchor(tabID);
            self.setState({
              activeKey: activeKey,
            });
          }, 500);
          return false;
        } else {
          if (_url.indexOf("hs") !== -1) {
            setTimeout(() => {
              self.scrollToAnchor("HealthScreening");
              self.setState({
                activeKey: 3,
              });
            }, 500);
            return false;
          } else if (this.state.patient_id) {
            setTimeout(() => {
              self.scrollToDr(this.state.patient_id);
            }, 100);
            return false;
          } else if (self.props.location.state) {
            let { oldurl } = self.props.location.state;
            if (oldurl) {
              setTimeout(() => {
                self.scrollToAnchor(oldurl);
                self.setState({
                  activeKey: 3,
                });
              }, 100);
            }
            return false;
          }
        }
      }
    );
  };
  getprogramList() {
    if (AuthGuard.checkLogin()) {
      AuthGuard.getProgramTokenList(
        {
          clinicInfoId: this.state.clinic_info_id,
          status: "active",
        },
        (isOk, res) => {
          if (isOk) {
            this.setState(
              {
                programList: res,
              },
              () => {
                // this.tabMarginLeftChange()
              }
            );
          }
        }
      );
    } else {
      AuthGuard.getprogramList(
        {
          clinicInfoId: this.state.clinic_info_id,
          status: "active",
        },
        (isOk, res) => {
          if (isOk) {
            this.setState(
              {
                programList: res,
              },
              () => {
                // this.tabMarginLeftChange()
              }
            );
          }
        }
      );
    }
  }

  getScreening() {
    let self = this;
    AuthGuard.hsList(
      {
        clinic_id: this.state.clinic_info_id,
      },
      (isOk, res) => {
        if (isOk) {
          this.setState(
            {
              screeningList: res,
              hasHSPackage: Boolean(res.find((item) => item?.pacakage)),
            },
            () => {
              // this.tabMarginLeftChange()
              // console.log(this.state.hasHSPackage)
            }
          );
        }

        let _url = window.location.href;
        if (_url.indexOf("hs") !== -1) {
          setTimeout(() => {
            self.scrollToAnchor("HealthScreening");
            self.setState({
              activeKey: 3,
            });
          }, 500);
          return false;
        }
      }
    );
  }

  tabMarginLeftChange() {
    if (this.state.tabs.length === 3) {
      this.setState({
        tabMarginLeft: "11.5%",
      });
    } else if (this.state.tabs.length === 4) {
      this.setState({
        tabMarginLeft: "7.5%",
      });
    }
  }

  onChange() {
    console.log(`11`);
  }

  saveDr(id, status, e) {
    e.stopPropagation();

    let _url = "/doctorDetails/" + this.state.clinic_info_id;
    if (!AuthGuard.checkLogin()) {
      this.loginModel.alertLogin({
        url: _url,
        alert_title: "You need a HeartVoice account to consult doctors.",
      });
      return false;
    }

    if (this.state.savedrLoading) return;

    this.setState({
      savedrLoading: true,
    });
    AuthGuard.savedr(
      {
        coach_id: id,
        status: status === "saved" ? "unsaved" : "saved",
      },
      (isok, res) => {
        if (isok) {
          let { coach_id, status } = res;
          let _doctorList = this.state.doctorList;
          let index = _doctorList.findIndex((item) => item.id === coach_id);
          _doctorList[index].saved = status;
          this.setState({
            doctorList: _doctorList,
          });
        }
        this.setState({
          savedrLoading: false,
        });
      }
    );
  }

  onValueChange(value) {
    console.log(value);
  }

  bookAppointment = async (id, type) => {
    let _url = window.location.pathname;
    if (!AuthGuard.checkLogin()) {
      this.loginModel.alertLogin({
        url: _url,
        query: id,
        alert_title: "You need a HeartVoice account to consult doctors.",
      });
    } else {
      try {
        let res = await this.checkProfile();
        this.scModel.openScModel({
          clinic_id: this.state.clinic_info_id,
          doctor_id: id,
          type,
        });
      } catch (error) {
        if (error && error.status_code !== 401) {
          Modal.alert(
            "Complete Profile",
            <div className="complete_Profile">
              Please provide more information about yourself to initiate a
              teleconsultation.{" "}
            </div>,
            [
              {
                text: "Cancel",
                style: { color: "#108ee9" },
                onPress: () => console.log("第0个按钮被点击了"),
              },
              {
                text: "Proceed",
                style: { color: "#108ee9" },
                onPress: () => this.goMyinfo(),
              },
              {
                text: " ",
                style: { display: "none" },
                onPress: () => {
                  console.log("No");
                },
              },
            ]
          );
        }
      }
    }
  };

  consult = async (code, id) => {
    console.log(code, id);

    let _url = window.location.pathname;

    if (!AuthGuard.checkLogin()) {
      this.loginModel.alertLogin({
        url: _url,
        query: id,
        alert_title: "You need a HeartVoice account to consult doctors.",
      });
    } else {
      try {
        let res = await this.checkProfile();
        console.log(res);
        if (res) {
          if (navigator.userAgent.indexOf("heartvoice") !== -1) {
            this.doMobileInqueue.call(this, id, "app");
          } else if (
            navigator.userAgent.indexOf("1docAndroid") !== -1 ||
            navigator.userAgent.indexOf("1docIos") !== -1
          ) {
            this.doMobileInqueue.call(this, id, "1docApp");
          } else {
            this.doMobileInqueue.call(this, id, "whatsapp");
          }
        }
      } catch (error) {
        if (error && error.status_code !== 401) {
          Modal.alert(
            "Complete Profile",
            <div className="complete_Profile">
              Please provide more information about yourself to initiate a
              teleconsultation.{" "}
            </div>,
            [
              {
                text: "Cancel",
                style: { color: "#108ee9" },
                onPress: () => console.log("第0个按钮被点击了"),
              },
              {
                text: "Proceed",
                style: { color: "#108ee9" },
                onPress: () => this.goMyinfo(),
              },
              {
                text: " ",
                style: { display: "none" },
                onPress: () => {
                  console.log("No");
                },
              },
            ]
          );
        }
      }
    }
  };

  checkProfile = () => {
    this.loadingToast();
    return new Promise((resolve, reject) => {
      AuthGuard.checkProfileComplete({}, (isOk, res) => {
        console.log(isOk, res);
        let { drug_allergy, is_complete, source } = res;
        Toast.hide();
        if (isOk) {
          if (is_complete) {
            resolve(res);
          } else {
            this.setState(
              {
                user_drug_allergy: drug_allergy,
                user_source: source,
                user_is_complete: is_complete,
              },
              () => {
                reject(res);
              }
            );
          }
        } else {
          reject(res);
        }
      });
    });
  };

  goMyinfo = () => {
    let hasUrl = window.location.pathname;
    setCache("redirectUrl", hasUrl);
    if (this.state.user_source === "manually") {
      // manually 手动注册的用户
      this.props.history.push("/improve");
    } else {
      // myinfo myinfo注册的用户
      if (!this.state.user_is_complete && this.state.user_drug_allergy) {
        this.props.history.push("/improve");
      } else {
        this.props.history.push("/drugAllergy");
      }
    }
  };

  doMobileInqueue(id, channel) {
    this.loadingToast();
    this.setState({
      consultBtnLoading: true,
    });
    AuthGuard.mobileInqueue(
      {
        doctor_id: id,
        channel: channel,
      },
      (isOk, res) => {
        Toast.hide();
        this.setState({
          consultBtnLoading: false,
        });
        if (isOk) {
          if (res.message !== null) {
            if (isBrowser) {
              Modal.alert(
                "Success",
                <div>Please check your whatsapp for updates!</div>,
                [
                  {
                    text: "OK",
                    onPress: () => console.log("第0个按钮被点击了"),
                  },
                ]
              );
            } else {
              if (channel === "app") {
                window.location.href =
                  "heartvoice://heartvoice/home?action=home";
              } else if (channel === "1docApp") {
                isIOS &&
                  window.webkit.messageHandlers.back_to_app_home.postMessage(
                    ""
                  );
                isAndroid && window.JsToAndroid.back_to_app_home();
              } else {
                Modal.alert(
                  "Success",
                  <div>Please check your whatsapp for updates!</div>,
                  [
                    {
                      text: "OK",
                      onPress: () => {
                        window.location.href =
                          "https://wa.me/" + WHATSAPP_MOBILENO;
                      },
                    },
                  ]
                );
              }
            }
          }
        } else {
          if (res.status_code !== 401) {
            Modal.alert("Oops", <div>{res.message}</div>, [
              { text: "Okay", onPress: () => console.log("第0个按钮被点击了") },
            ]);
          }
        }
      }
    );
  }

  goBack = () => {
    const { prePath } = this.props?.location?.state || {};
    console.log(prePath);
    if (
      navigator.userAgent.indexOf("1docAndroid") !== -1 ||
      navigator.userAgent.indexOf("1docIos") !== -1
    ) {
      if (isIOS) {
        window.webkit.messageHandlers.back_last_page.postMessage("");
      } else if (isAndroid) {
        window.JsToAndroid.back_last_page();
      }
    } else if (prePath === "hsl") {
      this.props.history.goBack();
    } else {
      this.props.history.push("/doctorList");
    }
  };

  goScreeningPage = (iitem, item) => {
    let _this = this;
    AuthGuard.getCPQuestions({}, (isOk, res) => {
      let _pathname = `/doctorScreeningDetails/` + iitem.id;
      if (isOk && res) {
        setCache("source", "DoctorDetails");
        _pathname = `/CPQuestions/dd/${iitem.id}`;
      }
      _this.props.history.push({
        pathname: _pathname,
        state: {
          ...item,
          ...iitem,
        },
      });
    });
  };

  renderPcDoctors = () => {
    return (
      <SwiperCom
        data={this.state.doctorList.filter((x) => x.is_public == "Y")}
        consultFee={{
          consultFeeMin: this.state.teleconsultation_consultation_min_fee,
          consultFeeMax: this.state.teleconsultation_consultation_max_fee,
        }}
        consultBtnLoading={this.state.consultBtnLoading}
        saveDr={this.saveDr.bind(this)}
        bookAppointment={this.bookAppointment.bind(this)}
        consult={this.consult.bind(this)}
      />
    );
  };

  downloadHealthScreen = (url, name) => {
    if (!url) return;
    if (isBrowser) {
      new JsFileDownloader({
        url: url,
        filename: name,
      })
        .then((res) => {})
        .catch((error) => {
          console.log(error);
        });
    } else {
      window.location.href = `heartvoice://heartvoice/home?action=downloadFile&fileUrl=${url}&fileName=${name}`;
    }
  };

  downloadHealthScreenOther = (url, name) => {
    if (!url) return;
    if (isBrowser) {
      window.open(url);
    } else {
      let data = {
        file_url: url,
        file_name: name,
      };
      if (isIOS) {
        window.webkit.messageHandlers.native_download_pdf.postMessage(
          JSON.stringify(data)
        );
      } else if (isAndroid) {
        window.JsToAndroid.native_download_pdf(JSON.stringify(data));
      }
    }
  };

  renderHSDom = () => {
    const { screeningList } = this.state;
    let documents = _.map(screeningList, "document");
    let fistDC = _.head(_.head(documents));

    return (
      <React.Fragment>
        <div className="Health-screening">
          <div className="screening_header">
            <div>Health Screening</div>
            {fistDC &&
              (navigator.userAgent.indexOf("heartvoice") !== -1 ? (
                <Button
                  className="outline-pink-btn"
                  onClick={() =>
                    this.downloadHealthScreen(fistDC?.s3_url, fistDC?.file_name)
                  }
                >
                  {" "}
                  View Package Overview{" "}
                </Button>
              ) : (
                <Button
                  className="outline-pink-btn"
                  onClick={() =>
                    this.downloadHealthScreenOther(
                      fistDC?.s3_url,
                      fistDC?.file_name
                    )
                  }
                >
                  {" "}
                  View Package Overview{" "}
                </Button>
              ))}
          </div>
          <WingBlank size="lg">
            {this.state.hasHSPackage &&
              screeningList &&
              screeningList.map((item) => {
                return item.pacakage.map((iitem) => (
                  <div
                    key={iitem.id}
                    className="health-screening-box"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.goScreeningPage(iitem, item)}
                  >
                    <WhiteSpace size="lg" />
                    <div className="health_screening_left">
                      <h2>{iitem.name}</h2>
                      <span>{item.merchant_name}</span>
                      {iitem.health_screening_type === "corporate" &&
                        !!iitem.patient_type_display && (
                          <p style={{ marginBottom: "0" }}>
                            This Package is for {iitem.patient_type_display}
                          </p>
                        )}
                      <p>{iitem.package_summary ?? ""}</p>
                    </div>
                    <span className="health_screening_right">
                      $
                      {iitem.unit_price
                        ? parseFloat(iitem.unit_price).toFixed(2)
                        : "0.00"}
                    </span>
                  </div>
                ));
              })}
          </WingBlank>
        </div>
        <WhiteSpace size="xs" />
        <WhiteSpace size="xs" />
        <WhiteSpace size="lg" />
      </React.Fragment>
    );
  };

  render() {
    // const slides = Array.from({ length: 10 }).map((el, index) => `Slide ${index + 1}`);
    const {
      country,
      country_code,
      province,
      city,
      postal_code,
      addressId,
      unitNo,
    } = this.state;
    let clinicAddress = "";
    if (country_code === "SG") {
      clinicAddress =
        addressId + " " + unitNo + " " + country + " " + postal_code;
    } else if (country_code) {
      clinicAddress =
        addressId +
        " " +
        unitNo +
        " " +
        country +
        " " +
        province +
        " " +
        city +
        " " +
        postal_code;
    }

    return (
      <>
      <div className="Doctor-Details">
        <LoginModel onRef={this.onRef} history={this.props.history} />
        <ScheduleCalendar onRef={this.onSCRef} />

        <div className="box-shadow">
          {!isBrowser ? (
            <NavBar
              mode="light"
              icon={<Icon type="left" color="#EB5DA6" size="lg" />}
              onLeftClick={() => this.goBack()}
            ></NavBar>
          ) : (
            ""
          )}

          <Tabs
            tabs={this.state.tabs}
            initialPage={this.state.initialPage}
            onChange={(tab, index) => {
              this.setState({ activeKey: index });
            }}
            onTabClick={(tab, index) => {
              this.scrollToAnchor(tab.id, true);
            }}
            page={this.state.activeKey}
            tabBarActiveTextColor={"#E02381"}
            tabBarInactiveTextColor={"#888"}
            tabBarUnderlineStyle={{
              border: "0.04rem solid #E02381",
              maxWidth: "10%",
              marginLeft: this.state.tabMarginLeft,
              backgroundColor: "#E02381",
            }}
          ></Tabs>
        </div>
        <div className="main">
          <div
            id="AboutClinic"
            style={{ height: "1.46rem", width: "100%" }}
            onScrollCapture={() => this.bindHandleScroll()}
          ></div>

          {!isBrowser ? (
            <NavBar
              mode="light"
              icon={<Icon type="left" color="#EB5DA6" size="lg" />}
              onLeftClick={() => this.goBack()}
            ></NavBar>
          ) : (
            <div className="pc-home" style={{ marginTop: "1rem" }}>
              <div className="header">
                <a href="https://www.ourheartvoice.com/">
                  <div className="logo">
                    <img src={logo}></img>
                  </div>
                </a>
                <div className="menu-list" style={{ zIndex: 10 }}>
                  <ul className="menu-ul">
                    {this.state.menu_list.map((item, index) => {
                      return (
                        <li className="menu-ul-li" key={`menu-ul-li${index}`}>
                          <div
                            className={`menu-name ${
                              item.code === "for_patients"
                                ? "active-sub-meue"
                                : ""
                            } ${item.code === "contact" ? "block-div" : ""}`}
                          >
                            {item.href ? (
                              <a href={item.href}> {item.name} </a>
                            ) : (
                              item.name
                            )}
                          </div>
                          {item.children ? (
                            <ul className="menu-sub">
                              {item.children.map((iitem, iindex) => {
                                return (
                                  <li
                                    className="menu-ul-li-li"
                                    key={`menu-ul-li-ul-li-${iitem.code}`}
                                  >
                                    <div
                                      className={`menu-sub-name ${
                                        iitem.code === "heartvoice_telemedicine"
                                          ? "active-sub-meue"
                                          : ""
                                      }`}
                                    >
                                      <a href={iitem.href}>{iitem.name}</a>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          ) : null}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              {/* <NavBar
              mode="light"
              icon={<Icon type="left" color="#EB5DA6" size="lg" />}
              onLeftClick={() => this.goBack()}
            ></NavBar> */}
            </div>
          )}

          <div className="About-clinic-main">
            <div className="About-clinic">
              <Flex className="doctor-clinic">
                <Flex.Item>
                  <WingBlank size="lg">
                    <div className="clinit-title-img">
                      {this.state.clinic_logo ? (
                        <img src={this.state.clinic_logo} />
                      ) : (
                        <div style={{ border: "none" }}>Clinic Logo</div>
                      )}
                    </div>
                    <h1 className="clinic-title">{this.state.clinicName}</h1>
                    <WingBlank size="lg">
                      <div className="clinic-desc">
                        <p className="flGrey PTag">
                          {this.state.introduction &&
                          this.state.introduction.length >= 150 ? (
                            <ViewMore
                              length={150}
                              text={this.state.introduction}
                            />
                          ) : (
                            this.state.introduction
                          )}
                        </p>
                      </div>
                    </WingBlank>
                  </WingBlank>
                </Flex.Item>
              </Flex>
              <WingBlank>
                <WhiteSpace size="lg" />
                <ul className="contanct">
                  <li className="background-ccc flGrey">
                    <Flex.Item>
                      <WingBlank size="lg" className="itemForLi">
                        <p>Contact</p>
                        {clinicAddress && (
                          <p>
                            <img
                              src={location}
                              className="icon-sm"
                              alt="location"
                            />
                            <span> {clinicAddress}</span>
                          </p>
                        )}
                        {this.state.mailId && (
                          <p>
                            <span>
                              <img
                                src={letter}
                                className="icon-sm"
                                alt="letter"
                              />
                            </span>
                            <span> {this.state.mailId} </span>
                          </p>
                        )}
                        {this.state.contactId && (
                          <p>
                            <span>
                              <img
                                src={phone}
                                className="icon-sm"
                                alt="phone"
                              />
                            </span>
                            {this.state.contactId?.length ? (
                              <span>
                                +{this.state.dialingCode} {this.state.contactId}
                              </span>
                            ) : (
                              ""
                            )}
                          </p>
                        )}
                      </WingBlank>
                    </Flex.Item>
                  </li>
                  <li className="background-ccc">
                    <Flex.Item>
                      <WingBlank size="lg" className="itemForLi">
                        <p>Opening Hours:</p>
                        <ul className="opening-hours">
                          {this.state.openingHoursList.length > 0
                            ? this.state.openingHoursList.map((item, index) => {
                                return (
                                  <li key={index}>
                                    <p
                                      className="weekname"
                                      style={{
                                        color:
                                          getWeek() === dayFile(item.day)
                                            ? "#E02381"
                                            : "#000",
                                      }}
                                    >
                                      {dayFile(item.day)}
                                    </p>

                                    {typeof item.data !== "undefined" ||
                                    item.data !== null
                                      ? item.data.map((iitem, iindex) => {
                                          return (
                                            <p key={`clinic-time-${iindex}`}>
                                              <span>{iitem.start}</span>-
                                              <span>{iitem.end}</span>
                                            </p>
                                          );
                                        })
                                      : ""}
                                  </li>
                                );
                              })
                            : ""}
                        </ul>
                      </WingBlank>
                    </Flex.Item>
                  </li>
                </ul>
              </WingBlank>
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <WingBlank size="lg">
                {this.state.doctorList && this.state.doctorList.length > 1 ? (
                  <Flex>
                    <Flex.Item>
                      <p
                        className="flGrey consult-text"
                        style={{ textAlign: "center" }}
                      >
                        Consult any doctor in
                        <br />
                        {this.state.clinicName}
                      </p>
                    </Flex.Item>
                  </Flex>
                ) : (
                  ""
                )}
                {this.state.doctorList &&
                this.state.doctorList.length > 1 &&
                this.state.isShowConsultNow === true ? (
                  <WingBlank size="lg">
                    <Button
                      className="outline-pink-btn consult-btn"
                      activeClassName="outline-pink-active-btn"
                      onClick={this.consultAnyDoctor.bind(
                        this,
                        this.state.identification,
                        "any"
                      )}
                    >
                      CONSULT NOW
                    </Button>
                  </WingBlank>
                ) : (
                  ""
                )}
                {/* {this.state.doctorList && this.state.doctorList.length === 1 ? 
                  <WingBlank size="lg">
                  <Button
                    className="outline-pink-btn consult-btn"
                    activeClassName="outline-pink-active-btn"
                    onClick={this.consultAnyDoctor.bind(this, this.state.identification,'now')}
                  >
                    CONSULT NOW
                    </Button>
                </WingBlank> : ''  
                } */}
              </WingBlank>
              <WhiteSpace size="lg" />
              <div
                id="DoctorsSection"
                style={{ height: "2.2rem", width: "100%" }}
                onScrollCapture={() => this.bindHandleScroll()}
              ></div>
            </div>
          </div>

          <div className="Doctors-section-main">
            {/*  one doctor card---start*/}
            <div className="Doctors-section">
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <WhiteSpace size="xs" />
              <div
                className="doctors-section-block"
                style={{
                  marginBottom: this.state.doctorList.length < 4 ? "15px" : "0",
                }}
              >
                <div className="doctors_doctors">Providers</div>
                <div className="doctors_title" style={{ marginBottom: "15px" }}>
                  Our list of providers offering telemedicine service
                </div>
              </div>
              <WingBlank
                className="doctor_list"
                size="lg"
                style={{ display: "flex", justifyContent: "center" }}
              >
                {isMobile ? (
                  <div className="scroll-box">
                    <div className="scroll-main">
                      {this.state.doctorList.length > 0
                        ? this.state.doctorList.map((item, index) => {
                            if (item.is_public == "N") {
                              return "";
                            }
                            let _specialty = "-";
                            let _specialtyArr = [];
                            if (item.specialty && item.specialty.length > 0) {
                              item.specialty.map((item, index) => {
                                _specialtyArr.push(item.display_name);
                              });
                              _specialty = _specialtyArr.join(" / ");
                            }
                            var isThereNoLeadTime = false;
                            if (
                              item.lead_time === undefined ||
                              item.lead_time === null ||
                              item.lead_time === 0 ||
                              item.lead_time === "" ||
                              item.lead_time === "0"
                            ) {
                              isThereNoLeadTime = true;
                            }
                            return (
                              <div
                                className="doctors-section-card"
                                key={item.id}
                                id={item.id}
                              >
                                <WingBlank size="lg">
                                  <div
                                    className="icon-save"
                                    onClick={this.saveDr.bind(
                                      this,
                                      item.id,
                                      item.saved
                                    )}
                                  >
                                    <img
                                      src={
                                        item.saved === "saved"
                                          ? iconUnSaveDoctor
                                          : iconSaveDoctor
                                      }
                                      alt=""
                                    />
                                    <p className="flPink">
                                      {item.saved === "saved"
                                        ? "Unsave"
                                        : "Save"}
                                    </p>
                                  </div>
                                  <img
                                    src={
                                      item.photo && item.photo.url
                                        ? item.photo.url.l
                                        : iconDoctorAvatar
                                    }
                                    className="doctor-img"
                                    alt="doctor_photo"
                                  />
                                  <p
                                    className="fb"
                                    style={{ wordBreak: "break-word" }}
                                  >
                                    {item.doctorinfo.name}
                                  </p>
                                  <p className="flGrey">
                                    {item.specialty &&
                                    item.specialty.length >= 10 ? (
                                      <ViewMore
                                        length={150}
                                        text={_specialty}
                                      />
                                    ) : (
                                      _specialty
                                    )}
                                  </p>
                                  <WhiteSpace size="lg" />
                                  <p style={{ wordBreak: "break-word" }}>
                                    {item.professional_profile &&
                                    item.professional_profile.length >= 150 ? (
                                      <ViewMore
                                        length={150}
                                        text={item.professional_profile}
                                      />
                                    ) : (
                                      item.professional_profile
                                    )}
                                  </p>
                                  <WhiteSpace size="lg" />
                                </WingBlank>
                                <div className="opening-hours">
                                  <WingBlank>
                                    <div className="background-ccc">
                                      <Flex>
                                        <ul>
                                          {item.doctor_opening_time &&
                                          item.doctor_opening_time.length > 0
                                            ? item.doctor_opening_time.map(
                                                (dayI, index) => {
                                                  return (
                                                    <li key={index}>
                                                      <p
                                                        className="weekname"
                                                        style={{
                                                          color:
                                                            getWeek() ===
                                                            dayFile(dayI.day)
                                                              ? "#E02381"
                                                              : "#000",
                                                        }}
                                                      >
                                                        {dayFile(dayI.day)}
                                                      </p>
                                                      {dayI.data.map(
                                                        (iitem, iindex) => {
                                                          return (
                                                            <p
                                                              key={`clinic-time-${iindex}`}
                                                            >
                                                              <span>
                                                                {iitem.start}
                                                              </span>
                                                              -
                                                              <span>
                                                                {iitem.end}
                                                              </span>
                                                            </p>
                                                          );
                                                        }
                                                      )}
                                                    </li>
                                                  );
                                                }
                                              )
                                            : ""}
                                        </ul>
                                      </Flex>
                                    </div>
                                  </WingBlank>
                                </div>
                                <WhiteSpace size="lg" />
                                {item.doctor_opening_time &&
                                  item.doctor_opening_time.length > 0 && (
                                    <>
                                      <WingBlank size="lg">
                                        <div className="dr-extr">
                                          <p className="flPink">
                                            Consultation Fee:
                                            {this.state
                                              .teleconsultation_consultation_min_fee &&
                                            this.state
                                              .teleconsultation_consultation_max_fee
                                              ? " " +
                                                currencyType(
                                                  this.state
                                                    .teleconsultation_consultation_min_fee,
                                                  this.state
                                                    .teleconsultation_currency_code
                                                ) +
                                                " - " +
                                                currencyType(
                                                  this.state
                                                    .teleconsultation_consultation_max_fee,
                                                  this.state
                                                    .teleconsultation_currency_code
                                                )
                                              : item.consultation_fee
                                              ? " " +
                                                currencyType(
                                                  item.consultation_fee,
                                                  this.state
                                                    .teleconsultation_currency_code
                                                )
                                              : " --"}
                                          </p>
                                          <p className="flGrey">
                                            Send "hello{item.identification}" to
                                            +{WHATSAPP_MOBILENO}
                                          </p>
                                          <p className="flGrey">
                                            to book a consultation.{" "}
                                          </p>
                                        </div>
                                      </WingBlank>
                                      <WhiteSpace size="lg" />
                                      <WhiteSpace size="xs" />
                                      <WingBlank size="lg">
                                        <WingBlank size="lg">
                                          <div className="book-consult">
                                            {item.show_book_visit && (
                                              <>
                                                <Button
                                                  className="consult-img fill-pink-btn"
                                                  onClick={this.bookAppointment.bind(
                                                    this,
                                                    item.id,
                                                    "visit"
                                                  )}
                                                >
                                                  {" "}
                                                  BOOK A VISIT
                                                </Button>
                                              </>
                                            )}
                                            {item.show_book_teleconsult && (
                                              <>
                                                <WhiteSpace size="lg" />
                                                <Button
                                                  className="consult-img outline-pink-btn"
                                                  onClick={this.bookAppointment.bind(
                                                    this,
                                                    item.id,
                                                    "teleconsult"
                                                  )}
                                                >
                                                  {" "}
                                                  BOOK TELECONSULT
                                                </Button>
                                              </>
                                            )}

                                            {item.show_consult_now ? (
                                              <>
                                                <WhiteSpace size="lg" />
                                                <Button
                                                  loading={
                                                    this.state.consultBtnLoading
                                                  }
                                                  disabled={
                                                    this.state.consultBtnLoading
                                                  }
                                                  className="consult-img outline-pink-btn"
                                                  onClick={this.consult.bind(
                                                    this,
                                                    item.identification,
                                                    item.id
                                                  )}
                                                >
                                                  TELECONSULT NOW
                                                </Button>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </WingBlank>
                                      </WingBlank>
                                      <WhiteSpace size="lg" />
                                      <WhiteSpace size="xs" />
                                    </>
                                  )}
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                ) : this.state.doctorList.length > 0 ? (
                  this.renderPcDoctors()
                ) : (
                  ""
                )}
              </WingBlank>
              <WhiteSpace size="lg" />
              <div
                id="HealthProgram"
                style={{ height: "1.46rem", width: "100%" }}
                onScrollCapture={() => this.bindHandleScroll()}
              ></div>
            </div>
            {/*  one doctor card---end*/}
          </div>
          {this.state.programList ? (
            <div className="Health-program-main">
              <div className="Health-program" id="HealthProgram">
                <WhiteSpace size="lg" />
                <WingBlank size="lg">
                  <div className="doctors-section-block">
                    <div className="doctors_doctors">Health Program</div>
                    <div className="doctors_title">
                      Our remote patient monitoring program helps you share your
                      health information easily with Providers for follow up
                      consultation
                    </div>
                  </div>
                  <WhiteSpace size="lg" />
                  <WhiteSpace size="lg" />
                  <WhiteSpace size="lg" />
                  <WhiteSpace size="lg" />
                  <div
                    className="scroll-box"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div className="scroll-main">
                      {this.state.programList &&
                      this.state.programList.length > 0
                        ? this.state.programList.map((item, index) => {
                            return (
                              <div
                                className="health-program-card"
                                key={item.id}
                              >
                                <div className="img-bg">
                                  <WhiteSpace size="lg" />
                                  <WhiteSpace size="lg" />
                                  <WingBlank size="lg">
                                    <WingBlank size="lg">
                                      <img
                                        className="banner-nobg"
                                        src={
                                          item.image &&
                                          item.image.url &&
                                          item.image.url.o
                                            ? item.image &&
                                              item.image.url &&
                                              item.image.url.o
                                            : banner_nobg
                                        }
                                        alt={this.state.alt}
                                      />
                                    </WingBlank>
                                  </WingBlank>
                                  <WhiteSpace size="lg" />
                                </div>
                                <WhiteSpace size="lg" />
                                <h2> {item.name} </h2>
                                <WhiteSpace size="lg" />
                                <WingBlank size="lg">
                                  <Flex>
                                    <img
                                      className="close-red"
                                      src={close_red}
                                      alt="close_red"
                                    />
                                    <Flex.Item>
                                      <h2>Chat</h2>
                                      <p className="flGrey">
                                        In-App messaging using
                                      </p>
                                      <p className="flGrey">
                                        HeartVoice Application
                                      </p>
                                    </Flex.Item>
                                  </Flex>
                                </WingBlank>
                                <div className="background-ccc">
                                  <WhiteSpace size="lg" />
                                  <WingBlank size="lg">
                                    <WingBlank size="lg">
                                      <h2 style={{ textAlign: "left" }}>
                                        Whats It Includes
                                      </h2>
                                      <WhiteSpace size="lg" />
                                      <p className="flGrey">
                                        -High Blood Pressure(Hypertension), High
                                        Cholesterol(Hyperlipidemia), Blood
                                        Sugar(Diabetes){" "}
                                      </p>
                                      <p className="flGrey">
                                        -Regular review of chronic disease
                                        parameters
                                      </p>
                                      <p className="flGrey">
                                        -Customised weight /diet plans
                                      </p>
                                      <p className="flGrey">
                                        -Teleconsultation/In-Person consultation
                                      </p>
                                      <p className="flGrey">
                                        -Medication Delivery to your door step
                                      </p>
                                      <p className="flGrey">
                                        -purchase of connected devices
                                      </p>
                                    </WingBlank>
                                  </WingBlank>
                                  <WhiteSpace size="lg" />
                                </div>
                                <WhiteSpace size="lg" />
                                <WhiteSpace size="lg" />
                                <WingBlank size="lg">
                                  <WingBlank size="lg">
                                    {item.purchase_type == "Request" ? (
                                      <Button
                                        className="request-img fill-pink-btn"
                                        onClick={() =>
                                          this.requestCode(item.id,{url: item?.program_file_url || "",title: item?.program_file || ""})
                                        }
                                      >
                                        Request
                                      </Button>
                                    ) : item.purchase_type == "Requested" ? (
                                      <Button className="request-img fill-gray-btn">
                                        <img src={success_icon} /> Requested!
                                      </Button>
                                    ) : item.purchase_type == "Joined" ? (
                                      <Button className="request-img fill-gray-btn">
                                        <img src={success_icon} /> Joined
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                    <p className="program_message">
                                      {this.state.errorReuqestMsg.id == item.id
                                        ? this.state.errorReuqestMsg.message
                                        : ""}
                                    </p>
                                  </WingBlank>
                                </WingBlank>
                                <WhiteSpace size="lg" />
                                <WhiteSpace size="lg" />
                                <WingBlank size="lg">
                                  <WingBlank size="lg">
                                    {item.purchase_type == "Request" ? (
                                      <p className="flGrey bottomP">
                                        Click request to find out more
                                        information on the specifics of clinic
                                        offering and pricing. The clinic will be
                                        in touch shortly.
                                      </p>
                                    ) : item.purchase_type == "Requested" ? (
                                      <p className="flGrey bottomP">
                                        You have successfully requested for
                                        monitoring program. The clinic will be
                                        in touch shortly. You will receive a
                                        confirmation email when you can start
                                        the program.
                                      </p>
                                    ) : item.purchase_type == "Joined" ? (
                                      <p className="flGrey bottomP">
                                        You have successfully requested for
                                        monitoring program. The clinic will be
                                        in touch shortly. You will receive a
                                        confirmation email when you can start
                                        the program.
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </WingBlank>
                                </WingBlank>
                                <WhiteSpace size="lg" />
                                <WhiteSpace size="lg" />
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                  <WhiteSpace size="lg" />
                  <WhiteSpace size="lg" />
                  <WhiteSpace size="lg" />
                  <WhiteSpace size="lg" />
                </WingBlank>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="Health-screening-main">
            <div
              id="HealthScreening"
              style={{ height: "0.2rem", width: "100%" }}
              onScrollCapture={() => this.bindHandleScroll()}
            ></div>
            {this.renderHSDom()}
          </div>
        </div>
      </div>
      <Modal
          className="program-tc-modal"
          visible={this.state.program_open}
          transparent
          onClose={()=>{this.setState({...this.state, program_open: false, program_request_id: "", program_file: {} })}}
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
          >
          <div style={{padding: '20px'}}>
              <div style={{fontSize: 22, color: 'black',fontWeight: 'bolder'}}>Terms & Conditions</div>
              <WhiteSpace />
              <div>To view the full document, please tap <span style={{color: '#c5427e',textDecoration: 'underline'}} onClick={()=>{viewFile(this.state.program_file,"native_open_web")}}>here</span>.</div>
              <WhiteSpace />
              <div className="footerBtns">
                  <Button
                      className="outline-pink-btn"
                      style={{
                      fontFamily: "Montserrat-Regular",
                      fontSize: 17,
                      fontWeight: 600,
                      width: '45%'
                      }}
                      onClick={()=>{this.setState({...this.state, program_open: false, program_request_id: "", program_file: {}})}}
                  >
                      Go Back
                  </Button>
                  <Button
                      style={{
                          background:
                          "linear-gradient(135deg, #E02381 0%, #C23579 100%) ",
                          color: "#fff",
                          fontFamily: "Montserrat-Regular",
                          fontSize: 17,
                          fontWeight: 600,
                          width: '45%'
                      }}
                      onClick={() => this.requestCode(this.state.program_request_id)}
                      >
                      I Agree
                  </Button>
              </div>
          </div>
      </Modal>
      </>
    );
  }
}

export default DoctorDetails;
