import React from 'react';

import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import theme from './theme';
import './types';
import { VideoProvider } from './components/VideoProvider';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
//import {TrackNameInvalidError} from "twilio-video";
// import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';

const VideoApp = ({t, e}: {t: any, e: any}) => {
  const { error, setError } = useAppState();
  const { token, setToken } = useAppState();
  const connectionOptions = useConnectionOptions();

  if (token !== t) {
      setToken(t)
    // setError({message: "", name: "", stack: "", code: 1, toString: () => 'err'})
  }

  return (
    // <UnsupportedBrowserWarning>
      <VideoProvider options={connectionOptions} onError={setError} endCall={e}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <App />
      </VideoProvider>
    // </UnsupportedBrowserWarning>
  );
};

export default function TwilioVideo({token, endCall}: {token: any, endCall: any}) {
  if (!token) {
    return <></>
  }
  return (
    <MuiThemeProvider theme={theme}>
      <AppStateProvider>
        <VideoApp t={token} e={endCall}/>
      </AppStateProvider>
    </MuiThemeProvider>
  );
}