import React, { Component } from 'react';
import { Modal, Toast, Button, Flex, InputItem } from 'antd-mobile';
import AuthGuard from '../services/AuthGuard';
import LoginModel from '../component/LoginModel';
import { isMobile, isAndroid } from "react-device-detect";
import { getCache, copyClipboard, getValdots } from '../commons/Helpers';
import DatePicker from '../component/DatePicker';
import moment from 'moment';
import _ from 'lodash';
import url from 'url';
import DatePickerBor from "react-datepicker";
import { isBrowser } from "react-device-detect";
import calendar from '../images/icons/ic_status_calendar_pink.png'

class CPQuestions extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            preState: {...props.location.state},
            type: props.match.params.type,
            id: props.match.params.id,
            token: props.match.params.token,
            questions:[],
            datePicker: {
                key: '',
                value: new Date(),
                isOpen: false,
                min: undefined,
                max: undefined,
            },
            disabled_confirm: true,
            errMsg:{},
        }
    }

    async componentDidMount() {                
        
        // let miu = url.parse(this.props.location.search, true);
        // setState({ id:miu.query.cid });
        if(getCache("source_back")){
            this.props.history.goBack();
            return;
        }
        if (this.state.token) await AuthGuard.getNewToken(this.state.token)

        this.initHSData()
    }

    redirectPage = () => {
        const { type, id} = this.state;
        let _pathname = `/DoctorDetails/${id}`;
        if(navigator.userAgent.indexOf('heartvoice') !== -1){
            if(type === 't'){
                window.location.href = 'heartvoice://heartvoice/home?action=completeHSEventQuestion'
            }else{
                if(type === 'dd'){
                    _pathname = `/doctorScreeningDetails/${id}`;
                }    
                this.props.history.push({ pathname: _pathname, state: this.state.preState });
            }
        }else{
            if(type === 'dd'){
                _pathname = `/doctorScreeningDetails/${id}`;
            }
            this.props.history.push({ pathname: _pathname, state: this.state.preState });
        }
    }

    initHSData(){
        this.loadingToast()
        let _this = this
        AuthGuard.getCPQuestions({}, (isOk, res)=>{
            Toast.hide()
            if(isOk && res.length > 0){
                res && res.map((row, k)=>{
                    res[k] = {
                        ...res[k],
                        question_value: ''
                    }
                    if(row.field_type === 'dropdown_list'){
                        let listValue = row.dropdown_list_value && JSON.parse(row.dropdown_list_value);
                        let defaultVal = listValue.value;
                        if(defaultVal){
                            res[k] = {
                                ...res[k],
                                question_value: defaultVal
                            }
                        }
                    }
                });
                _this.setState({
                    questions: res,
                    disabled_confirm: false
                })
            }else{
                _this.redirectPage()
            }
        })
    }

    renderAlertTilte = ()=>{
      return <div><span>Welcome to</span><br/><span>the Registration Portal!</span></div>
    }

    loadingToast() {
        Toast.loading('Loading...', 30, () => {
            console.log('Load complete !!!');
        });
    }

    goHome(){        
        window.location.href = "https://www.iwell.sg";
    }

    onRef = (ref) => {
        this.loginModel = ref
    }

    stopScroll(e) {
        e.stopPropagation();
        e.preventDefault();
    }
    openDatePicker = (e) => {
        e.stopPropagation();

        let id = e.target.dataset.id;
        this.cleanErr(id, 'date');
        let key = e.target.dataset.no;
        let value = this.state.questions[key].question_value;
        if(value){
            value = new Date(value)
        }
        // let min = new Date(1900, 1, 1);
        // let max = new Date(2050, 1, 1);
        let min = undefined;
        let max = undefined;
        // check min/max
        let t = this.state.questions[key].date_lightbox
        switch(t){
            case "future":
                min = new Date();
            break;
            case "past":
                max = new Date();
            break;
        }
        let newDate = {
            key: key,
            value: value || undefined,
            isOpen: true,
            min: min,
            max: max,
        }
        this.setState({datePicker: newDate})

        document.body.addEventListener("touchmove", this.stopScroll, { passive: false });
        document.body.style.overflow = 'hidden';
    }

    openDatePickerIsBrower = (id, no) => {
        this.cleanErr(id, 'date');
        let key = no;
        let value = this.state.questions[key].question_value;
        if(value){
            value = new Date(value)
        }
        // let min = new Date(1900, 1, 1);
        // let max = new Date(2050, 1, 1);
        let min = undefined;
        let max = undefined;
        // check min/max
        let t = this.state.questions[key].date_lightbox
        switch(t){
            case "future":
                min = new Date();
            break;
            case "past":
                max = new Date();
            break;
        }
        let newDate = {
            key: key,
            value: value || undefined,
            isOpen: false,
            min: min,
            max: max,
        }
        this.setState({datePicker: newDate})
    }

    handleSelect = (e) => {
        let value = moment(e).format("YYYY-MM-DD");
        let key = this.state.datePicker.key;
        let questions = [...this.state.questions]
        questions[key] = {...questions[key], question_value: value}
        this.setState({
            datePicker:{...this.state.datePicker, isOpen: false},
            questions: questions
        })

        document.body.removeEventListener('touchmove', this.stopScroll);
        //添加事件监听时添加了passive参数，在ios9中移除事件监听也需要加此参数
        document.body.removeEventListener('touchmove', this.stopScroll, { passive: true });
        document.body.style.overflow = 'auto';
    }

    handleSelectIsBower = (e) => {
        let value = moment(e).format("YYYY-MM-DD");
        let key = this.state.datePicker.key;
        let questions = [...this.state.questions]
        questions[key] = {...questions[key], question_value: value}
        this.setState({
            datePicker:{...this.state.datePicker, isOpen: false},
            questions: questions
        })
    }

    handleCancel = () => {
        this.setState({
            datePicker:{...this.state.datePicker, isOpen: false}
        })

        document.body.removeEventListener('touchmove', this.stopScroll);
        //添加事件监听时添加了passive参数，在ios9中移除事件监听也需要加此参数
        document.body.removeEventListener('touchmove', this.stopScroll, { passive: true });
        document.body.style.overflow = 'auto';
    }

    changeQuestions = (index, value) => {
        let questions = [
            ...this.state.questions
        ]
        questions[index] = {
            ...questions[index],
            question_value: value
        }
        this.setState({questions})
    }

    handleConfirm = () => {
        this.loadingToast()
        let err = {}
        this.state.questions.map(item=>{
            if(item.is_required === "Y" && !item.question_value){
                err[item.id] = "Please fill in the required field";
            }else{
                delete err[item.id]
            }
        })
        this.setState({errMsg:err}, ()=>{
            if(Object.keys(this.state.errMsg).length === 0){
                let params = { question: JSON.stringify(this.state.questions) }
                let _this = this;
                AuthGuard.saveCPQuestions(params, (isOk, res)=>{
                    Toast.hide()
                    if(isOk){
                        _this.redirectPage()
                    }
                })
            }else{
                Toast.hide()
                // this.alertMsg(errMsg)
            }
        });
    }

    cleanErr = (key, value) => {
        if(value !== ''){
            let err = {
                ...this.state.errMsg
            }
            delete err[key]
            this.setState({errMsg:err})
        }
    }

    render() { 
        return (
            <div className="CPQuestions">
              <LoginModel onRef={this.onRef} history={this.props.history} />
                <h4 className="title">Health Screening</h4>
                <p className="sub-title my-3">Please fill in your details below before proceeding!</p>
                <div className="row">
                    {this.state.questions && this.state.questions.map((item, index) => {
                        switch(item.field_type){
                            case "text_box":
                                return <div key={index} className="col my-3">
                                    <p className="label">{item?.question_name} {item.is_required === 'Y' && '*'}</p>
                                    <div className="form-box">
                                        <input 
                                            className="form-control"
                                            type="text"
                                            value={this.state.questions[index]?.question_value}
                                            onChange={
                                                (e)=>{
                                                    this.cleanErr(item.id, e.target.value)
                                                    this.changeQuestions(index, e.target.value)
                                                }
                                            }
                                        />
                                    </div>
                                    {!!this.state.errMsg[item.id] && <div className="errorMsg">{this.state.errMsg[item.id]}</div>}
                                </div>
                            break;
                            case "numeric_text_box":
                                return <div key={index} className="col my-3">
                                    <p className="label">{item?.question_name} {item.is_required === 'Y' && '*'}</p>
                                    <div className="form-box">
                                        <input 
                                            className="form-control"
                                            type="tel"
                                            value={this.state.questions[index]?.question_value}
                                            onChange={
                                                (e)=>{
                                                    this.cleanErr(item.id, e.target.value)
                                                    let val = getValdots(e.target.value, 2);
                                                    this.changeQuestions(index, val)
                                                }
                                            }
                                        />
                                    </div>
                                    {!!this.state.errMsg[item.id] && <div className="errorMsg">{this.state.errMsg[item.id]}</div>}
                                </div>
                            break;
                            case "date":
                                return <div key={index} className="col my-3">
                                    <p className="label">{item?.question_name} {item.is_required === 'Y' && '*'}</p>
                                    <div className="form-box">
                                    {isBrowser ? (
                                        <div className='calender-input'>
                                            <DatePickerBor
                                                selected={this.state.questions[index]?.question_value ? new Date(this.state.questions[index]?.question_value) : ''}
                                                onInputClick={()=>{
                                                    this.openDatePickerIsBrower(item?.id, index);
                                                }}
                                                onChange={(date) => {
                                                    this.handleSelectIsBower(date);
                                                }}
                                                dateFormat={"dd-MM-yyyy"}
                                                dateFormatCalendar={"MMM yyyy"}
                                                minDate={this.state.datePicker.min ? new Date(this.state.datePicker.min) : ""}
                                                maxDate={this.state.datePicker.max ? new Date(this.state.datePicker.max) : ""}
                                                className='datePickerCalendarPCInput'
                                                calendarClassName="datePickerCalendarPC"
                                                placeholderText={"DD-MM-YYYY"}
                                                showMonthDropdown
                                                showYearDropdown
                                            />
                                            <span><img src={calendar} style={{ width: '1em', marginRight: '1em' }} alt="" /></span>
                                        </div>
                                    ) : (
                                        <div 
                                            className={`cursor bg-date form-control ${!this.state.questions[index]?.question_value && "color-gray"}`}
                                            data-no={index}
                                            data-id={item.id}
                                            onClick={this.openDatePicker.bind(this)}
                                        >
                                            {(this.state.questions[index]?.question_value && moment(this.state.questions[index]?.question_value).format('DD-MM-YYYY')) || 'DD-MM-YYYY'}
                                        </div>
                                    )}
                                    </div>
                                    {!!this.state.errMsg[item.id] && <div className="errorMsg">{this.state.errMsg[item.id]}</div>}
                                </div>
                            break;
                            case "dropdown_list":
                                let listValue = item.dropdown_list_value && JSON.parse(item.dropdown_list_value);
                                let list = listValue.list && listValue.list.split('^');
                                return <div key={index} className="col my-3">
                                    <p className="label">{item?.question_name} {item.is_required === 'Y' && '*'}</p>
                                    <div className="form-box">
                                        <select 
                                            key={index} 
                                            className="form-control form-select"
                                            onChange={(e) => {
                                                this.cleanErr(item.id, e.target.value)
                                                this.changeQuestions(index, e.target.value)
                                            }}
                                            value={this.state.questions[index]?.question_value}
                                        >
                                            <option key={0} value=""></option>
                                            {list.map((op, k)=>{
                                                return <option key={k+1} value={op}>{op}</option>
                                            })}
                                        </select>
                                    </div>
                                    {!!this.state.errMsg[item.id] && <div className="errorMsg">{this.state.errMsg[item.id]}</div>}
                                </div>
                            break;
                        }
                    })}
                    
                </div>
                <div className="confirm-box">
                    <Button
                        className="use-coupon-btn fill-pink-btn"
                        onClick={this.handleConfirm}
                        disabled={this.state.disabled_confirm}
                    >
                        Confirm
                    </Button>
                </div>
                <DatePicker
                    mode="date"
                    showHeader={false}
                    value={this.state.datePicker.value}
                    isOpen={this.state.datePicker.isOpen}
                    onSelect={this.handleSelect}
                    onCancel={this.handleCancel}
                    min={this.state.datePicker.min}
                    max={this.state.datePicker.max}
                >
                </DatePicker>
            </div>
        ); 
    }   
}

export default CPQuestions;