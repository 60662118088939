import React, { useState, useEffect } from "react";
import {
  Flex,
  WhiteSpace,
  WingBlank,
  Toast,
  Button,
  Modal,
  NavBar,
  Icon,
} from "antd-mobile";
import { isBrowser } from "react-device-detect";
import { useHistory } from "react-router-dom";
import {
  showDate,
  currencyType,
  getCache,
  setCache,
  removeCache,
} from "../commons/Helpers";
import { QRCode } from "react-qrcode-logo";
import Logo from "../images/icons/Paynow(White Bg).jpg";
import html2canvas from "html2canvas";
import AuthGuard from "../services/AuthGuard";
import { WHATSAPP_MOBILENO } from "../commons/Constants";

const PayNow = (props) => {
  let timer = null;
  const history = useHistory();
  const [paymentInfo, setPaymentInfo] = useState({
    total: 0,
    refNo: "",
    recipient: "",
    paymentDueData: "",
    backPath: "",
    qrcode: "",
  });
  let isInIOS = navigator.userAgent.indexOf("1docIos") !== -1;
  let isInAndroid = navigator.userAgent.indexOf("1docAndroid") !== -1;
  let in1DocApp = isInIOS || isInAndroid;

  useEffect(() => {
    let payNowInfo = getCache("payNowInfo");
    if (payNowInfo) {
      payNowInfo = JSON.parse(payNowInfo);
    }
    setPaymentInfo(payNowInfo);
    // let step = 6
    timer = setInterval(() => {
      // step--
      // console.log(step);
      getStatus(payNowInfo);
      // if (step <= 0) {
      // clearInterval(timer)
      // }
    }, 10000);
    return () => {
      clearInterval(timer);
      setTimeout(() => {
        removeCache("payNowInfo");
      }, 1000);
    };
  }, []);
  function getStatus(payNowInfo) {
    AuthGuard.getTransactionStatus({ id: payNowInfo.id }, (isOk, res) => {
      if (isOk) {
        let { status_code } = res;
        if (status_code == "completed") {
          props.history.push({ pathname: payNowInfo.backPath });
          setTimeout(() => {
            removeCache("payNowInfo");
          }, 1000);
        }
      } else {
        clearInterval(timer);
      }
    });
  }
  // function paymentBill(data) {
  // 	loadingToast()
  // 	AuthGuard.noFeePay({
  // 		userQueueId: id,
  // 		recipientAddressId: data?.recipientAddressId,
  // 		deliveryServiceId: data?.deliveryServiceId,
  // 		totalMoney: data?.totalAmount,
  // 		couponId: data?.couponId || '',
  // 		deleteMedicationItemIds: data?.deleteMedicationItemIds ? data?.deleteMedicationItemIds : '',
  // 		paymentMode: 'PN1'
  // 	}, (isOk, res) => {
  // 		console.log('nnnnn=-->', res,isOk);
  // 		if (isOk) {
  // 			let { param } = res.paymentRequest
  // 			let transaction_id = res.transaction.id;
  // 			setCache('bill_info', { transaction_id: transaction_id, userQueueId: id })
  // 			setPaymentInfo({
  // 				...paymentInfo,
  // 				total: param?.QR_CODE?.amount,
  // 				refNo: param?.REFERENCE_NO,
  // 				recipient: param?.QR_CODE?.payee_name,
  // 				paymentDueData: param?.TIME,
  // 				qrcode:param?.QR_CODE?.qr_code
  // 			})
  // 		} else {
  // 			// removeCache('bill_info');
  // 			setCache('bill_info', { transaction_id: '', userQueueId: id })
  // 			// props.history.push({ pathname: "/fail" });
  // 		}
  // 		removeCache('billInfo')
  // 		Toast.hide()
  // 	})
  // }

  const saveQrCode = () => {
    if (in1DocApp) {
      let qrcode = paymentInfo?.qrcode || "";
      console.log("qrcode", qrcode);
      console.log("in1DocApp", in1DocApp ? "in1DocApp" : "no in app");
      if (isInIOS) {
        window.webkit.messageHandlers.native_save_qrcode.postMessage({
          qrcode: qrcode,
        });
      } else if (isInAndroid) {
        window.JsToAndroid.native_save_qrcode(
          JSON.stringify({ qrcode: qrcode })
        );
      }
    } else {
      html2canvas(document.querySelector("#react-qrcode-logo")).then(
        (canvas) => {
          const link = document.createElement("a");
          link.download = "qrcode.png";
          link.href = canvas.toDataURL();
          link.click();
        }
      );
    }
  };
  const formatDate = (a) => {
    return `${a.slice(0, 4)}-${a.slice(4, 6)}-${a.slice(6, 8)} ${a.slice(
      8,
      10
    )}:${a.slice(10)}`;
  };
  const goBack = () => {
    if (paymentInfo?.backToWa) {
      window.location.href = "https://wa.me/" + WHATSAPP_MOBILENO;
      return;
    }
    if (getCache("clinic_id")) {
      props.history.push("/DoctorDetails/" + getCache("clinic_id") + "?tab=3");
    }
  };

  return (
    <div className="Paymet-Page pay-page">
      {!isBrowser && !in1DocApp ? (
        <NavBar
          mode="light"
          icon={<Icon type="left" color="#EB5DA6" size="lg" />}
          onLeftClick={goBack}
        ></NavBar>
      ) : (
        ""
      )}
      <WingBlank size="lg">
        <Flex>
          <Flex.Item>
            <h2>Payment</h2>
          </Flex.Item>
        </Flex>
        <WhiteSpace />
        <WhiteSpace />
        <div className="content">
          <Flex justify="between">
            <Flex.Item>
              <div>Total Amount Payable:</div>
            </Flex.Item>
            <Flex.Item align={"end"}>
              <div>${currencyType(paymentInfo.total)}</div>
            </Flex.Item>
          </Flex>
          <WhiteSpace />
          <Flex justify="between">
            <Flex.Item>
              <div>Reference No:</div>
            </Flex.Item>
            <Flex.Item align={"end"}>
              <div>{paymentInfo.refNo}</div>
            </Flex.Item>
          </Flex>
          <WhiteSpace />
          <Flex justify="between">
            <Flex.Item>
              <div>Recipient:</div>
            </Flex.Item>
            <Flex.Item align={"end"}>
              <div>{paymentInfo.recipient}</div>
            </Flex.Item>
          </Flex>
          <WhiteSpace />
          <Flex justify="between">
            <Flex.Item>
              <div>Payment Due Date:</div>
            </Flex.Item>
            <Flex.Item align={"end"}>
              <div>
                {showDate(formatDate(paymentInfo.paymentDueData + ""), 13)}
              </div>
            </Flex.Item>
          </Flex>
          <WhiteSpace />
          <Flex justify="between">
            <Flex.Item>
              <div>Payment Method:</div>
            </Flex.Item>
            <Flex.Item align={"end"}>
              <div>PayNow(Via Heartvoice)</div>
            </Flex.Item>
          </Flex>
          <WhiteSpace />
          <div
            style={{ borderTop: "1px solid #ccc", margin: "0 0.5rem" }}
          ></div>
          <WhiteSpace />
          <Flex align="center" justify="center">
            {paymentInfo?.qrcode && (
              <QRCode
                value={paymentInfo?.qrcode}
                logoImage={Logo}
                fgColor="#7F2674"
                logoWidth={30}
                size={250}
              />
            )}
          </Flex>
          <Flex justify="center">
            {paymentInfo?.qrcode && (
              <Button
                className="outline-pink-btn save_qr"
                activeClassName="fill-pink-active-btn"
                onClick={saveQrCode}
              >
                Save QR Code
              </Button>
            )}
          </Flex>
          <div
            style={{ borderBottom: "1px solid #ccc", margin: "0 0.5rem" }}
          ></div>
          <WhiteSpace size="lg" />
          <Flex>
            <Flex.Item>
              <h3>Instructions</h3>
              <ul className="num_ul">
                <li>
                  Save or screenshot the QR code to make payments using PayNow
                  supporting applications.
                </li>
                <li>Review and confirm payment details.</li>
                <li>
                  Payment will reflect within 30 minutes to an hour. Otherwise,
                  please contact our helpdesk at3129 2937
                </li>
                <li>
                  Please complete payment within{" "}
                  <span style={{ color: "red" }}>30 minutes</span> or the QR
                  code will expire
                </li>
              </ul>
            </Flex.Item>
          </Flex>
        </div>
      </WingBlank>
    </div>
  );
};

export default PayNow;
