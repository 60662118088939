import React, { Component } from 'react'
import { sliceEnglish } from '../commons/Helpers';

export class ViewMore extends Component {
    constructor(props) {
        super(props);
        this.state ={
            show: false,
            more: false
        }
    }
    
    viewMore = ()=>{
        this.setState({
            more:  !this.state.more
        })
    }

    render() {
        let { text ,length } = this.props
        let { more } = this.state
        let htmlP = more ? text : sliceEnglish(text,length)
        
        return (
            <>
                {htmlP}<a className="viewMoreBtn fbPink" onClick={this.viewMore}>{this.state.more ? 'view less' : 'view more'} </a>
            </>
        )
    }
}

export default ViewMore