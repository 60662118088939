import React, { Component } from 'react';
import { Flex, WhiteSpace, WingBlank, TabBar, Icon,NavBar, Grid, Toast, Modal, ListView, Button } from 'antd-mobile';
import success from '../images/icons/success.png'
import { getCache ,currencyType} from '../commons/Helpers';
import {
    isBrowser,
    isMobile
} from "react-device-detect";

class GESuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalAmount: 0.00,
            ge_id: '',
        }
    }

    okay = ()=>{
        if(!isBrowser){
            Modal.alert('Proceed to App', <div>If you have the HeartVoice app, you can continue on the app with your existing HeartVoice account! </div>, [
                {
                    text: "Continue on Web", style: { color: '#50B2C2', fontSize: '16px' }, onPress: () => {
                        
                    }
                },
                {
                    text: 'Go to App', style: { color: 'red', fontSize: '16px' }, onPress: () => {
                        Modal.alert('Proceed to App', <div>You can proceed to open the HeartVoice app if you have installed it on your device!</div>, [
                            {
                                text: "Download App", style: {color: '#50B2C2', fontSize: '16px' }, onPress: () => {
                                    window.location.href = 'http://onelink.to/myazfx';
                                }
                            },
                            {
                                text: 'Open App', style: {color: '#50B2C2', fontSize: '16px' }, onPress: () => new Promise((resolve, reject) => {
                                    if (getCache('ge_transaction')) {
                                        let {order_id} = JSON.parse(getCache('ge_transaction'));
                                        window.location.href = 'heartvoice://heartvoice/home?action=GEorder&order_id=' + order_id;
                                        reject('')
                                    }
                                })
                            },
                        ])
                    }
                },
            ])
        }else{
            Modal.alert('Oops', <div>Download the HeartVoice app to view your order!</div>, [
                {
                    text: 'No',style: {color: '#50B2C2', fontSize: '16px' },
                    onPress: () => { }
                },
                {
                    text: 'Find Out More!',style: {color: '#50B2C2', fontSize: '16px' },
                    onPress: () => window.location.href = 'https://www.ourheartvoice.com'
                },
            ])
        }
    }

    componentDidMount() {
        if (getCache('geInfo')) {
            let {totalAmount,ge_id} = JSON.parse(getCache('geInfo'));
            this.setState({
                totalAmount: totalAmount,
                ge_id: ge_id
            })
        }else{
            this.props.history.push("/404");
        }
    }

    goBack = ()=>{
        this.props.history.push('/marketPlaceDetail/' + this.state.packages_id);
    }

    render() {
        return (
            <div className="Paymet-Page result-success-fail-page">
                <NavBar
                    mode="light"
                    icon={<Icon type="left" color="#EB5DA6" size="lg"/>}
                    onLeftClick={this.goBack}
                ></NavBar>
                <Flex className="content">
                    <Flex.Item>
                        <WingBlank>
                            <WhiteSpace size="md" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <Flex direction="column">
                                <img src={success} className="icon-payment-fail" alt="Result - Fail" />
                                <WhiteSpace size="lg" />
                                <WhiteSpace size="lg" />
                                <WhiteSpace size="lg" />
                                <WhiteSpace size="lg" />
                                <div className="result-title">Payment Success</div>
                                <WhiteSpace size="md" />
                                <div className="result-desc">Your payment for ${this.state.totalAmount}</div>
                                <div className="result-desc">was successfully completed.</div>
                            </Flex>
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WingBlank size="lg">
                                <WingBlank size="lg">
                                    <WingBlank size="lg">
                                        <WingBlank size="lg">
                                            <WingBlank size="lg">
                                                <Button
                                                    className="fill-pink-btn"
                                                    activeClassName="fill-pink-active-btn"
                                                    onClick={this.okay.bind()}
                                                >
                                                    View Order
                                                </Button>
                                                <WhiteSpace size="lg" />
                                                <WhiteSpace size="lg" />
                                                <WhiteSpace size="lg" />
                                            </WingBlank>
                                        </WingBlank>
                                    </WingBlank>
                                </WingBlank>
                            </WingBlank>
                        </WingBlank>
                    </Flex.Item>
                </Flex>
            </div>
        );
    }
}

export default GESuccess;