import { withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import baseImg from "../images/icons/hsqueue.png";
import logoImg from "../images/icons/IconHeartVoiceLong.svg";
import { PanoramaSharp } from "@material-ui/icons";
import AuthGuard from "../services/AuthGuard";

const DeclarationQSDone = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [isDisplay, setIsDisplay] = useState(false);
  useEffect(()=>{
    if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      setIsMobile(true);
    }
    let token = GetQueryString(props.location.search, 't');
    
    if (token){
      AuthGuard.getNewToken(token)
      .then((res)=>{
        if(navigator.userAgent.indexOf('heartvoice') !== -1){
          go('web');
        }else{
          getDetail();
        }
      });
    }else{
      getDetail();
    }

  }, []);

  const go = (t) => {
    let url = window.location.origin+"/health_declaration.html"+props.location.search;
    if(t === 'app'){
      url = "heartvoice://heartvoice/home";
      if(navigator.userAgent.indexOf('heartvoice') !== -1){
        url = "heartvoice://heartvoice/home?action=goback";
      }
    }
    window.location.href=url;
  }

  const getDetail = () => {
    let params={
      appointment_request_id:GetQueryString(props.location.search, 'id')
    }
		AuthGuard.getDeclarationFormDetail(params,
			(isOk, res) => {
				if (isOk) {
          setIsDisplay(true);
					let {can_edit} = res;
          if(can_edit === 'N'){
            setIsEdit(false);
          }else{
            setIsEdit(true);
          }
				} else {
          go('web');
				}
			})
	}

  function GetQueryString(string, name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = string.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
  }

  return (
    <div className="declarationQSDone" style={{display: !!isDisplay ? "flex":'none',}}>
      <img className="logo-img" src={logoImg} />
      <img className="base-img" src={baseImg} />
      <div className="text">Thank You!<br />Health Declaration<br />Form completed</div>
      {!!isEdit && 
      (<button type="button" onClick={()=>go('web')} className="btn edit-btn">Edit Form</button>
      )}
      {!!isMobile && 
      (<button type="button" onClick={()=>go('app')} className="btn link-btn">Go to App</button>)
      }
    </div>
  )
}
export default DeclarationQSDone;
// export default withRouter(DeclarationQSDone);