import React, { Component } from "react";
import { WingBlank, Flex, WhiteSpace, Button } from "antd-mobile";
import Swiper, { Navigation, Pagination } from "swiper";
import iconSaveDoctor from "../images/icons/icon_saveDr.png";
import iconUnSaveDoctor from "../images/icons/icon_unsaveDr.png";
import ViewMore from "../component/ViewMore";
import { getWeek, currencyType } from "../commons/Helpers";
import { dayFile, WHATSAPP_MOBILENO } from "../commons/Constants";
import iconDoctorAvatar from "../images/icons/icon_doctor_avatar.png";
import "swiper/swiper-bundle.css";
Swiper.use([Navigation, Pagination]);

class SwiperCom extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.swiperInit();
  }

  swiperInit = () => {
    if (this.props.data.length > 4) {
      new Swiper(".swiper-container", {
        cssMode: true,
        updateOnWindowResize: true,

        // direction: 'horizontal',
        // autoHeight: true,
        // speed:300,
        // loop: false,
        // preventClicks: false,
        // preventClicksPropagation: false, //阻止click冒泡。拖动Swiper时阻止click事件
        slidesPerView: 4, // 一行显示slider的个数
        slidesPerGroup: 4, //定义slides的数量多少为一组
        spaceBetween: 30,
        // a11y: true, //为残障人士浏览网页提供辅助功能，无障碍阅读
        // grabCursor: true,
        // centeredSlides: false, //true活动块居中，false活动快居左
        // initialSlide: 0, //设定初始化时slide的索引
        // observer: true,
        // observeParents: true,
        // observeSlideChildren: true,

        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },

        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        // breakpoints: {
        //   640: {
        //     slidesPerView: 2,
        //     spaceBetween: 20,
        //   },
        //   768: {
        //     slidesPerView: 4,
        //     spaceBetween: 50,
        //   },
        //   1024: {
        //     slidesPerView: 5,
        //     spaceBetween: 50,
        //   },
        // },
        // 如果需要滚动条
        scrollbar: {
          el: ".swiper-scrollbar",
        },
        on: {
          setTranslate: function (swiper, translate) {
            //自定义事件
            // console.log(translate);
          },
        },
      });
    }
  };

  render() {
    let { data, consultFee } = this.props;
    return (
      <div className="swiper-container">
        <div
          className="swiper-wrapper"
          style={{
            justifyContent: data.length <= 4 ? "center" : "",
            transition: "all 0ms ease 0s",
            transform: "translate3d(0px, 0px, 0px)",
          }}
        >
          {data.length > 0 &&
            data.map((item) => {
              let _specialty = "-";
              let _specialtyArr = [];
              if (item.specialty && item.specialty.length > 0) {
                item.specialty.map((item) => {
                  _specialtyArr.push(item.display_name);
                });
                _specialty = _specialtyArr.join(" / ");
              }
              var isThereNoLeadTime = false;
              if (
                item.lead_time === undefined ||
                item.lead_time === null ||
                item.lead_time === 0 ||
                item.lead_time === "" ||
                item.lead_time === "0"
              ) {
                isThereNoLeadTime = true;
              }
              return (
                <div
                  key={item.id}
                  className="swiper-slide"
                  style={{
                    marginLeft: data.length <= 4 ? "15px" : "0",
                    width: data.length == 1 ? "35%" : "22.5%",
                  }}
                >
                  <div
                    className="doctors-section-card"
                    key={item.id}
                    id={item.id}
                  >
                    <WingBlank size="lg">
                      <div
                        className="icon-save"
                        onClick={this.props.saveDr.bind(
                          this,
                          item.id,
                          item.saved
                        )}
                      >
                        <img
                          src={
                            item.saved === "saved"
                              ? iconUnSaveDoctor
                              : iconSaveDoctor
                          }
                          alt=""
                        />
                        <p className="flPink">
                          {item.saved === "saved" ? "Unsave" : "Save"}
                        </p>
                      </div>
                      <img
                        src={
                          item.photo && item.photo.url
                            ? item.photo.url.l
                            : iconDoctorAvatar
                        }
                        className="doctor-img"
                        alt="doctor_photo"
                      />
                      <p className="fb" style={{ wordBreak: "break-word" }}>
                        {item.doctorinfo.name}
                      </p>
                      <p className="flGrey">
                        {item.specialty && item.specialty.length >= 10 ? (
                          <ViewMore length={150} text={_specialty} />
                        ) : (
                          _specialty
                        )}
                      </p>
                      <WhiteSpace size="lg" />
                      <p style={{ wordBreak: "break-word" }}>
                        {item.professional_profile &&
                        item.professional_profile.length >= 150 ? (
                          <ViewMore
                            length={150}
                            text={item.professional_profile}
                          />
                        ) : (
                          item.professional_profile
                        )}
                      </p>
                      <WhiteSpace size="lg" />
                    </WingBlank>
                    <div className="opening-hours">
                      <WingBlank>
                        <div className="background-ccc">
                          <Flex>
                            <ul>
                              {item.doctor_opening_time &&
                              item.doctor_opening_time.length > 0
                                ? item.doctor_opening_time.map(
                                    (dayI, index) => {
                                      return (
                                        <li key={index}>
                                          <p
                                            className="weekname"
                                            style={{
                                              color:
                                                getWeek() === dayFile(dayI.day)
                                                  ? "#E02381"
                                                  : "#000",
                                            }}
                                          >
                                            {dayFile(dayI.day)}
                                          </p>
                                          {dayI.data.map((iitem, iindex) => {
                                            return (
                                              <p key={`clinic-time-${iindex}`}>
                                                <span>{iitem.start}</span>-
                                                <span>{iitem.end}</span>
                                              </p>
                                            );
                                          })}
                                        </li>
                                      );
                                    }
                                  )
                                : ""}
                            </ul>
                          </Flex>
                        </div>
                      </WingBlank>
                    </div>
                    <WhiteSpace size="lg" />
                    {item.doctor_opening_time &&
                      item.doctor_opening_time.length > 0 && (
                        <>
                          <WingBlank size="lg">
                            <div className="dr-extr">
                              <p className="flPink">
                                Consultation Fee:
                                {consultFee.consultFeeMin &&
                                consultFee.consultFeeMax
                                  ? " " +
                                    currencyType(
                                      consultFee.consultFeeMin,
                                      this.state.teleconsultation_currency_code
                                    ) +
                                    " - " +
                                    currencyType(
                                      consultFee.consultFeeMax,
                                      this.state.teleconsultation_currency_code
                                    )
                                  : item.consultation_fee
                                  ? " " +
                                    currencyType(
                                      item.consultation_fee,
                                      this.state.teleconsultation_currency_code
                                    )
                                  : " --"}
                              </p>
                              <p className="flGrey">
                                Send "hello{item.identification}" to +
                                {WHATSAPP_MOBILENO}
                              </p>
                              <p className="flGrey">to book a consultation. </p>
                            </div>
                          </WingBlank>
                          <WhiteSpace size="lg" />
                          <WhiteSpace size="xs" />
                          <WingBlank size="lg">
                            <WingBlank size="lg">
                              <div className="book-consult">
                                {item.show_book_visit && (
                                  <>
                                    <Button
                                      className="consult-img fill-pink-btn"
                                      onClick={this.props.bookAppointment.bind(
                                        this,
                                        item.id,
                                        "visit"
                                      )}
                                    >
                                      {" "}
                                      BOOK A VISIT
                                    </Button>
                                  </>
                                )}
                                {item.show_book_teleconsult && (
                                  <>
                                    <WhiteSpace size="lg" />
                                    <Button
                                      className="consult-img outline-pink-btn"
                                      onClick={this.props.bookAppointment.bind(
                                        this,
                                        item.id,
                                        "teleconsult"
                                      )}
                                    >
                                      {" "}
                                      BOOK TELECONSULT
                                    </Button>
                                  </>
                                )}

                                {item.show_consult_now ? (
                                  <>
                                    <WhiteSpace size="lg" />
                                    <Button
                                      loading={this.props.consultBtnLoading}
                                      disabled={this.props.consultBtnLoading}
                                      className="consult-img outline-pink-btn"
                                      onClick={this.props.consult.bind(
                                        this,
                                        item.identification,
                                        item.id
                                      )}
                                    >
                                      {" "}
                                      TELECONSULT NOW
                                    </Button>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </WingBlank>
                          </WingBlank>
                          <WhiteSpace size="lg" />
                          <WhiteSpace size="xs" />
                        </>
                      )}
                  </div>
                </div>
              );
            })}
        </div>
        <div className={`${data.length > 4 ? "swiper-pagination" : ""}`}></div>
        <div className={`${data.length > 4 ? "swiper-button-next" : ""}`}></div>
        <div className={`${data.length > 4 ? "swiper-button-prev" : ""}`}></div>
        <div className={`${data.length > 4 ? "swiper-scrollbar" : ""}`}></div>
      </div>
    );
  }
}

export default SwiperCom;
