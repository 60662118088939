import React, { Component } from "react";
import {
  NavBar,
  Flex,
  WhiteSpace,
  WingBlank,
  Toast,
  Button,
  InputItem,
  Icon,
  Modal,
  Picker,
} from "antd-mobile";
import DatePicker from "../component/DatePicker";
import AuthGuard from "../services/AuthGuard";
import enUs from "antd-mobile/lib/date-picker/locale/en_US";
import CustomChildren from "../commons/CustomChildren";
import moment from "moment";
import {
  getCache,
  removeCache,
  addKeyValueForDorpList,
  setCache,
  isMobileNo,
  isEmail,
  toHump,
  verifyNRIC,
} from "../commons/Helpers";
import calendar from "../images/icons/ic_status_calendar_pink.png";
import { TCLink, DIALING_CODE } from "../commons/Constants";
import GoogleSearchInput from "../component/GoogleSearchInput";
import { isBrowser } from "react-device-detect";
import Select, { components } from "react-select";
import {
  DropdownIndicator,
  selectStylesForDownList,
} from "./SignUpCompleteInfo";
import {
  SingleValueForDialingCode,
  selectStylesForDialingCodeV2,
} from "./LoginV2";
import DatePickerBor from "react-datepicker";
import _ from "lodash";
import "react-datepicker/dist/react-datepicker.css";

function formatDate(date) {
  /* eslint no-confusing-arrow: 0 */
  const dateString = date.toDateString().split(" ");
  var d = dateString[2];
  var m = dateString[1];
  var y = dateString[3];
  // const dateStr = `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()}`;
  const dateStr = `${d}/${m}/${y}`;
  // const timeStr = `${pad(date.getHours())}:${pad(date.getMinutes())}`;
  return `${dateStr}`;
}
class MissProfileInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      singPassCode: true,
      isDatePickerOpen: false,
      birth: new Date(1980, 0, 1),
      _birth: "",
      email: "",
      _email: "",
      mobile: "",
      _mobile: "",
      dialing_code: DIALING_CODE,
      fullName: "",
      _fullName: "",
      is_otp: false,
      errorMsg: {},
      dialingCodeList: [],
      nric: "",
      _nric: "",
      identification_type: "",
      _identification_type: "",
      identificationTypeList: [],
      _gender: "",
      gender: "",
      genderList: [
        {
          label: "",
          value: "",
        },
        {
          label: "Male",
          value: "male",
        },
        {
          label: "Female",
          value: "female",
        },
        {
          label: "I do not wish to specify",
          value: "other",
        },
      ],
      countryListArr: [],
      countryList: [],
      provinceList: [],
      gmapsLoaded: false,
      address: "",
      country_code: "SG",
      country_name: "",
      postal_code: "",
      unit_no: "",
      race: "",
      residentialstatus: "",
      nationality: "Singapore Citizen",
      raceList: [],
      nationalityList: [],
    };
  }

  componentDidMount() {
    window.initMap = this.initMap;
    const gmapScriptEl = document.createElement(`script`);
    gmapScriptEl.src =
      `https://maps.googleapis.com/maps/api/js?key=` +
      process.env.REACT_APP_GOOGLE_API_KEY +
      `&libraries=places&callback=initMap`;
    document
      .querySelector(`body`)
      .insertAdjacentElement(`beforeend`, gmapScriptEl);
    if (getCache("miss_info")) {
      let {
        email,
        dialing_code,
        mobile_number,
        name,
        birth,
        nric,
        identification_type,
        gender,
        race,
        nationality,
        country_code,
        province,
        city,
        postal_code,
        unit_no,
        address,
      } = JSON.parse(getCache("miss_info"));
      this.setState(
        {
          email: email,
          _email: email,
          mobile: mobile_number,
          _mobile: mobile_number,
          fullName: name,
          _fullName: name,
          dialing_code: dialing_code || DIALING_CODE,
          _dialing_code: dialing_code,
          birth: birth !== null ? new Date(birth) : new Date(1980, 0, 1),
          _birth: birth,
          nric: nric,
          _nric: nric,
          identification_type: identification_type || "nric",
          _identification_type: identification_type,
          _gender: gender,
          gender: gender || "",
          race: race || "",
          _race: race,
          nationality: nationality ? toHump(nationality) : "Singapore Citizen",
          _nationality: nationality,
          country_code: country_code,
          _country_code: country_code,
          province: province,
          city: city,
          postal_code: postal_code,
          _postal_code: postal_code,
          unit_no: unit_no,
          _unit_no: unit_no,
          _address: address,
          address: address,
        },
        () => {
          if (!this.state._identification_type) {
            this.getIdentificationTypeList();
          }
        }
      );
      if (!mobile_number || !dialing_code) {
        this.setState({
          is_otp: true,
        });
      }
      this.getCountryList();
    } else {
      this.setState({
        is_otp: true,
      });
      this.getCountryList();
    }
    // this.getRaceAndRaceStatusList("residential_status");
    this.getRaceAndRaceStatusList("race");
    this.getRaceAndRaceStatusList("nationality");
  }

  getRaceAndRaceStatusList(_attribute) {
    let self = this;
    AuthGuard.getRaceAndRaceStatusList(
      {
        attribute: _attribute,
      },
      (isOk, res) => {
        if (isOk) {
          if (_attribute == "race") {
            let _raceList = [];
            let _result2 = res?.list?.SG;
            _raceList.push({
              value: "",
              label: "",
            });
            _result2.map((item, index) => {
              _raceList.push({
                value: item,
                label: item,
              });
            });
            self.setState({
              raceList: _raceList,
            });
          }
          if (_attribute == "nationality") {
            let _nationalityList = [];
            let _result3 = res?.list?.SG;
            _nationalityList.push({
              value: "",
              label: "",
            });
            _result3.map((item, index) => {
              _nationalityList.push({
                value: item,
                label: item,
              });
            });
            self.setState({
              nationalityList: _nationalityList,
            });
          }
        }
      }
    );
  }

  getIdentificationTypeList() {
    AuthGuard.getIdentificationTypeList(
      {
        group: "identification_type",
      },
      (isOk, res) => {
        let _identificationTypeList = [];
        if (isOk) {
          res.map((item, index) => {
            _identificationTypeList.push({
              value: item.code,
              label: item.display_name,
            });
          });
          this.setState({
            identificationTypeList: _identificationTypeList,
          });
        }
      }
    );
  }

  initMap = () => {
    this.setState({
      gmapsLoaded: true,
    });
  };

  getCountryList = () => {
    this.loadingToast();
    AuthGuard.getCountry({}, (isOk) => {
      if (isOk) {
        this.setState(
          {
            dialingCodeList: addKeyValueForDorpList(
              AuthGuard.listCountrys,
              "dialing_code_name"
            ),
            countryList: addKeyValueForDorpList(
              AuthGuard.listCountrys,
              "country_list"
            ),
            countryListArr: AuthGuard.listCountrys,
          },
          () => {
            if (this.state.country_code !== "") {
              if (this.state.country_code === "SG") {
                this.setState(
                  {
                    province_code: "",
                    city_code: "",
                  },
                  () => {
                    this.getProvinceList();
                  }
                );
              } else {
                this.getProvinceList();
              }
            }
          }
        );
      } else {
      }
      Toast.hide();
    });
  };

  getProvinceList = () => {
    if (!this.state.country_code) return;
    this.setState({ provinceList: [], cityList: [] });
    AuthGuard.getProvinceList(
      {
        code: this.state.country_code,
      },
      (isOk, res) => {
        if (isOk) {
          let { province_code } = this.state;
          this.setState(
            {
              provinceList: addKeyValueForDorpList(
                res[0].province_list,
                "country_list"
              ),
              // province_code: province_code
              //     ? province_code
              //     : res &&
              //         res.length > 0 &&
              //         res[0].province_list &&
              //         res[0].province_list.length > 0
              //         ? res[0].province_list[0].code
              //         : "",
            },
            () => {
              if (this.state.province_code !== "") {
                if (this.state.country_code === "SG") {
                  this.setState(
                    {
                      province_code: "",
                      city_code: "",
                    },
                    () => {
                      this.getCityList();
                    }
                  );
                } else {
                  this.getCityList();
                }
              }
            }
          );
        }
      }
    );
  };

  getCityList = () => {
    if (!this.state.province_code) return;
    AuthGuard.getCityList(
      {
        province_code: this.state.province_code,
      },
      (isOk, res) => {
        if (isOk) {
          // console.log(res)
          let { city_code } = this.state;
          this.setState({
            cityList: addKeyValueForDorpList(res, "country_list"),
            // city_code: city_code
            //     ? city_code
            //     : res && res.length > 0
            //         ? res[0].code
            //         : "",
          });
        }
      }
    );
  };

  getCountryName(code) {
    let name = "";
    if (code) {
      this.state.countryList.map((item) => {
        if (item.value === code) {
          name = item.label;
        }
        return item;
      });
    }
    return name;
  }
  getProvinceName(code) {
    if (this.state.country_code === "SG") return "";
    let name = "";
    if (code) {
      this.state.provinceList.map((item) => {
        if (item.value === code) {
          name = item.label;
        }
        return item;
      });
    }

    return name;
  }
  getCityName(code) {
    if (this.state.country_code === "SG") return "";
    let name = "";
    if (code) {
      this.state.cityList.map((item) => {
        if (item.value === code) {
          name = item.label;
        }
        return item;
      });
    }

    return name;
  }

  genderFormat() {
    if (
      this.state.gender == "F" ||
      this.state.gender == "f" ||
      this.state.gender == "female"
    ) {
      return "female";
    }
    if (
      this.state.gender == "M" ||
      this.state.gender == "m" ||
      this.state.gender == "male"
    ) {
      return "male";
    }
    if (this.state.gender == "other") {
      return "other";
    }
    if (this.state.gender == "" || this.state.gender == null) {
      return "";
    }
    return "other";
  }

  handleSelect = (e) => {
    this.setState(
      {
        birth: e,
        isDatePickerOpen: false,
      },
      () => {
        console.log(this.state.birth);
      }
    );

    document.body.removeEventListener("touchmove", this.stopScroll);
    //添加事件监听时添加了passive参数，在ios9中移除事件监听也需要加此参数
    document.body.removeEventListener("touchmove", this.stopScroll, {
      passive: true,
    });
    document.body.style.overflow = "auto";
  };

  handleCancel = () => {
    this.setState({
      isDatePickerOpen: false,
    });

    document.body.removeEventListener("touchmove", this.stopScroll);
    //添加事件监听时添加了passive参数，在ios9中移除事件监听也需要加此参数
    document.body.removeEventListener("touchmove", this.stopScroll, {
      passive: true,
    });
    document.body.style.overflow = "auto";
  };

  openDatePicker = (e) => {
    e.stopPropagation();
    if (this.state._birth) return false;
    this.setState({
      isDatePickerOpen: true,
    });

    document.body.addEventListener("touchmove", this.stopScroll, {
      passive: false,
    });
    document.body.style.overflow = "hidden";
  };

  stopScroll(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  checkField() {
    let result = { isok: true };
    let emailMsg = "";
    let mobileMsg = "";
    let nameMsg = "";
    let dobMsg = "";
    let dialing_code = "";
    let itypeMsg = "";
    let icnMsg = "";
    let genderMsg = "";
    let countryMsg = "";
    let posMsg = "";
    let addressMsg = "";
    let unitMsg = "";
    let nationalityMsg = "";
    let raceMsg = "";

    if (!this.state.email || this.state.email == "") {
      result.isok = false;
      emailMsg = "Please fill in the required field.";
    }
    if (!this.state.mobile || this.state.mobile == "") {
      result.isok = false;
      mobileMsg = "Please fill in the required field.";
    }
    if (!this.state.dialing_code || this.state.dialing_code == "") {
      result.isok = false;
      dialing_code = "Please fill in the required field.";
    }
    if (!this.state.fullName || this.state.fullName == "") {
      result.isok = false;
      nameMsg = "Please fill in the required field.";
    }
    if (!this.state.birth || this.state.birth == "") {
      result.isok = false;
      dobMsg = "Please fill in the required field.";
    }
    if (
      !this.state.identification_type ||
      this.state.identification_type == ""
    ) {
      result.isok = false;
      itypeMsg = "Please fill in the required field.";
    }
    if (
      !this.state.nric ||
      this.state.nric == "" ||
      this.state.nric.trim() == ""
    ) {
      result.isok = false;
      icnMsg = "Please fill in the required field.";
    }
    if (
      this.state.identification_type === "nric" &&
      this.state.nric &&
      !verifyNRIC(this.state.nric)
    ) {
      result.isok = false;
      icnMsg = "Please enter a valid IC";
    }
    if (
      !this.state.gender ||
      this.state.gender == "" ||
      this.state.gender.trim() == ""
    ) {
      result.isok = false;
      genderMsg = "Please fill in the required field.";
    }
    if (!this.state.country_code || this.state.country_code == "") {
      result.isok = false;
      countryMsg = "Please fill in the required field.";
    }
    if (
      !this.state.postal_code ||
      this.state.postal_code == "" ||
      this.state.postal_code.trim() == ""
    ) {
      result.isok = false;
      posMsg = "Please fill in the required field.";
    }
    if (
      !this.state.address ||
      this.state.address == "" ||
      this.state.address.trim() == ""
    ) {
      result.isok = false;
      addressMsg = "Please fill in the required field.";
    }
    if (
      !this.state.race ||
      this.state.race == "" ||
      this.state.race.trim() == ""
    ) {
      result.isok = false;
      raceMsg = "Please fill in the required field.";
    }
    if (
      !this.state.nationality ||
      this.state.nationality == "" ||
      this.state.nationality.trim() == ""
    ) {
      result.isok = false;
      nationalityMsg = "Please fill in the required field.";
    }
    this.setState({
      errorMsg: {
        emailMsg: emailMsg,
        mobileMsg: mobileMsg,
        nameMsg: nameMsg,
        dobMsg: dobMsg,
        dialing_code: dialing_code,
        itypeMsg: itypeMsg,
        icnMsg: icnMsg,
        genderMsg: genderMsg,
        countryMsg: countryMsg,
        posMsg: posMsg,
        addressMsg: addressMsg,
        nationalityMsg: nationalityMsg,
        raceMsg: raceMsg,
      },
    });
    return result;
  }

  loadingToast() {
    Toast.loading("Loading...", 30, () => {
      console.log("Load complete !!!");
    });
  }

  confirm = () => {
    this.setState({ errorMsg: {} });
    let res = this.state.countryListArr.find((item, index) => {
      return item.dialing_code === this.state.dialing_code;
    });
    let resM = res.mobile_no_format ? res.mobile_no_format : "";
    let checkResult = this.checkField();
    if (!checkResult.isok) {
      return;
    }
    if (isEmail(this.state.email)) {
      if (isMobileNo(this.state.mobile, this.state.dialing_code, resM)) {
        this.loadingToast();
        let missSubmitObj = {};
        if (this.state.is_otp) {
          let otpVrifyNric = {
            email: !this.state._email ? this.state.email : null,
            dialingCode: this.state.dialing_code,
            mobile: this.state.mobile,
            nric: !this.state._email ? this.state.nric : null,
          };
          AuthGuard.loginComOtpSubmit(otpVrifyNric, (isOk, res) => {
            if (isOk) {
              missSubmitObj = {
                fromPage: "/missProfileInfo",
                type: "login_miss",
                dialing_code: !this.state._mobile
                  ? this.state.dialing_code
                  : null,
                mobile: !this.state._mobile ? this.state.mobile : null,
                email: !this.state._email ? this.state.email : null,
                full_name: !this.state._full_name ? this.state.fullName : null,
                otp_code: "",
                birth: !this.state._birth
                  ? moment(this.state.birth).format("YYYY-MM-DD")
                  : null,
                nric: !this.state._nric ? this.state.nric : null,
                identification_type: !this.state._identification_type
                  ? this.state.identification_type
                  : null,
                gender: this.genderFormat(this.state.gender),
                country_code: this.state.country_code,
                city_code:
                  this.state.country_code === "SG" ? "" : this.state.city_code,
                province_code:
                  this.state.country_code === "SG"
                    ? ""
                    : this.state.province_code,
                country_name: this.getCountryName(this.state.country_code),
                province_name: this.getProvinceName(this.state.province_code),
                city_name: this.getCityName(this.state.city_code),
                postal_code: this.state.postal_code,
                street: this.state.address,
                unit_no: this.state.unit_no,
                race: this.state.race,
                nationality: this.state.nationality,
              };
              removeCache("verifyOptFor");
              removeCache("miss_info");
              setCache("verifyOptFor", missSubmitObj);
              this.props.history.push("/newVerifyOTP/");
            } else {
              if (res.status_code == 11032) {
                this.setState({
                  errorMsg: { mobileMsg: res.message },
                });
              } else if (res.status_code == 11033) {
                this.setState({
                  errorMsg: { emailMsg: res.message },
                });
              } else if (res.status_code == 11031) {
                this.setState({
                  errorMsg: { icnMsg: res.message },
                });
              } else {
                Modal.alert("warning", res.message, [
                  { text: "OK", onPress: () => console.log("ok") },
                ]);
              }
            }
            Toast.hide();
          });
        } else {
          missSubmitObj = {
            dialing_code: !this.state._mobile ? this.state.dialing_code : null,
            mobile: !this.state._mobile ? this.state.mobile : null,
            email: !this.state._email ? this.state.email : null,
            full_name: !this.state._full_name ? this.state.fullName : null,
            otp_code: "",
            birth: !this.state._birth
              ? moment(this.state.birth).format("YYYY-MM-DD")
              : null,
            nric: !this.state._nric ? this.state.nric : null,
            identification_type: !this.state._identification_type
              ? this.state.identification_type
              : null,
            gender: this.genderFormat(this.state.gender),
            country_code: this.state.country_code,
            city_code:
              this.state.country_code === "SG" ? "" : this.state.city_code,
            province_code:
              this.state.country_code === "SG" ? "" : this.state.province_code,
            country_name: this.getCountryName(this.state.country_code),
            province_name: this.getProvinceName(this.state.province_code),
            city_name: this.getCityName(this.state.city_code),
            postal_code: this.state.postal_code,
            street: this.state.address,
            unit_no: this.state.unit_no,
            race: this.state.race,
            nationality: this.state.nationality,
          };
          AuthGuard.loginComSubmit(missSubmitObj, (isOk, res) => {
            if (isOk) {
              removeCache("miss_info");
              if (getCache("redirectUrl")) {
                let url = getCache("redirectUrl");
                url = url.replace("#", "");
                this.props.history.push(url);
              } else {
                this.props.history.push("/improve");
              }
            } else {
              if (res.status_code == 11032) {
                this.setState({
                  errorMsg: { mobileMsg: res.message },
                });
              } else if (res.status_code == 11033) {
                this.setState({
                  errorMsg: { emailMsg: res.message },
                });
              } else if (res.status_code == 11031) {
                this.setState({
                  errorMsg: { icnMsg: res.message },
                });
              } else {
                Modal.alert("warning", res.message, [
                  { text: "OK", onPress: () => console.log("ok") },
                ]);
              }
            }
            Toast.hide();
          });
        }
      } else {
        this.setState({
          // errorTxtDisplay: "block",
          errorMsg: { mobileMsg: "Please fill in a valid mobile number." },
        });
      }
    } else {
      console.log("fail");
      this.setState({
        // errorTxtDisplay: "block",
        errorMsg: { emailMsg: "Please fill in a valid email address." },
      });
    }
  };

  goBack = () => {
    this.props.history.push("/login");
  };

  getAddress = (val) => {
    let _address = "";
    if (val) {
      AuthGuard.getGeoAdd(
        {
          address: val,
        },
        (isOk, res) => {
          // console.log(isOk, res)
          if (isOk) {
            _address = res.formatted_address;
            this.setState(
              {
                ...res,
                address: res.formatted_address,
                isDisplay: res.country_code !== "SG",
              },
              () => {
                this.getCountryList();
              }
            );
          }
        }
      );
    }
    this.setState({
      address: _address,
    });
  };

  render() {
    let options = [];
    if (isBrowser) {
      this.state.countryListArr.map((item) => {
        options.push({
          value: item.dialing_code,
          flag: item.flag_image_url?.url?.s,
          label: (
            <div className="selectOptions">
              <img src={item.flag_image_url?.url?.s} /> {item.name} +
              {item.dialing_code}
            </div>
          ),
        });
      });
    }

    return (
      <div className="Register-Page-V2 h5-Page login-Page">
        <NavBar
          mode="light"
          icon={<Icon type="left" color="#EB5DA6" size="lg" />}
          onLeftClick={this.goBack}
        ></NavBar>
        <WingBlank>
          <div className="content">
            <WhiteSpace size="lg" />
            <Flex>
              <Flex.Item>
                <p className="sign-up-title">Missing Profile Information</p>
              </Flex.Item>
            </Flex>
            <WhiteSpace size="lg" />
            <WhiteSpace size="lg" />
            <Flex>
              <Flex.Item>
                <p className="sign-up-level">
                  Please fill in the required fields below in order to proceed.
                </p>
              </Flex.Item>
            </Flex>
            <WhiteSpace size="lg" />
            <WhiteSpace size="lg" />
            {!this.state._email ? (
              <div>
                <div className="input_item">
                  <div className="input-title">
                    {this.state.email ? "Email *" : ""}
                  </div>
                  <Flex>
                    <Flex.Item>
                      <InputItem
                        clear
                        // className="inputField"
                        name="mobile_email"
                        placeholder="Email *"
                        error={this.state.emailError}
                        value={this.state.email}
                        onChange={(v) => {
                          let _email = v.trim();
                          this.setState({ email: _email });
                        }}
                      ></InputItem>
                    </Flex.Item>
                  </Flex>
                </div>
                {this.state.errorMsg.emailMsg ? (
                  <Flex.Item className="errorMsg">
                    <WhiteSpace size="md" />
                    <p>{this.state.errorMsg.emailMsg}</p>
                  </Flex.Item>
                ) : (
                  ""
                )}
                <WhiteSpace size="lg" />
              </div>
            ) : (
              ""
            )}
            {!this.state._mobile || !this.state._dialing_code ? (
              <div>
                <div className="input_item">
                  <div className="input-title">
                    {this.state.mobile ? "Mobile Number *" : ""}
                  </div>
                  <Flex
                    className={`${this.state._dialing_code ? "flGrey" : ""}`}
                  >
                    {isBrowser ? (
                      <Flex>
                        <Select
                          components={{
                            DropdownIndicator,
                            SingleValueForDialingCode,
                          }}
                          name="dialingCode"
                          value={
                            this.state.dialingCode
                              ? options.find(
                                  (option) =>
                                    option.value == this.state.dialingCode
                                )
                              : null
                          }
                          onChange={(e) => {
                            this.setState({
                              dialingCode: e.value,
                            });
                          }}
                          styles={selectStylesForDialingCodeV2}
                          theme={(theme) => ({
                            ...theme,
                            zIndex: -100000,
                            border: "1px solid #ffffff",
                          })}
                          menuPortalTarget={document.querySelector("#root")}
                          isSearchable={false}
                          options={options}
                          placeholder={"Select Dialing Code"}
                        />
                      </Flex>
                    ) : (
                      <Picker
                        data={this.state.dialingCodeList}
                        locale={enUs}
                        extra="Dialing Code"
                        disabled={this.state._dialing_code}
                        value={[this.state.dialing_code]}
                        cols={1}
                        format={(labels) => {
                          if (!labels.length) return;
                          return [
                            labels[0].props.children[0],
                            labels[0].props.children[2],
                          ];
                        }}
                        onChange={(v) => {
                          this.setState({
                            dialing_code: v[0],
                          });
                        }}
                      >
                        <CustomChildren
                          sClas={this.state.dialing_code ? "sel" : "noSel"}
                        ></CustomChildren>
                      </Picker>
                    )}
                    <Flex.Item>
                      <InputItem
                        clear
                        value={this.state.mobile}
                        error={this.state.mobileError}
                        disabled={
                          this.state._mobile && _.isNumber(this.state._mobile)
                        }
                        placeholder="Mobile Number *"
                        type="number"
                        onChange={(v) => this.setState({ mobile: v })}
                      ></InputItem>
                    </Flex.Item>
                  </Flex>
                </div>
                {this.state.errorMsg.dialing_code ? (
                  <Flex.Item className="errorMsg">
                    <WhiteSpace size="md" />
                    <p>{this.state.errorMsg.dialing_code}</p>
                  </Flex.Item>
                ) : (
                  ""
                )}
                {this.state.errorMsg.mobileMsg ? (
                  <Flex.Item className="errorMsg">
                    <WhiteSpace size="md" />
                    <p>{this.state.errorMsg.mobileMsg}</p>
                  </Flex.Item>
                ) : (
                  ""
                )}
                <WhiteSpace size="lg" />
              </div>
            ) : (
              ""
            )}
            {!this.state._fullName ? (
              <div>
                <div className="input_item">
                  <div className="input-title">
                    {this.state.fullName
                      ? "Full Name (as in NRIC / Passport) *"
                      : ""}
                  </div>
                  <Flex>
                    <Flex.Item>
                      <InputItem
                        clear
                        className="inputField"
                        style={{ fontFamily: "Montserrat-Regular" }}
                        name="mobile_email"
                        placeholder="Full Name (as in NRIC / Passport) *"
                        onChange={(v) => {
                          this.setState({ fullName: v });
                        }}
                      ></InputItem>
                    </Flex.Item>
                  </Flex>
                </div>
                {this.state.errorMsg.nameMsg ? (
                  <Flex.Item className="errorMsg">
                    <WhiteSpace size="md" />
                    <p>{this.state.errorMsg.nameMsg}</p>
                  </Flex.Item>
                ) : (
                  ""
                )}
                <WhiteSpace size="lg" />
              </div>
            ) : (
              ""
            )}
            {!this.state._birth ? (
              <div>
                <div className="input_item">
                  <div className="input-title">
                    {this.state.birth ? "Date of Birth *" : ""}
                  </div>
                  <Flex
                    onClick={!isBrowser ? this.openDatePicker.bind(this) : null}
                  >
                    <Flex.Item
                      className={`${
                        this.state._birth
                          ? "disabledInput input-outline"
                          : " input-outline"
                      }`}
                    >
                      <div>
                        {!isBrowser ? (
                          <InputItem
                            className={`pickerLabel ${
                              this.state._birth ? "disabledPicker-input" : ""
                            }`}
                            name="birth"
                            placeholder="Date of Birth *"
                            error={this.state.birthError}
                            value={formatDate(this.state.birth)}
                            onFocus={() => {
                              document.activeElement.blur();
                            }}
                          ></InputItem>
                        ) : (
                          ""
                        )}
                      </div>
                      {this.state.errorMsg.birthMsg ? (
                        <Flex.Item className="errorMsg">
                          <WhiteSpace size="md" />
                          <p>{this.state.errorMsg.birthMsg}</p>
                        </Flex.Item>
                      ) : (
                        ""
                      )}
                      {!isBrowser ? (
                        <DatePicker
                          mode="date"
                          showHeader={false}
                          value={this.state.birth}
                          isOpen={this.state.isDatePickerOpen}
                          onSelect={this.handleSelect}
                          onCancel={this.handleCancel}
                          min={new Date(1900, 1, 1)}
                          max={new Date()}
                        ></DatePicker>
                      ) : (
                        ""
                      )}
                      {!isBrowser ? (
                        ""
                      ) : (
                        <DatePickerBor
                          selected={this.state.birth}
                          onChange={(date) => {
                            this.setState({
                              birth: date,
                            });
                          }}
                          dateFormat={"dd/MMM/yyyy"}
                          dateFormatCalendar={"MMM yyyy"}
                          // minDate={new Date(1980)}
                          className="datePickerCalendarPCInput"
                          calendarClassName="datePickerCalendarPC"
                          showMonthDropdown
                          showYearDropdown
                        />
                      )}
                    </Flex.Item>
                    <span>
                      <img
                        src={calendar}
                        style={{ width: "1em", marginRight: "1em" }}
                        alt=""
                      />
                    </span>
                  </Flex>
                </div>
                {this.state.errorMsg.dobMsg ? (
                  <Flex.Item className="errorMsg">
                    <WhiteSpace size="md" />
                    <p>{this.state.errorMsg.dobMsg}</p>
                  </Flex.Item>
                ) : (
                  ""
                )}
                <WhiteSpace size="lg" />
              </div>
            ) : (
              ""
            )}
            {!this.state._identification_type ? (
              <div>
                <div className="input_item">
                  <div className="input-title">
                    {this.state.identification_type
                      ? "Identification Type *"
                      : ""}
                  </div>
                  <Flex>
                    <Flex.Item className="select-module">
                      {isBrowser ? (
                        <Flex.Item>
                          <Select
                            components={{ DropdownIndicator }}
                            name="identification_type"
                            value={
                              this.state.identification_type
                                ? this.state.identificationTypeList.find(
                                    (option) =>
                                      option.value ==
                                      this.state.identification_type
                                  )
                                : null
                            }
                            onChange={(e) => {
                              this.setState({
                                identification_type: e.value,
                              });
                            }}
                            styles={selectStylesForDownList}
                            menuPortalTarget={document.querySelector("#root")}
                            isSearchable={false}
                            options={this.state.identificationTypeList}
                            placeholder={"Identification Type *"}
                          />
                        </Flex.Item>
                      ) : (
                        <Picker
                          clear
                          data={this.state.identificationTypeList}
                          locale={enUs}
                          value={[this.state.identification_type]}
                          style={{ fontFamily: "Montserrat-Regular" }}
                          placeholder="Identification Type *"
                          cols={1}
                          onChange={(v) => {
                            this.setState({
                              identification_type: v[0],
                            });
                          }}
                        >
                          <CustomChildren
                            sClas={
                              this.state.identification_type ? "sel" : "noSel"
                            }
                          ></CustomChildren>
                        </Picker>
                      )}
                    </Flex.Item>
                  </Flex>
                </div>
                {this.state.errorMsg.itypeMsg ? (
                  <Flex.Item className="errorMsg">
                    <WhiteSpace size="md" />
                    <p>{this.state.errorMsg.itypeMsg}</p>
                  </Flex.Item>
                ) : (
                  ""
                )}
                <WhiteSpace size="lg" />
              </div>
            ) : (
              ""
            )}
            {!this.state._nric || !verifyNRIC(this.state._nric) ? (
              <div>
                <div className="input_item">
                  <div className="input-title">
                    {this.state.nric ? "Identification Number *" : ""}
                  </div>
                  <Flex>
                    <Flex.Item>
                      <InputItem
                        clear
                        className="inputField"
                        style={{ fontFamily: "Montserrat-Regular" }}
                        name="mobile_email"
                        placeholder="Identification Number *"
                        value={this.state.nric}
                        onChange={(v) => {
                          this.setState({
                            nric: v.toUpperCase(),
                          });
                        }}
                      ></InputItem>
                    </Flex.Item>
                  </Flex>
                </div>
                {this.state.errorMsg.icnMsg ? (
                  <Flex.Item className="errorMsg">
                    <WhiteSpace size="md" />
                    <p>{this.state.errorMsg.icnMsg}</p>
                  </Flex.Item>
                ) : (
                  ""
                )}
                <WhiteSpace size="lg" />
              </div>
            ) : (
              ""
            )}
            {!this.state._gender ? (
              <div>
                <div className="input_item">
                  <div className="input-title">
                    {this.state.gender ? "Gender *" : ""}
                  </div>
                  <Flex>
                    <Flex.Item className="select-module">
                      {isBrowser ? (
                        <Flex.Item>
                          <Select
                            components={{ DropdownIndicator }}
                            name="gender"
                            value={
                              this.state.gender
                                ? this.state.genderList.find(
                                    (option) =>
                                      option.value == this.state.gender
                                  )
                                : null
                            }
                            onChange={(e) => {
                              this.setState({
                                gender: e.value,
                              });
                            }}
                            styles={selectStylesForDownList}
                            menuPortalTarget={document.querySelector("#root")}
                            isSearchable={false}
                            options={this.state.genderList}
                            placeholder={"Gender *"}
                          />
                        </Flex.Item>
                      ) : (
                        <Picker
                          clear
                          data={this.state.genderList}
                          locale={enUs}
                          value={[this.genderFormat(this.state.gender)]}
                          style={{ fontFamily: "Montserrat-Regular" }}
                          extra="Gender *"
                          cols={1}
                          onChange={(v) => {
                            this.setState({
                              gender: v[0],
                            });
                          }}
                        >
                          <CustomChildren
                            sClas={this.state.gender ? "sel" : "noSel"}
                          ></CustomChildren>
                        </Picker>
                      )}
                    </Flex.Item>
                  </Flex>
                </div>
                {this.state.errorMsg.genderMsg ? (
                  <Flex.Item className="errorMsg">
                    <WhiteSpace size="md" />
                    <p>{this.state.errorMsg.genderMsg}</p>
                  </Flex.Item>
                ) : (
                  ""
                )}
                <WhiteSpace size="lg" />
              </div>
            ) : (
              ""
            )}
            {!this.state._nationality ? (
              <div>
                <div className="input_item">
                  <div className="input-title">
                    {this.state.nationality ? "Nationality *" : ""}
                  </div>
                  <Flex>
                    <Flex.Item className="select-module">
                      {isBrowser ? (
                        <Flex.Item>
                          <Select
                            components={{ DropdownIndicator }}
                            name="nationality"
                            value={
                              this.state.nationality
                                ? this.state.nationalityList.find(
                                    (option) =>
                                      option.value == this.state.nationality
                                  )
                                : null
                            }
                            onChange={(e) => {
                              this.setState({
                                nationality: e.value,
                              });
                            }}
                            styles={selectStylesForDownList}
                            menuPortalTarget={document.querySelector("#root")}
                            isSearchable={false}
                            options={this.state.nationalityList}
                            placeholder={"Nationality *"}
                          />
                        </Flex.Item>
                      ) : (
                        <Picker
                          clear
                          data={this.state.nationalityList}
                          locale={enUs}
                          value={[this.state.nationality]}
                          style={{ fontFamily: "Montserrat-Regular" }}
                          extra="Nationality *"
                          cols={1}
                          onChange={(v) => {
                            this.setState({
                              nationality: v[0],
                            });
                          }}
                        >
                          <CustomChildren
                            sClas={this.state.nationality ? "sel" : "noSel"}
                          ></CustomChildren>
                        </Picker>
                      )}
                    </Flex.Item>
                  </Flex>
                </div>
                {this.state.errorMsg.nationalityMsg ? (
                  <Flex.Item className="errorMsg">
                    <WhiteSpace size="md" />
                    <p>{this.state.errorMsg.nationalityMsg}</p>
                  </Flex.Item>
                ) : (
                  ""
                )}
                <WhiteSpace size="lg" />
              </div>
            ) : (
              ""
            )}
            {!this.state._race ? (
              <div>
                <div className="input_item">
                  <div className="input-title">
                    {this.state.race ? "Race *" : ""}
                  </div>
                  <Flex>
                    <Flex.Item className="select-module">
                      {isBrowser ? (
                        <Flex.Item>
                          <Select
                            components={{ DropdownIndicator }}
                            name="race"
                            value={
                              this.state.race
                                ? this.state.raceList.find(
                                    (option) => option.value == this.state.race
                                  )
                                : null
                            }
                            onChange={(e) => {
                              this.setState({
                                race: e.value,
                              });
                            }}
                            styles={selectStylesForDownList}
                            menuPortalTarget={document.querySelector("#root")}
                            isSearchable={false}
                            options={this.state.raceList}
                            placeholder={"Race *"}
                          />
                        </Flex.Item>
                      ) : (
                        <Picker
                          clear
                          data={this.state.raceList}
                          locale={enUs}
                          value={[this.state.race]}
                          style={{ fontFamily: "Montserrat-Regular" }}
                          extra="Race *"
                          cols={1}
                          onChange={(v) => {
                            this.setState({
                              race: v[0],
                            });
                          }}
                        >
                          <CustomChildren
                            sClas={this.state.race ? "sel" : "noSel"}
                          ></CustomChildren>
                        </Picker>
                      )}
                    </Flex.Item>
                  </Flex>
                </div>
                {this.state.errorMsg.raceMsg ? (
                  <Flex.Item className="errorMsg">
                    <WhiteSpace size="md" />
                    <p>{this.state.errorMsg.raceMsg}</p>
                  </Flex.Item>
                ) : (
                  ""
                )}
                <WhiteSpace size="lg" />
              </div>
            ) : (
              ""
            )}
            {!this.state._country_code ||
            !this.state._postal_code ||
            !this.state._address ||
            !this.state._unit_no ? (
              <div>
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <Flex>
                  <Flex.Item>
                    <p className="sign-up-title">Add your address</p>
                  </Flex.Item>
                </Flex>
                <WhiteSpace size="lg" />
                <Flex.Item>
                  {this.state.gmapsLoaded && (
                    <div className="serch_myinfo">
                      <GoogleSearchInput
                        getAddress={this.getAddress}
                      ></GoogleSearchInput>
                    </div>
                  )}
                </Flex.Item>
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
              </div>
            ) : (
              ""
            )}
            {!this.state._country_code ||
            !this.state._postal_code ||
            !this.state._address ||
            !this.state._unit_no ? (
              <div>
                <div className="input_item">
                  <div className="input-title">
                    {this.state.country_code ? "Country *" : ""}
                  </div>
                  <Flex>
                    <Flex.Item className="select-module">
                      {isBrowser ? (
                        <Flex.Item>
                          <Select
                            components={{ DropdownIndicator }}
                            name="country"
                            value={
                              this.state.country_code
                                ? this.state.countryList.find(
                                    (option) =>
                                      option.value == this.state.country_code
                                  )
                                : null
                            }
                            onChange={(e) => {
                              this.setState(
                                {
                                  country_code: e.value,
                                },
                                () => {
                                  let show;
                                  if (this.state.country_code === "SG") {
                                    show = false;
                                  } else {
                                    show = true;
                                  }
                                  this.getProvinceList();
                                  this.setState({
                                    isDisplay: show,
                                  });
                                }
                              );
                            }}
                            styles={selectStylesForDownList}
                            menuPortalTarget={document.querySelector("#root")}
                            isSearchable={false}
                            options={this.state.countryList}
                            placeholder={"Country *"}
                          />
                        </Flex.Item>
                      ) : (
                        <Picker
                          clear
                          data={this.state.countryList}
                          locale={enUs}
                          value={[this.state.country_code]}
                          style={{ fontFamily: "Montserrat-Regular" }}
                          extra="Country *"
                          cols={1}
                          onChange={(v) => {
                            this.setState(
                              {
                                country_code: v[0],
                              },
                              () => {
                                let show;
                                if (this.state.country_code === "SG") {
                                  show = false;
                                } else {
                                  show = true;
                                }
                                this.getProvinceList();
                                this.setState({
                                  isDisplay: show,
                                });
                              }
                            );
                          }}
                        >
                          <CustomChildren
                            sClas={this.state.country_code ? "sel" : "noSel"}
                          ></CustomChildren>
                        </Picker>
                      )}
                    </Flex.Item>
                  </Flex>
                </div>
                {this.state.errorMsg.countryMsg ? (
                  <Flex.Item className="errorMsg">
                    <WhiteSpace size="md" />
                    <p>{this.state.errorMsg.countryMsg}</p>
                  </Flex.Item>
                ) : (
                  ""
                )}
                <WhiteSpace size="lg" />

                <WhiteSpace size="lg" />
                {this.state.country_code == "SG" ||
                this.state.country_code == "" ? (
                  ""
                ) : (
                  <div>
                    <div className="input_item">
                      <div className="input-title">
                        {this.state.province_code ? "Province" : ""}
                      </div>
                      <Flex>
                        <Flex.Item className="select-module">
                          {isBrowser ? (
                            <Flex.Item>
                              <Select
                                components={{ DropdownIndicator }}
                                name="province"
                                value={
                                  this.state.province_code
                                    ? this.state.provinceList.find(
                                        (option) =>
                                          option.value ==
                                          this.state.province_code
                                      )
                                    : null
                                }
                                onChange={(e) => {
                                  this.setState(
                                    {
                                      province_code: e.value,
                                    },
                                    () => {
                                      this.getCityList();
                                    }
                                  );
                                }}
                                styles={selectStylesForDownList}
                                menuPortalTarget={document.querySelector(
                                  "#root"
                                )}
                                isSearchable={false}
                                options={this.state.provinceList}
                                placeholder={"Province"}
                              />
                            </Flex.Item>
                          ) : (
                            <Picker
                              data={this.state.provinceList}
                              extra="Province"
                              locale={enUs}
                              cols={1}
                              value={[this.state.province_code]}
                              onChange={(v) => {
                                this.setState(
                                  {
                                    province_code: v[0],
                                  },
                                  () => {
                                    this.getCityList();
                                  }
                                );
                              }}
                            >
                              <CustomChildren
                                sClas={
                                  this.state.province_code ? "sel" : "noSel"
                                }
                              ></CustomChildren>
                            </Picker>
                          )}
                        </Flex.Item>
                      </Flex>
                    </div>
                    {this.state.errorMsg.provinceMsg ? (
                      <Flex.Item className="errorMsg">
                        <WhiteSpace size="md" />
                        <p>{this.state.errorMsg.provinceMsg}</p>
                      </Flex.Item>
                    ) : (
                      ""
                    )}
                    <WhiteSpace size="lg" />
                    <div className="input_item">
                      <div className="input-title">
                        {this.state.city_code ? "City" : ""}
                      </div>
                      <Flex>
                        <Flex.Item className="select-module">
                          {isBrowser ? (
                            <Flex.Item>
                              <Select
                                components={{ DropdownIndicator }}
                                name="city_code"
                                value={
                                  this.state.city_code
                                    ? this.state.cityList.find(
                                        (option) =>
                                          option.value == this.state.city_code
                                      )
                                    : null
                                }
                                onChange={(e) => {
                                  this.setState({
                                    city_code: e.value,
                                  });
                                }}
                                styles={selectStylesForDownList}
                                menuPortalTarget={document.querySelector(
                                  "#root"
                                )}
                                isSearchable={false}
                                options={this.state.cityList}
                                placeholder={"City"}
                              />
                            </Flex.Item>
                          ) : (
                            <Picker
                              data={this.state.cityList}
                              extra="City"
                              cols={1}
                              locale={enUs}
                              value={[this.state.city_code]}
                              onChange={(v) => {
                                this.setState({
                                  city_code: v[0],
                                });
                              }}
                            >
                              <CustomChildren
                                sClas={this.state.city_code ? "sel" : "noSel"}
                              ></CustomChildren>
                            </Picker>
                          )}
                        </Flex.Item>
                      </Flex>
                    </div>
                    {this.state.errorMsg.cityMsg ? (
                      <Flex.Item className="errorMsg">
                        <WhiteSpace size="md" />
                        <p>{this.state.errorMsg.cityMsg}</p>
                      </Flex.Item>
                    ) : (
                      ""
                    )}
                    <WhiteSpace size="lg" />
                  </div>
                )}
                <div className="input_item">
                  <div className="input-title">
                    {this.state.postal_code ? "Postal Code *" : ""}
                  </div>
                  <Flex>
                    <Flex.Item>
                      <InputItem
                        clear
                        className="inputField"
                        style={{ fontFamily: "Montserrat-Regular" }}
                        name="mobile_email"
                        placeholder="Postal Code *"
                        value={this.state.postal_code}
                        onChange={(v) => {
                          this.setState({
                            postal_code: v,
                          });
                        }}
                      ></InputItem>
                    </Flex.Item>
                  </Flex>
                </div>
                {this.state.errorMsg.posMsg ? (
                  <Flex.Item className="errorMsg">
                    <WhiteSpace size="md" />
                    <p>{this.state.errorMsg.posMsg}</p>
                  </Flex.Item>
                ) : (
                  ""
                )}
                <WhiteSpace size="lg" />
                <div className="input_item">
                  <div className="input-title">
                    {this.state.address ? "Street *" : ""}
                  </div>
                  <Flex>
                    <Flex.Item>
                      <InputItem
                        clear
                        className="inputField"
                        style={{ fontFamily: "Montserrat-Regular" }}
                        name="mobile_email"
                        placeholder="Street *"
                        value={this.state.address}
                        onChange={(v) => {
                          this.setState({
                            address: v,
                          });
                        }}
                      ></InputItem>
                    </Flex.Item>
                  </Flex>
                </div>
                {this.state.errorMsg.addressMsg ? (
                  <Flex.Item className="errorMsg">
                    <WhiteSpace size="md" />
                    <p>{this.state.errorMsg.addressMsg}</p>
                  </Flex.Item>
                ) : (
                  ""
                )}
                <WhiteSpace size="lg" />
                <div className="input_item">
                  <div className="input-title">
                    {this.state.unit_no ? "Unit Number" : ""}
                  </div>
                  <Flex>
                    <Flex.Item>
                      <InputItem
                        clear
                        className="inputField"
                        style={{ fontFamily: "Montserrat-Regular" }}
                        name="mobile_email"
                        placeholder="Unit Number"
                        value={this.state.unit_no}
                        onChange={(v) => {
                          this.setState({
                            unit_no: v,
                          });
                        }}
                      ></InputItem>
                    </Flex.Item>
                  </Flex>
                </div>
                {this.state.errorMsg.unitMsg ? (
                  <Flex.Item className="errorMsg">
                    <WhiteSpace size="md" />
                    <p>{this.state.errorMsg.unitMsg}</p>
                  </Flex.Item>
                ) : (
                  ""
                )}
                <WhiteSpace size="lg" />
              </div>
            ) : (
              ""
            )}
            <Button
              className="fill-pink-btn"
              activeClassName="fill-pink-active-btn"
              onClick={this.confirm.bind(this)}
            >
              Confirm
            </Button>
            <WhiteSpace size="lg" />
            <WhiteSpace size="lg" />
          </div>
        </WingBlank>
      </div>
    );
  }
}

export default MissProfileInfo;
