import React, { Component } from 'react';
import { Flex, WhiteSpace, NavBar, WingBlank, Toast, Button, Icon, InputItem, Modal } from 'antd-mobile';
import Flag from '../images/icons/iconCreditcard.svg'
import AuthGuard from '../services/AuthGuard';
import { getCache, isEmail, setCache, removeCache, isInvalid,emailBigFormate,mobileBigFormate, get_local_cache } from '../commons/Helpers';
import { ShowErrorMsgCode } from '../commons/Constants';
var otpTimer = null
class VerifyOTP extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errorTxtDisplay: 'none',
            errorMsg:'Invalid OTP Code',
            code1: '',
            code2: '',
            code3: '',
            code4: '',
            code5: '',
            code6: '',
            email: '',
            destination: '',
            code: '',
            username: '',
            conent1: '',//a sms or an email
            conent2: '', //phone or email
            vcode: [],
            tempArr:[0,1,2,3,4,5],
            active:'',
            input_code:'',
            border:false,
            i:0,
            seconds: 0,
        }
    }

    componentDidMount() {
        this.vcodeFoucs()
        document.body.style.backgroundColor = '#FFF';
        let verifyOptFor = JSON.parse(getCache('verifyOptFor'))
        this.setState({
            conent1: verifyOptFor.type === 'email'? 'an email':'a sms',
            conent2: verifyOptFor.type === 'email'? 'email':'mobile',
            username: verifyOptFor.username
        })
        this.secondsResendOtp();
    }

    onChange = e => {
        let { name, value } = e.target;
        this.setState({ [name]: value },()=>{
            if (value.length > 0){
                console.log(value);
                if (name == 'code1'){
                    this.inputCode2.focus()
                }
    
                if (name == 'code2') {
                    this.inputCode3.focus()
                }
    
                if (name == 'code3') {
                    this.inputCode4.focus()
                }   
    
                if (name == 'code4') {
                    this.inputCode5.focus()
                }
    
                if (name == 'code5') {
                    this.inputCode6.focus()
                }            
    
                if (name == 'code6') {
    
                }            
    
                this.submitOpt();
            }        
        });        
        
    };

    loadingToast() {
        Toast.loading('Loading...', 30, () => {
            console.log('Load complete !!!');
        });
    }

    submitOpt = () =>{
        let otpCode = this.state.code;
        if(otpCode.length === 6){
            this.loadingToast();
            let verifyOptFor = JSON.parse(getCache('verifyOptFor')) 
            if(verifyOptFor.fromPage === 'Register'){
                let patientInfo = getCache('patientInfo')
                let _patientInfo = JSON.parse(patientInfo)
                if (!isInvalid(_patientInfo.id)){
                    //信息不全，补全信息
                    AuthGuard.checkOtp4updte({
                        dialingCode: verifyOptFor.dialingCode,
                        mobile: verifyOptFor.username,
                        code: otpCode,
                    }, (isok, res) => {
                        if (isok) {
                            this.completeMyinfo()
                        } else {
                            this.setState({
                                errorTxtDisplay: "block",
                                errorMsg: res
                            })
                        }

                        Toast.hide();
                    })
                }else{
                    const { fullName, aliasName, ...$params } = _patientInfo
                    //首次注册
                    AuthGuard.checkOtp4Reg({
                        otpCode: otpCode,
                        full_name: fullName,
                        alias_name: aliasName,
                        ...$params,
                        
                    },(isok , res)=>{
                        console.log(isok, res)
                        if (isok){
                            AuthGuard.signupNotie({
                                dialing_code: verifyOptFor.dialingCode,
                                mobile_number: verifyOptFor.username,
                            }, (isOk, res) => {

                            })
                            let user_profile_id = res.user.user_id;
                            let token = res.accessToken.token;
                            AuthGuard.SetAuthenticated(token)
                            if (getCache('redirectUrl')){
                                let url = getCache('redirectUrl')
                                if (url.indexOf('marketPlaceDetail') !== -1){
                                    let packages_id =  getCache('packages_id')
                                    let is_contacted_by_ge = getCache('is_contacted_by_ge')
                                    let is_share_personal_data = getCache('is_share_personal_data')
                                    let hasUrl = '/geCheckOutPage/' + packages_id + '/' + is_share_personal_data + '/' + is_contacted_by_ge
                                    setCache('redirectUrl', hasUrl)
                                }
                            }
                            removeCache('verifyOptFor')
                            removeCache('patientInfo')
                            this.props.history.push("/referralCode/" + user_profile_id);
                        }else{
                            this.setState({
                                errorTxtDisplay: "block",
                                errorMsg: res.message
                            })
                        }
                        Toast.hide();
                    })
                }

            }else{
                // login
                let _destination = isEmail(verifyOptFor.username) ? verifyOptFor.username : verifyOptFor.dialingCode + this.state.username
                let _otp_type = isEmail(verifyOptFor.username) ? 'EMAIL' : 'SMS'
                let grantee_app_id = getCache("grantee_app_id")//如果传的有被授权者app_id，代表用于授权登录
                let auth_type = grantee_app_id ? "CLIENT_OAUTH" : ""
                AuthGuard.loginVerifyOtp({
                    destination: _destination,
                    code: otpCode,
                    otp_type: _otp_type,
                    auth_type: auth_type
                }, (isOk, res) => {
                    removeCache('patientInfo')
                    setCache('patientInfo', res)
                    if (isOk) {
                        removeCache('miss_info')
                        if(getCache('redirectUrl') && (getCache('redirectUrl').indexOf('/doctorList') != -1 || getCache('redirectUrl').indexOf('/doctorDetails') != -1)){
                            if(!res.email || !res.mobile_number || !res.dialing_code || !res.name || !res.birth || !res.nric || !res.identification_type || !res?.gender || !res?.race || !res?.nationality || !res?.country_code || !res?.postal_code || !res?.address){
                                setCache('miss_info', {'email':res.email,'dialing_code':res.dialing_code,'mobile_number':res.mobile_number,'name':res.name,'birth':res.birth,'nric': res.nric,'identification_type': res.identification_type, "gender": res?.gender, "race": res?.race,"nationality": res?.nationality,"country_code": res?.country_code,"province": res?.province,"city": res?.city,"postal_code": res?.postal_code,"unit_no": res?.unit_no,"address": res?.address});
                                this.props.history.push("/missProfileInfo")
                            }else{
                                let url = getCache('redirectUrl')
                                url = url.replace('#' ,'')
                                this.props.history.push(url)
                            }
                        }else{
                            if(!res.email || !res.mobile_number || !res.dialing_code || !res.name || !res.birth || !res.nric || !res.identification_type || !res?.gender || !res?.race || !res?.nationality || !res?.country_code || !res?.postal_code || !res?.address){
                                setCache('miss_info', {'email':res.email,'dialing_code':res.dialing_code,'mobile_number':res.mobile_number,'name':res.name,'birth':res.birth,'nric': res.nric,'identification_type': res.identification_type, "gender": res?.gender, "race": res?.race,"nationality": res?.nationality,"country_code": res?.country_code,"province": res?.province,"city": res?.city,"postal_code": res?.postal_code,"unit_no": res?.unit_no,"address": res?.address});
                                this.props.history.push("/missProfileInfo")
                            }else{
                                if (getCache('redirectUrl')){
                                    let url = getCache('redirectUrl')
                                    url = url.replace('#' ,'')
                                    if(url.indexOf('/health_declaration.html') !== -1){
                                        window.location.href=window.location.origin+url;
                                    }else{
                                        this.props.history.push(url)
                                    }
                                }else{
                                    this.props.history.push("/improve")
                                }
                            }
                        }
                    } else {
                        //3023 User Not Found.
                        this.setState({
                            errorTxtDisplay: "block",
                            errorMsg: res.message
                        })
                    }
                    Toast.hide();
                })
            }
        }

    }

    resendOTP = (e) =>{
        // e.preventDefault();
        this.setState({
            errorTxtDisplay: "none",
            code1: "",
            code2: "",
            code3: "",
            code4: "",
            code5: "",
            code6: ""
        });
        this.loadingToast();
        let verifyOptFor = JSON.parse(getCache('verifyOptFor')) 
        if(verifyOptFor.fromPage === 'Register'){
            let patientInfo  = getCache('patientInfo')
            let _patientInfo  = JSON.parse(patientInfo)
            if (!isInvalid(_patientInfo.id)){
                AuthGuard.sendOtp4update({
                    dialingCode: verifyOptFor.dialingCode,
                    mobile: verifyOptFor.username
                }, (isOk, res) => {
                    if (isOk) {
                        // countdownTimer
                        Modal.alert('success', res.message, [{ text: 'OK', onPress: () => console.log('ok') }]);
                        this.secondsResendOtp()
                    } else {
                        this.setState({
                            errorTxtDisplay: "block",
                            errorMsg: res.message
                        })
                    }
                    Toast.hide();
                })
            }else{
                AuthGuard.sendOtp4Reg({
                    dialingCode: verifyOptFor.dialingCode,
                    mobile: verifyOptFor.username,
                    email: _patientInfo.email,
                },(isok , res)=>{
                    console.log(res)
                    if (isok){
                        AuthGuard.signupNotie({
                            dialing_code: verifyOptFor.dialingCode,
                            mobile_number: verifyOptFor.username,
                        }, (isOk, res) => {

                        })
                        Modal.alert('success', res.message, [{ text: 'OK', onPress: () => console.log('ok') }]);
                        this.secondsResendOtp()
                    }else{
                        this.setState({
                            errorTxtDisplay: "block",
                            errorMsg: res.message
                        })
                    }
                    Toast.hide();
                })
            }
            
        }else{
            
            if(isEmail(verifyOptFor.username)){
                AuthGuard.loginSendOtp({
                    otpType: "EMAIL",
                    username: verifyOptFor.username
                },(isOk, res) => {
                    // console.log(res);
                    if(isOk){
                        // countdownTimer
                        Modal.alert('Success', 'An OTP has been sent to your registered email at ' + emailBigFormate(verifyOptFor.username) + '.', [{text: 'OK', onPress: () => console.log('ok') }]);
                        this.secondsResendOtp()
                    }else{  
                        this.setState({
                            errorTxtDisplay: "block",
                            errorMsg: res.message
                        })
                    }
                    Toast.hide();
                })
            }else{
                AuthGuard.loginSendOtp({
                    otpType: "SMS",
                    username: verifyOptFor.username,
                    dialingCode: verifyOptFor.dialingCode
                },(isOk, res) =>{
                    console.log(res);
                    if(isOk){
                        // countdownTimer
                        Modal.alert('Success', 'An OTP has been sent to your registered mobile number at ' + mobileBigFormate(verifyOptFor.username) + '.', [{text: 'OK', onPress: () => console.log('ok') }]);
                        this.secondsResendOtp()
                    }else{
                        this.setState({
                            errorTxtDisplay: "block",
                            errorMsg: res.message
                        })
                    }
                    Toast.hide();
                });
                
            }
        }
        // console.log('resendOTP');
    }

    secondsResendOtp = ()=>{
        this.state.seconds = 60;
        otpTimer = setInterval(() => {
            this.setState((preState) => ({
                seconds: preState.seconds - 1,
            }), () => {
                if (this.state.seconds <= 0) {
                    clearInterval(otpTimer);
                }
            });
        }, 1000)
    }

    completeMyinfo = ()=>{
        let verifyOptFor = JSON.parse(getCache('verifyOptFor')) 
        let patientInfo =  getCache('patientInfo')
        this.loadingToast();
        AuthGuard.completeProfile(JSON.parse(patientInfo), (isOk, res) => {
            if (isOk) {
                AuthGuard.signupNotie({
                    dialing_code: verifyOptFor.dialingCode,
                    mobile_number: verifyOptFor.username,
                }, (isOk, res) => {

                })
                removeCache('verifyOptFor')
                removeCache('patientInfo')
                if (getCache('redirectUrl')) {
                    let url = getCache('redirectUrl')
                    url = url.replace('#', '')
                    this.props.history.push(url)
                } else {
                    this.props.history.push("/submitResult");
                }
                
            } else {
                this.setState({
                    errorTxtDisplay: "block",
                    errorMsg: res
                })
            }

            Toast.hide();
        })
        // this.props.history.push("/submitResult")
    }
    vcodeChange = (e) =>{
        let {value} = e.target
        var _vcode = [];
        var _i = 0;
        for(var j=0;j<=value.length;j++){
            _vcode.push(value.charAt(j))
            _i = j
        }
        this.setState({
            vcode: _vcode,
            i: _i,
            active: _i
        })
        if(value.length == 6){
            let _code = _vcode.join('')
            this.setState({
                code: _code
            },()=>{
                this.submitOpt()
                this.vcodeBlur()
            })
        }
        this.setState({
            input_code:value
        })
        
    }
    
    vcodeFoucs = (e) => {
        this.setState({
            code:'',
            vcode:[],
            active:0,
            input_code:'',
            border:true,
            errorTxtDisplay:'none'
        })
        this.otp_code.focus()
    }

    vcodeBlur = (e) => {
        this.setState({
            active:'',
            border:false
        })
        this.otp_code.blur()
    }

    goBack = ()=>{
        let verifyOptFor = JSON.parse(getCache('verifyOptFor'))
        if (otpTimer) { clearInterval(otpTimer)}
        if (verifyOptFor.fromPage === 'Register'){
            this.props.history.push('/myinfo')
        }else{
            this.props.history.push(verifyOptFor.fromPage)
        }
    }

    render() {

        return (
            <div className="Register-Page h5-Page login-Page">
                <NavBar
                    mode="light"
                    icon={<Icon type="left" color="#EB5DA6" size="lg"/>}
                    onLeftClick={this.goBack}
                ></NavBar>
                <WingBlank>
                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />
                    <Flex direction="column" justify="start" align="start">
                        <Flex.Item>
                            <p className="alert-otp">We just sent you {this.state.conent1} with a code. Enter it to verify you {this.state.conent2}.</p>
                        </Flex.Item>
                    </Flex>
                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />

					<div className="vcode" id='vertifycode'>
                        <input 
                        type="number" 
                        onBlur={this.vcodeBlur} 
                        onFocus={this.vcodeFoucs} 
                        name="otp_code" 
                        maxLength='6' 
                        autoComplete="off" 
                        style={{imeMode: "disabled"}}
                        ref={el=>this.otp_code=el} 
                        className='otp_code' 
                        id='otp_code' 
                        onChange={this.vcodeChange}
                        value={this.state.input_code.replace(/[^\d]/g,'')}
                        />
						<div className="otp-labels">
                            {this.state.tempArr.map((item , i)=>{
                                return (
                                    <label key={i} className={`otp-label ${this.state.border ? 'border' : ''} ${this.state.active === item ? '' : ''}`} htmlFor="otp_code">{this.state.vcode[item] ? this.state.vcode[item] : 0}</label>
                                )
                            })}
						</div> 
					</div>

                    <WhiteSpace size="md" />
                    <Flex.Item className="errorMsg" style={{ display: this.state.errorTxtDisplay }}>
                        <p>{this.state.errorMsg}</p>
                    </Flex.Item>
                    <WhiteSpace size="lg" />
                    <WhiteSpace size="sm" />
                    <button
                     disabled={this.state.seconds <= 0 ? false : true}
                     className="resendOTP" 
                     onClick={this.resendOTP}
                        style={{ color: this.state.seconds <= 0 ? '#56B3C4' : '#8e8e93' }}
                    >
                        Resend OTP
                    </button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <span style={{ display: this.state.seconds > 0 ? 'inline-block' : 'none', fontSize: 'small' }}> <span style={{ color: '#56B3C4', fontWeight: 'bolder' }}>in</span> <span style={{ color: '#56B3C4', fontWeight: 'bolder' }} className="cb">{this.state.seconds} secs</span></span>
                </WingBlank>
            </div>
        );
    }
}

export default VerifyOTP;