import React, { Component } from 'react';
import { Icon } from 'antd-mobile';
class CustomChildren extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        let props = this.props;
        return (  
            <div
                onClick={props.onClick}
                className="picker-select"
            >
                <div className="picker-value">
                    <span className={props.sClas}>{props.extra}</span>
                    <Icon type="down" style={{ float: 'right' }} color="#a53468" />
                </div>
            </div>

        );
    }
}
 
export default CustomChildren;