import React, { Component } from 'react';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
  } from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import ic_search from "../images/icons/ic_search.png";
  
const GoogleSearchInput = (props) => {

    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        /* Define search scope here */
      },
      debounce: 300,
    });
    const ref = useOnclickOutside(() => {
      // When user clicks outside of the component, we can dismiss
      // the searched suggestions by calling this method
      clearSuggestions();
      document.querySelector(".google-search-input").classList.remove("active");
    });
  
    const handleInput = (e) => {
        // Update the keyword of the input element
        document.querySelector(".google-search-input").classList.add("active");
        setValue(e.target.value);
      };
  
    const handleBlur = (e) => {
        document.querySelector(".google-search-input").classList.remove("active");
    };
      
    const handleSelect = ({ description }) => () => {
        // console.log(description)
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();
      props.getAddress(description)
      
      // Get latitude and longitude via utility functions
      getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          console.log("📍 Coordinates: ", { lat, lng });
        })
        .catch((error) => {
          console.log("😱 Error: ", error);
        });
    };
  
    const renderSuggestions = () =>
      data.map((suggestion) => {
          // console.log(suggestion)
        const {
          id,
          structured_formatting: { main_text, secondary_text },
        } = suggestion;
        // console.log(id)
        return (
          <li className="google-search-li" key={id} onClick={handleSelect(suggestion)}>
            <strong>{main_text}</strong> <small>{secondary_text}</small>
          </li>
        );
      });
  
    return (
      <div className="google-search-contain" ref={ref}>
        <div className="google-search-title">{value ? 'Quick search your address' : ''}</div>
        <div className="input-img">
          <input
            className="google-search-input"
            value={value}
            onChange={handleInput}
            onBlur={handleBlur}
            disabled={!ready}
            placeholder="Quick search your address"
          />
          {/* <img className="page-search-img" src={ic_search} alt="" /> */}
        </div>
        {status === "OK" && <ul className="google-search-ul">{renderSuggestions()}</ul>}
      </div>
    );
};
export default GoogleSearchInput;