import { Toast } from 'antd-mobile';
import { useState, useEffect } from 'react';
import { LocalAudioTrack, LocalVideoTrack, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';
import useVideoContext from '../useVideoContext/useVideoContext';
import { useAppState } from '../../state';

type TrackType = LocalAudioTrack | LocalVideoTrack | RemoteAudioTrack | RemoteVideoTrack | undefined;

export default function useIsTrackEnabled(track: TrackType) {
  const [isEnabled, setIsEnabled] = useState(track ? track.isEnabled : false);
  const { localTracks } = useVideoContext();
  const {setIsRemoteMuteAudio} = useAppState();
  const localAudioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;

  useEffect(() => {
    setIsEnabled(track ? track.isEnabled : false);

    if (track) {
      const setEnabled = () => {
        setIsEnabled(true);
        if (track !== localAudioTrack) {
          setIsRemoteMuteAudio(false)
        }
      }
      const setDisabled = () => {
        if (track !== localAudioTrack) {
          // Toast.info("Provider has turned off audio", 1);
          setIsRemoteMuteAudio(true)
        }
        setIsEnabled(false);
      }
      track.on('enabled', setEnabled);
      track.on('disabled', setDisabled);
      return () => {
        track.off('enabled', setEnabled);
        track.off('disabled', setDisabled);
      };
    }
  }, [track]);

  return isEnabled;
}
