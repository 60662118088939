import React, { Component } from "react";
import AuthGuard from "../services/AuthGuard";
import URL from "url";

class GranteeRedirectDriver extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {}

  async componentDidMount() {
    let httpUrl = this.props.location.search;
    let _query = URL.parse(httpUrl, true).query;
    let tokenFromGrantee = _query.tokenFromGrantee;
    let targetUrl = _query.targetUrl;

    if (tokenFromGrantee) {
      AuthGuard.SetAuthenticated(tokenFromGrantee);
    } else {
      AuthGuard.SetAuthenticated("");
    }
    if (targetUrl) {
      AuthGuard.checkVaildliUrl({ url: targetUrl }, (isOk, res) => {
        console.log(isOk, res);
        if (isOk) {
          window.location.href = targetUrl;
        } else {
          this.props.history.push({
            pathname: "/invalidomain",
          });
        }
      });
    }
  }

  render() {
    return <></>;
  }
}
export default GranteeRedirectDriver;
