import React, { useState, useEffect } from 'react';
import { Flex, WhiteSpace, WingBlank, Toast, Button, Modal, NavBar, Icon } from 'antd-mobile';
import { isBrowser } from "react-device-detect";
import { useHistory } from 'react-router-dom'
import { showDate, currencyType, getCache, setCache, removeCache } from '../commons/Helpers'
import Icon1docwallet from '../images/icons/Icon1docwallet.png'

import html2canvas from 'html2canvas'
import AuthGuard from '../services/AuthGuard'

const PayNow = (props) => {
	let timer = null
	const history = useHistory();
	const [paymentInfo, setPaymentInfo] = useState({})
	useEffect(() => {
		let oneDocInfo = getCache('oneDocInfo')
		if (oneDocInfo) {
			oneDocInfo = JSON.parse(oneDocInfo)
		}
		setPaymentInfo(oneDocInfo)
		// let step = 6
		timer = setInterval(() => {
			// step--
			getStatus(oneDocInfo)
			// console.log(step);
			// if (step <= 0) {
			// clearInterval(timer)
			// }
		}, 60000);
		return () => {
			clearInterval(timer)
			setTimeout(() => {
				removeCache("oneDocInfo")
			}, 1000);
		}
	}, []);
	function getStatus(oneDocInfo) {
		AuthGuard.getTransactionStatus({ id: oneDocInfo.id },
			(isOk, res) => {
				if (isOk) {
					let { status_code, total, id } = res
					setCache("oneDocInfo", {
						...oneDocInfo,
						total,
						id
					})
					if (status_code == "completed") {
						props.history.push({ pathname: oneDocInfo.backPath });
						setTimeout(() => {
							removeCache("oneDocInfo")
						}, 1000);
					}
				} else {
					clearInterval(timer)
				}
			})
	}
	const goBack = () => {
		history.goBack()
	}

	return <div className="Paymet-Page pay-page" style={{ height: '100%', fontSize: 20 }}>
		{!isBrowser ? <NavBar
			mode="light"
			icon={<Icon type="left" color="#EB5DA6" size="lg" />}
			onLeftClick={goBack}
		>Payment</NavBar> : ''}
		<WingBlank size="lg" style={{ height: 'calc(100% - 45px)', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
			<div className="content">
				<WhiteSpace />
				<WhiteSpace />
				<Flex align='center' justify="center">
					<img className='oneDocImg' src={Icon1docwallet} />
				</Flex>
				<WhiteSpace />
				<WhiteSpace />
				<Flex direction="column" align='center' justify="center">
					<Flex.Item>
						<div>Approve Payment</div>
					</Flex.Item>
					<Flex.Item>
						<div>with 1doc Wallet</div>
					</Flex.Item>
				</Flex>
			</div>
			<div>
				<WhiteSpace />
				<WhiteSpace />
				<WhiteSpace />
				<WhiteSpace />
				<Flex justify="between">
					<Flex.Item>
						User has to approve payment using 1doc wallet for their teleconsult in Heartvoice before they can be directed to the payment page in 1doc.
					</Flex.Item>
				</Flex>
				<WhiteSpace />
				<WhiteSpace />
				<WhiteSpace />
				<Flex justify="center">
					<Button
						style={{ background: '#399DBB', color: '#fff', fontFamily: 'Montserrat-Regular', fontSize: 17, fontWeight: 600, width: 200 }}
						// loading={this.state.confirmBtnLoading}
						// disabled={this.state.confirmBtnLoading}
						onClick={()=>{window.location.href = `ihguser://scan_to_pay?request_token=${paymentInfo.payment_request.request_token}`}}
					>
						Approve
					</Button>
				</Flex>
			</div>
		</WingBlank>
	</div>
}


export default PayNow