import React, { Component } from 'react';
import { WhiteSpace } from 'antd-mobile';
import Menu from '../component/Menu'
import logo from '../images/icons/logo.png'
import iphone from '../images/icons/image_s.png'
import icon1 from '../images/icons/general-practioner.png'
import icon2 from '../images/icons/doctor1.png'
import icon3 from '../images/icons/stethoscope.png'
import iconStep1 from '../images/icons/icon_step1.png'
import iconStep2 from '../images/icons/icon_step2.png'
import iconStep3 from '../images/icons/icon_step3.png'
import telemeddoct from '../images/icons/telemeddoct.png'
import iconIappsOmron from '../images/icons/icon_iapps_omron.png'
import drIcon from '../images/icons/dr_icon.png'
import searchIcon from '../images/icons/search_icon.png'
import heartIcon from '../images/icons/heart_icon.png'
import consultOurDietician from '../images/icons/consult_our_dietician.png'

class PCHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu_list: [],
        }
    }

    componentDidMount() {
        this.setState({
            menu_list: Menu()
        })
    }
    render() {
        return (
            <div className="pc-home">
                <div className="header">
                    <a href="https://www.ourheartvoice.com/">
                        <div className="logo">
                            <img src={logo}></img>
                        </div>
                    </a>
                    <div className='menu-list'>
                        <ul className="menu-ul">
                            {this.state.menu_list.map((item, index) => {
                                return (
                                    <li className="menu-ul-li" key={`menu-ul-li${index}`}>
                                        <div className={`menu-name ${item.code === 'for_patients' ? 'active-sub-meue' : ''} ${item.code === 'contact' ? 'block-div' : ''}`}>
                                            {item.href ? <a href={item.href}> {item.name} </a> : item.name}
                                        </div>
                                        {item.children ? (
                                            <ul className="menu-sub">
                                                {item.children.map((iitem, iindex) => {
                                                    return (
                                                        <li className="menu-ul-li-li" key={`menu-ul-li-ul-li-${iitem.code}`}>
                                                            <div className={`menu-sub-name ${iitem.code === 'heartvoice_telemedicine' ? 'active-sub-meue' : ''}`}>
                                                                <a href={iitem.href}>{iitem.name}</a>
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        ) : null}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                <div className="banner-block">
                    <div className="banner">
                        <div className="banner-desc">
                            <p className="content-title1">Better health at your fingertips</p>
                            <p className="content-title2">Monitor your health vitals and see a Doctor online with HeartVoice</p>
                        </div>
                        <div className="imgPhone">
                            <img src={iphone}></img>
                        </div>
                    </div>
                </div>
                <div className="btns">
                    <div className="btns">
                        <a className="outline-pink-btn " onClick={() => this.props.getLocal()}>
                            <img className="fl-left" src={drIcon} alt=""/>
                            <span className="fl-right">Consult Any General Practitioner</span>
                        </a>
                        <a className="outline-pink-btn " onClick={() => this.props.checkToken('find_doctor')}>
                            <img className="fl-left" src={searchIcon} alt=""/>
                            <span className="fl-right">Find a Doctor</span>
                        </a>
                        <a className="outline-pink-btn " onClick={() => this.props.dieticianMoudule()}>
                            <img className="fl-left" src={consultOurDietician} alt="" />
                            <span className="fl-right">Consult our Dietitian</span>
                        </a>    
                        <a className="outline-pink-btn " onClick={() => this.props.checkToken('my_doctors')}>
                            <img className="fl-left" src={heartIcon} alt=""/>
                            <span className="fl-right">My Doctors</span>
                        </a>
                        {/* <a className="fill-pink-btn " onClick={() => this.props.checkToken('active_sg')}>
                            <span>ActiveSG Promotion</span>
                        </a> */}
                        <a className="fill-pink-btn " onClick={() => this.props.checkToken('monitoring')}>
                            <span>Explore Monitoring Program</span>
                        </a>
                        <WhiteSpace size="lg" />
                        <WhiteSpace size="lg" />
                    </div>
                </div>
                <div className="steps">
                    <div className="steps-info">
                        <div className="info" style={{ textAlign: 'center' }}>
                            With our secured integration, you can now see a doctor simply via WhatsApp!
                        </div>
                    </div>
                    <div className="steps-items">
                        <div className="steps-item">
                            <div className="steps-item-icon">
                                <img src={iconStep1} alt="" />
                            </div>
                            <div className="steps-item-name">
                                Step 1:
                            </div>
                            <div className="steps-item-desc">
                                Select your preferred medical professional
                            </div>
                        </div>
                        <div className="steps-item">
                            <div className="steps-item-icon">
                                <img src={iconStep2} alt="" />
                            </div>
                            <div className="steps-item-name">
                                Step 2:
                            </div>
                            <div className="steps-item-desc">
                                See your doctor now or book an appointment via WhatsApp
                            </div>
                        </div>
                        <div className="steps-item">
                            <div className="steps-item-icon">
                                <img src={iconStep3} alt="" />
                            </div>
                            <div className="steps-item-name">
                                Step 3:
                            </div>
                            <div className="steps-item-desc">
                                You will be directed to WhatsApp to start a video consultation
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="fl-left">
                        <div className="introduction">
                            <p>
                                At HeartVoice, our mission is to make sure that you can receive convenient healthcare whenever you need it. With our busy day-to-day lives and limited time for self-care, we often neglect our health. Hence, HeartVoice now allows you to connect with our SG-registered medical professionals easily through WhatsApp!
                            </p>
                            <p>
                                HeartVoice promises to be here for you no matter where you are on your health journey – be it a simple phone call to a GP or long-term chronic care and weight management support.
                            </p>
                        </div>
                        <div className="footer-img">
                            <img src={iconIappsOmron} alt="" />
                        </div>

                    </div>
                    <div className="fl-right">
                        <img src={telemeddoct} />
                    </div>
                </div>
            </div>
        )
    }
}

export default PCHome;