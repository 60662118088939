//corporate_service_fee_total 平台服务费用 自己平台的费用
//corporate_service_total 咨询费用 医生收的 （不用）
//user_queue_total  咨询费用 医生收的

import React, { Component } from "react";
import {
  Flex,
  WhiteSpace,
  WingBlank,
  Toast,
  Modal,
  Button,
  Accordion,
  List,
} from "antd-mobile";
import HVLogo from "../images/icons/IconHeartVoiceLong.svg";
import IconLoatcion from "../images/icons/location.svg";
import AuthGuard from "../services/AuthGuard";
import {
  setCache,
  removeCache,
  getCache,
  currencyType,
  isInvalid,
  MoneyToFixed,
  showStatus,
  addKeyValueForDorpList,
  getGST,
} from "../commons/Helpers";
import { SumTotalAmount } from "../commons/ComputerBill";
import _ from "lodash";
// import Alert from '../component/Alert'
const alert = Modal.alert;

const DeliveryItem = (props) => (
  <div className="deliveryItem" onClick={props.selectDeliveryClick}>
    <div className="itemNameTime">
      <div className="itemName">
        {" "}
        {!isInvalid(props.deliver) ? props.deliver.name : "-"}
      </div>
      <div className="itemTime">
        {" "}
        {parseFloat(props.deliver.day_required) > 0
          ? "Deliver in ~" + parseFloat(props.deliver.day_required) + "hrs"
          : " "}
      </div>
    </div>
    <div className="itemPirice">
      {props.deliver.price_after_subsidy
        ? "$" +
          currencyType(props.deliver.price_after_subsidy, props.currencyCode)
        : ""}
    </div>
  </div>
);
class Bill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      billID: "",
      loading: false,
      total: 0.0,
      totalAmount: 0.0,
      subTotalAmount: 0.0,
      corporate_service_total: 0.0,
      corporate_service_fee_total: 0.0,
      disabled: false,
      delivery: [],
      selectedDelivery: {},
      name: "",
      dialing_code: "",
      mobile_number: "",
      unit_no: "",
      address: "",
      country_code: "",
      postal_code: "",
      recipientAddressId: "",
      clinic_number: "",
      isDisplay: false,
      paymentMode: "",
      user_profile_id: "",
      currency_code: "",
      couponId: getCache("selectCouponId"),
      selectedCoupon: false,
      displayCounponAmount: null,
      delivery_selected: {},
      medication_patient_item_list: [],
      GST_reg_no: "",
      GST_rate: "",
      GST_calculation: "1",
      GST_inclusive: "1",
      user_queue_status: "",
      queue_number: "",
      coach_name: "",
      teleconsult_time: "",
      order_no: "",
      subsidy_list: [],

      isDeliveryAddrDisplay: false,
      isDeliveryPriceDisplay: false,
      // medicationList: JSON.parse(getCache('setMedication')),
      medicationList: [],
      consultation_fee: 0.0,
      medication_fee: 0.0,
      subsidy_fee: 0.0,
      other_fee: {},
      discount_fee: 0.0,
      deleteMedicationItemIds: [],
      deleteMedFlag: true,
      check_delete_medicine: false,
      subtotals: 0.0,
      payStatus: false,
      provinceCode: "",
      countryCode: "",
      countryList: [],
      coupon_price: "",
      deleteMIds: [],
      paid_amount: "",
    };
  }

  componentWillMount() {
    let self = this;
    let { id } = this.props.match.params;
    if (id) {
      this.setState(
        {
          billID: id,
        },
        () => {
          if (!AuthGuard.checkLogin()) {
            alert(
              "Error",
              "This payment does not belong to you. Please login again to confirm your identify.",
              [
                {
                  text: "Login",
                  onPress: () => {
                    this.toLogin();
                  },
                },
              ]
            );
            return;
          }
          self.userGetBill();
        }
      );
    }
  }
  componentDidMount() {
    this.accordionElement = null;
    // page completed ...
    this.getCountryList();
  }

  toLogin = () => {
    let _url = window.location.pathname;
    setCache("redirectUrl", _url);
    this.props.history.push("/login");
  };

  getCountryList = () => {
    this.loadingToast();
    AuthGuard.getCountry({}, (isOk) => {
      if (isOk) {
        this.setState({
          countryList: addKeyValueForDorpList(
            AuthGuard.listCountrys,
            "country_list"
          ),
        });
      }
      Toast.hide();
    });
  };

  loadingToast() {
    Toast.loading("Loading...", 30, () => {
      console.log("Load complete !!!");
    });
  }

  getSelectedCouponDetail() {
    let _this = this;
    if (this.state.couponId) {
      AuthGuard.getPromoCodeDetail(
        {
          user_profile_id: _this.state.user_profile_id,
          user_promo_reward_id: _this.state.couponId,
        },
        (isOk, res) => {
          if (isOk) {
            console.log("ccccc", res);
            this.setState(
              {
                selectedCoupon: res,
              },
              () => {
                this.updateData();
              }
            );
          }
        }
      );
    } else {
      console.log("not coupon");
    }
  }

  getPayNowInfo(id) {
    this.loadingToast();
    AuthGuard.getLastPending(
      {
        order_ids: JSON.stringify([id]),
      },
      (isOk, res) => {
        Toast.hide();
        if (isOk) {
          let { param, transaction_id, payment_method } = res[0];
          if (payment_method == "PN1") {
            setCache(
              "payNowInfo",
              JSON.stringify({
                total: param?.QR_CODE?.amount,
                refNo: param?.REFERENCE_NO,
                recipient: param?.recipient,
                paymentDueData: param?.QR_CODE?.expiry,
                qrcode: param?.QR_CODE?.qr_code,
                id: transaction_id,
                backPath: "/success",
                backToWa: true,
              })
            );
            this.props.history.push({ pathname: `/paynow` });
          }
        }
      }
    );
  }

  userGetBill() {
    let self = this;
    this.loadingToast();
    AuthGuard.userCheckBill(
      {
        userQueueId: this.state.billID,
      },
      (isOk, res) => {
        Toast.hide();
        if (isOk) {
          if (res?.is_has_pending_payment) {
            this.getPayNowInfo(res.order_id);
          }
          if (
            res.user_queue_status === "paid" ||
            res.user_queue_status === "pick_up" ||
            res.user_queue_status === "delivered" ||
            res.user_queue_status === "on_route" ||
            res.user_queue_status === "completed" ||
            res.user_queue_status === "closed"
          ) {
            this.setState(
              {
                payStatus: true,
              },
              () => {
                console.log(res.user_queue_status, this.state.payStatus);
              }
            );
          }
          // let sameId = []
          // res.medication_patient_item_list.map(ite=>{
          // 	res.medicationList.map(ites=>{
          // 		if (ite.id==ites.id) {
          // 			sameId.push(ite.id)
          // 		}
          // 	})
          // })
          // let patient_item_list = []
          // res.medication_patient_item_list.map(ite=>{
          // 	if (!sameId.includes(ite.id)) {
          // 		patient_item_list.push(ite)
          // 	}
          // })
          self.setState(
            {
              ...res.recipient_address,
              delivery: res.delivery,
              clinic_number: res.clinic_contact_no
                ? res.clinic_contact_no
                : "-",
              disabled: res.status === "completed" ? true : false,
              user_queue_status: res.user_queue_status,
              queue_number: res.queue_number,
              teleconsult_time: res.teleconsult_time,
              coach_name: res.coach_name,
              order_no: res.order_no,
              total: res.total,
              delivery_selected: res.delivery_selected
                ? res.delivery_selected
                : "",
              medication_patient_item_list: res.medication_patient_item_list,
              GST_reg_no: res.GST_reg_no,
              GST_rate: res.GST_rate * 1,
              GST_calculation: res.GST_calculation,
              GST_inclusive: res?.GST_inclusive || "0.00",
              currency_code: res.teleconsultation_currency_code
                ? res.teleconsultation_currency_code
                : "SGD",
              user_queue_total: res.user_queue_total,
              corporate_service_fee_total: res.corporate_service_fee_total,
              recipientAddressId: res.recipient_address
                ? res.recipient_address.id
                : "",
              selectedCoupon: res.coupon_selected ? res.coupon_selected : null,
              coupon_price: res.coupon_price,
              selectedDelivery: {
                id: "",
                price_after_subsidy: 0,
                name: "Select Delivery",
              },
              isDisplay: true,
              medicationList:
                getCache("setMedication") && getCache("setMedication") !== null
                  ? JSON.parse(getCache("setMedication"))
                  : res.medicationList,
              check_delete_medicine: res.check_delete_medicine,
              subsidy_list: res.subsidy_list,
              oname: res.user_profile_info
                ? res.user_profile_info.fullName
                : "",
              odialingCode: res.user_profile_info
                ? res.user_profile_info.dialingCode
                : "",
              omobile: res.user_profile_info
                ? res.user_profile_info.mobile
                : "",
              user_profile_info_address: res.user_profile_info
                ? res.user_profile_info.address
                : "",
              ounitNo:
                res.user_profile_info && res.user_profile_info.unit_no
                  ? res.user_profile_info.unit_no
                  : "",
              province_code: res.user_profile_info
                ? res.user_profile_info.province_code
                : "",
              city_code: res.user_profile_info
                ? res.user_profile_info.city_code
                : "",
              postalCode: res.user_profile_info
                ? res.user_profile_info.postal_code
                : "",
              countryCode: res.country_code ? res.country_code : "",
              user_profile_id: res.user_profile_id ? res.user_profile_id : "",
            },
            () => {
              let _selectedDelivery = getCache("selectedDelivery");

              console.log(res.user_profile_id);

              if (!isInvalid(_selectedDelivery)) {
                this.setState(
                  {
                    selectedDelivery: JSON.parse(_selectedDelivery),
                    isDeliveryAddrDisplay: true,
                    isDeliveryPriceDisplay: true,
                  },
                  () => {
                    self.updateData();
                    self.getSelectedCouponDetail();

                    console.log(
                      res.recipient_address,
                      _selectedDelivery,
                      this.state.isDeliveryAddrDisplay
                    );
                  }
                );
              } else {
                this.setState(
                  {
                    isDeliveryAddrDisplay: true,
                  },
                  () => {
                    self.updateData();
                    self.getSelectedCouponDetail();

                    console.log(
                      res.recipient_address,
                      _selectedDelivery,
                      this.state.isDeliveryAddrDisplay
                    );
                  }
                );
              }
            }
          );
        } else {
          if (res.message) {
            Modal.alert("Notice", res.message, [
              { text: "OK", onPress: () => console.log("ok") },
            ]);
            this.setState({
              disabled: true,
            });
          }
        }
      }
    );
  }

  updateData = async () => {
    // let { couponId } = this.state
    if (this.state.payStatus === true) {
      let _this = this;
      this.setState(
        {
          selectedDelivery: this.state.delivery_selected,
        },
        () => {
          _this.calculateForTelemedFor();
        }
      );
      return false;
    }
    // let _totalAmount = 0.00
    // let _displayCounponAmount = 0.00

    if (getCache("selectCouponId")) {
      try {
        if (getCache("setMedication") && getCache("setMedication") !== null) {
          this.setState(
            {
              medicationList: JSON.parse(getCache("setMedication")),
            },
            () => {
              this.calculateForTelemedFor();
            }
          );
        } else {
          this.calculateForTelemedFor();
        }
        console.log(this.state.medicationList, getCache("setMedication"));
      } catch (error) {
        // _displayCounponAmount = 0.00

        if (getCache("setMedication") && getCache("setMedication") !== null) {
          this.setState(
            {
              medicationList: JSON.parse(getCache("setMedication")),
            },
            () => {
              this.calculateForTelemedFor();
            }
          );
        } else {
          this.calculateForTelemedFor();
        }
      }
    } else {
      // _displayCounponAmount = 0.00

      this.calculateForTelemedFor();

      console.log(this.state.medicationList, getCache("setMedication"));
    }
  };
  getCouponPrice() {
    return new Promise((resolve, reject) => {
      AuthGuard.calculateForTelemed(
        {
          user_queue_id: this.state.billID,
          coupon_id: this.state.couponId,
          delivery_service_id: !isInvalid(this.state.selectedDelivery.id)
            ? this.state.selectedDelivery.id
            : "",
          medicationPrice: this.state.medication_fee
            ? MoneyToFixed(this.state.medication_fee)
            : 0.0,
        },
        (isOk, res) => {
          if (isOk) {
            resolve(res);
          } else {
            reject(false);
          }
        }
      );
    });
  }
  updateCoupon = () => {
    removeCache("selectCouponId");
    this.setState({
      selectedCoupon: null,
    });
  };

  getCountryName(code) {
    let name = "";
    if (code) {
      this.state.countryList.map((item) => {
        if (item.value === code) {
          name = item.label;
        }
        return item;
      });
    }
    return name;
  }

  checkAddress = () => {
    let countryCode = this.state.recipientAddressId
      ? this.state.country_code
      : this.state.countryCode;
    let _country_name = this.getCountryName(countryCode);
    let _postal_code = this.state.recipientAddressId
      ? this.state.postal_code
      : this.state.postalCode;
    return new Promise((resolve, reject) => {
      AuthGuard.checkRegAdd(
        {
          param: this.state.recipientAddressId
            ? this.state.address
            : this.state.user_profile_info_address,
          country_name: _country_name,
          country_code: countryCode,
          postal_code: _postal_code,
        },
        (isOk, res) => {
          if (isOk) {
            resolve(res);
          } else {
            Modal.alert("", res.message, [
              { text: "OK", onPress: () => console.log("ok") },
            ]);
            reject(false);
          }
        }
      );
    });
  };

  ProceedToPayment = async (e) => {
    // console.log(this.state.postalCode)

    let _delMedIDs = [];
    this.state.medicationList.map((item, index) => {
      if (item.user_request === "NOT_REQUIRED") {
        _delMedIDs.push(item.transaction_item_id);
      }
    });

    let err_postalCode;
    this.state.countryList.map((item) => {
      // console.log(item.postal_code_format)
      if (item.postal_code_format) {
        // console.log(item.postal_code_format)
        if (item.value === this.state.countryCode) {
          let reg = eval(item.postal_code_format);
          // console.log(item)
          // console.log(reg)
          let _postal_code = this.state.recipientAddressId
            ? this.state.postal_code
            : this.state.postalCode;
          console.log(_postal_code);
          if (!reg.test(_postal_code)) {
            err_postalCode = false;
          } else {
            err_postalCode = true;
          }
        }
      }
    });

    if (this.state.selectedDelivery.name == "Self Collect") {
      err_postalCode = true;
    }

    // console.log(this.state.postal_code, this.state.postalCode)
    console.log(this.state.selectedDelivery);
    console.log(err_postalCode);

    let slef = this;

    if (err_postalCode == true) {
      if (
        this.state.medicationList.length >= 1 &&
        this.state.selectedDelivery.id === ""
      ) {
        if (this.state.medicationList.length === _delMedIDs.length) {
          Modal.alert(
            "You did not select a delivery option",
            <div>
              Are you sure you want to proceed to checkout without delivery?
            </div>,
            [
              {
                text: "Confirm",
                style: { color: "red" },
                onPress: () => slef.Pay(),
              },
              {
                text: "Back",
                style: { color: "blue" },
                onPress: () => console.log("cancal"),
              },
              {
                text: "",
                style: { display: "none" },
                onPress: () => console.log(""),
              },
            ]
          );
        } else {
          Modal.alert(
            "You did not select a delivery option",
            <div>Please select a delivery option</div>,
            [
              {
                text: "Okay",
                style: { color: "blue" },
                onPress: () => console.log("cancal"),
              },
            ]
          );
        }
      } else if (!this.state.selectedDelivery.id) {
        Modal.alert(
          "You did not select a delivery option",
          <div>
            Are you sure you want to proceed to checkout without delivery?
          </div>,
          [
            {
              text: "Confirm",
              style: { color: "red" },
              onPress: () => slef.Pay(),
            },
            {
              text: "Back",
              style: { color: "blue" },
              onPress: () => console.log("cancal"),
            },
            {
              text: "",
              style: { display: "none" },
              onPress: () => console.log(""),
            },
          ]
        );
      } else if (
        this.state.medicationList.length >= 1 &&
        this.state.selectedDelivery.id &&
        this.state.selectedDelivery.name !== "Self Collect"
      ) {
        // let is_check = true;
        // if(this.state.delivery.length > 0){
        //     for(let type = 0;type < this.state.delivery.length; type++){
        //         if(this.state.delivery[type].id === this.state.selectedDelivery.id && this.state.delivery[type].type === 'self_collect'){
        //             is_check = false;
        //             break;
        //         }
        //     }
        // }
        let _address = this.state.recipientAddressId
          ? this.state.address
          : this.state.user_profile_info_address;
        if (!_address) {
          Modal.alert(
            "Notice",
            "We will need a valid address for delivery. Please check your address and try again.",
            [{ text: "OK", onPress: () => console.log("ok") }]
          );
          return;
        }
        let res = false;
        try {
          res = await this.checkAddress();
        } catch (error) {
          res = false;
        }
        if (!res) {
          return;
        }
        this.setState(
          {
            deleteMedicationItemIds:
              _delMedIDs.length > 0 ? JSON.stringify(_delMedIDs) : "",
          },
          () => {
            this.Pay();
          }
        );
      } else {
        this.setState(
          {
            deleteMedicationItemIds:
              _delMedIDs.length > 0 ? JSON.stringify(_delMedIDs) : "",
          },
          () => {
            this.Pay();
          }
        );
      }
    } else {
      Modal.alert(
        "",
        "We will need a valid address for delivery. Please check your address and try again",
        [{ text: "OK", onPress: () => console.log("ok") }]
      );
    }
  };

  Pay = () => {
    let _delMedIDs = [];
    let _errIf = true;

    let data = {
      id: this.state.recipientAddressId,
      userProfileId: this.state.user_profile_id,
      address: this.state.recipientAddressId
        ? this.state.address
        : this.state.user_profile_info_address,
      unitNo: this.state.recipientAddressId
        ? this.state.unit_no
        : this.state.ounitNo,
      countryCode: this.state.recipientAddressId
        ? this.state.country_code
        : this.state.countryCode,
      postalCode: this.state.recipientAddressId
        ? this.state.postal_code
        : this.state.postalCode,
      name: this.state.recipientAddressId ? this.state.name : this.state.oname,
      dialingCode: this.state.recipientAddressId
        ? this.state.dialing_code
        : this.state.odialingCode,
      mobileNumber: this.state.recipientAddressId
        ? this.state.mobile_number
        : this.state.omobile,
      province: this.state.recipientAddressId ? this.state.province_code : "",
      city: this.state.recipientAddressId ? this.state.city_code : "",
    };

    this.state.medicationList.map((item, index) => {
      if (item.user_request === "NOT_REQUIRED") {
        _delMedIDs.push(item.transaction_item_id);
      }
    });

    if (
      this.state.totalAmount === 0.0 ||
      this.state.totalAmount === "0.00" ||
      this.state.totalAmount === "0" ||
      this.state.totalAmount === "0.0"
    ) {
      let noFeePayParams = {
        userQueueId: this.state.billID,
        recipientAddressId: this.state.recipientAddressId,
        deliveryServiceId: this.state.selectedDelivery.id,
        couponId: this.state.couponId,
        totalMoney: 0,
        paymentMode: "cc1",
        deleteMedicationItemIds:
          _delMedIDs && _delMedIDs.length > 0 ? JSON.stringify(_delMedIDs) : "",
      };
      if (!this.state.recipientAddressId) {
        this.loadingToast();
        AuthGuard.addAddress(data, (isOk, res) => {
          Toast.hide();
          if (isOk) {
            console.log(res);
            this.totalPay(noFeePayParams);
          } else {
            this.toastFailed(res.message);
          }
        });
      } else {
        this.totalPay(noFeePayParams);
      }
    } else {
      if (this.state.selectedDelivery.name == "Self Collect") {
        _errIf = false;
      }

      if (_errIf) {
        AuthGuard.addAddress(data, (isOk, res) => {
          Toast.hide();
          if (isOk) {
            console.log(res);
            setCache("billInfo", {
              recipientAddressId: this.state.recipientAddressId
                ? this.state.recipientAddressId
                : res.id,
              deliveryServiceId: this.state.selectedDelivery.id
                ? this.state.selectedDelivery.id
                : null,
              totalAmount: this.state.totalAmount,
              paymentMode: this.state.paymentMode,
              couponId: this.state.couponId,
              deleteMedicationItemIds:
                _delMedIDs && _delMedIDs.length > 0
                  ? JSON.stringify(_delMedIDs)
                  : "",
            });
            this.props.history.push({
              pathname: "/pay/" + this.state.billID,
              state: { total: this.state.totalAmount },
            });
          } else {
            this.toastFailed(res.message);
          }
        });
      } else {
        setCache("billInfo", {
          recipientAddressId: this.state.recipientAddressId
            ? this.state.recipientAddressId
            : "",
          deliveryServiceId: this.state.selectedDelivery.id
            ? this.state.selectedDelivery.id
            : null,
          totalAmount: this.state.totalAmount,
          paymentMode: this.state.paymentMode,
          couponId: this.state.couponId,
          deleteMedicationItemIds:
            _delMedIDs && _delMedIDs.length > 0
              ? JSON.stringify(_delMedIDs)
              : "",
        });
        this.props.history.push({
          pathname: "/pay/" + this.state.billID,
          state: { total: this.state.totalAmount },
        });
      }
    }
  };

  totalPay(params) {
    this.loadingToast();
    AuthGuard.noFeePay(params, (isOk) => {
      Toast.hide();
      removeCache("billInfo");
      if (isOk) {
        this.props.history.push({ pathname: "/success/" });
      } else {
        removeCache("bill_info");
        setCache("bill_info", {
          transaction_id: "",
          userQueueId: this.state.billID,
        });
        this.props.history.push({ pathname: "/fail" });
      }
    });
  }

  toastFailed(msg) {
    Toast.fail(msg, 2);
  }

  onChange = () => {
    // console.log(key);
  };

  addAddress = () => {
    console.log(this.state.selectedDelivery);
    setCache("selectedDelivery", this.state.selectedDelivery);
    this.props.history.push({ pathname: "/addAddress/" + this.state.billID });
  };

  coupon = () => {
    // if (this.state.user_queue_status === '' || this.state.user_queue_status === 'completed' || this.state.user_queue_status === 'pick_up') return false

    if (this.state.user_queue_status === "" || this.state.payStatus === true)
      return false;

    let _delMedIDs = [];
    this.state.medicationList.map((item, index) => {
      if (item.user_request === "NOT_REQUIRED") {
        _delMedIDs.push(item.transaction_item_id);
      }
    });

    let delivery_id =
      Object.keys(this.state.selectedDelivery) !== 0
        ? this.state.selectedDelivery.id
        : "";
    let data = {
      id: this.state.billID,
      delivery_id: delivery_id,
      medicationPrice: this.state.medication_fee,
      medication_ids:
        _delMedIDs && _delMedIDs.length > 0 ? JSON.stringify(_delMedIDs) : null,
    };
    setCache("selectedDelivery", this.state.selectedDelivery);
    this.props.history.push({ pathname: "/coupon", query: data });

    // console.log(_delMedIDs)
  };

  selectDeliveryClick = (item) => {
    if (!this.state.payStatus) {
      let id = item.id ? item.id : "";
      let type = item.type ? item.type : "";
      let isDisplay = false;
      if (type !== "self_collect") {
        // show address
        isDisplay = true;
      }
      console.log(id);
      let newDelivery;
      if (id !== "") {
        newDelivery = this.state.delivery.find((item) => {
          return item.id === id;
        });
      } else {
        newDelivery = {
          id: "",
          price_after_subsidy: 0,
          name: "Select Delivery",
        };
      }
      this.accordionElement.handleItemClick();
      this.setState(
        {
          selectedDelivery: newDelivery,
          isDeliveryAddrDisplay: isDisplay,
          isDeliveryPriceDisplay: true,
          couponId: removeCache("selectCouponId"),
          selectedCoupon: false,
          displayCounponAmount: 0.0,
        },
        () => {
          this.updateData();
          this.updateCoupon();
        }
      );
    }
  };

  clickMedication(index) {
    return false;

    if (!this.state.payStatus) {
      if (!this.state.check_delete_medicine) {
        Modal.alert(
          "Alert",
          <div>
            A Discount/Subsidy (CHAS/Medisave) has been applied to your bill. If
            you would like to edit the prescriptions, please contact the
            provider.
          </div>,
          [
            {
              text: "Okay",
              style: { color: "blue" },
              onPress: () => console.log("cancal"),
            },
          ]
        );
        return false;
      }

      let item = this.state.medicationList;

      if (
        this.state.user_queue_status === "" ||
        this.state.user_queue_status === "completed" ||
        this.state.user_queue_status === "pick_up"
      )
        return false;

      if (item[index].user_request == "--" || !item[index].user_request) {
        item[index].user_request = "NOT_REQUIRED";

        this.setState(
          {
            medicationList: item,
          },
          () => {
            if (this.state.couponId) {
              this.setState(
                {
                  couponId: removeCache("selectCouponId"),
                  selectedCoupon: false,
                  displayCounponAmount: 0.0,
                },
                () => {
                  setCache("setMedication", item);
                  this.calculateForTelemedFor();
                }
              );
            } else {
              setCache("setMedication", item);
              this.calculateForTelemedFor();
            }
          }
        );
      } else if (item[index].user_request === "NOT_REQUIRED") {
        item[index].user_request = "--";

        if (this.state.couponId) {
          this.setState(
            {
              couponId: removeCache("selectCouponId"),
              selectedCoupon: false,
              displayCounponAmount: 0.0,
            },
            () => {
              setCache("setMedication", item);
              this.calculateForTelemedFor();
            }
          );
        } else {
          setCache("setMedication", item);
          this.calculateForTelemedFor();
        }
      }

      // console.log(item)
    }
  }

  calculateForTelemedFor() {
    let _delMedIDs = [];
    this.state.medicationList.map((item, index) => {
      if (item.user_request === "NOT_REQUIRED") {
        _delMedIDs.push(item.transaction_item_id);
      }
    });

    let delivery_id =
      Object.keys(this.state.selectedDelivery) !== 0
        ? this.state.selectedDelivery.id
        : "";
    let delivery_name =
      Object.keys(this.state.selectedDelivery) !== 0
        ? this.state.selectedDelivery.name
        : "";

    this.setState({
      disabled: true,
    });

    AuthGuard.calculateForTelemed(
      {
        user_queue_id: this.state.billID,
        delivery_service_id: delivery_id ? delivery_id : "",
        delete_medication_item_ids:
          _delMedIDs && _delMedIDs.length >= 1
            ? JSON.stringify(_delMedIDs)
            : "",
        coupon_id: this.state.couponId ? this.state.couponId : "",
        payment_mode: null,
      },
      (isOk, res) => {
        // console.log(res)
        this.setState(
          {
            consultation_fee: res.consultation_fee,
            medication_fee: res.medication_fee,
            other_fee: res.other_fee,
            subsidy_fee: Math.abs(res.subsidy_fee),
            subTotalAmount: res.subtotal,
            totalAmount: res.total_price,
            discount_fee: Math.abs(res.discount_fee),
            displayCounponAmount: res.coupon_price,
            selectedDelivery: {
              id: delivery_id,
              price_after_subsidy: res.delivery_price,
              name: delivery_name,
            },
            deleteMIds: _delMedIDs,
            paid_amount: res?.paid_amount || "0.00",
            GST_inclusive: res?.GST_inclusive || "0.00",
          },
          () => {
            this.setState({
              disabled: false,
            });
          }
        );
      }
    );
  }

  render() {
    return (
      <div className="Paymet-Page">
        <Flex className="content py-1">
          <Flex.Item className="content-contain px-5 pb-5">
            <WingBlank size="sm">
              <Flex>
                <Flex.Item>
                  <img
                    src={HVLogo}
                    className="HVLogo"
                    alt="HeartVoice - Logo"
                  />
                </Flex.Item>
              </Flex>
              <h3 className="checkTitle">Checkout</h3>
              <Flex className=" pb-5">
                <Flex.Item>
                  <div className="text">Status:</div>
                </Flex.Item>
                <Flex.Item className="flex-grow-15">
                  <div className="text text-pink f-uppercase">
                    {showStatus(this.state.user_queue_status)}
                  </div>
                </Flex.Item>
              </Flex>

              <Flex className=" pb-5">
                <Flex.Item>
                  <div className="text">Queue No.:</div>
                </Flex.Item>
                <Flex.Item className="flex-grow-15">
                  <div className="text text-b">{this.state.queue_number}</div>
                </Flex.Item>
              </Flex>
              {this.state.order_no ? (
                <Flex className=" pb-5">
                  <Flex.Item>
                    <div className="text">Order No.:</div>
                  </Flex.Item>
                  <Flex.Item className="flex-grow-15">
                    <div className="text text-b">{this.state.order_no}</div>
                  </Flex.Item>
                </Flex>
              ) : (
                ""
              )}
              <Flex className=" pb-5">
                <Flex.Item>
                  <div className="text">Date & Time:</div>
                </Flex.Item>
                <Flex.Item className="flex-grow-15">
                  <div className="text text-b">
                    {this.state.teleconsult_time}
                  </div>
                </Flex.Item>
              </Flex>

              <Flex className=" pb-5">
                <Flex.Item>
                  <div className="text">Provider:</div>
                </Flex.Item>
                <Flex.Item className="flex-grow-15">
                  <div className="text text-b">{this.state.coach_name}</div>
                </Flex.Item>
              </Flex>
            </WingBlank>
          </Flex.Item>
        </Flex>
        <div className="grayLine"></div>
        {this.state.medicationList && this.state.medicationList.length > 0 ? (
          <>
            <Flex className="content py-1">
              <Flex.Item>
                <WingBlank>
                  <div className="medication-list">
                    <div className="page-title py-5"> Medication </div>
                    {/* <i className="font-gray">Select which medications you want</i> */}
                    {this.state.medicationList &&
                      this.state.medicationList.map((item, index) => {
                        return (
                          <div
                            key={item.id}
                            className="medication-lists border-gray font-gray"
                            style={{
                              borderColor:
                                item.user_request == "--" || !item.user_request
                                  ? "#09B5C8"
                                  : "lightgray",
                            }}
                            onClick={this.clickMedication.bind(this, index)}
                          >
                            <Flex justify="between">
                              <div className="item-title">{item.name} </div>
                              {/* <div className="item-amount" style={{ color: 'black', fontSize: '14px' }}> ${item.price} </div> */}
                            </Flex>
                            <Flex justify="between">
                              <div className="item-title">
                                {item.frequency}{" "}
                              </div>
                            </Flex>
                            <Flex justify="between">
                              <div className="item-title">
                                {item.description}{" "}
                              </div>
                            </Flex>
                          </div>
                        );
                      })}
                  </div>
                </WingBlank>
              </Flex.Item>
            </Flex>
            <div className="grayLine"></div>
          </>
        ) : (
          ""
        )}
        {/* start */}
        {this.state.medication_patient_item_list.length > 0 && (
          <>
            <Flex className="content">
              <Flex.Item>
                <WingBlank>
                  <WhiteSpace size="md" />
                  <Flex>
                    <Flex.Item>
                      <div className="page-title py-5"> Item(s) </div>
                      <ul className="billList">
                        {this.state.medication_patient_item_list.map((item) => {
                          console.log(item, this.state.deleteMIds);
                          if (
                            this.state.deleteMIds.includes(
                              item.transaction_item_id
                            )
                          ) {
                            return;
                          }
                          return (
                            <li className="billitem" key={item.id}>
                              <Flex justify="between">
                                <div className="item-title">
                                  <big>
                                    {item.name}
                                    {item.qty == "1" ? "" : ` x ${item.qty}`}
                                  </big>
                                </div>
                                <div className="item-amount">
                                  $
                                  {currencyType(
                                    item.inventory_type === "concoctions"
                                      ? Math.abs(item.subtotal_price)
                                      : Math.abs(item.unit_price) * item.qty,
                                    this.state.currency_code
                                  )}
                                </div>
                              </Flex>
                              {!!item.discount && item.discount != 0 && (
                                <Flex justify="between">
                                  <div className="item-title">
                                    <big>Discount</big>
                                  </div>
                                  <div className="item-amount">
                                    -$
                                    {currencyType(
                                      (item.unit_price *
                                        item.discount *
                                        item.qty) /
                                        100,
                                      this.state.currency_code
                                    )}
                                  </div>
                                </Flex>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </Flex.Item>
                  </Flex>
                  <WhiteSpace size="md" />
                </WingBlank>
              </Flex.Item>
            </Flex>
            <div className="grayLine"></div>
          </>
        )}
        {/* end */}
        <div className="letter-line"></div>
        <Flex
          className="content"
          style={{ display: this.state.isDisplay ? "flex" : "none" }}
        >
          <Flex.Item>
            <WingBlank>
              {this.state.delivery && this.state.delivery.length > 0 ? (
                <Flex>
                  <Flex.Item>
                    <div className="delivery-option">
                      <div className="page-title py-5"> Delivery Option </div>
                      <div style={{ marginTop: 10, marginBottom: 10 }}>
                        {this.state.user_queue_status === "completed" ||
                        this.state.user_queue_status === "pick_up" ||
                        this.state.user_queue_status === "closed" ? (
                          <div className="delivery-accordion">
                            {this.state.selectedDelivery ? (
                              <DeliveryItem
                                deliver={this.state.selectedDelivery}
                                currencyCode={this.state.currency_code}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          <Accordion
                            className="delivery-accordion"
                            onChange={this.onChange}
                          >
                            <Accordion.Panel
                              ref={(accordion) =>
                                (this.accordionElement = accordion)
                              }
                              key="0"
                              header={
                                <DeliveryItem
                                  deliver={this.state.selectedDelivery}
                                  currencyCode={this.state.currency_code}
                                />
                              }
                            >
                              <List className="delivery-list">
                                {this.state.delivery &&
                                this.state.delivery.length > 0
                                  ? this.state.delivery.map((item) => {
                                      // console.log(this.state.selectedDelivery)
                                      let { id = false } =
                                        this.state.selectedDelivery || {};
                                      // console.log(id)
                                      return (
                                        <List.Item
                                          className={
                                            id && id === item.id
                                              ? "selectedDelivery"
                                              : ""
                                          }
                                          key={item.id}
                                        >
                                          <DeliveryItem
                                            selectDeliveryClick={this.selectDeliveryClick.bind(
                                              this,
                                              item
                                            )}
                                            deliver={item}
                                            currencyCode={
                                              this.state.currency_code
                                            }
                                          />
                                        </List.Item>
                                      );
                                    })
                                  : ""}
                              </List>
                            </Accordion.Panel>
                          </Accordion>
                        )}
                      </div>
                      <i className="font-gray word-space-3 line-height-1">
                        Delivery time subject to clinic opening hours.
                        <br />
                        Clinic contact no.: {this.state.clinic_number}
                        <br />
                        Helpdesk: +65 9617 7488{" "}
                        <a href="mailto:support@ourheartvoice.com">
                          support@ourheartvoice.com
                        </a>
                      </i>
                    </div>
                  </Flex.Item>
                </Flex>
              ) : null}

              <Flex
                className="content-inline"
                style={{
                  display: this.state.isDeliveryAddrDisplay ? "flex" : "none",
                }}
              >
                <Flex.Item>
                  <div className="delivery-address pb-5">
                    <div className="delivery-address-title">
                      <img
                        src={IconLoatcion}
                        alt=""
                        className="icon-loaction"
                      />
                      <div className="address-title"> Delivery Address </div>
                      {/* {this.state.name && this.state.postal_code && this.state.user_queue_status !== 'completed' || this.state.user_queue_status !== 'pick_up' ? <>
                                                <div className="edit-link" onClick={this.addAddress.bind(this)}>Edit</div>
                                            </> : <></>} */}
                      {this.state.user_queue_status === "completed" ||
                      this.state.user_queue_status === "pick_up" ||
                      this.state.user_queue_status === "paid" ||
                      this.state.user_queue_status === "on_route" ||
                      this.state.user_queue_status === "delivered" ||
                      this.state.user_queue_status === "closed" ? (
                        <></>
                      ) : (
                        <div
                          className="edit-link"
                          onClick={this.addAddress.bind(this)}
                        >
                          Edit
                        </div>
                      )}
                    </div>
                    <div className="address-desc">
                      {this.state.name || this.state.oname ? (
                        <>
                          <div
                            style={{
                              display:
                                this.state.name || this.state.oname
                                  ? "block"
                                  : "none",
                            }}
                          >
                            {this.state.recipientAddressId
                              ? this.state.name
                              : this.state.oname}
                            , +
                            {this.state.recipientAddressId
                              ? this.state.dialing_code
                              : this.state.odialingCode}{" "}
                            {this.state.recipientAddressId
                              ? this.state.mobile_number
                              : this.state.omobile}
                          </div>
                          <div
                            style={{
                              display:
                                this.state.name || this.state.oname
                                  ? "block"
                                  : "none",
                            }}
                          >
                            {this.state.recipientAddressId
                              ? this.state.address
                              : this.state.user_profile_info_address}
                            {this.state.recipientAddressId
                              ? ", " + this.state.unit_no
                              : ", " + this.state.ounitNo}
                          </div>
                          <div
                            style={{
                              display:
                                this.state.postal_code || this.state.postalCode
                                  ? "block"
                                  : "none",
                            }}
                          >
                            {this.state.recipientAddressId
                              ? this.state.country_code
                              : this.state.countryCode}{" "}
                            {this.state.recipientAddressId
                              ? this.state.postal_code
                              : this.state.postalCode}
                          </div>
                        </>
                      ) : (
                        <div onClick={this.addAddress.bind(this)}>
                          + Add Address
                        </div>
                      )}

                      {/* {this.state.name && this.state.postal_code ? <>
                                                <div style={{ display: this.state.name ? 'block' : 'none' }}>
                                                    { this.state.name }, +{this.state.recipientAddressId ? this.state.dialing_code : ''} {this.state.recipientAddressId ? this.state.mobile_number : ''}
                                                </div>
                                                <div style={{ display: this.state.name ? 'block' : 'none' }}>
                                                    {this.state.recipientAddressId ? this.state.address : this.state.user_profile_info_address}{this.state.recipientAddressId && this.state.unit_no ? ', ' + this.state.unit_no : ''}
                                                </div>
                                                <div style={{ display: this.state.postal_code ? 'block' : 'none' }}>
                                                    {this.state.recipientAddressId ? this.state.country_code : this.state.countryCode}  {this.state.recipientAddressId ? this.state.postal_code : this.state.postalCode}
                                                </div>
                                            </> : <div onClick={this.addAddress.bind(this)}>
                                                    + Add Address
                                            </div>} */}
                    </div>
                  </div>
                </Flex.Item>
              </Flex>
            </WingBlank>
          </Flex.Item>
        </Flex>

        <Flex className="content">
          <Flex.Item>
            <div className="coupon-selection">
              <WhiteSpace size="md" />
              {!this.state.selectedCoupon ? (
                <>
                  <div className="grayLine"></div>
                  <List.Item
                    className="CouponDiv"
                    arrow="horizontal"
                    onClick={this.coupon.bind(this)}
                  >
                    {this.state.payStatus === true
                      ? "No coupon applied"
                      : "Coupon"}
                  </List.Item>
                  <div className="grayLine"></div>
                </>
              ) : (
                <div className="selected-coupon">
                  <div className="selected-coupon-title">Coupon</div>
                  <div
                    className="selected-coupon-detail"
                    onClick={this.coupon.bind(this)}
                  >
                    <div className="selected-coupon-code">
                      {this.state.selectedCoupon.code}
                    </div>
                    <div className="selected-coupon-name">
                      {this.state.selectedCoupon.name}
                    </div>
                  </div>
                </div>
              )}
              <WhiteSpace size="md" />
            </div>
          </Flex.Item>
        </Flex>
        <Flex className="content">
          <Flex.Item>
            <WingBlank>
              <WhiteSpace size="md" />
              <Flex>
                <Flex.Item>
                  <div className="page-title py-5"> Summary </div>
                  {this.state.user_queue_status !== "completed" ? (
                    <ul className="billList">
                      <li className="billitem">
                        <Flex justify="between">
                          <div className="item-title"> Subtotal </div>
                          <div className="item-amount">
                            $
                            {currencyType(
                              this.state.subTotalAmount,
                              this.state.currency_code
                            )}
                          </div>
                        </Flex>
                      </li>
                      {(!!this.state.discount_fee ||
                        !!this.state.selectedCoupon?.name) && (
                        <li className="billitem">
                          <Flex justify="between">
                            <div className="item-title"> Discount </div>
                          </Flex>
                          {!!this.state.discount_fee && (
                            <Flex justify="between">
                              <div className="item-subtitle">
                                {" "}
                                Clinic Total Bill Discount{" "}
                              </div>
                              <div className="item-amount">
                                {" "}
                                -${MoneyToFixed(this.state.discount_fee)}{" "}
                              </div>
                            </Flex>
                          )}
                          {/* {
																!this.state.coupon_price &&
																<Flex justify="between">
																	<div className="item-subtitle"> Coupon </div>
																	<div className="item-amount"> -${MoneyToFixed(this.state.coupon_price)} </div>
																</Flex>
															} */}
                          {!!this.state.selectedCoupon && (
                            // {/* <div className="item-title">  Coupon </div> */}
                            <Flex justify="between">
                              <div className="item-subtitle">
                                {" "}
                                {this.state.selectedCoupon?.name}{" "}
                              </div>
                              <div className="item-amount">
                                {" "}
                                -$
                                {MoneyToFixed(
                                  this.state.displayCounponAmount
                                )}{" "}
                              </div>
                            </Flex>
                          )}
                        </li>
                      )}
                      {!!this.state.subsidy_list.length && (
                        <li className="billitem">
                          <div className="item-title"> Subsidy </div>
                          {this.state.subsidy_list.map((ite) => {
                            return (
                              <Flex justify="between" key={ite.id}>
                                <div className="item-subtitle">
                                  {" "}
                                  {ite.subsidy_name}{" "}
                                </div>
                                <div className="item-amount">
                                  {" "}
                                  -${MoneyToFixed(ite.subsidy_value)}{" "}
                                </div>
                              </Flex>
                            );
                          })}
                        </li>
                      )}
                      <li
                        className="billitem"
                        style={{
                          display: this.state.isDeliveryPriceDisplay
                            ? "block"
                            : "none",
                        }}
                      >
                        <Flex justify="between">
                          <div className="item-title">Delivery fee</div>
                          <div className="item-amount">
                            $
                            {currencyType(
                              this.state.selectedDelivery.price_after_subsidy,
                              this.state.currency_code
                            )}
                          </div>
                        </Flex>
                      </li>
                      {this.state.GST_reg_no && (
                        <li className="billitem">
                          <Flex justify="between">
                            <div className="item-title">
                              {this.state.GST_rate.toFixed(2)}% GST{" "}
                              {this.state.GST_calculation === "1" &&
                                "Inclusive"}
                            </div>
                            <div className="item-amount">
                              $
                              {currencyType(
                                _.toNumber(this.state.GST_inclusive),
                                this.state.currency_code
                              )}
                            </div>
                          </Flex>
                        </li>
                      )}
                      <li className="billitem">
                        <Flex justify="between">
                          <div className="item-title">Paid Amount</div>
                          <div className="item-amount">
                            -$
                            {currencyType(
                              this.state.paid_amount,
                              this.state.currency_code
                            )}
                          </div>
                        </Flex>
                      </li>
                      <li className="billitem text-big">
                        <Flex justify="between">
                          <div className="total-title">
                            <big>Total</big>
                          </div>
                          <div className="total-title">
                            <big>
                              $
                              {currencyType(
                                this.state.totalAmount,
                                this.state.currency_code
                              )}
                            </big>
                            {/* <big>{currencyType(this.state.totalNum)}</big> */}
                          </div>
                        </Flex>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </Flex.Item>
              </Flex>
              <WhiteSpace size="md" />
            </WingBlank>
          </Flex.Item>
        </Flex>

        <WingBlank>
          {this.state.user_queue_status === "completed" ||
          this.state.user_queue_status === "pick_up" ? null : (
            <div className="btn proceed-btn pb-5">
              <Flex className="content">
                <Flex.Item>
                  <Button
                    className="fill-pink-btn"
                    activeClassName="fill-pink-active-btn"
                    loading={this.state.loading}
                    onClick={this.ProceedToPayment.bind(this)}
                    disabled={
                      this.state.disabled || this.state.payStatus == true
                    }
                  >
                    Proceed to Payment
                  </Button>
                </Flex.Item>
              </Flex>
            </div>
          )}
        </WingBlank>

        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default Bill;
