import React, { Component } from "react";
import {
  NavBar,
  Flex,
  WhiteSpace,
  Picker,
  List,
  WingBlank,
  Checkbox,
  Toast,
  Button,
  Modal,
  InputItem,
  Icon,
} from "antd-mobile";
import { TCLink, DIALING_CODE } from "../commons/Constants";
import enUs from "antd-mobile/lib/date-picker/locale/en_US";
import CustomChildren from "../commons/CustomChildren";
import myInfo from "../images/icons/myinfo.jpeg";
import AuthGuard from "../services/AuthGuard";
import {
  setCache,
  addKeyValueForDorpList,
  isMobileNo,
  removeCache,
  vCodeChange,
  isEmail,
  getCache,
} from "../commons/Helpers";
import { isBrowser } from "react-device-detect";
import Select, { components } from "react-select";
import { DropdownIndicator } from "./SignUpCompleteInfo";
import _ from "lodash";

const AgreeItem = Checkbox.AgreeItem;

const SingleValue = ({
  cx,
  getStyles,
  selectProps,
  data,
  isDisabled,
  className,
  hasValue,
  ...props
}) => {
  const { value, flag } = data;
  if (!hasValue) {
    return (
      <div
        className={cx(
          {
            "single-value": true,
            "single-value--is-disabled": isDisabled,
            "border-color": "unset",
          },
          className
        )}
      >
        {selectProps.getOptionLabel(data)}
      </div>
    );
  }
  return (
    <div className="selectOptions">
      <img src={flag} /> +{value}
    </div>
  );
};

const selectStyles = {
  container: (base) => ({
    ...base,
    width: "100%",
    marginLeft: "0.1rem",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: 0,
    margin: 0,
    alignItems: "flex-start",
  }),
  control: (base) => ({
    ...base,
    border: "unset",
    boxShadow: "none",
  }),
  menu: (styles) => ({
    ...styles,
    width: "4rem",
  }),
  valueContainer: (styles) => ({
    ...styles,
    display: "flex",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    padding: 0,
    margin: 0,
    alignItems: "flex-start",
  }),
};

class SignUpProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labelDisplay: "none",
      nextBtnDisplay: "none",
      errorTxtDisplay: "none",
      errorMsg: {},
      dialingCode: DIALING_CODE,
      mobile: "",
      dialingCodeList: [],
      countryListArr: [],
      is_num: false,
      otpType: "EMAIL",
      email: "",
      tc: "N",
      other: "N",
    };
  }

  componentDidMount() {
    this.getCountryList();
  }

  getCountryList = () => {
    this.loadingToast();
    AuthGuard.getCountry({}, (isOk) => {
      if (isOk) {
        this.setState({
          dialingCodeList: addKeyValueForDorpList(
            AuthGuard.listCountrys,
            "dialing_code_name"
          ),
          countryListArr: AuthGuard.listCountrys,
        });
      }
      Toast.hide();
    });
  };

  loadingToast() {
    Toast.loading("Loading...", 30, () => {
      console.log("Load complete !!!");
    });
  }

  checkIsSuccess = () => {
    console.log("checkIsSuccess");
    if (this.state.errorMsg.emailMsg && this.state.errorMsg.mobileMsg) {
      return;
    }
    this.setState({ errorMsg: {} });
    let res = this.state.countryListArr.find((item, index) => {
      return item.dialing_code === this.state.dialingCode;
    });
    let resM = res.mobile_no_format ? res.mobile_no_format : "";
    let is_check = true;
    let emailMsg = "";
    let mobileMsg = "";

    let _redirectUrl = getCache("redirectUrl");
    let email_mobile_required = _redirectUrl
      ? _.split(_redirectUrl, "/")
      : null;

    // 当是Health corporate healthscreen / Health corporate Teleconsultation Event时候 email 、mobile 必填
    if (
      _.isArray(email_mobile_required) &&
      _.includes(["ct", "hs"], email_mobile_required[1])
    ) {
      if (!this.state.email) {
        is_check = false;
        emailMsg = "Please fill in the required field.";
      } else if (this.state.email && !isEmail(this.state.email)) {
        is_check = false;
        emailMsg = "Please fill in a valid email address.";
      }

      if (!this.state.mobile) {
        is_check = false;
        mobileMsg = "Please fill in the required field.";
      } else if (
        this.state.mobile &&
        !isMobileNo(this.state.mobile, this.state.dialingCode, resM)
      ) {
        is_check = false;
        mobileMsg = "Please fill in a valid mobile number.";
      }
    } else {
      // 当不是 Health corporate healthscreen / Health corporate Teleconsultation Event时候 email 、mobile 二选一
      if (!this.state.email && !this.state.mobile) {
        is_check = false;
        emailMsg = "Please fill in the required field.";
      } else if (this.state.email && !isEmail(this.state.email)) {
        is_check = false;
        emailMsg = "Please fill in a valid email address.";
      }

      if (!this.state.mobile && !this.state.email) {
        is_check = false;
        mobileMsg = "Please fill in the required field.";
      } else if (
        this.state.mobile &&
        !isMobileNo(this.state.mobile, this.state.dialingCode, resM)
      ) {
        is_check = false;
        mobileMsg = "Please fill in a valid mobile number.";
      }
    }

    console.log(this.state.email);
    console.log(this.state.mobile);
    console.log(mobileMsg, emailMsg);

    this.setState({
      errorMsg: {
        emailMsg: emailMsg,
        mobileMsg: mobileMsg,
      },
    });
    if (!is_check) {
      return false;
    } else {
      return true;
    }
  };

  sendOpt = () => {
    let is_check = this.checkIsSuccess();
    console.log("sendOpt", is_check);
    if (!is_check) {
      return;
    }
    if (this.state.tc == "N") {
      Modal.alert(
        "Error",
        "Please read and agree to the Terms & Conditions before proceeding.",
        [{ text: "OK", onPress: () => console.log("ok") }]
      );
      return;
    }
    this.loadingToast();
    AuthGuard.SignUpSendOtp(
      {
        email: this.state.email,
        dialingCode: this.state.mobile ? this.state.dialingCode : "",
        mobile: this.state.mobile,
      },
      (isOk, res) => {
        console.log(res);
        if (isOk) {
          let cacheObj = {
            fromPage: "/signUpProfile",
            type: "sign_up",
            dialingCode: this.state.dialingCode,
            mobile: this.state.mobile,
            email: this.state.email,
            tc: this.state.tc,
            other: this.state.other,
          };
          removeCache("verifyOptFor");
          setCache("verifyOptFor", cacheObj);
          this.props.history.push("/newVerifyOTP/");
        } else {
          if (res.status_code == 11033) {
            if (res.result.length == 1 && res.result[0] == "mobile") {
              this.setState({
                errorMsg: { mobileMsg: res.message },
              });
            } else if (res.result.length == 1 && res.result[0] == "email") {
              this.setState({
                errorMsg: { emailMsg: res.message },
              });
            } else if (res.result.length == 2) {
              this.setState({
                errorMsg: {
                  emailMsg: res.message,
                  mobileMsg: res.message,
                },
              });
            } else {
              Modal.alert("warning", res.message, [
                { text: "OK", onPress: () => console.log("ok") },
              ]);
            }
          } else if (res.status_code == 11015) {
            this.setState({
              errorMsg: { mobileMsg: res.message },
            });
          } else {
            Modal.alert("warning", res.message, [
              { text: "OK", onPress: () => console.log("ok") },
            ]);
          }
        }
        Toast.hide();
      }
    );
  };

  getSingPassUrl = () => {
    this.loadingToast();
    AuthGuard.getSingPassUrl({}, (isOk, res) => {
      if (isOk) {
        setCache("is_sign", { type: "sign_up" });
        window.location.href = res.url;
      }
      Toast.hide();
    });
  };

  goBack = () => {
    this.props.history.push("/login");
  };

  checkIsExist = (type, value) => {
    this.loadingToast();
    if (type == "mobile") {
      value = this.state.dialingCode.concat(value);
    }
    let emailMsg = this.state.errorMsg.emailMsg;
    let mobileMsg = this.state.errorMsg.mobileMsg;
    AuthGuard.checkIsExist({ type: type, value: value }, (isOk, res) => {
      if (!isOk) {
        if (type == "email") {
          let errorMsg = {};
          if (mobileMsg) errorMsg = { emailMsg: res, mobileMsg: mobileMsg };
          else errorMsg = { emailMsg: res };
          this.setState({ errorMsg: errorMsg });
        } else {
          let errorMsg = {};
          if (emailMsg) errorMsg = { emailMsg: emailMsg, mobileMsg: res };
          else errorMsg = { mobileMsg: res };
          this.setState({ errorMsg: errorMsg });
        }
      } else {
        if (type == "email") {
          let errorMsg = {};
          if (mobileMsg) errorMsg = { emailMsg: "", mobileMsg: mobileMsg };
          else errorMsg = { emailMsg: "", mobileMsg: "" };
          this.setState({ errorMsg: errorMsg });
        } else {
          let errorMsg = {};
          if (emailMsg) errorMsg = { emailMsg: emailMsg, mobileMsg: "" };
          else errorMsg = { mobileMsg: "" };
          this.setState({ errorMsg: errorMsg });
        }
      }
      Toast.hide();
    });
  };

  render() {
    let options = [];
    if (isBrowser) {
      this.state.countryListArr.map((item) => {
        options.push({
          value: item.dialing_code,
          flag: item.flag_image_url?.url?.s,
          label: (
            <div className="selectOptions">
              <img src={item.flag_image_url?.url?.s} /> {item.name} +
              {item.dialing_code}
            </div>
          ),
        });
      });
    }

    return (
      <div className="Register-Page-V2 h5-Page login-Page">
        <NavBar
          mode="light"
          icon={<Icon type="left" color="#EB5DA6" size="lg" />}
          onLeftClick={this.goBack}
        ></NavBar>
        <WingBlank>
          <div className="content">
            <WhiteSpace size="lg" />
            <Flex>
              <Flex.Item>
                <p className="sign-up-title">Sign up with us!</p>
              </Flex.Item>
            </Flex>
            <WhiteSpace size="lg" />
            <WhiteSpace size="lg" />
            <Flex>
              <Flex.Item>
                <div
                  className="progress-bar-left-right"
                  style={{ backgroundColor: "#EB5DA6" }}
                ></div>
                <div className="progress-bar"></div>
                <div className="progress-bar"></div>
                <div className="progress-bar-left-right"></div>
              </Flex.Item>
            </Flex>
            <WhiteSpace size="lg" />
            <WhiteSpace size="lg" />
            <Flex className="page-myinfo completeWithMyinfoBt">
              <Flex className="size-1" onClick={this.getSingPassUrl.bind(this)}>
                <p>Sign Up with </p>
                <img className="page-myinfo-img" src={myInfo} alt="" />
              </Flex>
            </Flex>
            <WhiteSpace size="lg" />
            <div className="input_item">
              <div className="input-title">
                {this.state.email ? "Email " : ""}
              </div>
              <Flex>
                <Flex.Item>
                  <InputItem
                    clear
                    // className="inputField"
                    name="mobile_email"
                    placeholder="Email "
                    error={this.state.emailError}
                    value={this.state.email}
                    onBlur={(v) => {
                      let _email = v.trim();
                      if (_email) {
                        this.checkIsExist("email", _email);
                      }
                    }}
                    onChange={(v) => {
                      let _email = v.trim();
                      this.setState({ email: _email });
                    }}
                  ></InputItem>
                </Flex.Item>
              </Flex>
            </div>
            {this.state.errorMsg.emailMsg ? (
              <Flex.Item className="errorMsg">
                <WhiteSpace size="md" />
                <p style={{ marginTop: "10px" }}>
                  {this.state.errorMsg.emailMsg}
                </p>
              </Flex.Item>
            ) : (
              ""
            )}
            <WhiteSpace size="lg" />
            <div className="input_item">
              <div className="input-title">
                {this.state.mobile ? "Mobile Number " : ""}
              </div>
              <Flex className={`${this.state._dialingCode ? "flGrey" : ""}`}>
                {isBrowser ? (
                  <Flex>
                    <Select
                      components={{ DropdownIndicator, SingleValue }}
                      name="dialingCode"
                      value={
                        this.state.dialingCode
                          ? options.find(
                              (option) => option.value == this.state.dialingCode
                            )
                          : null
                      }
                      onChange={(e) => {
                        this.setState({
                          dialingCode: e.value,
                        });
                      }}
                      styles={selectStyles}
                      theme={(theme) => ({
                        ...theme,
                        zIndex: -100000,
                        border: "1px solid #ffffff",
                      })}
                      menuPortalTarget={document.querySelector("body")}
                      isSearchable={false}
                      options={options}
                      placeholder={"Select Dialing Code"}
                    />
                  </Flex>
                ) : (
                  <Picker
                    data={this.state.dialingCodeList}
                    locale={enUs}
                    extra="Dialing Code"
                    disabled={this.state._dialingCode}
                    value={[this.state.dialingCode]}
                    cols={1}
                    format={(labels) => {
                      if (!labels.length) return;
                      return [
                        labels[0].props.children[0],
                        labels[0].props.children[2],
                      ];
                    }}
                    onChange={(v) => {
                      this.setState({
                        dialingCode: v[0],
                      });
                    }}
                  >
                    <CustomChildren
                      sClas={this.state.dialingCode ? "sel" : "noSel"}
                    ></CustomChildren>
                  </Picker>
                )}
                <Flex.Item>
                  <InputItem
                    clear
                    value={this.state.mobile.trim()}
                    error={this.state.mobileError}
                    disabled={this.state._mobile}
                    placeholder="Mobile Number "
                    type="number"
                    onBlur={(v) => {
                      let mobile = v.trim();
                      if (mobile) {
                        this.checkIsExist("mobile", mobile);
                      }
                    }}
                    onChange={(v) => this.setState({ mobile: v })}
                  ></InputItem>
                </Flex.Item>
              </Flex>
            </div>
            {this.state.errorMsg.dialingCode ? (
              <Flex.Item className="errorMsg">
                <WhiteSpace size="md" />
                <p style={{ marginTop: "10px" }}>
                  {this.state.errorMsg.dialingCode}
                </p>
              </Flex.Item>
            ) : (
              ""
            )}
            {this.state.errorMsg.mobileMsg ? (
              <Flex.Item className="errorMsg">
                <WhiteSpace size="md" />
                <p style={{ marginTop: "10px" }}>
                  {this.state.errorMsg.mobileMsg}
                </p>
              </Flex.Item>
            ) : (
              ""
            )}
            <WhiteSpace size="lg" />
            {/* <div className="gray-line-all">
                            <div className="gray-line"></div>
                            <div className="gary-font">OR</div>
                            <div className="gray-line"></div>
                        </div> */}
            <WhiteSpace size="lg" style={{ marginTop: "10em" }} />

            <AgreeItem
              data-seed="logId"
              onChange={(e) => {
                if (e.target.checked) {
                  this.setState({ tc: "Y" });
                } else {
                  this.setState({ tc: "N" });
                }
              }}
              style={{ padding: "0px", margin: "1em 0px" }}
            >
              <span>
                By continuing, I agree to the
                <a
                  href="https://www.ourheartvoice.com/termsandconditions/"
                  target="_blank"
                  style={{ color: "#EB5DA6" }}
                >
                  {" "}
                  Terms & Conditions and Privacy Policy
                </a>
              </span>
            </AgreeItem>
            <AgreeItem
              data-seed="logId2"
              onChange={(e) => {
                if (e.target.checked) {
                  this.setState({ other: "Y" });
                } else {
                  this.setState({ other: "N" });
                }
              }}
              style={{ padding: "0px", margin: "1em 0px" }}
            >
              <span>Please keep me updated on news, events, offers.</span>
            </AgreeItem>
            <Button
              className="fill-pink-btn"
              activeClassName="fill-pink-active-btn"
              onClick={this.sendOpt}
            >
              Next
            </Button>
          </div>
        </WingBlank>
      </div>
    );
  }
}

export default SignUpProfile;
