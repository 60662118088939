import React, { Component } from "react";
import {
  Flex,
  WhiteSpace,
  WingBlank,
  TabBar,
  Icon,
  Grid,
  Toast,
  Modal,
  ListView,
  Button,
  NavBar
} from "antd-mobile";
import HVLogo from "../images/icons/IconHeartVoiceLong.svg";
import IconSuccess from "../images/icons/IconResultSuccess.svg";
import success from '../images/icons/success.png';
import { WHATSAPP_MOBILENO } from "../commons/Constants";
import { getCache, currencyType, removeCache } from '../commons/Helpers';

class HSSuccess extends Component {

  constructor(props) {
    super(props);
    this.state = {
      totalAmount: 0.00,
    }
  }

  componentDidMount() {
    if (getCache('hs_payInfo')) {
      let { totalAmount } = JSON.parse(getCache('hs_payInfo'));
      this.setState({
        totalAmount: totalAmount,
      })
    } else {
      this.props.history.push("/404");
    }
  }

  goToWA = () => {
    window.location.href = "https://wa.me/" + WHATSAPP_MOBILENO;
  };

  goBack = () => {
    if (getCache('clinic_id')) {
      this.props.history.push("/DoctorDetails/" + getCache('clinic_id') + '?tab=3');
    }
  }

  render() {
    return (
      <div className="Paymet-Page result-success-fail-page">
        <NavBar
          mode="light"
          icon={<Icon type="left" color="#EB5DA6" size="lg" />}
          onLeftClick={this.goBack}
        ></NavBar>
        <Flex className="content">
          <Flex.Item>
            <WingBlank>
              <WhiteSpace size="md" />
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <Flex direction="column">
                <img src={success} className="icon-payment-fail" alt="Result - Success" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <div className="result-title">Payment Success</div>
                <WhiteSpace size="md" />
                <div className="result-desc hs_success">
                  Your payment for ${this.state.totalAmount} was successfully completed.
                </div>
              </Flex>
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
            </WingBlank>
          </Flex.Item>
        </Flex>
      </div>

    );
  }
}

export default HSSuccess;
