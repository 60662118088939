import React, { Component } from "react";
import { Button, Flex, Icon, NavBar, Toast, Modal } from "antd-mobile";
import { GOOGLE_ANALYTICS } from "../commons/Constants";
import ReactGA from "react-ga";
import AuthGuard from "../services/AuthGuard";
import { isBrowser } from "react-device-detect";
import LoginModel from "../component/LoginModel";
import { getCache, setCache, removeCache } from "../commons/Helpers";
import _ from "lodash";
import JsFileDownloader from "js-file-downloader";

class DoctorScreeningDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.location.state,
      pdfUrl: "",
      pdfName: "",
    };
  }

  onRef = (ref) => {
    this.loginModel = ref;
  };

  async componentDidMount() {
    if (getCache("source") == "DoctorDetails") {
      setCache("source_back", "DoctorDetails");
    } else if (getCache("source") == "BookNow") {
      setCache("source_back", "BookNow");
    } else {
      removeCache("source");
    }
    if (GOOGLE_ANALYTICS) {
      ReactGA.set({
        page: window.location.href,
      });
    }
    const { id, document } = this.state;
    if (!id) this.initState(this.props.match.params.id);
    if (document)
      this.setState({
        pdfUrl: _.head(document).s3_url,
        pdfName: _.head(document).file_name,
      });
  }

  goBack = () => {
    console.log(this.state);
    const { prePath, clinic_id } = this.state;
    if (this.state.prePath === "hsl") {
      this.props.history.goBack();
    } else if (this.state.clinic_id) {
      this.props.history.push(
        "/DoctorDetails/" + this.state.clinic_id + "?tab=3"
      );
    } else {
      this.props.history.goBack();
    }
  };

  handleConfirm = () => {
    let _url = window.location.pathname;
    if (!AuthGuard.checkLogin()) {
      this.loginModel.alertLogin({
        url: _url,
        query: this.props.match.params.id,
        alert_title: "You need a HeartVoice account to consult doctors.",
      });
    } else {
      if (navigator.userAgent.indexOf("heartvoice") !== -1) {
        window.location.href =
          "heartvoice://heartvoice/home?action=HealthScreeningCheckout&package_id=" +
          this.props.match.params.id;
      } else {
        this.loadingToast();
        AuthGuard.getProfileInfo({}, (isOk, res) => {
          Toast.hide();
          if (isOk) {
            console.log(res);
            if (
              !res.email ||
              !res.mobile ||
              !res.dialingCode ||
              !res.fullName ||
              !res.birthday ||
              !res.nric ||
              !res?.identification_type?.code ||
              !res?.gender ||
              !res?.race ||
              !res?.user_nationality ||
              !res?.country_code ||
              !res?.postal_code ||
              !res?.address
            ) {
              setCache("miss_info", {
                email: res.email,
                dialing_code: res.dialingCode,
                mobile_number: res.mobile,
                name: res.fullName,
                birth: res.birthday,
                nric: res.nric,
                identification_type: res?.identification_type?.code,
                gender: res?.gender,
                race: res?.race,
                nationality: res?.user_nationality,
                country_code: res?.country_code,
                province: res?.province,
                city: res?.city,
                postal_code: res?.postal_code,
                unit_no: res?.unit_no,
                address: res?.address,
              });
              let url = window.location.pathname;
              setCache("redirectUrl", url);
              this.props.history.push("/missProfileInfo");
            } else {
              this.props.history.push(
                `/bookHSWithClinic/${this.props.match.params.id}`
              );
            }
          } else {
            Modal.alert("warning", res.message, [
              { text: "OK", onPress: () => console.log("ok") },
            ]);
          }
        });
      }
    }
  };

  downloadHealthScreen(url, name) {
    if (!url) return;
    if (isBrowser) {
      new JsFileDownloader({
        url: url,
        filename: name,
      })
        .then((res) => {})
        .catch((error) => {
          console.log(error);
        });
    } else {
      window.location.href = `heartvoice://heartvoice/home?action=downloadFile&fileUrl=${url}&fileName=${name}`;
    }
  }

  loadingToast() {
    Toast.loading("Loading...", 30, () => {});
  }

  initState(id) {
    let _this = this;
    AuthGuard.getPackageInfo(
      {
        id,
      },
      (isOk, res) => {
        console.log(isOk, res);
        if (isOk) {
          _this.setState({
            ...res,
          });
        }
      }
    );
  }

  render() {
    const {
      name,
      merchant_name,
      unit_price,
      package_details,
      health_screening_type,
      patient_type_display,
    } = this.state;
    return (
      <div className="Screening-Details">
        <LoginModel onRef={this.onRef} history={this.props.history} />
        {!isBrowser ? (
          <NavBar
            mode="light"
            icon={<Icon type="left" color="#EB5DA6" size="lg" />}
            onLeftClick={() => this.goBack()}
          ></NavBar>
        ) : (
          ""
        )}
        <Flex className="screening_content">
          <Flex.Item>
            <div className="health_screening_head">
              <div>{name || "-"}</div>
              <div className="merchant_name">
                {merchant_name || "-"}
                <br />
                {health_screening_type === "corporate" &&
                  !!patient_type_display && (
                    <p style={{ marginBottom: 0 }}>
                      This Package is for {patient_type_display}
                    </p>
                  )}
              </div>
              <div>${unit_price || "0.00"}</div>
            </div>
            <br />
            <br />
            <div className="about_package_datail">
              <div
                className="about_package_title"
                style={{ fontSize: 16, fontWeight: "blod" }}
              >
                About Package
              </div>
              <div
                className="about_package_content"
                style={{
                  lineHeight: 1.5,
                  fontSize: 16,
                  color: "gray",
                  fontWeight: "blod",
                }}
                dangerouslySetInnerHTML={{
                  __html: package_details
                    ? package_details.replace(new RegExp("\n", "gm"), "<br />")
                    : "-",
                }}
              ></div>
            </div>
            {this.state.pdfUrl &&
              (navigator.userAgent.indexOf("heartvoice") !== -1 ? (
                <Button
                  className="view_package_overview outline-pink-btn"
                  onClick={() =>
                    this.downloadHealthScreen(
                      this.state.pdfUrl,
                      this.state.pdfName
                    )
                  }
                >
                  View Package Overview
                </Button>
              ) : (
                <Button
                  className="view_package_overview outline-pink-btn"
                  href={this.state.pdfUrl}
                >
                  View Package Overview
                </Button>
              ))}
            <Button
              className="confirm_screening fill-pink-btn"
              onClick={() => this.handleConfirm()}
            >
              Confirm
            </Button>
          </Flex.Item>
        </Flex>
      </div>
    );
  }
}

export default DoctorScreeningDetails;
