import React, { Component } from 'react';
import { Flex, WhiteSpace, WingBlank, NavBar, TabBar, Icon, Grid, Toast, Modal, ListView, Button, Checkbox, List } from 'antd-mobile';
import { WHATSAPP_MOBILENO, GOOGLE_ANALYTICS } from '../commons/Constants'
import {
    isBrowser,
    isMobile
} from "react-device-detect";
import AuthGuard from '../services/AuthGuard';
import LoginModel from '../component/LoginModel';
import { setCache, getCache } from '../commons/Helpers';
import MarketPlaceImg from '../images/icons/PD_productdetails.png';
import logo from '../images/icons/logo.png';
import ReactGA from "react-ga";
import { ReactGAEvent } from '../commons/ReactGAEvent';

const CheckboxItem = Checkbox.CheckboxItem;
const AgreeItem = Checkbox.AgreeItem;

class MarketPlaceDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            marketPlaceInfoName: '',
            photoUrl: '',
            marketPlaceInfoPrice: '',
            desc: '',
            static_url: '',
            packages_id: '',
            is_share_personal_data: 'N',
            is_contacted_by_ge: 'N',
            merchant_logo: '',
            packages_sub_type: ''
        };
    }

    componentDidMount() {
        let { id } = this.props.match.params;
        this.setState({
            packages_id: id
        })

        if (getCache('is_share_personal_data')) {
            this.setState({
                is_share_personal_data: getCache('is_share_personal_data')
            })
        }


        if (getCache('is_contacted_by_ge')) {
            this.setState({
                is_contacted_by_ge: getCache('is_contacted_by_ge')
            })
        }

        console.log(getCache('is_share_personal_data'), getCache('is_contacted_by_ge'))

        AuthGuard.marketPlaceDetailInfo({
            packages_id: id
        }, (isOk, res) => {
            console.log(isOk, res)
            this.setState({
                marketPlaceInfoName: res.name ? res.name : '',
                photoUrl: res.photo.url.l ? res.photo.url.l : '',
                marketPlaceInfoPrice: res.price ? res.price : '',
                desc: res.description ? res.description : '',
                static_url: res.static_url ? res.static_url : '',
                merchant_logo: res.merchant_logo.url ? res.merchant_logo.url.l : '',
                packages_sub_type: res.packages_sub_type ? res.packages_sub_type : ''
            })

        })

    }

    goBack = () => {
        window.location.href = 'https://www.ourheartvoice.com/back-on-track/'
    }

    ckeck(type) {

        let typeOne = document.querySelector('.radio-typeOne input')
        let typeTwo = document.querySelector('.radio-typeTwo input')
        console.log(typeOne, typeTwo)
        if (typeOne.checked == true) {
            this.setState({
                is_share_personal_data: 'Y'
            }, () => {
                setCache('is_share_personal_data', this.state.is_share_personal_data)
            })
        } else {
            this.setState({
                is_share_personal_data: 'N'
            }, () => {
                setCache('is_share_personal_data', this.state.is_share_personal_data)
            })
        }

        if (typeTwo.checked == true) {
            this.setState({
                is_contacted_by_ge: 'Y'
            }, () => {
                setCache('is_contacted_by_ge', this.state.is_contacted_by_ge)
            })
        } else {
            this.setState({
                is_contacted_by_ge: 'N'
            }, () => {
                setCache('is_contacted_by_ge', this.state.is_contacted_by_ge)
            })
        }
    }

    onRef = (ref) => {
        this.loginModel = ref
    }

    hasBought = () => {

        AuthGuard.hasBought({
            packages_id: this.state.packages_id
        }, (isOk, res) => {
            if (isOk) {
                let RGAValue = JSON.stringify({
                  send_to: "AW-686013963/XQfuCKnr1eEBEIv8jscC",
                  transaction_id: this.state.packages_id,
                });
                ReactGAEvent("conversion", RGAValue)
                this.props.history.push({ pathname: "/geCheckOutPage/" + this.state.packages_id + '/' + this.state.is_share_personal_data + '/' + this.state.is_contacted_by_ge });
            } else {
                if (res) {
                    Modal.alert('Oops!', res, [
                        { text: 'OK', onPress: () => console.log('ok') },
                    ]);
                }
            }
        })
    }

    MarketPlaceToPay = async (id) => {
        if (this.state.is_share_personal_data === 'N') {
            Modal.alert('Oops!', 'Please check the Terms & Conditions checkbox (first checkbox) to proceed with the purchase.', [
                { text: 'OK', onPress: () => console.log('ok') },
            ]);
        } else {
            setCache('MarketPlaceDetail', 'true')
            setCache('packages_id', this.state.packages_id)
            this.hasBought()
        }
    }

    render() {
        return (
            <div className="marketPlaceDetail-Page">
                <LoginModel
                    onRef={this.onRef}
                    history={this.props.history}
                />
                {console.log(this.props.history)}
                {!isBrowser ? <NavBar
                    mode="light"
                    icon={<Icon type="left" color="#EB5DA6" size="lg" />}
                    onLeftClick={() => this.goBack()}
                ></NavBar> :
                    null}
                <Flex className="marketPlaceDetail-content">
                    <Flex.Item>
                        <div className="marketPlaceDetail-header">
                            <Flex className="marketPlace-header-img">
                                <Flex.Item>
                                    <img className="marketPlaceImg" src={this.state.photoUrl} />
                                    {/* <img className="marketPlaceImg" src={MarketPlaceImg} /> */}
                                </Flex.Item>
                            </Flex>
                            <div className="pc-marketPlaceDetail">
                                <div className="content">
                                    <Flex>
                                        <Flex.Item>
                                            <h2>{this.state.marketPlaceInfoName}</h2>
                                        </Flex.Item>
                                    </Flex>
                                    <Flex>
                                        <Flex.Item>
                                            <div>
                                                <span className="marketPlaceDetail-price">${this.state.marketPlaceInfoPrice}</span>
                                                <span className="gst">GST Included</span>
                                            </div>
                                        </Flex.Item>
                                    </Flex>
                                </div>

                                {
                                    isBrowser ?
                                        <div className="marketPlaceDetail-package">
                                            <div className="content">
                                                <div className="radio-typeOne">
                                                    <AgreeItem data-seed="logId" checked={this.state.is_share_personal_data === 'Y' ? true : false} onChange={this.ckeck.bind(this, 'typeOne')}>
                                                        <span>
                                                            By purchasing this programme, I agree for
                                                            HeartVoice to share my Personal Data with
                                                            HeartVoice’s partnered Clinics and I agree to the
                                                <a href="https://www.ourheartvoice.com/back-on-track-terms-and-conditions" target="_blank">  Terms & Conditions </a>.
                                                I further agree to the <a href="https://www.ourheartvoice.com/mozziesafe-terms-and-conditions" target="_blank"> Terms & Conditions </a>
                                                of the GREAT MozzieSafe (Basic Plan).
                                            </span>
                                                    </AgreeItem>


                                                </div>
                                                <div className="radio-typeTwo">
                                                    <AgreeItem data-seed="logId" checked={this.state.is_contacted_by_ge === 'Y' ? true : false} onChange={this.ckeck.bind(this, 'typeTwo')}>
                                                        <span>
                                                            I would like to be contacted by Great Eastern
                                                            for future insurance products, special offers and related
                                                information <a href="https://www.greateasternlife.com/sg/en/privacy-and-security-policy.html" target="_blank"> (GE’s privacy policy) </a> and am aware that I
                                                may withdraw my consent via GE’s website at any
                                                time.
                                                        </span>
                                                    </AgreeItem>
                                                </div>
                                                <Button
                                                    className="fill-pink-btn buy"
                                                    activeClassName="fill-pink-active-btn"
                                                    onClick={this.MarketPlaceToPay.bind(this)}
                                                >
                                                    Buy
                                                </Button>
                                            </div>
                                        </div> : null
                                }
                            </div>
                        </div>

                        {/* <div className="home-header marketPlaceDetail-logo">
                            <div className="content">
                                <Flex>
                                    <Flex.Item>
                                        <h3>Presented by</h3>
                                    </Flex.Item>
                                </Flex>
                                <Flex>
                                    <Flex.Item>
                                        <img className="img-left" style={{ width: '40%' }} src={this.state.merchant_logo} />
                                    </Flex.Item>
                                </Flex>
                            </div>
                        </div> */}

                        <div className="marketPlaceDetail-package">
                            <div className="content">
                                {
                                    this.state.packages_sub_type === 'self' ? <iframe src={this.state.static_url} id="frame" frameBorder="0" height="2000px" width='100%'></iframe> : <iframe src={this.state.static_url} id="frame" frameBorder="0" height="2460px" width='100%'></iframe>
                                }

                            </div>
                        </div>
                        {
                            !isBrowser ?
                                <div className="marketPlaceDetail-package" style={{ marginBottom: '45px' }}>
                                    <div className="content" style={{ paddingBottom: 0 }}>
                                        <div className="radio-typeOne">
                                            <AgreeItem data-seed="logId" checked={this.state.is_share_personal_data == 'Y' ? true : false} onChange={this.ckeck.bind(this, 'typeOne')}>
                                                <span>
                                                    By purchasing this programme, I agree for
                                                    HeartVoice to share my Personal Data with
                                                    HeartVoice’s partnered Clinics and I agree to the
                                                <a href="https://www.ourheartvoice.com/back-on-track-terms-and-conditions" target="_blank">  Terms & Conditions </a>.
                                                I further agree to the <a href="https://www.ourheartvoice.com/mozziesafe-terms-and-conditions" target="_blank"> Terms & Conditions </a>
                                                of the GREAT MozzieSafe (Basic Plan).
                                            </span>
                                            </AgreeItem>
                                        </div>
                                        <div className="radio-typeTwo">
                                            <AgreeItem data-seed="logId" checked={this.state.is_contacted_by_ge == 'Y' ? true : false} onChange={this.ckeck.bind(this, 'typeTwo')}>
                                                <span>
                                                    I would like to be contacted by Great Eastern
                                                    for future insurance products, special offers and related
                                                information <a href="https://www.greateasternlife.com/sg/en/privacy-and-security-policy.html" target="_blank"> (GE’s privacy policy) </a> and am aware that I
                                                may withdraw my consent via GE’s website at any
                                                time.
                                            </span>
                                            </AgreeItem>
                                        </div>

                                    </div>
                                </div> : null
                        }
                        {
                            !isBrowser ?
                                <div className="buy"
                                    style={{
                                        width: '90%',
                                        // backgroundColor: 'white',
                                        position: 'fixed',
                                        bottom: 0,
                                        left: 0,
                                        marginLeft: '5%'
                                    }}>
                                    <Button
                                        className="fill-pink-btn"
                                        style={{ width: '90%', left: '5%' }}
                                        activeClassName="fill-pink-active-btn"
                                        onClick={this.MarketPlaceToPay.bind(this)}
                                    >
                                        Buy
                                    </Button>
                                </div> : null}
                    </Flex.Item>
                </Flex>
            </div>
        );
    }
}

export default MarketPlaceDetail;