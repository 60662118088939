import Home from "./page/Home";
import Bill from "./page/Bill";
import Fail from "./page/Fail";
import Payment from "./page/Payment";
import Success from "./page/Success";
import PaymentStatus from "./page/PaymentStatus";
import PageNotFound from "./page/NotFound";
import Invalidomain from "./page/Invalidomain";
import PlatformCheck from "./page/PlatformCheck";
import LeaveRoom from "./page/LeaveRoom";
// import Register from './page/Register'
import Login from "./page/LoginV2";

import SignUpProfile from "./page/SignUpProfile";
import SignUpMyinfo from "./page/SignUpMyinfo";
import SignUpCompleteInfo from "./page/SignUpCompleteInfo";
import VerifyOTPV2 from "./page/VerifyOTPV2";
import MissProfileInfo from "./page/MissProfileInfo";

import LoginByMobile from "./page/LoginByMobile";
import LoginByEmail from "./page/LoginByEmail";

import CompleteProfile from "./page/CompleteProfile";
import Registry from "./page/Registry";
import PerfectInfo from "./page/PerfectInfo";
import SubmitProfileResult from "./page/SubmitProfileResult";
import VerifyOTP from "./page/VerifyOTP";
import AddAddress from "./page/AddAddress";
import Coupon from "./page/Coupon";
import CouponDetail from "./page/CouponDetail";
import DoctorList from "./page/DoctorList";
import DoctorDetails from "./page/DoctorDetails";
import WebHome from "./page/WebHome";

import GoogleMap from "./page/GoogleMap";
import FlyPage from "./page/FlyPage";
import ShortLinkForDoctorDetails from "./page/ShortLinkForDoctorDetails";

import SwiperCom from "./page/SwiperCom";
import ProfileResultHeight from "./page/ProfileResultHeight";
import ReferralCode from "./page/ReferralCode";
import JoinPrograms from "./page/JoinPrograms";
import RequestRust from "./page/RequestRust";
import ProgramsPayment from "./page/ProgramsPayment";
import PaymentResultSuccess from "./page/PaymentResultSuccess";
import PaymentFail from "./page/PaymentFail";
import DrugAllergy from "./page/DrugAllergy";
import ProgramsSuccess from "./page/ProgramsSuccess";
import CheckOutPage from "./page/CheckOutPage";
import PaymentCoupon from "./page/PaymentCoupon";
import PaymentCouponDetail from "./page/PaymentCouponDetail";
import ProgramsPaymentStatus from "./page/ProgramsPaymentStatus";
import AcceptOrDeclien from "./page/AcceptOrDeclien";
import OpenNestedLink from "./page/OpenNestedLink";
import asgDrLink from "./page/AsgDrLink";
import geDrLink from "./page/GeDrLink";
import getDrList from "./page/GetDrList";
import GECheckOutPage from "./page/GECheckOutPage";
import GESuccess from "./page/GESuccess";
import HSSuccess from "./page/HSSuccess";
import GEFail from "./page/GEFail";
import DoctorScreeningDetails from "./page/DoctorScreeningDetails";

import MarketPlaceDetail from "./page/MarketPlaceDetail";
import { fullBrowserVersion, isSafari } from "react-device-detect";
import compareVersions from "compare-versions";
import BookHSWithClinic from "./page/BookHSWithClinic";
import HealthScreen from "./page/HealthScreen";
import CorTelProvider from "./page/CorTelProvider";
import ViewPDF from "./page/ViewPDF";
import CTEA from "./page/CTEA";
import CPQuestions from "./page/CPQuestions";
import PayNow from "./page/PayNow";
import OneDoc from "./page/1doc";
import DeclarationQSDone from "./page/DeclarationQSDone";

import AuthGrant from "./page/AuthGrant";
import GranteeRedirectDriver from "./page/GranteeRedirectDriver";

import MyReport from "./page/myReport";
import HealthScreenList from "./page/HealthScreenList";

import ScanUpload from "./page/ScanUpload";

const { isSupported } = require("twilio-video");

let routes = [];
// if (isSupported) {
if (
  isSupported &&
  !(isSafari && compareVersions(fullBrowserVersion, "12.1") === -1)
) {
  routes = [
    {
      path: "/",
      exact: true,
      component: PageNotFound,
    },
    {
      path: "/tel/:id",
      component: Home,
    },
    {
      path: "/hangup",
      component: LeaveRoom,
    },
    {
      path: "*",
      component: PageNotFound,
    },
  ];
} else {
  routes = [
    {
      path: "/",
      exact: true,
      component: PageNotFound,
    },
    {
      path: "*",
      component: PlatformCheck,
    },
  ];
}

let commonArr = [
  {
    path: "/scanUpload/:id?/:expired?/",
    component: ScanUpload,
  },
  {
    path: "/addAddress/:id",
    component: AddAddress,
  },
  {
    path: "/pay/:id",
    component: Payment,
  },
  {
    path: "/paynow",
    component: PayNow,
  },
  {
    path: "/1doc",
    component: OneDoc,
  },
  {
    path: "/bill/:id",
    component: Bill,
  },
  {
    path: "/coupon",
    component: Coupon,
  },
  {
    path: "/couponDetail",
    component: CouponDetail,
  },
  // {
  //     path: '/signUp',
  //     component: Register
  // },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/signUpMyinfo",
    component: SignUpMyinfo,
  },
  {
    path: "/signUpCompleteInfo",
    component: SignUpCompleteInfo,
  },
  {
    path: "/signUpProfile",
    component: SignUpProfile,
  },
  {
    path: "/missProfileInfo",
    component: MissProfileInfo,
  },
  {
    path: "/newVerifyOTP",
    component: VerifyOTPV2,
  },
  // {
  //   path: "/loginByMobile",
  //   component: LoginByMobile,
  // },
  // {
  //   path: "/loginByEmail",
  //   component: LoginByEmail,
  // },
  // {
  //     path: '/myinfo/:token?',
  //     component: CompleteProfile
  // },
  // {
  //   path: "/myinfo",
  //   component: Registry,
  // },
  {
    path: "/improve/:token?",
    component: PerfectInfo,
  },
  {
    path: "/drugAllergy",
    component: DrugAllergy,
  },
  {
    path: "/verifyOTP",
    component: VerifyOTP,
  },
  {
    path: "/submitResult",
    component: SubmitProfileResult,
  },
  {
    path: "/heightPage/:id/",
    component: ProfileResultHeight,
  },
  {
    path: "/referralCode/",
    component: ReferralCode,
  },
  {
    path: "/payBack",
    component: PaymentStatus,
  },
  {
    path: "/success",
    component: Success,
  },
  {
    path: "/fail",
    component: Fail,
  },
  {
    path: "/doctorList",
    component: DoctorList,
  },
  {
    path: "/doctorList/myDoctor/:token?",
    component: DoctorList,
  },
  {
    path: "/doctorList/findDoctor/:token?",
    component: DoctorList,
  },
  {
    path: "/doctorList/activeSG/:token?",
    component: DoctorList,
  },
  {
    path: "/doctorList/monitoringProgram/:token?/:pgName?/",
    component: DoctorList,
  },
  //whatapp 进入传三个参数
  {
    path: "/doctorDetails/:id/:uid?/:token?/",
    component: DoctorDetails,
  },
  {
    path: "/doctorScreeningDetails/:id", //id: package_id
    component: DoctorScreeningDetails,
  },
  {
    path: "/bookHSWithClinic/:id", // id: package_id
    component: BookHSWithClinic,
  },
  //安卓 and iOS home page bannner入口
  {
    path: "/doctorDetailsAI/:id/:token?/:hs?",
    component: DoctorDetails,
  },
  {
    path: "/telemedicine/:token?",
    component: WebHome,
  },
  {
    path: "/telemedicine/consultAnyGP/:token?",
    component: WebHome,
  },
  {
    path: "/googleMap",
    component: GoogleMap,
  },
  {
    path: "/Swiper",
    component: SwiperCom,
  },
  {
    path: "/flyPage/:id?",
    component: FlyPage,
  },
  {
    path: "/clinicdetails/:mpid/:tabno?",
    component: ShortLinkForDoctorDetails,
  },
  {
    path: "/hs/:name/:id",
    component: HealthScreen,
  },
  // {
  //   path: "/joinProgram/:id/:code?/:token?/",
  //   component: JoinPrograms,
  // },
  {
    path: "/joinProgramSuccess/",
    component: RequestRust,
  },
  {
    path: "/programsPayment/:id/:module_name?/",
    component: ProgramsPayment,
  },
  {
    path: "/paymentSuccess/",
    component: PaymentResultSuccess,
  },
  {
    path: "/paymentFail/",
    component: PaymentFail,
  },
  {
    path: "/programsSuccess/",
    component: ProgramsSuccess,
  },
  {
    path: "/checkOutPage/:pro_id",
    component: CheckOutPage,
  },
  {
    path: "/paymentCoupon",
    component: PaymentCoupon,
  },
  {
    path: "/paymentCouponDetail",
    component: PaymentCouponDetail,
  },
  {
    path: "/payCallback",
    component: ProgramsPaymentStatus,
  },
  {
    path: "/acceptOrDeclien/",
    component: AcceptOrDeclien,
  },
  {
    path: "/openNestedLink/:module_name/:token",
    component: OpenNestedLink,
  },
  {
    path: "/ge/:token?",
    component: geDrLink,
  },
  {
    path: "/asg/:token?",
    component: asgDrLink,
  },
  {
    path: "/gdl/:token?",
    component: getDrList,
  },
  {
    path: "/geCheckOutPage/:package_id/:is_share_personal_data/:is_contacted_by_ge",
    component: GECheckOutPage,
  },
  {
    path: "/marketPlaceDetail/:id",
    component: MarketPlaceDetail,
  },
  {
    path: "/gESuccess/",
    component: GESuccess,
  },
  {
    path: "/HSSuccess/",
    component: HSSuccess,
  },
  {
    path: "/gEFail/",
    component: GEFail,
  },
  // {
  //   path: "/viewPDF/",
  //   component: ViewPDF,
  // },
  {
    path: "/ctpList/:teleconsult_event_id/:token?/",
    component: CorTelProvider,
  },
  {
    path: "/ct/:name/:id",
    component: CTEA,
  },
  {
    path: "/CPQuestions/:type/:id/:token?",
    component: CPQuestions,
  },
  {
    path: "/declarationQSDone",
    component: DeclarationQSDone,
  },
  {
    path: "/granteeRedirectDriver",
    component: GranteeRedirectDriver,
  },
  {
    path: "/authGrant/",
    component: AuthGrant,
  },
  {
    path: "/invalidomain",
    component: Invalidomain,
  },
  {
    path: "/myReport/:token?",
    component: MyReport,
  },
  {
    path: "/healthScreenList/:token?",
    component: HealthScreenList,
  },
];

commonArr.concat(routes);
// console.log(commonArr.concat(routes))
export default commonArr.concat(routes);
