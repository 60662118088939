import React, { Component } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { WingBlank, Flex, WhiteSpace,Toast} from 'antd-mobile';
import { ROUTES } from '../commons/Constants';
import AuthGuard from '../services/AuthGuard';
import { addKeyValueForDorpList, isPostalCode } from '../commons/Helpers';
import GoogleMapReact from 'google-map-react';


const AnyReactComponent = ({ text }) => <div>{text}</div>;
class GoogleMap extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         billID: props.match.params.id,
    //         loading: false,
    //     }
    // }
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        googleAPIKey : "AIzaSyDS6vg491KesawwT2dTidswhURN62-1AR8",
        zoom: 11
    };

    state = {
        open: true,
    }

    componentDidMount() {


    }
    loadingToast() {
        Toast.loading('Loading...', 30, () => {
            console.log('Load complete !!!');
        });
    }




    render() {

        return (
            <div className="Doctor-list">
                <div style={{ height: '100vh', width: '100%' }}>
                    {/* <GoogleMapReact
                        bootstrapURLKeys={{ key: this.props.googleAPIKey }}
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                    >
                        <AnyReactComponent
                            lat={59.955413}
                            lng={30.337844}
                            text="My Marker"
                        />
                    </GoogleMapReact> */}
                </div>

            </div>

        );
    }
}


export default GoogleMap;