import React, { Component } from 'react';
import AuthGuard from '../services/AuthGuard';
import { ReactGAEvent } from '../commons/ReactGAEvent';
import URL from "url";

class OpenNestedLink extends Component {
    constructor(props) {
        super(props);
        this.state = { 
        }
    }

    componentWillMount() {
        
    }

    async componentDidMount() {
        let { token,module_name } = this.props.match.params;
        
        if (token) await AuthGuard.getNewToken(token)
        if(module_name){
            if(module_name === 'programs'){
                this.checkToken('monitoring');
            }
        }
    }

    checkToken(filterKey) {
        console.log(filterKey)
        ReactGAEvent("Go TO DoctorList", "onclick " + filterKey ? filterKey : 'Find a Doctor' + " Btn");
        this.props.history.push({ pathname: "/doctorList", state: { filterKey } })
    }

    render() {
        return(<></>);
    };
}
export default OpenNestedLink;