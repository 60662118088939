import React, { Component } from 'react';
import { Flex, WhiteSpace, WingBlank,InputItem,NavBar ,Icon, Button,Modal} from 'antd-mobile';
import {RespondCode } from '../commons/Constants'
import AuthGuard from '../services/AuthGuard';
import LoginModel from '../component/LoginModel';
import { setCache, removeCache} from '../commons/Helpers';

class JoinPrograms extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            programs_id: '',
            name: '',
            user_profile_id: '',
            loadding: false,
            loadding_request: false,
            programs_code: '',
            error:'',
        }
    }

    goBack = ()=>{
        this.props.history.push('/doctorList/');
    }

    componentDidMount() {
        let {id ,code, token} = this.props.match.params;
        this.setState({
            programs_id: id,
            programs_code: code,
        })
        if(id && token){
            if (token){
                AuthGuard.exchangeToken({ token: token },(isOk,res) => {
                    if(isOk){
                        AuthGuard.getProgramsInfo({ program_id: id },(isok,res2) => {
                            if(isok){
                                this.setState({
                                    name: res2.name,
                                })
                            }
                        });
                    }else{
                        let _url = '/joinProgram/' + id;
                        if (!AuthGuard.checkLogin()) {
                            this.loginModel.alertLogin({
                                url: _url,
                                alert_title: 'Please Login in.',
                            })
                            return false
                        }
                    }
                });
            }
            
        }else{
            if(id){
                let _url = '/joinProgram/' + id;
                if (!AuthGuard.checkLogin()) {
                    this.loginModel.alertLogin({
                        url: _url,
                        alert_title: 'Please Login in.',
                    })
                    return false
                }
                this.setState({
                    programs_id: id,
                })
                AuthGuard.getProgramsInfo({ program_id: id },(isok,res3) => {
                    if(isok){
                        this.setState({
                            name: res3.name,
                        })
                    }
                });
            }else{
                console.log('无效id')
            }
        }
    }

    onRef = (ref) => {
        this.loginModel = ref
      }

    join = ()=>{
        let _url = '/joinProgram/' + this.state.programs_id
        if (!AuthGuard.checkLogin()) {
            this.loginModel.alertLogin({
                url: _url,
                alert_title: 'Please Login in.',
            })
            return false
        }else{
            this.setState({
                loadding: true,
            })
            let _programs_code = this.state.programs_code;
            if(_programs_code === '' || _programs_code === undefined){
                this.setState({
                    error: 'Please fill out required field.',
                })
                this.setState({
                    loadding: false,
                })
            }else{
                AuthGuard.joinPrograms({
                    program_code: _programs_code,
                }, (isOk,state_code,res) => {
                    if(isOk){
                        //等于0
                        if(state_code === RespondCode.joinProgramsEqualSuccess){
                            setCache('pro_info', {program_img: res.program_image.url.o,program_name:res.name,coach_name:res.coach_name});
                            this.props.history.push("/programsSuccess");
                        }
                        //大于0
                        if(state_code === RespondCode.joinProgramsMorethanSuccess){
                            this.props.history.push("/checkOutPage/" + this.state.programs_code);
                        }
                    }else{
                        // Modal.alert('Notice', 'Join programs fail.', [
                        //     { text: 'OK', onPress: () => console.log('ok') },
                        // ]);
                    }
                    this.setState({
                        loadding: false,
                    })
                })
            }
        }
    }

    request_code = ()=>{
        let _url = '/joinProgram/' + this.state.programs_id
        if (!AuthGuard.checkLogin()) {
            this.loginModel.alertLogin({
                url: _url,
                alert_title: 'Please Login in.',
            })
            return false
        }else{
            this.setState({
                loadding_request: true,
            })
            AuthGuard.requestCode({
                programId: this.state.programs_id,
            }, (isOk, res) => {
                if(isOk){
                    this.props.history.push({pathname:"/joinProgramSuccess",data:{programs_id:this.state.programs_id}});
                }else{
                    this.setState({
                        loadding_request: false,
                    })
                }
            })
        }
    }

    render() {
        return (
            <div className="referral-code-title">
                <LoginModel
                onRef={this.onRef}
                history={this.props.history}
                />
                <NavBar
                    mode="light"
                    icon={<Icon type="left" color="#EB5DA6" size="lg"/>}
                    onLeftClick={this.goBack}
                ></NavBar>
                <div style={{marginLeft:'1%'}}>
                    <WingBlank>
                        <div className="page-title" >{this.state.name}</div>
                    </WingBlank>
                    <WhiteSpace />
                    <div className="CompleteProfile-Page">
                        <WingBlank>
                            <Flex direction="column" align="start">
                                <Flex.Item>
                                    <div className="lable-title requiredFields">Enter Code to Join Program</div>
                                </Flex.Item>
                                <Flex.Item>
                                    <InputItem
                                        clear
                                        name='programs_code'
                                        disabled={this.state.loadding}
                                        placeholder="e.g. hellodrlee"
                                        error={this.state.error}
                                        value={this.state.programs_code}
                                        onChange={v => this.setState({ programs_code: v })}
                                    ></InputItem>
                                    <span className="errorMsg" style={{fontSize:'14px'}}>{(this.state.error) ? this.state.error : null} </span>
                                </Flex.Item>
                            </Flex>
                        </WingBlank>
                    </div>
                    <WhiteSpace />
                    <WhiteSpace />
                    <WhiteSpace />
                    
                    <WingBlank size="lg">
                        <Button
                            className="fill-pink-btn"
                            activeClassName="fill-pink-active-btn"
                            loading={this.state.loadding}
                            disabled={this.state.loadding}
                            onClick={this.join.bind()}
                        >
                            Join
                        </Button>
                        <WhiteSpace />
                        <Button
                            className="outline-pink-btn"
                            activeClassName="fill-pink-active-btn"
                            loading={this.state.loadding_request}
                            disabled={this.state.loadding_request}
                            onClick={() => this.request_code()}
                        >
                            Request for a code
                        </Button>
                    </WingBlank>
                    <WhiteSpace />
                    <WhiteSpace />
                    <Flex.Item>
                        <div className="lable-title join-text">Request for a code to find out more information about program details. The clinic will be in touch shortly.</div>
                    </Flex.Item>
                </div>
            </div>
        );
    };
}
export default JoinPrograms;