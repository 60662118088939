import React, { Component } from 'react';
import { Flex, WhiteSpace, NavBar, WingBlank, Toast, Button, Icon, InputItem, Picker} from 'antd-mobile';
import enUs from 'antd-mobile/lib/date-picker/locale/en_US';
import Flag from '../images/icons/iconCreditcard.svg'
import AuthGuard from '../services/AuthGuard';
import { setCache, addKeyValueForDorpList, isMobileNo, removeCache } from '../commons/Helpers';
import CustomChildren from '../commons/CustomChildren';
import { DIALING_CODE } from '../commons/Constants';

class LoginByMobile extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            labelDisplay: 'none',
            nextBtnDisplay: 'none',
            errorTxtDisplay: 'none',
            errorMsg:'Your phone is not registered yet, please login with email',
            dialingCode: DIALING_CODE,
            mobileNo: '',
            dialingCodeList:[],
            countryListArr:[]
        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = '#FFF';
        this.getCountryList();
    }

    inputOnFocus = (e) => {

        this.setState({
            labelDisplay: 'block'
        })
    }
    inputOnBlur = (value) => {

        if (value.trim().length > 0) {
            this.setState({
                nextBtnDisplay: 'block',
                labelDisplay: 'block',
            })
        } else {
            this.setState({
                labelDisplay: 'none',
                nextBtnDisplay: 'none'
            })
        }
    }

    onChange = (value) => {
        this.setState({
            mobileNo: value
        })
    }

    loadingToast() {
        Toast.loading('Loading...', 30, () => {
            console.log('Load complete !!!');
        })
    }

    getCountryList = () =>{
        this.loadingToast();
        AuthGuard.getCountryList({
            limit: 300,
            page: 1,
        }
        ,(isOk, res) =>{
            if(isOk){
                this.setState({
                    dialingCodeList: addKeyValueForDorpList(res, 'dialing_code'),
                    countryListArr:res
                })
            }else{
                
            }
            Toast.hide();
        });
    }

    sendOpt = () => {
        let res = this.state.countryListArr.find((item, index) => {
            return item.dialing_code === this.state.dialingCode;
        })
        let resM = res.mobile_no_format ? res.mobile_no_format : '';
    
        if (isMobileNo(this.state.mobileNo, this.state.dialingCode, resM)){
            this.loadingToast();
            AuthGuard.loginSendOtp({
                otpType: 'SMS',
                username: this.state.mobileNo,
                dialingCode: this.state.dialingCode
            }
                , (isOk, res) => {
                    if (isOk) {
                        let cacheObj = {
                            fromPage: '/loginByMobile',
                            type: 'mobile',
                            dialingCode: this.state.dialingCode,
                            username: this.state.mobileNo,
                        }
                        removeCache('verifyOptFor')
                        setCache('verifyOptFor', cacheObj);
                        this.props.history.push('/VerifyOTP/')
                    } else {
                        //6615 Your phone is not registered yet, please login with email
                        this.setState({
                            errorTxtDisplay: "block",
                            errorMsg: res.message
                        })
                    }
                    Toast.hide();
                });    
        }else{
            this.setState({
                errorTxtDisplay: "block",
                errorMsg: 'Invalid Mobile No Format!'
            })
        }
    }

    goback = () => {
        this.props.history.push('/login')
    }

    render() {
        
        return (
            <div className="Register-Page h5-Page login-Page">
                <NavBar
                    mode="light"
                    icon={<Icon type="left" color="#EB5DA6" size="lg"/>}
                    onLeftClick={this.goback}
                ></NavBar>
                <WingBlank>
                    <WhiteSpace size="lg" />
                    <Flex direction="column" justify="start" align="start">
                        <Flex.Item>
                            <div className="page-sub-title">Login with Mobile</div>
                        </Flex.Item>
                    </Flex>
                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />
                    <Flex justify="end" align="end" className="loginWithMobile">
                        <Picker
                            data={this.state.dialingCodeList}
                            locale={enUs}
                            extra="Dialing Code"
                            cols={1}
                            value={[this.state.dialingCode]}
                            onChange={v => {
                                console.log(v);
                                this.setState({
                                    dialingCode: v[0]
                                })
                            }}
                        >
                            <CustomChildren></CustomChildren>
                        </Picker>
                        <Flex.Item>
                            <span className="flGrey" style={{ display: this.state.labelDisplay }}>Mobile No.</span>
                            <InputItem
                                className="inputField"
                                placeholder='Mobile No.'
                                clear
                                value={this.state.mobileNo}
                                name="mobile"
                                onBlur={this.inputOnBlur}
                                onFocus={this.inputOnFocus}
                                onChange={this.onChange}
                            />
                        </Flex.Item>
                    </Flex>
                    <WhiteSpace size="md" />
                    <Flex.Item className="errorMsg" style={{ display: this.state.errorTxtDisplay }}>
                        <p>{this.state.errorMsg}</p>
                    </Flex.Item>
                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />
                    <Flex.Item>
                        <Button
                            style={{ border: ' 1px solid #EB5DA6', color: '#EB5DA6'}}
                            onClick={this.sendOpt}
                        >
                            Next
                            </Button>
                    </Flex.Item>
                    
                </WingBlank>
            </div>
        );
    }
}

export default LoginByMobile;