import React, { useState, Component } from 'react'
import enUs from 'antd-mobile/lib/date-picker/locale/en_US';
import DatePicker from "../component/DatePicker";
import { LocaleProvider, Flex, WhiteSpace, Picker, WingBlank, InputItem, TextareaItem, Toast, Button, Modal, Checkbox } from 'antd-mobile';
import myInfo from '../images/icons/myinfo.jpeg';
import AuthGuard from '../services/AuthGuard';
import CustomChildren from '../commons/CustomChildren';
import { TCLink, DIALING_CODE } from "../commons/Constants";
import { addKeyValueForDorpList, getCache, setCache, removeCache, isEmail, isInvalid } from '../commons/Helpers';
import moment from 'moment'

const AgreeItem = Checkbox.AgreeItem;
function formatDate(date) {
  /* eslint no-confusing-arrow: 0 */
  const dateString = date.toDateString().split(" ");
  var d = dateString[2];
  var m = dateString[1];
  var y = dateString[3];
  // const dateStr = `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()}`;
  const dateStr = `${d}/${m}/${y}`;
  // const timeStr = `${pad(date.getHours())}:${pad(date.getMinutes())}`;
  return `${dateStr}`;
}
class Registry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nationalityList: [],
      countryList: [],
      dialingCodeList: [],
      countryListArr: [],
      email: "",
      dialingCode: DIALING_CODE,
      mobile: "",
      old_dialingCode: "",
      old_mobile: "",
      fullName: "",
      aliasName: "",
      nric: "",
      birth: new Date(1970, 0, 1),
      agreeBtn: false,
      updateBtn: false,
      TcModal: false,
      isDatePickerOpen: false,
      errorMsg: {},
    };
  }

  componentDidMount() {
    this.getCountryList();
    this.checkUserInfo()
  }

  checkUserInfo(){
    let singPassCode = getCache('state');
    if (!isInvalid(singPassCode) && singPassCode !== null && singPassCode) {
      this.setState({
        singPassCode: singPassCode
      })
      this.getSingPassInfo();
    } else {
      this.setState({
        singPassCode: ''
      })
    }
  }

  getSingPassInfo = () => {
    let singPassCode = getCache('state');
    // this.loadingToast()
    AuthGuard.getSingPassInfo({
      state: singPassCode
    }, (isOk, res) => {
      // let res = {
      //     myInfoId: "c14094a1-7ebb-4e4c-ac35-a2bf14e04c6d",
      //     email: "test@iapps.com",
      //     dialingCode: "65",
      //     mobile: "134123441234",
      //     fullName: "ANDY  LAU",
      //     nric: "S6005048A",
      //     birth: "1988-10-06",
      //     gender: "F",
      //     nationality: "SINGAPORE CITIZEN",
      //     marital_status: "MARRIED",
      //     postal_code: "542319",
      //     country_code: "SG",
      //     country_name: "SINGAPORE",
      //     address: "319 ANCHORVALE DRIVE",
      //     unit_no: "38-10"
      // }
      if (isOk) {
        this.setState({
          email: res.email,
          myInfoId: res.myInfoId,
          fullName: res.fullName,
          dialingCode: res.dialingCode,
          mobile: res.mobile,
          nric: res.nric,
          _nric: res.nric ? true : false,
          birth: new Date(res.birth) || new Date(1970, 0, 1),
          _birth: res.birth ? true : false
        })
      } else {
        this.setState({
          singpassModal: true
        })
        removeCache('state')
      }
      // Toast.hide()
    })
    // Toast.hide()
  }

  loadingToast() {
    Toast.loading("Loading...", 30, () => {
      console.log("Load complete !!!");
    });
  }

  handleSelect = (e) => {
    this.setState({
      birth: e,
      isDatePickerOpen: false,
    });

    document.body.removeEventListener("touchmove", this.stopScroll);
    //添加事件监听时添加了passive参数，在ios9中移除事件监听也需要加此参数
    document.body.removeEventListener("touchmove", this.stopScroll, {
      passive: true,
    });
    document.body.style.overflow = "auto";
  };

  handleCancel = () => {
    this.setState({
      isDatePickerOpen: false,
    });

    document.body.removeEventListener("touchmove", this.stopScroll);
    //添加事件监听时添加了passive参数，在ios9中移除事件监听也需要加此参数
    document.body.removeEventListener("touchmove", this.stopScroll, {
      passive: true,
    });
    document.body.style.overflow = "auto";
  };

  stopScroll(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  getCountryList = () => {
    this.loadingToast();
    AuthGuard.getCountryList(
      {
        limit: 300,
        page: 1,
      },
      (isOk, res) => {
        if (isOk) {
          this.setState({
              dialingCodeList: addKeyValueForDorpList(res, "dialing_code"),
            });
        }
        Toast.hide();
      }
    );
  };

  confirm = async (e) => {
    e.preventDefault();
    let self = this;
    let noError = true;

    let params = {
      myInfoId: this.state.myInfoId,
      email: this.state.email,
      mobile: this.state.mobile,
      dialingCode: this.state.dialingCode,
      fullName: this.state.fullName,
      aliasName: this.state.aliasName,
      nric: this.state.nric,
      birth: moment(this.state.birth).format('YYYY-MM-DD')
    }

    let checkRequest = {
      data: params,
      dataName: {
        email: 'Email',
        mobile: 'Mobile No.',
        dialingCode: 'Dialing Code',
        fullName: 'Full Name',
        nric: 'NRIC',
        birth: 'Birthday'
      }
    }

    if (!self.getFieldError(checkRequest)) {
      noError = false;
    }

    if (this.state.patient_id) {
      params.id = this.state.patient_id
    }

    if (noError) {
      this.setState({
        errorMsg: {}
      })
    }

    let data = null
    data = params

    if (!noError){
      Toast.fail('Complete necessary information !!!', 2);
      return false
    }

    if (!this.state.agreeBtn) {
      this.setState({
        TcModal: true
      })

      return false
    }
    this.setState({
      confirmLoadding: true
    })

    let cacheObj = {
      fromPage: 'Register',
      type: 'SMS',
      dialingCode: this.state.dialingCode,
      username: this.state.mobile,
    }
    removeCache('verifyOptFor');
    removeCache('state')
    setCache('verifyOptFor', cacheObj)
    setCache('patientInfo', data)
    // 注册
    this.loadingToast()
    AuthGuard.sendOtp4Reg({
      dialingCode: this.state.dialingCode,
      mobile: this.state.mobile,
      email: this.state.email,
    }, (isok) => {
      if (isok) {
        this.props.history.push("/verifyOTP");
      } else {
        // Modal.alert('success', res.message, [{ text: 'OK', onPress: () => console.log('ok') }]);
      }
      Toast.hide()
    })

    this.setState({
      confirmLoadding: false
    })
  }


  getFieldError = (datas) => {

    let data = datas.data;
    let requiredArr = [
      "email",
      "mobile",
      "dialingCode",
      "fullName",
      "nric",
      "birth"
    ];
    
    let err = true;
    let msg = {};

    Object.keys(data).forEach((k) => {
      if (requiredArr.includes(k) && err) {
        // console.log(data, name[k])
        if (!data[k]) {
          err = false;
          // msg[k] = name[k] + ' is required!';
          msg[k] = 'Please fill out required field';//name[k] + ' is required!';
        }
        if (err && k === 'email') {
          if (!isEmail(data[k])) {
            err = false;
            msg[k] = 'Invalid Email Format!';
          }
        }
      }
    })
    if (!err) {
      this.setState({
        errorMsg: msg
      });
    }
    return err;

  }

  getSingPassUrl = () => {
    this.loadingToast();
    if (!this.state.singPassCode) {
      AuthGuard.getSingPassUrl({}, (isOk, res) => {
        if (isOk) {
          window.location.href = res.url;
        }
        Toast.hide();
      });
    }
  };

  openDatePicker = (e) => {
    e.stopPropagation();
    if (this.state._birth) return false;
    this.setState({
      isDatePickerOpen: true,
    });

    document.body.addEventListener("touchmove", this.stopScroll, {
      passive: false,
    });
    document.body.style.overflow = "hidden";
  };

  onTCClose() {
    this.setState({
      TcModal: false,
    });
  }

  onSingpassClose() {
    this.setState({
      singpassModal: false,
      singPassCode: "",
    });
  }

  onAgrrTerm = (e) => {
    this.setState({
      agreeBtn: e.target.checked,
    });
  }

  onAgrrKeepUpdates = (e) => {
    this.setState({
      updateBtn: e.target.checked,
    });
  }

  stopScroll(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  renderFormUserInfo = () => {
    return (
      <>
        <WingBlank>
          <Flex>
            <div className="page-title py-1">
              {this.state.patient_id
                ? "Complete"
                : this.state.singPassCode
                ? "Telemed Registration"
                : "Registration"}
            </div>
          </Flex>
          <WhiteSpace />
          <Flex
            className={`page-myinfo completeWithMyinfoBtn ${
              this.state.singPassCode ? "disabled" : ""
            }`}
            onClick={this.getSingPassUrl.bind(this)}
          >
            <Flex className="size-1">
              {this.state.singPassCode ? "Completed" : "Complete"} with{" "}
              <img className="page-myinfo-img" src={myInfo} alt="" />
            </Flex>
          </Flex>
          <WhiteSpace />
          <WhiteSpace />
          <WhiteSpace />
          <form id="complete-profile">
            <Flex direction="column" align="start">
              <Flex.Item>
                <div className="lable-title">
                  {" "}
                  {this.state.email ? "Email *" : ""}{" "}
                </div>
              </Flex.Item>
              <Flex.Item className="input-outline">
                <InputItem
                  clear
                  name="email"
                  disabled={this.state._email}
                  placeholder="Email *"
                  error={this.state.emailError}
                  value={this.state.email}
                  onChange={(v) => {
                    let _email = v.trim();
                    this.setState({ email: _email });
                  }}
                ></InputItem>
                <span className="errorMsg">
                  {this.state.errorMsg.email ? this.state.errorMsg.email : null}{" "}
                </span>
              </Flex.Item>
              <Flex.Item>
                <div className="lable-title">
                  {this.state.mobile ? "Mobile Number *" : ""}
                </div>
              </Flex.Item>
              <Flex.Item className="input-outline">
                <Flex className={`${this.state._dialingCode ? "flGrey" : ""}`}>
                  <Picker
                    data={this.state.dialingCodeList}
                    locale={enUs}
                    extra="Dialing Code"
                    disabled={this.state._dialingCode}
                    cols={1}
                    value={[this.state.dialingCode]}
                    onChange={(v) => {
                      console.log(v);
                      this.setState({
                        dialingCode: v[0],
                      });
                    }}
                  >
                    <CustomChildren
                      sClas={this.state.dialingCode ? "sel" : "noSel"}
                    ></CustomChildren>
                  </Picker>
                  <Flex.Item>
                    <InputItem
                      clear
                      value={this.state.mobile}
                      error={this.state.mobileError}
                      disabled={this.state._mobile}
                      placeholder="Mobile Number *"
                      onChange={(v) => this.setState({ mobile: v })}
                    ></InputItem>
                  </Flex.Item>
                </Flex>
                <span className="errorMsg">
                  {this.state.errorMsg.dialingCode
                    ? this.state.errorMsg.dialingCode
                    : null}
                  {this.state.errorMsg.mobile
                    ? this.state.errorMsg.mobile
                    : null}
                </span>
              </Flex.Item>
              <Flex.Item>
                <div className="lable-title">
                  {this.state.fullName ? "Full Name *" : ""}
                </div>
              </Flex.Item>
              <Flex.Item className="input-outline">
                <InputItem
                  clear
                  placeholder="Full Name *"
                  error={this.state.fullNameError}
                  value={this.state.fullName}
                  disabled={this.state._fullName}
                  onChange={(v) => this.setState({ fullName: v })}
                ></InputItem>
                <span className="errorMsg">
                  {this.state.errorMsg.fullName
                    ? this.state.errorMsg.fullName
                    : null}{" "}
                </span>
              </Flex.Item>
              <Flex.Item>
                <div className="lable-title">
                  {this.state.aliasName ? "Preferred Name" : ""}{" "}
                </div>
              </Flex.Item>

              <Flex.Item className="input-outline">
                <InputItem
                  clear
                  value={this.state.aliasName}
                  error={this.state.aliasNameError}
                  placeholder="Preferred Name"
                  onChange={(v) => this.setState({ aliasName: v })}
                ></InputItem>
                <span className="errorMsg">
                  {this.state.errorMsg.aliasName
                    ? this.state.errorMsg.aliasName
                    : null}{" "}
                </span>
              </Flex.Item>
              <Flex.Item>
                <div className="lable-title">
                  {this.state.nric ? "Identification Number *" : ""}
                </div>
              </Flex.Item>
              <Flex.Item className={`${this.state._nric ? "disabledInput input-outline" : " input-outline"}`}>
                <InputItem
                  clear
                  value={this.state.nric}
                  disabled={this.state._nric}
                  error={this.state.nricError}
                  placeholder="Identification Number *"
                  onChange={(v) => this.setState({ nric: v.toUpperCase() })}
                ></InputItem>
                <span className="errorMsg">
                  {this.state.errorMsg.nric ? this.state.errorMsg.nric : null}{" "}
                </span>
              </Flex.Item>
              <Flex.Item>
                <div className="lable-title">
                  {this.state.birth ? "Date of Birth *" : ""}
                </div>
              </Flex.Item>
              <Flex.Item className={`${this.state._birth ? "disabledInput input-outline" : " input-outline"}`}>
                <div onClick={this.openDatePicker.bind(this)}>
                  <InputItem
                    className={`pickerLabel ${
                      this.state._birth ? "disabledPicker-input" : ""
                    }`}
                    name="birth"
                    placeholder="Picker your DOB"
                    error={this.state.birthError}
                    value={formatDate(this.state.birth)}
                    onFocus={() => {
                      document.activeElement.blur();
                    }}
                  ></InputItem>
                </div>
                <span className="errorMsg">
                  {this.state.errorMsg.birth ? this.state.errorMsg.birth : null}{" "}
                </span>
              </Flex.Item>
            </Flex>
            <WhiteSpace />
            <WhiteSpace />
            <WhiteSpace />
            <Flex className="termCon">
              <AgreeItem
                data-seed="logId"
                onChange={this.onAgrrTerm.bind(this)}
              >
                <i>
                  By continuing you are registering for a HeartVoice account
                </i>
                <a href={TCLink} target="_blank" rel="noopener noreferrer">
                  <i className="fbPink"> Terms & Conditions</i>
                </a>
              </AgreeItem>
            </Flex>
            <Flex className="termCon">
              <AgreeItem
                data-seed="logId"
                onChange={this.onAgrrKeepUpdates.bind(this)}
              >
                <i>Please keep me updated on news, events, offers</i>
              </AgreeItem>
            </Flex>

            <WhiteSpace />
            <WhiteSpace />
            <WingBlank size="lg">
              <Button
                className="fill-pink-btn pb-5"
                activeClassName="fill-pink-active-btn"
                loading={this.state.confirmLoadding}
                onClick={this.confirm}
                // disabled={this.state.agreeBtn}
              >
                Register
              </Button>
            </WingBlank>
            <WhiteSpace />
            <WhiteSpace />
          </form>
        </WingBlank>
      </>
    );
  };

  render() {
    return (
      <LocaleProvider
        locale={{
          value: "English",
          label: "English",
          language: enUs,
        }}
      >
        <div className="CompleteProfile-Page">
          {this.renderFormUserInfo()}
          <DatePicker
            mode="date"
            showHeader={false}
            value={this.state.birth}
            isOpen={this.state.isDatePickerOpen}
            onSelect={this.handleSelect}
            onCancel={this.handleCancel}
            min={new Date(1900, 1, 1)}
            max={new Date()}
          ></DatePicker>
          <br />
          <br />
          <br />
          <br />
          <Modal
            visible={this.state.TcModal}
            transparent
            maskClosable={false}
            onClose={() => this.onTCClose()}
            title="Error"
            footer={[
              {
                text: "Okay",
                onPress: () => {
                  this.onTCClose();
                },
              },
            ]}
          >
            <div>
              Please read and agree to the <br /> Terms & Conditions before{" "}
              <br /> proceeding
            </div>
          </Modal>
          <Modal
            visible={this.state.singpassModal}
            transparent
            maskClosable={false}
            onClose={() => this.onSingpassClose()}
            title="Error"
            footer={[
              {
                text: "Okay",
                onPress: () => {
                  this.onSingpassClose();
                },
              },
            ]}
          >
            <div>
              We counld not retrieve your info form MyInfo. Pelease try again or fill in the form manually.
            </div>
          </Modal>
        </div>
      </LocaleProvider>
    );
  }
}
 
export default Registry
