import React, { createContext, useContext, useReducer, useState } from 'react';
import { RoomType } from '../types';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import { default as zip } from 'jszip';
import { default as axios } from 'axios';
import { stringify } from 'flatted';
import AuthGuard from "../../../services/AuthGuard";

interface User {
  displayName: string,
}

export interface StateContextType {
  error: TwilioError | null;
  setError(error: TwilioError | null): void;
  uploadLog(): void;
  getToken(name: string, room: string, passcode?: string): Promise<string>;
  user?: User | null | { displayName: undefined; photoURL: undefined; passcode?: string };
  userName: string,
  setUserName(name: string): void,
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  token: string;
  setToken(token: string): void;
  endCall: any;
  setEndCall(endCall: any): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
  isShowMenu?: boolean;
  setIsShowMenu(isShowMenu: boolean): void;
  isRemoteMuteVideo: boolean,
  setIsRemoteMuteVideo(isRemoteMuteVideo: boolean): void,
  isRemoteMuteAudio: boolean,
  setIsRemoteMuteAudio(isRemoteMuteAudio: boolean): void,
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks fron being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/

let twilloLogs: Array<string> = [];
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [userName, setUserName] = useState('');
  const [token, setToken] = useState('');
  const [endCall, setEndCall] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isShowMenu, setIsShowMenu] = useState(true);
  const [activeSinkId, setActiveSinkId] = useState('default');
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
  const [isRemoteMuteVideo, setIsRemoteMuteVideo] = useState(false);
  const [isRemoteMuteAudio, setIsRemoteMuteAudio] = useState(false);

  (window as any).TwilioLogHandler = (level: string, msg: Array<string>) => {
    if (typeof msg[6] === 'string' && (
      msg[6] === 'Incoming: {"type":"heartbeat"}' ||
      msg[6] === 'Outgoing: {"type":"heartbeat"}'
    )) {
      return;
    }
    let log = msg.join(' ')
    if (msg.slice(7).length) {
      log += stringify(msg.slice(7))
    }
    twilloLogs.push(log)
    console.log(log, twilloLogs.length)
  }

  const uploadLog: StateContextType['uploadLog'] = () => {
    if (!twilloLogs.length) {
      return
    }
    var blob = new Blob([twilloLogs.join('\n')], { type: 'plain/text' });
    (new zip()).file("log.txt", blob).generateAsync({ type: "blob" }).then(zipped => {
      const formData = new FormData()
      // @ts-ignore
      let v:any = AuthGuard.videoCallInfo.originUser
      formData.append('files', zipped, 'log.zip');
      formData.append('room_name', v.roomName);
      formData.append('room_id', v.roomId);
      formData.append('ref_type', 'USER_QUEUE');
      formData.append('ref_id', v.id);
      formData.append('coach_id', v.coachId);
      formData.append('user_profile_id', v.userProfileId);
      formData.append('channel', 'DR_DASHBOARD');

      AuthGuard.uploadTwilioLog(formData, (ok, res) => {
        if (ok) {
          twilloLogs = []
          console.info('twilio log uploaded')
        }
      })
    })
  }

  let contextValue = {
    error,
    uploadLog,
    setError: (error: TwilioError | null) => {
      if (error && error.code === 53118) {
        return;
      }
      setError(error)
      if (error !== null) {
        uploadLog()
      }
    },
    userName,
    setUserName,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    isShowMenu,
    setIsShowMenu,
    token,
    setToken,
    endCall,
    setEndCall,
    settings,
    dispatchSetting,
    isRemoteMuteVideo,
    setIsRemoteMuteVideo,
    isRemoteMuteAudio,
    setIsRemoteMuteAudio,
  } as StateContextType;

  contextValue = {
    ...contextValue,
    // todo
    // getToken: async (identity, roomName) => {
    //   const headers = new window.Headers();
    //   const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/token';
    //   const params = new window.URLSearchParams({ identity, roomName });

    //   return fetch(`${endpoint}?${params}`, { headers }).then(res => res.text());
    // },
    getToken: async (identity, roomName) => {
      const endpoint = 'https://dev.api.ourheartvoice.com/health_service/twilio/generatingAccessTokens';

      const formData = new FormData()
      formData.append('roomName', roomName);
      formData.append('userId', identity);

      return axios({
        method: 'post',
        url: endpoint,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(function (response: any) {
        console.log(response);
        return response.data.result
      });
    },
  }

  const getToken: StateContextType['getToken'] = (name, room) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, room)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return <StateContext.Provider value={{ ...contextValue, getToken }}>{props.children}</StateContext.Provider>;
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
