import React, { Component } from 'react'
import NotFound from '../component/404'
class PageNotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {  

        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = '#E9F5F6';    
    }
    
    render() { 
        return (  
            <div className="page-not-found">
                <NotFound  {...this.props}/>
            </div>
        );
    }
}
 
export default PageNotFound;