import React, { useState, useEffect, Fragment } from "react";
import {
  Flex,
  WhiteSpace,
  WingBlank,
  Toast,
  Button,
  Modal,
  NavBar,
  Icon,
} from "antd-mobile";
import { isBrowser, isIOS, isAndroid } from "react-device-detect";
import IconDown from "../images/icons/downLoad.svg";
import JsFileDownloader from "js-file-downloader";

import AuthGuard from "../services/AuthGuard";
import { viewFile } from "../commons/Helpers";
let timer = null;
const MyReport = (props) => {
  const [reportList, setReportList] = useState([]);
  const [viewLink, setViewLink] = useState(null);
  const [viewData, setViewData] = useState({});
  const [pwd, setPwd] = useState(null);
  const goBack = () => {
    document.querySelector("#icontent").innerHTML = "";
    setViewLink(null);
    timer = null;
  };
  useEffect(() => {
    init();
    return () => {
      timer = null;
    };
  }, []);
  async function init(params) {
    let { token } = props.match.params;
    if (token) await AuthGuard.getNewToken(token);
    await getMyReport();
    AuthGuard.getProfileInfo({}, (isOk, res) => {
      if (isOk && res.nric) {
        setPwd(res.nric.slice(res.nric.length - 4));
      }
    });
  }
  function getMyReport() {
    AuthGuard.getMyReport(
      {
        page: 1,
        limit: 999,
      },
      (isOk, res) => {
        if (isOk) {
          setReportList(res);
        }
      }
    );
  }
  function getReportName(val) {
    if (val?.type == "CONSULTATION_RECORDS_MC" || val?.type == "TELEMED_MC") {
      return "MC.PDF";
    } else {
      return val?.report_name;
    }
  }
  function handleViewReport(ite) {
    if (
      navigator.userAgent.indexOf("1docAndroid") !== -1 ||
      navigator.userAgent.indexOf("1docIos") !== -1
    ) {
      let data = {
        url: ite.file_url,
        title: getReportName(ite),
      };
      viewFile(data,'native_open_web');
    } else{
      let isPDF = ite.file_url.indexOf(".pdf") !== -1;
      if (!isPDF) {
        window.open(ite.file_url, "_blank").focus();
        return;
      }

      var iframe = document.createElement("iframe");
      iframe.setAttribute("width", "100%");
      iframe.setAttribute("height", "100%");
      iframe.setAttribute("allowfullscreen", true);
      iframe.src = "./pdf/web/viewer.html?file=" + ite.file_url + "#toolbar=0";
      document.querySelector("#icontent").appendChild(iframe);
      iframe.onload = function () {
        setTimeout(() => {
          decryptionPdf(iframe);
        }, 100);
      };
      setViewLink(ite.file_url);
      setViewData(ite);
    }
  }
  function decryptionPdf(iframe) {
    if (!document.querySelector("#icontent").innerHTML) {
      timer = null;
      return;
    }
    if (
      iframe.contentWindow.document
        .querySelector("#viewer")
        .querySelector(".page")
    ) {
      timer = null;
      return;
    }
    if (
      !iframe.contentWindow.document.querySelector("#overlayContainer")
        .className
    ) {
      iframe.contentWindow.document.querySelector("#password").value = pwd;
      iframe.contentWindow.document.querySelector("#passwordSubmit").click();
      timer = null;
    } else {
      if (!timer) {
        timer = setInterval(() => {
          if (!iframe.contentWindow?.document) {
            timer = null;
            return;
          }
          if (
            !iframe.contentWindow.document.querySelector("#overlayContainer")
              .className
          ) {
            iframe.contentWindow.document.querySelector("#password").value =
              pwd;
            iframe.contentWindow.document
              .querySelector("#passwordSubmit")
              .click();
            timer = null;
          }
        }, 1000);
      }
    }
  }
  function handleDownReport(ite) {
    let data = {
      file_url: ite.file_url,
      file_name: getReportName(ite),
    };
    if (isIOS) {
      window.webkit.messageHandlers.native_download_pdf.postMessage(
        JSON.stringify(data)
      );
    } else if (isAndroid) {
      window.JsToAndroid.native_download_pdf(JSON.stringify(data));
    } else {
      new JsFileDownloader({
        url: ite.file_url,
        filename: getReportName(ite),
      })
        .then((res) => {})
        .catch((error) => {
          console.log(error);
        });
    }
  }
  return (
    <div
      className="Paymet-Page pay-page"
      style={{ height: "100%", fontSize: 20 }}
    >
      {viewLink ? (
        <NavBar
          mode="light"
          icon={<Icon type="left" color="#EB5DA6" size="lg" />}
          onLeftClick={goBack}
        >
          {getReportName(viewData)}
        </NavBar>
      ) : (
        <div style={{ height: 20 }}></div>
      )}
      <div
        style={{
          width: viewLink ? "100%" : 0,
          height: viewLink ? "calc(100% - 45px)" : 0,
          border: 0,
        }}
        id="icontent"
      ></div>
      {/* <iframe id='ifaaa' style={{ width: viewLink ? '100%' : 0, height: viewLink ? '100%' : 0, border: 0 }} ></iframe> */}
      {!viewLink && (
        <div style={{ fontSize: 16 }}>
          <WingBlank
            size="lg"
            style={{ minHeight: "calc(100% - 45px)", backgroundColor: "#fff" }}
          >
            <div style={{ padding: "0 40px 10px" }}>
              <div style={{ fontSize: 18, marginBottom: 10 }}>My Reports</div>
              <div style={{ color: "gray" }}>
                Documents are locked with the last 4 characters of your
                identification number(e.g. 123A)
              </div>
            </div>
            {reportList.map((ite, idx) => (
              <Fragment key={idx}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px 40px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      color: "gray",
                    }}
                    onClick={() => handleViewReport(ite)}
                  >
                    <div>{ite?.archive_date}</div>
                    <div style={{ color: "#000" }}>{getReportName(ite)}</div>
                    <div>{ite?.clinic_name}</div>
                  </div>
                  <div onClick={() => handleDownReport(ite)}>
                    <img src={IconDown} alt="" />
                  </div>
                </div>
                <div
                  style={{ borderBottom: "1px solid gray", padding: "0 20px" }}
                ></div>
              </Fragment>
            ))}
          </WingBlank>
        </div>
      )}
    </div>
  );
};

export default MyReport;
