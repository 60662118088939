import React, { Component } from 'react';
import { Flex, WhiteSpace, WingBlank,InputItem,NavBar ,Icon, Button,} from 'antd-mobile';
import ScheduleCalendar from '../component/ScheduleCalendar';
import * as isWebview from 'js-is-webview';
const is_webview = new isWebview();

class RequestRust extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            programs_id: '',
            loadding: false,
        }
    }

    goBack = ()=>{
        this.props.history.push('/joinProgram/' + this.state.programs_id)
    }

    componentDidMount() {
        let _programs_id = this.props.location.data !== undefined ? this.props.location.data.programs_id : '';
        if(_programs_id){
            this.setState({
                programs_id: _programs_id,
            })
        }else{
            console.log('无效programs_id')
        }
    }

    okay = () =>{
        let userAgent = {
            userAgent:navigator.userAgent
        }
        let userAgentValue = userAgent.userAgent;
        if('heartvoice' && userAgentValue.indexOf('heartvoice') !== -1){
            console.log('webview')
            this.scModel.openRequestSuccess();
        }else{
            this.props.history.push('/telemedicine/');
            console.log('not webview')
        }
    }

    onSCRef = (ref) => {
        this.scModel = ref
    }

    render() {
        return (
            <div className="referral-code-title">
                 <ScheduleCalendar
                onRef={this.onSCRef}
                />
                <NavBar
                    mode="light"
                    icon={<Icon type="left" color="#EB5DA6" size="lg"/>}
                    onLeftClick={this.goBack}
                ></NavBar>
                <div style={{marginLeft:'1%'}}>
                    <WingBlank>
                        <div className="page-title" >Success!</div>
                    </WingBlank>
                    <WhiteSpace />
                    <div className="CompleteProfile-Page">
                        <WingBlank>
                            <Flex direction="column" align="start">
                                <Flex.Item>
                                    <div className="lable-title"><b>You have successfully requested for monitoring program.</b></div>
                                    <div className="lable-title"><b>The clinic will be in touch shortly. You will receive a confirmation email when you can start the program.</b></div>
                                </Flex.Item>
                                
                            </Flex>
                        </WingBlank>
                    </div>
                    <WhiteSpace />
                    <WhiteSpace />
                    <WhiteSpace />
                    
                    <WingBlank size="lg">
                        <Button
                            className="fill-pink-btn pb-5"
                            activeClassName="fill-pink-active-btn"
                            loading={this.state.loadding}
                            disabled={this.state.loadding}
                            onClick={this.okay}
                        >
                            Okay
                        </Button>
                       
                    </WingBlank>
                </div>
            </div>
        );
    };
}
export default RequestRust;