import React, { Component } from 'react';
import { NavBar, Flex, WhiteSpace, Picker, List, WingBlank, Checkbox, Toast, Button, Modal, InputItem, Icon } from 'antd-mobile';
import { TCLink, DIALING_CODE } from "../commons/Constants";
import enUs from 'antd-mobile/lib/date-picker/locale/en_US';
import CustomChildren from '../commons/CustomChildren';
import { addKeyValueForDorpList, getCache, setCache, removeCache, isEmail, isInvalid, isMobileNo, toHump } from '../commons/Helpers';
import AuthGuard from '../services/AuthGuard';
import moment from 'moment'
import GoogleSearchInput from "../component/GoogleSearchInput";
import DatePicker from '../component/DatePicker'
import calendar from '../images/icons/ic_status_calendar_pink.png'
import { isBrowser } from "react-device-detect";
import Select, { components } from 'react-select';
import { DropdownIndicator, selectStylesForDownList } from "./SignUpCompleteInfo";
import { SingleValueForDialingCode, selectStylesForDialingCodeV2 } from "./LoginV2";
import DatePickerBor from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const AgreeItem = Checkbox.AgreeItem;
function formatDate(date) {
    /* eslint no-confusing-arrow: 0 */
    const dateString = date.toDateString().split(" ")
    var d = dateString[2]
    var m = dateString[1]
    var y = dateString[3]
    // const dateStr = `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()}`;
    const dateStr = `${d}/${m}/${y}`;
    // const timeStr = `${pad(date.getHours())}:${pad(date.getMinutes())}`;
    return `${dateStr}`;
}
class SignUpMyinfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dialingCode: DIALING_CODE,
            mobile: '',
            dialingCodeList: [],
            countryListArr: [],
            countryList: [],
            provinceList: [],
            gmapsLoaded: false,
            tc: 'N',
            other: 'N',
            errorMsg: {},
            address: '',
            birth: new Date(1980, 0, 1),
            country_code: 'SG',
            country_name: '',
            fullName: '',
            gender: '',
            myInfoId: '',
            nric: '',
            postal_code: '',
            unit_no: '',
            identification_type: 'nric',
            identificationTypeList: [],
            _gender: '',
            genderList: [
                {
                    label: "",
                    value: "",
                },
                {
                    label: "Male",
                    value: "male",
                },
                {
                    label: "Female",
                    value: "female",
                },
                {
                    label: "I do not wish to specify",
                    value: "other",
                },
            ],
            isDatePickerOpen: false,
            race: "",
            residentialstatus: "",
            nationality: "Singapore Citizen",
            raceList: [],
            residentialstatusList: [],
            nationalityList: [],
        }
    }

    initMap = () => {
        this.setState({
            gmapsLoaded: true,
        });
    };

    componentDidMount() {
        window.initMap = this.initMap;
        const gmapScriptEl = document.createElement(`script`);
        gmapScriptEl.src =
            `https://maps.googleapis.com/maps/api/js?key=` +
            process.env.REACT_APP_GOOGLE_API_KEY +
            `&libraries=places&callback=initMap`;
        document
            .querySelector(`body`)
            .insertAdjacentElement(`beforeend`, gmapScriptEl);
        this.getCountryList();
        this.checkUserInfo();
        this.getIdentificationTypeList()
        this.getRaceAndRaceStatusList("residential_status");
        this.getRaceAndRaceStatusList("race");
        this.getRaceAndRaceStatusList("nationality");
    }

    getRaceAndRaceStatusList(_attribute) {
        let self = this;
        AuthGuard.getRaceAndRaceStatusList({
            attribute: _attribute
        }, (isOk, res) => {
            if (isOk) {
                if (_attribute == "residential_status") {
                    let _residentialstatusList = [];
                    let _result = res?.list?.SG;
                    _residentialstatusList.push({
                        value: "",
                        label: "",
                    });
                    _result.map((item, index) => {
                        _residentialstatusList.push({
                            value: item,
                            label: item,
                        })
                    });
                    self.setState({
                        residentialstatusList: _residentialstatusList
                    })
                }
                if (_attribute == "race") {
                    let _raceList = [];
                    let _result2 = res?.list?.SG;
                    _raceList.push({
                        value: "",
                        label: "",
                    });
                    _result2.map((item, index) => {
                        _raceList.push({
                            value: item,
                            label: item,
                        })
                    });
                    self.setState({
                        raceList: _raceList
                    })
                }
                if (_attribute == "nationality") {
                    let _nationalityList = [];
                    let _result3 = res?.list?.SG;
                    _nationalityList.push({
                        value: "",
                        label: "",
                    });
                    _result3.map((item, index) => {
                        _nationalityList.push({
                            value: item,
                            label: item,
                        })
                    });
                    self.setState({
                        nationalityList: _nationalityList
                    })
                }
            }
        })
    }

    getIdentificationTypeList() {
        AuthGuard.getIdentificationTypeList({
            group: "identification_type"
        }, (isOk, res) => {
            let _identificationTypeList = []
            if (isOk) {
                res.map((item, index) => {
                    _identificationTypeList.push({
                        value: item.code,
                        label: item.display_name,
                    })
                })
                this.setState({
                    identificationTypeList: _identificationTypeList
                })
            }
        })
    }

    getAddress = (val) => {
        let _address = "";
        if (val) {
            AuthGuard.getGeoAdd(
                {
                    address: val,
                },
                (isOk, res) => {
                    // console.log(isOk, res)
                    if (isOk) {
                        _address = res.formatted_address;
                        this.setState(
                            {
                                ...res,
                                address: res.formatted_address,
                                isDisplay: res.country_code !== "SG",
                            },
                            () => {
                                this.getCountryList();
                            }
                        );
                    }
                }
            );
        }
        this.setState({
            address: _address,
        });
    };

    getCountryList = () => {
        this.loadingToast();
        AuthGuard.getCountry({}, (isOk) => {
            if (isOk) {
                this.setState({
                    dialingCodeList: addKeyValueForDorpList(AuthGuard.listCountrys, 'dialing_code_name'),
                    countryList: addKeyValueForDorpList(AuthGuard.listCountrys, "country_list"),
                    countryListArr: AuthGuard.listCountrys
                },
                    () => {
                        if (this.state.country_code !== "") {
                            if (this.state.country_code === "SG") {
                                this.setState(
                                    {
                                        province_code: "",
                                        city_code: "",
                                    },
                                    () => {
                                        this.getProvinceList();
                                    }
                                );
                            } else {
                                this.getProvinceList();
                            }
                        }
                    }
                );
            } else {

            }
            Toast.hide();
        });
    }

    checkUserInfo() {
        let singPassCode = getCache('state');
        if (!isInvalid(singPassCode) && singPassCode !== null && singPassCode) {
            this.setState({
                singPassCode: singPassCode
            })
            this.getSingPassInfo();
        } else {
            this.setState({
                singPassCode: ''
            })
        }
    }

    getSingPassInfo = () => {
        let self = this;
        let singPassCode = getCache('state');
        this.loadingToast()
        AuthGuard.getSingPassInfo({
            state: singPassCode
        }, (isOk, res) => {
            if (isOk) {
                self.setState({
                    address: res.address,
                    birth: new Date(res.birth) || new Date(1980, 0, 1),
                    country_code: res.country_code ? res.country_code : 'SG',
                    country_name: res.country_name,
                    dialingCode: res.dialingCode,
                    email: res.email,
                    fullName: res.fullName,
                    gender: res.gender,
                    marital_status: res.marital_status,
                    mobile: res.mobile,
                    myInfoId: res.myInfoId,
                    nationality: res.nationality ? toHump(res.nationality) : "Singapore Citizen",
                    nric: res.nric,
                    postal_code: res.postal_code,
                    unit_no: res.unit_no,
                }, () => {
                    if (self.state.email) {
                        self.checkIsExist('email', self.state.email);
                    }
                    if (self.state.mobile) {
                        self.checkIsExist('mobile', self.state.mobile);
                    }
                    if (self.state.nric) {
                        self.checkIsExist('nric', self.state.nric);
                    }
                });
            } else {
                Modal.alert('', res.msg, [{ text: 'OK', onPress: () => console.log('ok') }]);
            }
            removeCache('state')
        });
        Toast.hide()
    }

    getProvinceList = () => {
        if (!this.state.country_code) return;
        this.setState({ provinceList: [], cityList: [] })
        AuthGuard.getProvinceList(
            {
                code: this.state.country_code,
            },
            (isOk, res) => {
                if (isOk) {
                    let { province_code } = this.state;
                    this.setState(
                        {
                            provinceList: addKeyValueForDorpList(
                                res[0].province_list,
                                "country_list"
                            ),
                            // province_code: province_code
                            //     ? province_code
                            //     : res &&
                            //         res.length > 0 &&
                            //         res[0].province_list &&
                            //         res[0].province_list.length > 0
                            //         ? res[0].province_list[0].code
                            //         : "",
                        },
                        () => {
                            if (this.state.province_code !== "") {
                                if (this.state.country_code === "SG") {
                                    this.setState(
                                        {
                                            province_code: "",
                                            city_code: "",
                                        },
                                        () => {
                                            this.getCityList();
                                        }
                                    );
                                } else {
                                    this.getCityList();
                                }
                            }
                        }
                    );
                }
            }
        );
    };

    getCityList = () => {
        if (!this.state.province_code) return;
        AuthGuard.getCityList(
            {
                province_code: this.state.province_code,
            },
            (isOk, res) => {
                if (isOk) {
                    // console.log(res)
                    let { city_code } = this.state;
                    this.setState({
                        cityList: addKeyValueForDorpList(res, "country_list"),
                        // city_code: city_code
                        //     ? city_code
                        //     : res && res.length > 0
                        //         ? res[0].code
                        //         : "",
                    });
                }
            }
        );
    }

    loadingToast() {
        Toast.loading('Loading...', 30, () => {
            console.log('Load complete !!!');
        });
    }

    checkField(resM) {
        let result = { isok: true }
        let emailMsg = '';
        let mobileMsg = '';
        let nameMsg = '';
        let itypeMsg = '';
        let icnMsg = '';
        let dobMsg = '';
        let genderMsg = '';
        let countryMsg = '';
        let provinceMsg = '';
        let cityMsg = '';
        let posMsg = '';
        let addressMsg = '';
        let unitMsg = '';
        let nationalityMsg = '';
        let raceMsg = '';

        if ((!this.state.email || this.state.email == '' || this.state.email.trim() == '')) {
            result.isok = false;
            emailMsg = 'Please fill in the required field.';
        } else if (!isEmail(this.state.email)) {
            result.isok = false;
            emailMsg = 'Please fill in a valid email address.'
        }
        if ((!this.state.mobile || this.state.mobile == '' || this.state.mobile.trim() == '')) {
            result.isok = false;
            mobileMsg = 'Please fill in the required field.';
        } else if (!isMobileNo(this.state.mobile, this.state.dialingCode, resM)) {
            result.isok = false;
            mobileMsg = 'Please fill in a valid mobile number.'
        }
        if ((!this.state.fullName || this.state.fullName == '' || this.state.fullName.trim() == '')) {
            result.isok = false;
            nameMsg = 'Please fill in the required field.';
        }
        if ((!this.state.identification_type || this.state.identification_type == '')) {
            result.isok = false;
            itypeMsg = 'Please fill in the required field.';
        }
        if ((!this.state.nric || this.state.nric == '' || this.state.nric.trim() == '')) {
            result.isok = false;
            icnMsg = 'Please fill in the required field.';
        }
        if ((!this.state.birth || this.state.birth == '')) {
            result.isok = false;
            dobMsg = 'Please fill in the required field.';
        }
        if ((!this.state.gender || this.state.gender == '' || this.state.gender.trim() == '')) {
            result.isok = false;
            genderMsg = 'Please fill in the required field.';
        }
        if ((!this.state.country_code || this.state.country_code == '')) {
            result.isok = false;
            countryMsg = 'Please fill in the required field.'
        }
        // if ((!this.state.province_code || this.state.province_code == '') && this.state.isDisplay) {
        //     result.isok = false;
        //     provinceMsg = 'Please fill in the required field.'
        // }
        // if ((!this.state.city_code || this.state.city_code == '') && this.state.isDisplay) {
        //     result.isok = false;
        //     cityMsg = 'Please fill in the required field.'
        // }
        if ((!this.state.postal_code || this.state.postal_code == '' || this.state.postal_code.trim() == '')) {
            result.isok = false;
            posMsg = 'Please fill in the required field.'
        }
        if ((!this.state.address || this.state.address == '' || this.state.address.trim() == '')) {
            result.isok = false;
            addressMsg = 'Please fill in the required field.'
        }
        if ((!this.state.race || this.state.race == '' || this.state.race.trim() == '')) {
            result.isok = false;
            raceMsg = 'Please fill in the required field.'
        }
        if ((!this.state.nationality || this.state.nationality == '' || this.state.nationality.trim() == '')) {
            result.isok = false;
            nationalityMsg = 'Please fill in the required field.'
        }

        this.setState({
            errorMsg: {
                emailMsg: emailMsg,
                mobileMsg: mobileMsg,
                nameMsg: nameMsg,
                itypeMsg: itypeMsg,
                icnMsg: icnMsg,
                dobMsg: dobMsg,
                genderMsg: genderMsg,
                countryMsg: countryMsg,
                provinceMsg: provinceMsg,
                cityMsg: cityMsg,
                posMsg: posMsg,
                addressMsg: addressMsg,
                nationalityMsg: nationalityMsg,
                raceMsg: raceMsg,
            }
        })
        return result;
    }

    sendOpt = async (e) => {
        if (this.state.emailMsg || this.state.mobileMsg || this.state.nircMsg) {
            return;
        }
        this.setState({ errorMsg: {} })
        let res = this.state.countryListArr.find((item, index) => {
            return item.dialing_code === this.state.dialingCode;
        })
        let resM = res.mobile_no_format ? res.mobile_no_format : '';
        let checkResult = this.checkField(resM);
        if (!checkResult.isok) {
            return;
        }
        if (this.state.tc == 'N') {
            Modal.alert('Error', 'Please read and agree to the Terms & Conditions before proceeding.', [{ text: 'OK', onPress: () => console.log('ok') }]);
            return;
        }
        // let res = false
        // try {
        //     res = await this.checkAddress()
        // } catch (error) {
        //     res = false
        // }
        // if (!res) {
        // return;
        // }
        this.loadingToast();
        AuthGuard.SignUpSendOtp({
            email: this.state.email,
            dialingCode: this.state.dialingCode,
            mobile: this.state.mobile,
            nric: this.state.nric,
        }
            , (isOk, res) => {
                console.log(res)
                if (isOk) {
                    let cacheObj = {
                        fromPage: '/signUpMyinfo',
                        type: 'myinfo',
                        dialingCode: this.state.dialingCode,
                        mobile: this.state.mobile,
                        email: this.state.email,
                        tc: this.state.tc,
                        other: this.state.other,
                        fullName: this.state.fullName,
                        nric: this.state.nric,
                        birth: moment(this.state.birth).format('YYYY-MM-DD'),
                        gender: this.genderFormat(this.state.gender),
                        country_code: this.state.country_code,
                        city_code: this.state.country_code === 'SG' ? '' : this.state.city_code,
                        province_code: this.state.country_code === 'SG' ? '' : this.state.province_code,
                        country_name: this.getCountryName(this.state.country_code),
                        province_name: this.getProvinceName(this.state.province_code),
                        city_name: this.getCityName(this.state.city_code),
                        postal_code: this.state.postal_code,
                        address: this.state.address,
                        unit_no: this.state.unit_no,
                        myInfoId: this.state.myInfoId,
                        identification_type: this.state.identification_type,
                        race: this.state.race,
                        residential_status: this.state.residentialstatus,
                        nationality: this.state.nationality,
                    }
                    removeCache('verifyOptFor');
                    setCache('verifyOptFor', cacheObj)
                    this.props.history.push('/newVerifyOTP/')
                } else {
                    if (res.status_code == 11033) {
                        if (res.result.length > 0) {
                            let _mobileMsg = '';
                            let _emailMsg = '';
                            let _icnMsg = '';
                            if (res.result.includes('mobile')) {
                                _mobileMsg = res.message;
                            }
                            if (res.result.includes('email')) {
                                _emailMsg = res.message;
                            }
                            if (res.result.includes('nric')) {
                                _icnMsg = res.message;
                            }
                            this.setState({
                                errorMsg: {
                                    emailMsg: _emailMsg,
                                    mobileMsg: _mobileMsg,
                                    icnMsg: _icnMsg
                                }
                            })
                        } else {
                            Modal.alert('warning', res.message, [{ text: 'OK', onPress: () => console.log('ok') }]);
                        }
                    } else if (res.status_code == 11015) {
                        this.setState({
                            errorMsg: { unitMsg: res.message }
                        })
                    } else {
                        Modal.alert('warning', res.message, [{ text: 'OK', onPress: () => console.log('ok') }]);
                    }
                }
                Toast.hide();
            });
    }

    checkAddress = () => {
        return new Promise((resolve, reject) => {
            AuthGuard.checkRegAdd({
                param: this.state.address
            }, (isOk, res) => {
                if (isOk) {
                    resolve(res)
                } else {
                    Modal.alert('Notice', res.message, [{ text: 'OK', onPress: () => console.log('ok') }]);
                    reject(false)
                }
            })
        })
    }

    getCountryName(code) {
        let name = ''
        if (code) {
            this.state.countryList.map((item) => {
                if (item.value === code) {
                    name = item.label
                }
                return item
            })
        }
        return name

    }
    getProvinceName(code) {
        if (this.state.country_code === 'SG') return ''
        let name = ''
        if (code) {
            this.state.provinceList.map((item) => {
                if (item.value === code) {
                    name = item.label
                }
                return item
            })
        }

        return name

    }
    getCityName(code) {
        if (this.state.country_code === 'SG') return ''
        let name = ''
        if (code) {
            this.state.cityList.map((item) => {
                if (item.value === code) {
                    name = item.label
                }
                return item
            })
        }

        return name
    }

    handleSelect = (e) => {
        this.setState({
            birth: e,
            isDatePickerOpen: false,
        });

        document.body.removeEventListener("touchmove", this.stopScroll);
        //添加事件监听时添加了passive参数，在ios9中移除事件监听也需要加此参数
        document.body.removeEventListener("touchmove", this.stopScroll, {
            passive: true,
        });
        document.body.style.overflow = "auto";
    };

    handleCancel = () => {
        this.setState({
            isDatePickerOpen: false,
        });

        document.body.removeEventListener("touchmove", this.stopScroll);
        //添加事件监听时添加了passive参数，在ios9中移除事件监听也需要加此参数
        document.body.removeEventListener("touchmove", this.stopScroll, {
            passive: true,
        });
        document.body.style.overflow = "auto";
    };

    openDatePicker = (e) => {
        e.stopPropagation();
        if (this.state._birth) return false;
        this.setState({
            isDatePickerOpen: true,
        });

        document.body.addEventListener("touchmove", this.stopScroll, {
            passive: false,
        });
        document.body.style.overflow = "hidden";
    };

    stopScroll(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    genderFormat() {
        if (this.state.gender == 'F' || this.state.gender == 'f' || this.state.gender == 'female') {
            return 'female';
        }
        if (this.state.gender == 'M' || this.state.gender == 'm' || this.state.gender == 'male') {
            return 'male';
        }
        if (this.state.gender == 'other') {
            return 'other';
        }
        if (this.state.gender == '' || this.state.gender == null) {
            return '';
        }
        return 'other';
    }

    goBack = () => {
        this.props.history.push('/login')
    }

    checkIsExist = (type, value) => {
        this.loadingToast();
        if (type == 'mobile') {
            value = this.state.dialingCode.concat(value)
        }
        AuthGuard.checkIsExist({ type: type, value: value }, (isOk, res) => {
            if (!isOk) {
                if (type == 'email') {
                    this.setState({ emailMsg: res });
                } else if (type == 'mobile') {
                    this.setState({ mobileMsg: res });
                } else {
                    this.setState({ nricMsg: res });
                }
            } else {
                if (type == 'email') {
                    this.setState({ emailMsg: '' });
                } else if (type == 'mobile') {
                    this.setState({ mobileMsg: '' });
                } else {
                    this.setState({ nricMsg: '' });
                }
            }
            Toast.hide();
        });
    }

    render() {
        let options = []
        if (isBrowser) {
            this.state.countryListArr.map((item) => {
                options.push({
                    value: item.dialing_code,
                    flag: item.flag_image_url?.url?.s,
                    label: <div className="selectOptions">
                        <img src={item.flag_image_url?.url?.s} /> {item.name} +{item.dialing_code}
                    </div>
                })
            })
        }

        return (
            <div className="Register-Page-V2 h5-Page login-Page signUp-myInfo">
                <NavBar
                    mode="light"
                    icon={<Icon type="left" color="#EB5DA6" size="lg" />}
                    onLeftClick={this.goBack}
                ></NavBar>
                <WingBlank>
                    <div className="content">
                        <WhiteSpace size="lg" />
                        <Flex>
                            <Flex.Item>
                                <p className="sign-up-title">Verify your details below!</p>
                            </Flex.Item>
                        </Flex>
                        <WhiteSpace size="lg" />
                        <WhiteSpace size="lg" />
                        <Flex>
                            <Flex.Item>
                                <div className="progress-bar-left-right"></div>
                                <div className="progress-bar" style={{ backgroundColor: '#EB5DA6' }}></div>
                                <div className="progress-bar"></div>
                                <div className="progress-bar-left-right"></div>
                            </Flex.Item>
                        </Flex>
                        <WhiteSpace size="lg" />
                        <WhiteSpace size="lg" />
                        <WhiteSpace size="lg" />
                        <Flex>
                            <Flex.Item>
                                <p className="sign-up-title">Contact Information</p>
                            </Flex.Item>
                        </Flex>
                        <WhiteSpace size="lg" />
                        <div className="input_item">
                            <div className="input-title">{this.state.email ? 'Email *' : ''}</div>
                            <Flex>
                                <Flex.Item>
                                    <InputItem
                                        // clear
                                        style={{ fontFamily: 'Montserrat-Regular' }}
                                        name="mobile_email"
                                        placeholder="Email *"
                                        value={this.state.email}
                                        onBlur={(v) => {
                                            let _email = v.trim();
                                            if (_email) {
                                                this.checkIsExist('email', _email)
                                            }
                                        }}
                                        onChange={(v) => {
                                            this.setState({ email: v });
                                        }}
                                    ></InputItem>
                                </Flex.Item>
                            </Flex>
                        </div>
                        {this.state.errorMsg.emailMsg ?
                            <Flex.Item className="errorMsg">
                                <WhiteSpace size="md" />
                                <p>{this.state.errorMsg.emailMsg}</p>
                            </Flex.Item> : ''
                        }
                        {this.state.emailMsg ?
                            <Flex.Item className="errorMsg">
                                <WhiteSpace size="md" />
                                <p>{this.state.emailMsg}</p>
                            </Flex.Item> : ''
                        }
                        <WhiteSpace size="lg" />
                        <div className="input_item">
                            <div className="input-title">{this.state.mobile ? 'Mobile Number *' : ''}</div>
                            <Flex className={`${this.state._dialingCode ? "flGrey" : ""}`} className="inputField">
                                {isBrowser ? (
                                    <Flex>
                                        <Select
                                            components={{ DropdownIndicator, SingleValueForDialingCode }}
                                            name="dialingCode"
                                            value={this.state.dialingCode ? options.find(option => option.value == this.state.dialingCode) : null}
                                            onChange={(e) => {
                                                this.setState({
                                                    dialingCode: e.value
                                                })
                                            }}
                                            styles={selectStylesForDialingCodeV2}
                                            theme={(theme) => ({
                                                ...theme,
                                                zIndex: -100000,
                                                border: '1px solid #ffffff'
                                            })}
                                            menuPortalTarget={document.querySelector('#root')}
                                            isSearchable={false}
                                            options={options}
                                            placeholder={'Select Dialing Code'}
                                        />
                                    </Flex>
                                ) : (
                                    <Picker
                                        data={this.state.dialingCodeList}
                                        locale={enUs}
                                        extra="Dialing Code"
                                        disabled={this.state._dialingCode}
                                        cols={1}
                                        value={[this.state.dialingCode]}
                                        format={(labels) => {
                                            if (!labels.length) return
                                            return [
                                                labels[0].props.children[0],
                                                labels[0].props.children[2],
                                            ]
                                        }}
                                        onChange={(v) => {
                                            this.setState({
                                                dialingCode: v[0],
                                            });
                                        }}
                                    >
                                        <CustomChildren
                                            sClas={this.state.dialingCode ? "sel" : "noSel"}
                                        ></CustomChildren>
                                    </Picker>
                                )}
                                <Flex.Item>
                                    <InputItem
                                        clear
                                        name=""
                                        placeholder="Mobile Number *"
                                        value={this.state.mobile}
                                        type='number'
                                        onBlur={(v) => {
                                            let mobile = v.trim();
                                            if (mobile) {
                                                this.checkIsExist('mobile', mobile)
                                            }
                                        }}
                                        onChange={(v) => {
                                            this.setState({ mobile: v });
                                        }}
                                    ></InputItem>
                                </Flex.Item>
                            </Flex>
                        </div>
                        {this.state.errorMsg.mobileMsg ?
                            <Flex.Item className="errorMsg">
                                <WhiteSpace size="md" />
                                <p>{this.state.errorMsg.mobileMsg}</p>
                            </Flex.Item> : ''
                        }
                        {this.state.mobileMsg ?
                            <Flex.Item className="errorMsg">
                                <WhiteSpace size="md" />
                                <p>{this.state.mobileMsg}</p>
                            </Flex.Item> : ''
                        }
                        <WhiteSpace size="lg" />
                        <WhiteSpace size="lg" />
                        <WhiteSpace size="lg" />
                        <Flex>
                            <Flex.Item>
                                <p className="sign-up-title">Personal Information</p>
                            </Flex.Item>
                        </Flex>
                        <WhiteSpace size="lg" />
                        <div className="input_item">
                            <div className="input-title">{this.state.fullName ? 'Full Name (as in NRIC / Passport) *' : ''}</div>
                            <Flex>
                                <Flex.Item>
                                    <InputItem
                                        clear
                                        style={{ fontFamily: 'Montserrat-Regular' }}
                                        name=""
                                        placeholder="Full Name (as in NRIC / Passport) *"
                                        value={this.state.fullName}
                                        onChange={(v) => {
                                            this.setState({
                                                fullName: v,
                                            });
                                        }}
                                    ></InputItem>
                                </Flex.Item>
                            </Flex>
                        </div>
                        {this.state.errorMsg.nameMsg ?
                            <Flex.Item className="errorMsg">
                                <WhiteSpace size="md" />
                                <p>{this.state.errorMsg.nameMsg}</p>
                            </Flex.Item> : ''
                        }
                        <WhiteSpace size="lg" />
                        <div className="input_item">
                            <div className="input-title">{this.state.identification_type ? 'Identification Type *' : ''}</div>
                            <Flex>
                                <Flex.Item className="select-module">
                                    {isBrowser ? (
                                        <Flex.Item>
                                            <Select
                                                components={{ DropdownIndicator }}
                                                name="identification_type"
                                                value={this.state.identification_type ? this.state.identificationTypeList.find(option => option.value == this.state.identification_type) : null}
                                                onChange={(e) => {
                                                    this.setState({
                                                        identification_type: e.value
                                                    })
                                                }}
                                                styles={selectStylesForDownList}
                                                menuPortalTarget={document.querySelector('#root')}
                                                isSearchable={false}
                                                options={this.state.identificationTypeList}
                                                placeholder={'Identification Type *'}
                                            />
                                        </Flex.Item>
                                    ) : (
                                        <Picker
                                            clear
                                            data={this.state.identificationTypeList}
                                            locale={enUs}
                                            value={[this.state.identification_type]}
                                            style={{ fontFamily: 'Montserrat-Regular' }}
                                            placeholder="Identification Type *"
                                            cols={1}
                                            onChange={(v) => {
                                                this.setState({
                                                    identification_type: v[0],
                                                });
                                            }}
                                        >
                                            <CustomChildren
                                                sClas={this.state.identification_type ? "sel" : "noSel"}
                                            ></CustomChildren>
                                        </Picker>
                                    )}
                                </Flex.Item>
                            </Flex>
                        </div>
                        {this.state.errorMsg.itypeMsg ?
                            <Flex.Item className="errorMsg">
                                <WhiteSpace size="md" />
                                <p>{this.state.errorMsg.itypeMsg}</p>
                            </Flex.Item> : ''
                        }
                        <WhiteSpace size="lg" />
                        <div className="input_item">
                            <div className="input-title">{this.state.nric ? 'Identification Number *' : ''}</div>
                            <Flex>
                                <Flex.Item>
                                    <InputItem
                                        clear
                                        style={{ fontFamily: 'Montserrat-Regular' }}
                                        name=""
                                        placeholder="Identification Number *"
                                        value={this.state.nric}
                                        onBlur={(v) => {
                                            let nric = v.trim();
                                            if (nric) {
                                                this.checkIsExist('nric', nric)
                                            }
                                        }}
                                        onChange={(v) => {
                                            this.setState({
                                                nric: v.toUpperCase(),
                                            });
                                        }}
                                    ></InputItem>
                                </Flex.Item>
                            </Flex>
                        </div>
                        {this.state.errorMsg.icnMsg ?
                            <Flex.Item className="errorMsg">
                                <WhiteSpace size="md" />
                                <p>{this.state.errorMsg.icnMsg}</p>
                            </Flex.Item> : ''
                        }
                        {this.state.nricMsg ?
                            <Flex.Item className="errorMsg">
                                <WhiteSpace size="md" />
                                <p>{this.state.nricMsg}</p>
                            </Flex.Item> : ''
                        }
                        <WhiteSpace size="lg" />
                        <div className="input_item">
                            <div className="input-title">{this.state.birth ? 'Date of Birth *' : ''}</div>
                            <Flex onClick={!isBrowser ? (this.openDatePicker.bind(this)) : ""}>
                                <Flex.Item>
                                    <div>
                                        {!isBrowser ? (
                                            <InputItem
                                                clear
                                                style={{ fontFamily: 'Montserrat-Regular' }}
                                                name=""
                                                placeholder="Date of Birth *"
                                                value={formatDate(this.state.birth)}
                                                onFocus={() => {
                                                    document.activeElement.blur();
                                                }}
                                            ></InputItem>
                                        ) : ""}
                                    </div>
                                    {!isBrowser ? (
                                        <DatePicker
                                            mode="date"
                                            showHeader={false}
                                            value={this.state.birth}
                                            isOpen={this.state.isDatePickerOpen}
                                            onSelect={this.handleSelect}
                                            onCancel={this.handleCancel}
                                            min={new Date(1900, 1, 1)}
                                            max={new Date()}
                                        >
                                        </DatePicker>
                                    ) : ""}
                                    {!isBrowser ? "" : (
                                        <DatePickerBor
                                            selected={this.state.birth}
                                            onChange={(date) => {
                                                this.setState({
                                                    birth: date,
                                                });
                                            }}
                                            dateFormat={"dd/MMM/yyyy"}
                                            dateFormatCalendar={"MMM yyyy"}
                                            // minDate={new Date(1980)}
                                            className='datePickerCalendarPCInput'
                                            calendarClassName="datePickerCalendarPC"
                                            showMonthDropdown
                                            showYearDropdown
                                        />
                                    )}
                                </Flex.Item>
                                <span><img src={calendar} style={{ width: '1em', marginRight: '1em' }} alt="" /></span>
                            </Flex>
                        </div>
                        {this.state.errorMsg.dobMsg ?
                            <Flex.Item className="errorMsg">
                                <WhiteSpace size="md" />
                                <p>{this.state.errorMsg.dobMsg}</p>
                            </Flex.Item> : ''
                        }
                        <WhiteSpace size="lg" />
                        <div className="input_item">
                            <div className="input-title">{this.state.gender ? 'Gender *' : ''}</div>
                            <Flex>
                                <Flex.Item className="select-module">
                                    {isBrowser ? (
                                        <Flex.Item>
                                            <Select
                                                components={{ DropdownIndicator }}
                                                name="gender"
                                                value={this.state.gender ? this.state.genderList.find(option => option.value == this.state.gender) : null}
                                                onChange={(e) => {
                                                    this.setState({
                                                        gender: e.value,
                                                    });
                                                }}
                                                styles={selectStylesForDownList}
                                                menuPortalTarget={document.querySelector('#root')}
                                                isSearchable={false}
                                                options={this.state.genderList}
                                                placeholder={"Gender *"}
                                            />
                                        </Flex.Item>
                                    ) : (
                                        <Picker
                                            data={this.state.genderList}
                                            style={{ fontFamily: 'Montserrat-Regular' }}
                                            locale={enUs}
                                            value={[this.genderFormat(this.state.gender)]}
                                            extra="Gender *"
                                            cols={1}
                                            onChange={(v) => {
                                                this.setState({
                                                    gender: v[0],
                                                });
                                            }}>
                                            <CustomChildren
                                                sClas={this.state.gender ? "sel" : "noSel"}
                                            ></CustomChildren>
                                        </Picker>
                                    )}
                                </Flex.Item>
                            </Flex>
                        </div>
                        {this.state.errorMsg.genderMsg ?
                            <Flex.Item className="errorMsg">
                                <WhiteSpace size="md" />
                                <p>{this.state.errorMsg.genderMsg}</p>
                            </Flex.Item> : ''
                        }
                        <WhiteSpace size="lg" />
                        <div className="input_item">
                            <div className="input-title">{this.state.nationality ? 'Nationality *' : ''}</div>
                            <Flex>
                                <Flex.Item className="select-module">
                                    {isBrowser ? (
                                        <Flex.Item>
                                            <Select
                                                components={{ DropdownIndicator }}
                                                name="nationality"
                                                value={this.state.nationality ? this.state.nationalityList.find(option => option.value == this.state.nationality) : null}
                                                onChange={(e) => {
                                                    this.setState({
                                                        nationality: e.value
                                                    })
                                                }}
                                                styles={selectStylesForDownList}
                                                menuPortalTarget={document.querySelector('#root')}
                                                isSearchable={false}
                                                options={this.state.nationalityList}
                                                placeholder={'Nationality *'}
                                            />
                                        </Flex.Item>
                                    ) : (
                                        <Picker
                                            data={this.state.nationalityList}
                                            style={{ fontFamily: 'Montserrat-Regular' }}
                                            locale={enUs}
                                            value={[this.state.nationality]}
                                            extra="Nationality *"
                                            cols={1}
                                            onChange={(v) => {
                                                this.setState({
                                                    nationality: v[0],
                                                });
                                            }}>
                                            <CustomChildren
                                                sClas={this.state.nationality ? "sel" : "noSel"}
                                            ></CustomChildren>
                                        </Picker>
                                    )}
                                </Flex.Item>
                            </Flex>
                        </div>
                        {this.state.errorMsg.nationalityMsg ?
                            <Flex.Item className="errorMsg">
                                <WhiteSpace size="md" />
                                <p>{this.state.errorMsg.nationalityMsg}</p>
                            </Flex.Item> : ''
                        }
                        <WhiteSpace size="lg" />
                        <div className="input_item">
                            <div className="input-title">{this.state.race ? 'Race *' : ''}</div>
                            <Flex>
                                <Flex.Item className="select-module">
                                    {isBrowser ? (
                                        <Flex.Item>
                                            <Select
                                                components={{ DropdownIndicator }}
                                                name="race"
                                                value={this.state.race ? this.state.raceList.find(option => option.value == this.state.race) : null}
                                                onChange={(e) => {
                                                    this.setState({
                                                        race: e.value
                                                    })
                                                }}
                                                styles={selectStylesForDownList}
                                                menuPortalTarget={document.querySelector('#root')}
                                                isSearchable={false}
                                                options={this.state.raceList}
                                                placeholder={'Race *'}
                                            />
                                        </Flex.Item>
                                    ) : (
                                        <Picker
                                            data={this.state.raceList}
                                            style={{ fontFamily: 'Montserrat-Regular' }}
                                            locale={enUs}
                                            value={[this.state.race]}
                                            extra="Race *"
                                            cols={1}
                                            onChange={(v) => {
                                                this.setState({
                                                    race: v[0],
                                                });
                                            }}>
                                            <CustomChildren
                                                sClas={this.state.race ? "sel" : "noSel"}
                                            ></CustomChildren>
                                        </Picker>
                                    )}
                                </Flex.Item>
                            </Flex>
                        </div>
                        {this.state.errorMsg.raceMsg ?
                            <Flex.Item className="errorMsg">
                                <WhiteSpace size="md" />
                                <p>{this.state.errorMsg.raceMsg}</p>
                            </Flex.Item> : ''
                        }
                        <WhiteSpace size="lg" />
                        <div className="input_item">
                            <div className="input-title">{this.state.residentialstatus ? 'Residential Status' : ''}</div>
                            <Flex>
                                <Flex.Item className="select-module">
                                    {isBrowser ? (
                                        <Flex.Item>
                                            <Select
                                                components={{ DropdownIndicator }}
                                                name="residentialstatus"
                                                value={this.state.residentialstatus ? this.state.residentialstatusList.find(option => option.value == this.state.residentialstatus) : null}
                                                onChange={(e) => {
                                                    this.setState({
                                                        residentialstatus: e.value
                                                    })
                                                }}
                                                styles={selectStylesForDownList}
                                                menuPortalTarget={document.querySelector('#root')}
                                                isSearchable={false}
                                                options={this.state.residentialstatusList}
                                                placeholder={'Residential Status'}
                                            />
                                        </Flex.Item>
                                    ) : (
                                        <Picker
                                            data={this.state.residentialstatusList}
                                            style={{ fontFamily: 'Montserrat-Regular' }}
                                            locale={enUs}
                                            value={[this.state.residentialstatus]}
                                            extra="Residential Status"
                                            cols={1}
                                            onChange={(v) => {
                                                this.setState({
                                                    residentialstatus: v[0],
                                                });
                                            }}>
                                            <CustomChildren
                                                sClas={this.state.residentialstatus ? "sel" : "noSel"}
                                            ></CustomChildren>
                                        </Picker>
                                    )}
                                </Flex.Item>
                            </Flex>
                        </div>
                        {this.state.errorMsg.residentialstatusMsg ?
                            <Flex.Item className="errorMsg">
                                <WhiteSpace size="md" />
                                <p>{this.state.errorMsg.residentialstatusMsg}</p>
                            </Flex.Item> : ''
                        }
                        <WhiteSpace size="lg" />
                        <WhiteSpace size="lg" />
                        <WhiteSpace size="lg" />
                        <Flex>
                            <Flex.Item>
                                <p className="sign-up-title">Address</p>
                            </Flex.Item>
                        </Flex>
                        <WhiteSpace size="lg" />
                        <Flex.Item>
                            {this.state.gmapsLoaded && (
                                <div className="serch_myinfo">
                                    <GoogleSearchInput
                                        getAddress={this.getAddress}
                                    ></GoogleSearchInput>
                                </div>
                            )}
                        </Flex.Item>
                        <WhiteSpace size="lg" />
                        <div className="input_item">
                            <div className="input-title">{this.state.country_code ? 'Country *' : ''}</div>
                            <Flex>
                                <Flex.Item className="select-module">
                                    {isBrowser ? (
                                        <Flex.Item>
                                            <Select
                                                components={{ DropdownIndicator }}
                                                name="country"
                                                value={this.state.country_code ? this.state.countryList.find(option => option.value == this.state.country_code) : null}
                                                onChange={(e) => {
                                                    this.setState({
                                                        country_code: e.value
                                                    },
                                                        () => {
                                                            let show;
                                                            if (this.state.country_code === "SG") {
                                                                show = false;
                                                            } else {
                                                                show = true;
                                                            }
                                                            this.getProvinceList();
                                                            this.setState({
                                                                isDisplay: show,
                                                            });
                                                        })
                                                }}
                                                styles={selectStylesForDownList}
                                                menuPortalTarget={document.querySelector('#root')}
                                                isSearchable={false}
                                                options={this.state.countryList}
                                                placeholder={'Country *'}
                                            />
                                        </Flex.Item>
                                    ) : (
                                        <Picker
                                            data={this.state.countryList}
                                            extra="Country *"
                                            locale={enUs}
                                            cols={1}
                                            value={[this.state.country_code]}
                                            onChange={(v) => {
                                                this.setState(
                                                    {
                                                        country_code: v[0],
                                                    },
                                                    () => {
                                                        let show;
                                                        if (this.state.country_code === "SG") {
                                                            show = false;
                                                        } else {
                                                            show = true;
                                                        }
                                                        this.getProvinceList();
                                                        this.setState({
                                                            isDisplay: show,
                                                        });
                                                    }
                                                );
                                            }}
                                        >
                                            <CustomChildren
                                                sClas={this.state.country_code ? "sel" : "noSel"}
                                            ></CustomChildren>
                                        </Picker>
                                    )}
                                </Flex.Item>
                            </Flex>
                        </div>
                        {this.state.errorMsg.countryMsg ?
                            <Flex.Item className="errorMsg">
                                <WhiteSpace size="md" />
                                <p>{this.state.errorMsg.countryMsg}</p>
                            </Flex.Item> : ''
                        }
                        <WhiteSpace size="lg" />
                        {(this.state.country_code == 'SG' || this.state.country_code == '') ? '' :
                            <div>
                                <div className="input_item">
                                    <div className="input-title">{this.state.province_code ? 'Province' : ''}</div>
                                    <Flex>
                                        <Flex.Item className="select-module">
                                            {isBrowser ? (
                                                <Flex.Item>
                                                    <Select
                                                        components={{ DropdownIndicator }}
                                                        name="province"
                                                        value={this.state.province_code ? this.state.provinceList.find(option => option.value == this.state.province_code) : null}
                                                        onChange={(e) => {
                                                            this.setState(
                                                                {
                                                                    province_code: e.value
                                                                },
                                                                () => {
                                                                    this.getCityList();
                                                                }
                                                            );
                                                        }}
                                                        styles={selectStylesForDownList}
                                                        menuPortalTarget={document.querySelector('#root')}
                                                        isSearchable={false}
                                                        options={this.state.provinceList}
                                                        placeholder={'Province'}
                                                    />
                                                </Flex.Item>
                                            ) : (
                                                <Picker
                                                    data={this.state.provinceList}
                                                    extra="Province"
                                                    locale={enUs}
                                                    cols={1}
                                                    value={[this.state.province_code]}
                                                    onChange={(v) => {
                                                        this.setState(
                                                            {
                                                                province_code: v[0],
                                                            },
                                                            () => {
                                                                this.getCityList();
                                                            }
                                                        );
                                                    }}
                                                >
                                                    <CustomChildren
                                                        sClas={this.state.province_code ? "sel" : "noSel"}
                                                    ></CustomChildren>
                                                </Picker>
                                            )}
                                        </Flex.Item>
                                    </Flex>
                                </div>
                                {this.state.errorMsg.provinceMsg ?
                                    <Flex.Item className="errorMsg">
                                        <WhiteSpace size="md" />
                                        <p>{this.state.errorMsg.provinceMsg}</p>
                                    </Flex.Item> : ''
                                }
                                <WhiteSpace size="lg" />
                                <div className="input_item">
                                    <div className="input-title">{this.state.city_code ? 'City' : ''}</div>
                                    <Flex>
                                        <Flex.Item className="select-module">
                                            {isBrowser ? (
                                                <Flex.Item>
                                                    <Select
                                                        components={{ DropdownIndicator }}
                                                        name="city_code"
                                                        value={this.state.city_code ? this.state.cityList.find(option => option.value == this.state.city_code) : null}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                city_code: e.value
                                                            })
                                                        }}
                                                        styles={selectStylesForDownList}
                                                        menuPortalTarget={document.querySelector('#root')}
                                                        isSearchable={false}
                                                        options={this.state.cityList}
                                                        placeholder={'City'}
                                                    />
                                                </Flex.Item>
                                            ) : (
                                                <Picker
                                                    data={this.state.cityList}
                                                    extra="City"
                                                    cols={1}
                                                    locale={enUs}
                                                    value={[this.state.city_code]}
                                                    onChange={(v) => {
                                                        this.setState({
                                                            city_code: v[0],
                                                        });
                                                    }}
                                                >
                                                    <CustomChildren
                                                        sClas={this.state.city_code ? "sel" : "noSel"}
                                                    ></CustomChildren>
                                                </Picker>
                                            )}
                                        </Flex.Item>
                                    </Flex>
                                </div>
                                {this.state.errorMsg.cityMsg ?
                                    <Flex.Item className="errorMsg">
                                        <WhiteSpace size="md" />
                                        <p>{this.state.errorMsg.cityMsg}</p>
                                    </Flex.Item> : ''
                                }
                                <WhiteSpace size="lg" />
                            </div>}
                        <div className="input_item">
                            <div className="input-title">{this.state.postal_code ? 'Postal Code *' : ''}</div>
                            <Flex>
                                <Flex.Item>
                                    <InputItem
                                        clear
                                        value={this.state.postal_code}
                                        error={this.state.postal_codeError}
                                        placeholder="Postal Code *"
                                        onChange={(v) => this.setState({ postal_code: v })}
                                    ></InputItem>
                                </Flex.Item>
                            </Flex>
                        </div>
                        {this.state.errorMsg.posMsg ?
                            <Flex.Item className="errorMsg">
                                <WhiteSpace size="md" />
                                <p>{this.state.errorMsg.posMsg}</p>
                            </Flex.Item> : ''
                        }
                        <WhiteSpace size="lg" />
                        <div className="input_item">
                            <div className="input-title">{this.state.address ? 'Street *' : ''}</div>
                            <Flex>
                                <Flex.Item className="input-outline">
                                    <InputItem
                                        clear
                                        error={this.state.addressError}
                                        value={this.state.address}
                                        placeholder="Street *"
                                        onChange={(v) =>
                                            this.setState({
                                                address: v,
                                            })
                                        }
                                    ></InputItem>
                                </Flex.Item>
                            </Flex>
                        </div>
                        {this.state.errorMsg.addressMsg ?
                            <Flex.Item className="errorMsg">
                                <WhiteSpace size="md" />
                                <p>{this.state.errorMsg.addressMsg}</p>
                            </Flex.Item> : ''
                        }
                        {/* <WhiteSpace size="lg" /> */}
                        {/* <div className="input_item">
                            <div className="input-title">{this.state.country_name ? 'Country*' : ''}</div>
                        <Flex>
                            <Flex.Item>
                                <InputItem
                                clear
                                style={{fontFamily:'Montserrat-Regular'}}
                                name=""
                                placeholder="Country*"
                                value={this.state.country_name}
                                onChange={(v) => {
                                    this.setState({
                                        country_name: v,
                                    });
                                }}
                                ></InputItem>
                            </Flex.Item>
                        </Flex>
                        </div>
                        {   this.state.errorMsg.countryMsg ? 
                            <Flex.Item className="errorMsg">
                                <WhiteSpace size="md" />
                                <p>{this.state.errorMsg.countryMsg}</p>
                            </Flex.Item> : ''
                        }
                        <WhiteSpace size="lg" />
                        <div className="input_item">
                            <div className="input-title">{this.state.postal_code ? 'Postal Code*' : ''}</div>
                        <Flex>
                            <Flex.Item>
                                <InputItem
                                clear
                                style={{fontFamily:'Montserrat-Regular'}}
                                name=""
                                placeholder="Postal Code*"
                                value={this.state.postal_code}
                                onChange={(v) => {
                                    this.setState({
                                        postal_code: v,
                                    });
                                }}
                                ></InputItem>
                            </Flex.Item>
                        </Flex>
                        </div>
                        {   this.state.errorMsg.posMsg ? 
                            <Flex.Item className="errorMsg">
                                <WhiteSpace size="md" />
                                <p>{this.state.errorMsg.posMsg}</p>
                            </Flex.Item> : ''
                        }
                        <WhiteSpace size="lg" />
                        <div className="input_item">
                            <div className="input-title">{this.state.address ? 'Street*' : ''}</div>
                        <Flex>
                            <Flex.Item>
                                <InputItem
                                clear
                                style={{fontFamily:'Montserrat-Regular'}}
                                name=""
                                placeholder="Street*"
                                value={this.state.address}
                                onChange={(v) => {
                                    this.setState({
                                        address: v,
                                    });
                                }}
                                ></InputItem>
                            </Flex.Item>
                        </Flex>
                        </div>
                        {   this.state.errorMsg.addressMsg ? 
                            <Flex.Item className="errorMsg">
                                <WhiteSpace size="md" />
                                <p>{this.state.errorMsg.addressMsg}</p>
                            </Flex.Item> : ''
                        } */}
                        <WhiteSpace size="lg" />
                        <div className="input_item">
                            <div className="input-title">{this.state.unit_no ? 'Unit Number' : ''}</div>
                            <Flex>
                                <Flex.Item>
                                    <InputItem
                                        clear
                                        style={{ fontFamily: 'Montserrat-Regular' }}
                                        name=""
                                        placeholder="Unit Number"
                                        value={this.state.unit_no}
                                        onChange={(v) => {
                                            this.setState({
                                                unit_no: v,
                                            });
                                        }}
                                    ></InputItem>
                                </Flex.Item>
                            </Flex>
                        </div>
                        {this.state.errorMsg.unitMsg ?
                            <Flex.Item className="errorMsg">
                                <WhiteSpace size="md" />
                                <p>{this.state.errorMsg.unitMsg}</p>
                            </Flex.Item> : ''
                        }
                        <WhiteSpace size="lg" />
                        <AgreeItem data-seed="logId" onChange={e => { if (e.target.checked) { this.setState({ tc: 'Y' }) } else { this.setState({ tc: 'N' }) } }} style={{ padding: '0px', margin: '1em 0px' }}>
                            <span>
                                By continuing, I agree to the
                                <a href="https://www.ourheartvoice.com/back-on-track-terms-and-conditions" target="_blank" style={{ color: '#EB5DA6' }}>  Terms & Conditions and Privacy Policy</a>
                            </span>
                        </AgreeItem>
                        <AgreeItem data-seed="logId" onChange={e => { if (e.target.checked) { this.setState({ other: 'Y' }) } else { this.setState({ other: 'N' }) } }} style={{ padding: '0px', margin: '1em 0px' }}>
                            <span>
                                Please keep me updated on news, events, offers.
                            </span>
                        </AgreeItem>
                        <Button
                            className="fill-pink-btn"
                            activeClassName="fill-pink-active-btn"
                            onClick={this.sendOpt}
                        >
                            Next
                        </Button>
                        <WhiteSpace size="lg" />
                        <WhiteSpace size="lg" />
                    </div>
                </WingBlank>
            </div>
        );
    }
}

export default SignUpMyinfo;