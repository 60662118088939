import React, { Component } from 'react';
import Success from './Success'
import Fail from './Fail'
import GESuccess from './GESuccess'
import GEFail from './GEFail'
import HSSuccess from './HSSuccess'
import HSFail from './HSFail'
import AuthGuard from '../services/AuthGuard'
import url from 'url'
import { getCache, removeCache, setCache } from '../commons/Helpers'
import { Toast } from 'antd-mobile';

class PaymentStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            success: false,
            is_ge: false,
        }
    }

    componentWillMount() {
        this.renderRes();
    }

    loadingToast() {
        Toast.loading('Loading...', 30, () => {
            console.log('Load complete !!!');
        });
    }
    getPayBackData(resolve, reject) {
        var httpUrl = this.props.location.search;
        var token = url.parse(httpUrl, true).query.token;
        if (getCache('hs_payInfo')) {
            AuthGuard.getHsPayBackData({ ...JSON.parse(getCache('hs_payInfo')), response: token }, (isOk, res) => {
                if (isOk && res?.orderID) {
                    resolve("success");
                } else {
                    resolve("error");
                }
            })
        } else if (getCache('pro_ge_info')) {
            AuthGuard.getPayBackData({ ...JSON.parse(getCache('pro_ge_info')), response: token }, (isOk, res) => {
                if (isOk && res.transaction !== undefined && res.transaction) {
                    setCache('ge_transaction', { order_id: res.transaction.order_id })
                    resolve('success')
                } else {
                    resolve('error')
                }
            })
        } else {
            if (getCache('bill_info')) {
                AuthGuard.getPayBackData({ ...JSON.parse(getCache('bill_info')), response: token }, (isOk, res) => {
                    if (isOk && res.transaction !== undefined && res.transaction) {
                        resolve('success')
                    } else {
                        resolve('error')
                    }
                })
            } else {
                reject('error')
            }
        }
    }

    renderRes() {
        this.loadingToast();
        let self = this;
        new Promise((resolve, reject) => { this.getPayBackData(resolve, reject) }).then(data => {
            Toast.hide();
            if (getCache('pro_ge_info')) {
                let { program_id, totalAmount, is_share_personal_data, is_contacted_by_ge } = JSON.parse(getCache('pro_ge_info'));
                setCache('geInfo', { ge_id: program_id, totalAmount: totalAmount, is_share_personal_data: is_share_personal_data, is_contacted_by_ge: is_contacted_by_ge })
                self.setState({
                    is_ge: true,
                })
            }
            this.setState({
                success: data
            })
            removeCache('bill_info');
            // removeCache('pro_ge_info');
            // removeCache("hs_payInfo");

        }).catch(error => {
            Toast.hide();
            // this.setState({
            //     success: error
            // })
            this.props.history.push("/404");
            // removeCache('bill_info')
        })
    }

    render() {
        let is_hs = !!getCache("hs_payInfo")        
        const { success, is_ge } = this.state
        
        if(!success) return ''

        if (is_ge) {            
            return success === "success" ? (
                <GESuccess {...this.props} />
            ) : (
                    <GEFail {...this.props} />
                );
        } else if ( is_hs ) {
            if (success === "success") {
                return <HSSuccess {...this.props} />
            } else {
                return <HSFail {...this.props} />
            }
        } else {            
            return success === 'success' ? <Success /> : <Fail {...this.props} />
        }
    }
}

export default PaymentStatus;