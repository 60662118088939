import React, {Component} from 'react';
import {Button, Flex, Icon, NavBar, WhiteSpace, WingBlank} from 'antd-mobile';
import failed from '../images/icons/failed.png'
import {getCache} from '../commons/Helpers'

class PaymentFail extends Component {
    constructor(props){
        super(props);
        this.state = {
            backToChatBtnLoading: false,
            tryAgainBtnLoading: false
        }
    }

    componentDidMount() {
        if (this.props.location?.state?.type === "HS") {
            this.setState({
              id: this.props.location.state.id,
              type: this.props.location.state.type,
            });
        }
    }

    tryAgain = ()=>{
        console.log(getCache('pro_info'));
        if (getCache('pro_info')) {
            let { program_code } = JSON.parse(getCache('pro_info'));
            this.props.history.push({ pathname: "/checkOutPage/" + program_code });
        }else{
            if (this.state?.type === "HS") {
              this.props.history.push(
                "/DoctorScreeningDetails/" + this.state.id
              );  
            } else {
              this.props.history.push("/404");
            }            
        }
    }

    goBack = ()=>{
        if (this.state?.type === "HS") {
            this.props.history.push("/DoctorScreeningDetails/" + this.state.id);  
        }else{
            this.props.history.push("/doctorList/");
        }
    }

    render() {
        return (
            <div className="Paymet-Page result-success-fail-page">
                <NavBar
                    mode="light"
                    icon={<Icon type="left" color="#EB5DA6" size="lg"/>}
                    onLeftClick={this.goBack}
                ></NavBar>
                <Flex className="content">
                    <Flex.Item>
                        <WingBlank>
                            <WhiteSpace size="md" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <Flex direction="column">
                                <img src={failed} className="icon-payment-fail" alt="Result - Fail" />
                                <WhiteSpace size="lg" />
                                <WhiteSpace size="lg" />
                                <WhiteSpace size="lg" />
                                <WhiteSpace size="lg" />
                                <div className="result-title">Payment Failed</div>
                                <WhiteSpace size="md" />
                                <div className="result-desc">Something seems to have gone wrong.</div>
                                <div className="result-desc">Would you like to try again?</div>
                            </Flex>
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WingBlank size="lg">
                                <WingBlank size="lg">
                                    <WingBlank size="lg">
                                        <WingBlank size="lg">
                                            <WingBlank size="lg">
                                                <Button
                                                    className="fill-pink-btn"
                                                    activeClassName="fill-pink-active-btn"
                                                    loading={this.state.tryAgainBtnLoading}
                                                    onClick={this.tryAgain}
                                                >
                                                    Try Again
                                                </Button>
                                                <WhiteSpace size="lg" />
                                                <WhiteSpace size="lg" />
                                            </WingBlank>
                                        </WingBlank>
                                    </WingBlank>
                                </WingBlank>
                            </WingBlank>
                        </WingBlank>
                    </Flex.Item>
                </Flex>
            </div>
        );
    }
}

export default PaymentFail;