import React, { Component } from 'react';
import { Flex, WhiteSpace, WingBlank, InputItem, NavBar, Icon, Button, } from 'antd-mobile';
import AuthGuard from '../services/AuthGuard';
import { getCache } from '../commons/Helpers';

class DrugAllergy extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            drug_allergy: '',
            user_profile_id: '',
            loadding: false,
            error: '',
         }
    }
    goBack = () => {
        this.props.history.goBack()
    }
    confirm = () => {
        let { drug_allergy } = this.state
        if ( drug_allergy === '' ) {
            this.setState({
                error: 'Please fill out required field.',
            })
        } else {
            this.setState({
                loadding: true,
            })
            AuthGuard.addDrugAllergy({
                attribute_code: 'drug_allergy',
                value: drug_allergy,
            }, (isOk, res) => {
                this.setState({
                    loadding: false,
                })
                if (isOk) {
                    this.skip()
                } else {
                    console.log(res)
                }
            })
        }
    }

    nope = () => {
        this.setState({
            drug_allergy: '-'
        },()=>{
            this.confirm()
        })
    }

    skip = () => {
        if (getCache('redirectUrl')) {
            let url = getCache('redirectUrl')
            url = url.replace('#', '')
            this.props.history.push(url)
        } else {
            this.props.history.push("/submitResult");
        }
    }
    render() { 
        return ( 
            <div className="referral-code-title">
                <NavBar
                    mode="light"
                    icon={<Icon type="left" color="#EB5DA6" size="lg" />}
                    onLeftClick={this.goBack}
                ></NavBar>
                <div style={{ marginLeft: '1%' }}>
                    <WingBlank>
                        <div className="page-title" >Any Drug Allergy?</div>
                    </WingBlank>
                    <WhiteSpace />
                    <WhiteSpace />
                    <div className="CompleteProfile-Page">
                        <WingBlank>
                            <Flex direction="column" align="start">
                                <Flex.Item className="input-outline">
                                    <InputItem
                                        clear
                                        name='drug_allergy'
                                        disabled={this.state.loadding}
                                        placeholder="Drug Allergy(if any)"
                                        error={this.state.error}
                                        value={this.state.drug_allergy}
                                        onChange={v => this.setState({ drug_allergy: v })}
                                    ></InputItem>
                                    <span className="errorMsg" style={{ fontSize: '14px' }}>{(this.state.error) ? this.state.error : null} </span>
                                </Flex.Item>
                            </Flex>
                        </WingBlank>
                    </div>
                    <WhiteSpace />
                    <WhiteSpace />
                    <WhiteSpace />

                    <WingBlank size="lg">
                        <Button
                            className="fill-pink-btn pb-5"
                            activeClassName="fill-pink-active-btn"
                            loading={this.state.loadding}
                            disabled={this.state.loadding}
                            onClick={this.confirm.bind(this)}
                        >
                            Confirm
                        </Button>
                        <WhiteSpace />
                        <Button
                            className="outline-pink-btn pb-5"
                            activeClassName="fill-pink-active-btn"
                            loading={this.state.loadding}
                            disabled={this.state.loadding}
                            onClick={this.nope.bind(this)}
                        >
                            Nope!
                        </Button>
                    </WingBlank>
                </div>
            </div>
         );
    }
}
 
export default DrugAllergy;