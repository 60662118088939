import React, { Component } from 'react';
import { WingBlank, Flex, WhiteSpace, NavBar, Icon, Toast } from 'antd-mobile';
import AuthGuard from '../services/AuthGuard';
import { showDate, setCache } from '../commons/Helpers';



class Coupon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            billID: '',
            delivery_id: '',
            medicationPrice: '',
            user_profile_id: '',
            countryList: [],
            loading: false,
            couponError: "",
            couponSeccess: "",
            promocodeList: [],
            promo_code: '',
            refreshing: false,
            down: true,
            height: document.documentElement.clientHeight,
            deleteMedicationItemIds:''
        }

    }

    componentDidMount() {
        // console.log(this.props.location.query)
        let id = this.props.location.query !== undefined ? this.props.location.query.id : '';
        let delivery_id = this.props.location.query !== undefined ? this.props.location.query.delivery_id : '';
        let medicationPrice = this.props.location.medicationPrice !== undefined ? this.props.location.medicationPrice : '';
        // let _medication_ids=this.props.location.query.medication_ids !== undefined ? this.props.location.query.medication_ids : '';
        if (id === '') {
            this.props.history.goBack()
        } else {
            this.setState({
                billID: id,
                delivery_id: delivery_id,
                medicationPrice: medicationPrice,
                deleteMedicationItemIds:this.props.location.query.medication_ids
            }, () => {
                this.getBillInfo();

                // console.log(this.props.location.query.medication_ids,this.state.deleteMedicationItemIds)

            });
        }


    }

    loadingToast() {
        Toast.loading('Loading...', 30, () => {
            console.log('Load complete !!!');
        });
    }

    getBillInfo() {
        this.loadingToast();
        let self = this;
        AuthGuard.userCheckBill({
            userQueueId: self.state.billID
        }, (isOk, res) => {
            if (isOk) {
                // console.log(res)
                Toast.hide();
                self.setState({
                    user_profile_id: res.user_profile_id
                }, () => {
                    this.getPromoCodeList();
                })
            }

        });
    }

    bindTap(user_promo_reward_id, status) {
        if (status !== 'available') return false
        setCache('selectCouponId', user_promo_reward_id)
        this.props.history.goBack(1)
    }

    getPromoCodeList() {
        let self = this;
        this.loadingToast()
        AuthGuard.getPromoCodeList({
            user_profile_id: self.state.user_profile_id,
            delivery_id: self.state.delivery_id,
            user_queue_id: self.state.billID,
            code: self.state.promo_code,
            status: '',
            medication_ids:self.state.deleteMedicationItemIds
        }, (isOk, res) => {
            Toast.hide()
            let list = []
            if (isOk) {
                // console.log(res)
                list = res
            }
            self.setState({
                promocodeList: list
            })
            Toast.hide();
        });
    }

    applyCoupon() {
        let self = this;
        console.log(self.state.promo_code)
        if (self.state.promo_code === '') {
            this.setState({
                couponError: 'Please enter coupon code.'
            });
            document.querySelector(".errorMsg").style.display = "block"
        } else {
            AuthGuard.applyPromocode({
                user_profile_id: self.state.user_profile_id,
                promo_code: self.state.promo_code
            }, (isOk, res) => {
                console.log(res)
                if (isOk) {
                    this.setState({
                        promo_code: ''
                    }, () => {
                        self.getPromoCodeList()
                    })
                } else {
                    this.setState({
                        couponError: res.message
                    });
                    document.querySelector(".errorMsg").style.display = "block"
                }
            });
        }
    }

    inputChange(e) {
        document.querySelector(".successMsg").style.display = "none"
        document.querySelector(".errorMsg").style.display = "none"
        this.setState({
            couponError: '',
            couponSeccess: '',
            promo_code: e.target.value
        })
    }

    searchCode = (e) => {
        console.log('search')
        this.getPromoCodeList();
        e.preventDefault();
    }

    viewDetail = (id, e) => {
        e.stopPropagation();
        let data = {
            id: this.state.billID,
            delivery_id: this.state.delivery_id,
            medicationPrice: this.state.medicationPrice,
            promo_row_id: id
        }
        this.props.history.push({ pathname: "/couponDetail", query: data })
    }

    render() {
        return (
            <div className="Coupon-Page">
                <NavBar
                    mode="light"
                    icon={<Icon type="left" size="lg" color="#EB5DA6" size="lg" />}
                    onLeftClick={() => this.props.history.goBack()}
                    className="backBtn"
                ></NavBar>
                <WingBlank>
                    <WhiteSpace />
                    <form className="input-search-form" onSubmit={this.searchCode}>
                        <Flex className="search-contain">
                            <Icon name="search" type="search" size="md" color="#eb5da6" />
                            <input
                                type="search"
                                placeholder="Search Coupon Code"
                                onChange={(event) => { this.inputChange(event) }}
                                className="search-input"
                                name="couponCode"
                                autoComplete="off"
                                value={this.state.promo_code}
                            />
                            <Icon name="plus" type="plus" onClick={this.applyCoupon.bind(this)} size="md" color="#eb5da6" />
                        </Flex>
                    </form>
                    <div className="coupon-msg">
                        <WhiteSpace />
                        <span className="errorMsg">{(this.state.couponError) ? this.state.couponError : null} </span>
                        <span className="successMsg">{(this.state.couponSeccess) ? this.state.couponSeccess : null}</span>
                    </div>
                    <WhiteSpace />
                    <div className="coupon-list-contain">

                        {this.state.promocodeList.map((item) => (
                            <Flex justify="between" onClick={this.bindTap.bind(this, item.user_promo_reward_id, item.status_code)} key={item.user_promo_reward_id} className={'coupon-row ' + (item.status_code === "available" ? 'active' : '')}>
                                <div>
                                    <div className="h3 mb-1">{item.code}</div>
                                    <div className="text">Expires on: {showDate(item.expiry_date, 8)}</div>
                                    <WhiteSpace />
                                    <div className="link" onClick={this.viewDetail.bind(this, item.user_promo_reward_id)}>View Details</div>
                                </div>
                                <div className={item.status_code !== 'available' ? '' : 'hide'} style={{ width: '20%', textAlign: 'right', color: '#bbb' }}>
                                    {item.status}
                                </div>
                            </Flex>
                        ))}

                    </div>
                </WingBlank>
            </div>
        );
    }
}


export default Coupon;