import React, { Component } from 'react';
import { Modal, Toast, Button, Flex} from 'antd-mobile';
import AuthGuard from '../services/AuthGuard';
import LoginModel from '../component/LoginModel';
import OmronLogo from "../images/icons/Omron.png";
import OmronLogoM from "../images/icons/logo.png"
import { isMobile, isAndroid } from "react-device-detect";
import { getCache, removeCache, setCache, copyClipboard} from '../commons/Helpers'
import { oneDocUrl } from '../commons/Constants';


class HealthScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            name: props.match.params.name,
            bookHS:false,
            clinic_id:"",
            user_profile_id:"",
            is_invalid_package:false
        }
    }

    componentDidMount() {
      removeCache("source")
      removeCache("source_back")             
      window.location.href = oneDocUrl + 'hs/' + this.state.name + '/' + this.state.id
        // if (isMobile){          
        //   if (getCache('redirectUrl') !== this.props.location.pathname){
        //     this.proceedToHS()
        //   }else{
        //     this.initHSData()
        //   }
        // }else{
        //     this.initHSData()
        // }
    }


    initHSData(){
        console.log('initHSData')
        let _this = this
        this.loadingToast();
        AuthGuard.checkIDForHS({ id: this.state.id, name: this.state.name }, (isOk, res) => {
            Toast.hide()
            if (isOk) {
                _this.setState({
                    name: res
                })
                _this.checkLogin()
            } else {
                document.getElementById("root").style.backgroundColor = "#FFFFFF";
                _this.setState({
                  is_invalid_package: true,
                });
            }
        })
    }

    renderAlertTilte = ()=>{
      return <div><span>Welcome to</span><br/><span>the Registration Portal!</span></div>
    }

    checkLogin(){
        let _url = window.location.pathname
        if(!AuthGuard.checkLogin()){
            this.loginModel.alertLogin({
                url: _url,
                query: this.props.match.params.id,
              alert_title: 'Create a HeartVoice account to book Health Screening',
            },
              this.renderAlertTilte()
            )
        }else{
            this.bookHS()
        }
    }

    loadingToast() {
        Toast.loading('Loading...', 30, () => {
            console.log('Load complete !!!');
        });
    }

    goOfficialWebsite(){
        window.location.href = "https://www.ourheartvoice.com/"
    }

    proceedToHS = () => {
        const { id, name } = this.state        
        Modal.alert('Proceed to App', <div>If you have the HeartVoice app, you can continue on the app with your existing HeartVoice account! </div>, [
            {
                text: "Continue on Web", style: { color: '#50B2C2', fontSize: '16px' }, onPress: () => {
                    this.initHSData()
                }
            },
            {
                text: 'Go to App', style: { color: 'red', fontSize: '16px' }, onPress: () => {
                    Modal.alert('Proceed to App', <div>You can proceed to open the HeartVoice app if you have installed it on your device!</div>, [
                        {
                            text: "Download App", style: { color: '#50B2C2', fontSize: '16px' }, onPress: () => {
                                copyClipboard('heartvoice://heartvoice/home?action=joinHealthScreeningEvent&name=' + name + '&id=' + id);
                                window.location.href = 'http://onelink.to/myazfx';
                            }
                        },
                        {
                            text: 'Open App', style: { color: '#50B2C2', fontSize: '16px' }, onPress: () => new Promise((resolve, reject) => {
                                window.location.href = 'heartvoice://heartvoice/home?action=joinHealthScreeningEvent&name=' + name + '&id=' + id
                                reject('')
                            })
                        },
                    ])
                }
            },
        ])
    }

    goClinicPage(){    
      let _this = this;
      AuthGuard.getCPQuestions({}, (isOk, res)=>{
          const { clinic_id} = _this.state;
          let _pathname = `/DoctorDetails/${clinic_id}`;
          if(isOk && res){
              setCache('source', "BookNow")
              _pathname = `/CPQuestions/hs/${clinic_id}`;
          }
          this.props.history.push({ pathname: _pathname, state: { oldurl: 'HealthScreening' } });
      })
        // const { clinic_id} = this.state
        // let _pathname = `/DoctorDetails/${clinic_id}`
        // this.props.history.push({ pathname: _pathname, state: { oldurl: 'HealthScreening' } });
    }


    goHome(){        
        window.location.href = "https://www.iwell.sg";
    }

    bookHS(){
        let _this = this
        const { id,name} = this.state
        AuthGuard.joinIDForHS({ id, name}, (isOk, res) => {
            if (isOk) {
                const { clinic_id, user_profile_id } = res
                _this.setState({
                    bookHS: true,
                    clinic_id, 
                    user_profile_id
                })
            }else{
                Toast.fail('Join Fail!!!', 3);
            }
        })
    }

    onRef = (ref) => {
        this.loginModel = ref
    }

    render() { 
        const { name, is_invalid_package } = this.state;
        if (is_invalid_package){
            if (isMobile) {
                return (
                  <div className="health-screening-mobile">
                    <img className="omron-logo" src={OmronLogoM} alt="" />
                    <div className="div-h3">Sorry!</div>
                    <div className="div-text">
                      We are no longer accepting any health screening
                      appointment bookings via this link!
                    </div>
                    <br />
                    <div className="div-text">
                      Please contact{" "}
                      <a
                        href="mailto:enquiries.iwell@ourheartvoice.com"
                        className="email-link"
                      >
                        enquiries.iwell@ourheartvoice.com
                      </a>{" "}
                      for assistance or visit our home page for to book another
                      health screening appointment.
                    </div>
                    <Button
                      inline
                      size="small"
                      className="outline-pink-btn backHomeBtn"
                      onClick={() => this.goHome()}
                    >
                      Go to Home Page
                    </Button>
                  </div>
                );
            }else{
                return (
                  <div className="health-screening">
                    <img className="omron-logo" src={OmronLogo} alt=""></img>
                    <div className="div-h3">Sorry!</div>
                    <div className="div-text">
                      We are no longer accepting any health screening
                      appointment bookings via this link!
                    </div>
                    <div className="div-text">
                      Please contact{" "}
                      <a
                        href="mailto:enquiries.iwell@ourheartvoice.com"
                        className="email-link"
                      >
                        enquiries.iwell@ourheartvoice.com
                      </a>{" "}
                      for assistance or visit our home page for to book another
                      health screening appointment.
                    </div>
                    <Button
                      inline
                      className="outline-blue-btn backHomeBtn"
                      onClick={() => this.goHome()}
                    >
                      Go to Home Page
                    </Button>
                  </div>
                );
            }
            
        }else{
            return (
              <div className="Health-Screening">
                <LoginModel onRef={this.onRef} history={this.props.history} />
                <Modal
                  visible={this.state.bookHS}
                  transparent
                  maskClosable={false}
                  title={<b>Welcome!</b>}
                  className="bookHSModal"
                >
                  <div className="desc">
                    You have successfully joined the{" "}
                    <span style={{ color: "#000000" }}>{name}</span> health
                    screening event! You are now entitled to special corporate
                    prices for health screening by IWellness!
                  </div>
                  <Flex justify="around" className="footerBtns">
                    <Button
                      inline
                      size="small"
                      className="outline-pink-btn"
                      activeClassName="outline-pink-active-btn"
                      onClick={() => this.goOfficialWebsite()}
                    >
                      Close
                    </Button>
                    <Button
                      inline
                      size="small"
                      className="fill-pink-btn"
                      activeClassName="fill-pink-active-btn"
                      onClick={() => this.goClinicPage()}
                    >
                      Book Now
                    </Button>
                  </Flex>
                </Modal>
              </div>
            );  
        }
    }   
}

export default HealthScreen;