import React, { Component } from 'react';
import { Toast } from 'antd-mobile';
import AuthGuard  from '../services/AuthGuard';


class ShortLinkForDoctorDetails extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.loadingToast();        
        const {mpid,tabno} = this.props.match.params
        let _tabno = ''
        if(tabno == '3'){
            _tabno = 2
        }else if(tabno == '4'){
            _tabno = 3
        }else{
            _tabno = ''
        }

        console.log(this.props.match.params)
        let type = ''
        if(!mpid){this.props.history.push("/")}
        if(mpid.indexOf("M") == 0){
            type = 'c'
        }

        if(mpid.indexOf("CH") == 0){
            type = 'p'
        }

        if(!type) {this.props.history.push("/")}

        let params  = {}
        if(type == 'c'){
            params = {
                clinic_account: mpid
            }
        }else{
            params = {
                coach_account: mpid
            }
        }
        
        AuthGuard.getMerchantProviderUUid(params, (isOk, res) => {
            Toast.hide()
            if (isOk) {
                const {clinic_id,coash_id } = res
                let _url = _tabno ?  "/doctorDetails/" + clinic_id + '?tab=' + _tabno :  "/doctorDetails/" + clinic_id
                if(coash_id){
                    _url = "/doctorDetails/" + clinic_id + '/' + coash_id
                }
                this.props.history.push(_url);
            } else {
                this.props.history.push("/")
            }
        })
    }

    loadingToast() {
        Toast.loading('Loading...', 30, () => {
            console.log('Load complete !!!');
        });
    }

    render(){
        return (<></>)
    }
}

export default ShortLinkForDoctorDetails;