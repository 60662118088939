import React, { Component } from 'react';
import { Flex, Icon, NavBar, WhiteSpace, WingBlank } from 'antd-mobile';
import close_red from '../images/icons/close_red.png'
import { getCache, removeCache } from '../commons/Helpers';
import ScheduleCalendar from '../component/ScheduleCalendar';
import success from '../images/icons/success.png'

class ProgramsSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            program_img: '',
            program_name: '',
            coach_name: '',
            totalAmount: 0,
        }
    }

    componentDidMount() {

        if (this.props.location?.state?.type === "HS") {
            console.log("HS")
            this.setState({
                totalAmount: this.props.location.state.total,
                type: this.props.location.state.type
            });
        } else if (getCache('payNowInfo')) {
            let { total } = getCache('payNowInfo')
            this.setState({
                totalAmount: total,
                type: "HS",
                payNow: true
            });
            removeCache('payNowInfo')
        }else if (getCache('oneDocInfo')) {
            let { total } = getCache('oneDocInfo')
            this.setState({
                totalAmount: total,
                type: "HS",
                payNow: true
            });
            removeCache('oneDocInfo')
        } else {
            if (getCache('pro_info')) {
                let { program_img, program_name, coach_name } = JSON.parse(getCache('pro_info'));
                this.setState({
                    program_img: program_img,
                    program_name: program_name,
                    coach_name: coach_name,
                });
                removeCache('pro_info')

            } else {
                this.props.history.push("/404");
            }
        }
    }

    goBack = () => {
        if (this.state?.type === 'HS' && !this.state?.payNow) {
            this.props.history.goBack();
        } else {
            this.props.history.push("/doctorList");
        }
    }

    letGo = () => {
        if (navigator.userAgent.indexOf('heartvoice') !== -1) {
            console.log('webview')
            this.scModel.openJoinProgramsSuccess(this.state.coach_name);
        } else {
            window.location.href = 'http://onelink.to/myazfx';
            console.log('not webview')
        }
    }

    onSCRef = (ref) => {
        this.scModel = ref
    }

    viewDetails = () => {
        console.log("viewDetails")
    }

    render() {
        return (
            <div>
                {this.state?.type === "HS" ?

                    <div className="Paymet-Page result-success-fail-page">
                        <NavBar
                            mode="light"
                            icon={<Icon type="left" color="#EB5DA6" size="lg" />}
                            onLeftClick={this.goBack}
                        ></NavBar>
                        <Flex className="content">
                            <Flex.Item>
                                <WingBlank>
                                    <WhiteSpace size="md" />
                                    <WhiteSpace size="lg" />
                                    <WhiteSpace size="lg" />
                                    <WhiteSpace size="lg" />
                                    <WhiteSpace size="lg" />
                                    <WhiteSpace size="lg" />
                                    <WhiteSpace size="lg" />
                                    <Flex direction="column">
                                        <img src={success} className="icon-payment-fail" alt="Result - Fail" />
                                        <WhiteSpace size="lg" />
                                        <WhiteSpace size="lg" />
                                        <WhiteSpace size="lg" />
                                        <WhiteSpace size="lg" />
                                        <div className="result-title">Booking Success</div>
                                        <WhiteSpace size="md" />
                                        <div className="result-desc hs_success">
                                            Thank you for booking your appointment with the clinic! You can check your appointment status with the 1doc app.
                                        </div>
                                        {this.state.totalAmount && <div className="result-desc hs_success">Please pay ${this.state.totalAmount} at the location upon visit.</div>}
                                    </Flex>
                                    <WhiteSpace size="lg" />
                                    <WhiteSpace size="lg" />
                                    <WhiteSpace size="lg" />
                                    <WhiteSpace size="lg" />
                                    {/* <WingBlank size="lg">
                                        <WingBlank size="lg">
                                            <WingBlank size="lg">
                                                <WingBlank size="lg">
                                                    <WingBlank size="lg">
                                                        <Button
                                                            className="fill-pink-btn"
                                                            activeClassName="fill-pink-active-btn"
                                                            loading={this.state.tryAgainBtnLoading}
                                                            onClick={this.viewDetails}
                                                        >
                                                            View Details
                                                        </Button>
                                                        <WhiteSpace size="lg" />
                                                        <WhiteSpace size="lg" />
                                                    </WingBlank>
                                                </WingBlank>
                                            </WingBlank>
                                        </WingBlank>
                                    </WingBlank> */}
                                </WingBlank>
                            </Flex.Item>
                        </Flex>
                    </div>

                    : <div className="Paymet-Page background-white">
                        <ScheduleCalendar
                            onRef={this.onSCRef}
                        />
                        <div className="content programs-success">
                            <NavBar
                                mode="light"
                                icon={<Icon type="left" color="#EB5DA6" size="lg" />}
                                onLeftClick={this.goBack}
                            ></NavBar>
                            <h1 className="programs-success-title">Success!</h1>
                            <br />
                            <p className="flGrey programs-success-content">You have successfully joined a program. Start tracking your vitals on HeartVoice Mobile App now.</p>
                            <div className="main programs-list">
                                <div className="Health-program-main">
                                    <div className="Health-program" style={{ backgroundColor: 'white' }}>
                                        <WingBlank size="lg">
                                            <div className="scroll-box" style={{ display: 'flex', justifyContent: 'center' }}>
                                                <div className="scroll-main">
                                                    <div className="health-program-card" style={{ boxShadow: '0 0 5px 0.5px #c0c0c0' }}>
                                                        <div className="img-bg">
                                                            <WhiteSpace size="lg" /><WhiteSpace size="lg" />
                                                            <WingBlank size="lg"><WingBlank size="lg">
                                                                <img className="banner-nobg" src={this.state.program_img} alt="" />
                                                            </WingBlank></WingBlank>
                                                            <WhiteSpace size="lg" />
                                                        </div>
                                                        <WhiteSpace size="lg" />
                                                        <h2 style={{ textAlign: 'left', marginLeft: '1rem' }}>{this.state.program_name}</h2>
                                                        <WhiteSpace size="lg" />
                                                        <WingBlank size="lg">
                                                            <Flex>
                                                                <img className="close-red" src={close_red} alt="close_red" />
                                                                <Flex.Item>
                                                                    <h2>Chat</h2>
                                                                    <p className="flGrey">In-App messaging using</p>
                                                                    <p className="flGrey">HeartVoice Application</p>
                                                                </Flex.Item>
                                                            </Flex>
                                                        </WingBlank>
                                                        <div className="background-ccc" style={{ backgroundColor: '#f8f8f8' }}>
                                                            <WhiteSpace size="lg" />
                                                            <WingBlank size="lg">
                                                                <WingBlank size="lg">
                                                                    <h2 style={{ textAlign: 'left' }}>Whats It Includes</h2>
                                                                    <WhiteSpace size="lg" />
                                                                    <p className="flGrey">-High Blood Pressure(Hypertension), High Cholesterol(Hyperlipidemia), Blood Sugar(Diabetes) </p>
                                                                    <p className="flGrey">-Regular review of chronic disease parameters</p>
                                                                    <p className="flGrey">-Customised weight /diet plans</p>
                                                                    <p className="flGrey">-Teleconsultation/In-Person consultation</p>
                                                                    <p className="flGrey">-Medication Delivery to your door step</p>
                                                                    <p className="flGrey">-purchase of connected devices</p>
                                                                </WingBlank>
                                                            </WingBlank>
                                                            <WhiteSpace size="lg" />

                                                        </div>
                                                        <WhiteSpace size="lg" /><WhiteSpace size="lg" />
                                                        <WingBlank size="lg">
                                                            <WingBlank size="lg" >
                                                                {/* <Button className="request-img fill-pink-btn" onClick={this.letGo}>Let's Go!</Button> */}
                                                                <p style={{ width: '90%', margin: '0px auto', fontSize: '0.3rem' }}>Start tracking your vitals in HeartVoice app now!</p>
                                                            </WingBlank>
                                                        </WingBlank>
                                                        <WhiteSpace size="lg" /><WhiteSpace size="lg" />
                                                    </div>
                                                </div>
                                            </div>
                                            <WhiteSpace size="lg" /><WhiteSpace size="lg" />
                                            <WhiteSpace size="lg" /><WhiteSpace size="lg" />
                                        </WingBlank>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                }
            </div>
        );
    }
}

export default ProgramsSuccess;