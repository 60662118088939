export const AGORA_APPID = process.env.REACT_APP_AGORA_APPID;
export const WHATSAPP_MOBILENO = process.env.REACT_APP_WHATSAPP_MOBILENO;

/**
 * BASE API URL FOR EACH ENVIRONMENT
 */
export const APPENV = process.env.REACT_APP_ENV;
export const apiBaseUrl = process.env.REACT_APP_API_URL;
export const APP_VERSION = process.env.REACT_APP_VERSION; //应用程序版本
export const XAPP = process.env.REACT_APP_APPAUTH;
export const TCLink = process.env.REACT_APP_TermsCondition; //客户关系平台
export const OneDocTCLink = process.env.REACT_APP_OneDocTermsCondition; //客户关系平台
export const GAID = process.env.REACT_APP_GA_ID; //Google Analytics
export const oneDocUrl = process.env.REACT_APP_1DOC_WEB;

export const DIALING_CODE =
  new Date().toString().indexOf("中国") !== -1 ? "86" : "65";
export const GOOGLE_ANALYTICS = ["PRODUCTION", "STAGING"].includes(APPENV)
  ? true
  : false;
export const IP_WHITELIST = process.env.REACT_APP_IP_WHITELIST;
export const devDieticianMouble =
  "/doctorDetails/70e82d78-6108-4fba-8b27-d082f14be994/9a892a9e-b3bb-4860-8c5b-c2608a868472";
export const stagingDieticianMouble =
  "/doctorDetails/7168e7b6-ff55-4fc7-91a5-578faa63c923/a40016e7-92e4-46ea-9b6a-4274383d365c";
export const productionDieticianMouble =
  "/doctorDetails/5ad02353-a59a-4c9d-b19f-230ab8787a60/f883a631-f350-43cf-8274-64643ad3d254";

export const getDieticianMouble = () => {
  if (APPENV.includes("DEVELOPMENT")) {
    return devDieticianMouble;
  } else if (APPENV.includes("STAGING")) {
    return stagingDieticianMouble;
  } else {
    return productionDieticianMouble;
  }
};

// const showFeePaySuccess = [8014, 8236];

export const RespondCode = {
  userCheckBillSuccess: 8052,
  verifyUserSuccess: 10045,
  creatBillSuccess: 8236,
  payCheckoutSuccess: 8014,
  signUpSendOtpSuccess: 3004,
  signUpVerifyOtpSuccess: 3718,
  loginSendOtpSuccess: 3004,
  loginVerifyOtpSuccess: 3718,
  nationalityListSuccess: 3057,
  countryListSuccess: 1002,
  provinceListSuccess: 1032,
  cityListSuccess: 1042,
  completeProfileSuccess: 3025,
  signupNotieSuccess: 1009,
  addAddressSuccess: 10140,
  noFeePaySuccess: 8014,
  getspecialtySuccess: 10005,
  doctorDetailsSuccess: 10005,
  coachSearchSuccess: 10005,
  getspecialtyListSuccess: 10005,
  searchMyDoctorSuccess: 10005,
  getProgramListSuccess: 8207,
  deliveryOptionSuccess: 10221,
  serchLocalDoctorSuccess: 10005,
  applyPromocodeSuccess: 16003,
  getPromoCodeListSuccess: 16009,
  getPromoCodeDetailSuccess: 16009,
  getSingPassUrlSuccess: 17000,
  getSingPassInfoSuccess: 17001,
  singPassLoginInSuccess: 17001,
  getServiceCountrySuccess: 10005,
  getSGRegionSuccess: 1111,
  getProvinceListSuccess: 1032,
  getCityListSuccess: 1042,
  savedrSuccess1: 15007,
  savedrSuccess2: 15008,
  getAvaliableDateSuccess: 15004,
  appointmentScheduleSuccess: 15002,
  validateTokenSuccess: 6733,
  getMyinfoSuccess: 3024,
  sendOtp4updateSuccess: 3004,
  checkOtp4updteSuccess: 6620,
  sendOtp4RegSuccess: 3004,
  checkOtp4RegSuccess: 3012,
  getGeoAddSuccess: 10000,
  mobileInqueueSuccess: 15002,
  getCouponPriceSuccess: 8251,
  checkRegAddSuccess: 10000,
  queueAssignSuccess: 15002,
  updateHeightSuccess: 6629,
  addReferralSuccess: 16003,
  requestCodeSuccess: 8243,
  joinProgramsMorethanSuccess: 8207,
  joinProgramsEqualSuccess: 8260,
  getProgramsCouponPriceSuccess: 8251,
  creatProSuccess: 8236,
  creatProSuccessV2: 8260,
  proPayCheckoutSuccess: 8014,
  acceptProgramsRequestSuccess: 8245,
  getProgramsInfoSuccess: 8002,
  acceptProgramsInfoSuccess: 8247,
  // checkProfileCompleteSuccess: 6041,
  checkProfileCompleteSuccess: 3024,
  getIdentificationTypeListSuccess: 6600,
  addDrugAllergySuccess: 3063,
  marketPlaceDetailInfoSuccess: 8302,
  packagesFindDetailSuccess: 8302,
  calculateForTelemedSuccess: 8251,
  creatGESuccess: 8236,
  hasBoughtSuccess: 8302,
  uploadTwilioLogSuccess: 16015,
  packagesCheckDetailSuccess: 8306,
  SignUpSendOtpSuccess: 3004,
  SignUpOtpSubmitSuccess: 3012,
  SignUpCompleteInfoSuccess: 11005,
  SignUpMyinfoOtpSubmitSuccess: 3012,
  loginComOtpSubmitSuccess: 3004,
  loginComSubmitSuccess: 3025,
  notAddressSuccess: 10005,
  checkIsExistSuccess: 6733,
  getHSListSuccess: 11007,
  searchAddOnsSuccess: 11007,
  searchLocationSuccess: 11007,
  requestForHealthScreeningSuccess: 8236,
  checkoutScreeningSuccess: 8236,
  getHSDateSuccess: 15004,
  checkIDForHSSuccess: 11007,
  checkIDForCTSuccess: 22007,
  joinIDForHSSuccess: 11007,
  findBillForHealthScreening: 8331,
  getPackageInfoSuccess: 11007,
  payHSSuccess: 8014,
  checkAbleBookSuccess: 19996,
  getProfileInfoSuccess: 3024,
  getRaceAndRaceStatusListSuccess: 3057,
  telDoctorListSuccess: 10005,
  getMerchantProviderUUidSuccess: 10005,
  getCPQuestionsSuccess: 11007,
  saveCPQuestionsSuccess: 11007,
  lastPendingSuccess: 8268,
  oauthAuthorizeSuccess: 17110,
  getDeclarationFormDetailSuccess: 10030,
  getMyReportSuccess: 10030,
  getHealthScreenListSuccess: 11007,
  getHSBookedEvnetSuccess: 11007,
  getScanInfoSuccess: 11007,
  ScanUploadSuccess: 10010,
};

export const ShowErrorMsgCode = [
  8056, //Invalid transaction.
  3017, //Create Login Fail.
  8050, //Failed to add transaction
  6622,
  3032,
  6004,
  3515,
  3011,
  3023,
  6614,
  6621,
  11017, // complete profile fail
  400,
  11108,
  8252, //join program fail
  8257, //Already joined program
  10044, //book appointment issue message
  //16004,// Invalid Referral code
  8308, //buyed GE create order
  15003,
  11008,
  23005,
];

export const WeekEveryDay = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];

//星期日期转换
export const dayFile = (day) => {
  switch (day) {
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    case 7:
      return "Sunday";
    default:
      return null;
  }
};

export const ApiUrl = {
  userCheckBill: "merchant_service/transaction/system/findBill",
  userCheckBillV2: "merchant_service/v6/transaction/findBill",
  verifyUser: "health_service/video/verify/user",
  creatBill: "merchant_service/campaign/coach/request",
  payCheckout: "merchant_service/campaign/coach/checkout",
  payCheckoutV2: "merchant_service/v6/user/campaign/checkoutForTelemedicine",
  signUpSendOtp: "account_service/user/register/sendEmailOtp",
  signUpverifyOtp: "account_service/user/signup/verifyOtp",
  loginSendotp: "account_service/v2/otp/sendOtp",
  loginVerifyOtp: "account_service/user/login/verifyOtp",
  nationalityList: "account_service/attribute/value/get",
  countryList: "country_service/country/list",
  provinceList: "country_service/country/province/get",
  // cityList:'country_service/province/city/getbyprovincecode',
  // completeProfile: 'account_service/user/account/save',
  completeProfile: "account_service/user/account/complete",
  signupNotie: "promote_service/signup/success",
  addAddress: "delivery_service/system/recipient/address/handle",
  addAddressV2: "delivery_service/user/recipient/address/handle",
  noFeePay: "merchant_service/transaction/system/request_v2",
  noFeePayV2: "merchant_service/v6/transaction/requestForTelemedicine",
  getSpecialty: "health_service/coach/getspecialty/list",
  doctorDetails: "health_service/coach/search/clinic",
  coachSearch: "health_service/coach/search",
  getspecialtyList: "health_service/coach/getspecialty/list",
  searchMyDoctor: "health_service/coach/search/mydoctor",
  getprogramList: "merchant_service/system/program/findForClinic",
  getProgramTokenList: "merchant_service/program/findProgramForClinic",
  deliveryOption: "delivery_service/system/deliveryservice/list",
  applyPromocode: "account_service/system/apply/promocode",
  applyPromocodeV2: "account_service/user/apply/promocode",
  getPromoCodeList: "account_service/system/my/promocode",
  getPromoCodeDetail: "account_service/system/my/promocode/detail",
  getPromoCodeDetailV3: "account_service/user/my/promocode/detailForTele",
  getSingPassUrl: "account_service/singpass/build/request",
  getSingPassInfo: "account_service/singpass/data/retrieveAttrs",
  singPassLoginIn: "account_service/test/singpass/data/retireve",
  savedr: "health_service/coach/user/save",
  getServiceCountry: "health_service/coach/getall/servicecountry",
  getSGRegion: "account_service/merchant/dropdown",
  getProvinceList: "country_service/country/province/get",
  cityList: "country_service/city/getbyprovincecode",
  serchLocalDoctor: "health_service/coach/search/local",
  // getAvaliableDate: 'health_service/mobile/appointment/avaliable/date', 弃用
  getAvaliableDate: "health_service/app/calendar/date",
  appointmentSchedule: "health_service/mobile/appointment/schedule",
  validateToken: "account_service/system/validate/token",
  // getMyinfo: 'account_service/user/self/profile/get',
  getMyinfo: "account_service/v3/user/profileApp",
  sendOtp4update: "account_service/v2/otp/sendOtp/verifyMobileForUpdate",
  checkOtp4updte: "account_service/user/update/verifyOtp",
  sendOtp4Reg: "account_service/v2/otp/sendSmsOtpForRegisteredWithEmail",
  // checkOtp4Reg: 'account_service/user/account/createUserWithInfo',
  checkOtp4Reg: "account_service/user/account/register",
  getGeoAdd: "country_service/geo/location/find",
  mobileInqueue: "health_service/mobile/inqueue",
  getCouponPrice: "merchant_service/campaign/getCouponPrice",
  checkRegAdd: "delivery_service/geo/location/get",
  queueAssign: "health_service/mobile/queue/assign",
  queueOneAssign: "health_service/mobile/inqueue",
  updateHeight: "account_service/v2/user/updateHeightById",
  addReferral: "account_service/system/apply/promocode/signup",
  requestCode: "merchant_service/programrequestcode/requestForCode",
  joinPrograms: "merchant_service/program/joinProgram",
  joinProgramsV2: "merchant_service/program/bill",
  getPromoCodeListV2: "account_service/user/my/promocode",
  getPromoCodeDetailV2: "account_service/user/my/promocode/detail",
  getProgramsCouponPrice: "merchant_service/campaign/getCouponPriceForProgram",
  creatPro: "merchant_service/transaction/createForJoinProgram",
  creatProV2: "merchant_service/v2/transaction/createForJoinProgram",
  proCheckout: "merchant_service/transaction/checkoutForJoinProgram",
  acceptProgramsRequest:
    "merchant_service/system/programrequestcode/responseProgram",
  getProgramsInfo: "merchant_service/partner/product/findByProgramId",
  acceptProgramsinfo:
    "merchant_service/system/programrequestcode/findDetailByHashParam",
  // checkProfileComplete:'account_service/v2/user/profile/isComplete',
  checkProfileComplete: "account_service/user/account/isCompleteForTeleProfile",
  marketPlaceDetailInfo: "merchant_service/system/packages/findDetail",
  packagesFindDetail: "merchant_service/system/packages/findDetail",
  calculateForTelemed:
    "merchant_service/transaction/system/calculateForTelemed",
  calculateForTelemedV2: "merchant_service/v6/transaction/calculateForTelemed",
  creatGE: "merchant_service/v2/transaction/requestForPackages",
  hasBought: "merchant_service/packages/isCanPurchase",
  getIdentificationTypeList: "account_service/systemcode/findSystemCode",
  uploadTwilioLog: "health_service/system/videolog/add",
  addDrugAllergy: "account_service/v2/user/setUserAttribute",
  packagesCheckDetail: "merchant_service/v2/transaction/findBillForPackages",
  SignUpSendOtp: "account_service/v3/otp/sendSmsOtpForRegisteredWithEmail",
  SignUpOtpSubmit: "account_service/user/account/createUser",
  // SignUpCompleteInfo: 'account_service/user/account/fillPartInfo',
  SignUpCompleteInfo: "account_service/user/account/fillPartInfoV2",
  // SignUpMyinfoOtpSubmit: 'account_service/v3/user/createUserWithMyInfo',
  SignUpMyinfoOtpSubmit: "account_service/v4/user/createUserWithMyInfo",
  loginComOtpSubmit: "account_service/v2/otp/sendOtpForFillInfo",
  // loginComSubmit: 'account_service/v2/user/fillProfile',
  loginComSubmit: "account_service/v2/user/fillProfileV2",
  notAddress: "health_service/coach/search/notAddress",
  checkIsExist: "account_service/v3/user/checkOnly",
  hsList: "health_service/v6/corporate/screening/findByClinicId",
  searchAddOns: "health_service/v6/screening/addon/search",
  searchLocation: "health_service/v6/screening/location/search/v2",
  requestForHealthScreening:
    "merchant_service/v6/transaction/requestForHealthScreening",
  checkoutScreening:
    "merchant_service/v6/transaction/OCBC/response/health/screening",
  getHSDate: "health_service/app/calendar/date",
  checkIDForHS: "health_service/v6/check/corporate/health/screening",
  checkIDForCT: "health_service/userjoineventrecord/webJoinTeleconsultEvent",
  joinIDForHS: "health_service/v6/join/corporate/health/screening",
  getPackageInfo: "health_service/v6/screening/find/detail",
  findBillForHealthScreening:
    "merchant_service/v6/transaction/findBillForHealthScreening",
  getHsPayBackData:
    "merchant_service/v6/transaction/checkoutForHealthScreening",
  checkAbleBook: "health_service/app/check/able/book",
  getProfileInfo: "account_service/v3/user/profileApp",
  getRaceAndRaceStatusList: "account_service/attribute/value/get",
  telDoctorList: "health_service/teleconsult/event/coach/list",
  getMerchantProviderUUid: "health_service/coach/getIdByAccountID",
  getCPQuestions: "health_service/v6/book/user/question",
  saveCPQuestions: "health_service/v6/book/save/user/question",
  getTransactionStatus: "merchant_service/user/check/payment/status",
  getLastPending: "merchant_service/orders/payment/lastPending",
  oauthAuthorize: "account_service/user/oauth/authorize",
  getDeclarationFormDetail: "health_service/userquestion/findInfoByApptId",
  getMyReport: "health_service/user/health/report",
  getHealthScreenList: "health_service/v6/book/screening/search",
  getHSBookedEvnet: "health_service/v6/book/screening",
  getScanInfo: "health_service/document/qr/request/scan",
  scanUpload: "health_service/document/qr/request/upload",
  checkVaildliUrl: "account_service/url/verify",
};
