// 1 注册
// 1.1 无singpass
// 正常填写信息 ,点击confirm , 验证手机号 ， 成功
// 1.2 有singpass 
// 先使用singpass 数据 填充信息 ,点击confirm , 验证手机号 ， 成功
// 2. 更新
// 2.1 无singpass信息
// 正常填写信息 ,点击confirm , 验证手机号 ， 成功
// 2.2 无singpass信息 但是更新的时候开始使用
// 先使用singpass 数据 替换原来的信息 ,点击confirm , 验证手机号 ， 成功
// 2.3 有singpass信息
// 正常填写信息, 但是singpass的 （ dialing code , moibile no, email , fist name , last name除外）信息不可以改， 点击confirm , 验证手机号 ， 成功

import React, { Component } from 'react';
import { LocaleProvider, Flex, WhiteSpace, Picker, WingBlank, InputItem, TextareaItem, Toast, Button, Modal, Checkbox } from 'antd-mobile';
import DatePicker from '../component/DatePicker'
import enUs from 'antd-mobile/lib/date-picker/locale/en_US';
import AuthGuard from '../services/AuthGuard';
import { addKeyValueForDorpList, getCache, removeCache, isPostalCode, isEmail, setCache, isInvalid } from '../commons/Helpers';
import moment from 'moment'
import CustomChildren from '../commons/CustomChildren';
import { TCLink, DIALING_CODE } from '../commons/Constants';
import myInfo from '../images/icons/myinfo.jpeg';
import GoogleSearchInput from '../component/GoogleSearchInput';
const AgreeItem = Checkbox.AgreeItem;

function formatDate(date) {
    /* eslint no-confusing-arrow: 0 */
    const dateString = date.toDateString().split(" ")
    var d = dateString[2]
    var m = dateString[1]
    var y = dateString[3]
    // const dateStr = `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()}`;
    const dateStr = `${d}/${m}/${y}`;
    // const timeStr = `${pad(date.getHours())}:${pad(date.getMinutes())}`;
    return `${dateStr}`;
}

class CompleteProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            genderList: [
                {
                    label: 'Male',
                    value: 'male',
                },
                {
                    label: 'Female',
                    value: 'female',
                },
            ],
            maritalStatus: [
                {
                    label: 'Single',
                    value: 'Single',
                },
                {
                    label: 'Married',
                    value: 'Married',
                },
                {
                    label: 'Widowed',
                    value: 'Widowed',
                },
                {
                    label: 'Divorced',
                    value: 'Divorced',
                }
            ],
            nationalityList: [],
            countryList: [],
            dialingCodeList: [],
            countryListArr: [],

            email: '',
            dialingCode: DIALING_CODE,
            mobile: '',
            old_dialingCode: '',
            old_mobile: '',
            firstName: '',
            lastName: '',
            nric: '',
            birth: new Date(1970, 0, 1),
            sValue: '',
            gender: '',
            attribute: 'nationality',
            nationality: 'Singaporean',
            country_code: 'SG',
            province_code: '',
            city_code: '',
            marital_status: '',
            drug_allergy: '',
            patient_id: '',
            fromPage: '',
            agreeBtn: false,
            TcModal: false,
            isDatePickerOpen: false,
            confirmLoadding: false,
            show: false,
            disabled: false,
            singPassCode: false,
            gmapsLoaded: false,
            errorMsg: {},
            completeSuccess: true,
            myInfoId: '',
            singpassModal: false,

        }
        this.patientInfo = getCache('patientInfo');

    }

    initMap = () => {
        this.setState({
            gmapsLoaded: true,
        })
    }

    componentDidMount() {

        window.initMap = this.initMap
        const gmapScriptEl = document.createElement(`script`)
        gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=` + process.env.REACT_APP_GOOGLE_API_KEY + `&libraries=places&callback=initMap`
        document.querySelector(`body`).insertAdjacentElement(`beforeend`, gmapScriptEl)

        this.getNationalityList();
        this.getCountryList();
        this.checkUserInfo()
    }


    loadingToast() {
        Toast.loading('Loading...', 30, () => {
            console.log('Load complete !!!');
        })
    }

    getNationalityList = () => {
        AuthGuard.getNationalityList({
            attribute: this.state.attribute
        }, (isOk, res) => {
            if (isOk) {
                let { list: nationality } = res
                this.setState({
                    nationalityList: addKeyValueForDorpList(nationality.SG)
                })
            }
        })
    }

    getCountryList = () => {
        this.loadingToast();
        AuthGuard.getCountry({},(isOk) =>{
            if (isOk) {
                this.setState({
                    dialingCodeList: addKeyValueForDorpList(AuthGuard.listCountrys, 'dialing_code'),
                    countryList: addKeyValueForDorpList(AuthGuard.listCountrys, 'country_list'),
                    countryListArr: AuthGuard.listCountrys
                }, () => {
                    if (this.state.country_code !== '') {
                        if (this.state.country_code === 'SG') {
                            this.setState({
                                province_code: '',
                                city_code: ''
                            }, () => {
                                this.getProvinceList()
                            })
                        } else {
                            console.log(this.state.country_code)
                            this.getProvinceList()
                        }

                    }
                    // if (this.state.countryCode != '' && this.state.countryCode != 'SG') {
                    //     this.getProvinceList()
                    // }
                })
            }
            Toast.hide();
        });
    }

    getProvinceList = () => {
        if (!this.state.country_code) return
        AuthGuard.getProvinceList({
            code: this.state.country_code
        }
            , (isOk, res) => {
                if (isOk) {
                    let { province_code } = this.state
                    this.setState({
                        provinceList: addKeyValueForDorpList(res[0].province_list, 'country_list'),
                        province_code: province_code ? province_code : res && res.length > 0 && res[0].province_list && res[0].province_list.length > 0 ? res[0].province_list[0].code : ''
                    }, () => {
                        if (this.state.province_code !== '') {
                            if (this.state.country_code === 'SG') {
                                this.setState({
                                    province_code: '',
                                    city_code: ''
                                }, () => {
                                    this.getCityList();
                                })
                            } else {
                                this.getCityList();
                            }

                        }
                    })
                }
            });
    }

    getCityList = () => {
        if (!this.state.province_code) return
        AuthGuard.getCityList({
            province_code: this.state.province_code
        }
            , (isOk, res) => {
                if (isOk) {
                    // console.log(res)
                    let { city_code } = this.state
                    this.setState({
                        cityList: addKeyValueForDorpList(res, 'country_list'),
                        city_code: city_code ? city_code : res && res.length > 0 ? res[0].code : ''
                    })
                }
            });
    }


    getCountryName(code) {
        let name = ''
        if (code) {
            this.state.countryList.map((item) => {
                if (item.value === code) {
                    name = item.label
                }
                return item
            })
        }
        return name

    }
    getProvinceName(code) {
        if (this.state.country_code === 'SG') return ''
        let name = ''
        if (code) {
            this.state.provinceList.map((item) => {
                if (item.value === code) {
                    name = item.label
                }
                return item
            })
        }

        return name

    }
    getCityName(code) {
        if (this.state.country_code === 'SG') return ''
        let name = ''
        if (code) {
            this.state.cityList.map((item) => {
                if (item.value === code) {
                    name = item.label
                }
                return item
            })
        }

        return name
    }

    onTCClose() {
        this.setState({
            TcModal: false
        })
    }

    onSingpassClose() {
        this.setState({
            singpassModal: false,
            singPassCode: ''
        })
    }

    confirm = async (e) => {
        e.preventDefault();
        let self = this;
        let noError = true;

        let params = {
            myinfoId: this.state.myInfoId,
            myInfoId: this.state.myInfoId,
            email: this.state.email,
            mobile: this.state.mobile,
            dialingCode: this.state.dialingCode,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            nric: this.state.nric,
            birth: moment(this.state.birth).format('YYYY-MM-DD'),
            gender: this.state.gender,
            nationality: this.state.nationality,
            marital_status: this.state.marital_status,
            drug_allergy: this.state.drug_allergy,
            postal_code: this.state.postal_code,
            country_code: this.state.country_code,
            city_code: this.state.country_code === 'SG' ? '' : this.state.city_code,
            province_code: this.state.country_code === 'SG' ? '' : this.state.province_code,
            country: this.state.country_code,
            province: this.state.country_code === 'SG' ? '' : this.state.province_code,
            city: this.state.country_code === 'SG' ? '' : this.state.city_code,
            address: this.state.address,
            unit_no: this.state.unit_no,
            country_name: this.getCountryName(this.state.country_code),
            province_name: this.getProvinceName(this.state.province_code),
            city_name: this.getCityName(this.state.city_code)
        }

        let checkRequest = {
            data: params,
            dataName: {
                email: 'Email',
                mobile: 'Mobile No.',
                dialingCode: 'Dialing Code',
                firstName: 'First Name',
                lastName: 'Last Name',
                nric: 'NRIC',
                birth: 'Birthday',
                gender: 'Gender',
                nationality: 'Nationality',
                postal_code: 'Postal ',

                province_code: 'Province ',
                country_code: 'Country ',
                city_code: 'City ',
                address: 'Address',
            }
        }

        if (!self.getFieldError(checkRequest)) {
            noError = false;
        }

        if (this.state.patient_id) {
            params.id = this.state.patient_id
        }

        if (noError) {
            this.setState({
                errorMsg: {}
            })
        }

        let data = null
        if (params.country_code === 'SG') {
            let { province_code, province_name, city_code, city_name, ...$params } = params
            data = $params
        } else {
            data = params
        }

        if (noError) {
            let res = false
            try {
                res = await this.checkAddress()
            } catch (error) {
                res = false
            }

            if (!res) {
                return false
            }

            if (!this.state.agreeBtn) {
                this.setState({
                    TcModal: true
                })

                return false
            }
            this.setState({
                confirmLoadding: true
            })

            let cacheObj = {
                fromPage: 'Register',
                type: 'SMS',
                dialingCode: this.state.dialingCode,
                username: this.state.mobile,
            }
            removeCache('verifyOptFor');
            removeCache('state')
            setCache('verifyOptFor', cacheObj)
            setCache('patientInfo', data)
            // 更新信息
            if (this.state.patient_id) {
                if (this.state.dialingCode !== this.state.old_dialingCode || this.state.mobile !== this.state.old_mobile) {
                    AuthGuard.sendOtp4update({
                        dialingCode: this.state.dialingCode,
                        mobile: this.state.mobile
                    }, (isOk, res) => {
                        if (isOk) {
                            this.props.history.push("/verifyOTP");
                        } else {
                            Modal.alert('success', res.message, [{ text: 'OK', onPress: () => console.log('ok') }]);
                        }
                        this.setState({
                            confirmLoadding: false
                        })
                    })
                } else {
                    AuthGuard.completeProfile(data, (isOk, res) => {
                        if (isOk) {
                            AuthGuard.signupNotie({
                                dialing_code: self.state.dialingCode,
                                mobile_number: self.state.mobile,
                            }, () => {

                            })
                            removeCache('verifyOptFor')
                            removeCache('patientInfo')
                            if (getCache('redirectUrl')) {
                                let url = getCache('redirectUrl')
                                url = url.replace('#', '')
                                self.props.history.push(url)
                            } else {
                                self.props.history.push("/submitResult");
                            }
                            
                        } else {
                            Modal.alert('success', res, [{ text: 'OK', onPress: () => console.log('ok') }]);
                        }

                        this.setState({
                            confirmLoadding: false
                        })
                    })
                }
            } else {
                // 注册
                this.loadingToast()
                AuthGuard.sendOtp4Reg({
                    dialingCode: this.state.dialingCode,
                    mobile: this.state.mobile,
                    email: this.state.email,
                }, (isok) => {
                    if (isok) {
                        this.props.history.push("/verifyOTP");
                    } else {
                        // Modal.alert('success', res.message, [{ text: 'OK', onPress: () => console.log('ok') }]);
                    }
                    Toast.hide()
                })

                this.setState({
                    confirmLoadding: false
                })

            }

        } else {
            Toast.fail('Complete necessary information !!!', 2);
        }
    }

    checkAddress = () => {
        return new Promise((resolve, reject) => {
            AuthGuard.checkRegAdd({
                param: this.state.address
            }, (isOk, res) => {
                if (isOk) {
                    resolve(res)
                } else {
                    Modal.alert('Notice', res.message, [{ text: 'OK', onPress: () => console.log('ok') }]);
                    reject(false)
                }
            })
        })
    }

    onChange = () => {
        console.log('onChange');
    }

    onAgrrTerm = (e) => {
        this.setState({
            agreeBtn: e.target.checked
        })
    }

    stopScroll(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    openDatePicker = (e) => {
        e.stopPropagation();
        if (this.state._birth) return false
        this.setState({
            isDatePickerOpen: true
        })

        document.body.addEventListener("touchmove", this.stopScroll, { passive: false });
        document.body.style.overflow = 'hidden';
    }

    handleSelect = (e) => {
        this.setState({
            birth: e,
            isDatePickerOpen: false
        })

        document.body.removeEventListener('touchmove', this.stopScroll);
        //添加事件监听时添加了passive参数，在ios9中移除事件监听也需要加此参数
        document.body.removeEventListener('touchmove', this.stopScroll, { passive: true });
        document.body.style.overflow = 'auto';
    }

    handleCancel = () => {
        this.setState({
            isDatePickerOpen: false
        })

        document.body.removeEventListener('touchmove', this.stopScroll);
        //添加事件监听时添加了passive参数，在ios9中移除事件监听也需要加此参数
        document.body.removeEventListener('touchmove', this.stopScroll, { passive: true });
        document.body.style.overflow = 'auto';
    }


    getFieldError = (datas) => {

        let data = datas.data;
        let requiredArr = [
            "email",
            "mobile",
            "dialingCode",
            "firstName",
            "lastName",
            "nric",
            "birth",
            "gender",
            "nationality",
            "country_code",
            "postal_code",
            "address",
        ];
        if (data.country_code !== '' && data.country_code !== null && data.country_code !== 'SG') {
            requiredArr.push('province_code', 'city_code');
        }
        let err = true;
        let msg = {};

        Object.keys(data).forEach((k) => {
            if (requiredArr.includes(k) && err) {
                // console.log(data, name[k])
                if (!data[k]) {
                    err = false;
                    // msg[k] = name[k] + ' is required!';
                    msg[k] = 'Please fill out required field';//name[k] + ' is required!';
                }
                if (err && k === 'email'){
                    if (!isEmail(data[k])){
                        err = false;
                        msg[k] = 'Invalid Email Format!';
                    }
                }
                if (err && k === 'postal_code') {
                    let res = this.state.countryListArr.find((item) => {
                        return item.code === this.state.country_code;
                    })
                    if (res) {
                        let resP = !isInvalid(res.postal_code_format) ? res.postal_code_format : '';
                        if (!isPostalCode(data[k], this.state.country_code, resP)) {
                            err = false;
                            msg[k] = 'Invalid Postal Code Format!';
                        }
                    }

                }
            }
        })
        if (!err) {
            this.setState({
                errorMsg: msg
            });
        }
        return err;

    }

    getAddress = (val) => {
        let _address = ''
        if (val) {
            // let addressArr = val.split(', ')
            // if (addressArr.length > 1 && addressArr.length === 2){
            //     _address = addressArr[0]
            // } else if (addressArr.length > 2){
            //     _address = addressArr[0] + ', ' + addressArr[1]
            // }else{
            //     _address = val
            // }
            AuthGuard.getGeoAdd({
                address: val
            }, (isOk, res) => {
                // console.log(isOk, res)
                if (isOk) {
                    _address = res.formatted_address
                    this.setState({
                        ...res,
                        address: res.formatted_address,
                        isDisplay: res.country_code !== 'SG'
                    }, () => {
                        this.getCountryList()
                    })
                }
            })
        }
        this.setState({
            address: _address
        })
    }

    UpperString(str) {
        if (!str) return ''
        str = str.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1)
    }

    getSingPassUrl = () => {
        this.loadingToast();
        if (!this.state.singPassCode) {
            AuthGuard.getSingPassUrl({}, (isOk, res) => {
                if (isOk) {
                    window.location.href = res.url;
                }
                Toast.hide();
            })
        }
    }

    checkUserInfo = async () => {
        let { token } = this.props.match.params;
        if (token) {
            try {
                let res = await this.exchangeToken()
                this.setUserInfoData(res)
            } catch (error) {
                console.log(error)
            }
        }

        if (AuthGuard.checkLogin()) {
            if (this.state.patient_id) {

            } else {

                try {
                    let res = await this.getUserProfileData()
                    this.setUserInfoData(res)
                } catch (error) {
                }
            }
        }

        let singPassCode = getCache('state');
        if (!isInvalid(singPassCode) && singPassCode !== null && singPassCode) {
            this.setState({
                singPassCode: singPassCode
            })
            this.getSingPassInfo();
        } else {
            this.setState({
                singPassCode: ''
            })
        }
    }

    getSingPassInfo = () => {
        let singPassCode = getCache('state');
        // this.loadingToast()
        AuthGuard.getSingPassInfo({
            state: singPassCode
        }, (isOk, res) => {
            // let res = {
            //     myInfoId: "c14094a1-7ebb-4e4c-ac35-a2bf14e04c6d",
            //     email: "test@iapps.com",
            //     dialingCode: "65",
            //     mobile: "134123441234",
            //     fullName: "ANDY  LAU",
            //     nric: "S6005048A",
            //     birth: "1988-10-06",
            //     gender: "F",
            //     nationality: "SINGAPORE CITIZEN",
            //     marital_status: "MARRIED",
            //     postal_code: "542319",
            //     country_code: "SG",
            //     country_name: "SINGAPORE",
            //     address: "319 ANCHORVALE DRIVE",
            //     unit_no: "38-10"
            // }
            let fullName = !isInvalid(res.fullName) ? res.fullName.split(' ') : ''
            if (isOk) {
                this.setState({
                    email: res.email,
                    myInfoId: res.myInfoId,
                    firstName: fullName ? fullName[0] : '',
                    lastName: fullName ? fullName[fullName.length - 1] : '',
                    fullName: res.fullName,
                    dialingCode: res.dialingCode,
                    mobile: res.mobile,
                    nric: res.nric,
                    postal_code: res.postal_code,
                    _postal_code: res.postal_code,
                    country_code: res.country_code ? res.country_code : '',
                    _country_code: res.country_code,
                    unit_no: res.unit_no,
                    _unit_no: res.unit_no,
                    address: res.address,
                    _address: res.address,
                    _nric: res.nric ? true : false,
                    birth: new Date(res.birth) || new Date(1970, 0, 1),
                    _birth: res.birth ? true : false,
                    gender: res.gender ? (res.gender === 'M' ? 'male' : 'female') : '',
                    _gender: res.gender ? true : false,
                    nationality: res.nationality ? (res.nationality === 'SINGAPORE CITIZEN' ? 'Singaporean' : res.nationality) : '',
                    _nationality: res.nationality ? true : false,
                    marital_status: this.UpperString(res.marital_status),
                    _marital_status: res.marital_status ? true : false,
                })
            } else {
                this.setState({
                    singpassModal: true
                })
                removeCache('state')
            }
            // Toast.hide()
        })
        // Toast.hide()
    }

    getUserProfileData = () => {
        return new Promise((resolve, reject) => {
            AuthGuard.getMyinfo({}, (isOk, res) => {
                if (isOk) {
                    resolve(res)
                } else {
                    reject(false)
                }
            })
        })
    }

    exchangeToken = () => {
        let { token } = this.props.match.params;
        return new Promise((resolve, reject) => {
            AuthGuard.exchangeToken({ token: token }, (isOk, res) => {
                if (isOk) {
                    resolve(res)
                } else {
                    reject(false)
                }
            })
        })
    }

    setUserInfoData = (res) => {
        let isSingPassUser = !isInvalid(res.singpassInfo)

        this.setState({
            myInfoId: res.myInfoId || '',
            email: res.email || '',
            dialingCode: res.dialing_code || DIALING_CODE,
            mobile: res.mobile_number || '',
            old_dialingCode: res.dialing_code || DIALING_CODE,
            old_mobile: res.mobile_number || '',
            firstName: res.first_name || '',
            lastName: res.last_name || '',
            nric: res.nric || '',
            birth: new Date(res.birth) || new Date(1970, 0, 1),
            gender: res.gender || '',
            nationality: res.nationality || '',
            country_code: !isInvalid(res.host_address.country_code) ? res.host_address.country_code : 'SG',
            _country_code: isSingPassUser && res.country_code ? true : false,
            province_code: !isInvalid(res.host_address.province_code) ? res.host_address.province_code : '',
            city_code: !isInvalid(res.host_address.city_code) ? res.host_address.city_code : '',
            address: !isInvalid(res.host_address.address) ? res.host_address.address : '',
            _address: isSingPassUser && res.address ? true : false,
            postal_code: !isInvalid(res.host_address.postal_code) ? res.host_address.postal_code : '',
            _postal_code: isSingPassUser && res.postal_code ? true : false,
            unit_no: !isInvalid(res.host_address.unit_no) ? res.host_address.unit_no : '',
            marital_status: res.marital_status || '',
            drug_allergy: res.drug_allergy || '',
            patient_id: res.id || '',
            _nric: isSingPassUser && res.nric ? true : false,
            _birth: isSingPassUser && res.birth ? true : false,
            _gender: isSingPassUser && res.gender ? true : false,
            _nationality: isSingPassUser && res.nationality ? true : false,
            _marital_status: isSingPassUser && res.marital_status ? true : false,
            _unit_no: isSingPassUser && res.unit_no ? true : false
        }, () => {
            if (this.state.country_code && this.state.country_code !== 'SG') {
                this.setState({
                    isDisplay: true
                })
                this.getProvinceList()
            } else {

            }

        })
    }

    renderFormUserInfo = () => {
        return (
            <>
                <WingBlank>
                    <Flex>
                        <div className="page-title py-1">{this.state.patient_id ? 'Complete' : (this.state.singPassCode ? 'Telemed Registration' : 'Registration')}</div>
                    </Flex>
                    <WhiteSpace />
                    <Flex className={`page-myinfo completeWithMyinfoBtn ${this.state.singPassCode ? 'disabled' : ''}`} onClick={this.getSingPassUrl.bind(this)}>
                        <Flex className="size-1">{this.state.singPassCode ? 'Completed' : 'Complete'} with <img className="page-myinfo-img" src={myInfo} alt="" /></Flex>
                    </Flex>
                    <WhiteSpace />
                    <Flex>
                        <div className="page-sub-title">Complete your profile</div>
                    </Flex>
                    <WhiteSpace />
                    <form id="complete-profile">
                        <Flex direction="column" align="start">
                            <Flex.Item>
                                <div className="lable-title requiredFields">Email</div>
                            </Flex.Item>
                            <Flex.Item>
                                <InputItem
                                    clear
                                    name='email'
                                    disabled={this.state._email}
                                    placeholder="Enter your Email"
                                    error={this.state.emailError}
                                    value={this.state.email}
                                    onChange={v => {
                                        let _email= v.trim()
                                        this.setState({ email: _email })
                                    }}
                                ></InputItem>
                                <span className="errorMsg" >{(this.state.errorMsg.email) ? this.state.errorMsg.email : null} </span>
                            </Flex.Item>
                            <Flex.Item>
                                <div className="lable-title requiredFields">Mobile No.</div>
                            </Flex.Item>
                            <Flex.Item>
                                <Flex className={`${this.state._dialingCode ? 'flGrey' : ''}`}>
                                    <Picker
                                        data={this.state.dialingCodeList}
                                        locale={enUs}
                                        extra="Dialing Code"
                                        disabled={this.state._dialingCode}
                                        cols={1}
                                        value={[this.state.dialingCode]}
                                        onChange={v => {
                                            console.log(v);
                                            this.setState({
                                                dialingCode: v[0]
                                            })
                                        }}
                                    >
                                        <CustomChildren sClas={this.state.dialingCode ? 'sel' : 'noSel'}></CustomChildren>
                                    </Picker>
                                    <Flex.Item>
                                        <InputItem
                                            clear
                                            value={this.state.mobile}
                                            error={this.state.mobileError}
                                            disabled={this.state._mobile}
                                            placeholder="Enter your Mobile No."
                                            onChange={v => this.setState({ mobile: v })}
                                        ></InputItem>
                                    </Flex.Item>
                                </Flex>
                                <span className="errorMsg" >
                                    {(this.state.errorMsg.dialingCode) ? this.state.errorMsg.dialingCode : null}
                                    {(this.state.errorMsg.mobile) ? this.state.errorMsg.mobile : null}
                                </span>
                            </Flex.Item>
                            {
                                this.state.singPassCode ?
                                    (
                                        <>
                                            <Flex.Item>
                                                <div className="lable-title">Full Name * <span>(as in ID card)</span></div>
                                            </Flex.Item>
                                            <Flex.Item>
                                                <InputItem
                                                    value={this.state.fullName}
                                                    disabled
                                                ></InputItem>
                                            </Flex.Item>
                                        </>
                                    ) : ''
                            }
                            <Flex.Item>
                                <div className="lable-title">First Name * <span>(as in ID card)</span></div>
                            </Flex.Item>
                            <Flex.Item>
                                <InputItem
                                    clear
                                    placeholder="Enter your First Name"
                                    error={this.state.firstNameError}
                                    value={this.state.firstName}
                                    disabled={this.state._firstName}
                                    onChange={v => this.setState({ firstName: v })}
                                ></InputItem>
                                <span className="errorMsg" >{(this.state.errorMsg.firstName) ? this.state.errorMsg.firstName : null} </span>
                            </Flex.Item>

                            <Flex.Item>
                                <div className="lable-title">Last Name * <span>(as in ID card)</span></div>
                            </Flex.Item>
                            <Flex.Item>
                                <InputItem
                                    clear
                                    value={this.state.lastName}
                                    error={this.state.lastNameError}
                                    placeholder="Enter your Last Name"
                                    onChange={v => this.setState({ lastName: v })}
                                ></InputItem>
                                <span className="errorMsg" >{(this.state.errorMsg.lastName) ? this.state.errorMsg.lastName : null} </span>
                            </Flex.Item>

                            <Flex.Item>
                                <div className="lable-title requiredFields">Identification No.</div>
                            </Flex.Item>
                            <Flex.Item>
                                <InputItem
                                    clear
                                    value={this.state.nric}
                                    disabled={this.state._nric}
                                    error={this.state.nricError}
                                    placeholder="Enter your Identification No."
                                    onChange={v => this.setState({ nric: v })}
                                ></InputItem>
                                <span className="errorMsg" >{(this.state.errorMsg.nric) ? this.state.errorMsg.nric : null} </span>
                            </Flex.Item>

                            <Flex.Item>
                                <div className="lable-title requiredFields">Date of Birth</div>
                            </Flex.Item>

                            <Flex.Item>
                                <div onClick={this.openDatePicker.bind(this)}>
                                    <InputItem
                                        className={`pickerLabel ${this.state._birth ? 'disabledPicker-input' : ''}`}
                                        name='birth'
                                        placeholder="Picker your DOB"
                                        error={this.state.birthError}
                                        value={formatDate(this.state.birth)}
                                        onFocus={() => {
                                            document.activeElement.blur();
                                        }}
                                    ></InputItem>
                                </div>
                                <span className="errorMsg" >{(this.state.errorMsg.birth) ? this.state.errorMsg.birth : null} </span>
                            </Flex.Item>

                            <Flex.Item className={`pickerLabel ${this.state._gender ? 'disabledPicker' : ''}`}>
                                <Picker
                                    disabled={this.state._gender}
                                    data={this.state.genderList}
                                    locale={enUs}
                                    value={[this.state.gender]}
                                    extra="Gender"
                                    cols={1}
                                    onChange={v => {
                                        this.setState({
                                            gender: v[0]
                                        })
                                    }}
                                >
                                    <CustomChildren sClas={this.state.gender ? 'sel' : 'noSel'}></CustomChildren>
                                </Picker>
                                <span className="errorMsg" >{(this.state.errorMsg.gender) ? this.state.errorMsg.gender : null} </span>
                            </Flex.Item>

                            <Flex.Item className={`pickerLabel ${this.state._nationality ? 'disabledPicker' : ''}`}>
                                <Picker
                                    data={this.state.nationalityList}
                                    value={[this.state.nationality]}
                                    disabled={this.state._nationality}
                                    cols={1}
                                    extra="Nationality"
                                    locale={enUs}
                                    onChange={v => {
                                        this.setState({ nationality: v[0] })
                                    }}
                                >
                                    <CustomChildren sClas={this.state.nationality ? 'sel' : 'noSel'}></CustomChildren>
                                </Picker>
                                <span className="errorMsg" >{(this.state.errorMsg.nationality) ? this.state.errorMsg.nationality : null} </span>
                            </Flex.Item>

                            <Flex.Item className={`pickerLabel ${this.state._marital_status ? 'disabledPicker' : ''}`}>
                                <Picker
                                    data={this.state.maritalStatus}
                                    disabled={this.state._marital_status}
                                    value={[this.state.marital_status]}
                                    cols={1}
                                    extra="Marital Status"
                                    locale={enUs}
                                    onChange={v => this.setState({ marital_status: v[0] })}
                                >
                                    <CustomChildren sClas={this.state.marital_status ? 'sel' : 'noSel'}></CustomChildren>
                                </Picker>
                            </Flex.Item>
                            <Flex.Item>
                                <div className="lable-title">Drug Allergy (if any)</div>
                            </Flex.Item>
                            <Flex.Item>
                                <TextareaItem
                                    className="formTextarea border-light"
                                    value={this.state.drug_allergy}
                                    placeholder="Enter Drug Allergy"
                                    autoHeight
                                    rows={3}
                                    onChange={v => this.setState({ drug_allergy: v })}
                                ></TextareaItem>
                            </Flex.Item>
                        </Flex>
                        <WhiteSpace />
                        <WhiteSpace />
                        <WhiteSpace />
                        <Flex>
                            <div className="page-sub-title">Add your address</div>
                        </Flex>
                        <WhiteSpace />
                        <WhiteSpace />
                        <WhiteSpace />
                        <WhiteSpace />
                        <Flex.Item>
                            {this.state.gmapsLoaded && (
                                <GoogleSearchInput
                                    getAddress={this.getAddress}
                                ></GoogleSearchInput>
                            )}
                        </Flex.Item>
                        <Flex.Item>
                            <div className="lable-title requiredFields">Postal Code</div>
                        </Flex.Item>
                        <Flex.Item>
                            <InputItem
                                clear
                                value={this.state.postal_code}
                                error={this.state.postal_codeError}
                                disabled={this.state._postal_code}
                                placeholder="Enter Postal Code"
                                onChange={v => this.setState({ postal_code: v })}
                            ></InputItem>
                            <span className="errorMsg" >{(this.state.errorMsg.postal_code) ? this.state.errorMsg.postal_code : null} </span>
                        </Flex.Item>
                        <Flex.Item>
                            <div className="lable-title requiredFields">Country</div>
                        </Flex.Item>
                        <Flex.Item className={`pickerLabel ${this.state._country_code ? 'disabledPicker' : ''}`}>
                            <Picker
                                data={this.state.countryList}
                                extra="Country"
                                locale={enUs}
                                cols={1}
                                value={[this.state.country_code]}
                                disabled={this.state._country_code}
                                onChange={v => {
                                    this.setState({
                                        country_code: v[0],
                                    }, () => {
                                        let show;
                                        if (this.state.country_code === 'SG') {
                                            show = false;
                                        } else {
                                            show = true;
                                        }
                                        this.getProvinceList();
                                        this.setState({
                                            isDisplay: show
                                        });
                                    })
                                }}
                            >
                                <CustomChildren sClas={this.state.country_code ? 'sel' : 'noSel'}></CustomChildren>
                            </Picker>
                            <span className="errorMsg" >{(this.state.errorMsg.country_code) ? this.state.errorMsg.country_code : null} </span>
                        </Flex.Item>

                        <div style={{ width: '100%', display: this.state.isDisplay ? 'block' : 'none' }}>
                            <Flex.Item>
                                <div className="lable-title requiredFields">Province</div>
                            </Flex.Item>
                            <Flex.Item>
                                <Picker
                                    data={this.state.provinceList}
                                    extra="Province"
                                    locale={enUs}
                                    cols={1}
                                    value={[this.state.province_code]}
                                    onChange={v => {
                                        this.setState({
                                            province_code: v[0],
                                        }, () => {
                                            console.log('change get c')
                                            this.getCityList();
                                        })
                                    }}
                                >
                                    <CustomChildren sClas={this.state.province_code ? 'sel' : 'noSel'}></CustomChildren>
                                </Picker>
                                <span className="errorMsg" >{(this.state.errorMsg.province_code) ? this.state.errorMsg.province_code : null} </span>
                            </Flex.Item>

                            <Flex.Item>
                                <div className="lable-title requiredFields">City</div>
                            </Flex.Item>
                            <Flex.Item>
                                <Picker
                                    data={this.state.cityList}
                                    extra="City"
                                    cols={1}
                                    locale={enUs}
                                    value={[this.state.city_code]}
                                    onChange={v => {
                                        this.setState({
                                            city_code: v[0],
                                        })
                                    }}
                                >
                                    <CustomChildren sClas={this.state.city_code ? 'sel' : 'noSel'}></CustomChildren>
                                </Picker>
                                <span className="errorMsg" >{(this.state.errorMsg.city_code) ? this.state.errorMsg.city_code : null} </span>
                            </Flex.Item>
                        </div>

                        <Flex.Item>
                            <div className="lable-title requiredFields">Street</div>
                        </Flex.Item>
                        <Flex.Item>
                            <InputItem
                                clear
                                error={this.state.addressError}
                                value={this.state.address}
                                disabled={this.state._address}
                                placeholder="23 Keppin Ave"
                                onChange={v => this.setState({
                                    address: v
                                })}
                            ></InputItem>
                            <span className="errorMsg" >{(this.state.errorMsg.address) ? this.state.errorMsg.address : null} </span>
                        </Flex.Item>

                        <Flex.Item>
                            <div className="lable-title">Unit No.</div>
                        </Flex.Item>
                        <Flex.Item>
                            <InputItem
                                clear
                                value={this.state.unit_no}
                                disabled={this.state._unit_no}
                                placeholder="#23-34"
                                onChange={v => this.setState({
                                    unit_no: v
                                })}
                            ></InputItem>
                        </Flex.Item>

                        <Flex className="mt10" justify="center">
                            <i>By continuing you are registering for a HeartVoice account</i>
                        </Flex>
                        <Flex className="termCon">
                            <AgreeItem data-seed="logId" onChange={this.onAgrrTerm.bind(this)}>
                                <a href={TCLink} target="_blank" rel="noopener noreferrer"><i className="fbPink">Terms & Conditions</i></a>
                            </AgreeItem>
                        </Flex>

                        <WhiteSpace />
                        <WhiteSpace />
                        <WingBlank size="lg">
                            <Button
                                className="fill-pink-btn pb-5"
                                activeClassName="fill-pink-active-btn"
                                loading={this.state.confirmLoadding}
                                onClick={this.confirm}
                            // disabled={this.state.agreeBtn}
                            >
                                Confirm
                                    </Button>
                        </WingBlank>
                        <WhiteSpace />
                        <WhiteSpace />
                    </form>
                </WingBlank>
            </>
        )
    }



    render() {
        return (
            <LocaleProvider locale={{
                value: 'English',
                label: 'English',
                language: enUs,
            }}>
                <div className="CompleteProfile-Page">
                    {this.renderFormUserInfo()}
                    <DatePicker
                        mode="date"
                        showHeader={false}
                        value={this.state.birth}
                        isOpen={this.state.isDatePickerOpen}
                        onSelect={this.handleSelect}
                        onCancel={this.handleCancel}
                        min={new Date(1900, 1, 1)}
                        max={new Date()}
                    >
                    </DatePicker>
                    <br /><br /><br /><br />
                    <Modal
                        visible={this.state.TcModal}
                        transparent
                        maskClosable={false}
                        onClose={() => this.onTCClose()}
                        title="Error"
                        footer={[{ text: 'Okay', onPress: () => { this.onTCClose() } }]}
                    >
                        <div>
                            Please read and agree to the <br /> Terms & Conditions before  <br /> proceeding
                        </div>
                    </Modal>
                    <Modal
                        visible={this.state.singpassModal}
                        transparent
                        maskClosable={false}
                        onClose={() => this.onSingpassClose()}
                        title="Error"
                        footer={[{ text: 'Okay', onPress: () => { this.onSingpassClose() } }]}
                    >
                        <div>
                            We counld not retrieve your info <br /> form MyInfo. Pelease try again or fill<br /> in the form manually.
                        </div>
                    </Modal>
                </div>
            </LocaleProvider>
        );
    }
}

export default CompleteProfile;