import React, { Component } from "react";
import {
  Flex,
  WhiteSpace,
  WingBlank,
  TabBar,
  Icon,
  Grid,
  Toast,
  Modal,
  ListView,
  Button,
  NavBar
} from "antd-mobile";
import HVLogo from "../images/icons/IconHeartVoiceLong.svg";
import success from '../images/icons/success.png';
import { OneDocTCLink } from "../commons/Constants";
import { getCache, removeCache } from '../commons/Helpers';
import Icon1docwallet from '../images/icons/Icon1docwallet.png'
import OmronLogo from "../images/icons/Omron.png";
import ArrowsIcon from "../images/icons/arrows.png";
import { isMobile } from 'react-device-detect';
import AuthGuard from '../services/AuthGuard';

class AuthGrant extends Component {

  constructor(props) {
    super(props);
    this.state = {
      totalAmount: 0.00,
    }
  }

  componentDidMount() {
    if (getCache('grantee_app_id')) {
    //   let { totalAmount } = JSON.parse(getCache('hs_payInfo'));
    //   this.setState({
    //     totalAmount: totalAmount,
    //   })
    } else {
      this.props.history.push("/404");
    }
  }

  goToWA = () => {
    
  };

  goBack = () => {
    if (getCache('clinic_id')) {
      this.props.history.push("/DoctorDetails/" + getCache('clinic_id') + '?tab=3');
    }
  }

  agree = ()=>{
    this.loadingToast();
    AuthGuard.getOauthCode({
      app_id: getCache("grantee_app_id")
    }
      , (isOk, res) => {
        if (isOk) {
            removeCache('grantee_app_id')
            let code = res.code
            // if (isMobile) {
            let grantee_callback_url = getCache("grantee_callback_url")
            if (grantee_callback_url) {
              let url = grantee_callback_url + "?status=success&code=" + code
              window.location.href = url
              removeCache("grantee_app_id")
              removeCache("grantee_callback_url")
            }
            // }
            // Toast.success();
            console.log(res);
            Toast.hide();
        } else {
            Toast.fail(res,2);
        }
      });
}

reject = ()=>{
  if (isMobile) {
    let grantee_callback_url = getCache("grantee_callback_url")
    if (grantee_callback_url) {
      let url = grantee_callback_url + "?status=reject"
      window.location.href = url
      removeCache("grantee_app_id")
      removeCache("grantee_callback_url")
    }
  }
}

loadingToast() {
  Toast.loading('Loading...', 30, () => {
      console.log('Load complete !!!');
  })
}

// go1DocTNC = ()=>{
//     this.props.history.push(OneDocTCLink);
// }

  render() {
    return (
      <div className="authGrantPage">
        <Flex className="content">
          <Flex.Item>
            <WingBlank>
              <WhiteSpace size="md" />
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <Flex direction="column">
                <Flex justify="center" align="center">
                <img src={OmronLogo} className="iconHVLogo" />
                <img src={ArrowsIcon} className="iconArrows" />
                <img src={Icon1docwallet} className="icon1DocLogo" />
            </Flex>
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <div className="title bolder">To continue with this service, you agree to:</div>
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <div className="description">
                <p>1. Give permission to HeartVoice to send your personal information to <span className="bolder">1doc</span> for account creation:</p>
                <p>Full name, name, mobile no., email, date of birth, gender, nric/ passport no., address, postal code, citizenship, race</p>
                </div>
                <WhiteSpace size="lg" />
                <div className="description">
                {/* 2. <button  className="tncBtn fbPink" onClick={this.go1DocTNC}> 1Doc Terms & Conditions </button> */}
                2. <a href={OneDocTCLink} target="_blank" className="fbPink"> 1Doc Terms & Conditions </a>
                </div>
              </Flex>
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <Flex justify="center">
                <Button
                        className="outline-pink-btn noBtn"
                        activeClassName="outline-pink-active-btn noBtn"
                        onClick={this.reject}
                        loading={false}
                    >
                        Do not continue
                </Button>
                <Button
                        className="fill-pink-btn yesBtn"
                        activeClassName="fill-pink-active-btn yesBtn"
                        onClick={this.agree}
                        loading={false}
                    >
                        I agree
                </Button>
            </Flex>
            </WingBlank>
            
            <WingBlank size="lg">
                
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
             </WingBlank>
          </Flex.Item>
        </Flex>
      </div>

    );
  }
}

export default AuthGrant;
