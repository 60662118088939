import React, { useEffect } from "react";
import { Flex, Button } from "antd-mobile";
export default () => {
  const goToContactUs = () => {
    window.location.href = "https://www.ourheartvoice.com/contact-us/";
  };

  return (
    <div
      className="notfound-page"
      style={{
        backgroundColor: "#E9F5F6",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Flex direction="column" justify="end">
        <div className="desc1" style={{ paddingTop: "35%" }}>
          No Access, Invalid address.
        </div>
        {/* <div className="desc2" style={{ color: "red" }}>
          http://www.baidu.com
        </div> */}
        <div className="desc2">Need help?</div>
        <Button
          className="outline-blue-btn"
          onClick={goToContactUs}
          size="large"
        >
          Contact Us
        </Button>
      </Flex>
    </div>
  );
};
