import React, { Component } from 'react';
import { WingBlank, Flex, WhiteSpace, Button, Modal, Toast } from 'antd-mobile';
import { dayFile, WHATSAPP_MOBILENO } from '../commons/Constants';
import AuthGuard from '../services/AuthGuard';
import { getWeek, setCache, showDate } from '../commons/Helpers';
import iconDoctorAvatar from '../images/icons/icon_doctor_avatar.png'
import LoginModel from '../component/LoginModel';
import iconSaveDoctor from '../images/icons/icon_saveDr.png'
import iconUnSaveDoctor from '../images/icons/icon_unsaveDr.png'
import Search from '../images/icons/search.png'
import { ViewMore } from '../component/ViewMore';
import ScheduleCalendar from '../component/ScheduleCalendar';
import { isBrowser, isMobile } from 'react-device-detect';
import moment from 'moment';
class CorTelProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consultBtnLoading: false,
      savedrLoading: false,
      clinic_info_id: "",
      user_drug_allergy: "",
      user_source: "",
      user_is_complete: false,
      doctorList: [],
      teleconsult_event_id: "",
      doctor_name: "",
    }
  }

  async componentDidMount() {
    let { teleconsult_event_id, token } = this.props.match.params;
    if (token) await AuthGuard.getNewToken(token);
    if (teleconsult_event_id) this.setState({ teleconsult_event_id: teleconsult_event_id }, () => {
      this.getTelDoctorList();
    })
  }

  getTelDoctorList() {
    this.loadingToast()
    AuthGuard.telDoctorList({
      teleconsult_event_id: this.state.teleconsult_event_id,
      doctor_name: this.state.doctor_name,
    }, (isOk, res) => {
      Toast.hide();
      if (isOk) {
        let _doctorList = [];
        if(res && res.length > 0){
          res.map((item, index)=>{
            if(item.doctor_opening_time && item.doctor_opening_time.length > 0){
              _doctorList.push(item);
            }
          });
        }
        this.setState({
          doctorList: _doctorList,
        })
      }
    });
  }

  consult = async (code, id) => {
    console.log(code, id)

    let _url = window.location.pathname

    if (!AuthGuard.checkLogin()) {
      this.loginModel.alertLogin({
        url: _url,
        query: id,
        alert_title: 'You need a HeartVoice account to consult doctors.',
      })
    } else {
      try {
        let res = await this.checkProfile()
        console.log(res)
        if (res) {

          if (navigator.userAgent.indexOf('heartvoice') !== -1) {
            this.doMobileInqueue.call(this, id, 'app')
          } else {
            this.doMobileInqueue.call(this, id, 'whatsapp')
          }
        }
      } catch (error) {
        if (error && error.status_code !== 401) {
          Modal.alert('Complete Profile', <div className="complete_Profile">Please provide more information about
            yourself to initiate a teleconsultation. </div>, [
            { text: 'Cancel', style: { color: '#108ee9' }, onPress: () => console.log('第0个按钮被点击了') },
            { text: 'Proceed', style: { color: '#108ee9' }, onPress: () => this.goMyinfo() },
            {
              text: ' ',
              style: { display: 'none' },
              onPress: () => {
                console.log('No')
              }
            }
          ])
        }
      }
    }
  }

  doMobileInqueue(id, channel) {
    this.loadingToast()
    this.setState({
      consultBtnLoading: true
    })
    AuthGuard.mobileInqueue({
      doctor_id: id,
      channel: channel
    }, (isOk, res) => {
      Toast.hide();
      this.setState({
        consultBtnLoading: false
      })
      if (isOk) {
        if (res.message !== null) {
          if (isBrowser) {
            Modal.alert('Success', <div>Please check your whatsapp for updates!</div>, [
              { text: 'OK', onPress: () => console.log('第0个按钮被点击了') }
            ])
          } else {
            if (channel == 'app') {
              window.location.href = 'heartvoice://heartvoice/home?action=home'
            } else {
              Modal.alert('Success', <div>Please check your whatsapp for updates!</div>, [
                { text: 'OK', onPress: () => { window.location.href = 'https://wa.me/' + WHATSAPP_MOBILENO } }
              ])
            }
          }
        }
      } else {
        if (res.status_code !== 401) {
          Modal.alert('Oops', <div>{res.message}</div>, [
            { text: 'Okay', onPress: () => console.log('第0个按钮被点击了') }
          ])
        }
      }
    });
  }

  bookAppointment = async (id, clinic_id) => {
    let _url = window.location.pathname
    if (!AuthGuard.checkLogin()) {
      this.loginModel.alertLogin({
        url: _url,
        query: id,
        alert_title: 'You need a HeartVoice account to consult doctors.'
      })
    } else {
      try {
        let res = await this.checkProfile()
        this.scModel.openScModel({
          clinic_id: clinic_id,
          doctor_id: id,
          for_corporate_teleconsult: true,
          type:'teleconsult'
        })
      } catch (error) {
        if (error && error.status_code !== 401) {
          Modal.alert('Complete Profile', <div className="complete_Profile">Please provide more information about
            yourself to initiate a teleconsultation. </div>, [
            { text: 'Cancel', style: { color: '#108ee9' }, onPress: () => console.log('第0个按钮被点击了') },
            { text: 'Proceed', style: { color: '#108ee9' }, onPress: () => this.goMyinfo() },
            {
              text: ' ',
              style: { display: 'none' },
              onPress: () => {
                console.log('No')
              }
            }
          ])
        }
      }
    }
  }

  checkProfile = () => {
    this.loadingToast()
    return new Promise((resolve, reject) => {
      AuthGuard.checkProfileComplete({}, (isOk, res) => {
        console.log(isOk, res)
        let { drug_allergy, is_complete, source } = res
        Toast.hide()
        if (isOk) {
          if (is_complete) {
            resolve(res)
          } else {
            this.setState({
              user_drug_allergy: drug_allergy,
              user_source: source,
              user_is_complete: is_complete
            }, () => {
              reject(res)
            })
          }

        } else {
          reject(res)
        }
      })
    })
  }

  goMyinfo = () => {
    let hasUrl = window.location.pathname;
    setCache('redirectUrl', hasUrl)
    if (this.state.user_source === "manually") {
      // manually 手动注册的用户
      this.props.history.push("/improve");
    } else {
      // myinfo myinfo注册的用户
      if (!this.state.user_is_complete && this.state.user_drug_allergy) {
        this.props.history.push("/improve");
      } else {
        this.props.history.push("/drugAllergy");
      }
    }
  }

  saveDr(id, status, e) {
    e.stopPropagation();
    let _url = window.location.pathname
    if (!AuthGuard.checkLogin()) {
      this.loginModel.alertLogin({
        url: _url,
        alert_title: 'You need a HeartVoice account to consult doctors.',
      })
      return false
    }
    if (this.state.savedrLoading) return
    this.setState({
      savedrLoading: true
    })
    AuthGuard.savedr({
      coach_id: id,
      status: status === 'saved' ? 'unsaved' : 'saved'
    }, (isok, res) => {
      if (isok) {
        let { coach_id, status } = res
        let _doctorList = this.state.doctorList
        let index = _doctorList.findIndex(item => item.provider_id === coach_id)
        _doctorList[index].is_fav = status
        this.setState({
          doctorList: _doctorList
        })
      }
      this.setState({
        savedrLoading: false
      })
    })
  }

  keyPressSearch = (e) => {
    if (e.which !== 13) return
    this.setState({
      doctorList: []
    }, () => {
      this.getTelDoctorList();
    });
  }

  loadingToast() {
    Toast.loading('Loading...', 30, () => {
      console.log('Load complete !!!');
    });
  }

  onRef = (ref) => {
    this.loginModel = ref
  }

  onSCRef = (ref) => {
    this.scModel = ref
  }

  render() {
    let getDoctorList = [];
    //搜索获取医生列表
    getDoctorList = this.state.doctorList.length > 0 ? this.state.doctorList.map((item, index) => {
      let _specialty = '-'
      let _specialtyArr = []
      if (item.specialty && item.specialty.length > 0) {
        item.specialty.map((item, index) => {
          _specialtyArr.push(item.display_name)
        })
        _specialty = _specialtyArr.join(' / ')
      }
      var isThereNoLeadTime = false
      if (!item.lead_time) {
        isThereNoLeadTime = true
      }
      return (
        <div className="doctors-section-card" key={item.provider_id} id={item.provider_id}>
          <WingBlank size="lg">
            <div className="icon-save" onClick={this.saveDr.bind(this, item.provider_id, item.is_fav)}>
              <img src={item.is_fav === 'saved' ? iconUnSaveDoctor : iconSaveDoctor} alt="" />
              <p className="flPink">{item.is_fav === 'saved' ? 'Unsave' : 'Save'}</p>
            </div>
            <img src={item.photo && item.photo.url ? item.photo.url.l : iconDoctorAvatar} className="doctor-img" alt="doctor_photo" />
            <p className="fb" style={{ wordBreak: 'break-word' }}>{item.name}</p>
            <p className="flGrey">
              {
                item.specialty && item.specialty.length >= 10 ?
                  <ViewMore
                    length={150}
                    text={_specialty}
                  /> : _specialty
              }
            </p>
            <WhiteSpace size="lg" />
            <p style={{ wordBreak: 'break-word' }}>
              {
                item.professional_profile && item.professional_profile.length >= 150 ?
                  <ViewMore
                    length={150}
                    text={item.professional_profile}
                  /> : item.professional_profile
              }
            </p>
            <WhiteSpace size="lg" />
          </WingBlank>
          {item.doctor_opening_time && item.doctor_opening_time.length > 0 ?
            <div className="opening-hours">
              <WingBlank>
                <div className="background-ccc">
                  <Flex>
                    <ul>
                      {
                        item.doctor_opening_time && item.doctor_opening_time.length > 0 ?
                          item.doctor_opening_time.map((dayI, index) => {
                            return (
                              <li key={index}>
                                <p className="weekname" style={{ color: getWeek() === dayFile(dayI.day) ? '#E02381' : '#000' }}>{dayFile(dayI.day)}</p>
                                {dayI.data.map((iitem, iindex) => {
                                  return (<p key={`clinic-time-${iindex}`}><span>{iitem.start}</span>-<span>{iitem.end}</span></p>)
                                })
                                }
                              </li>
                            )
                          }) : ''
                      }
                    </ul>
                  </Flex>
                </div>
              </WingBlank>
            </div>
            : ""}
          {item.doctor_opening_time && item.doctor_opening_time.length > 0 ? <>
            <WhiteSpace size="lg" />
            <WhiteSpace size="lg" />
            <WhiteSpace size="xs" />
            <WingBlank size="lg">
              <WingBlank size="lg">
                <div className="book-consult">
                  {item.doctor_opening_time_to == true && isThereNoLeadTime && (item.start_date && item.end_date && moment(showDate(new Date(), 2)).valueOf() >= moment(item.start_date).valueOf() && moment(showDate(new Date(), 2)).valueOf() <= moment(item.end_date).valueOf()) && !item.exception_slots.filter(x=>x.day==showDate(new Date(),2)).length ?
                    <Button loading={this.state.consultBtnLoading} disabled={this.state.consultBtnLoading} className="consult-img fill-pink-btn" onClick={this.consult.bind(this, item.identification, item.provider_id)} > CONSULT NOW </Button>
                    : ''
                  }
                  <WhiteSpace size="lg" />
                  <Button className="consult-img outline-pink-btn" onClick={this.bookAppointment.bind(this, item.provider_id, item.clinic_id)}> BOOK APPOINTMENT</Button>
                </div>
              </WingBlank>
            </WingBlank>
            <WhiteSpace size="lg" />
            <WhiteSpace size="xs" />
          </> : ""}
        </div>
      )
    }) : []

    return (
      <div className="Doctor-list pd-0">
        <LoginModel
          onRef={this.onRef}
          history={this.props.history}
        />
        <ScheduleCalendar
          onRef={this.onSCRef}
        />
        <div className="white-panel">
          <Flex className="doctor-search">
            <Flex.Item>
              <Flex>
                <div className="search-module">
                  <img src={Search} className="Search" alt="Search" />
                  <input
                    className="search-input"
                    type="search"
                    value={this.state.doctor_name}
                    onKeyPress={this.keyPressSearch}
                    onChange={e => {
                      this.setState({
                        doctor_name: e.target.value
                      })
                    }}
                    placeholder="SEARCH" />
                </div>
              </Flex>
            </Flex.Item>
          </Flex>
        </div>
        <div>
          <Flex style={{ paddingLeft: '1.6em', }} className="doctor-list">
            <div className="total_res" style={{ paddingTop: '1em' }}>
              {this.state.doctorList.length > 0 ? this.state.doctorList.length + ' Result(s) found' : 'No Result found'}
            </div>
          </Flex>
          <Flex className="doctor-list">
            <Flex.Item>
              <WingBlank size="lg">
                <Flex>
                  <WingBlank className="corporate-tel-provider" size="lg" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <div className="Doctors-section" style={{width: '100%'}}>
                      <div className="scroll-box">
                        <div className="scroll-main">
                          {getDoctorList.length > 0 ? getDoctorList : ''}
                        </div>
                      </div>
                    </div>
                  </WingBlank>
                </Flex>
              </WingBlank>
            </Flex.Item>
          </Flex>
        </div>
      </div>
    );
  }
}
export default CorTelProvider;