import React, { useEffect, useState } from "react";
import {
  WingBlank,
  Flex,
  WhiteSpace,
  NavBar,
  Icon,
  Toast,
  Button,
  Switch,
  Modal,
  Checkbox,
} from "antd-mobile";
import filterIcon from "../images/icons/icon-filter.png";
import AuthGuard from "../services/AuthGuard";
import { currencyType } from "../commons/Helpers";
import { useDebounce } from "../hook/utils";
import InfiniteScroll from "react-infinite-scroll-component";
import _ from "lodash";

const CheckboxItem = Checkbox.CheckboxItem;

const FilterHeader = ({ onLeftClick }) => {
  return (
    <div>
      <NavBar
        mode="light"
        icon={<Icon type="left" size="lg" color="#EB5DA6" />}
        onLeftClick={onLeftClick}
        className="backBtn"
      >
        Filters
      </NavBar>
    </div>
  );
};

export default (props) => {
  const [searchCode, setSearchCode] = useState("");
  const [totalData, setTotalData] = useState(0);
  const [healthScreenList, setHealthScreenList] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [bookedHS, setBookedHS] = useState(null);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [filters, setFilters] = useState([]);

  const data = [
    { value: "North", label: "North" },
    { value: "West", label: "West" },
    { value: "Central", label: "Central" },
    { value: "East", label: "East" },
    { value: "North-East", label: "North-East" },
  ];

  const onChange = ({ checked, value }) => {
    if (checked) {
      setFilters([...filters, value]);
    } else {
      console.log(_.remove(filters, (o) => o === value));
      setFilters([...filters]);
    }
  };

  const handleApplyFilter = () => {
    setPopoverVisible(false);
    setParmas({
      ...params,
      limit: 10,
      page: 1,
      filters: _.size(filters) > 0 ? JSON.stringify(filters) : "",
    });
  };

  const debouncedParam = useDebounce(searchCode, 800);

  const [params, setParmas] = useState({
    corporate_type: "N",
    view_type: "package",
    filters: "",
    code: "",
    limit: 10,
    page: 1,
  });

  const handleToDetail = (row) => {
    if (params.view_type === "clinic") {
      let _pathname = `/doctorDetailsAI/` + row.id + "//hs";
      props.history.push({
        pathname: _pathname,
        state: {
          prePath: "hsl",
        },
      });
    } else {
      let _pathname = `/doctorScreeningDetails/` + row.health_screening_id;
      props.history.push({
        pathname: _pathname,
        state: {
          prePath: "hsl",
        },
      });
    }
  };

  const handleCorporateTypeSearch = () => {
    setParmas({
      ...params,
      limit: 10,
      page: 1,
      corporate_type: params?.corporate_type === "Y" ? "N" : "Y",
    });
  };

  const handleViewPdf = () => {
    bookedHS?.pdfUrl && window.open(bookedHS?.pdfUrl);
  };

  const fetchMoreData = () => {
    let _page = params.page;
    console.log(_page);
    setParmas({
      ...params,
      page: _page + 1,
    });
  };

  useEffect(() => {
    setFetchLoading(true);
    AuthGuard.getHealthScreenList(params, (isOk, res) => {
      const { total, result } = res;
      setFetchLoading(false);
      if (isOk) {
        if (params.page === 1) {
          setHealthScreenList(result);
        } else {
          let mergeArr = [...healthScreenList, ...result];
          console.log(mergeArr);
          setHealthScreenList([...mergeArr]);
        }

        setTotalData(total);
      }
    });
  }, [params]);

  useEffect(() => {
    AuthGuard.getHSBookedEvnet({ limit: 1, page: 1 }, (isOk, res) => {
      if (isOk) {
        setBookedHS({
          ...res?.corporate,
          pdfUrl: res?.document[0].s3_url,
          is_corporate: res.is_corporate,
        });
        handleCorporateTypeSearch();
      } else {
        setBookedHS(null);
      }
    });
  }, []);

  useEffect(() => {
    setParmas({
      ...params,
      limit: 10,
      page: 1,
      code: debouncedParam,
    });
  }, [debouncedParam]);

  return (
    <div className="HealthScreenList-Page">
      <NavBar
        mode="light"
        icon={<Icon type="left" size="lg" color="#EB5DA6" />}
        onLeftClick={() => this.props.history.goBack()}
        className="backBtn"
      >
        Health Screening
      </NavBar>
      <WingBlank>
        <WhiteSpace />
        <form className="input-search-form" onSubmit={() => {}}>
          <Flex justify="between" align="center">
            <Flex.Item
              className="search-contain"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Icon name="search" type="search" size="md" color="#eb5da6" />
              <input
                type="search"
                placeholder="Search"
                onChange={(e) => setSearchCode(e.target.value)}
                className="search-input"
                name="searchCode"
                autoComplete="off"
                value={searchCode}
              />
              <Icon
                name="search"
                type="search"
                onClick={() => {}}
                size="md"
                color="#eee"
              />
            </Flex.Item>
            <img
              alt="img"
              src={filterIcon}
              className="filterIcon"
              onClick={() => setPopoverVisible(!popoverVisible)}
            ></img>
          </Flex>
        </form>
        <div className="coupon-msg">
          <WhiteSpace />
          <WhiteSpace />
        </div>
        <Flex justify="between" align="center">
          <Flex>
            <div
              className="text"
              style={{ color: "#eb5da6" }}
              onClick={() =>
                setParmas({
                  ...params,
                  limit: 10,
                  page: 1,
                  view_type:
                    params.view_type === "package" ? "clinic" : "package",
                })
              }
            >
              {params.view_type === "package"
                ? "View by Clinic"
                : "View by Package"}
            </div>
          </Flex>
          <Flex>
            {bookedHS?.is_corporate && (
              <>
                {bookedHS?.name}
                <Switch
                  checked={params?.corporate_type === "Y"}
                  color="#eb5da6"
                  style={{
                    marginLeft: 10,
                  }}
                  onChange={handleCorporateTypeSearch}
                />
              </>
            )}
          </Flex>
        </Flex>
        <Flex>
          <div className="text">{totalData} Result(s) found</div>
        </Flex>
        <div className="coupon-msg">
          <WhiteSpace />
          {bookedHS?.is_corporate && (
            <>
              <WhiteSpace />
              <Flex justify="center">
                <Button
                  size="small"
                  className="outline-pink-btn"
                  style={{ maxWidth: "200px" }}
                  onClick={handleViewPdf}
                >
                  View Package Overview
                </Button>
              </Flex>
              <WhiteSpace />
            </>
          )}
        </div>
        <WhiteSpace />
        <div className="coupon-list-contain">
          <InfiniteScroll
            dataLength={healthScreenList?.length}
            hasMore={totalData > healthScreenList.length}
            loader={
              <div style={{ textAlign: "center", fontSize: 12 }}>
                loading...
              </div>
            }
            endMessage={
              <div style={{ textAlign: "center", fontSize: 12 }}>
                {fetchLoading ? "loading..." : "No more data..."}
              </div>
            }
            next={fetchMoreData}
          >
            {_.size(healthScreenList) > 0 &&
              params.view_type === "package" &&
              healthScreenList.map((item) => (
                <Flex
                  justify="between"
                  key={item.inventory_list_id}
                  className={"coupon-row "}
                  onClick={() => handleToDetail(item)}
                >
                  <div style={{ maxWidth: "80%" }}>
                    <div className="h3 mb-1">{item.name}</div>
                    <WhiteSpace />
                    <div className="h5">{item?.clinic_name || "-"}</div>
                    <div className="h5">{item?.package_summary}</div>
                  </div>
                  <div style={{ width: "20%", textAlign: "right" }}>
                    {item?.unit_price
                      ? "$" + currencyType(item?.unit_price, "SG")
                      : "$0.00"}
                  </div>
                </Flex>
              ))}

            {_.size(healthScreenList) > 0 &&
              params.view_type === "clinic" &&
              healthScreenList.map((item) => {
                let address = item.clinic_address;

                if (item?.unit_no) {
                  address += item?.unit_no;
                }

                if (item?.country) {
                  address += item?.country;
                }

                if (_.lowerCase(item?.country) !== "singapore") {
                  if (item?.province) {
                    address += item?.province;
                  }
                  if (item?.city) {
                    address += item?.city;
                  }
                }

                if (item?.postal_code) {
                  address += item?.postal_code;
                }

                return (
                  <Flex
                    justify="between"
                    key={item.inventory_list_id}
                    className={"coupon-row "}
                    onClick={() => handleToDetail(item)}
                  >
                    <div style={{ maxWidth: "80%" }}>
                      <div className="h3 mb-1">{item.clinic_name}</div>
                      <WhiteSpace />
                      <div className="h5">{address || "-"}</div>
                    </div>
                    <div style={{ width: "20%", textAlign: "right" }}>
                      <img
                        src={item.clinic_logo_url}
                        alt="clinic logo"
                        className="clinic-logo"
                      ></img>
                    </div>
                  </Flex>
                );
              })}
          </InfiniteScroll>
        </div>
      </WingBlank>
      <Modal
        visible={popoverVisible}
        transparent
        maskClosable={false}
        className="modal-filters"
        onClose={() => setPopoverVisible(false)}
        title={<FilterHeader onLeftClick={() => setPopoverVisible(false)} />}
        footer={[]}
      >
        <div style={{ overflow: "hidden" }}>
          <div className="font-b">Region</div>
          <Flex wrap="wrap">
            {data.map((i) => (
              <CheckboxItem
                key={i.value}
                checked={_.includes(filters, i.value)}
                onChange={(event) =>
                  onChange({
                    checked: event.target.checked,
                    value: i.value,
                  })
                }
              >
                {i.label}
              </CheckboxItem>
            ))}
          </Flex>
          <WhiteSpace size="lg" />
          <div className="dr-ftr-btn">
            <Button className="fill-pink-btn" onClick={handleApplyFilter}>
              Apply
            </Button>
            <WhiteSpace size="lg" />
            <Button
              className="outline-pink-btn"
              onClick={() => setPopoverVisible(false)}
            >
              Clear All
            </Button>
          </div>
          <WhiteSpace size="lg" />
        </div>
      </Modal>
    </div>
  );
};
