import React, { Component } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { ROUTES, GAID, GOOGLE_ANALYTICS } from "../commons/Constants";
import VideoCall from '../component/videoCall'
import VideoCallV3 from '../component/videoCallV3'
import AuthGuard from '../services/AuthGuard'
import ReactGA from "react-ga";
import { ReactGAEvent } from '../commons/ReactGAEvent';
import { getTokenSourceMapRange } from 'typescript';


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {  
            token: '',
            patient_uid:'',
            channel_code_hash:'',
            patient_token: '',
            dialing_code: '',
            mobile_number: '',
        }
    }

    componentDidMount() {
        if (GOOGLE_ANALYTICS){
            ReactGA.set({
            page: window.location.href,
            });
        }

        let id = this.props.match.params.id;        
        let self = this;
        // self.setState({
        //     patient_uid: 'cd086cc2-9187-4236-94aa-8f285304455e',
        //     channel_code_hash: 'c514361ad4486a50662775ea3a59d252e35d61377b0c641cd6fafd5de563e610',
        //     patient_token: '00687c93b423139423ab23167dcf7982080IABeRZPf9BfeeQt2qRaOM8dCCKef6XTZufVOKRWfa1QNkHITNsJVYgSHIgDu9AAA1paZXgQAAQD20ZheAwD20ZheAgD20ZheBAD20Zhe',
        // });

        if (id){
            AuthGuard.verifyUser({id:id},(isOk , res)=>{
                console.log(isOk, res)
                if (isOk){
                    res.queueId = id
                    AuthGuard.videoCallInfo = {
                        canConnect: true,
                        originUser: res,
                    }
                    this.setState({
                        token: res.userToken
                    })
                }else{
                    self.props.history.push("/404");
                }
            })
        }else{
            this.props.history.push("/404");
        }
    }

    componentWillReceiveProps(nextProps){
        if (GOOGLE_ANALYTICS){
            ReactGA.set({
              page: window.location.href,
            });
        }
    }

    endVideoCall(){
        ReactGAEvent('endCall click event','onclick endCall Btn');
        this.props.history.push('/hangup');
    }

    render() { 
        return ( 
            <VideoCallV3
                token={this.state.token}
                endVideoCall={this.endVideoCall.bind(this)}
             />
            // <VideoCall
            //     {...this.state}
            //     endVideoCall={this.endVideoCall.bind(this)}
            //  />
        );
    }
}
 
export default Home;