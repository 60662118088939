import React, { Component } from "react";
import {
  Button,
  Flex,
  Icon,
  NavBar,
  Toast,
  WhiteSpace,
  WingBlank,
} from "antd-mobile";
import AuthGuard from "../services/AuthGuard";
import { getCache, removeCache, setCache, getGST } from "../commons/Helpers";
import { isBrowser, isMobile } from "react-device-detect";
import IconCreditCard from "../images/icons/IconCard.png";
import IconPayNow from "../images/icons/IconPayNow.png";
import IconPayOnsite from "../images/icons/IconPayOnsite.png";
import _ from "lodash";

class ProgramsPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      program_id: "",
      loading: false,
      payOnSiteLoading: false,
      totalAmount: 0.0,
      disabled: false,
      totalMoney: "",
      couponId: "",
      program_code: "",
      program_image: "",
      programs_name: "",
      teleconsultation_currency_code: "",
      module_name: "",
      payment_mode: "CC1",
      item_ids: [],
      location_id: "",
      location_type: "",
      location_shot: {},
      appointment_date_id: "",
      appointment_date_shot: {},
      coupon_id: "",
      is_agree: "",
      package_id: null,
    };

    console.log(props);
  }
  componentDidMount() {
    let { id, module_name } = this.props.match.params;
    let proInfo = getCache("proInfo");

    if (this.props.location.state?.type === "HS") {
      let _totalAmount = this.props.location.state.totalAmount;
      let _GST_rate = this.props.location.state.GST_rate;
      let _GST_calculation = this.props.location.state.GST_calculation;
      if (_.toString(_GST_calculation) === "0") {
        _totalAmount = _.add(
          _.toNumber(_totalAmount),
          getGST(
            _.toNumber(_totalAmount),
            _.toNumber(_GST_rate),
            _.toString(_GST_calculation)
          )
        );
      }
      this.setState({
        ...this.props.location.state,
        package_id: id,
        totalAmount: _totalAmount,
      });
    } else {
      if (proInfo) {
        proInfo = JSON.parse(proInfo);
        this.setState({
          ...proInfo,
        });
      }
      if (id || module_name) {
        this.setState({
          program_id: id,
          module_name: module_name,
        });
      }
      removeCache("pro_ge_info");
      removeCache("geInfo");
      removeCache("bill_info");
    }
    if (getCache("hs_payInfoState")) {
      this.setState({
        ...this.state,
        ...JSON.parse(getCache("hs_payInfoState")),
        loading: false,
      });
      removeCache("hs_payInfoState");
    }
  }

  loadingToast() {
    Toast.loading("Loading...", 30, () => {
      console.log("Load complete !!!");
    });
  }

  hsPay = (type) => {
    this.loadingToast();
    removeCache("hs_payInfo");
    let total = this.state.totalAmount;
    AuthGuard.requestForHealthScreening(
      {
        item_ids: this.state.item_ids, //addonID
        location_id: this.state.location_id,
        location_shot: this.state.location_shot,
        appointment_date_shot: this.state.appointment_date_shot,
        total,
        is_agree: this.state.is_agree,
        payment_mode:
          type === "paynow"
            ? "PN1"
            : type === "onedoc"
            ? "DOC1"
            : this.state.payment_mode,
        test_list: JSON.stringify(this.state.test_list),
      },
      (isOk, res) => {
        Toast.hide();
        if (isOk) {
          if (this.state.payment_mode === "CC1") {
            console.log(res);
            setCache("hs_payInfo", {
              transaction_id: res.transaction_id,
              packageId: this.state.package_id,
              totalAmount: total,
            });
            setCache("hs_payInfoState", JSON.stringify(this.state));
            this.loadingToast();
            window.location.href =
              res.payment_request.ocbc_submit_url +
              "?" +
              res.payment_request.param_query_string;
          } else if (type === "paynow") {
            let { param } = res.payment_request;
            setCache("hs_payInfo", {
              transaction_id: res.transaction_id,
              packageId: this.state.package_id,
              totalAmount: total,
            });
            setCache(
              "payNowInfo",
              JSON.stringify({
                total: param?.QR_CODE?.amount,
                refNo: param?.REFERENCE_NO,
                recipient: param?.recipient,
                paymentDueData: param?.QR_CODE?.expiry,
                qrcode: param?.QR_CODE?.qr_code,
                id: res.transaction_id,
                backPath: "/HSSuccess/",
              })
            );
            this.props.history.push({ pathname: `/paynow` });
          } else if (type === "onedoc") {
            setCache("hs_payInfo", {
              transaction_id: res.transaction_id,
              packageId: this.state.package_id,
              totalAmount: total,
            });
            setCache("oneDocInfo", {
              backPath: "/HSSuccess/",
              id: res.transaction_id,
              payment_request: res.payment_request,
            });
            this.props.history.push({ pathname: `/1doc` });
          } else {
            this.props.history.push({
              pathname: "/programsSuccess/",
              state: { type: "HS", total: total },
            });
          }
        } else {
          this.props.history.push({
            pathname: "/paymentFail",
            state: { type: "HS", id: this.state.package_id },
          });
        }
      }
    );
  };
  beforePayPro = (type) => {
    console.log("type===>", type);
    if (type) {
      this.setState(
        {
          payment_mode: type,
        },
        () => {
          this.paymentPro(type);
        }
      );
    } else {
      this.paymentPro(type);
    }
  };
  paymentPro = (type) => {
    this.setState({
      loading: true,
    });

    if (this.state?.type === "HS") {
      this.hsPay(type);
    } else if (this.state.module_name === "GE") {
      this.loadingToast();
      AuthGuard.creatGE(
        {
          packages_id: this.state.program_id,
          total_money: this.state.totalAmount,
          coupon_id: this.state.couponId,
        },
        (isOk, res) => {
          this.setState({
            loading: false,
          });
          removeCache("proInfo");
          if (isOk) {
            let transaction_id = res.transaction.id;
            let { param } = res.payment_request;
            if (this.state.totalAmount === 0.0) {
              removeCache("ge_transaction");
              setCache("ge_transaction", { transaction_id: transaction_id });
              setCache("geInfo", {
                totalAmount: this.state.totalAmount,
                ge_id: this.state.packages_id,
              });
              this.props.history.push({ pathname: "/gESuccess/" });
            }
            setCache("pro_ge_info", {
              transaction_id: transaction_id,
              totalAmount: this.state.totalAmount,
              program_id: this.state.program_id,
              is_share_personal_data: this.state.is_share_personal_data,
              is_contacted_by_ge: this.state.is_contacted_by_ge,
            });
            if (type === "paynow") {
              setCache(
                "payNowInfo",
                JSON.stringify({
                  total: param?.QR_CODE?.amount,
                  refNo: param?.REFERENCE_NO,
                  recipient: param?.recipient,
                  paymentDueData: param?.QR_CODE?.expiry,
                  qrcode: param?.QR_CODE?.qr_code,
                  id: transaction_id,
                  backPath: "/gESuccess",
                })
              );
              this.props.history.push({ pathname: `/paynow` });
            } else if (type === "onedoc") {
              setCache("oneDocInfo", {
                backPath: "/gESuccess",
                id: res.transaction_id,
                payment_request: res.payment_request,
              });
              this.props.history.push({ pathname: `/1doc` });
            } else {
              window.location.href =
                res.payment_request.ocbc_submit_url +
                "?" +
                res.payment_request.param_query_string;
            }
          } else {
            setCache("geInfo", {
              ge_id: this.state.packages_id,
              is_share_personal_data: this.state.is_share_personal_data,
              is_contacted_by_ge: this.state.is_contacted_by_ge,
            });
            this.props.history.push({ pathname: "/gEFail/" });
          }
          Toast.hide();
        }
      );
    } else {
      if (this.state.totalAmount === 0.0) {
        this.loadingToast();
        AuthGuard.creatPro(
          {
            x: this.state.program_code,
            // program_code: this.state.program_code,
            total: this.state.totalAmount,
            coupon_id: this.state.couponId,
          },
          (isOk, res) => {
            console.log(isOk, res);
            this.setState({
              loading: false,
            });
            if (isOk) {
              this.clearCache();
              setCache("pro_info", {
                program_image: this.state.program_img,
                programs_name: this.state.programs_name,
              });
              this.props.history.push({ pathname: "/programsSuccess/" });
            } else {
              setCache("pro_info", { program_code: this.state.program_code });
              this.props.history.push({ pathname: "/paymentFail/" });
            }
            removeCache("proInfo");
            Toast.hide();
          }
        );
      } else {
        this.loadingToast();
        AuthGuard.creatPro(
          {
            x: this.state.program_code,
            // program_code: this.state.program_code,
            total: this.state.totalAmount,
            coupon_id: this.state.couponId,
            paymentMode:
              type === "paynow" ? "PN1" : type === "onedoc" ? "DOC1" : "",
          },
          (isOk, res) => {
            console.log(isOk, res);
            this.setState({
              loading: false,
            });
            if (isOk) {
              let transaction_id = res.transaction.id;
              let { param } = res.paymentRequest;
              setCache("pro_info", {
                transaction_id: transaction_id,
                program_code: this.state.program_code,
                totalAmount: this.state.totalAmount,
                currency_code: this.state.teleconsultation_currency_code,
              });
              if (type === "paynow") {
                setCache(
                  "payNowInfo",
                  JSON.stringify({
                    total: param?.QR_CODE?.amount,
                    refNo: param?.REFERENCE_NO,
                    recipient: param?.recipient,
                    paymentDueData: param?.QR_CODE?.expiry,
                    qrcode: param?.QR_CODE?.qr_code,
                    id: transaction_id,
                    backPath: "/paymentSuccess",
                  })
                );
                this.props.history.push({ pathname: `/paynow` });
              } else if (type === "onedoc") {
                setCache("oneDocInfo", {
                  backPath: "/paymentSuccess",
                  id: res.transaction_id,
                  payment_request: res.paymentRequest,
                });
                this.props.history.push({ pathname: `/1doc` });
              } else {
                window.location.href =
                  res.paymentRequest.ocbc_submit_url +
                  "?" +
                  res.paymentRequest.param_query_string;
              }
            }
            removeCache("proInfo");
            Toast.hide();
          }
        );
      }
    }
  };

  paymentOnSite = () => {
    this.setState(
      {
        payment_mode: "CC4",
      },
      () => {
        this.hsPay();
      }
    );
  };

  clearCache = () => {
    removeCache("selectPaymentCouponId");
  };

  goBack = () => {
    if (this.state.module_name === "GE") {
      this.props.history.push({
        pathname:
          "/geCheckOutPage/" +
          this.state.program_id +
          "/" +
          this.state.is_share_personal_data +
          "/" +
          this.state.is_contacted_by_ge,
      });
    } else if (this.state?.type === "HS") {
      this.props.history.push({
        pathname: "/bookHSWithClinic/" + this.state.package_id,
      });
    } else {
      this.props.history.push({
        pathname: "/checkOutPage/" + this.state.program_code,
      });
    }
  };

  render() {
    return (
      <div className="Paymet-Page payment-title">
        {!isBrowser ? (
          <NavBar
            mode="light"
            icon={<Icon type="left" color="#EB5DA6" size="lg" />}
            onLeftClick={() => this.goBack()}
          ></NavBar>
        ) : (
          ""
        )}
        <WingBlank size="lg">
          <Flex className="content">
            <Flex.Item>
              <WingBlank size="sm">
                <WhiteSpace size="md" />
                <Flex>
                  <Flex.Item>
                    <WingBlank>
                      <div className="page-title header-title">Payment</div>
                    </WingBlank>
                  </Flex.Item>
                </Flex>
              </WingBlank>
              <WingBlank>
                <Flex>
                  <Flex.Item>
                    <WhiteSpace size="md" />
                    <div className="payment-title2">Total Amount Payable:</div>
                    <WhiteSpace size="md" />
                    <div className="payment-price-amount">
                      ${_.toNumber(this.state.totalAmount).toFixed(2)}
                    </div>
                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />
                    <div className="payment-title2 font-1">
                      Select Payment Method:
                    </div>
                    <WhiteSpace size="lg" />
                    <ul className="paymentType">
                      <li>
                        <Button
                          className="outline-pink-btn paymentTypeItem"
                          loading={this.state.loading}
                          disabled={this.state.disabled}
                          onClick={() => this.beforePayPro()}
                          style={{ textAlign: "left" }}
                        >
                          <img
                            src={IconCreditCard}
                            className="paymentTypeIcon"
                            alt="credit-debit card"
                          />
                          <span style={{ marginLeft: "0.5rem" }}>
                            Credit / Debit Card
                          </span>
                        </Button>
                      </li>
                      <WhiteSpace size="md" />
                      <WhiteSpace size="md" />
                      {this.state?.type === "HS" ? (
                        <li>
                          {this.state.location_type === "offsite" ? null : (
                            <Button
                              className="outline-pink-btn paymentTypeItem"
                              loading={this.state.payOnSiteLoading}
                              disabled={this.state.disabled}
                              onClick={this.paymentOnSite}
                              style={{ textAlign: "left" }}
                            >
                              <img
                                src={IconPayOnsite}
                                className="paymentTypeIcon"
                                alt="credit-debit card"
                              />
                              <span style={{ marginLeft: "0.5rem" }}>
                                Pay On-site
                              </span>
                            </Button>
                          )}
                          <WhiteSpace size="md" />
                          <WhiteSpace size="md" />
                        </li>
                      ) : null}
                      {isMobile && (
                        <li>
                          <Button
                            className="outline-pink-btn paymentTypeItem"
                            onClick={() => this.beforePayPro("paynow")}
                            loading={this.state.loading}
                            disabled={this.state.disabled}
                            style={{ textAlign: "left" }}
                          >
                            <img
                              src={IconPayNow}
                              className="paymentTypeIcon"
                              alt="credit-debit card"
                            />
                            <span style={{ marginLeft: "0.5rem" }}>PayNow</span>
                          </Button>
                        </li>
                      )}
                      {/* {
                                                isMobile &&
                                                <>
                                                    <WhiteSpace size="md" /><WhiteSpace size="md" />
                                                    <li>
                                                        <Button
                                                            className="outline-pink-btn paymentTypeItem"
                                                            onClick={() => this.beforePayPro('onedoc')}
                                                            loading={this.state.loading}
                                                            disabled={this.state.disabled}
                                                            style={{ textAlign: 'left' }}
                                                        >
                                                            <img src={Icon1docwallet} className="paymentTypeIcon" alt="credit-debit card" />
                                                            <span style={{ marginLeft: '0.5rem' }}>1doc Wallet</span>
                                                        </Button>
                                                    </li>
                                                </>
                                            } */}
                    </ul>
                    <WhiteSpace size="md" />
                  </Flex.Item>
                </Flex>
              </WingBlank>
            </Flex.Item>
          </Flex>
        </WingBlank>
      </div>
    );
  }
}

export default ProgramsPayment;
