import React, { Component } from 'react';
import { Flex, WhiteSpace, NavBar, WingBlank, Toast, Button, Icon, InputItem } from 'antd-mobile';
import Flag from '../images/icons/iconCreditcard.svg'
import AuthGuard from '../services/AuthGuard';
import { setCache, isEmail, removeCache } from '../commons/Helpers';

class LoginByEmail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            labelDisplay: 'none',
            nextBtnDisplay: 'none',
            errorTxtDisplay: 'none',
            errorMsg:'Your phone is not registered yet, please login with email',
            otpType: 'EMAIL',
            email: ''
        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = '#FFF';
    }

    inputOnFocus = (e) => {

        this.setState({
            labelDisplay: 'block'
        })
    }
    inputOnBlur = (value) => {

        if (value.trim().length > 0) {
            this.setState({
                nextBtnDisplay: 'block',
                labelDisplay: 'block',
            })
        } else {
            this.setState({
                labelDisplay: 'none',
                nextBtnDisplay: 'none'
            })
        }
    }

    onChange = (value) => {
        this.setState({
            email: value
        })
    }

    loadingToast() {
        Toast.loading('Loading...', 30, () => {
            console.log('Load complete !!!');
        });
    }

    sendOtp = () => {
        if (isEmail(this.state.email)){
            this.loadingToast();
            AuthGuard.loginSendOtp({
                otpType: this.state.otpType,
                username: this.state.email
            }
                , (isOk, res) => {
                    if (isOk) {
                        let cacheObj = {
                            fromPage: '/loginByEmail',
                            type: 'email',
                            dialingCode: this.state.dialingCode,
                            username: this.state.email,
                        }
                        removeCache('verifyOptFor');
                        setCache('verifyOptFor', cacheObj)
                        this.props.history.push('/VerifyOTP/')
                    } else {
                        //6615 Your phone is not registered yet, please login with email
                        this.setState({
                            errorTxtDisplay: "block",
                            errorMsg: res.message
                        })
                    }
                    Toast.hide();
                });
        }else{
            this.setState({
                errorTxtDisplay: "block",
                errorMsg: 'Invalid Email Format!'
            })
        }
    }

    goback = ()=>{
        this.props.history.push('/login')
    }

    render() {

        return (
            <div className="Register-Page h5-Page login-Page">
                <NavBar
                    mode="light"
                    icon={<Icon type="left" color="#EB5DA6" size="lg"/>}
                    onLeftClick={this.goback}
                ></NavBar>
                <WingBlank>
                    <WhiteSpace size="lg" />
                    <Flex direction="column" justify="start" align="start">
                        <Flex.Item>
                            <div className="page-sub-title">Login</div>
                        </Flex.Item>
                    </Flex>
                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />
                    <Flex justify="end" align="end" className="loginWithMobile">
                        <Flex.Item>
                            <span className="flGrey" style={{ display: this.state.labelDisplay }}>Email</span>
                            <InputItem
                                className="inputField"
                                placeholder='Email'
                                clear
                                value={this.state.email}
                                name="email"
                                onBlur={this.inputOnBlur}
                                onFocus={this.inputOnFocus}
                                onChange={this.onChange}
                            />
                        </Flex.Item>
                    </Flex>
                    <WhiteSpace size="md" />
                    <Flex.Item className="errorMsg" style={{ display: this.state.errorTxtDisplay }}>
                        <p>{this.state.errorMsg}</p>
                    </Flex.Item>
                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />
                    <Flex.Item>
                        <Button
                            style={{ border: ' 1px solid #EB5DA6', color: '#EB5DA6'}}
                            onClick={this.sendOtp}
                        >
                            Next
                            </Button>
                    </Flex.Item>

                </WingBlank>
            </div>
        );
    }
}

export default LoginByEmail;