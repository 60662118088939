import React, { Component } from 'react';
import { Flex, WhiteSpace, WingBlank, Toast, Button, Modal, NavBar, Icon } from 'antd-mobile';
import HVLogo from '../images/icons/IconHeartVoiceLong.svg'
import IconCreditCard from '../images/icons/IconCard.png'
import IconActivewallet from '../images/icons/iconActivewallet.svg'
import IconPayOnsite from '../images/icons/IconPayOnsite.png'
import IconPayNow from '../images/icons/IconPayNow.png'
import Icon1docwallet from '../images/icons/Icon1docwallet.png'
import AuthGuard from '../services/AuthGuard'
import { setCache, getCache, removeCache, currencyType } from '../commons/Helpers'
import {
    isBrowser,
    isMobile
} from "react-device-detect";

class Payment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            billID: '',
            loading: false,
            total: 0.00,
            totalAmount: 0.00,
            recipientAddressId: '',
            deliveryServiceId: '',
            request_url: '',
            disabled: false,
            totalMoney: '',
            paymentMode: '',
            couponId: ''
        };

        console.log(props)
    }
    componentDidMount() {
        let self = this;
        let { id } = this.props.match.params;
        let billInfo = getCache('billInfo')

        if (billInfo) {
            billInfo = JSON.parse(billInfo);
            this.setState({
                ...billInfo
            });
        }
        if (id) {
            this.setState({
                billID: id
            }, () => {
                self.userGetBill()
            });
        }
        if (getCache("hs_payInfoState")) {
            this.setState({
                ...this.state,
                ...JSON.parse(getCache("hs_payInfoState")),
                loading:false
            })
            removeCache('hs_payInfoState');
        }
        removeCache('bill_info');
        removeCache('pro_ge_info');
        removeCache('geInfo');
    }

    userGetBill() {
        let self = this;
        AuthGuard.userCheckBill({
            userQueueId: this.state.billID
        }, (isOk, res) => {
            console.log(res)
            if (isOk) {
                self.setState({
                    ...res,
                    disabled: res.status === 'completed' ? true : false
                })
            } else {
                Modal.alert('Notice', res.message, [
                    { text: 'OK', onPress: () => console.log('ok') },
                ]);
                this.setState({
                    disabled: true
                })
            }
        });
    }

    loadingToast() {
        Toast.loading('Loading...', 30, () => {
            console.log('Load complete !!!');
        });
    }

    payBill = (type = 'card') => {

        if (type == 'card') {
            this.paymentBill()
            return;
        }

        if (type == 'paynow') {
            this.setState({
                loading: true
            });
            this.loadingToast()
            AuthGuard.noFeePay({
                userQueueId: this.state.billID,
                recipientAddressId: this.state.recipientAddressId,
                deliveryServiceId: this.state.deliveryServiceId,
                totalMoney: this.state.totalAmount.toFixed(2),
                couponId: this.state.couponId,
                deleteMedicationItemIds: this.state.deleteMedicationItemIds ? this.state.deleteMedicationItemIds : '',
                paymentMode: 'PN1'
            }, (isOk, res) => {
                // removeCache('billInfo');
                console.log(isOk, res)
                this.setState({
                    loading: false
                });
                if (isOk) {
                    let { param } = res.paymentRequest
                    let transaction_id = res.transaction.id;
                    setCache('bill_info', { transaction_id: transaction_id, userQueueId: this.state.billID })
                    setCache('payNowInfo', JSON.stringify({
                        total: param?.QR_CODE?.amount,
                        refNo: param?.REFERENCE_NO,
                        recipient: param?.recipient,
                        paymentDueData: param?.QR_CODE?.expiry,
                        qrcode: param?.QR_CODE?.qr_code,
                        id: transaction_id,
                        backPath: '/success',
                        backToWa:true
                    }))
                    this.props.history.push({ pathname: `/paynow` });
                } else {
                    setCache('bill_info', { transaction_id: '', userQueueId: this.state.billID })
                    this.props.history.push({ pathname: "/fail" });
                }
                removeCache('billInfo')
                Toast.hide()
            })
            return;
        }

        if (type == '1docwallet') {
            this.setState({
                loading: true
            });
            this.loadingToast()
            AuthGuard.noFeePay({
                userQueueId: this.state.billID,
                recipientAddressId: this.state.recipientAddressId,
                deliveryServiceId: this.state.deliveryServiceId,
                totalMoney: this.state.totalAmount.toFixed(2),
                couponId: this.state.couponId,
                deleteMedicationItemIds: this.state.deleteMedicationItemIds ? this.state.deleteMedicationItemIds : '',
                paymentMode: 'DOC1'
            }, (isOk, res) => {
                // removeCache('billInfo');
                console.log(isOk, res)
                this.setState({
                    loading: false
                });
                if (isOk) {
                    let { param } = res.paymentRequest
                    let transaction_id = res.transaction.id;
                    setCache('bill_info', { transaction_id: transaction_id, userQueueId: this.state.billID })
                    setCache('oneDocInfo', JSON.stringify({
                        id: transaction_id,
                        backPath: '/success',
                        payment_request: res.paymentRequest
                    }))
                    this.props.history.push({ pathname: `/1doc` });
                } else {
                    setCache('bill_info', { transaction_id: '', userQueueId: this.state.billID })
                    this.props.history.push({ pathname: "/fail" });
                }
                removeCache('billInfo')
                Toast.hide()
            })
            return;
        }
    }

    paymentBill() {
        this.setState({
            loading: true
        });

        console.log(this.state.deleteMedicationItemIds)
        // if (this.state.totalAmount == 0.00) {
        this.loadingToast()
        AuthGuard.noFeePay({
            userQueueId: this.state.billID,
            recipientAddressId: this.state.recipientAddressId,
            deliveryServiceId: this.state.deliveryServiceId,
            totalMoney: this.state.totalAmount.toFixed(2),
            couponId: this.state.couponId,
            deleteMedicationItemIds: this.state.deleteMedicationItemIds ? this.state.deleteMedicationItemIds : ''
        }, (isOk, res) => {
            // removeCache('billInfo');
            console.log(isOk, res)
            this.setState({
                loading: false
            });
            if (isOk) {
                Toast.loading('Loading...',999);
                let transaction_id = res.transaction.id;
                setCache('bill_info', { transaction_id: transaction_id, userQueueId: this.state.billID })
                setCache("hs_payInfoState", JSON.stringify(this.state));
                window.location.href = res.paymentRequest.ocbc_submit_url + '?' + res.paymentRequest.param_query_string

                // this.clearCache()
                // this.props.history.push({ pathname: "/success/" });
            } else {
                // removeCache('bill_info');
                setCache('bill_info', { transaction_id: '', userQueueId: this.state.billID })
                this.props.history.push({ pathname: "/fail" });
                Toast.hide()
            }
            removeCache('billInfo')

            // console.log(res.paymentRequest, res.paymentRequest)
            // console.log(res.paymentRequest.ocbc_submit_url + '?' + res.paymentRequest.param_query_string)

        })
        // } else {
        //     this.loadingToast()
        //     AuthGuard.creatBill({
        //         userQueueId: this.state.billID,
        //         recipientAddressId: this.state.recipientAddressId,
        //         deliveryServiceId: this.state.deliveryServiceId,
        //         paymentMode:this.state.paymentMode,
        //         couponId:this.state.couponId
        //     }, (isOk, res) => {
        //         console.log(isOk, res)
        //         this.setState({
        //             loading: false
        //         });
        //         if (isOk) {
        //             let transaction_id = res.transaction.id;
        //             setCache('bill_info', { transaction_id: transaction_id, userQueueId: this.state.billID })
        //             window.location.href = res.paymentRequest.ocbc_submit_url + '?' + res.paymentRequest.param_query_string
        //         }
        //         removeCache('billInfo')
        //         Toast.hide()
        //     })
        // }
        // window.location.href = this.state.request_url;
    }

    clearCache = () => {
        removeCache('selectedDelivery')
        removeCache('selectCouponId')
    }

    goBack = () => {
        this.props.history.push({ pathname: "/bill/" + this.state.billID });
    }

    render() {

        return (
            <div className="Paymet-Page pay-page">
                {!isBrowser ? <NavBar
                    mode="light"
                    icon={<Icon type="left" color="#EB5DA6" size="lg" />}
                    onLeftClick={() => this.goBack()}
                ></NavBar> : ''}
                <WingBlank size="lg">
                    <Flex className="content">
                        <Flex.Item>
                            <WingBlank size="sm">
                                <WhiteSpace size="md" />
                                <Flex>
                                    <Flex.Item>
                                        <img src={HVLogo} className="HVLogo" alt="HeartVoice - Logo" />
                                    </Flex.Item>
                                </Flex>
                            </WingBlank>
                            <WingBlank>
                                <Flex>
                                    <Flex.Item>
                                        <WhiteSpace size="md" />
                                        <div className="paymentTitle">Total Amount Payable:</div>
                                        <WhiteSpace size="md" />
                                        <div className="paymentPriceAmount">
                                            ${currencyType(this.state.totalAmount)}
                                        </div>
                                        <WhiteSpace size="lg" />
                                        <WhiteSpace size="lg" />
                                        <div className="paymentTitle">Select Payment Method:</div>
                                        <ul className="paymentType">
                                            <li>
                                                <Button
                                                    className="paymentTypeItem"
                                                    onClick={() => this.payBill('card')}
                                                    loading={this.state.loading}
                                                    disabled={this.state.disabled}
                                                >
                                                    <img src={IconCreditCard} className="paymentTypeIcon" alt="credit-debit card" />
                                                    <span>Credit / Debit Card</span>
                                                </Button>
                                            </li>
                                            <li>
                                                <Button
                                                    className="paymentTypeItem"
                                                    onClick={() => this.payBill('paynow')}
                                                    loading={this.state.loading}
                                                    disabled={this.state.disabled}
                                                >
                                                    <img src={IconPayNow} className="paymentTypeIcon" alt="credit-debit card" />
                                                    <span>PayNow</span>
                                                </Button>
                                            </li>
                                            {/* {
                                                isMobile &&
                                                <li>
                                                    <Button
                                                        className="paymentTypeItem"
                                                        onClick={() => this.payBill('1docwallet')}
                                                        loading={this.state.loading}
                                                        disabled={this.state.disabled}
                                                    >
                                                        <img src={Icon1docwallet} className="paymentTypeIcon" alt="credit-debit card" />
                                                        <span>1doc Wallet</span>
                                                    </Button>
                                                </li>
                                            } */}
                                            {/* <li>
                                            <Button
                                                disabled
                                                className="paymentTypeItem itemDisable"
                                            >
                                                <img src={IconActivewallet} className="paymentTypeIcon" alt="credit-debit card" />
                                                <div>ActiveWallet</div>
                                            </Button>
                                        </li>
                                        <li>
                                            <Button
                                                disabled
                                                className="paymentTypeItem itemDisable"
                                            >
                                                <img src={IconSlidewallet} className="paymentTypeIcon" alt="credit-debit card" />
                                                <div>SLIDE Wallet</div>
                                            </Button>
                                        </li> */}
                                        </ul>
                                        <WhiteSpace size="md" />
                                    </Flex.Item>
                                </Flex>

                            </WingBlank>
                        </Flex.Item>
                    </Flex>
                </WingBlank>
            </div>
        );
    }
}

export default Payment;