import API from "./API";
import { RespondCode, IP_WHITELIST } from "../commons/Constants";
import {
  get_local_cache,
  set_local_cache,
  remove_local_cache,
  setCache,
  getCache,
  removeCache,
  isArray,
  isInvalid,
} from "../commons/Helpers";
import { Toast } from "antd-mobile";
import publicIp from "public-ip";
const Api = new API();
// const SomeComponent = withRouter(props => <AuthGuard {...props} />);
class AuthGuard {
  isAuthenticated = null;
  videoCallInfo = {
    canConnect: false,
  };
  localIpV4 = "";
  constructor(prop) {}
  listCountrys = [];

  getCountry({}, callback = () => {}) {
    if (isArray(this.listCountrys) && this.listCountrys.length > 0) {
      callback(true);
    } else {
      this.getCountryList({ limit: 300, page: 1 }, (isOk, res) => {
        if (isOk) {
          this.listCountrys = !isInvalid(res) ? res : [];
          callback(true);
        } else {
          this.listCountrys = [];
          callback(true);
        }
      });
    }
  }

  authenticate(auth, callback = () => {}) {
    this.isAuthenticated = auth;
    setTimeout(() => {
      callback();
    }, 100);
  }

  SetAuthenticated(AuthInfo) {
    if (AuthInfo) {
      set_local_cache("UserAuth", AuthInfo);
    } else {
      removeCache("UserAuth");
    }
    this.authenticate(AuthInfo);
  }

  /**
   * Check authenticate
   *
   * @memberof AuthGuard
   */

  setMedication(_item, _medication_fee, _subTotalAmount) {
    if (_item && _item.length > 0) {
      this.getMedicationList = _item;
      this.medicationFee = _medication_fee;
      this.getSubTotalFee = _subTotalAmount;
    }
  }

  checkAuthenticated(callback = () => {}) {
    if (get_local_cache("UserAuth")) {
      let AuthInfo = get_local_cache("UserAuth");
      if (AuthInfo) {
        this.SetAuthenticated(AuthInfo);
        callback(true);
      } else {
        this.SetAuthenticated("");
        callback(false);
      }
    } else {
      this.SetAuthenticated("");
      callback(false);
    }
  }

  setLogout() {
    this.removeCaches();
    window.location.href = "/login";
    return this.authenticate(null);
  }

  removeCaches() {
    remove_local_cache("UserAuth");
    removeCache("UserAuth");
    removeCache("patientInfo");
    removeCache("verifyOptFor");
  }

  logout(callback = (isOk, res) => {}) {
    let self = this;
    let hasUrl = window.location.pathname + window.location.search;
    setCache("redirectUrl", hasUrl);
    // Api.logout().then((res) => {

    // }).catch((res) => {
    //     // console.log(res);
    //     callback(false, res);
    // });
    setTimeout(() => {
      self.setLogout();
    }, 100);
  }

  signUp(callback = (isOk, res) => {}) {
    this.removeCaches();
    window.location.href = "/myinfo";
    return this.authenticate(null);
  }

  async checkIpWhiteList() {
    let ips = IP_WHITELIST || "";
    let valid_ip = true;
    if (ips) {
      if (!this.localIpV4) {
        this.localIpV4 = await publicIp.v4();
      }
      // let ipv6 = await publicIp.v6();
      let ipsArr = ips.split(",");
      valid_ip = ipsArr.includes(this.localIpV4);
    } else {
      this.localIpV4 = "";
    }
    return valid_ip;
  }

  userCheckBill(data, callback = (isOk, res) => {}) {
    Api.userCheckBill({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.userCheckBillSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  verifyUser(data, callback = (isOk, res) => {}) {
    Api.verifyUser({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.verifyUserSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getPayBackData(data, callback = (isOk, res) => {}) {
    Api.getPayBackData({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.payCheckoutSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getHsPayBackData(data, callback = (isOk, res) => {}) {
    Api.getHsPayBackData({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.payHSSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  creatBill(data, callback = (isOk, res) => {}) {
    Api.creatBill({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.creatBillSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  signUpSendOtp(data, callback = (isOk, res) => {}) {
    Api.singUpSendOtp({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.signUpSendOtpSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  signUpVerifyOtp(data, callback = (isOk, res) => {}) {
    Api.signUpVerifyOtp({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.signUpVerifyOtpSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  checkLogin() {
    if (get_local_cache("UserAuth")) {
      return true;
    } else {
      return false;
    }
    // await this.checkAuthenticated((res)=>{
    //     return res
    // })
  }

  loginSendOtp(data, callback = (isOk, res) => {}) {
    Api.loginSendOtp({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.loginSendOtpSuccess) {
          callback(true, data);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  loginVerifyOtp(data, callback = (isOk, res) => {}) {
    let self = this;
    Api.loginVerifyOtp({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.loginVerifyOtpSuccess) {
          let { token } = result;
          self.SetAuthenticated(token);
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getNationalityList(data, callback = (isOk, res) => {}) {
    Api.nationalityList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.nationalityListSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  // getProvinceList(data, callback = (isOk, res) => { }) {
  //     Api.getProvinceList({
  //         data
  //     })
  //         .then((res) => {
  //             const { data } = res;
  //             const { result, message } = data;
  //             if (data.status_code === RespondCode.getProvinceListSuccess) {
  //                 callback(true, result);
  //             } else {
  //                 callback(false, message);
  //             }
  //         })
  //         .catch((res) => {
  //             callback(false, res);
  //         });
  // }

  // getCitys(data, callback = (isOk, res) => { }) {
  //     Api.getCityList({
  //         data
  //     })
  //         .then((res) => {
  //             const { data } = res;
  //             const { result, message } = data;
  //             if (data.status_code === RespondCode.getCityListSuccess) {
  //                 callback(true, result);
  //             } else {
  //                 callback(false, message);
  //             }
  //         })
  //         .catch((res) => {
  //             callback(false, res);
  //         });
  // }

  getCountryList(data, callback = (isOk, res) => {}) {
    Api.countryList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.countryListSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  completeProfile(data, callback = (isOk, res) => {}) {
    Api.completeProfile({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.completeProfileSuccess) {
          Toast.success(message, 1);
          callback(true, result);
        } else {
          Toast.fail(message, 2);
          callback(false, message);
        }
      })
      .catch((data) => {
        callback(false, data);
      });
  }

  signupNotie(data, callback = (isOk, res) => {}) {
    Api.signupNotie({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.signupNotieSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  addAddress(data, callback = (isOk, res) => {}) {
    Api.addAddress({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.addAddressSuccess) {
          Toast.success(message, 1);
          callback(true, result);
        } else {
          Toast.fail(message, 2);
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  noFeePay(data, callback = (isOk, res) => {}) {
    Api.noFeePay({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (
          data.status_code === RespondCode.noFeePaySuccess ||
          data.status_code === 8236
        ) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getSpecialty(data, callback = (isOk, res) => {}) {
    Api.getSpecialty({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getspecialtySuccess) {
          console.log("getspecialtySuccess true");
        } else {
          console.log("not");
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  doctorDetails(data, callback = (isOk, res) => {}) {
    Api.doctorDetails({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.doctorDetailsSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getspecialtyList(data, callback = (isOk, res) => {}) {
    Api.getspecialtyList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getspecialtyListSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getprogramList(data, callback = (isOk, res) => {}) {
    Api.getprogramList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getProgramListSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }
  getProgramTokenList(data, callback = (isOk, res) => {}) {
    Api.getProgramTokenList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getProgramListSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }
  coachSearch(data, callback = (isOk, res) => {}) {
    Api.coachSearch({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.coachSearchSuccess) {
          let res = {
            result: result,
            total: data.total,
          };
          callback(true, res);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }
  serchLocalDoctor(data, callback = (isOk, res) => {}) {
    Api.serchLocalDoctor({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.serchLocalDoctorSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }
  getDeliveryOption(data, callback = (isOk, res) => {}) {
    Api.deliveryOption({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.deliveryOptionSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  applyPromocode(data, callback = (isOk, res) => {}) {
    Api.applyPromocode({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.applyPromocodeSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getPromoCodeList(data, callback = (isOk, res) => {}) {
    Api.getPromoCodeList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getPromoCodeListSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getPromoCodeDetail(data, callback = (isOk, res) => {}) {
    Api.getPromoCodeDetail({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getPromoCodeDetailSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getSingPassUrl(data, callback = (isOk, res) => {}) {
    Api.getSingPassUrl({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getSingPassUrlSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getSingPassInfo(data, callback = (isOk, res) => {}) {
    Api.getSingPassInfo({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getSingPassInfoSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getProvinceList(data, callback = (isOk, res) => {}) {
    Api.provinceList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.provinceListSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getCityList(data, callback = (isOk, res) => {}) {
    Api.cityList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.cityListSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getServiceCountry(data, callback = (isOk, res) => {}) {
    Api.getServiceCountry({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getServiceCountrySuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getSGRegion(data, callback = (isOk, res) => {}) {
    Api.getSGRegion(data)
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getSGRegionSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  savedr(data, callback = (isOk, res) => {}) {
    Api.savedr({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (
          data.status_code === RespondCode.savedrSuccess1 ||
          RespondCode.savedrSuccess2
        ) {
          Toast.success(message, 1);
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getNewToken(token) {
    return new Promise((resolve, reject) => {
      this.exchangeToken({ token: token }, () => {
        resolve();
      });
    });
  }

  exchangeToken(data, callback = (isOk, res) => {}) {
    Api.validateToken({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.validateTokenSuccess) {
          let { token } = result;
          this.SetAuthenticated(token);
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getAvaliableDate(data, callback = (isOk, res) => {}) {
    Api.getAvaliableDate({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getAvaliableDateSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getHSDate(data, callback = (isOk, res) => {}) {
    Api.getHSDate({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getHSDateSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  appointmentSchedule(data, callback = (isOk, res) => {}) {
    Api.appointmentSchedule({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.appointmentScheduleSuccess) {
          callback(true, message);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getMyinfo(data, callback = (isOk, res) => {}) {
    Api.getMyinfo({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getMyinfoSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  sendOtp4update(data, callback = (isOk, res) => {}) {
    Api.sendOtp4update({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.sendOtp4updateSuccess) {
          callback(true, data);
        } else {
          callback(false, data);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  checkOtp4updte(data, callback = (isOk, res) => {}) {
    Api.checkOtp4updte({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.checkOtp4updteSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }
  sendOtp4Reg(data, callback = (isOk, res) => {}) {
    Api.sendOtp4Reg({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.sendOtp4RegSuccess) {
          callback(true, data);
        } else {
          callback(false, data);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  checkOtp4Reg(data, callback = (isOk, res) => {}) {
    Api.checkOtp4Reg({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.checkOtp4RegSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getGeoAdd(data, callback = (isOk, res) => {}) {
    Api.getGeoAdd({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getGeoAddSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  mobileInqueue(data, callback = (isOk, res) => {}) {
    Api.mobileInqueue({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.mobileInqueueSuccess) {
          callback(true, result);
        } else {
          callback(false, data);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  queueAssign(data, callback = (isOk, res) => {}) {
    Api.queueAssign({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.queueAssignSuccess) {
          callback(true, result);
        } else {
          callback(false, data);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  queueOneAssign(data, callback = (isOk, res) => {}) {
    Api.queueOneAssign({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.queueAssignSuccess) {
          callback(true, result);
        } else {
          callback(false, data);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getCouponPrice(data, callback = (isOk, res) => {}) {
    Api.getCouponPrice({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getCouponPriceSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }
  checkRegAdd(data, callback = (isOk, res) => {}) {
    Api.checkRegAdd({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.checkRegAddSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  updateHeight(data, callback = (isOk, res) => {}) {
    Api.updateHeight({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.updateHeightSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  addReferral(data, callback = (isOk, res) => {}) {
    Api.addReferral({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.addReferralSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((data) => {
        callback(false, data);
      });
  }

  requestCode(data, callback = (isOk, res) => {}) {
    Api.requestCode({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.requestCodeSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  joinPrograms(data, callback = (isOk, res) => {}) {
    Api.joinPrograms({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.joinProgramsMorethanSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res.message);
      });
  }
  getPromoCodeListV2(data, callback = (isOk, res) => {}) {
    Api.getPromoCodeListV2({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        console.log("cupon", res);
        if (data.status_code === RespondCode.getPromoCodeListSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }
  getPromoCodeDetailV2(data, callback = (isOk, res) => {}) {
    Api.getPromoCodeDetailV2({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getPromoCodeDetailSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }
  getProgramsCouponPrice(data, callback = (isOk, res) => {}) {
    Api.getProgramsCouponPrice({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getProgramsCouponPriceSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  creatPro(data, callback = (isOk, res) => {}) {
    Api.creatPro({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (
          data.status_code === RespondCode.creatProSuccess ||
          data.status_code === RespondCode.creatProSuccessV2
        ) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getPayProBackData(data, callback = (isOk, res) => {}) {
    Api.getPayProBackData({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.proPayCheckoutSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }
  acceptProgramsRequest(data, callback = (isOk, res) => {}) {
    Api.acceptProgramsRequest({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.acceptProgramsRequestSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getProgramsInfo(data, callback = (isOk, res) => {}) {
    Api.getProgramsInfo({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getProgramsInfoSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  acceptProgramsInfo(data, callback = (isOk, res) => {}) {
    Api.acceptProgramsInfo({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.acceptProgramsInfoSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  checkProfileComplete(data, callback = (isOk, res) => {}) {
    Api.checkProfileComplete({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.checkProfileCompleteSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  packagesFindDetail(data, callback = (isOk, res) => {}) {
    Api.packagesFindDetail({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.packagesFindDetailSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  calculateForTelemed(data, callback = (isOk, res) => {}) {
    Api.calculateForTelemed({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.calculateForTelemedSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  packagesCheckDetail(data, callback = (isOk, res) => {}) {
    Api.packagesCheckDetail({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.packagesCheckDetailSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  creatGE(data, callback = (isOk, res) => {}) {
    Api.creatGE({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.creatGESuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  marketPlaceDetailInfo(data, callback = (isOk, res) => {}) {
    Api.marketPlaceDetailInfo({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.marketPlaceDetailInfoSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  hasBought(data, callback = (isOk, res) => {}) {
    Api.hasBought({
      data,
    })
      .then((res) => {
        console.log(res);
        // 如果用户可以购买，返回 true；反之，返回 false；
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.hasBoughtSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        console.log(res);
        let { status_code, message } = res;
        if (![400, 403, 401].includes(status_code)) {
          callback(false, message);
        } else {
          this.logout();
          callback(false, "");
        }
      });
  }

  uploadTwilioLog(data, callback = (isOk, res) => {}) {
    Api.uploadTwilioLog({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.uploadTwilioLogSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getIdentificationTypeList(data, callback = (isOk, res) => {}) {
    Api.getIdentificationTypeList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getIdentificationTypeListSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  addDrugAllergy(data, callback = (isOk, res) => {}) {
    Api.addDrugAllergy({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.addDrugAllergySuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  SignUpSendOtp(data, callback = (isOk, res) => {}) {
    Api.SignUpSendOtp({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.SignUpSendOtpSuccess) {
          callback(true, data);
        } else {
          callback(false, data);
        }
      })
      .catch((data) => {
        callback(false, data);
      });
  }

  SignUpOtpSubmit(data, callback = (isOk, res) => {}) {
    Api.SignUpOtpSubmit({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.SignUpOtpSubmitSuccess) {
          callback(true, data);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  SignUpCompleteInfo(data, callback = (isOk, res) => {}) {
    Api.SignUpCompleteInfo({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.SignUpCompleteInfoSuccess) {
          callback(true, data);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res.message);
      });
  }

  SignUpMyinfoOtpSubmit(data, callback = (isOk, res) => {}) {
    Api.SignUpMyinfoOtpSubmit({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.SignUpMyinfoOtpSubmitSuccess) {
          callback(true, data);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  loginComOtpSubmit(data, callback = (isOk, res) => {}) {
    Api.loginComOtpSubmit({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.loginComOtpSubmitSuccess) {
          callback(true, data);
        } else {
          callback(false, message);
        }
      })
      .catch((data) => {
        callback(false, data);
      });
  }

  loginComSubmit(data, callback = (isOk, res) => {}) {
    Api.loginComSubmit({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.loginComSubmitSuccess) {
          callback(true, data);
        } else {
          callback(false, message);
        }
      })
      .catch((data) => {
        callback(false, data);
      });
  }

  notAddress(data, callback = (isOk, res) => {}) {
    Api.notAddress({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.notAddressSuccess) {
          let res = {
            result: result,
            total: data.total,
          };
          callback(true, res);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }
  checkIsExist(data, callback = (isOk, res) => {}) {
    Api.checkIsExist({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.checkIsExistSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }
  hsList(data, callback = (isOk, res) => {}) {
    Api.hsList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getHSListSuccess) {
          callback(true, result);
        } else {
          // callback(false, message); //没有package不弹框
        }
      })
      .catch((res) => {
        // callback(false, res);
      });
  }

  searchAddOns(data, callback = (isOk, res) => {}) {
    Api.searchAddOns({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.searchAddOnsSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  searchLocation(data, callback = (isOk, res) => {}) {
    Api.searchLocation({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.searchLocationSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  requestForHealthScreening(data, callback = (isOk, res) => {}) {
    Api.requestForHealthScreening({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.requestForHealthScreeningSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  checkoutScreening(data, callback = (isOk, res) => {}) {
    Api.checkoutScreening({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.checkoutScreeningSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  checkIDForHS(data, callback = (isOk, res) => {}) {
    Api.checkIDForHS({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.checkIDForHSSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  checkIDForCT(data, callback = (isOk, res) => {}) {
    Api.checkIDForCT({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.checkIDForCTSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  joinIDForHS(data, callback = (isOk, res) => {}) {
    Api.joinIDForHS({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.joinIDForHSSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getPackageInfo(data, callback = (isOk, res) => {}) {
    Api.getPackageInfo({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.joinIDForHSSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  findBillForHealthScreening(data, callback = (isOk, res) => {}) {
    Api.findBillForHealthScreening({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.findBillForHealthScreening) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  checkAbleBook(data, callback = (isOk, res) => {}) {
    Api.checkAbleBook({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.checkAbleBookSuccess) {
          callback(true, result);
        } else {
          callback(false, res);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getProfileInfo(data, callback = (isOk, res) => {}) {
    Api.getProfileInfo({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getProfileInfoSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getRaceAndRaceStatusList(data, callback = (isOk, res) => {}) {
    Api.getRaceAndRaceStatusList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getRaceAndRaceStatusListSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  telDoctorList(data, callback = (isOk, res) => {}) {
    Api.telDoctorList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.telDoctorListSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getMerchantProviderUUid(data, callback = (isOk, res) => {}) {
    Api.getMerchantProviderUUid({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getMerchantProviderUUidSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getProfileInfo(data, callback = (isOk, res) => {}) {
    Api.getProfileInfo({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getProfileInfoSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getMerchantProviderUUid(data, callback = (isOk, res) => {}) {
    Api.getMerchantProviderUUid({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getMerchantProviderUUidSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getCPQuestions(data, callback = (isOk, res) => {}) {
    Api.getCPQuestions({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getCPQuestionsSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  saveCPQuestions(data, callback = (isOk, res) => {}) {
    Api.saveCPQuestions({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.saveCPQuestionsSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getTransactionStatus(data, callback = (isOk, res) => {}) {
    Api.getTransactionStatus({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.userCheckBillSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getLastPending(data, callback = (isOk, res) => {}) {
    Api.getLastPending({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.lastPendingSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }
  getDeclarationFormDetail(data, callback = (isOk, res) => {}) {
    let self = this;
    Api.getDeclarationFormDetail({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getDeclarationFormDetailSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getOauthCode(data, callback = (isOk, res) => {}) {
    let self = this;
    Api.oauthAuthorize({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.oauthAuthorizeSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getMyReport(data, callback = (isOk, res) => {}) {
    let self = this;
    Api.getMyReport({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getMyReportSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getHealthScreenList(data, callback = (isOk, res) => {}) {
    Api.getHealthScreenList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getHealthScreenListSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getHSBookedEvnet(data, callback = (isOk, res) => {}) {
    Api.getHSBookedEvnet({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getHSBookedEvnetSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getScanInfo(data, callback = (isOk, res) => {}) {
    Api.getScanInfo({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getScanInfoSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  ScanUpload(data, callback = (isOk, res) => {}) {
    Api.ScanUpload({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.ScanUploadSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  checkVaildliUrl(data, callback = (isOk, res) => {}) {
    Api.CheckVaildliUrl({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === 11007 && result) {
          callback(true, message);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getPaymentModes(data, callback = (isOk, res) => {}) {
    Api.getPaymentModes({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getPaymentModesSuccess && result) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }
}

export default new AuthGuard();
