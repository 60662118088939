import React, { Component } from 'react';
import { Flex, Button, Modal, } from 'antd-mobile';
import AuthGuard from '../services/AuthGuard';
import HVLogo from '../images/icons/IconHeartVoiceLong.svg'
import url from 'url'

const alert = Modal.alert;
const showAlert = ({ msg }) => {
    const alertInstance = alert('Alert', msg, [
        // { text: 'Cancel', onPress: () => console.log('cancel'), style: 'default' },
        { text: 'OK', onPress: () => console.log('ok') },
    ]);
    setTimeout(() => {
        // 可以调用close方法以在外部close
        console.log('auto close');
        alertInstance.close();
    }, 500000);
};

class AcceptOrDeclien extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accept_id: '',
            decline_id: '',
            status: '',
            status_name: '',
            program_name: '',
            name: '',
            mobile_no: '',
            email: '',
            program_code: '',
            responded_by: '',
            loading: false,
            disabled: false,
        }
    }

    componentWillMount() {

    }

    componentDidMount() {
        let pro = this;
        let httpUrl = this.props.location.search;
        pro.setState({
            accept_id: url.parse(httpUrl, true).query.accept,
            decline_id: url.parse(httpUrl, true).query.decline,
        }, () => {
            let _accept = this.state.accept_id;
            let _decline = this.state.decline_id;
            if (_accept && _decline) {
                AuthGuard.acceptProgramsInfo({
                    hashed_params: _accept,
                }, (isOk, res) => {
                    console.log('Get info', res);
                    if (isOk) {
                        this.setState({
                            program_name: res.program_name,
                            name: res.name,
                            mobile_no: res.mobile,
                            email: res.email,
                            status: res.status,
                            program_code: res.program_code,
                            responded_by: res.responded_by,
                        }, () => {
                            let _status_name = '';
                            if (this.state.status === 'accept') {
                                _status_name = 'Accepted';
                            } else if (this.state.status === 'decline') {
                                _status_name = 'Declined';
                            } else {
                                _status_name = '';
                            }
                            this.setState({
                                status_name: _status_name,
                            });
                        });
                    } else {
                        showAlert({ msg: res?.message || "Oops! Get program request fail,Please select again!!!" })
                        console.log('Get info fail');
                    }
                })
            } else {
                console.log('无效数据');
            }
        });
    }

    accept = () => {
        this.setState({
            loading: true,
            disabled: true,
        })
        if (this.state.accept_id) {
            AuthGuard.acceptProgramsRequest({
                x: this.state.accept_id,
            }, (isOk, res) => {
                if (isOk) {
                    this.setState({
                        status: 'accept',
                        status_name: 'Accepted',
                        program_code: res.program_code,
                        loading: false,
                        disabled: false,
                    })
                } else {
                    console.log('accept fail', res);
                    this.setState({
                        loading: false,
                        disabled: false,
                    })
                }
            })
        } else {
            console.log('accept fail');
            this.setState({
                loading: false,
                disabled: false,
            })
        }
    }

    decline = () => {
        this.setState({
            loading: true,
            disabled: true,
        })
        if (this.state.decline_id) {
            AuthGuard.acceptProgramsRequest({
                x: this.state.decline_id,
            }, (isOk, res) => {
                if (isOk) {
                    this.setState({
                        status: 'decline',
                        status_name: 'Declined',
                        loading: false,
                        disabled: false,
                    })
                } else {
                    console.log('decline fail', res);
                    this.setState({
                        loading: false,
                        disabled: false,
                    })
                }
            })
        } else {
            console.log('decline fail');
            this.setState({
                loading: false,
                disabled: false,
            })
        }
    }

    render() {
        return (
            <div className="email-page">
                <div className="content">
                    <div className="email-content">
                        <div className="email-site">
                            <Flex className="eamil-img-border">
                                <Flex.Item><img src={HVLogo} className="HVLogo" alt="HeartVoice - Logo" /></Flex.Item>
                            </Flex>
                            <Flex className="email-title">
                                <Flex.Item>
                                    Program Request:
                                </Flex.Item>
                            </Flex>
                            <Flex className="eamil-sub-title">
                                <Flex.Item>
                                    A new patient has requested to join the monitoring program: <br /><b>{this.state.program_name || '-'}</b>
                                </Flex.Item>
                            </Flex>
                            <Flex className="eamil-sub-title">
                                <Flex.Item>
                                    Name: {this.state.name || '-'}<br />
                                    Mobile no.: {this.state.mobile_no || '-'}<br />
                                    Email Address: {this.state.email || '-'}<br />
                                    {this.state.status === 'accept' ?
                                        <div>Status: <span className="text-red">{this.state.status_name}</span><br />Responded by: <span className="text-red">{this.state.responded_by}</span></div>
                                        : ''
                                    }
                                    {this.state.status === 'decline' ?
                                        <div>Status: <span className="text-red">{this.state.status_name}</span><br />Responded by: <span className="text-red">{this.state.responded_by}</span></div>
                                        : ''
                                    }
                                </Flex.Item>
                            </Flex>
                            <Flex className="eamil-sub-title">
                                <Flex.Item>
                                    {this.state.status === 'pending' ? 'User will be added to the monitoring list once you accept.' : ''}
                                    {this.state.status === 'accept' ? 'User has been added to the monitoring program.' : ''}
                                    {this.state.status === 'decline' ? 'You have declined a new user to monitoring program. User will receive a email notification.' : ''}
                                </Flex.Item>
                            </Flex>
                            {this.state.status === 'pending' ?
                                <Flex className="eamil-sub-title">
                                    <Flex.Item>
                                        <Button
                                            className="fill-blue-btn"
                                            activeClassName="fill-blue-active-btn"
                                            loading={this.state.loading}
                                            disabled={this.state.disabled}
                                            onClick={this.accept}
                                        >
                                            Accept
                                        </Button>
                                        <Button
                                            className="fill-pink-btn"
                                            activeClassName="fill-pink-active-btn"
                                            loading={this.state.loading}
                                            disabled={this.state.disabled}
                                            onClick={this.decline}
                                        >
                                            Decline
                                        </Button>
                                    </Flex.Item>
                                </Flex>
                                : ''
                            }
                            <Flex className="eamil-footer">
                                <Flex.Item>
                                    <b>Questions?</b> Contact HeartVoice Support at 6594 1381(Mon-Sun,7am-10pm)
                                    support@ourheartvoice.com
                                </Flex.Item>
                            </Flex>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}
export default AcceptOrDeclien;