import React, { Component } from 'react';
import { Flex, WhiteSpace, WingBlank, TabBar, Icon,NavBar, Grid, Toast, Modal, ListView, Button } from 'antd-mobile';
import failed from '../images/icons/failed.png'
import { getCache, removeCache } from '../commons/Helpers'

class GEFail extends Component {
    constructor(props){
        super(props);
        this.state = {
            backToChatBtnLoading: false,
            tryAgainBtnLoading: false,
            ge_id: '',
            is_share_personal_data: '',
            is_contacted_by_ge: '',
        }
    }

    tryAgain = ()=>{
        if (getCache('geInfo')) {
            let { ge_id,is_share_personal_data,is_contacted_by_ge } = JSON.parse(getCache('geInfo'));
            this.setState({
                ge_id: ge_id,
                is_share_personal_data: is_share_personal_data,
                is_contacted_by_ge: is_contacted_by_ge,
            },() => {
                this.props.history.push({ pathname: "/geCheckOutPage/" + this.state.ge_id + "/" + this.state.is_share_personal_data + "/" + this.state.is_contacted_by_ge });
            })
        }else{
            this.props.history.push("/404");
        }
        removeCache('geInfo');
    }

    goBack = ()=>{
        this.props.history.push('/marketPlaceDetail/' + this.state.ge_id);
    }

    render() {
        return (
            <div className="Paymet-Page result-success-fail-page">
                <NavBar
                    mode="light"
                    icon={<Icon type="left" color="#EB5DA6" size="lg"/>}
                    onLeftClick={this.goBack}
                ></NavBar>
                <Flex className="content">
                    <Flex.Item>
                        <WingBlank>
                            <WhiteSpace size="md" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <Flex direction="column">
                                <img src={failed} className="icon-payment-fail" alt="Result - Fail" />
                                <WhiteSpace size="lg" />
                                <WhiteSpace size="lg" />
                                <WhiteSpace size="lg" />
                                <WhiteSpace size="lg" />
                                <div className="result-title">Payment Failed</div>
                                <WhiteSpace size="md" />
                                <div className="result-desc">Something seems to have gone wrong.</div>
                                <div className="result-desc">Would you like to try again?</div>
                            </Flex>
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WingBlank size="lg">
                                <WingBlank size="lg">
                                    <WingBlank size="lg">
                                        <WingBlank size="lg">
                                            <WingBlank size="lg">
                                                <Button
                                                    className="fill-pink-btn"
                                                    activeClassName="fill-pink-active-btn"
                                                    loading={this.state.tryAgainBtnLoading}
                                                    onClick={this.tryAgain}
                                                >
                                                    Try Again
                                                </Button>
                                                <WhiteSpace size="lg" />
                                                <WhiteSpace size="lg" />
                                            </WingBlank>
                                        </WingBlank>
                                    </WingBlank>
                                </WingBlank>
                            </WingBlank>
                        </WingBlank>
                    </Flex.Item>
                </Flex>
            </div>
        );
    }
}

export default GEFail;