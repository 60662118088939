import React, { Component } from 'react';
import { Flex, WhiteSpace, WingBlank, TabBar, Icon, Grid, Toast, Modal, ListView, Button } from 'antd-mobile';
import HVLogo from '../images/icons/IconHeartVoiceLong.svg'
import { WHATSAPP_MOBILENO } from '../commons/Constants'
import { setCache, getCache } from '../commons/Helpers';

class SubmitProfileResult extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    goToWA = () => {

        window.location.href = 'https://wa.me/' + WHATSAPP_MOBILENO;

    }

    render() {
        return (
            <div className="Paymet-Page result-success-fail-page">
                <Flex className="content">
                    <Flex.Item>
                        <WingBlank size="sm">
                            <WhiteSpace size="md" />
                            <Flex>
                                <Flex.Item>
                                    <img src={HVLogo} className="HVLogo" alt="HeartVoice - Logo" />
                                </Flex.Item>
                            </Flex>
                            <WhiteSpace size="md" />
                        </WingBlank>
                        <WingBlank>
                            <Flex direction="column" align="start">
                                <p className="ft8">Success!</p>
                                <div className="ft8">You are now logged into your HeartVoice account</div>
                                <WhiteSpace size="md" />
                                <WhiteSpace size="lg" />
                                <WhiteSpace size="lg" />
                                <WhiteSpace size="lg" />
                                <WhiteSpace size="lg" />
                            </Flex>
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WhiteSpace size="lg" />
                            <WingBlank size="lg">
                                <WingBlank size="lg">
                                    <WingBlank size="lg">
                                        <WingBlank size="lg">
                                            <WingBlank size="lg">
                                                <Button
                                                    className="fill-pink-btn"
                                                    activeClassName="fill-pink-active-btn"
                                                    onClick={this.goToWA}
                                                >
                                                   Go to Chat
                                                </Button>
                                                <WhiteSpace size="lg" />
                                                <WhiteSpace size="lg" />
                                                <WhiteSpace size="lg" />
                                            </WingBlank>
                                        </WingBlank>
                                    </WingBlank>
                                </WingBlank>
                            </WingBlank>
                        </WingBlank>
                    </Flex.Item>
                </Flex>
            </div>
        );
    }
}

export default SubmitProfileResult;