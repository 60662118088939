import React, { Component } from "react";
import {
  Flex,
  WhiteSpace,
  NavBar,
  WingBlank,
  Toast,
  Button,
  Icon,
  InputItem,
  Modal,
} from "antd-mobile";
import Flag from "../images/icons/iconCreditcard.svg";
import AuthGuard from "../services/AuthGuard";
import {
  getCache,
  isEmail,
  setCache,
  removeCache,
  isInvalid,
  mobileBigFormate,
} from "../commons/Helpers";
import { ShowErrorMsgCode } from "../commons/Constants";
var otpTimer = null;
class VerifyOTPV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorTxtDisplay: "none",
      errorMsg: "Invalid OTP Code",
      code1: "",
      code2: "",
      code3: "",
      code4: "",
      code5: "",
      code6: "",
      email: "",
      destination: "",
      code: "",
      username: "",
      conent1: "", //a sms or an email
      conent2: "", //phone or email
      vcode: [],
      tempArr: [0, 1, 2, 3, 4, 5],
      active: "",
      input_code: "",
      border: false,
      i: 0,
      seconds: 0,
      is_miss: true,
    };
  }

  componentDidMount() {
    this.vcodeFoucs();
    document.body.style.backgroundColor = "#FFF";
    this.secondsResendOtp();
    let verifyOptFor = JSON.parse(getCache("verifyOptFor"));
    this.setState({
      module_type: verifyOptFor.type,
    });
    if (verifyOptFor.type == "login_miss") {
      this.setState({
        is_miss: false,
      });
    }
    // this.setState({
    //     conent1: verifyOptFor.type === 'email'? 'an email':'a sms',
    //     conent2: verifyOptFor.type === 'email'? 'email':'phone',
    //     username: verifyOptFor.username
    // })
  }

  onChange = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      if (value.length > 0) {
        console.log(value);
        if (name == "code1") {
          this.inputCode2.focus();
        }

        if (name == "code2") {
          this.inputCode3.focus();
        }

        if (name == "code3") {
          this.inputCode4.focus();
        }

        if (name == "code4") {
          this.inputCode5.focus();
        }

        if (name == "code5") {
          this.inputCode6.focus();
        }

        if (name == "code6") {
        }

        this.submitOpt();
      }
    });
  };

  loadingToast() {
    Toast.loading("Loading...", 30, () => {
      console.log("Load complete !!!");
    });
  }

  submitOpt = () => {
    let otpCode = this.state.code;
    if (otpCode.length === 6) {
      this.loadingToast();
      let { fromPage, dialingCode, mobile, email, tc, other, type } =
        JSON.parse(getCache("verifyOptFor"));
      if (type === "sign_up") {
        this.loadingToast();
        AuthGuard.SignUpOtpSubmit(
          {
            otpCode: this.state.code,
            email: email,
            dialingCode: mobile ? dialingCode : "",
            mobile: mobile,
            agreeConditions: tc,
            keepUpdated: other,
          },
          (isOk, res) => {
            if (isOk) {
              if (mobile) {
                AuthGuard.signupNotie(
                  {
                    dialing_code: dialingCode,
                    mobile_number: mobile,
                  },
                  (isOk, res) => {}
                );
              }

              let token = res.result.accessToken.token;
              AuthGuard.SetAuthenticated(token);
              removeCache("verifyOptFor");
              setCache("referral_id", { id: res.result.user.user_id });
              this.props.history.push("/signUpCompleteInfo/");
            } else {
              this.setState({
                errorTxtDisplay: "block",
                errorMsg: res.message,
              });
            }
            Toast.hide();
          }
        );
      } else if (type === "myinfo") {
        let {
          fullName,
          nric,
          birth,
          gender,
          country_code,
          country_name,
          postal_code,
          address,
          unit_no,
          myInfoId,
          identification_type,
          province_code,
          city_code,
          province_name,
          city_name,
          race,
          residential_status,
          nationality,
        } = JSON.parse(getCache("verifyOptFor"));
        this.loadingToast();
        AuthGuard.SignUpMyinfoOtpSubmit(
          {
            otpCode: this.state.code,
            email: email,
            dialingCode: dialingCode,
            mobile: mobile,
            agreeConditions: tc,
            keepUpdated: other,
            fullName: fullName,
            identificationType: identification_type,
            identification: nric,
            birth: birth,
            gender: gender,
            postalCode: postal_code,
            countryCode: country_code,
            countryName: country_name,
            street: address,
            unitNo: unit_no,
            myInfoId: myInfoId,
            city: city_code,
            province: province_code,
            provinceName: province_name,
            cityName: city_name,
            residential_status: residential_status,
            nationality: nationality,
            race: race,
          },
          (isOk, res) => {
            if (isOk) {
              if (mobile) {
                AuthGuard.signupNotie(
                  {
                    dialing_code: dialingCode,
                    mobile_number: mobile,
                  },
                  (isOk, res) => {}
                );
              }

              let token = res.result.accessToken.token;
              AuthGuard.SetAuthenticated(token);
              removeCache("verifyOptFor");
              setCache("referral_id", { id: res.result.user.user_id });
              this.props.history.push("/referralCode/");
            } else {
              this.setState({
                errorTxtDisplay: "block",
                errorMsg: res.message,
              });
            }
            Toast.hide();
          }
        );
      } else if (type === "login_miss") {
        let login_params = JSON.parse(getCache("verifyOptFor"));
        login_params.otp_code = this.state.code;
        this.loadingToast();
        AuthGuard.loginComSubmit(login_params, (isOk, res) => {
          if (isOk) {
            removeCache("verifyOptFor");
            if (getCache("redirectUrl")) {
              let url = getCache("redirectUrl");
              url = url.replace("#", "");
              this.props.history.push(url);
            } else {
              this.props.history.push("/improve");
            }
          } else {
            this.setState({
              errorTxtDisplay: "block",
              errorMsg: res.message,
            });
          }
          Toast.hide();
        });
      } else {
        this.setState({
          errorTxtDisplay: "block",
          errorMsg: "Invalid Data.",
        });
      }
    }
  };

  resendOTP = (e) => {
    // e.preventDefault();
    this.setState({
      errorTxtDisplay: "none",
      code1: "",
      code2: "",
      code3: "",
      code4: "",
      code5: "",
      code6: "",
    });
    this.loadingToast();
    let verifyOptFor = JSON.parse(getCache("verifyOptFor"));
    if (verifyOptFor.type === "sign_up" || verifyOptFor.type === "myinfo") {
      AuthGuard.SignUpSendOtp(
        {
          dialingCode: verifyOptFor.mobile ? verifyOptFor.dialingCode : "",
          mobile: verifyOptFor.mobile,
          email: verifyOptFor.email,
        },
        (isok, res) => {
          if (isok) {
            if (verifyOptFor.mobile) {
              AuthGuard.signupNotie(
                {
                  dialing_code: verifyOptFor.dialingCode,
                  mobile_number: verifyOptFor.mobile,
                },
                (isOk, res) => {}
              );
              Modal.alert(
                "Success",
                "An OTP has been sent to your registered mobile number at " +
                  mobileBigFormate(verifyOptFor.mobile) +
                  ".",
                [{ text: "OK", onPress: () => console.log("ok") }]
              );
            } else {
              Modal.alert(
                "Success",
                "An OTP has been sent to your registered Email at " +
                  verifyOptFor.email +
                  ".",
                [{ text: "OK", onPress: () => console.log("ok") }]
              );
            }

            this.secondsResendOtp();
          } else {
            this.setState({
              errorTxtDisplay: "block",
              errorMsg: res.message,
            });
          }
          Toast.hide();
        }
      );
    } else if (verifyOptFor.type === "login_miss") {
      AuthGuard.loginComOtpSubmit(
        {
          email: verifyOptFor.email,
          dialingCode: verifyOptFor.dialingCode,
          mobile: verifyOptFor.mobile,
        },
        (isok, res) => {
          if (isok) {
            if (verifyOptFor.mobile) {
              AuthGuard.signupNotie(
                {
                  dialing_code: verifyOptFor.dialingCode,
                  mobile_number: verifyOptFor.mobile,
                },
                (isok, res) => {}
              );
              Modal.alert(
                "Success",
                "An OTP has been sent to your registered mobile number at " +
                  mobileBigFormate(verifyOptFor.mobile) +
                  ".",
                [{ text: "OK", onPress: () => console.log("ok") }]
              );
            } else {
              Modal.alert(
                "Success",
                "An OTP has been sent to your registered Email at " +
                  verifyOptFor.email +
                  ".",
                [{ text: "OK", onPress: () => console.log("ok") }]
              );
            }

            this.secondsResendOtp();
          } else {
            this.setState({
              errorTxtDisplay: "block",
              errorMsg: res.message,
            });
          }
          Toast.hide();
        }
      );
    } else {
      if (isEmail(verifyOptFor.email)) {
        AuthGuard.loginSendOtp(
          {
            otpType: "EMAIL",
            username: verifyOptFor.email,
          },
          (isOk, res) => {
            // console.log(res);
            if (isOk) {
              // countdownTimer
              Modal.alert("Success", res.message, [
                { text: "OK", onPress: () => console.log("ok") },
              ]);
              this.secondsResendOtp();
            } else {
              this.setState({
                errorTxtDisplay: "block",
                errorMsg: res.message,
              });
            }
            Toast.hide();
          }
        );
      } else {
        AuthGuard.loginSendOtp(
          {
            otpType: "SMS",
            username: verifyOptFor.mobile,
            dialingCode: verifyOptFor.dialingCode,
          },
          (isOk, res) => {
            console.log(res);
            if (isOk) {
              // countdownTimer
              Modal.alert("Success", res.message, [
                { text: "OK", onPress: () => console.log("ok") },
              ]);
              this.secondsResendOtp();
            } else {
              this.setState({
                errorTxtDisplay: "block",
                errorMsg: res.message,
              });
            }
            Toast.hide();
          }
        );
      }
    }
    // console.log('resendOTP');
  };

  secondsResendOtp = () => {
    this.state.seconds = 60;
    otpTimer = setInterval(() => {
      this.setState(
        (preState) => ({
          seconds: preState.seconds - 1,
        }),
        () => {
          if (this.state.seconds <= 0) {
            clearInterval(otpTimer);
          }
        }
      );
    }, 1000);
  };

  vcodeChange = (e) => {
    let { value } = e.target;
    var _vcode = [];
    var _i = 0;
    for (var j = 0; j <= value.length; j++) {
      _vcode.push(value.charAt(j));
      _i = j;
    }
    this.setState({
      vcode: _vcode,
      i: _i,
      active: _i,
    });
    if (value.length == 6) {
      let _code = _vcode.join("");
      this.setState(
        {
          code: _code,
        },
        () => {
          this.submitOpt();
          this.vcodeBlur();
        }
      );
    }
    this.setState({
      input_code: value,
    });
  };

  vcodeFoucs = (e) => {
    this.setState({
      code: "",
      vcode: [],
      active: 0,
      input_code: "",
      border: true,
      errorTxtDisplay: "none",
    });
    this.otp_code.focus();
  };

  vcodeBlur = (e) => {
    this.setState({
      active: "",
      border: false,
    });
    this.otp_code.blur();
  };

  goBack = () => {
    let verifyOptFor = JSON.parse(getCache("verifyOptFor"));
    if (otpTimer) {
      clearInterval(otpTimer);
    }
    this.props.history.push(verifyOptFor.fromPage);
  };

  render() {
    return (
      <div className="Register-Page-V2 h5-Page login-Page">
        <NavBar
          mode="light"
          icon={<Icon type="left" color="#EB5DA6" size="lg" />}
          onLeftClick={this.goBack}
        ></NavBar>
        <div className="content">
          <WingBlank>
            <WhiteSpace size="lg" />
            {this.state.is_miss ? (
              <div>
                <Flex>
                  <Flex.Item>
                    <p className="sign-up-title">Creating new account</p>
                  </Flex.Item>
                </Flex>
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <Flex>
                  <Flex.Item>
                    <div className="progress-bar-left-right"></div>
                    <div
                      className={`${
                        this.state.module_type == "sign_up"
                          ? "progress-bar red-color"
                          : "progress-bar"
                      }`}
                    ></div>
                    <div
                      className={`${
                        this.state.module_type == "myinfo"
                          ? "progress-bar red-color"
                          : "progress-bar"
                      }`}
                    ></div>
                    <div className="progress-bar-left-right"></div>
                  </Flex.Item>
                </Flex>
                <WhiteSpace size="lg" />
              </div>
            ) : (
              ""
            )}
            <WhiteSpace size="lg" />
            <Flex direction="column" justify="start" align="start">
              <Flex.Item>
                <p className="alert-otp">
                  We just sent you a sms with a code. Enter it to verify your
                  mobile.
                </p>
              </Flex.Item>
            </Flex>
            <WhiteSpace size="lg" />
            <WhiteSpace size="lg" />
            <div className="vcode" id="vertifycode">
              <input
                type="text"
                onBlur={this.vcodeBlur}
                onFocus={this.vcodeFoucs}
                name="otp_code"
                maxLength="6"
                autoComplete="off"
                style={{ imeMode: "disabled" }}
                ref={(el) => (this.otp_code = el)}
                className="otp_code"
                id="otp_code"
                onChange={this.vcodeChange}
                value={this.state.input_code}
              />
              <div className="otp-labels">
                {this.state.tempArr.map((item, i) => {
                  return (
                    // <label key={i} className={`otp-label ${this.state.border ? 'border' : ''} ${this.state.active === item ? 'active' : ''}`} htmlFor="otp_code">{this.state.vcode[item] ? this.state.vcode[item] : 0}</label>
                    <label
                      key={i}
                      className={`otp-label ${
                        this.state.border ? "border" : ""
                      } ${this.state.active === item ? "" : ""}`}
                      htmlFor="otp_code"
                    >
                      {this.state.vcode[item] ? this.state.vcode[item] : 0}
                    </label>
                  );
                })}
              </div>
            </div>
            <WhiteSpace size="md" />
            <Flex.Item
              className="errorMsg"
              style={{ display: this.state.errorTxtDisplay }}
            >
              <p>{this.state.errorMsg}</p>
            </Flex.Item>
            <WhiteSpace size="lg" />
            <WhiteSpace size="sm" />
            <button
              disabled={this.state.seconds <= 0 ? false : true}
              className="resendOTP"
              onClick={this.resendOTP}
              style={{ color: this.state.seconds <= 0 ? "#56B3C4" : "#8e8e93" }}
            >
              Resend OTP
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span
              style={{
                display: this.state.seconds > 0 ? "inline-block" : "none",
                fontSize: "small",
              }}
            >
              {" "}
              <span style={{ color: "#56B3C4", fontWeight: "bolder" }}>
                in
              </span>{" "}
              <span
                style={{ color: "#56B3C4", fontWeight: "bolder" }}
                className="cb"
              >
                {this.state.seconds} secs
              </span>
            </span>
          </WingBlank>
        </div>
      </div>
    );
  }
}

export default VerifyOTPV2;
