import React, { Component } from 'react';
import { Flex, WhiteSpace, Picker, List, WingBlank, Toast, Button, Modal, InputItem, Icon } from 'antd-mobile';
import { TCLink, DIALING_CODE } from "../commons/Constants";
import enUs from 'antd-mobile/lib/date-picker/locale/en_US';
import { setCache, addKeyValueForDorpList, isMobileNo, isEmail, removeCache, vCodeChange, getCache, isInvalid} from '../commons/Helpers';
import AuthGuard from '../services/AuthGuard';
import CustomChildren from '../commons/CustomChildren';
import { isBrowser } from "react-device-detect";
import Select, { components } from 'react-select';
import URL from "url";

const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CustomChildren sClas={props.hasValue ? "sel" : "noSel"} />
      </components.DropdownIndicator>
    );
};

export const SingleValueForDialingCode = ({ cx,
    getStyles,
    selectProps,
    data,
    isDisabled,
    className,
    hasValue,
    ...props}) =>{    
    const {value, flag} = data
    if(!hasValue){
        return(
            <div
                className={cx({
                        "single-value": true,
                        "single-value--is-disabled": isDisabled,
                        'border-color':'unset'
                    },
                    className
                )}
            >
                {selectProps.getOptionLabel(data)}
            </div>
        )    
    }
    return (
        <div className='selectOptions'>
            <img src={flag}/> +{value}
        </div>
    )
}

export const selectStylesForDialingCode = {
    control: (base) => ({
        ...base,
        border:'unset',
        boxShadow:'none',
    }),
    menu:styles =>({
        ...styles,
        width:'4rem',
    }),
    valueContainer:styles =>({
        ...styles,
        display:'flex',
    }),
    indicatorsContainer: (base) => ({
        ...base,
        padding: 0,
        margin: 0,
        alignItems: 'flex-start',
    }),
}

export const selectStylesForDialingCodeV2 = {
    container: (base) => ({
        ...base,
        width: '100%',
        marginLeft: '0.1rem',
    }),
    dropdownIndicator: (base) => ({
        ...base,
        padding: 0,
        margin: 0,
        alignItems: 'flex-start',
    }),
    control: (base) => ({
        ...base,
        border:'unset',
        boxShadow:'none',
    }),
    menu:styles =>({
        ...styles,
        width:'4rem',
    }),
    valueContainer:styles =>({
        ...styles,
        display:'flex',
    }),
    indicatorsContainer: (base) => ({
        ...base,
        padding: 0,
        margin: 0,
        alignItems: 'flex-start',
    }),
}
class LoginV2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            labelDisplay: 'none',
            nextBtnDisplay: 'none',
            errorTxtDisplay: 'none',
            errorMsg: 'Your phone is not registered yet, please login with email',
            dialingCode: DIALING_CODE,
            mobileNo: '',
            dialingCodeList: [],
            countryListArr: [],
            is_num: false,
            otpType: 'EMAIL',
            email: '',
            titleName: '',
        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = '#FFF';
        this.getCountryList();
        
        let httpUrl = this.props.location.search
            let _query = URL.parse(httpUrl, true).query
            if (!isInvalid(_query) && _query.grantee_app_id) {
                setCache("grantee_app_id", _query.grantee_app_id)
                setCache("grantee_callback_url", _query.grantee_callback_url)
                setCache('redirectUrl', "/authGrant")
            }else{
                //if is not login to auth grant. clear the cache
                removeCache("grantee_app_id")
                removeCache("grantee_callback_url")
                if (getCache("redirectUrl") == "/authGrant") {
                    removeCache("redirectUrl")
                }
            }
            console.log(httpUrl)
            console.log(_query)
    }

    getCountryList = () => {
        this.loadingToast();
        AuthGuard.getCountry({}, (isOk) => {
            if (isOk) {
                this.setState({
                    dialingCodeList: addKeyValueForDorpList(AuthGuard.listCountrys, 'dialing_code_name'),
                    countryListArr: AuthGuard.listCountrys
                })
            }
            Toast.hide();
        })
        // AuthGuard.getCountryList({
        //     limit: 300,
        //     page: 1,
        // }
        // ,(isOk, res) =>{
        //     if(isOk){
        //         this.setState({
        //             dialingCodeList: addKeyValueForDorpList(res, 'dialing_code'),
        //             countryListArr:res
        //         })
        //     }else{

        //     }
        //     Toast.hide();
        // });
    }

    inputOnFocus = (e) => {
        this.setState({
            labelDisplay: 'block'
        })
    }
    inputOnBlur = (value) => {
        if (value.trim().length > 0) {
            this.setState({
                nextBtnDisplay: 'block',
                labelDisplay: 'block',
            })
        } else {
            this.setState({
                labelDisplay: 'none',
                nextBtnDisplay: 'none'
            })
        }
    }

    onChange = (value) => {

        let val = value.trim()
        val = val.replace(/\s/g,"");
        this.setState({
            mobileNo: val
        })
        this.mobile_change(val);
    }

    loadingToast() {
        Toast.loading('Loading...', 30, () => {
            console.log('Load complete !!!');
        })
    }

    mobile_change(v) {
        if (vCodeChange(v) && v != '') {
            this.setState({
                is_num: true,
                titleName: 'Mobile Number'
            });
        } else if (v == '') {
            this.setState({
                is_num: false,
                titleName: ''
            });
        } else {
            this.setState({
                is_num: false,
                titleName: 'Email'
            });
        }
    }

    sendOtp = () => {
        this.setState({
            errorTxtDisplay: "none",
            errorMsg: ''
        })
        if (this.state.is_num) {
            let res = this.state.countryListArr.find((item, index) => {
                return item.dialing_code === this.state.dialingCode;
            })
            let resM = res.mobile_no_format ? res.mobile_no_format : '';
            if (this.state.mobileNo == '' || this.state.mobileNo.trim() == '') {
                Modal.alert('Error', 'Please enter an email address or mobile number to login.', [{ text: 'OK', onPress: () => console.log('ok') }]);
                // this.setState({
                //     errorTxtDisplay: "block",
                //     errorMsg: 'Please fill in the required field.'
                // })
                return;
            }
            if (isMobileNo(this.state.mobileNo, this.state.dialingCode, resM)) {
                this.loadingToast();
                AuthGuard.loginSendOtp({
                    otpType: 'SMS',
                    username: this.state.mobileNo,
                    dialingCode: this.state.dialingCode
                }
                    , (isOk, res) => {
                        if (isOk) {
                            let cacheObj = {
                                fromPage: '/login',
                                type: 'mobile',
                                dialingCode: this.state.dialingCode,
                                username: this.state.mobileNo,
                            }
                            removeCache('verifyOptFor')
                            setCache('verifyOptFor', cacheObj);
                            this.props.history.push('/VerifyOTP/')
                        } else {
                            //6615 Your phone is not registered yet, please login with email
                            this.setState({
                                errorTxtDisplay: "block",
                                errorMsg: res.message
                            })
                        }
                        Toast.hide();
                    });
            } else {
                this.setState({
                    errorTxtDisplay: "block",
                    errorMsg: 'Please fill in a valid mobile number.'
                })
            }
        } else {
            if (this.state.mobileNo == '' || this.state.mobileNo.trim() == '') {
                Modal.alert('Error', 'Please enter an email address or mobile number to login.', [{ text: 'OK', onPress: () => console.log('ok') }]);
                // this.setState({
                //     errorTxtDisplay: "block",
                //     errorMsg: 'Please fill in the required field.'
                // })
                return;
            }
            if (isEmail(this.state.mobileNo)) {
                this.loadingToast();
                AuthGuard.loginSendOtp({
                    otpType: this.state.otpType,
                    username: this.state.mobileNo
                }
                    , (isOk, res) => {
                        if (isOk) {
                            let cacheObj = {
                                fromPage: '/login',
                                type: 'email',
                                dialingCode: this.state.dialingCode,
                                username: this.state.mobileNo,
                            }
                            removeCache('verifyOptFor');
                            setCache('verifyOptFor', cacheObj)
                            this.props.history.push('/VerifyOTP/')
                        } else {
                            //6615 Your phone is not registered yet, please login with email
                            this.setState({
                                errorTxtDisplay: "block",
                                errorMsg: res.message
                            })
                        }
                        Toast.hide();
                    });
            } else {
                this.setState({
                    errorTxtDisplay: "block",
                    errorMsg: 'Please fill in a valid email address.'
                })
            }
        }
    }

    render() {
        let options = []
        if(isBrowser){
            this.state.countryListArr.map((item) => {
                options.push({
                    value: item.dialing_code,
                    flag: item.flag_image_url?.url?.s,
                    label: <div className="selectOptions">
                        <img src={item.flag_image_url?.url?.s} /> {item.name} +{item.dialing_code}
                    </div>
                })
            })
        }
        return (
            <div className="Register-Page-V2 h5-Page login-Page">
                <div className="bgHeader"></div>
                <WingBlank>
                    <Flex direction="column" justify="start" align="start">
                        <Flex.Item>
                            <div className="reg-title"><strong>Login</strong></div>
                        </Flex.Item>
                    </Flex>
                    <WhiteSpace size="lg" />
                    <div className=" input-outline-v2">
                        <div style={{ height: '1em', margin: '5px 10px 0px 10px' }}>{this.state.titleName}</div>
                        <Flex justify="end" align="end" className="loginWithMobile">
                            {this.state.is_num ?
                                <div className="content-pick" style={{paddingLeft: isBrowser ? "0px" : null}}>
                                    {isBrowser ? (
                                        <Flex.Item>
                                            <Select
                                                components={{ DropdownIndicator, SingleValueForDialingCode }}
                                                name="dialingCode"
                                                value={this.state.dialingCode ? options.find(option => option.value == this.state.dialingCode) : null}
                                                onChange={(e) => {
                                                    this.setState({
                                                        dialingCode: e.value
                                                    })
                                                }}
                                                styles={selectStylesForDialingCode}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    zIndex: -100000,
                                                    border: '1px solid #ffffff'
                                                })}
                                                menuPortalTarget={document.querySelector('body')}
                                                isSearchable={false}
                                                options={options}
                                                placeholder={'Select Dialing Code'}
                                            />
                                        </Flex.Item>
                                    ) : (
                                        <Picker
                                            data={this.state.dialingCodeList}
                                            locale={enUs}
                                            extra="Dialing Code"
                                            cols={1}
                                            value={[this.state.dialingCode]}
                                            format={(labels) => {
                                                if (!labels.length) return
                                                return [
                                                    labels[0].props.children[0],
                                                    labels[0].props.children[2],
                                                ]
                                            }}
                                            onChange={v => {
                                                this.setState({
                                                    dialingCode: v[0]
                                                })
                                            }}
                                        >
                                            <CustomChildren />
                                        </Picker>)}
                                </div> : ''
                            }
                            <Flex.Item>
                                <InputItem
                                    className="inputField"
                                    placeholder="Mobile Number / Email"
                                    clear
                                    value={this.state.mobileNo.trim()}
                                    name="mobile"
                                    onBlur={this.inputOnBlur}
                                    onFocus={this.inputOnFocus}
                                    onChange={this.onChange}
                                />
                            </Flex.Item>
                        </Flex>
                    </div>
                    <Flex.Item className="errorMsg" style={{ display: this.state.errorTxtDisplay }}>
                        <p>{this.state.errorMsg}</p>
                    </Flex.Item>
                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />
                    <Flex.Item>
                        <Button
                            className="fill-pink-btn"
                            activeClassName="fill-pink-active-btn"
                            onClick={this.sendOtp}
                        >
                            Login
                        </Button>
                    </Flex.Item>
                    <WhiteSpace size="lg" />
                    <WhiteSpace size="md" />
                    <Flex>
                        <Flex.Item className="FlexAlignCenter">
                            <button className="normalBtn"> <p>Don’t have an account?</p></button>
                        </Flex.Item>
                    </Flex>
                    <WhiteSpace size="lg" />
                    <WhiteSpace size="md" />
                    <Flex>
                        <Flex.Item className="FlexAlignCenter">
                            <Button
                                className="outline-pink-btn"
                                style={{ width: '100%' }}
                                activeClassName="fill-pink-active-btn"
                                onClick={() => this.props.history.push("/signUpProfile")}
                            >
                                Sign Up
                            </Button>
                            {/* <button className="normalBtn" onClick={() => this.props.history.push("/signUpProfile")} > <strong className="fbPink">Sign Up</strong></button> */}
                        </Flex.Item>
                    </Flex>
                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />
                </WingBlank>
            </div>
        );
    }
}

export default LoginV2;