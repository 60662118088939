import React, { Component } from 'react';
import { Flex, WhiteSpace, TabBar, Icon, Grid, Toast, Modal} from 'antd-mobile';
import Button from './Button'
// const USER_ID = Math.floor(Math.random() * 1000000001);
import RingtoneMp3 from '../sounds/ringtone.mp3'
import CallingMp3 from '../sounds/calling.mp3'
import EndCallMp3 from '../sounds/endCall.mp3'
import md5 from 'js-md5';
import {default as TwilioVideo} from '../component/twilio/index'

var hideToolBarTimer = null
class VideoCallV3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            elementID1: 'agora_local',
            elementID2: 'agora_origin',
            SwitchCamera: 'IconSwitchCamera1',
            Video: 'IconVideo1',
            MuteMic: 'IconMuteMic1',
            orientation: null,
            toolbarSwitch: true,
        }

        this.channelName = "";
        this.mode = "rtc";
        this.codec = "vp8";
        // this.codec = "h264";
        this.attendeeMode = 0b11;
        // this.videoProfile = "1080p";
        this.videoProfile = "720p_6";
        this.token = '';
        this.streamId = '';
        this.cameraId = undefined;
        this.microphoneId = undefined;
        this.elementID = 'agora_local';
        // stream management
        this.localUser = null;
        this.remoteUser = null;
        this.remoteUsers = [];
        this.screenStream = null;
        
        // client state
        this.initializing = false;
        this.published = false;
        this.joined = false;
        this.cameras = [];
        this.microphones = [];
        this.dialing_code = '';
        this.mobile_number = '';
        this.localUserPlaylocation ='agora_local'
    }

    componentDidMount() {
        // this.initialize();
        // this.getDevices();
        
        //判断手机是横屏还是竖屏
        this.listenOrientationChange = () => {
            var mql = window.matchMedia("(orientation: portrait)");
            if (mql.matches) {
                this.setState({
                    orientation:'vertical'
                })
            }else{
                this.setState({
                    orientation: 'horizontal'
                }) 
            }
        };

        this.listenOrientationChange();
        window.addEventListener("resize", this.listenOrientationChange);
        // window.addEventListener("click", this.listenWebClick);
    }

    componentWillUnmount(){
        //判断手机是横屏还是竖屏
        window.removeEventListener("resize", this.listenOrientationChange);
        // window.removeEventListener("click", this.listenWebClick);
    }

    componentWillReceiveProps(props) {
        let { channel_code_hash, dialing_code, mobile_number, patient_token, patient_uid } = props;
        if (patient_uid !== undefined && !this.initializing) {
            
            this.token = patient_token;
            this.streamId = patient_uid;
            this.channelName = channel_code_hash;
            this.dialing_code = dialing_code;
            this.mobile_number = mobile_number;
            this.initialize();
        }
    }

    hideToolbarSwitch(){
        this.setState({
            toolbarSwitch: false
        })
    }

    showToolbarSwitch() {
        window.clearTimeout(hideToolBarTimer)
        let self = this
        this.setState({
            toolbarSwitch: true
        }, () => {
            hideToolBarTimer = window.setTimeout(() => {
                self.hideToolbarSwitch()
            }, 3000);
        })
    }

    listenWebClick(){
        console.log('clcik')
        let self = this
        this.setState({
            toolbarSwitch: true
        },()=>{
            setTimeout(() => {
                self.hideToolbarSwitch()
            }, 3000);
        })
    }

    async initialize() {
        this.initializing = true;

    }

    _parseError(err) {
        if (err instanceof Error) {
            return String(err);
        } else {
            return JSON.stringify(err);
        }
    }

    notify(msg, type = "info") {
        this.text = msg;
        Toast.info(msg, 1);
        if (type == 'error'){
            // alert(msg);
        }
        // console.log(msg)
    }

    endCall(){
        this.props.endVideoCall();
    }

    getDevices = () => {
    }

    async leave() {
        let self = this;
        // this.leaving = true;
        if (!this.localUser)  return ;
        try {
            if (this.attendeeMode !== 0b00) {
                this.localUser.stream.stop();
                this.localUser.stream.close();
                this.localUser.stream.setBeautyEffectOptions(false); //是否美颜
                this.client.unpublish(this.localUser.stream);
                this.published = false;
                this.localUser = null;
                this.client.leave();
            }

            if (this.remoteUser) {
                this.remoteUser.stream.stop();
                this.remoteUser.stream.close();
                this.remoteUser = null;
            }
            if(this.screenStream){
                this.screenStream.stream.stop();
                this.screenStream.stream.close();
                this.screenStream = undefined;
            }
            
            this.client = null;
            this.remoteUsers = [];
            this.notify("You have left the room", "info");
            this.joined = false;
            this.initializing = false;
        } catch (err) {
            console.error(err);
            this.notify(err);
        } finally {
            // this.leaving = false;
            setTimeout(() => {
                self.endCall();
            }, 1000);
        }
    }

    playAudio(audioID, _loop = true) {
        var audio = document.getElementById(audioID);
        if (audio !== null) { 
            if (audio.paused) {
                audio.play();// 播放
                document.getElementById(audioID).loop = _loop;
            }
        }
    }

    closeAudio(audioID) {
        var audio = document.getElementById(audioID);
        if (audio !== null) {
            if (!audio.paused) {
                audio.pause();// 播放
                audio.load();
                document.getElementById(audioID).loop = false;
            }
        }
    }

    videoCallBtn(type){
        let self = this;
        if (type == 'IconCall') {
            Modal.alert('End Call', <div>Are you sure you want to end this <br/> consultation?</div>, [
                {  text: 'Yes', 
                    style: { color: 'red' },
                    onPress: () => {
                        self.playAudio('endCallMp3', false);
                        self.leave();
                    } 
                },
                {  text: 'No', 
                    style:{color:'blue'},
                    onPress: () => {
                        console.log('No') 
                    }
                },
                {
                    text: ' ',
                    style: { display:'none'},
                    onPress: () => {
                        console.log('No')
                    }
                },
            ])
            
        }

        if (type == 'switch') {
            if (this.state.SwitchCamera === 'IconSwitchCamera1') {
                this.setState({
                    SwitchCamera: 'IconSwitchCamera2'
                });
                this.switchCameraFu(false);
            } else {
                this.setState({
                    SwitchCamera: 'IconSwitchCamera1'
                });
                this.switchCameraFu(true);
            }
        }

        if (type == 'video') {
            if (this.state.Video === 'IconVideo1') {
                this.setState({
                    Video: 'IconVideo2'
                });
                this.closeVidoFu(false)
            } else {
                this.setState({
                    Video: 'IconVideo1'
                });
                this.closeVidoFu(true)
            }
        }

        if (type == 'mic') {
            if (this.state.MuteMic === 'IconMuteMic1') {
                this.setState({
                    MuteMic: 'IconMuteMic2'
                });
                this.closeMicFu(false);
            } else {
                this.setState({
                    MuteMic: 'IconMuteMic1'
                });
                this.closeMicFu(true);
            }
        }
    }

    changeCamera(i){
        console.log(i);
    }

    switchCameraFu(status) {
        let modelCamerasArray = [];
        // if (this.cameras.length > 0){
        //     this.cameras.map((item, index)=>{
        //         modelCamerasArray.push({
        //             text: item.text,
        //             onPress: () => this.changeCamera(item)
        //         });
        //     });
        // }
        // Modal.alert('Choose Your Camera', '', modelCamerasArray);
    }

    closeVidoFu(status) {
        if (!this.localUser) return
        if (status) {
            this.localUser.stream.unmuteVideo()
        } else {
            this.localUser.stream.muteVideo()
        }
    }

    closeMicFu(status) {
        if (!this.localUser) return
        if (status) {
            this.localUser.stream.unmuteAudio();
        } else {
            this.localUser.stream.muteAudio();
        }
    }

    subScreenStreamEvts = (stream) => {
        stream.on('stopScreenSharing', () => {
            console.log('stopScreenSharing')
        })
    }

    removestreamPlayScreen(){
        console.log(this.localUser)
        console.log(this.elementID)
        console.log(this.screenStream)
        if (this.screenStream &&  this.screenStream.stream) {
            this.screenStream.stream.stop();
            this.screenStream = undefined
        }

        if (this.localUser){
            this.localUser.stream.play(this.localUserPlaylocation);
        }
    }

    changeVideo(e){
        if (!this.published) return;
        if (!this.remoteUser) return;
        if (this.screenStream){
            console.log(this.localUserPlaylocation)
            console.log(this.remoteUser)
            if (this.localUserPlaylocation === 'agora_local'){
                this.screenStream.stream.stop();
                this.screenStream.stream.play('agora_origin', { fit: "contain" });
                this.localUserPlaylocation = 'agora_origin'

                // if (this.remoteUser && this.remoteUser !== undefined) {
                    
                    this.remoteUser.stream.stop();
                    this.remoteUser.stream.play('agora_local');
                // }
            }else{
                this.screenStream.stream.stop();
                this.screenStream.stream.play('agora_local');
                this.localUserPlaylocation = 'agora_local'

                // if (this.remoteUser && this.remoteUser !== undefined) {
                    this.remoteUser.stream.stop();
                    this.remoteUser.stream.play('agora_origin', { fit: "contain" });
                // }
            }
            return ;
        }else if(this.localUser){
            if (this.localUserPlaylocation === 'agora_local') {
                this.localUser.stream.stop();
                this.localUser.stream.play('agora_origin', { fit: "contain" });
                this.localUserPlaylocation = 'agora_origin'

                if (this.remoteUser && this.remoteUser !== undefined) {
                    this.remoteUser.stream.stop();
                    this.remoteUser.stream.play('agora_local');
                }
            } else {
                this.localUser.stream.stop();
                this.localUser.stream.play('agora_local');
                this.localUserPlaylocation = 'agora_local'

                if (this.remoteUser && this.remoteUser !== undefined) {
                    this.remoteUser.stream.stop();
                    this.remoteUser.stream.play('agora_origin', { fit: "contain" });
                }
            }
        }
        console.log(this.localUserPlaylocation);
    }

    render() { 
        return (
            <Flex style={{ backgroundColor: '#E9F5F6' }}>
                <Flex.Item>
                    <audio src={RingtoneMp3} controls="controls" id="ringtoneMp3" hidden>
                        Your browser does not support the <code>audio</code> element.
                    </audio>
                    <audio src={CallingMp3} controls="controls" id="callingMp3" hidden>
                        Your browser does not support the <code>audio</code> element.
                    </audio>
                    <audio src={EndCallMp3} controls="controls" id="endCallMp3" hidden>
                        Your browser does not support the <code>audio</code> element.
                    </audio>
                    <div className="videoCall-block">
                        <TwilioVideo token={this.props.token} endCall={this.endCall.bind(this)} />
                    </div>
                </Flex.Item>
            </Flex>
        );
    }
}
 
export default VideoCallV3;