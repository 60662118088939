import React, { Component } from 'react';
import { Button } from 'antd-mobile';
class UButton extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (  
            <Button className={this.props.className ? this.props.className : 'custom-button'} type={this.props.type ? this.props.type : ''} onClick={this.props.onClick}>
                {this.props.name}
            </Button>
        );
    }
}
 
export default UButton;