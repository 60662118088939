import React, { Component } from "react";
import {
  Flex,
  WhiteSpace,
  WingBlank,
  TabBar,
  Icon,
  Grid,
  Toast,
  Modal,
  ListView,
  Button,
} from "antd-mobile";
import HVLogo from "../images/icons/IconHeartVoiceLong.svg";
import IconSuccess from "../images/icons/IconResultSuccess.svg";
import SadRobot from "../images/icons/iconSayByeRobot.svg";
import { WHATSAPP_MOBILENO } from "../commons/Constants";

class LeaveRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.isInIOS = navigator.userAgent.indexOf("1docIos") !== -1;
    this.isInAndroid = navigator.userAgent.indexOf("1docAndroid") !== -1;
  }

  goToWA = () => {
    if (this.isInIOS || this.isInAndroid) {
      this.isInIOS &&
        window.webkit.messageHandlers.back_to_app_home.postMessage("");
      this.isInAndroid && window.JsToAndroid.back_to_app_home();
    } else {
      window.location.href = "https://wa.me/" + WHATSAPP_MOBILENO;
    }
  };
  componentDidMount() {
    document.body.style.backgroundColor = "#FFF";
  }

  render() {
    return (
      <div className="LeaveRoom-Page">
        <Flex className="content">
          <Flex.Item>
            <WingBlank size="sm">
              <WhiteSpace size="md" />
              <Flex>
                <Flex.Item>
                  <img
                    src={HVLogo}
                    className="HVLogo"
                    alt="HeartVoice - Logo"
                  />
                </Flex.Item>
              </Flex>
              <WhiteSpace size="md" />
            </WingBlank>
            <WingBlank>
              <WhiteSpace size="md" />
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <Flex direction="column">
                <div className="title">
                  Your consultation has ended, we will send you the payment
                  details in chat.
                </div>
              </Flex>
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <WhiteSpace size="md" />
              <Flex justify="center">
                <img src={SadRobot} className="SadRobot" art="page not found" />
              </Flex>
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <WhiteSpace size="md" />
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <WingBlank size="lg">
                <WingBlank size="lg">
                  <WingBlank size="lg">
                    <Button
                      className="fill-pink-btn"
                      activeClassName="fill-pink-active-btn"
                      onClick={this.goToWA}
                    >
                      {this.isInIOS || this.isInAndroid
                        ? "Back to Homepage"
                        : "Back to Chat"}
                    </Button>
                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />
                    <WhiteSpace size="lg" />
                  </WingBlank>
                </WingBlank>
              </WingBlank>
            </WingBlank>
          </Flex.Item>
        </Flex>
      </div>
    );
  }
}

export default LeaveRoom;
