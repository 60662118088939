import React, { Component } from "react";
import {
  Flex,
  WhiteSpace,
  WingBlank,
  NavBar,
  Icon,
  Toast,
  Modal,
  Button,
  Accordion,
  List,
} from "antd-mobile";
import AuthGuard from "../services/AuthGuard";
import {
  setCache,
  removeCache,
  getCache,
  currencyType,
  isInvalid,
  getGST,
} from "../commons/Helpers";
import { SumTotalAmount, FLN } from "../commons/ComputerBill";
import banner_nobg from "../images/icons/banner_nobg.png";
import LoginModel from "../component/LoginModel";
import _ from "lodash";
const alert = Modal.alert;

class CheckOutPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      program_id: "",
      programs_code: "",
      user_profile_id: "",
      name: "",
      qty: "",
      original_price: "",
      program_price: "",
      discount_rate: 0,
      program_img: "",
      campaign_id: "",
      teleconsultation_currency_code: "",
      couponId: getCache("selectPaymentCouponId"),
      selectedCoupon: false,
      loading: false,
      disabled: false,
    };
  }

  componentWillMount() {}

  componentDidMount() {
    let { pro_id } = this.props.match.params;
    if (pro_id) {
      let _url = "/checkOutPage/" + pro_id;
      if (!AuthGuard.checkLogin()) {
        this.loginModel.alertLogin({
          url: _url,
          alert_title: "Please Login in.",
        });
        return false;
      } else {
        AuthGuard.joinPrograms(
          {
            x: pro_id,
          },
          (isOk, res) => {
            if (isOk) {
              //大于0
              this.setState({
                programs_code: pro_id,
                name: res.name,
                qty: res.qty,
                original_price: res.original_price,
                program_price: res.program_price,
                program_img: res.program_image.url.s,
                campaign_id: res.campaign_id,
                user_profile_id: res.user_profile_id,
                program_id: res.program_id,
                teleconsultation_currency_code:
                  res.teleconsultation_currency_code
                    ? res.teleconsultation_currency_code
                    : "SGD",
                gst_rate: _.toNumber(res?.GST_rate || 0),
                gst_calculation: _.toString(res?.GST_calculation || "1"),
                gst_inclusive: _.round(_.toNumber(res?.GST_inclusive || 0), 2),
                gst_reg_no: res?.GST_reg_no || "",
              });
              this.updateData();
              this.getSelectedCouponDetail();
            } else {
              //身份不一致
              if (res === "This payment does not belong to you.") {
                alert(
                  "Error",
                  "This payment does not belong to you. Please login again to confirm your identify.",
                  [
                    {
                      text: "Login",
                      onPress: () => {
                        this.toLogin();
                      },
                    },
                  ]
                );
                return;
              } else {
                Modal.alert("Notice", res, [
                  { text: "OK", onPress: () => console.log("ok") },
                ]);
              }
            }
            this.setState({
              loadding: false,
            });
          }
        );
      }
    } else {
      console.log("无效id");
    }
    removeCache("pro_info");
  }

  toLogin = () => {
    let _url = window.location.pathname;
    setCache("redirectUrl", _url);
    this.props.history.push("/login");
  };

  onRef = (ref) => {
    this.loginModel = ref;
  };

  updateData = async () => {
    let { couponId, gst_reg_no, gst_calculation, gst_rate } = this.state;
    let _totalAmount = 0.0;
    let _displayCounponAmount = 0.0;
    let _subTotalAmount = this.state.program_price;
    if (couponId) {
      try {
        let res = await this.getCouponPrice();
        console.log(!isInvalid(res.coupon_price));
        if (!isInvalid(res.coupon_price)) {
          _displayCounponAmount = res.coupon_price;
          // _displayCounponAmount = 1.2
        }
      } catch (error) {
        _displayCounponAmount = 0.0;
      }
    } else {
      _displayCounponAmount = null;
    }

    _totalAmount = SumTotalAmount(
      this.state.program_price,
      0,
      _displayCounponAmount
    );
    let _sale_price = this.state.program_price;
    let _original_price = this.state.original_price;
    let _discount_rate = 0;
    if (_sale_price && _original_price) {
      _discount_rate = (
        ((FLN(_original_price) - FLN(_sale_price)) * 100) /
        FLN(_original_price)
      ).toFixed(0);
    }
    let gst_number = 0.0;
    if (gst_reg_no) {
      gst_number = getGST(_totalAmount, gst_rate, gst_calculation);
    }
    if (_.toString(gst_calculation) === "0") {
      _totalAmount = _.sum([_.toNumber(_totalAmount), _.toNumber(gst_number)]);
    }
    this.setState({
      displayCounponAmount: _displayCounponAmount,
      totalAmount: _totalAmount,
      subTotalAmount: _subTotalAmount,
      discount_rate: _discount_rate,
      gst_inclusive: gst_number,
    });
  };

  getCouponPrice() {
    return new Promise((resolve, reject) => {
      AuthGuard.getProgramsCouponPrice(
        {
          programId: this.state.program_id,
          couponId: this.state.couponId,
        },
        (isOk, res) => {
          if (isOk) {
            resolve(res);
          } else {
            reject(false);
          }
        }
      );
    });
  }

  getSelectedCouponDetail = () => {
    let _this = this;
    if (this.state.couponId) {
      AuthGuard.getPromoCodeDetailV2(
        {
          user_promo_reward_id: this.state.couponId,
          campaign_id: this.state.campaign_id,
          payment_info: JSON.stringify({ payment_code: "CC1" }),
          qty: 1,
        },
        (isOk, res) => {
          if (isOk) {
            this.setState(
              {
                selectedCoupon: res,
              },
              () => {
                this.updateData();
              }
            );
          }
        }
      );
    } else {
      console.log("not coupon");
    }
  };

  coupon = () => {
    let data = {
      campaign_id: this.state.campaign_id,
      user_profile_id: this.state.user_profile_id,
    };
    this.props.history.push({ pathname: "/paymentCoupon", query: data });
  };

  updateCoupon = () => {
    removeCache("selectPaymentCouponId");
    this.setState({
      selectedCoupon: null,
    });
  };

  ProceedToPayment = () => {
    this.updateCoupon();
    this.Pay();
  };

  loadingToast() {
    Toast.loading("Loading...", 30, () => {});
  }

  Pay = () => {
    if (
      this.state.totalAmount === 0.0 ||
      this.state.totalAmount === "0.00" ||
      this.state.totalAmount === "0" ||
      this.state.totalAmount === "0.0"
    ) {
      this.loadingToast();
      AuthGuard.creatPro(
        {
          // program_id: this.state.program_id,
          // program_code: this.state.programs_code,
          x: this.state.programs_code,
          total: this.state.totalAmount,
          coupon_id: this.state.couponId,
        },
        (isOk, res) => {
          console.log(isOk, res);
          this.setState({
            loading: false,
          });
          if (isOk) {
            removeCache("proInfo");
            Toast.hide();
            setCache("pro_info", {
              program_img: res.program_image.url.o,
              program_name: res.name,
              coach_name: res.coach_name,
            });
            this.props.history.push({ pathname: "/programsSuccess/" });
          } else {
            Toast.hide();
            setCache("pro_info", { program_code: this.state.programs_code });
            this.props.history.push({ pathname: "/paymentFail/" });
          }
        }
      );
    } else {
      setCache("proInfo", {
        totalAmount: this.state.totalAmount,
        couponId: this.state.couponId,
        program_code: this.state.programs_code,
        program_image: this.state.program_img,
        programs_name: this.state.name,
        teleconsultation_currency_code:
          this.state.teleconsultation_currency_code,
      });
      this.props.history.push({
        pathname: "/programsPayment/" + this.state.programs_code,
      });
    }
  };

  goBack = () => {
    this.props.history.push("/doctorList/");
  };

  render() {
    return (
      <div className="Paymet-Page checkout-page background-white">
        <div className="content py-1">
          <Flex>
            <Flex.Item className="content-contain px-5 pb-5">
              <LoginModel onRef={this.onRef} history={this.props.history} />
              {/* <NavBar
                        style={{marginLeft:'-0.5rem'}}
                            mode="light"
                            icon={<Icon type="left" color="#EB5DA6" size="lg"/>}
                            onLeftClick={this.goBack}
                        ></NavBar> */}
              <WingBlank size="sm">
                <Flex className="pb-5">
                  <p className="checkout-title-font">Checkout</p>
                </Flex>
                <WhiteSpace size="lg" />
                <Flex className="pb-5">
                  <Flex.Item>
                    <div className="check-img-dev">
                      <img
                        src={this.state.program_img}
                        alt=""
                        className="check-img"
                      />
                    </div>
                  </Flex.Item>
                  <Flex.Item className="flex-grow-15 check-info">
                    <div className="f-uppercase check-info-name">
                      {this.state.name}
                    </div>
                    <div className="text-right">Qty: {this.state.qty}</div>
                    {this.state.discount_rate !== "0" ? (
                      <div className="price-font">
                        <s className="text-original-price">
                          ${this.state.original_price}
                        </s>
                        <span className="discount-price">
                          {this.state.discount_rate}%
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="now-price">${this.state.program_price}</div>
                  </Flex.Item>
                </Flex>
              </WingBlank>
            </Flex.Item>
          </Flex>
          <Flex className="bar pb-5"></Flex>
          <Flex>
            <Flex.Item>
              <div className="coupon-selection">
                <WhiteSpace size="md" />
                {!this.state.selectedCoupon ? (
                  <List.Item
                    className="CouponDiv"
                    arrow="horizontal"
                    onClick={this.coupon.bind(this)}
                    style={{ border: "0px" }}
                    // data={}
                  >
                    Coupon
                  </List.Item>
                ) : (
                  <div className="selected-coupon">
                    <div className="selected-coupon-title">Coupon</div>
                    <div
                      className="selected-coupon-detail"
                      onClick={this.coupon.bind(this)}
                    >
                      <div className="selected-coupon-code">
                        {this.state.selectedCoupon.code}
                      </div>
                      <div className="selected-coupon-name">
                        {this.state.selectedCoupon.name}
                      </div>
                    </div>
                  </div>
                )}

                <WhiteSpace size="md" />
              </div>
            </Flex.Item>
          </Flex>
          <Flex className="bar pb-5"></Flex>
          <Flex>
            <Flex.Item>
              <WingBlank>
                <WhiteSpace size="md" />
                <Flex>
                  <Flex.Item>
                    <div className="page-title py-5"> Summary </div>
                    <ul className="billList">
                      <li className="billitem">
                        <Flex justify="between">
                          <div className="item-title text-b">Subtotal</div>
                          <div className="item-amount">
                            ${this.state.subTotalAmount}
                          </div>
                        </Flex>
                      </li>
                      {this.state.displayCounponAmount ? (
                        <li className="billitem">
                          <Flex justify="between">
                            <div className="item-title text-b">
                              Coupon Discount
                            </div>
                            <div className="item-amount">
                              -${this.state.displayCounponAmount}
                            </div>
                          </Flex>
                        </li>
                      ) : (
                        ""
                      )}
                      {this.state.gst_inclusive > 0 && this.state.gst_reg_no && (
                        <li className="billitem">
                          <Flex justify="between">
                            <div className="item-title text-b">
                              {this.state.gst_rate}% GST
                              {this.state.gst_calculation === "1" &&
                                " inclusive"}
                            </div>
                            <div className="item-amount">
                              ${this.state.gst_inclusive}
                            </div>
                          </Flex>
                        </li>
                      )}
                      <li className="billitem text-big">
                        <Flex justify="between">
                          <div className="total-title">
                            <big>Total</big>
                          </div>
                          <div className="total-amount">
                            <big>${this.state.totalAmount}</big>
                          </div>
                        </Flex>
                      </li>
                    </ul>
                  </Flex.Item>
                </Flex>
                <WhiteSpace size="md" />
              </WingBlank>
            </Flex.Item>
          </Flex>
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <WingBlank>
            <div className="btn proceed-btn pb-5" style={{ marginTop: "40%" }}>
              <Flex style={{ border: "0px" }}>
                <Flex.Item>
                  <Button
                    className="fill-pink-btn"
                    activeClassName="fill-pink-active-btn"
                    loading={this.state.loading}
                    onClick={this.ProceedToPayment.bind(this)}
                    disabled={this.state.disabled}
                  >
                    Proceed to Payment
                  </Button>
                </Flex.Item>
              </Flex>
            </div>
          </WingBlank>
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
        </div>
      </div>
    );
  }
}
export default CheckOutPage;
