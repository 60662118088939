import React, { Component } from 'react';
import { WingBlank, Flex, WhiteSpace, InputItem, NavBar, Icon, Picker, Button, Toast, Modal } from 'antd-mobile';
import CustomChildren from '../commons/CustomChildren';
import enUs from 'antd-mobile/lib/date-picker/locale/en_US';
import AuthGuard from '../services/AuthGuard';
import { addKeyValueForDorpList, isPostalCode } from '../commons/Helpers';
import { DIALING_CODE } from '../commons/Constants';

class AddAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            billID: props.match.params.id,
            dialingCodeList: [],
            countryList: [],
            provinceList: [],
            cityList: [],
            countryListArr: [],
            errorMsg: {},

            userProfileId: '',
            address: '',
            countryCode: 'SG',
            postalCode: '',
            name: '',
            dialingCode: DIALING_CODE,
            mobileNumber: '',
            unitNo: '',
            addressId: '',

            province_code: '',
            city_code: '',

            loading: false,
            disabled: false,
            isDisplay: false
        }
    }

    componentDidMount() {
        this.getBillInfo();

    }

    getCountryList = () => {
        AuthGuard.getCountry({}, (isOk) => {
            if (isOk) {
                this.setState({
                    dialingCodeList: addKeyValueForDorpList(AuthGuard.listCountrys, 'dialing_code'),
                    countryList: addKeyValueForDorpList(AuthGuard.listCountrys, 'country_list'),
                    countryListArr: AuthGuard.listCountrys
                }, () => {
                    if (this.state.countryCode != '') {
                        this.getProvinceList()
                    }
                    // if (this.state.countryCode != '' && this.state.countryCode != 'SG') {
                    //     this.getProvinceList()
                    // }
                    // console.log(this.state.countryList)
                })
            }
        });
    }

    getProvinceList = () => {
        if (!this.state.countryCode) return
        AuthGuard.getProvinceList({
            code: this.state.countryCode
        }
            , (isOk, res) => {
                if (isOk) {
                    console.log(res)
                    let { province_code } = this.state
                    console.log(province_code)
                    this.setState({
                        provinceList: addKeyValueForDorpList(res[0].province_list, 'country_list'),
                        // province_code: province_code ? province_code : res && res.length > 0 && res[0].province_list && res[0].province_list.length > 0 ? res[0].province_list[0].code : ''
                    }, () => {
                        if (this.state.province_code !== '') {
                            this.getCityList();
                        }
                    })
                }
            });
    }

    getCityList = () => {
        if (!this.state.province_code) return
        AuthGuard.getCityList({
            province_code: this.state.province_code
        }
            , (isOk, res) => {
                if (isOk) {
                    console.log(res)
                    let { city_code } = this.state
                    this.setState({
                        cityList: addKeyValueForDorpList(res, 'country_list'),
                        // city_code: city_code ? city_code : res && res.length > 0 ? res[0].code : ''
                    })
                }
            });
    }

    loadingToast() {
        Toast.loading('Loading...', 30, () => {
            console.log('Load complete !!!');
        });
    }

    toastFailed(msg) {
        Toast.fail(msg, 2);
    }

    getBillInfo() {
        this.loadingToast();
        AuthGuard.userCheckBill({
            userQueueId: this.state.billID
        }, (isOk, res) => {
            if (isOk) {
                let country = res.recipient_address ? res.recipient_address.country_code : 'SG';
                let display = false;
                if (country !== 'SG') {
                    display = true;
                }
                this.setState({
                    userProfileId: res.user_profile_id,
                    addressId: res.recipient_address ? res.recipient_address.id : '',
                    name: res.recipient_address ? res.recipient_address.name : res.user_profile_info.fullName,
                    dialingCode: res.recipient_address && res.recipient_address.dialing_code ? res.recipient_address.dialing_code : res.user_profile_info.dialingCode,
                    mobileNumber: res.recipient_address ? res.recipient_address.mobile_number :  res.user_profile_info.mobile,
                    address: res.recipient_address ? res.recipient_address.address : res.user_profile_info.address,
                    unitNo: res.recipient_address ? res.recipient_address.unit_no : res.user_profile_info.unit_no ,
                    province_code: res.recipient_address ? res.recipient_address.province_code :  res.user_profile_info.province_code ,
                    city_code: res.recipient_address ? res.recipient_address.city_code : res.user_profile_info.city_code ,
                    countryCode: country,
                    postalCode: res.recipient_address ? res.recipient_address.postal_code : res.user_profile_info.postal_code,
                    isDisplay: display,

                }, () => {
                    this.getCountryList();
                })
            }
            Toast.hide();
        });
    }

    back = () => {
        this.props.history.push({ pathname: "/bill/" + this.state.billID })
    }

    getCountryName(code) {
        let name = ''
        if (code) {
            this.state.countryList.map((item) => {
                if (item.value === code) {
                    name = item.label
                }
                return item
            })
        }
        return name

    }

    checkAddress = () => {
        let _country_name = this.getCountryName(this.state.countryCode);
        return new Promise((resolve, reject) => {
            AuthGuard.checkRegAdd({
                param: this.state.address,
                country_name: _country_name,
                country_code: this.state.countryCode,
                postal_code: this.state.postalCode,
            }, (isOk, res) => {
                if (isOk) {
                    resolve(res)
                } else {
                    Modal.alert('', res.message, [{ text: 'OK', onPress: () => console.log('ok') }]);
                    reject(false)
                }
                Toast.hide();
            })
        })
    }

    saveAddressBtn = async (e) => {
        e.preventDefault();
        this.loadingToast();
        this.setState({
            errorMsg: {}
        });

        let res = false
        try {
            res = await this.checkAddress()
        } catch (error) {
            res = false
        }
        if (!res) {
            return;
        }

        let self = this;
        let noError = true;
        let province = this.state.province_code;
        let city = this.state.city_code;
        if (this.state.countryCode === 'SG') {
            province = null;
            city = null;
        }

        let err_postalCode;
        this.state.countryList.map((item) => {
            // console.log(item)
            if (item.postal_code_format) {
                // console.log(item.postal_code_format)
                if (item.value === this.state.countryCode) {
                    let reg = eval(item.postal_code_format);
                    if (!reg.test(this.state.postalCode)) {

                        err_postalCode = false;
                    } else {
                        err_postalCode = true;
                    }
                }
            }
        })

        console.log(err_postalCode)

        if (err_postalCode == true) {

            let data = {
                id: this.state.addressId,
                userProfileId: this.state.userProfileId,
                address: this.state.address,
                unitNo: this.state.unitNo,
                countryCode: this.state.countryCode,
                postalCode: this.state.postalCode,
                name: this.state.name,
                dialingCode: this.state.dialingCode,
                mobileNumber: this.state.mobileNumber,
                province: province,
                city: city,
            }
            let checkRequest = {
                data: data,
                dataName: {
                    address: 'street',
                    unitNo: 'unit no.',
                    countryCode: 'country',
                    postalCode: 'postal code',
                    name: 'full name',
                    dialingCode: 'dialing code',
                    mobileNumber: 'mobile number',
                    // province: 'province',
                    // city: 'city',
                }
            }

            if (!self.getFieldError(checkRequest)) {
                noError = false;
            }

            // console.log(noError)
            if (noError) {
                data.id = this.state.addressId;
                data.userProfileId = this.state.userProfileId;
                AuthGuard.addAddress(data, (isOk, res) => {
                    Toast.hide();
                    if (isOk) {
                        this.back();
                    } else {
                        this.toastFailed(res.message)
                    }
                })
            }
        } else {
            Modal.alert('', 'We will need a valid address for delivery. Please check your address and try again', [{ text: 'OK', onPress: () => console.log('ok') }]);
        }

    }

    getFieldError = (datas) => {
        let data = datas.data;
        let name = datas.dataName;
        let requiredArr = ['address', 'countryCode', 'postalCode', 'name', 'dialingCode', 'mobileNumber'];
        // if(data.countryCode !== 'SG'){
        //     requiredArr.push('province', 'city');
        // }
        let err = true;
        let msg = {};

        Object.keys(data).forEach((k, i) => {
            if (requiredArr.includes(k) && err) {
                // console.log(data, name[k])
                if (data[k] === '') {
                    err = false;
                    msg[k] = name[k] + ' is required!';
                }
                // if(err && k === 'postalCode'){
                //     if (!isPostalCode(data[k])) {
                //         err = false;
                //         msg[k] = 'Invalid Postal Code Format!';
                //     }
                // }
            }
        })
        if (!err) {
            this.setState({
                errorMsg: msg
            });
        }
        return err;
    }

    render() {

        return (
            <div className="CompleteProfile-Page">
                <NavBar
                    mode="light"
                    icon={<Icon type="left" color="#EB5DA6" size="lg" />}
                    onLeftClick={this.back}
                ></NavBar>
                <WingBlank>
                    <Flex>
                        <div className="page-title">Address</div>
                    </Flex>
                    <WhiteSpace />
                    <Flex>
                        <div className="lable-title">Deliver to registered address</div>
                    </Flex>
                    <form id="complete-profile">
                        <Flex direction="column" align="start">
                            <Flex.Item>
                                <div className="lable-title requiredFields">Mobile No.</div>
                            </Flex.Item>
                            <Flex.Item>
                                <Flex>
                                    <Picker
                                        data={this.state.dialingCodeList}
                                        locale={enUs}
                                        value={[this.state.dialingCode]}
                                        extra=" "
                                        cols={1}
                                        onChange={v => {
                                            this.setState({
                                                dialingCode: v[0]
                                            })
                                        }}
                                    >
                                        <CustomChildren sClas={this.state.dialingCode ? 'sel' : 'noSel'}></CustomChildren>
                                    </Picker>
                                    <Flex.Item>
                                        <InputItem
                                            value={this.state.mobileNumber}
                                            // error={this.state.mobileError}
                                            editable="false"
                                            placeholder="Enter your Mobile No."
                                            onChange={v => this.setState({ mobileNumber: v })}
                                        ></InputItem>
                                    </Flex.Item>
                                </Flex>
                                <span className="errorMsg" >{(this.state.errorMsg.mobileNumber) ? this.state.errorMsg.mobileNumber : null} </span>
                            </Flex.Item>

                            <Flex.Item>
                                <div className="lable-title">Full Name * (as in ID card)</div>
                            </Flex.Item>
                            <Flex.Item>
                                <InputItem
                                    name='full_name'
                                    id="full_name"
                                    placeholder="Enter your full name"
                                    disabled={this.state.name !== ''}
                                    // error={this.state.errorMsg.name}
                                    value={this.state.name}
                                    onChange={v => this.setState({ name: v })}
                                ></InputItem>
                                <span className="errorMsg" >{(this.state.errorMsg.name) ? this.state.errorMsg.name : null} </span>
                            </Flex.Item>

                            <Flex.Item>
                                <div className="lable-title requiredFields">Postal Code</div>
                            </Flex.Item>
                            <Flex.Item>
                                <InputItem
                                    placeholder="Enter your postal code"
                                    // error={this.state.errorMsg.name}
                                    value={this.state.postalCode}
                                    onChange={v => this.setState({ postalCode: v })}
                                ></InputItem>
                                <span className="errorMsg" >{(this.state.errorMsg.postalCode) ? this.state.errorMsg.postalCode : null} </span>
                            </Flex.Item>

                            <Flex.Item>
                                <div className="lable-title requiredFields">Country</div>
                            </Flex.Item>
                            <Flex.Item>
                                <Picker
                                    data={this.state.countryList}
                                    extra="Country"
                                    locale={enUs}
                                    cols={1}
                                    value={[this.state.countryCode]}
                                    onChange={v => {
                                        this.setState({
                                            countryCode: v[0],
                                        }, () => {
                                            let show;
                                            if (this.state.countryCode === 'SG') {
                                                show = false;
                                            } else {
                                                show = true;
                                                this.setState({
                                                    province_code: '',
                                                    city_code: ''
                                                })

                                            }
                                            this.getProvinceList();
                                            this.setState({
                                                isDisplay: show
                                            });
                                        })
                                    }}
                                >
                                    <CustomChildren sClas={this.state.countryCode ? 'sel' : 'noSel'}></CustomChildren>
                                </Picker>
                                <span className="errorMsg" >{(this.state.errorMsg.country) ? this.state.errorMsg.country : null} </span>
                            </Flex.Item>

                            <div style={{ width: '100%', display: this.state.isDisplay ? 'block' : 'none' }}>
                                <Flex.Item>
                                    <div className="lable-title">Province</div>
                                </Flex.Item>
                                <Flex.Item>
                                    <Picker
                                        data={this.state.provinceList}
                                        extra="Province"
                                        locale={enUs}
                                        cols={1}
                                        value={[this.state.province_code]}
                                        onChange={v => {
                                            this.setState({
                                                province_code: v[0],
                                            }, () => {
                                                console.log('change get c')
                                                this.getCityList();
                                            })
                                        }}
                                    >
                                        <CustomChildren sClas={this.state.province_code ? 'sel' : 'noSel'}></CustomChildren>
                                    </Picker>
                                    <span className="errorMsg" >{(this.state.errorMsg.province) ? this.state.errorMsg.province : null} </span>
                                </Flex.Item>

                                <Flex.Item>
                                    <div className="lable-title">City</div>
                                </Flex.Item>
                                <Flex.Item>
                                    <Picker
                                        data={this.state.cityList}
                                        extra="City"
                                        cols={1}
                                        locale={enUs}
                                        value={[this.state.city_code]}
                                        onChange={v => {
                                            this.setState({
                                                city_code: v[0],
                                            })
                                        }}
                                    >
                                        <CustomChildren sClas={this.state.city_code ? 'sel' : 'noSel'}></CustomChildren>
                                    </Picker>
                                    <span className="errorMsg" >{(this.state.errorMsg.city) ? this.state.errorMsg.city : null} </span>
                                </Flex.Item>
                            </div>
                            <Flex.Item>
                                <div className="lable-title requiredFields">Street</div>
                            </Flex.Item>
                            <Flex.Item>
                                <InputItem
                                    placeholder="Enter your street"
                                    // error={this.state.errorMsg.name}
                                    value={this.state.address}
                                    onChange={v => this.setState({ address: v })}
                                ></InputItem>
                                <span className="errorMsg" >{(this.state.errorMsg.address) ? this.state.errorMsg.address : null} </span>
                            </Flex.Item>

                            <Flex.Item>
                                <div className="lable-title">Unit No.</div>
                            </Flex.Item>
                            <Flex.Item>
                                <InputItem
                                    placeholder="Enter your Unit No."
                                    // error={this.state.errorMsg.name}
                                    value={this.state.unitNo}
                                    onChange={v => this.setState({ unitNo: v })}
                                ></InputItem>
                            </Flex.Item>
                        </Flex>
                        <WhiteSpace />
                        <WhiteSpace />
                        <WhiteSpace />
                        <WhiteSpace />
                        <Flex.Item>
                            <Button
                                className="fill-pink-btn pb-5"
                                activeClassName="fill-pink-active-btn"
                                loading={this.state.loading}
                                onClick={this.saveAddressBtn}
                                disabled={this.state.disabled}
                            >
                                Confirm
                            </Button>
                        </Flex.Item>
                        <br /><br /><br /><br />
                    </form>
                </WingBlank>
            </div>

        );
    }
}


export default AddAddress;