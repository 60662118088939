import { create, all } from 'mathjs'
const config = {}
const math = create(all, config)


export const FLN = (num)=>{
    let _num = 0.00
    if(num){
        _num =  parseFloat(num)
        if (isNaN(_num)){
            _num = 0
        }else{
            _num = parseFloat(parseFloat(_num).toFixed(2))
        }
    }
    return _num
}

export const SumTotalAmount = (num1, num2, num3 = 0) => {
    let res =  math.evaluate(FLN(num1) + FLN(num2) - FLN(num3))
    return parseFloat(res).toFixed(2)
}

export const MulTotalAmount = (num1, num2, num3) => {
    let res =  math.evaluate(FLN(num1) * FLN(num2) / FLN(num3))
    return parseFloat(res).toFixed(2)
}