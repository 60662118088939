import React, { Component } from 'react';
import { Toast } from 'antd-mobile';
import { setCache, getCache, removeCache } from '../commons/Helpers';

class PlyPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            responsData: props.match.params.id
        }
    }

    componentDidMount() {
        this.loadingToast();
        if (this.state.responsData){
            setCache('state', this.state.responsData);
            if(getCache('is_sign')){
                let {type} = JSON.parse(getCache('is_sign'));
                removeCache('is_sign');
                if(type == 'sign_up'){
                    this.props.history.push("/signUpMyinfo");
                }else{
                    this.props.history.push("/improve");
                }
            }else{
                this.props.history.push("/improve");
            }
            Toast.hide()
        }else{
            setCache('state','')
            Toast.hide()
            if(getCache('is_sign')){
                let {type} = JSON.parse(getCache('is_sign'));
                removeCache('is_sign');
                if(type == 'sign_up'){
                    this.props.history.push("/signUpMyinfo");
                }else{
                    this.props.history.push("/improve");
                }
            }else{
                this.props.history.push("/improve");
            }
        }
    }

    loadingToast() {
        Toast.loading('Loading...', 30, () => {
            console.log('Load complete !!!');
        });
    }

    render(){
        return (<></>)
    }
}


export default PlyPage;