import React, { Component } from 'react';
import { WingBlank, Flex, WhiteSpace, NavBar, Icon, Toast } from 'antd-mobile';
import AuthGuard from '../services/AuthGuard';
import { showDate, setCache } from '../commons/Helpers';



class PaymentCoupon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            campaign_id: '',
            user_profile_id: '',
            packages_id: '',
            countryList: [],
            loading: false,
            couponError: "",
            couponSeccess: "",
            promocodeList: [],
            promo_code: '',
            refreshing: false,
            down: true,
            height: document.documentElement.clientHeight,
        }
    }

    componentDidMount() {
        
        let coupon_type = this.props.location.query !== undefined ? this.props.location.query.coupon_type : '';
        let _user_profile_id = this.props.location.query !== undefined ? this.props.location.query.user_profile_id : '';
        if(coupon_type === 'GE'){
            let packages_id = this.props.location.query !== undefined ? this.props.location.query.packages_id : '';
            if(packages_id === ''){
                this.props.history.goBack()
            }else{
                this.setState({
                    packages_id: packages_id,
                    user_profile_id: _user_profile_id,
                    coupon_type: coupon_type,
                }, () => {
                    this.getGEPromoCodeList();
                });
            }
        }else{
            let campaign_id = this.props.location.query !== undefined ? this.props.location.query.campaign_id : '';
            if (campaign_id === '') {
                this.props.history.goBack()
            } else {
                this.setState({
                    campaign_id: campaign_id,
                    user_profile_id: _user_profile_id,
                }, () => {
                    this.getPromoCodeList();
                });
            }
        }

    }

    loadingToast() {
        Toast.loading('Loading...', 30, () => {
            console.log('Load complete !!!');
        });
    }

    bindTap(user_promo_reward_id, status){
        if (status !== 'available') return false
        setCache('selectPaymentCouponId', user_promo_reward_id)
        this.props.history.goBack(1)
    }

    getPromoCodeList() {
        let self = this;
        this.loadingToast()
        AuthGuard.getPromoCodeListV2({
            code:this.state.promo_code,
            status: '',
            campaign_id: this.state.campaign_id,
            payment_info: JSON.stringify({"payment_code":"CC1"}),
            qty: 1,
        }, (isOk, res) => {
            Toast.hide()
            let list = []
            if (isOk) {
                list = res
            }
            self.setState({
                promocodeList: list
            })
            Toast.hide();
        });
    }

    getGEPromoCodeList() {
        let self = this;
        this.loadingToast()
        AuthGuard.getPromoCodeListV2({
            code:this.state.promo_code,
            status: '',
            package_id:this.state.packages_id,
        }, (isOk, res) => {
            Toast.hide()
            let list = []
            if (isOk) {
                list = res
            }
            self.setState({
                promocodeList: list
            })
            Toast.hide();
        });
    }

    applyCoupon() {
        let self = this;
        if (self.state.promo_code === '') {
            this.setState({
                couponError: 'Please enter coupon code.'
            });
            document.querySelector(".errorMsg").style.display = "block"
        } else {
            if(self.state.user_profile_id){
                AuthGuard.applyPromocode({
                    user_profile_id: self.state.user_profile_id,
                    promo_code: self.state.promo_code
                }, (isOk, res) => {
                    if (isOk) {
                        this.setState({
                            promo_code:''
                        },()=>{
                            if(self.state.coupon_type == 'GE'){
                                self.getGEPromoCodeList()
                            }else{
                                self.getPromoCodeList()
                            }
                        })
                    } else {
                        this.setState({
                            couponError: res.message
                        });
                        document.querySelector(".errorMsg").style.display = "block"
                    }
                });
            }
        }
    }

    inputChange(e) {
        document.querySelector(".successMsg").style.display = "none"
        document.querySelector(".errorMsg").style.display = "none"
        this.setState({
            couponError: '',
            couponSeccess: '',
            promo_code: e.target.value
        })
    }

    searchCode = (e) => {
        let self = this;
        if(self.state.coupon_type == 'GE'){
            self.getGEPromoCodeList()
        }else{
            self.getPromoCodeList()
        }
        e.preventDefault();
    }

    viewDetail = (id, e) => {
        e.stopPropagation();
        let data = {
            campaign_id: this.state.campaign_id,
            user_profile_id: this.state.user_profile_id,
            promo_row_id: id,
            coupon_type: this.state.coupon_type,
            packages_id: this.state.packages_id,
        }
        this.props.history.push({ pathname: "/paymentCouponDetail", query: data })
    }

    render() {
        return (
            <div className="Coupon-Page">
                <NavBar
                    mode="light"
                    icon={<Icon type="left" size="lg" color="#EB5DA6" size="lg"/>}
                    onLeftClick={() => this.props.history.goBack()}
                    className="backBtn"
                ></NavBar>
                <WingBlank>
                    <WhiteSpace />
                    <form className="input-search-form" onSubmit={this.searchCode}>
                        <Flex className="search-contain">
                            <Icon name="search" type="search" size="md" color="#eb5da6" />
                            <input
                                type="search"
                                placeholder="Search Coupon Code"
                                onChange={(event) => { this.inputChange(event) }}
                                className="search-input"
                                name="couponCode"
                                autoComplete="off"
                                value={this.state.promo_code}
                            />
                            <Icon name="plus" type="plus" onClick={this.applyCoupon.bind(this)} size="md" color="#eb5da6" />
                        </Flex>
                    </form>
                    <div className="coupon-msg">
                        <WhiteSpace />
                        <span className="errorMsg">{(this.state.couponError) ? this.state.couponError : null} </span>
                        <span className="successMsg">{(this.state.couponSeccess) ? this.state.couponSeccess : null}</span>
                    </div>
                    <WhiteSpace />
                    <div className="coupon-list-contain">

                        {this.state.promocodeList.map((item) => (
                            <Flex justify="between" onClick={this.bindTap.bind(this, item.user_promo_reward_id, item.status_code)} key={item.user_promo_reward_id} className={'coupon-row ' + (item.status_code === "available" ? 'active' : '')}>
                                <div>
                                    <div className="h3 mb-1">{item.code}</div>
                                    <div className="text">Expires on: {showDate(item.expiry_date, 8)}</div>
                                    <WhiteSpace />
                                    <div className="link" onClick={this.viewDetail.bind(this, item.user_promo_reward_id)}>View Details</div>
                                </div>
                                <div className={item.status_code !== 'available' ? '' : 'hide'} style={{ width: '20%', textAlign: 'right', color: '#bbb' }}>
                                    {item.status}
                                </div>
                            </Flex>
                        ))}

                    </div>
                </WingBlank>
            </div>
        );
    }
}


export default PaymentCoupon;