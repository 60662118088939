import React, { Component } from 'react';
import PaymentResultSuccess from './PaymentResultSuccess'
import PaymentFail from './PaymentFail'
import AuthGuard from '../services/AuthGuard'
import axios from 'axios'
import url from 'url'
import { getCache, removeCache, setCache } from '../commons/Helpers'
import { Toast } from 'antd-mobile';

class ProgramsPaymentStatus extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            success:false
        }
    }

    componentWillMount() {        
        this.renderRes();
    }

    loadingToast() {
        Toast.loading('Loading...', 30, () => {
            console.log('Load complete !!!');
        });
    }
    getPayBackData(resolve, reject){
        var httpUrl = this.props.location.search;
        var token = url.parse(httpUrl, true).query.token;
        if (getCache('pro_info')) {
            let { transaction_id, program_code } = JSON.parse(getCache('pro_info'));
            AuthGuard.getPayProBackData({ ...JSON.parse(getCache('pro_info')), response: token }, (isOk, res) => {
                if (isOk) {
                    let pro_info = JSON.parse(getCache('pro_info'));
                    pro_info.program_name = res.name;
                    pro_info.program_img = res.program_image.url.o;
                    pro_info.coach_name = res.coach_name;
                    setCache('pro_info',pro_info)
                    resolve('success')
                } else {
                    reject('error')
                }
            })
        } else {
            reject('error')
        }
    }
    
    renderRes(){
        this.loadingToast();
        let self = this;
        new Promise((resolve, reject) => { this.getPayBackData(resolve, reject) }).then(data => {
            Toast.hide();
            this.setState({
                success: data
            })
            // removeCache('pro_info')
        }).catch(error =>{
            Toast.hide();
            this.setState({
                success: error
            })
            // removeCache('bill_info')
        })       


    }

    render() { 
        return ( 
            <>
                {
                    this.state.success ? (this.state.success === 'success' ? <PaymentResultSuccess {...this.props}/> : <PaymentFail {...this.props} />) :''
                }
            </>
        );
    }
}
 
export default ProgramsPaymentStatus;