import ReactGA from "react-ga";
import { GOOGLE_ANALYTICS } from "./Constants";

// (category : 类别 ； action ： 动作； 还可以有label： 标签； value：值等等....)
export const ReactGAEvent = (action , category) =>{
  if (!GOOGLE_ANALYTICS) return;
    ReactGA.event({
      category,
      action,
    });
}
