import React, { Component } from 'react';
import { Button, Modal, Toast } from 'antd-mobile';
import { setCache } from '../commons/Helpers';
import { ReactGAEvent } from '../commons/ReactGAEvent';
const alert = Modal.alert;

class LoginModel extends Component {
    constructor(props) {
        super(props);
        this.state = {  
            url: '',
            alert_title:'You need a HeartVoice account to consult doctors.',
            id: ''
        }
    }

    componentDidMount() {
        this.props.onRef(this)

        console.log(this.props)

    }

    toLogin = (params) => {
        let { url, query } = params
        setCache('redirectUrl', url)
        this.props.history.push("/login");
    }

    alertLogin(params, alertTilte = 'Oops'){
        if (navigator.userAgent.indexOf('heartvoice') !== -1) {
            Modal.alert('Notice', 'Invalid identity.', [
                { text: 'OK', onPress: () =>{ window.location.href = 'heartvoice://heartvoice/home?action=home'} },
            ]);
        }else{
            let { alert_title} = params
            this.setState({
                alert_title: alert_title
            })
            alert(alertTilte, this.state.alert_title, [
                { text: 'Cancel', onPress: () => { ReactGAEvent("Go TO Login / Sign up", "onclick Cancel button");console.log('cancel');}},
                { text: 'Login / Sign up', onPress: () => {  ReactGAEvent("Go TO Login / Sign up", "onclick Login / Sign up button"); this.toLogin(params)} },
            ])
        }
    }

    render() { 
        return (
            <></>
        )
    }
}
 
export default LoginModel;